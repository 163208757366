<template>
    <div class="taskItem">
         <div class="content" v-if="showSourse">
            <div v-for="(item,sourseIndex) in itemList" :key="item.id" class="sourse" @click="toDetail(sourseIndex,item)">
                <span :class="[isSelect===sourseIndex?'selected sourse-name':'sourse-name',item.type==7?'lianxiname':'']" :title="item.type === 1 ? item.stageName : item.contentName">
                    <span class="textType" v-if="item.type != 1">
                        {{item.type == 2?'课程':item.type === 3?'考试':item.type === 4 ? '直播':item.type === 5 ? '实训' :item.type === 6?'表单':'练习'}}
                    </span>
                    <span class="name">{{item.type === 1 ? item.stageName : item.contentName}} </span>
                </span>
                <div class="isLocked" v-show="item.isLocked">
                    <img src="./../assets/images/learning/lock-icon1.png"  class="icon" alt="">
                </div>
                <span v-show="item.type === 2 && !item.isLocked" class="learnRate">{{item.studyProcess || 0}}%</span>

                <span v-if="item.type === 6" :class="['submitStatus',item.submitStatus?'mrsubmitStatus':'']">{{item.submitStatus?'已填写':'未填写'}}</span>
                <span @click.stop="jumpReplay(item)" v-show="item.type === 4 && item.isLiveReplay && item.status === 2 && !item.isLocked" class="learnRate learngkhf">观看回放</span>
                <span class="time" :class="item.isLocked?'marginRight':''" v-show="item.type === 3 && item.isLimitTime">考试时间：{{item.contentStartTime && item.contentStartTime.replace(/-/g,'.')}} - {{item.contentEndTime && item.contentEndTime.replace(/-/g,'.')}}</span>
                <span class="time" :class="item.isLocked?'marginRight':''" v-show="item.type === 4 ">直播时间：{{item.contentStartTime && item.contentStartTime.replace(/-/g,'.')}} - {{item.contentEndTime && item.contentEndTime.replace(/-/g,'.')}}</span>
                <span class="time" :class="item.isLocked?'marginRight':''" v-show="item.type === 6 && item.contentEndTime">结束时间：{{item.contentEndTime && item.contentEndTime.replace(/-/g,'.')}}</span>
                
                
                
            </div>
        </div>

        <el-collapse v-model="activeNames" v-else>
            <el-collapse-item :name="index" v-for="(el,index) in itemList" :key="el.id" v-show="el.childItemList.length">
                <template slot="title" v-if="el.type === 1" >
                    <div @click="tipClick(el)" class="title-box">
                        <i :class="activeNames.includes(index)?'el-icon-caret-bottom':'el-icon-caret-right'"></i>
                        <span class="sourse-title">{{el.stageName}}</span>
                        <img src="./../assets/images/learning/lock-icon1.png" v-if="el.isLocked"  class="lock-icon" alt="">
                    </div>
                    
                </template>
                <!-- 1 目录，2 课程， 3 考试，4 直播，5 实训 6 表单-->
                <ul>
                    <li v-for="(ele,index1) in el.childItemList" :key="ele.id" >
                        <div v-if="ele.type != 1" class="sourse" @click="toDetail(index1,ele)">
                            <span :class="isSelect===index1?'selected sourse-name':'sourse-name'" :title="ele.type === 1 ? ele.stageName : ele.contentName">
                                <span class="textType" v-if="ele.type != 1">
                                    {{ele.type == 2?'课程':ele.type === 3?'考试':ele.type === 4 ? '直播':ele.type === 5 ? '实训':ele.type === 6?'表单':'练习'}}
                                </span>
                                <span class="name">{{ele.type === 1 ? ele.stageName : ele.contentName}} </span>
                                <!-- <span v-if="ele.dateExpireStatus == 2 || courseExpireStatus == 2" class="guoqi">已过期不可查看</span> -->
                            </span>
                            <div class="isLocked" v-show="ele.isLocked">
                                <img src="./../assets/images/learning/lock-icon1.png"  class="icon" alt="">
                            </div>
                            <span v-show="ele.type === 2 && !ele.isLocked" class="learnRate">{{ele.studyProcess || 0}}%</span>
                            <span v-if="ele.type === 6" :class="['submitStatus',ele.submitStatus?'mrsubmitStatus':'']">{{ele.submitStatus?'已填写':'未填写'}}</span>
                            <span @click.stop="jumpReplay(ele)" v-show="ele.type === 4 && ele.isLiveReplay && ele.status === 2 && !ele.isLocked" class="learnRate learngkhf">观看回放</span>
                            <span class="time" :class="ele.isLocked?'marginRight':''" v-show="ele.type === 3 && ele.isLimitTime">考试时间：{{ele.contentStartTime && ele.contentStartTime.replace(/-/g,'.')}} - {{ele.contentEndTime && ele.contentEndTime.replace(/-/g,'.')}}</span>
                            <span class="time" :class="ele.isLocked?'marginRight':''" v-show="ele.type === 4 ">直播时间：{{ele.contentStartTime && ele.contentStartTime.replace(/-/g,'.')}} - {{ele.contentEndTime && ele.contentEndTime.replace(/-/g,'.')}}</span>
                            <span class="time" :class="ele.isLocked?'marginRight':''" v-show="ele.type === 6  && ele.contentEndTime">结束时间：{{ele.contentEndTime && ele.contentEndTime.replace(/-/g,'.')}}</span>
                            
                            
                            
                        </div>
                    </li>
                </ul>
                <taskItem v-if="el.type===1" :itemList="el.childItemList" :taskDetail="taskDetail"></taskItem>
            </el-collapse-item>
        </el-collapse>
       
        
    </div>
</template>
<script>
export default {
    name: 'taskItem',
    props: {
        itemList: {
            type: Array,
            default: () => []
        },
        courseExpireStatus:{
            type:Number
        },
        taskDetail:{
            type: Object,
            default:() => {}
        },
        showSourse:{
            type:Boolean
        }
  
    },
     data () {
        return {
            activeNames: [0],
            isSelect:null,
        }
     },
    methods:{
        // 观看直播回放
        jumpReplay(item){
            console.log('直播回放')
            if(item.isReplay){
                this.$api.live.saveViewUser(item.contentId).then((res) => {
                    if(res.success){
                        this.$router.push({
                            path: '/livePlayback',
                            query:{
                                roomId: item.contentId,
                                sourceId: item.replayResourceId,
                            }
                        })
                    }
                })
            } else {
                this.$message.warning({message:'视频处理中，请次日查看~',offset:70})
            }
            //  && item.isReplay
            
        },
         // 点击章节上锁提示
         tipClick(el){
             if(el.isLocked){
                 if(localStorage.getItem('theme')=='junmin'){
                    this.$message({
                        message: this.multiPopTips(),
                        type: 'warning'
                    });
                 }else{
                    this.$message.info(this.multiPopTips())
                 }
                 //this.$message.info(`该章未开启，开启条件为前面的学习内容学习进度达到 ${this.taskDetail.lockCourseRate}%，考试及格。`)
             }
         },
         // 获取实训详情
        getdrillDetail(id){
            this.$api.learning.getdrillDetail({id}).then(res => {
                if(res.data){
                    window.open(window.location.origin + `/training_detail?courseId=${res.data.courseId}&labId=${res.data.labId}`)
                }
            })
        },
         toDetail(e,item){
            this.isSelect = e;
            // if(item.dateExpireStatus == 2 || this.courseExpireStatus == 2){
            //     this.$message.info('已过期不可查看')
            // }else {
                
            // }
            console.log(this.taskDetail,'tasssss')
            if(item.type === 1 && item.isLocked){
                this.$message.warning({message:this.multiPopTips(),offset:70})
                //this.$message.info(`该章未开启，开启条件为前面的学习内容学习进度达到 ${this.taskDetail.lockCourseRate}%${this.taskDetail.lockExamCondition?'，考试及格。':'。'}`)
            }else if(item.type === 2){
                if(item.isLocked){
                    //this.$message.info(`该课程未开启，开启条件为前面的学习内容学习进度达到 ${this.taskDetail.lockCourseRate}%${this.taskDetail.lockExamCondition?'，考试及格。':'。'}`)
                    this.$message.warning({message:this.multiPopTips(),offset:70})
                    return false
                }else {
                    this.$router.push({
                        path:'/learning/courseDetails',
                        query:{
                            id:item.contentId,
                            taskId:item.taskId,
                            taskType:2,//1：训练营 2：任务；
                            backMoudle:this.$route.query.backMoudle,
                            backPath:this.$route.query.backPath
                            
                        }
                    })
                }
                
            }else if(item.type === 3){
                if(item.isLocked){
                    //this.$message.info(`该考试未开启，开启条件为前面的学习内容学习进度达到 ${this.taskDetail.lockCourseRate}%${this.taskDetail.lockExamCondition?'，考试及格。':'。'}`)
                    this.$message.warning({message:this.multiPopTips(),offset:70})
                    return false
                }else {
                    this.$router.push({
                        path:'/examDetails',
                        query:{
                            examId:item.contentId,//考试ID
                            bussinessId:item.taskId,//任务ID
                            type:2,//训练营1，任务2
                            title:this.taskDetail.name,
                            pathName:this.$route.path,
                            backPath:this.$route.query.backPath,
                            timeSlot:this.taskDetail.cycleType === 1 ? this.taskDetail.startTime.replace(/-/g,'.')+'至'+this.taskDetail.endTime.replace(/-/g,'.'): this.taskDetail.cycleType === 2 ? this.taskDetail.endTime.split(' ')[0].replace(/-/g,'.'):'长期有效'
                        }
                    })
                }
                
            }else if(item.type === 4){
                if(item.isLocked){
                    //this.$message.info(`该直播未开启，开启条件为前面的学习内容学习进度达到 ${this.taskDetail.lockCourseRate}%${this.taskDetail.lockExamCondition?'，考试及格。':'。'}`)
                    this.$message.warning({message:this.multiPopTips(),offset:70})
                    return false
                }else {
                    this.$api.live.audienceEnterRoom({id: item.contentId}).then(async (res) => {
                            if (res.success) {
                                window.open(window.location.origin + `/studentSee?type=1&roomId=${item.contentId}`)
                            }
                    });
                    
                }
                
            }else if(item.type === 5){
                if(item.isLocked){
                    //this.$message.info(`该实训未开启，开启条件为前面的学习内容学习进度达到 ${this.taskDetail.lockCourseRate}%${this.taskDetail.lockExamCondition?'，考试及格。':'。'}`)
                    this.$message.warning({message:this.multiPopTips(),offset:70})
                }else {
                    this.getdrillDetail(item.contentId)
                }
            }else if(item.type === 6){
                if(item.isLocked){
                   this.$message.warning({message:this.multiPopTips(),offset:70})
                 }else {
                     this.$router.push({
                        path:'/learning/formPage',
                        query:{
                            type: this.$route.query.backMoudle == 'trainingTask' ? 2:  this.$route.query.backMoudle == 'trainingCamp' ? 1 : 2,// type：1训练营 2培训任务
                            typeId: this.$route.query.id || this.$route.query.taskId,// typeId：训练营id或者培训任务id
                            wjId:item.contentId // wjId：问卷ID
                        }
                    })

                }
            }else if(item.type === 7){
                if(item.isLocked){
                    //this.$message.info(`该课程未开启，开启条件为前面的学习内容学习进度达到 ${this.taskDetail.lockCourseRate}%${this.taskDetail.lockExamCondition?'，考试及格。':'。'}`)
                    this.$message.warning({message:this.multiPopTips(),offset:70})
                    return false
                }else {
                    this.$router.push({
                        path:'/pattern',
                        query:{
                            businessId:item.taskId,
                            practiceId:item.contentId,
                            businessType:2,
                        }
                    })
                }
            }
            
         },
         multiPopTips() {
            if(this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition===1 && this.taskDetail.isFormCommit){
                return `该章未开启，开启条件为前面的学习内容学习进度达到${this.taskDetail.lockCourseRate}%，考试及格，表单提交`;
            }else if(this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition===0 && this.taskDetail.isFormCommit){
                return `该章未开启，开启条件为前面的学习内容学习进度达到${this.taskDetail.lockCourseRate}%，表单提交`;
            }else if(!this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition===0 && this.taskDetail.isFormCommit){
                return '该章未开启，开启条件为表单提交';
            } else if(!this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition===1 && !this.taskDetail.isFormCommit ){
                return '该章未开启，开启条件为考试及格';
            } else if(this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition===0 && !this.taskDetail.isFormCommit){
                return `该章未开启，开启条件为前面的学习内容学习进度达到${this.taskDetail.lockCourseRate}%`;
            } else if(this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition && !this.taskDetail.isFormCommit) {
                return `该章未开启，开启条件为前面的学习内容学习进度达到${this.taskDetail.lockCourseRate}%，考试及格`;
            } else if(!this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition && this.taskDetail.isFormCommit) {
                return '该章未开启，开启条件为考试及格，表单提交';
            }             
         }
     }
}
</script>
<style lang="stylus" scoped>
 @import "../assets/css/courseItem.styl"

</style>