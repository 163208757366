<template>
    <div class="title">
        {{title}}
    </div>
</template>

<script>

export default {
    name: 'coursetitle',
    props: {
        title: {
            type: String,
            default: ''
        }
    },
    data() {
        return {};
    },
    computed: {},
    created() {},
    methods: {},
};
</script>

<style lang="stylus" scoped>
.title
    color #000000
    font-size 36px
    margin 70px 0 70px 50px
</style>
