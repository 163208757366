import { render, staticRenderFns } from "./weidongFooter.vue?vue&type=template&id=1db551db&scoped=true&"
import script from "./weidongFooter.vue?vue&type=script&lang=js&"
export * from "./weidongFooter.vue?vue&type=script&lang=js&"
import style0 from "./weidongFooter.vue?vue&type=style&index=0&id=1db551db&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1db551db",
  null
  
)

export default component.exports