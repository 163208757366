<template>
    <div class="services publicStylus">
        <div class="container">
            <div class="top">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/services-banner.png" class="banner" alt="">
                <div class="content">
                    <div class="info" @click="goPage(2)">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/services-icon2.png" class="icon" alt="">
                        <p>政府行政监管</p>
                    </div>
                    <!-- <div class="info" @click="goPage(2)">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/services-icon2.png" class="icon" alt="">
                        <p>业务监管（政府）</p>
                    </div> -->
                    <div class="info" @click="goPage(1)">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/services-icon3.png" class="icon" alt="">
                        <p>行业自律监管</p>
                    </div>
                    <div class="info" @click="jump">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/services-icon4.png" class="icon" alt="">
                        <p>上门证申领</p>
                    </div>
                </div>
                
            </div>
            <div class="bottom">
                <div class="item" v-for="item in list" :key="item.id">
                    <h2 class="title zh">{{item.zh}}</h2>
                    <p class="cn">{{item.cn}}</p>
                    <p class="line"></p>
                    <p class="content">{{item.content}}</p>
                </div>
            </div>
        </div>
        <footers></footers>
    </div>
</template>
<script>
import footers from './sdjz2Footer.vue'
export default {
    name:'services',
    components:{footers},
    data(){
        return{
            list:[
                {
                    id:1,
                    zh:'服务监管',
                    cn:'Services',
                    content:'服务监管系统为政府主管部门监测行业、家政企业信息等提供管理抓手、支撑决策服务，包括线上实时监管、实时数据统计、相关数据报表生成等功能，支持行业主管部门进行培训管理、供需监测、投诉管理和评价监管等。利用大数据分析工具，对记录的各种数据进行采集、挖掘，生成各种数据仪表盘，支持第一时间监测家政企业及服务人员状态。'
                },
            ]
        }
    },
    methods:{
        goPage(type){
            const hostname = window.location.hostname;
            if(hostname.includes('testing3')){
                if(type == 1) {
                    window.open('https://hrss-main.testing3.wdeduc.com/saas/screen/trainRegulation')
                }else {
                    window.open('https://hrss-main.testing3.wdeduc.com/saas/screen/governmentDataScreen')
                }
            }else if(hostname.includes('fat')){
                if(type == 1) {
                    window.open('https://hrss-main.fat.wdeduc.com/saas/screen/trainRegulation')
                }else {
                    window.open('https://hrss-main.fat.wdeduc.com/saas/screen/governmentDataScreen')
                }
            }else if(hostname.includes('uat')){
                if(type == 1) {
                    window.open('https://hrss-main.uat.wdeduc.com/saas/screen/trainRegulation')
                }else {
                    window.open('https://hrss-main.uat.wdeduc.com/saas/screen/governmentDataScreen')
                }
            }else {
                if(type == 1) { // 行业自律监管
                    window.open('https://hrss-main.wdeduc.com/saas/screen/trainRegulation')
                }else { // 政府行政监管
                    window.open('https://hrss-main.wdeduc.com/saas/screen/governmentDataScreen')
                }
            }
  
            // window.open(`https://${host}/screen?type=${type}`)
            // this.$router.push({
            //     path:'/screen',
            //     query:{
            //         type:type
            //     }
            // })
        },
        jump() {
            this.$router.push('doorToDoorApproval');
        }
    }
}
</script>
<style lang="stylus" scoped>
@import "../asset/css/publicStylus.styl"
</style>