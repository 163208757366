import CryptoJS from "crypto-js";
let base =  require('js-base64').Base64;

/**
 *
 * 第一个参数word是待加密或者解密的字符串；
 * 第二个参数keyStr是aes加密需要用到的16位字符串的key；
 * 第三个参数是初始化向量 iv。
 */
export default {


    // 青职加密
    encryptQZ(word, keyStr, ivStr) {
        keyStr = keyStr ? keyStr : "absoietlj32fai12";
        ivStr = ivStr ? ivStr : "absoietlj32fai12";
        let key = CryptoJS.enc.Utf8.parse(keyStr);
        let iv = CryptoJS.enc.Utf8.parse(ivStr);
        let srcs = CryptoJS.enc.Utf8.parse(word);

        let encrypted = CryptoJS.AES.encrypt(srcs, key, {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
        });
        return  base.encode(encrypted.toString());
    },
    // 青职解密
    decryptQZ(word, keyStr, ivStr) {
        keyStr = keyStr ? keyStr : "absoietlj32fai12";
        ivStr = ivStr ? ivStr : "absoietlj32fai12";
        var key = CryptoJS.enc.Utf8.parse(keyStr);
        let iv = CryptoJS.enc.Utf8.parse(ivStr);
        var decrypt = CryptoJS.AES.decrypt(base.decode(word), key, {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
        });

        return decrypt.toString(CryptoJS.enc.Utf8);
    },


    // 加密
    encrypt(word, keyStr, ivStr) {
        keyStr = keyStr ? keyStr : "absoietlj32fai12";
        ivStr = ivStr ? ivStr : "absoietlj32fai12";
        let key = CryptoJS.enc.Utf8.parse(keyStr);
        let iv = CryptoJS.enc.Utf8.parse(ivStr);
        let srcs = CryptoJS.enc.Utf8.parse(word);

        let encrypted = CryptoJS.AES.encrypt(srcs, key, {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
        });
        return  base.encode(encrypted.toString());
    },
    // 解密
    decrypt(word, keyStr, ivStr) {
        keyStr = keyStr ? keyStr : "absoietlj32fai12";
        ivStr = ivStr ? ivStr : "absoietlj32fai12";
        var key = CryptoJS.enc.Utf8.parse(keyStr);
        let iv = CryptoJS.enc.Utf8.parse(ivStr);
        var decrypt = CryptoJS.AES.decrypt(word, key, {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
        });

        return decrypt.toString(CryptoJS.enc.Utf8);
    }
};
