<template>
 <div class='footers'>
     <el-row class="footers-con" type="flex" justify="center">
         <div class="footer-con-content">
             <div class="left">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/logo11.png" alt="">
                <!-- <p>伟东云教育集团</p>
                <p class="address">
                    <span>地址: 北京市海淀区四道口大钟寺8号1号楼</span>
                    <span class="tel">客服电话：400-8555-686</span>
                </p> -->
                <!-- <div class="con">
                    <span class="title">工业和信息化部教育与考试中心</span>
                    <span class="link" @click="jump">https://www.miiteec.org.cn/</span>
                </div> -->
            </div>
            <div class="line line-l"></div>
            <div class="middle">
                
                <router-link to="/gongxin_about" target="a" class="about">关于我们</router-link>
                <!-- <p>平台所有方：伟东云教育集团</p> -->
                <p class="address">
                    地址: 北京市西城区北三环中路23号燕莎盛世大厦5层
                </p>
                <p class="tel">客服电话：400-8555-686</p>
                <!-- <p class="link">友情链接：<a href="https://www.miiteec.org.cn/" target="_blank">工业和信息化部教育与考试中心</a></p> -->

            </div>
            <div class="line line-r"></div>
            <div class="right">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/wechatfooter.png" alt="">
                <p>公众号</p>
            </div>
         </div>
     </el-row>
    <saas-nsr-footer :isContract="false" :bgColor="'#03024B'" />
     <!-- <el-row class="footers-f">
         <h2>北京伟东凌鸿教育科技有限公司@版权所有 京 ICP备10026535号</h2>
     </el-row> -->
 </div>
</template>

<script>
export default {
    name:'footers',
    props: {
    },
    data () {
        return {
            options: [
                {
                    value: 'http://www.sasac.gov.cn/',
                    label: '国务院国有资产监督管理委员会'
                },
                {
                    value: 'https://www.wdecloud.com/',
                    label: '伟东云教育'
                },
                {
                    value: 'http://www.ciic.com.cn/',
                    label: '中智集团'
                },
            ],
            value: ''
        };
    },

    methods: {
        selectChanged(value) {
            if (value) {
                window.open(value,"_blank")
                // window.location.href = value;
            }
        },
        jump () {
            window.open("https://www.miiteec.org.cn/", "_blank");
        }
    },

    mounted(){
    },
}

</script>
<style lang='stylus' scoped>
    @import "../../asset/css/footers.styl"
</style>
<style lang='stylus'>
    .footers .el-input__inner {
        width: 159px;
        height: 34px;
        line-height: 34px;
    }
    .footers .el-input__icon {
        line-height: 34px;
    }
    .el-select {
        display: inline-block;
        vertical-align: middle;
    }
</style>