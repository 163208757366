<template>
 <div class='footers'>
     <img class="footers-imgs" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/footer.png" alt="">
     <saas-nsr-footer :bgColor="'#1E2437'" :copyright="'© 2021 zzdxwdy.com 版权所有'" :nsrNumber="'京公网安备 11010502043970号'" :ICPNumber="'京ICP备17061969号-4'" :showText1="false" :showLicence="false"/>
     <!--<el-row class="footers-con" type="flex" justify="center">-->
         <!--<div class="left">-->
            <!--<img class="left-logo" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/logoF.png" alt="">-->
            <!--<div class="links">-->
                <!--<span class="label">友情链接：</span>-->
                <!--<el-select  v-model="value" placeholder="请选择" @change="selectChanged" clearable>-->
                    <!--<el-option-->
                    <!--v-for="item in options"-->
                    <!--:key="item.value"-->
                    <!--:label="item.label"-->
                    <!--:value="item.value">-->
                    <!--</el-option>-->
                <!--</el-select>-->
            <!--</div>-->
         <!--</div>-->
         <!--<div class="center">-->
            <!--<p>主办单位: 中智大学</p>-->
            <!--<p>承办单位：伟东云教育集团</p>-->
            <!--<p>邮编：100089</p>-->
            <!--<p>地址: 青岛市香港中路30号甲世奥国际大厦12层</p>-->
         <!--</div>-->
         <!--<div class="right">-->
             <!--<img src="" alt="">-->
             <!--<span>扫码查看手机版</span>-->
         <!--</div>-->
     <!--</el-row>-->
     <!-- <el-row class="footers-img" style="">
         <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/footer-bg.png" alt="">
         <h2>中智集团、伟东云教育集团 · 携手打造中国职业教育云平台</h2>
     </el-row>
     <el-row class="footers-f">
         <h2>北京伟东凌鸿教育科技有限公司@版权所有 京 ICP备10026535号-6</h2>
     </el-row> -->
 </div>
</template>

<script>
export default {
    name:'footers',
    props: {
    },
    data () {
        return {
            options: [
                {
                    value: 'http://www.sasac.gov.cn/',
                    label: '国务院国有资产监督管理委员会'
                },
                {
                    value: 'https://www.wdecloud.com/',
                    label: '伟东云教育'
                },
                {
                    value: 'http://www.ciic.com.cn/',
                    label: '中智集团'
                },
            ],
            value: ''
        };
    },

    methods: {
        selectChanged(value) {
            if (value) {
                window.open(value,"_blank")
                // window.location.href = value;
            }
        }
    },

    mounted(){
    },
}

</script>
<style lang='stylus' scoped>
    @import "../../asset/css/footers.styl"
</style>
<style lang='stylus'>
    .footers .el-input__inner {
        width: 159px;
        height: 34px;
        line-height: 34px;
    }
    .footers .el-input__icon {
        line-height: 34px;
    }
    .el-select {
        display: inline-block;
        vertical-align: middle;
    }
</style>
