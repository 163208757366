<template>
    <div class="gongxintDetails">
        <!--顶部底图-->
        <div class="banner-wrap" >
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/course-banner.jpg" />
        </div>
        <!--课程包信息-->
        <div class="trainng-card-wrap" :class="{'fixed': isFixed}">
            <div class="trainng-card flex lr-flex block-main">
                <div>
                    <div class="flex price-box">
                        <div class="free" v-if="trainObj.isFree">
                            免费
                        </div>
                        <div v-else class="flex">
                            <p class="flex" v-show="trainObj.salePrice">
                                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/red-y.png" class="red"/>
                                <span class="now-price">{{trainObj.salePrice}}</span>
                            </p>
                            <p  class="flex pre-price-box" v-show="trainObj.basePrice">
                                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/grey-y.png" class="grey"/>
                                <span class="pre-price">{{trainObj.basePrice}}</span>
                            </p>
                        </div>
                        
                        <p v-if="isFixed" style="display:flex;">
                            <span class="name" :title="trainObj.name">{{trainObj.name}}</span>
                            <span class="time" v-if="trainObj.validityType === 1">
                                有效期：长期有效
                            </span>
                            <span class="time" v-else-if="trainObj.validityType === 2">
                                有效期：{{dataFormat(trainObj.startTime)}}-{{dataFormat(trainObj.endTime)}}
                            </span>
                            <span class="time" v-if="trainObj.validityType === 4">
                                有效期：报名后{{trainObj.days}} 天内有效
                            </span>
                        </p>
                    </div>
                    <p v-if="!isFixed" style="display:flex;">
                        <span class="name" :title="trainObj.name">
                            {{trainObj.name}}
                        </span>
                        <span class="time" v-if="trainObj.validityType === 1">
                            有效期：长期有效
                        </span>
                        <span class="time" v-else-if="trainObj.validityType === 2">
                            有效期：{{dataFormat(trainObj.startTime)}}-{{dataFormat(trainObj.endTime)}}
                        </span>
                        <span class="time" v-if="trainObj.validityType === 4">
                            有效期：报名后{{trainObj.days}} 天内有效
                        </span>
                    </p>
                </div>
                <div class="btn-study"  >
                    <!-- {{purchased?'报名已结束':'立即学习'}} -->
                    <p @click="jumpStudy" class="button" :class="{'disable': btnType == '报名已结束'}">{{btnType}}</p>
                    <p @click="dialog.show = true" class="xieyi">《培训服务协议》</p>

                </div>
            </div>
        </div>
        <!-- 项目介绍 -->
        <div class="project-introduction">
            <div class="container">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/details-title-1.png" class="title" alt="">
                <div class="content">
                    <div class="left">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/project-img.png" alt="">
                    </div>
                    <div class="right">
                        <h2 class="title">《人工智能应用管理师职业能力等级评价项目》</h2>
                        <p class="text">是由工业和信息化部教育与考试中心（CEIAEC）面向人工智能行业从业者推出的职业能力等级评定项目。该项目面向当前人工智能应用管理人才极度紧缺的现状，弥补了权威性人工智能人才能力培养体系和评价体系标准的空白，作为国内人工智能领域首个职业能力等级评价标准。为人工智能人才培养，人才评估和企业招聘提供了重要的依据。目前，《人工智能应用管理师职业能力等级评价证书》已经得到越来越多用人单位的认可，助力求职者实现更高职业梦想。</p>
                        <p class="label">
                            <span>实现职业梦想</span>
                            <span>用人单位认可</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!--课程特色-->
        <div class="feature-block">
            <div class="top-course">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/details-top-title.png" />
            </div>
            <div class="block-main flex lr-flex">
                <div class="item">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/feature1.png" />
                    <p>重实战落地的学习模式</p>
                    <div class="desc">从理论学习，到案例实操，还原真实项目场景</div>
                </div>
                <div class="item">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/feature2.png" />
                    <p>专业教师团队</p>
                    <div class="desc">领域行业专家，兼具丰富的专业知识和强大的授课培训能力</div>
                </div>
                <div class="item">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/feature3.png" />
                    <p>实训课程资源</p>
                    <div class="desc">真实行业案例项目，提供案例源代码和实验指导手册</div>
                </div>
                <div class="item">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/feature4.png" />
                    <p>理论结合实际应用</p>
                    <div class="desc">立足岗位能力标准的提升培训体系</div>
                </div>
            </div>
        </div>
        <!--课程大纲-->
        <div class="course-block">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/stage1.png"  class="img1 img"/>
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/stage2.png"  class="img2 img"/>
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/stage3.png"  class="img3 img"/>
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/stage4.png"  class="img4 img"/>
            <div class="block-title">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/course-title.png" />
                <!-- <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/details-title-img.png" />
                课程大纲与学习安排
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/details-title-img.png" /> -->
            </div>
            <div class="block-main">
                <el-collapse accordion v-model="activeCollapse" >
                    <el-collapse-item
                        :name="item.id" 
                        class="my-collapse-item"  
                        v-for="item in planList" 
                        :key="item.id"
                        :class="{'item-one': item.id === 1, 'item-last': item.id === 9}"
                    >
                        <template slot="title">
                            <span class="stage">
                                {{item.stageNum}}：
                            </span>
                            <span class="name">
                                {{item.stageName}}
                            </span>
                        </template>
                        <div class="info">
                            {{item.describe}}
                        </div>
                        <div class="task-box flex">
                            <div class="zhangjie">
                                <div class="min-card" :class="{'active': chapterList[item.id-1] === index }" v-for="(subitem, index) in item.chapterList" :key="index" @click="clickItem(item.id, index)">
                                    {{subitem.chapter }}
                                </div>
                            </div>
                            <div class="right-box">
                                <div class="txt">
                                    学习任务
                                </div>
                                <p v-for="(ctx, idx) in item.chapterList[chapterList[item.id-1]].taskcontent" :key="idx">
                                    {{ctx}}
                                </p>
                            </div>
                        </div>
                    </el-collapse-item>
                    
                </el-collapse>
            </div>
        </div>
        <!--试听-->
        <div class="listen-block">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/listen-dot-1.png"  class="dots1"/>
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/listen-dot-2.png" class="dots2" />
            <div class="block-title">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/listen-title.png" />
                <!-- <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/details-title-img.png" />
                精品课程试听
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/details-title-img.png" /> -->
            </div>
            <div class="block-main">
                <div class="flex lr-flex tabs">
                    <p @click="changeCorousel(0)" :class="{active: activeListenIndex===0}">视频一：《鸢尾花（Iris）分类模型》</p>
                    <p @click="changeCorousel(1)"  :class="{active: activeListenIndex===1}">视频二：《LDA+SVM构建人脸识别模型》</p>
                </div>
                <el-carousel class="list" arrow="always" height="530px" ref="carousel" :autoplay="false" indicator-position="none" @change="changeCarousel">
                    <el-carousel-item>
                        <div class="carousel-ctx flex lr-flex">
                            <div class="video-wrap">
                                <video 
                                    id="video1" 
                                    src="https://platform-vod.wdcloudnet.com/249a7c9a61c24831ae71ca847c7dadd5/86a9f914199048e2956c396592b102c3-e58988aa1706770a7ce9f78a715906f5-sd.mp4" 
                                    controls="controls" 
                                    poster="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/video1.jpg"
                                    controlslist="nodownload"
                                />
                            </div>
                            <div class="tip-box">
                                <div class="txt">
                                    知识点：
                                </div>
                                <p>
                                    机器学习的一般流程
                                </p>
                                 <p>
                                    数据集拆分的目的
                                </p>
                                <p>
                                   数据集拆分函数的使用 
                                </p>
                                <p>
                                    K-最近邻模型的构建   
                                </p>
                                <p>
                                    模型评估的目的及方法
                                </p>
                                <p>
                                    使用模型预测陌生数据
                                </p>
                                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/listen-info.png" class="bg"/>
                            </div>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <div class="carousel-ctx flex lr-flex">
                            <div class="video-wrap">          
                                <video 
                                    id="video2" 
                                    src="https://platform-vod.wdcloudnet.com/7872c029c7494fd1ac23877f9f66b753/1659ba05f4f14af386d057423f1e7005-913bf4305510a0f3ce50b29e4444a492-sd.mp4" 
                                    controls="controls" 
                                    poster="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/video2.jpg"
                                    controlslist="nodownload"
                                />
                            </div>
                            <div class="tip-box tip-box2">
                                <div class="txt">
                                    知识点：
                                </div>
                                <p>
                                    LDA(线性判别分析)降维算法使用
                                </p>
                                 <p>
                                    PCA(主成分分析)降维算法使用
                                </p>
                                 <p>
                                    LDA与PCA降维效果对比
                                </p>
                                 <p>
                                    LDA与SVM构建人脸识别模型
                                </p>
                                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/listen-info2.png" class="bg"/>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <!--实战案例-->
        <div class="case-block">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/case-l-bg.png" class="right" />
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/case-r-bg.png" class="left" />
            <div class="block-title">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/case-title.png" />
                <!-- <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/details-title-img.png" />
                项目案例实战
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/details-title-img.png" /> -->
            </div>
            <div class="block-main flex">
                <div class="tab-box">
                    <div v-for="(item, index) in caseList" :key="index" @click="activeCaseTab=item.id" class="flex item" :class="{'active': activeCaseTab===item.id}">
                        <p >
                            {{item.txt}}
                        </p>
                         <i  class="el-icon-caret-right" />
                    </div>
                </div>
                <div class="img-wrap">
                    <img :src="curTabImg" />
                </div>
            </div>
        </div>
        <agreement :dialog="dialog" />
        <footers/>
    </div>
</template>

<script>
import planList from './planList.js'
import footers from '../block/front/footers.vue'
import agreement from './agreement.vue'
export default {
  name: 'gongxinDetails',
  show: true,
  props: {
  },
  components: {
      footers,
      agreement
  },
    data() {
        return {
            isFixed: false,
            activeListenIndex: 0,
            activeCaseTab: 1,
            planList: planList,
            chapterList: [0,0,0,0,0,0,0,0,0],
            trainObj:{},
            dialog:{
                show:false,
            },
            caseList: [
                {txt: '案例一', id: 1},
                {txt: '案例二', id: 2},
                {txt: '案例三', id: 3},
                {txt: '案例四', id: 4},
                {txt: '案例五', id: 5},
                {txt: '案例六', id: 6},
                {txt: '案例七', id: 7},
            ],
            video1: null,
            video2: null,
            activeCollapse: 1,
            // purchased: null,
            goodsId: '',
            btnType: ''
        };
    },
    computed: {
        curTabImg() {
            return `https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/case-${this.activeCaseTab}.png`;
        },
    },
    mounted() {
        let that = this;
        window.addEventListener("scroll", function () {
            if(document.documentElement.scrollTop > 220){
                that.isFixed = true
            } else {
                that.isFixed = false;
            }
        })
        this.getGoodsById();
        this.video1 = document.getElementById('video1');
        this.video2 = document.getElementById('video2');
    },
    created() {
        
    },
    beforeDestroy() {
        
    },
    methods: {
        // 时间换算
        dataFormat(msg) {
            if (msg) {
                const m = (msg||'').split(' ')[0].split('-');
                const s = m[0]+'年'+m[1]+'月'+m[2]+'日';
                return s;
            }
        },
        getGoodsById() {
            this.goodsId = this.$route.query.id || sessionStorage.getItem('goodsId');
            this.$api.home.getGoodsById(this.goodsId).then(res=>{
                if (res.data) {
                    this.trainObj = res.data
                    if(this.trainObj.name.length>11){
                        this.trainObj.name = this.trainObj.name.substring(0,11)+'...'
                    }
                    if (localStorage.getItem('token')) {
                        this.checkUserCanByGoods(this.trainObj.id);
                    } else {
                        // this.purchased = true;
                        if (this.trainObj.status==1) {
                            this.btnType='立即报名'
                        } else if (this.trainObj.status==0) {
                            this.btnType='报名已结束'
                        }
                    }
                }
            })
        },
        checkUserCanByGoods(id) {
            this.$api.home.checkUserCanByGoods(id).then(res=>{
                if (res.data) {
                    // this.purchased = res.data;
                    if (this.trainObj.status==1) {
                        this.btnType='立即报名'
                    } else if (this.trainObj.status==0) {
                        this.btnType='报名已结束'
                    }
                } else {
                    this.btnType='立即学习'
                }
            })
        },
        changeCarousel(a) {
            if (a === 1) {
                this.activeListenIndex = 1;
                this.video1.pause()
            } else {
                this.activeListenIndex = 0;
                this.video2.pause()
            }

        },
        jumpStudy() {
            if (this.btnType == '立即学习') {
                if(this.trainObj.goodsType === 1){
                    // this.$router.push(`/course/catalog?id=${this.trainObj.referId}&taskType=${this.trainObj.goodsType}`)
                    this.$router.push({
                                    path:'/course/catalog',
                                    query:{
                                        taskId:this.trainObj.referId,
                                        taskType:this.trainObj.goodsType,
                                        backPath:this.$route.path
                                    }
                                })
                }else if(this.trainObj.goodsType === 2){
                    // this.$router.push(`/learning/taskDetail?id=${this.trainObj.referId}&taskType=${this.trainObj.goodsType}`)
                    this.$router.push({
                                    path:'/learning/taskDetail',
                                    query:{
                                        id:this.trainObj.referId,//任务id
                                        taskType:this.trainObj.goodsType,
                                        backPath:this.$route.path
                                    }
                                })
                }else if(this.trainObj.goodsType === 3){
                    this.$router.push({
                        path:'/assess',
                        query:{
                            assessId:this.trainObj.referId,//测评id
                            refId:this.trainObj.id,
                            refType:2,
                            pathName:this.$route.path
                        }
                    })
                }
            }else {
                if(this.trainObj.isFree){
                    let data = {
                        goodsId:this.trainObj.id,
                        orderSource:1
                    }
                    this.$api.home.placeOrder({data}).then(res => {
                        if(res.code === 0){
                            this.$message({
                                message: '报名成功',
                                type: 'success'
                            })
                            if(this.trainObj.goodsType==1){
                                this.$router.push({
                                    path:'/course/catalog',
                                    query:{
                                        taskId:this.trainObj.referId,
                                        taskType:this.trainObj.goodsType,
                                        // goodsId:this.$route.query.goodsId,
                                        backPath:this.$route.path
                                    }
                                })
                            }else if(this.trainObj.goodsType==2){
                                this.$router.push({
                                    path:'/learning/taskDetail',
                                    query:{
                                        id:this.trainObj.referId,//任务id
                                        // goodsId:this.$route.query.goodsId,
                                        backPath:this.$route.path,
                                        type:this.trainObj.goodsType,
                                    }
                                })
                            }else if(this.trainObj.goodsType === 3){
                                this.$router.push({
                                    path:'/assess',
                                    query:{
                                        assessId:this.trainObj.referId,//测评id
                                        refId:this.trainObj.id,
                                        refType:2,
                                        pathName:this.$route.path
                                    }
                                })
                            }
                        }
                    })
                }else {
                    if (this.btnType == '立即报名') {
                        if (localStorage.getItem('token')) {
                            this.$router.push(`/orderPage?id=${this.trainObj.id}&dymode=1`)
                            sessionStorage.removeItem("dialogState")
                            sessionStorage.removeItem("typeState");
                            sessionStorage.removeItem("qrcodeState");
                        } else {
                            this.$router.push({
                                path: '/login',
                                query: {
                                    name: 'gongxinDetails',
                                    type: 'order',
                                    id: this.trainObj.id,
                                    referId: this.trainObj.referId
                                }
                            })
                        }
                    }
                }
                
            }
        },
        clickItem(id, index) {
            this.$set(this.chapterList, id-1, index);
        },
        changeCorousel(val) {
            this.activeListenIndex = val;
            this.$refs.carousel.setActiveItem(val);
            if (val == 1) {
                this.video1.pause();
            } else {
                this.video2.pause();
            }
        },
    }
};
</script>
<style lang="stylus">
</style>
<style lang="stylus" scoped>
@import '../asset/css/gongxinDetails';
.gongxintDetails {
    .listen-block {
        /deep/.el-carousel__container {
            width: 1100px;
            margin: 0 50px;
        }
    }
    /deep/.el-carousel__container {
        .el-carousel__arrow {
                content: none;
                background-color: rgba(31,45,61,0);
                width: 21px;
                height: 41px;
                background-repeat: no-repeat;
                background-position: center 0px;
                background-size: cover;
                i {
                    display: none;
                }
            }
            .el-carousel__arrow--left {
                background-image: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/arrorl.png');
                left: -50px;
            }
            .el-carousel__arrow--right {
                background-image: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/arrorr.png');
                right: -50px;
            }
        }
        /deep/.el-carousel__indicators--horizontal {
            display: none;
        }
}
</style>
