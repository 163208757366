<template>
 <div class='guarantee'>
    <h2 class="guarantee-titile">我们的服务保障</h2>
    <div class="guarantee-bgc"></div>
    <p class="guarantee-con">为您提供全方位服务支持</p>
    <div class="guarantee-list">
        <div class="list-item" v-for="(item, index) in support" :key="index">
            <img class="list-item-img" :src="item.imgUrl" alt="">
            <h3 class="list-item-title">{{item.title}}</h3>
            <p class="list-item-con">{{item.content}}</p>
        </div>
    </div>
 </div>
</template>

<script>
export default {
    name:'guarantee',
    props: {
    },
    data () {
        return {
            support: [
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/fuwu1.png',
                    title: '数据安全保密',
                    content: '拥有公安部信息系统安全等级保护三级资质，平台基于云计算模式，数据进行安全加密技术，有效保护您企业的数据和使用'
                },
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/fuwu2.png',
                    title: '首席学习官',
                    content: '培养企业内部首席学习官，掌握企业培训管理知识，助力企业线上学习项目，推动组织发展和人才储备'
                },
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/fuwu3.png',
                    title: '高效服务团队',
                    content: '项目服务团队核心来自浙江大学；技术团队来自阿里、华为等名企。24小时标准化运营服务流程，专职客服全方位服务客户'
                },
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/fuwu4.png',
                    title: '线上代运营',
                    content: '全年5向线上代运营服务，协助企业HR高效落地培训工作，推动企业打造学习型组织建设'
                }
            ]
        };
    },

    methods: {},

    mounted(){
    },
}

</script>
<style lang='stylus' scoped>
    @import "../../asset/css/guarantee.styl"
</style>