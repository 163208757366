<template>
    <div class="agreement">
        <el-dialog
            class="readdialog"
            title="人工智能应用管理师（中级）职业能力等级评价项目培训协议"
            :visible.sync="dialog.show"
            top="70px"
            width="880px"
            center
        >
            <div class="privacy-box">
                <!-- <h2 class="yinyan">引言</h2> -->
                <p>合同编号：</p>
                <p>甲  方（培训方）：北京伟东凌鸿教育科技有限公司	</p>
                <p>乙  方（学员方）：  </p>
                <p>学员经向甲方咨询，并对甲方提供的课程之学习方法及其他事项进行了全面的了解及认可后，自愿与甲方签订本课程培训协议。甲方将向学员提供在线视频及直播课程等形式的教学服务。</p>
                <p class="title2">一、培训课程内容介绍</p>
                <p>
                1. 项目及培训课程名称：人工智能应用管理师（中级）
                <br />2. 证书名称：人工智能应用管理师（中级）
                <br />3. 培训课程时间：60天（自然日）
                <br />4. 培训课程课时：108课时（45分钟/课时）
                <br />5. 培训课程科目：Python数据分析与可视化、AI数学基础、机器学习原理与实战、深度学习原理与实战、计算机视觉（CV）、自然语言处理（NLP）
                <br />6. 培训课程及考试报名费用：共计 6800元（大写：陆千捌佰元整）。
                <br />7. 具体明细：课时费 5000元，平台使用费1700元，考试报名费：100元
                </p>
                <p class="title2">二、个人信息的定义与范围</p>
                <p>
                    1. 课程周期为60天（自然日）；
                    <br />2. 课时消耗的计算方式为，付款60天内（包含60天）按使用天数进行等比计算，例：账号开通日起算使用20天，课时消耗为108*1/3=36课时；
                    <br />3. 平台使用费：自账号开通之日起，平台费用即时消耗；
                    <br />4. 考试报名费：成功报考即时消耗（报考未参加，参加未通过均视为消耗不予退还）。
                    <br />5. 若达到或超过60天仍未取得人工智能应用管理师（中级）证书，则该课程学习时间至多可延长至一年。
                </p>
                <p class="title2">三、退费说明</p>
                <p>学员应全程完成培训，因自身原因单方中止培训的，按如下规则办理退费申请：</p>
                <p>1. 学员报名培训课程并缴费成功且尚未实际消耗课时的七日内，扣除平台使用费1700元，可退回剩余全部培训课时费，未报考的考试报名费100元一并退还；</p>
                <p>2. 学员报名7日后30日（不含30日）内申请退费，扣除平台使用费1700元，可退回未消耗的课时费用（课时消耗参见“课时使用和消耗的说明”），未报考考试的考试报名费100元一并退还；</p>
                <p>3. 学员报名30日（含30日）以上不受理退费。若未报名考试则可退还100元考试报名费。</p>
                <p class="title2">四、教学管理</p>
                <p>1. 鉴于培训课程的特点，培训期间，甲方有权根据教学计划和进度对课程和考试的安排进行重新调整、修改并通知学员；设定职业能力等级评价的形式及内容，在做出变更评价形式及内容后通知学员。</p>
                <p>2. 学员需努力掌握培训的相关知识，按甲方提示的进度完成课程学习，配合作业或实训内容以达到培训的目的。</p>
                <p>3. 学员应服从甲方教务管理人员的学习安排，遵守甲方的各项教学及平台使用的管理制度。</p>
                <p>4. 学员需执行保密制度，不得将培训期间所学课程的内容、账号密码等外泄或传播第三方；不得私自录制、传播培训课程内容。</p>
                <p>5. 学员应真实、完整地填写入学申请表上所需的各项内容。个人资料如有变动，请及时主动与甲方联系，未及时联系所产生的不良后果由学员自行承担。</p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialog.show = false" style="border-radius 22px ">好的，我已阅</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name:'agreement',
    props:{
        dialog:{
            type:Object,
            default:()=>{}
        }
    }
}
</script>
<style scoped lang="stylus">
.readdialog {
  /deep/ .el-dialog {
    height: 800px;
  }

  /deep/ .el-dialog__header {
    padding: 20px;
    font-size: 16px;
    background: #F8FAFF;
    font-weight: 600;
    border-radius 20px 20px 0 0 
  }

  /deep/ .el-dialog__footer {
    background: #F8FAFF;
    padding: 20px;
    border-radius 0 0 20px 20px
  }

  /deep/ .el-dialog__title{
    font-size 22px
  }

  /deep/ .el-dialog__body{
    padding 0 20px
  }

  /deep/ .el-button{
    padding: 0px 20px;
    border-radius: 22px;
    width: 158px;
    height: 36px;
  }

  .privacy-box{
    height 660px
    margin-bottom 20px
    overflow auto
  }

  .privacy-box h1, .privacy-box h2 {
    text-align: center;
    margin 20px 0
  }

  .privacy-box h2 {
    text-align: left;
  }

  .privacy-box p {
    color: #666;
    text-align: justify;
    font-size 16px
    line-height 30px
  }

  .privacy-box .yinyan {
    text-align: center;
    margin-bottom 20px
  }

  .privacy-box .title2 {
    font-weight: bold;
    color: #000;
    text-indent: 0px;
    margin 10px 0 2px 0
  }
}
</style>