<template>
    <div class="result-list">
        <div class="result-title">养老护理员培训考核结果公示</div>
        <div class="result-form">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="年度">
                <el-date-picker
                v-model="formInline.year"
                :editable="false"
                type="year"
                placeholder="请选择年度">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="省/市/区">
                <el-select
                    v-model="formInline.regionCode"
                    filterable
                    remote
                    clearable
                    reserve-keyword
                    placeholder="请输入机构所属地区"
                    :remote-method="remoteMethod"
                    :loading="loading">
                    <el-option
                    v-for="item in filterOptions"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="姓名">
                <el-input v-model="formInline.userName" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="养老机构名称">
                <el-input v-model="formInline.oldAgeInstitutionName" placeholder="请输入养老机构名称"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
        </el-form>
        </div>
       <div class="result-table">
            <el-table
                :data="resultList"
                style="width: 100%">
                <el-table-column
                fixed
                prop="regionName"
                label="省/市/区"
                width="200">
                </el-table-column>
                <el-table-column
                prop="oldAgeInstitutionName"
                label="养老机构名称"
                width="200">
                </el-table-column>
                <el-table-column
                prop="userName"
                label="姓名"
                width="120">
                </el-table-column>
                <el-table-column
                prop="grade"
                label="成绩"
                width="120">
                </el-table-column>
                <el-table-column
                prop="certificateName"
                label="证书名称"
                width="300">
                </el-table-column>
                <el-table-column
                prop="obtainTime"
                label="发证日期"
                >
                </el-table-column>
            </el-table>
       </div>
        <div class="result-pagination">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="paging.params.pageNum"
            :page-sizes="[10, 20, 30, 40, 50]"
            :page-size="this.paging.params.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="paging.total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
// import $ from 'jquery';
export default {
    name:'resultList',
    data(){
        return {
            areaList:[],
            filterOptions:[],
            loading:false,
            formInline: {
                year: '',
                regionCode: '',
                oldAgeInstitutionName:'',
                userName:''
            },
            resultList:[],
            paging: {
                params: {
                    pageNum: 1,
                    pageSize: 10
                },
                total: 0
            }
        }
    },
    mounted(){
        this.getTree()
        this.getData()
    },
    methods:{
        onSubmit(){
            this.paging.params.pageNum = 1;
            this.getData();
        },
        //获取城市列表
        getTree(){
                return this.$api.yanglaoresult.getTree().then(res => {
                    if(res.code==0){
                        this.areaList = res.data;
                        this.filterOptions = this.areaList;
                    }
                    // res.data.forEach(item => {
                        // const L1Name = item.name
                        // item.children && item.children.length && item.children.forEach(el => {
                        //     const L2Name = el.name
                        //     if(!el.children){
                        //         this.areaList.push({
                        //             name: `${L1Name}-${L2Name}`,
                        //             code: el.code,
                        //             id: el.id
                        //         })
                        //     }else if(el.children.length){
                        //         el.children.forEach(v => {
                        //             const L3Name = v.name
                        //             this.areaList.push({
                        //             name: `${L1Name}-${L2Name}-${L3Name}`,
                        //             code: v.code,
                        //             id: v.id
                        //             })
                        //         })
                        //     }
                        // })
                    // })
                })
        },
        remoteMethod(query,code){
            if (query !== '') {
            setTimeout(() => {
                this.filterOptions = this.areaList.filter(item => {
                    return item.name.includes(query)
                });
            }, 200);
            } else {
            this.filterOptions = this.areaList.filter(item => {
                return item.code == code
            })
            }
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.paging.params.pageSize = val;
            this.getData();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.paging.params.pageNum = val;
            this.getData();
        },
        getData(){
                const params = {
                    pageNum: this.paging.params.pageNum,
                    pageSize: this.paging.params.pageSize,
                }
                const data = {
                    year: this.formInline.year?this.formInline.year.getFullYear()+'':"",
                    regionCode: this.formInline.regionCode,
                    oldAgeInstitutionName: this.formInline.oldAgeInstitutionName,
                    userName: this.formInline.userName
                }
                this.$api.yanglaoresult.findByCondition({params,data}).then(res => {
                    if(res.code==0){
                        this.resultList = res.data.list;
                        this.resultList.forEach((item)=>{
                            item.obtainTime = item.obtainTime.substring(0, 10);
                        })
                        this.paging.total = res.data.total;
                        this.paging.params.pageNum = res.data.pageNum;
                    }
                });
        }
    }
}
</script>
<style lang="stylus" scoped>
.result-list{
    width:1200px;
    margin:50px auto;
    .result-title{
        font-size :24px;
        color:#000000;
        font-weight :600;
        text-align :center;
        margin :20px;
    }
    .result-form{
        margin :0 0 20px;
    }
    .result-table{
        margin :0 0 20px;
    }
    .result-pagination{
        margin :0 0 20px;
        text-align :center;
    }
    >>>.el-input__inner,>>>.el-input__icon{
        cursor :pointer;
    }
}
</style>