let RouterArr = [
  // {
  //   name: 'home',
  //   path: '/',
  //   meta: { title: '首页', icon: 'md-apps', show: true, roles: [] },
  //   component: () => import(/* webpackChunkName: "home" */  '../view/home/index.vue'),
  // }
]
const Routers = require.context('./../modules/', true, /\.router\.js$/)
Routers.keys().forEach(key => {
  RouterArr = [
    ...RouterArr,
    ...Routers(key).default
  ]
})
RouterArr.sort((a, b) => {
  return a.meta.weights - b.meta.weights
})
RouterArr.forEach(item => {
    item.meta.highlight = item.path
})

sessionStorage.setItem('navigate', JSON.stringify(RouterArr))
export default [
  ...RouterArr,
  // {
  //   path: '/401',
  //   name: 'error_401',
  //   component: () => import(/* webpackChunkName: 'error' */ '../view/error/401.vue'),
  // },
  // {
  //     path: '/500',
  //     name: 'error_500',
  //     component: () => import(/* webpackChunkName: 'error' */ '../view/error/500.vue'),
  // },
  // {
  //     path: '*',
  //     name: 'error_404',
  //     component: () => import(/* webpackChunkName: 'error' */ '../view/error/404.vue'),
  // },
]
