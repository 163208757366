<template>
  <div class="exam-detail-page m-container">
    <div class="exam-detail clearfix">
      <div class="backButton">
        <span class="btn" @click="goBack">
          <i class="back el-icon-arrow-left"></i>
          <!-- <img src="../asset/images/_back.png"/> -->
          返回</span
        >
        <span class="name">{{ mustRead.name }}</span>
      </div>
      <div class="exam-content">
        <h1 class="titles">考前必读</h1>
        <div class="list" v-html="mustRead.notes">{{ mustRead.notes }}</div>
        <div class="read">
          <el-form
            v-if="read"
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
          >
            <el-form-item prop="read">
              <el-checkbox-group v-model="ruleForm.read">
                <el-checkbox
                  label="我已阅读"
                  name="read"
                  @change="onRead"
                ></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </div>
        <div
          class="btn"
          :class="startExam ? 'active' : ''"
          @click="enterExamination('ruleForm')"
        >
          开始考试{{ timeContent }}
        </div>
      </div>
    </div>
    <el-dialog
      class="warning-dialog"
      :visible.sync="warningdialogVisible"
      width="400px"
    >
      <img src="../asset/images/prohibit.png" class="icon-img" alt="">
      <p class="titles">提示</p>
      <p class="text">您已超过最迟开考时间！</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 100%" @click="returnDetail"
          >知道了</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import components from "./../config/vueBlock";
export default {
  name: "resdExam",
  show: true,
  components,
  data() {
    return {
      mustRead: {},
      read: false,
      startExam: false,
      ruleForm: {
        read: "",
      },
      rules: {
        read: [
          { required: true, message: '请选择"我已阅读"', trigger: "change" },
        ],
      },
      //taskType为1，则为从培训任务跳过来的
      taskType: this.$route.query.taskType || null,
      businessId: this.$route.query.businessId,
      examId: this.$route.query.examId,
      // 入口
      source: this.$route.query.source,
      countDown: 5,
      timeContent: "",
      flag: true,
      warningdialogVisible: false,
    };
  },
  mounted() {
    this.findBasicExamById();
    console.log("this.$route.query.taskType", this.$route.query.taskType);
  },
  created() {},
  beforeDestroy() {},
  methods: {
    // 迟到返回详情页
    returnDetail() {
      if (this.source == 'examDetails') {
        this.returnPath = {
          path:'/examDetails',
          query:{
            bussinessId: this.$route.query.businessId, 
            examId: this.examId, 
            type: this.taskType,
            dymode: this.$route.query.dymode,
            title: sessionStorage.getItem("examDetailsTitle"),
            timeSlot: sessionStorage.getItem("examDetailsTimeSlot")
          }
        }
      } else if (this.source == 'coursecatalog') {
        this.returnPath = {
          path:'/course/catalog',
          query:{
            id:this.$route.query.businessId,
            taskType: this.taskType,
            dymode: this.$route.query.dymode
          }
        }
      }
      this.$router.push(this.returnPath);
    },
    // 倒计时
    setFrameTime() {
      let num = 5;
      this.knowTime = setInterval(() => {
        num -= 1;
        this.countDown = num;
        this.timeContent = "(" + this.countDown + "s)";
      }, 1000);
    },
    // 返回
    goBack() {
      this.$router.go(-1);
      // this.$router.push({
      //     path:'/course/catalog',
      //     query:{
      //         id:this.businessId
      //     }
      // })
    },
    // 开始考试
    enterExamination(formName) {
      if (this.flag) {
        if (this.countDown < 1) {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              this.flag = false;
              this.joinExam();
            } else {
              console.log("error submit!!");
              return false;
            }
          });
        }
      }
    },
    joinExam() {
      this.$api.examination
        .joinExam(this.businessId, this.taskType, this.examId)
        .then((res) => {
          this.flag = true;
          if (res.data && res.data != "null") {
            // 是否迟到(true:是)
            if (res.data.beLate) {
              this.warningdialogVisible = true;
            } else {
              this.$router.push({
                path: "/course/testPaper",
                query: {
                  userPaperId: res.data.userPaperId,
                  businessId: this.businessId,
                  examId: this.examId,
                  taskType: this.taskType,
                  dymode: this.$route.query.dymode,
                  source: this.source
                }
              });
              // 考试允许用时
              sessionStorage.setItem('secondsExam',JSON.stringify(res.data.seconds));
              // 允许交卷时长(单位:分;0:无,默认值0)
              sessionStorage.setItem('allowCommitDurationExam',JSON.stringify(res.data.allowCommitDuration));
              Vue.prototype.resetSetItem("signOutStorage", "login");
              sessionStorage.removeItem("remainingTime");
              sessionStorage.removeItem("questionsList");
            }
          }
        });
    },
    onRead(val) {
      if (val) {
        this.startExam = true;
      } else {
        this.startExam = false;
        this.ruleForm.read = "";
      }
    },
    // 获取考试基础信息
    findBasicExamById() {
      this.$api.examination.findBasicExamById(this.examId).then((res) => {
        if (res.data && res.data != "null") {
          this.mustRead = res.data;
          this.timeContent = "(" + this.countDown + "s)";
          this.setFrameTime();
          setTimeout(() => {
            this.read = true;
            (this.timeContent = ""), clearInterval(this.knowTime);
          }, 6000);
        }
      });
    },
  },
};
</script>
<style lang="stylus">
.el-checkbox__label {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.el-form-item {
  margin-bottom: 0;
}

.el-form-item__content {
  line-height: 1;
}

.el-form-item__error {
  position: static;
}
</style>
<style lang="stylus" scoped>
@import '../asset/resdExam.styl';
</style>
