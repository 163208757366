<template>
  <div class="onlyCourseGoodsBg">
    <div class="moreCourseGoodsCont">
       <el-row>
        <el-col :span="24">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/courseGoods' }">课程</el-breadcrumb-item>
            <el-breadcrumb-item>课程列表</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <div class="courseGoodsListCont">

         <div class="courseGoodsList" v-if="courseListArray.length && !Array.isArray(courseListArray[0])">
            <div class="courseGoodsListItem"  v-for="(item, idx) in  courseListArray" :key="idx"  @click="jumpGoodsDetailHandle(item)">
              <div class="goodsPoster">
                <img :src="item.picUrl"/>
              </div>
              <div class="goodsProduceInfor">
                <div class="produceTitle">
                  <span class="courseHotIcon pinkHotIcon fl"  v-if="item.taskType == 1">必修课</span>
                  <span class="courseHotIcon greenHotIcon  fl"  v-if="item.taskType == 2">选修课</span>
                  <span class="courseHotIcon blueHotIcon fl"  v-if="item.taskType == 3">公开课</span>
                  <p class="textCut fl">{{item.name}}</p>
                </div>               
                <div class="goodsPrice" v-if="item.isFree"><span class="normalPrice">免费</span></div>
                <div class="goodsPrice" v-else><span class="normalPrice">¥{{item.salePrice}}</span><span class="discountPrice" v-if="item.basePrice">¥{{item.basePrice}}</span></div>
                <div class="nowSignUp">
                    <span class="signUpNum">{{item.purchaseNo}}人已报名</span>
                    <span class="nowLearnBtn">立即报名</span>           
                </div> 
              </div>
            </div>

         </div>
         <div v-if="paging.currentSize > 0 && (paging.currentSize <  paging.params.pageSize)" class="daodilaTips">到底啦，更多内容即将上线</div>
         <paging class="paging" :paging="paging"  v-if="paging.totalPages > 1" />
         <div class="nothingTips" v-if="!paging.total && !courseListArray.length">还没有课程哟～ </div>
      </div>
    </div>
  </div>
</template>

<script>
import paging from "../../../layout/paging";
export default {
  name: 'moreCourseGoods',
  components: {
    paging,
  },
  data () {
    return {
          courseListArray: [[]],
          paging: { 
              params: {
                  pageNum: 1,
                  pageSize:  Number(this.$route.query.pageSize) || 20
              },
              total: 0,
              totalPages: 0,
              currentSize: 0,
          },
    }
  },
  filters: {


  },
  created () {
    this.theme = localStorage.getItem('theme');
  }, 
  computed: {

  },
  mounted() {
    this.getData()

  },

  beforeDestroy() {
    
  },

  methods: {
      getData() {
      this.$api.coursegoods.findByCondition(
          {
              pageNum: this.paging.params.pageNum,
              pageSize:  this.paging.params.pageSize,
              categoryCodes: this.$route.query.categoryCode,
              name: this.$route.query.name,
              type: this.$route.query.type,
              goodsIds:this.$route.query.goodsIds,// 商品id，多个逗号隔开

          }
        )
        .then(res=>{
            if (res.data && res.data.list) {
              this.courseListArray = res.data.list;

              this.paging.total = res.data.total
              this.paging.totalPages = res.data.pages
              this.paging.currentSize = res.data.size

            } else {
                this.$message.error(res.message);
            } 
        })     

      },

      jumpGoodsDetailHandle(item) {
          this.$router.push({
              path: '/courseGoodsDetail',
              query: {
                  goodsId: item.id,
              }
          })   

      },

  },

};
</script>

<style lang="stylus" scoped>
 @import "../asset/css/courseGoods.styl"
</style>
