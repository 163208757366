<template>
    <div class="certificate-main certificate" :class="{yingjiCertificate:theme === 'yingji'}">
        <input type="text" style="display:none">
        <input type="password" style="display:none">
        <div class="certificate-title">
            <div class="certificate-header">
                <p>个人中心</p>
            </div>
        </div>
        <div class="certificate-content" ref="certificateContent">
            <div class="avatar">
                <div class="avatarIcon">
                    <img v-if="hasSettedAvatarUrl" :src="hasSettedAvatarUrl" alt="">
                    <img v-else-if="theme === 'wanghong'" src="@assets/wanghong.png" alt="">
                    <img v-else src="@assets/userL.png" alt="">
                </div>
                <div class="name">
                    {{name}}
                </div>
                <!-- <el-button class="uploadBtn" @click="uploadAvatarDialog = true" type="primary" plain>{{hasSettedAvatarUrl?'更换头像':'上传头像'}}</el-button> -->
                <div class="uploadBtn" @click="uploadAvatarDialog = true">{{hasSettedAvatarUrl?'更换头像':'上传头像'}}</div>
            </div>
             <el-tabs :tab-position="'left'" v-model="activetab" style="height: 200px; width:1200px;" class="outerTabs" @tab-click="changetab" :before-leave="stopChange?function(){return false}:function(){return true}">
                <el-tab-pane label="我的资料" name="我的资料" v-if="configArr.is_show_my_profile">
                     <span slot="label" class="list-title">
                        <img v-if="activetab === '我的资料'" src="./../asset/images/2.png" alt="">
                        <img v-else src="./../asset/images/1.png" alt="">
                        我的资料
                    </span>
                    <personalInfo v-if="activetab === '我的资料'" ></personalInfo>
                </el-tab-pane>
                <el-tab-pane label="身份认证" name="身份认证">
                     <span slot="label" class="list-title">
                        <img v-if="activetab === '身份认证'" src="./../asset/images/3.png" alt="">
                        <img v-else src="./../asset/images/4.png" alt="">
                        身份认证
                    </span>
                    <identify v-if="activetab === '身份认证'"></identify>
                </el-tab-pane>
                <el-tab-pane label="我的证书" name="我的证书" v-if="configArr.is_show_my_certificate">
                     <span slot="label" class="list-title">
                        <img v-if="activetab === '我的证书'" src="./../asset/images/certificate-icon.png" alt="">
                        <img v-else src="./../asset/images/ic_mine_wodechengjiu@2x.png" alt="">
                        我的证书
                    </span>
                    <template v-if="activetab === '我的证书'">
                        <ul class="certiUl">
                            <li class="certili" v-for="(item,index) in trainList" :key="index">
                                <div class="item-left">
                                    <img :src="item.url" alt="证书" v-if="item.url !=null">
                                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/placeholder.png" alt="证书" v-else>
                                </div>
                                <div class="item-right">
                                    <div class="item-right-top">
                                        <p class="certificate-name">{{item.businessName}}</p>
                                        <p class="certificate-issuedBy">{{item.issuedBy}}</p>
                                    </div>
                                    <div class="item-right-bottom">
                                        <p class="certificate-time">
                                            <span>获证时间：</span>
                                            <span>{{item.certifiedTime.substring(0,10)}}</span>
                                        </p>
                                        <div class="certificate-button">
                                            <el-button  type="primary" class="certificateButton" @click="checkCertificate('id',item.id,'btn','',index)">查看证书</el-button>
                                            <el-button  type="primary" plain  @click="downLoadCer('id',item.id,item.userName,item.businessName,index)">下载</el-button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="nodata-tip" v-if = "trainList.length == 0">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/nodatacer.png" alt="">
                            <p>你还没有证书哟～</p>
                        </div>
                    </template>
                </el-tab-pane>
                <el-tab-pane label="我的订单" name="我的订单" v-if="configArr.is_show_my_order">
                     <span slot="label" class="list-title">
                        <img v-if="activetab === '我的订单'" src="./../asset/images/ic_mine_orders@2x.png" alt=""/>
                        <img v-else src="./../asset/images/ic_mine_ordershui@2x.png" alt=""/>
                        我的订单
                    </span>
                    <template v-if="activetab === '我的订单'">
                        <invoices-form  v-if="hasOrder && applyInvoice" @myOrderList="myOrderList" :applyInfo="applyInfo" @applyInvoiceShow="applyInvoiceShow"></invoices-form>
                        <ul class="orderul" v-if="hasOrder && !applyInvoice">
                            <li class="orderli" v-for="(order,index) in orderlist" :key="index">
                                <!-- 0 未支付；1 支付中；2 已支付；8 已关闭；9 已取消 -->
                                <img v-if="order.status === 2" class="paysuccess" src="../asset/images/paysuccess@3x.png" alt="">
                                <img v-if="order.status === 8 || order.status === 9" class="paysuccess" src="../asset/images/closepay.png" alt="">
                            <img v-if="order.status === 0" class="paysuccess" src="../asset/images/waitpay.png" alt="">
                                <div class="ordertop">
                                    <span class="number">订单编号：{{order.orderNo}}</span>
                                    <span class="ordertime">下单时间：{{order.orderTime}}</span>
                                </div>
                                <div class="orderCurseInfo" v-for="(item, ind) in order.goodsList" :key="ind">
                                    <!-- 工信原图片链接字段：trainPicUrl -->
                                    <img class="orderCourseImg" :src="item.picUrl" alt="" @click="goDetails(item.id)">
                                    <div class="orderCurseRight">
                                        <h3  @click="goDetails(item.id)">{{item.name}}</h3>
                                        <div class="bottom-order">
                                            <p>
                                                实付金额：<span>￥{{order.realPay}}</span>
                                            </p>
                                            <div class="bottom-order-right">
                                                <p class="daoji" v-if="order.status==0">请在<span>{{order.left_time}}</span>内完成支付</p>
                                                <button @click="handleApply(order.id,index,order.realPay,item.name,order.time)"  class="applyBtn  itemBtn" v-if="order.invoiceStatus == 1 && order.status == 2 && order.realPay != 0">申请开票</button>
                                                <button @click="handlePreview(order.invoiceId)" class="previewBtn itemBtn" v-else-if="order.invoiceStatus== 2 && order.status ==2">查看开票</button>
                                                <button @click="goPayment(order.goodsList[0].id)" class="payBtn itemBtn" v-if="order.status==0">马上支付</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="nodata-tip" v-if="!hasOrder">
                            <img src="./../asset/images/noOrder@3x.png" alt="">
                            <p>还没有订单，赶快去购课吧～</p>
                        </div>
                    </template>
                </el-tab-pane>
                <el-tab-pane label="我的学习" name="我的学习" v-if="configArr.is_show_my_study">
                     <span slot="label">
                        <div v-if="themeContent !== 'junmin'" class="list-title">
                            <img v-if="activetab === '我的学习'" src="./../asset/images/3.png" alt="">
                            <img v-else src="./../asset/images/4.png" alt="">
                            我的学习
                        </div>
                        <div v-if="themeContent == 'junmin'" class="list-title">
                            <img v-if="activetab === '我的学习'" src="./../asset/images/junmin_study_light.png" alt="">
                            <img v-else src="./../asset/images/junmin_study.png" alt="">
                            我的学习
                        </div>
                    </span>
                    <learning v-if="activetab === '我的学习'"></learning>
                </el-tab-pane>
                <el-tab-pane label="我的测评" name="我的测评" v-if="configArr.is_show_my_evaluation">
                    <span slot="label" class="list-title">
                        <img v-if="activetab === '我的测评'" src="./../asset/images/ic-cs-xz@2x.png" alt="">
                        <img v-else src="./../asset/images/ic-cs@2x.png" alt="">
                        我的测评
                    </span>
                    <evaluation v-if="activetab === '我的测评'"></evaluation>
                </el-tab-pane>
                <el-tab-pane label="账号安全" name="账号安全">
                     <span slot="label" class="list-title">
                        <img v-if="activetab === '账号安全'" src="./../asset/images/safeactive.png" alt=""/>
                        <img v-else src="./../asset/images/safe.png" alt=""/>
                        账号安全
                    </span>
                    <accountSafe v-if="activetab === '账号安全'"></accountSafe>
                </el-tab-pane>
            </el-tabs>
        </div>
        <auth-guide :ModalVisbileAuth="authGuideModalVisbile" @handleCloseAuth="handleCloseAuth" :wechatErInfo="previewInfoImg"></auth-guide>
        <preview-certificate :previewModalVisbile="previewModalVisbile" @handleClosePreview="handleClosePreview" :isDownLoad="isDownLoad" :previewUrl="previewUrl"></preview-certificate>
        <!-- 发票详情 -->
        <el-dialog
            title=""
            :visible.sync="invoiceOuterVisible"
            class="invoiceDialog"
            width="480px"
            :before-close="handleClose">
             <div class="info-list">
                <p class="info-title">查看开票申请<span>{{formInfo.invoicesTxt}}</span></p>
                <div class="info-item">
                    <span>快递单号：</span>
                    <span>{{formInfo.expressNumber || ''}} {{formInfo.expressCompany || ''}}</span>
                </div>
                <div class="info-item">
                    <span>发票类型：</span>
                    <span>{{formInfo.invoiceType==1?'普通发票':'增值税专用发票'}}</span>
                </div>
                <div class="info-item">
                    <span>开票金额：</span>
                    <span class="money-num">￥{{formInfo.invoiceAmount}}</span>
                </div>
                <div class="info-item">
                    <span>发票内容：</span>
                    <span>{{formInfo.invoiceContent}}</span>
                </div>
                <div class="info-item">
                    <span>发票抬头：</span>
                    <span>{{formInfo.invoiceTitle}}</span>
                </div>
                <div class="info-item" v-if="formInfo.invoiceTitleType==1">
                    <span>单位税号：</span>
                    <span>{{formInfo.taxNumber}}</span>
                </div>
                <div class="info-item" v-if="formInfo.invoiceType==2">
                    <span>开户银行：</span>
                    <span>{{formInfo.bankName}}</span>
                </div>
                <div class="info-item" v-if="formInfo.invoiceType==2">
                    <span>银行账号：</span>
                    <span>{{formInfo.bankAccount}}</span>
                </div>
                <div class="info-item" v-if="formInfo.invoiceType==2">
                    <span>注册电话：</span>
                    <span>{{formInfo.registPhone}}</span>
                </div>
                <div class="info-item" v-if="formInfo.invoiceType==2">
                    <span>注册地址：</span>
                    <span>{{formInfo.registAdress}}</span>
                </div>
                <div class="info-item">
                    <span>申请时间：</span>
                    <span>{{formInfo.applyTime}}</span>
                </div>
                <div class="info-item">
                    <span>收件人姓名：</span>
                    <span>{{formInfo.recipientName}}</span>
                </div>
                <div class="info-item">
                    <span>手机号码：</span>
                    <span>{{formInfo.recipientPhone}}</span>
                </div>
                <div class="info-item">
                    <span>收件地址：</span>
                    <span>{{formInfo.recipientAddress}}</span>
                </div>
                <div class="info-item">
                    <span>邮箱地址：</span>
                    <span>{{formInfo.recipientEmail}}</span>
                </div>
            </div>
        </el-dialog>
        <!-- 头像上传 -->
        <el-dialog
            title="上传头像"
            class="uploadAvatarDialog"
            :visible.sync="uploadAvatarDialog"
            :close-on-click-modal="false"
            width="480px"
            :before-close="cancelUploadAvatar">
            <div class="dialogBody" v-loading="uploadAvatarLoading"  element-loading-text="正在上传">
                <el-upload
                    class="avatar-uploader"
                    ref="avatarUploader"
                    :action="action"
                    :headers="headers"
                    :show-file-list="false"
                    :before-upload="handleAvatarBefore"
                    :on-success="handleAvatarSuccess"
                    :on-error="handleAvatarError">
                    <img v-if="avatarUrl || hasSettedAvatarUrl" :src="avatarUrl || hasSettedAvatarUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div v-if="avatarUrl || hasSettedAvatarUrl" class="preView">
                    <div class="imgWrapper">
                        <img :src="avatarUrl || hasSettedAvatarUrl" alt="">
                    </div>
                    <div class="txt">头像预览</div>
                </div>
            </div>
            <span slot="footer" class="dialogFooter">
                <el-button @click="cancelUploadAvatar">取 消</el-button>
                <el-button type="primary" @click="confirmUploadAvatar">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
/* eslint-disable */
import config from '@/config/url'
import cercertificateIsShowStatus from '@/utils/certificateMixin'
import personalInfo from './personalInfo'
import identify from './identify'
import invoicesForm from './invoicesForm.vue'
import evaluation from './evaluation.vue'
import learning from './myLearning.vue';// 我的学习
import accountSafe from './accountSafe'//账号安全
export default {
    name:'personalCenter',
    data(){
        return{
            themeContent:'',
            configArr:{
                is_show_my_profile:true,
                is_show_my_certificate:true,
                is_show_my_task:true,
                is_show_my_study:true,
                is_show_my_order:true,
                is_show_my_evaluation:true
            },
            stopChange:false,
            uploadAvatarLoading:false,
            action:config.API + '/resource/v1/resource/uploadImage',
            headers:{
                token:localStorage.getItem('token')
            },
            // hasSettedAvatarUrl:'',
            avatarUrl:'',
            uploadAvatarDialog:false,
            formInfo:{
              invoiceStatus:1,
              invoicesTxt:''
            },
            trainList:[],
            isDownLoad:false,
            contentHeight:0,
            activetab: '我的资料',
            orderlist: [],
            hasOrder: true,
            timer:null,
            applyInvoice:false,
            invoiceOuterVisible:false,
            applyInfo:{
                invoiceAmount:'',
                invoiceContent:'',
            }
        }
    },
    mixins:[cercertificateIsShowStatus],
    created() {
        this.initConfig()
        this.getData();
        this.myOrderList();
        this.contentHeight = window.innerHeight - 80 - 76;
    },
    destroyed(){
        clearInterval(this.timer);
    },
    components:{
        invoicesForm,
        personalInfo,
        identify,
        evaluation,
        learning,
        accountSafe
    },
    computed:{
        theme(){
            return this.$store.state.theme
        },
        name(){
            return this.$store.state.subUserInfo.name
        },
        hasSettedAvatarUrl(){
            return this.$store.state.subUserInfo.headImage
        }
    },
    mounted(){
        this.themeContent = localStorage.getItem('theme')
        //根据保存的tab返回相应的tab
        if(sessionStorage.getItem('userCenterCurrentTab')){
            this.activetab = sessionStorage.getItem('userCenterCurrentTab')
        }
        if(localStorage.getItem('theme') === "junmin" && localStorage.getItem('checkJunminIsSaveInfo')=="false"){
            this.stopChange=true
            console.log("位置1",this.stopChange)
        }else if(localStorage.getItem('theme') === "junmin" && localStorage.getItem('checkJunminIsSaveInfo')=="true"){
            this.stopChange=false
            console.log("位置zxc",this.stopChange)
        }
    },
    methods: {
        initConfig(){
            /*
                is_show_my_certificate:false,
                is_show_my_task:false,
                is_show_my_study:false,
                is_show_my_order:false,
                is_show_my_evaluation:false
            */
            let configArr = localStorage.getItem('configurationArr') && JSON.parse(localStorage.getItem('configurationArr'))
            configArr.forEach(item => {
                switch (item.key) {
                    case 'is_show_my_profile':
                        this.configArr[item.key] = item.value === 'false'? false:true
                        break;
                    case 'is_show_my_certificate':
                        this.configArr[item.key] = item.value === 'false'? false:true
                        break;
                    case 'is_show_my_task':
                        this.configArr[item.key] = item.value === 'false'? false:true
                        break;
                    case 'is_show_my_study':
                        this.configArr[item.key] = item.value === 'false'? false:true
                        break;
                    case 'is_show_my_order':
                        this.configArr[item.key] = item.value === 'false'? false:true
                        break;
                    case 'is_show_my_evaluation':
                        this.configArr[item.key] = item.value === 'false'? false:true
                        break;
                    default:
                        break;
                }
            })
        },
        // 确认修改头像
        confirmUploadAvatar(){
            if(!this.avatarUrl){
                this.$message.error('请上传头像')
                return
            }
            if(localStorage.getItem("theme")=="shanghaijiaoda"){
                this.$api.usercenter.updateShjdUser({headImage:this.avatarUrl}).then(res => {
                    if(res.success){
                        // this.hasSettedAvatarUrl = this.avatarUrl
                        this.$message.success('修改成功')
                        this.uploadAvatarDialog = false
                        this.$store.commit('updateSubUserInfo',{key:'headImage',value:this.avatarUrl})
                        localStorage.setItem('saveSubUserInfo',JSON.stringify(this.$store.state.subUserInfo))
                    }
                })
            }else{
                this.$api.usercenter.updateUser({headImage:this.avatarUrl}).then(res => {
                    if(res.success){
                        // this.hasSettedAvatarUrl = this.avatarUrl
                        this.$message.success('修改成功')
                        this.uploadAvatarDialog = false
                        this.$store.commit('updateSubUserInfo',{key:'headImage',value:this.avatarUrl})
                        localStorage.setItem('saveSubUserInfo',JSON.stringify(this.$store.state.subUserInfo))
                    }
                })
            }
            
        },
        cancelUploadAvatar(){
            this.$refs.avatarUploader.abort()
            this.uploadAvatarLoading = false
            this.avatarUrl = ''
            this.uploadAvatarDialog = false
        },
        handleAvatarBefore(){
            this.uploadAvatarLoading = true
        },
        // 头像上传成功
        handleAvatarSuccess(res,file){
            this.avatarUrl = file.response.data
            this.uploadAvatarLoading = false
        },
        handleAvatarError(err){
            this.uploadAvatarLoading = false
            this.$message.error('头像上传失败')
        },
        findAndSetPayStatus(data) {
            this.Countdown = null
            const payNos = data || this.qrcodeState.payNo
            this.Countdown = setInterval(() => {
                this.$api.home.findAndSetPayStatus(payNos).then(res=>{
                    if (res.data==true) {
                        clearInterval(this.Countdown)
                        this.type = 'success'
                        sessionStorage.setItem("typeState", this.type);
                        sessionStorage.removeItem("qrcodeState");
                    } else if (res.code != 0) {
                        clearInterval(this.Countdown)
                        this.type = 'fail'
                        sessionStorage.setItem("typeState", this.type);
                        sessionStorage.removeItem("qrcodeState");
                    }
                })
            }, 3000)
        },
        // 关闭弹框,清除已经生成的二维码
        closeCode () {
            this.paymentdialogVisible = false
            sessionStorage.setItem("dialogState", this.paymentdialogVisible);
            this.$refs.qrCodeDiv.innerHTML = ''
            clearInterval(this.Countdown)
            sessionStorage.removeItem("dialogState")
            sessionStorage.removeItem("typeState");
            sessionStorage.removeItem("qrcodeState");
        },
        // 生成二维码
        qrcodeimg (data) {
            this.paymentdialogVisible = true;
            // 缓存弹框状态
            sessionStorage.setItem("dialogState", this.paymentdialogVisible);
            this.type = 'payment';
            // 缓存支付状态
            sessionStorage.setItem("typeState", this.type);
            setTimeout(() => {
                new QRCode(this.$refs.qrCodeDiv, {
                    text: data.codeUrl,
                    width: 150,
                    height: 150,
                    colorDark: '#333333', // 二维码颜色
                    colorLight: '#ffffff', // 二维码背景色
                    correctLevel: QRCode.CorrectLevel.H // 容错率L/M/H
                }, 100)
            })
            this.findAndSetPayStatus(data.payNo)
        },
        applyInvoiceShow(){
            this.applyInvoice = false;
        },
        handleClose(){
            this.invoiceOuterVisible = false
        },
        // 商品详情
        goDetails(id){
            const theme = sessionStorage.getItem('theme');
            if(theme=='wanghong'){
                  this.$router.push({
                    path: "/wanghongDetails",
                    query: {
                        id: id,
                    }
                });
            }else if(theme=='gongxin'){
                //工信商品跳转
                this.$router.push({
                    path: "/gongxinDetails",
                    query: {
                        id: id,
                    }
                });
            }else{
                //大平台商品跳转
                this.$router.push({
                    path: "/courseGoodsDetail",
                    query: {
                        goodsId: id,
                    }
                });
            }
        },
        // 申请
        handleApply(id,index,realPay,name,time){ //
            const that = this;
            if(this.orderlist[index].invoiceStatus==1){
                var nowDate = new Date().getTime();
                var starDate = new Date(time).getTime();
                var reTime = 365*24*60*60*1000;
                if((starDate + reTime - nowDate)<0){ //过期
                   this.$message({
                       type:'warning',
                       message: '此订单申请发票已过期',
                   })
                    return
                }
            }
            this.applyInvoice = true;
            this.applyInfo.invoiceAmount = realPay;
            this.applyInfo.invoiceContent = name;
            this.applyInfo.id = id;
        },
        // 查看
        handlePreview(id){
            this.invoiceOuterVisible = true;
            this.$api.invoice.getDataDetails(id).then((res)=>{
                if(res.data){
                    this.formInfo = {...res.data}
                    if(this.formInfo.invoiceStatus == 1){
                        this.formInfo.invoicesTxt = '（审核中）'
                    }else if(this.formInfo.invoiceStatus == 2){
                        this.formInfo.invoicesTxt = '（已邮寄）'
                    }
                }
            })
        },
        // 去付款
        goPayment(id) {
            if (!localStorage.getItem('token')) {
                this.$router.push({ path: "/login" , query: {name: 'personalCenter'}});
            } else {
                this.$router.push({
                    path: "/orderPage",
                    query: {
                        id: id,
                    }
                });
            }
        },
        changetab(tab) {
            var checkJunminIsSaveInfo = localStorage.getItem('checkJunminIsSaveInfo')
            if(localStorage.getItem('theme') === "junmin" && checkJunminIsSaveInfo=="false"){
                this.activetab = '我的资料';
                this.stopChange=true
                sessionStorage.setItem('userCenterCurrentTab','我的资料')
            }else{
                this.stopChange=false
                this.activetab = tab.name;
                sessionStorage.removeItem('userCenterCurrentTab')//移除当前保存的tab
            }
        },
        getData(){
            this.$api.certificate.getCertificateList().then(res=>{
                if(res.data){
                    this.trainList = res.data;
                }
            })
        },
        myOrderList(){
            this.$api.certificate.myOrderList().then(({data}) =>{
                this.orderlist = data
                this.hasOrder = this.orderlist.length > 0;
                this.countDown();
            })
        },
        formatNumber(num){
            return num > 10 ? num :('0'+num)
        },
        countDown(){
            var that = this;
            that.timer = setInterval(function () {
            var orders = that.orderlist;
            for (var i = 0; i < orders.length; i++) {
                var status = orders[i].status;
                if (status == 0) {
                var orderTime = orders[i].orderTime;
                //计算剩余时间差值
                var leftTime = (new Date(orderTime).getTime() + 30 * 60 * 1000) - (new Date().getTime());
                if (leftTime > 0) {
                    //计算剩余的分钟
                    var minutes = that.formatNumber(parseInt(leftTime / 1000 / 60 % 60, 10));
                    //计算剩余的秒数
                    var seconds = that.formatNumber(parseInt(leftTime / 1000 % 60, 10));
                    var left_time = minutes + ":" + seconds;
                    orders[i].left_time = left_time;
                }else{
                    //改变超时未支付的订单状态
                        that.orderlist[i].status = 8;
                    }
                }
                if(orders[i].invoiceStatus==1){
                    var nowDate = new Date().getTime();
                    var starDate = new Date(orders[i].orderTime).getTime();
                    var reTime = 365*24*60*60*1000;
                    if((starDate + reTime - nowDate)<0){ //过期
                        that.orderlist[i].invoiceStatus = 3;
                    }
                }
            }
            that.orderlist = orders;
            that.$forceUpdate();
            }, 1000);
        }
    }
}
/* eslint-disable no-new */
</script>
<style lang="stylus" scoped>
 @import "../asset/css/certificate.styl"
.yingjiCertificate  
    height calc(100vh - 211px)
/deep/ .el-tabs__header
    padding-top 196px
.certificate-content
    position relative
    .avatar
        position absolute
        left 0
        top 0
        width 201px
        height 188px
        border-bottom 8px solid #f8f9fa
        z-index 3
        display flex
        flex-direction column
        align-items center
        .avatarIcon
            margin-top 24px
            width 64px
            height 64px
            border-radius 50%
            overflow hidden
            >img
                display block
                width 100%
                height 100%
        .name
            margin 6px 0 16px 0
            font-size 16px
            // font-family AlibabaPuHuiTiR
            color #333333
            line-height 22px
        .uploadBtn
            // font-family AlibabaPuHuiTiR
            width 80px
            height 32px
            text-align center
            line-height 32px
            background #fff
            border 1px solid #316FFF
            border-radius 4px
            font-size 14px
            color #316FFF
            cursor pointer
.uploadAvatarDialog
    /deep/ .el-dialog__header
        padding 24px 
        padding-bottom 0
        >span
            font-size 24px
            font-weight 500
            color #1B2257
            line-height 34px
    /deep/ .el-dialog__body
        padding 24px
        padding-bottom 0
    /deep/ .el-dialog__footer
        text-align center
        margin-top 54px
    .dialogFooter
        /deep/ .el-button--default
            margin-right 30px
            width 120px
            height 40px
            >span
                font-size 16px
        /deep/ .el-button--primary
            width 120px
            height 40px
            >span
                font-size 16px
    .dialogBody
        .avatar-uploader
            display inline-block
            width 320px
            height 320px   
            /deep/ .el-upload
                border: 1px dashed #d9d9d9;
                border-radius: 6px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                &:hover
                    border-color: #409EFF;
            .avatar
                width: 320px;
                height: 320px;
                display: block;
            .avatar-uploader-icon
                font-size: 28px;
                color: #8c939d;
                width: 320px;
                height: 320px;
                line-height: 320px;
                text-align: center;
        .preView
            margin-left 16px
            display inline-block
            text-align center
            vertical-align top
            .imgWrapper
                display block
                width 96px
                height 96px
                border-radius 50%
                overflow hidden
                >img
                    width 100%
                    height 100%
            .txt
                margin-top 8px
                font-size 14px
                line-height 20px
                color #999
                font-weight 500

    
        
// @media screen and (min-width: 1400px) {
//     .certificate-main .certificate-content /deep/.el-tabs--left .el-tabs__header.is-left{
//          height:600px;
//     }
//     .certificate-main .certificate-content /deep/.el-tabs__content{
//          min-height:600px;
//     } 
// }
// @media screen and (min-width: 1600px) {
//    .certificate-main .certificate-content /deep/.el-tabs--left .el-tabs__header.is-left{
//          height:760px;
//     }
//     .certificate-main .certificate-content /deep/.el-tabs__content{
//          min-height:760px;
//     } 
// }
// @media screen and (min-width: 1900px) {
//    .certificate-main .certificate-content /deep/.el-tabs--left .el-tabs__header.is-left{
//          height:900px;
//     }
//     .certificate-main .certificate-content /deep/.el-tabs__content{
//          min-height:900px;
//     } 
// }
</style>
