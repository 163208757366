<template>
    <div class="evaluationList">
        <div v-if="list.length" class="reportList">
            <div class="item" v-for="item in list" :key="item.index">
                <div class="label">
                    <span>{{item.assessReportName}}</span>
                    <!-- 千万别删！！！ -->
                    <!-- <h3>{{item.assessReportName}}<span v-if="item.modelType" :class="item.modelType==2?'active':''">{{item.modelType==1?'素质':'专业'}}</span></h3> -->
                </div>
                <div class="con">
                    <div class="name">{{item.assessName}}</div>
                    <div class="time">测评时间：{{item.assessDate}}</div>
                    <div class="btn" @click="viewReport(item.id)">查看报告</div>
                </div>
            </div>
            <p class="loadingmore">- 已加载全部内容 -</p>
        </div>
        <article v-if="!list.length" class="no-data">
            <img class="notraining" src="./../asset/images/noOrder@3x.png" alt />
            <p>还没有测评报告呦，赶快去测评吧～</p>
        </article>
    </div>
</template>

<script>
export default {
    name:'evaluation',
    data(){
        return{
            list: [],
        }
    },
    created() {
        this.reportList();
    },
    methods:{
        reportList() {
            this.$api.assess.reportList().then((res) => {
                if(res.data){
                    this.list = res.data.list;
                }
            });
        },
        viewReport(id){
            this.$router.push({
                path: '/reportDetails',
                query: {
                    id: id
                }
            })
        },
    }
}
</script>

<style lang="stylus" scoped>
 @import "../asset/css/evaluation.styl"
</style>