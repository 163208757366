import pageJson from './config/page.json'
import staticPageJson from './config/staticPage.json'
import templateConf from './config/template.json'
import vueTemplate from './config/vueTemplate'
const routerArr = []

let pageObj = {}
const elObj = require.context('./page', false, /\.vue$/)
elObj.keys().forEach(key => {
  const el = elObj(key).default
  pageObj[el.name] = el
})

for (const key in staticPageJson) {
  routerArr.push({
    ...staticPageJson[key],
    props: () => ({
      pageData: staticPageJson[key],
      templateConf: templateConf[staticPageJson[key].block_layout],
    }),
    component: pageObj[staticPageJson[key].template_name]
  })
}

for (const key in pageJson) {
  routerArr.push({
    ...pageJson[key],
    props: () => ({
      pageData: pageJson[key],
      templateConf: templateConf[pageJson[key].block_layout],
    }),
    component: vueTemplate[pageJson[key].template_name]
  })
}

export default routerArr
