var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('aside',[(_vm.courseCount > 0)?_c('p',{staticClass:"course-num"},[_vm._v(" 共有"+_vm._s(_vm.courseCount)+"门课程 ")]):_vm._e(),_c('ul',{staticClass:"training-list",class:_vm.courseCount ? 'training-list-height' : 'training-list-nocourse'},[_c('el-scrollbar',{staticStyle:{"height":"100%"}},[_c('div',{staticClass:"list-li"},_vm._l((_vm.trainList),function(el){return _c('el-tooltip',{key:el.id,staticClass:"item",attrs:{"effect":"dark","placement":"right-start","content":el.label}},[_c('li',{class:[
                _vm.active === el.id && el.type === 1
                  ? 'training-list-active'
                  : _vm.active === el.id && el.type === 2
                  ? 'training-exam-active'
                  : '',
                el.type === 2 ? 'exam' : '' ],on:{"click":function($event){return _vm.changeInfo(el)}}},[(el.type === 1)?_c('img',{staticClass:"img",attrs:{"src":el.pic,"alt":""}}):_vm._e(),(el.type === 2)?_c('img',{attrs:{"src":require("../../asset//4.png"),"alt":""}}):_vm._e(),(el.type === 2 && el.isPassed !== 2)?_c('span',{staticClass:"exam-tip",class:{
                  nopass: el.isPassed === 0,
                  pass: el.isPassed === 1,
                  noanswer: el.isPassed === 3,
                }},[_vm._v(_vm._s(_vm._f("ispassTip")(el.isPassed)))]):_vm._e()])])}),1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }