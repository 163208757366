<template>
 <div class="footers">
     <el-row class="footers-con" type="flex" justify="flex-start">
         <div class="left">
            <img class="left-logo" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/peixun-footer-logo.png" alt="">
            <!-- <div class="foot-w">山东家政助力家政行业提质扩容</div> -->
         </div>
         <div class="right">
            <p>地址：北京市西城区北三环中路23号燕莎盛世大厦</p>
            <p>邮编：100000</p>
         </div>
     </el-row>
     <!-- <el-row class="footers-f">
         <span>版权所有： 伟东国信</span>
         <span>技术支持：伟东云教育</span>
     </el-row> -->
     <saas-nsr-footer :textColor="'#363636'" :bgColor="'#EAEAEA'"/>
 </div>
</template>

<script>
export default {
    name:'footers',
    props: {
    },
    data () {
        return {
        };
    },

    methods: {
    },

    mounted(){
    },
}

</script>
<style lang='stylus' scoped>
    @import "../../asset/css/footers.styl"
</style>
<style lang='stylus'>
</style>
