import Vue from 'vue';
import Router from 'vue-router';
import store from './../store'
import { Message } from 'element-ui'
import api from './../api'

import routes from './routers';
import conf from '../config/url.js'
import CryptoJS from './CryptoJS'
Vue.use(Router);


const router = new Router({
  mode: 'history',
  routes,
});

const noNeedTokenPage = [
  'weidong_homepage',
  'zhongzhi_homepage',
  'projectDetails',
  'gongxin_homepage',
  'gongxinDetails',
  'gongxinDetailsTwo',
  'personalEvaluation',
  'personalCenter',
  'jiazheng_homepage',
  'register-jz',
  'register-mz',
  'gzjt_Regulations',
  'gzjt_Detailpage',
  'gzjt_homepage',
  'gongxin_about',
  // 'online_training',
  // 'training_detail',
  'wanghongDetails',
  'wanghong_homepage',
  'wanghong_about',
  'publicClass',
  'readingBook',
  'classDetail',
  'collegeDetails',
  'wanghongPartner',
  'login',
  'forget',
  'register',
  'standardList',
  'standardDetail',
  'yingji_homepage',
  'register',
  'courseGoodsDetail',
  'courseGoods',
  'moreCourseGoods',
  'aic_homepage',
  'aic_about',
  'evaluationNot',
  'jobIdx',
  'jobDetail',
  'junminPersonalInfo',
  'anquan_homapage',
  'minzheng_homepage',
  'sdjz_homepage',
  'sdjzmh_homepage',
  'sdjz_yanglao2',
  'credit',
  'management',
  'HouseKeepingTrain',
  'IndustryAssess',
  'BusinessManagement',
  'services',
  'CreditInquiry',
  'personnel',
  'screen',
  'shandongCertificate',
  'wechatapplet',
  'service-agreement',
  'privacy',
  'zhaohupx_homepage',
  'zhaohumh_homepage',
  'zhaohuCredit',
  'zhaohuManagement',
  'zhaohuHouseKeepingTrain',
  'zhaohuIndustryAssess',
  'zhaohuBusinessManagement',
  'zhaohuservices',
  'zhaohuCreditInquiry',
  'zhaohupersonnel',
  'zhaohuscreen',
  'zhaohuCertificate',
  'admissions',
  'zhaohuevaluation',
  'resultList',
  'red_sdjzHomepage',
  'doorToDoorApproval',
  'emptyIndex'
] //不需要登录的定制页
// 大平台导航头部
const navName = ['learning', 'assessList', 'examList', 'courseGoods', 'aic_homepage', 'aic_about', 'liveList']
const gongxinNavName = ['gongxin_homepage', 'coursecatalog', 'taskDetail', 'online_training', 'certificationExam', 'liveList', 'examList']

let frontendConfig = null
router.beforeEach(async (to, from, next) => {




  // 赛事学习页面自动登录
  if(to.path === '/courseGoods'&&to.query.type=='qingzhi'){//定制注册页跳转


    // console.log(routes.query.token,'this.$route.query.tokenthis.$route.query.tokenthis.$route.query.token')
    if(to.query.token){
      // console.log(to.query.token,'this.$route.query.tokenthis.$route.query.tokenthis.$route.query.token')
      // aes解密token

      // let decData = CryptoJS.enc.Base64.parse(String(to.query.token)).toString();
      // console.log('测试base64++',decData)
      // let tokenDec = CryptoJS.decrypt(decData,'absoietlj32fai12','absoietlj32fai12')


      // 加密

      let secStr = CryptoJS.encryptQZ(String(to.query.token),'absoietlj32fai12','absoietlj32fai12')
      console.log(secStr,'secStrsecStrsecStrsecStr')
      let tokenDec = CryptoJS.decryptQZ(String(to.query.token),'absoietlj32fai12','absoietlj32fai12')
      console.log(tokenDec,'tokenDectokenDectokenDec')
      localStorage.setItem('token', tokenDec.slice(0,32))
      // localStorage.setItem('token',String(to.query.token))
      localStorage.setItem('actor',String(to.query.actor))
      localStorage.setItem('branch',String(to.query.branch))
      localStorage.setItem('organ',String(to.query.organ))
      localStorage.setItem('isHiddenAccount','1')
      next()
    }else{
      next()
    }
  }




  // 赛事学习页面自动登录
  if(to.path === '/learning'){//定制注册页跳转

    console.log(to.query,'this.$route.query.tokenthis.$route.query.tokenthis.$route.query.token')

    // console.log(routes.query.token,'this.$route.query.tokenthis.$route.query.tokenthis.$route.query.token')
    if(to.query.token){
      // console.log(to.query.token,'this.$route.query.tokenthis.$route.query.tokenthis.$route.query.token')
      // aes解密token

      // let decData = CryptoJS.enc.Base64.parse(String(to.query.token)).toString();
      // console.log('测试base64++',decData)
      // let tokenDec = CryptoJS.decrypt(decData,'absoietlj32fai12','absoietlj32fai12')


      // 加密

     let secStr = CryptoJS.encryptQZ(String(to.query.token),'absoietlj32fai12','absoietlj32fai12')
      console.log(secStr,'secStrsecStrsecStrsecStr')
      // 青职
      if(to.query.type=='qingzhi'){
        let tokenDec = CryptoJS.decryptQZ(String(to.query.token),'absoietlj32fai12','absoietlj32fai12')
        console.log(tokenDec,'tokenDectokenDectokenDec')
        localStorage.setItem('token', tokenDec.slice(0,32))
      }else {
        let tokenDec = CryptoJS.decrypt(String(to.query.token),'absoietlj32fai12','absoietlj32fai12')
        console.log(tokenDec,'tokenDectokenDectokenDec')
        localStorage.setItem('token', tokenDec.slice(0,32))
      }

      // localStorage.setItem('token',String(to.query.token))
      localStorage.setItem('actor',String(to.query.actor))
      localStorage.setItem('branch',String(to.query.branch))
      localStorage.setItem('organ',String(to.query.organ))
      localStorage.setItem('isHiddenAccount','1')

      next()
    }else{
      next()
    }
  }


  //   解决导航头选中问题
  if (localStorage.getItem('theme') === 'gongxin' && !gongxinNavName.includes(to.name)) {
    to.meta.highlight = from.meta.highlight
  }
  if (!navName.includes(to.name) && !localStorage.getItem('theme')) {
    to.meta.highlight = from.meta.highlight
  }

  // let frontendConfig = localStorage.getItem('frontendConfig') ? JSON.parse(localStorage.getItem('frontendConfig')) : ''
  const token = localStorage.getItem('token') ? localStorage.getItem('token') : ''

  // 获取配置参数【主题，页面配置】
  if (!frontendConfig) {
    await api.configure.configuration(conf.confUrl).then(res => {
      if (res.data) {
        frontendConfig = res.data.frontendConfig.filter(item => {
          if (item.key === 'homepage') {
            return true;
          }
        })[0];
        localStorage.setItem('domain', JSON.stringify(res.data.domain)?JSON.stringify(res.data.domain):'');
        localStorage.setItem('theme', res.data.theme || '');
        localStorage.setItem('frontendConfig', frontendConfig ? JSON.stringify(frontendConfig) : '');
        localStorage.setItem('hasGetConfig', '1')
        // sessionStorage.setItem('flag', false);
        localStorage.setItem('configurationArr', JSON.stringify(res.data.frontendConfig))
        if (res.data.pageLogo) {
          let link = document.querySelector("link[rel*='icon']");
          link.href = res.data.pageLogo;
          localStorage.setItem('pageLogo', res.data.pageLogo);
        }
        if (res.data.pageTitle) {
          document.title = res.data.pageTitle;
          localStorage.setItem('pageTitle', res.data.pageTitle)
        }
      }
    })
  } else {
    if (localStorage.getItem('pageTitle')) {
      document.title = localStorage.getItem('pageTitle');
    }
    if (localStorage.getItem('pageLogo')) {
      let link = document.querySelector("link[rel*='icon']");
      link.href = localStorage.getItem('pageLogo');
    }
    let setTempConfigs = localStorage.getItem('setTempConfigs');
    if (setTempConfigs == null) {
      let domain = window.location.host;
      await api.configuration.getConfiguration({ domain }).then((data) => {
        if (data.code === 0) {
          let temp = {
            pageHeader: data.data.pageHeader,
            pageHeaderSetting: data.data.pageHeaderSetting,
            pageEndSetting: data.data.pageEndSetting,
            pageEnd: data.data.pageEnd
          }
          localStorage.setItem('setTempConfigs', JSON.stringify(temp)) //本地存储数据
        }
      })
    }
  }

  if(to.path === '/register'){//定制注册页跳转
    if(localStorage.getItem('theme') === 'minzheng'){
      next('register-mz')
    }else{
      next()
    }
  }

  if (to.path === '/course/handPaper' && from.path === '/course/testPaper') {
    store.state.testPaper = true
  }

  if (noNeedTokenPage.includes(to.name) || noNeedTokenPage.includes(to.path)) {
    next();
  } else if (to.meta && to.meta.noLogin) {//网站地图配置的页面
    next();
  } else if (frontendConfig && frontendConfig.value && to.path === '/') {//配置的首页路由
    next(frontendConfig.value);
  } else {
    if (token) {
      if (localStorage.getItem('theme') === '' && to.path === '/') {
        next({ name: 'learning' });  //大平台7天免登陆
      } else {
        // 军民、民政登陆后需要判断是否完善资料
        if (localStorage.getItem('theme') === 'junmin') {
          checkJunmin(next)
        } else if(localStorage.getItem('theme') === 'minzheng'){
          checkMinzheng(next)
        }
        else {
          next()
        }
      }
    } else {
      next({ name: 'login' });
    }
  }
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

// 军民是否完善资料
function checkJunmin(next) {
  const checkJunminIsSaveInfo = localStorage.getItem('checkJunminIsSaveInfo')//军民是否完善资料
  if (checkJunminIsSaveInfo === 'true') {
    next()
  } else {
    Message({
      type: 'error',
      message: '请先完善个人资料',
      duration: 2500,
      onClose: () => {
        sessionStorage.setItem('userCenterCurrentTab','我的资料')
        next('/personalCenter')
      }
    })
  }
}

function checkMinzheng(next) {
  const checkMinzhengIsSaveInfo = localStorage.getItem('checkMinzhengIsSaveInfo')//民政是否完善资料
  if (checkMinzhengIsSaveInfo === 'true') {
    next()
  } else {
    sessionStorage.setItem('userCenterCurrentTab','我的资料')
    Message({
      type: 'error',
      message: '请先完善个人资料',
      duration: 2500,
      onClose: () => {
        next('/personalCenter')
      }
    })
  }
}


const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router;
