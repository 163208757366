<template>
  <div class="loginBox register-jz-page">
    <input type="text" style="display:none">
    <input type="password" style="display:none">
    <div class="login">
      <p class="login-left"/>
      <div class="login-box">
        <template v-if="!isRegistSuccess">
          <ul class="tabs">
            <li
              :key="item.key"
              v-for="(item, index) in tabsList"
              @click="setActiveName(item.key)"
              :style="index === 0 ? 'margin-right:40px' : ''"
              :class="{ highlight: activeName === item.key,isHasOne: tabsList.length == 1,[theme]: theme }"
            >
              {{ item.val }}
            </li>
          </ul>
          <div class="form">
            <el-form ref="form" :rules="rules" :model="formData">
              <el-form-item prop="account" ref="account">
                <el-input
                  :placeholder="activeName == 'phone'? '请输入手机号':'请输入邮箱账号'"
                  v-model="formData.account"
                >
                </el-input>
              </el-form-item>
              <el-form-item v-if="activeName === 'phone'" prop="authCode" ref="authCode">
                <el-input
                  placeholder="请输入验证码"
                  v-model="formData.authCode"
                >
                  <el-button 
                    slot="append" 
                    @click="postAccountGetAuthCode('phone')" 
                    round
                    :style="sendCodeBtnStyle" 
                    >{{ 0 >= Countdown1 ? '获取验证码' : Countdown1+'s' }}
                  </el-button>
                </el-input>
              </el-form-item>
              <el-form-item v-else prop="authCode" ref="authCode">
                <el-input
                  placeholder="请输入验证码"
                  v-model="formData.authCode"
                >
                  <el-button 
                    slot="append" 
                    :style="sendCodeBtnStyle" 
                    round
                    @click="postAccountGetAuthCode('mail')" 
                  >
                    {{ 0 >= Countdown2 ? '获取验证码' : Countdown2+'s' }}
                  </el-button>
                </el-input>
              </el-form-item>
              <input type="text"  style="display:block;width:0;height:0;border-width:0"/>
              <el-form-item prop="password" ref="password"> 
                  <el-input
                    @blur="comparePassword"
                    type="password"
                    autocomplete="new-password"
                    placeholder="请设置8-20位，数字、大小写字母、字符组合密码"
                    v-model="formData.password"
                  ></el-input>
                </el-form-item>
                <el-form-item class="rePassword" prop="rePassword" ref="rePassword"> 
                  <el-input
                    @blur="comparePassword"
                    placeholder="请再次输入登录密码"
                    :type="passw"
                    autocomplete="new-password"
                    v-model="formData.rePassword"
                  >
                    <template slot="suffix">
                      <img class="password" v-show="formData.rePassword && passw === 'password'" @click="showPassw" src="@assets/ic-by@2x.png" />
                      <img class="password" v-show="formData.rePassword && passw === 'text'" @click="showPassw" src="@assets/ic_zy@2x.png" />
                    </template>
                  </el-input>
                </el-form-item>
            </el-form>
          </div>
          <div class="read" :class="{[theme]:theme}">
            <el-checkbox v-model="hasreaded">我已阅读并同意</el-checkbox>
            <span class="agreement" @click="goPage('service-agreement')">《用户协议》</span>及
            <span class="agreement" @click="goPage('privacy')">《法律声明及隐私政策》</span>
          </div>
          <div class="btn">
            <el-button
              class="regist"
              @click="onSubmit"
              :disabled="disabled()"
              :style="registBtnStyle"
              >立即注册
            </el-button>
            <span>已有账号？</span>
            <router-link class="toLogin" :class="{[theme]:theme}" :to="{ name: 'login' }">立即登录 ></router-link>
          </div>
        </template>
        <template v-else>
          <div class="middle">
            <div class="registTitle">恭喜您，注册成功！</div>
            <div class="registTip">请妥善保管您的账户信息</div>
            <div class="toHome">正在跳转首页…{{backCount}}s</div>
          </div>
        </template>
      </div>
    </div>
    <el-dialog
      class="readdialog"
      title="个人信息及隐私保护政策"
      :visible.sync="userReadDialog"
      top="70px"
      width="880px"
      center
    >
      <div class="privacy-box">
        <h2 class="yinyan">引言</h2>
        <p>北京伟东凌鸿教育科技有限公司及各关联公司（以下统称“伟东”或“我们”）高度重视广大用户的个人信息安全与隐私保护，我们向用户提供方便快捷的产品和服务的同时，致力于提升信息处理的透明度，增强您对信息管理的便捷性，保障您的信息安全。我们严格遵守法律法规，遵循以下隐私保护原则，以期为您提供更加安全、可靠的产品与服务。</p>
        <p>“伟东个人信息及隐私保护政策”（以下简称“隐私政策”）适用于：（1）您访问伟东互联网网站（2）您关注、使用伟东的微信公众号和小程序时，我们收集和使用您的个人信息及其他相关信息。我们将通过网站、微信公众号和微信小程序向您提供服务。这些服务将实现您的课程搜索、教育内容介绍、课程报名、课程学习，认证，订单支付与查询的基础功能，具体包括课程介绍、账号注册、登录、订单支付等。</p>
        <p>本隐私政策旨在向您说明，我们如何收集、使用、共享和保护您的个人信息及其他相关信息，请您务必仔细阅读。当您向我们提供您的个人信息或开始使用我们的产品或服务时，即表示您已知悉并同意我们在本隐私政策中所述内容。我们会在以下描述中将重点内容以文字加粗的形式标记，提醒您注意。在使用过程中，请谨慎考虑是否向我们提供这些信息，我们将尽最大努力保护好您的个人信息。</p>
        <p class="title2">本隐私政策主要包含以下内容</p>
        <p>
          一、个人信息的定义与范围
          <br />二、我们如何收集您的信息
          <br />三、Cookies及同类技术的使用
          <br />四、个人信息的使用
          <br />五、我们如何共享、转让、公开披露您的个人信息
          <br />六、我们如何保存和保护您的个人信息
          <br />七、您如何管理个人信息
          <br />八、我们如何保护未成年人的个人信息
          <br />九、适用法律
          <br />十、版本更新
          <br />十一、联系我们
        </p>

        <h2>一、个人信息的定义与范围</h2>
        <p class="title2">(一) 个人信息</p>
        <p>
          个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。本政策中涉及的个人信息包括：
          <br />1.基本信息: 包括个人姓名、出生日期、性别、住址、个人电话号码、电子邮箱、面部识别特征等；
          <br />2.网络身份标识信息：包括系统账号、IP地址、邮箱地址及与前述有关的密码、口令、口令保护答案等；
          <br />3.个人财产信息：交易和消费记录、微信支付授权、支付宝授权、银联授权、伟东优惠卷等虚拟财产信息；
        </p>
        <p class="title2">(二) 个人敏感信息</p>
        <p>个人敏感信息：指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。在本政策中包括：手机号码、身份证或其他身份证明、位置信息、支付信息等。</p>
        <p class="title2">(三) 匿名化处理后的信息</p>
        <p>匿名化处理后的信息：指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后不能被复原的信息，匿名化处理后的信息不属于个人信息。其他无法识别特定自然人身份或者反映特定自然人活动情况的信息不属于个人信息。</p>
        <h2>二、我们如何收集您的信息</h2>
        <p class="title2">(一) 账号注册</p>
        <p>
          您可以通过伟东网站，注册伟东账号并享受伟东提供的服务。
          <br />您也可以在不注册账号或不登录的情形下浏览伟东网站中的内容，但您注册后可享受更加全面和优质的服务（即个人账号设置、伟东课程学习、学员答疑、订单查询、伟东教材获取）。
          <br />在您注册账号时，我们需要收集您的电话号码或个人邮箱，我们将通过发送短信验证码或邮件的方式来验证您的身份是否有效。为了提供更加个性及准确的教学服务，您可以选择向我们提供头像、昵称、学员信息，以便于我们向您推荐与之相匹配的服务。
          <br />您也可授权使用微信第三方账号关联登录，为保存登录信息并在不同设备登录时能同步数据，我们还需您授权我们从第三方账号中获取某些权限，如使用第三方关联登录的唯一标识（OpenID）、头像、昵称。有关您在这些第三方账号中所有的隐私控制、选项及具体的隐私规定，请参阅他们的隐私政策。
        </p>
        <p class="title2">(二) 使用我们的服务</p>
        <p>
          当您通过我们提供的课程搜索服务时，我们将收集您主动填写的课程名称或班号，以便于向您提供和展示与之相对应的课程信息。
          <br />当前您报名和购买了我们提供的课程教学服务时，我们可能要求您填写您的家庭地址信息，以便我们向您邮寄学习资料。
        </p>
        <p class="title2">(三) 订单信息或交易状态</p>
        <p>我们将记录您的订单信息及交易状态。我们收集上述信息是基于法律法规要求以及保护您的财产安全、依照平台规则处理用户纠纷的需要。</p>
        <p class="title2">(四) 关注使用公众号、小程序</p>
        <p>
          （1）当您关注、使用我们的微信公众号时，我们会收集您的微信昵称、头像信息，以建立与您的关联；
          <br />（2）当您使用该微信小程序时，我们会收集您的微信UnionID、OpenID、UserID、微信昵称、微信头像、登录记录信息，以确认你的身份。
          <br />（3）当您使用微信公众号或微信小程序的特定功能或服务时，您需要注册、登录伟东账号或使用微信账号快速登录，当您选择后者时，我们会收集您的手机号码，对于此类信息，我们将按照微信公众号和微信小程序的个人信息收集规则，根据相应提示获取您的同意。
          <br />（4）当您参加线上课程等活动时，我们将收集您主动填写的个人信息，且仅用于您所参加的教学活动的身份识别、教材发送及统计工作。
        </p>
        <p class="title2">(五) 参加活动</p>
        <p>当您参加我们的用户营销活动时，我们需要您提供某些信息，可能会包含您的姓名、照片、通讯地址、联系方式、微信或支付宝的支付授权。这些信息可以帮助我们对活动结果进行公示，对活动进行后续宣传，与您取得联系，向您发放礼品或提供服务等。如果您拒绝提供此类信息，可能导致无法参与相应活动、无法收到礼品或完成转账支付，但不会影响您使用伟东网站提供的核心业务功能服务（账号注册、账号使用、课程搜索）。您在进行课程交流、互动及评价过程中，也可能会包含或者关联到您的个人信息，请谨慎考虑披露相关个人敏感信息。</p>
        <p class="title2">(六) 意见反馈</p>
        <p>我们可以通过伟东网站所提供的“意见与建议功能”或“联系客服”功能，接收您所提供的反馈信息。反馈信息中可能包括您的姓名、手机号码、问题截图与说明。由于反馈信息由您主动提供，因此视为您已同意我们获取此类反馈信息。并同意我们在必要的情况下通过您所提供的联系方式与您联系以进一步获得相关反馈信息。</p>
        <p class="title2">(七) 问卷调研</p>
        <p>为了向您提供更好的服务和网络使用环境，我们可能会发起问卷调研以了解您的使用偏好、相关意见，例如您感兴趣的资料、您感兴趣的课程等。您可以自主选择是否参加问卷调研，调研结果将仅供统计使用。</p>
        <p class="title">(八) 通话信息</p>
        <p>当您与伟东客服团队联系时，我们会以通话录音及人工录入的方式记录您的通话信息、您提出的意见或建议、您提供的姓名、联系方式，以便我们处理纠纷、解决问题或向您反馈结果。如果您拒绝我们收集此类信息，可能会导致您无法拨打我们的客服电话或客服团队无法将处理结果反馈到您，但不影响您使用伟东提供的学校及课程搜索、报名选课、课程学习在内的核心业务功能。</p>
        <p class="title2">(九) 安全保障功能</p>
        <p>为提高您使用我们提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，我们可能会通过了解您的课程搜索信息、订单信息、并可能会记录一些我们认为有风险的链接（“URL”）和IP地址来判断您的账号风险。</p>
        <p class="title2">(十) 来自第三方的信息</p>
        <p>在您注册我们账户和使用服务过程中，经您授权我们可能向关联方、合作伙伴及其他独立第三方来源获得关于您的个人信息。这些个人信息包括但不限于您的账号信息、身份信息、地理位置信息、语音、交易信息、设备信息。我们会将此类信息汇总，用于帮助我们向您提供更好以及更加个性化的教学产品及课程服务。</p>
        <h2>三、Cookies及同类技术的使用</h2>
        <p class="title2">1.Cookies的使用</p>
        <p>
          （1）为实现您联机体验的个性化需求，使您获得更轻松的访问体验。我们可能通过Cookies或同类技术收集您的设备型号、操作系统、唯一设备标识符、登录IP地址信息、以及缓存您的浏览信息、点击信息。我们向您发送Cookies是为了简化您重复登录的步骤、存储您的使用偏好或您购物车中的商品等数据，进而为您提供更好的偏好设置、帮助判断您的登录状态以及账户或数据安全。
          <br />（2）我们不会将 Cookies 用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookies。但如果您这么做，您可能需要在每一次访问伟东网站时亲自更改用户设置，您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。
        </p>
        <p class="title2">2.网络Beacon和同类技术的使用</p>
        <p>
          除Cookies 外，我们还会在网站上使用网络Beacon等其他同类技术。我们的网页上常会包含一些电子图像（称为"单像素" GIF文件或 "网络 Beacon"）。我们使用网络Beacon的方式有：
          <br />（1）通过在伟东网站上使用网络Beacon，计算用户访问数量，并通过访问 Cookies辨认注册的伟东用户。
          <br />（2）通过得到的Cookies信息，为您提供个性化服务。
        </p>
        <h2>四、个人信息的使用</h2>
        <p>为向您提供服务、提升我们的服务质量以及优化您的服务体验，我们会在符合法律规定或获得您授权的情况下使用您的个人信息，并主要用于下列用途：</p>
        <p class="title2">1.提供产品与/或服务</p>
        <p>
          为实现我们最基本教育、教学服务，即展示各种教育课程信息、根据您搜索展示课程信息，根据您的选择完成课程的支付，教程发放并维护、改进这些服务，包括但不限于为优化用户体验，我们会使用您在该账号下的信息。
          <br />我们将根据您主动选择及填写的地区和搜索信息，向您提供和展示对应的校区及课程信息。
          <br />我们将根据您所绑定的学员号，提供在线查询积分情况，查看班级、课表、相关资料功能。
          <br />我们将根据你主动选择和提供的学校、手机号、姓名信息，向您提供一卡通二维码以作为学习打开及进行校园的标识。
          <br />当您购买伟东的相关线上/线下课程时，您可能需要使用“支付功能”。您可以选择与伟东合作的第三方支付机构，如：支付宝、微信及银联等（以下统称“支付机构”）所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的伟东订单号及交易金额信息与这些支付机构共享以实现其确认您的支付指令并完成支付。
          <br />当您购买了伟东相关课程，我们可能根据您所填写的地址信息，为您邮寄教课程教材。
        </p>
        <p class="title2">2、保持联络</p>
        <p>我们将及时回应您的询问和请求，例如：通过网站中的“用户反馈”或“联系客服”的方式提出你的问题，由客服人员联络您并解决您的问题；当您参与有关我们产品及服务的调查时，我们可能会获取您参与问卷调查时向我们发送的答复信息，以及您与我们的关联方、合作伙伴之间互动时提供的相关信息，以便于优化客户服务的质量与流程。</p>
        <p class="title2">3.线下信息推送</p>
        <p>向您推荐您可能感兴趣内容包括但不限于向您发送保证服务完成所必需的验证码，产品和服务信息，或通过系统向您展示个性化的推广信息。如果您不想接受我们以任何方式向您提供的营销信息，您可以选择收回您的授权同意，您可以通过我们在电子邮件中的退订按钮或按照我们在短信中提供的退订说明进行退订</p>
        <p>需要说明的是，即使您选择拒绝接受推广信息，我们可能还会向您发送重要的系统管理及安全信息。</p>
        <p class="title2">4、分析和研究的目的</p>
        <p>我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，以展示我们的产品或服务的整体使用趋势。我们可能会使用到您的个人信息以及经过去标识化和匿名化的信息。</p>
        <p class="title2">5、保障安全</p>
        <p>我们可能使用您的个人信息以验证身份，预防、发现、调查可能存在的欺诈、危害安全、非法或违反与我们或关联方协议、政策或规则的行为，以保护您、其他用户、我们或关联方的合法权益。</p>
        <p class="title2">请您知悉，以下情形中，我们收集、使用个人信息无需征得您的授权同意：</p>
        <p>
          （1）与国家安全、国防安全有关的；
          <br />（2）与公共安全、公共卫生、重大公共利益有关的；
          <br />（3）与犯罪侦查、起诉、审判和判决执行等有关的；
          <br />（4）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
          <br />（5）所收集的个人信息是个人信息主体自行向社会公众公开的；
          <br />（6）从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
          <br />（7）根据您的要求签订合同所必需的；
          <br />（8）用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；
          <br />（9）为合法的新闻报道所必需的；
          <br />（10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
          <br />（11）法律法规规定的其他情形；
          <br />（12）经您同意或授权的其他用途。
        </p>
        <h2>五、我们如何共享、转让、公开披露您的个人信息</h2>
        <p class="title2">1.共享</p>
        <p>经您同意，我们可能与我们的关联公司分享您必要的个人信息，以提供和发展我们的产品与/或服务。除以下情形外，未经您同意，我们及我们的关联公司不会与任何第三方分享您的个人信息：</p>
        <p>
          （1）获得您的同意或授权；
          <br />（2）根据法律法规的规定或行政、司法机构等有权机关的要求或者授权；
          <br />（3）出于实现“个人信息的使用”部分所述目的，或为履行我们在用户协议或本隐私政策中的义务和行使我们的权利，向我们的关联方、合作伙伴或代表我们履行某项职能的第三方（例如代表我们发出推送通知的通讯服务商、处理银行卡的支付机构、第三方验证机构、英语测评机构）分享您的个人信息；
          <br />（4）某些情况下，只有共享您的信息才能提供您需要的服务，或处理您与他人的纠纷或争议；
          <br />（5）我们与第三方进行联合营销活动，我们可能与其共享活动过程中产生的、为完成活动所必要的个人信息，如参加活动的用户数、中奖名单、中奖人联系方式，以便第三方能及时向您发放奖品。与授权合作伙伴共享，仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，在您使用我们的口语测评服务时，我们必须与口语测评服务提供商共享您的语音信息方能提供服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。
          <br />资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移。
        </p>
        <p class="title2">2.合并、转让或资产出售</p>
        <p>根据法律规定及合理商业习惯，在我们计划与其他公司合并或被其收购或进行其他资本市场活动时，以及其他情形下我们需要接受来自其他主体的尽职调查时，我们会把您的信息提供给必要的主体，但我们会通过和这些主体签署保密协议等方式要求其对您的个人信息采取合理的保密措施。随着我们业务的发展，我们及我们的关联方有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移。</p>
        <p class="title2">3.公开披露</p>
        <p class="title2">我们仅会在以下情形且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：</p>
        <p>
          （1）根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息。
          <br />（2）为维护我们及关联方或其他用户的合法权益，在协助处理与您有关的交易纠纷或争议时，我们可能向您的交易相对方或存在利害关系的第三方提供解决交易纠纷或争议所必需的信息。
          <br />（3）根据法律规定，刑事侦查机关为调查犯罪，依法定程序调取的必要个人信息；或行政机关、司法机构，依法定程序并经您授权同意调取的必要个人信息，我们会向其提供您的相关信息；非经法定程序或未获得您的同意，我们不会将您的个人信息提供给任何第三方机构或个人。
        </p>
        <h2>六、我们如何保存和保护您的个人信息</h2>
        <p class="title2">1.如何保存您的个人信息</p>
        <p>
          （1）按照相关法律法规规定，我们将把中华人民共和国境内运营过程中收集和产生的您的个人信息存储在中国境内。如部分服务涉及跨境业务，我们需要向境外机构传输境内收集的相关个人信息的，我们会按照法律法规和相关监管部门的规定执行，向您说明个人信息出境的目的以及涉及的个人信息类型，征得您同意，并通过签订协议、现场核查等有效措施，要求境外机构为所获得的您的个人信息保密。
          <br />（2）在您使用伟东产品与/或服务期间，我们会持续保存您的个人信息。
          <br />（3）我们收集的有关您的信息和资料将保存在我们和/或我们的关联公司的服务器上。同时只会在达成本政策所诉目的所需的期限保留您的个人信息，除非法律要求强制留存的，例如《中华人民共和国电子商务法》中，要求商品和服务信息、交易信息保存期限自交易之日起不少于3年；《教育部等六部门关于规范校外线上培训的实施意见》规定，用户行为日志需留存1年以上。按照国家相关法律的规定，用于识别您个人身份以及反映相关活动的个人信息，将在中国内地存储和使用。
          <br />（4）如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。
        </p>
        <p class="title2">2.如何保护您的个人信息</p>
        <p>
          我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：
          <br />（1）我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。
          <br />（2）如我们停止“伟东”相关业务，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。
          <br />（3）采取了内部管理制度和技术保障等多方面的保护措施，以保护您的个人信息，具体包括但不限于以下方面：
          <br />内部管理制度上，我们根据数据的敏感程度和数据类型的不同采取了数据分类分级制度，并根据分类分级结果进行使用者权限、使用场景权限、使用授权权限、分级审批机制等多方面的权限限制；对于超期储存的个人信息，我们将删除您的个人信息。
          <br />技术保障措施上，我们在存储时采取了不可逆的加密存储、隔离存储，在信息展示和下载时采取了脱敏处理，传输时采取了专线传输、加密传输、密钥和数据介质隔离传输等技术措施，以确保您的个人信息安全。
          <br />定期安全审计上，我们通过审计机制来对隐私政策和相关规程的有效性以及数据安全进行全面核查与控制。
          <br />机制保障上，我们设立了信息安全团队，针对个人信息收集、使用、共享、委托处理等开展个人信息安全影响评估。同时，我们不断对工作人员培训相关法律法规及隐私安全准则和安全意识强化宣导，并每年组织全体工作人员参加安全考试。另外，我们的相应网络/系统通过了国家网络安全等级保护的测评。
          <br />我们已制定相关的信息安全事件应急预案，定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程。
          <br />（4）互联网环境并非百分之百安全，我们将尽力确保您发送给我们的任何信息的安全性。在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以网站窗口提示、APP推送通知、发送邮件/短消息、电话等方式告知您。难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。若您的合法权益受损，我们将承担相应的法律责任。
          <br />（5）互联网并非绝对安全的环境，我们强烈建议您通过安全方式、使用复杂密码，协助我们保证您的账号安全。如您发现自己的个人信息泄密，尤其是您的账户或密码发生泄漏，请您立即根据本政策中提供的联系方式联络我们，以便我们采取相应措施。
          <br />请注意，单独的设备信息、搜索关键词信息等无法与任何特定个人直接建立联系的数据，不属于个人信息。如果我们将这类非个人信息与其他信息结合用于识别自然人个人身份，或者将其与个人信息结合使用，在结合使用期间，此类设备信息将被视为个人信息。我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
        </p>
        <h2>七、您如何管理个人信息</h2>
        <p>根据适用的法律法规，我们将采取适当的技术手段，保证您访问、更新、更正或删除您的个人信息的权利。如有法律法规的要求导致我们无法满足您的请求的，我们将向您提供合理的解释。您对您的个人信息享有以下权利：</p>
        <p class="title2">1.访问权</p>
        <p>您有权访问伟东网站中的您的账户信息、学习信息、订单信息信息。您登录帐号后，即可访问我们保留的您的个人信息。</p>
        <p class="title2">2.更正权</p>
        <p>您有权通过伟东网站登录个人账户后，修改您原有的个人信息，包括但不限于昵称、学员号、登录邮箱、登录手机号、邮寄地址）。当您发现我们处理关于您的个人信息有错误且您无法在账户内自行修改时，您有权通过伟东网站所提供的“联系客服”或“意见反馈”功能，要求我们对错误或不完整的信息做出更正或更新。</p>
        <p class="title2">3.撤销权</p>
        <p>
          每个业务功能需要一些基础的个人信息才能完成改变您授权同意的范围或撤回授权，我们将通过以下方式保障您撤回同意的权利：
          <br />伟东发送的个性化信息中，会说明退订方式，您可以按照信息中说明的退订方式撤回同意。
          <br />如果您无法管理以上个人信息，可以随时联系我们的客服来解决。
        </p>
        <p class="title2">4.删除权</p>
        <p>当您不再使用我们伟东服务时，或我们违反法律法规或与您的约定收集、使用、与他人分享您的个人信息时，您有权删除您提供的信息。您可以在伟东网站，使用账号编辑的功能对信息进行删除，也可通过伟东网站提供联系方式与客服联系，提交删除申请。在满足本政策、用户协议要求及法律法规要求的最短保存期限的情况下，我们将对您的个人信息进行删除或匿名化处理，但这样可能导致我们无法向您提供相应的服务。</p>
        <p class="title2">5.注销权</p>
        <p>
          （1）除以下情形外，您可以自主删除个人信息或注销账户：
          <br />为配合人民检察院、公安机关、国家安全机关侦查用户使用伟东各应用过程中产生的犯罪行为，更好保护其他用户生命财产安全，为配合人民法院查清案情，您的个人信息和账户将被保存。
          <br />为遵守法律法规、政策等关于个人信息的保存期限的规定。
          <br />（2）您可伟东网站方式随时注销此前注册的账户，我们将尽快删除该账号。账户注销可能会导致清除您的所有用户数据和账户信息且不可恢复，造成我们无法为您提供服务；但在特定情形下，如合理必要地履行我们的法律义务、解决争议、防止欺诈与滥用，我们将在使用者账号注销后保留不可识别个人的信息。
        </p>
        <h2>八、如何保护未成年人的个人信息</h2>
        <p>
          1.我们极其重视对未成年人个人信息的保护。如果您是未满14周岁的儿童或未满18周岁的未成年人，请您的父母或监护人仔细阅读本政策，并在得到您的父母或监护人同意后使用我们的服务或向我们提供信息。
          <br />2.对于经过父母或监护人同意而收集未成年个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年所必要的情况下使用或公开披露相关的信息。 如您的监护人不同意您按照本政策使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。
        </p>
        <p>3.为加强对未成年人个人信息的保护，除遵循本隐私政策项下的其他规定外，伟东将遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则，根据国家相关法律法规及本隐私政策的规定保护未成年人的个人信息。具体内容如下：</p>
        <p>
          （1）伟东就所收集的未成年人信息和未成年人以外的用户个人信息分别存储，平台将采取不低于行业同行的加密措施存储未成年人信息，确保未成年人信息安全。
          <br />（2）伟东将指定专人负责未成年人信息保护，任何工作人员访问未成年人的个人信息的，必须经未成年人信息保护人员审批（包括电子邮件、短信、微信此类电子信息交互方式）并形成访问情况的记录；同时，采取不低于行业同行的技术措施，避免违法复制、下载未成年人的个人信息。
          <br />（3）如伟东平台需向第三方转移未成年人的个人信息时，除再次征得未成年人监护人的同意及满足上述原则外，将自行或者委托第三方机构进行安全评估，并形成评估报告。
        </p>
        <h2>九、适用法律及争议解决</h2>
        <p>本隐私政策的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律。如果您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院诉讼来寻求解决方案。</p>
        <h2>十、版本更新</h2>
        <p>我们可能会对隐私政策进行修改、如有重大更改（例如：业务功能、使用目的、个人信息保护相关联系方式等变更），我们将在新版隐私政策正式生效前，通过页面通知或消息推送的方式在伟东网站上公布，以方便您了解具体变化并向我们提出建议。如果您继续使用我们的服务即被视为对新版隐私协议的接受，如果您不接受修改的条款，您可注销账号。</p>
        <h2>十一、联系我们</h2>
        <p>
          1.如对本政策内容有任何疑问、意见或建议或发现个人信息被泄露，请通过以下方式与我们的与我们个人信息保护专职部门联系。
          <br />公司名称：北京伟东凌鸿教育科技有限公司
          <br />注册地址：北京市海淀区四道口路大钟寺怡和8号院
          <br />2.我们将会在最多十五日个工作日内对您的问题、投诉和建议进行处理。
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="userReadDialog = false" style="border-radius 22px ">好的，我已阅</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import $ from "jquery";
import config from '@/config/url'
import { isPhone, isEmail, passwordValidator } from '@/utils/validator'
// import md5 from "js-md5";

export default {
  name: 'register',
  data () {
    const accountValidator = (rule, value, callback) => {
      if (isPhone.test(value) || isEmail.test(value)) {
        callback()
      } else {
        let message = ''
        if(this.activeName === 'phone'){
          message = this.formData.account? '请输入正确的手机号':'请输入手机号'
        }else {
          message = this.formData.account? '请输入正确的邮箱账号':'请输入邮箱账号'
        }
        callback(new Error(message))
      }
    }
    return {
      passw: "password",
      activeName:'phone',
      tabsList: [{ key: 'phone', val: '手机号注册' }, { key: 'mail', val: '邮箱注册' }],
      Countdown1: 0,
      Countdown2: 0,
      rules: {
        account: [ { validator: accountValidator, trigger: 'blur' } ],
        authCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 6, max: 6, message: '请输入六位验证码', trigger: 'blur' }
        ],
        password:[
          { required: true, message: '登录密码不能为空', trigger: 'blur' },
          { validator: passwordValidator, trigger: 'blur' }
        ],
        rePassword:[{ required: true, message: '登录密码不能为空', trigger: 'blur' }]
      },
      formData: {
        account:null,
        authCode: null,
        password:null,
        rePassword:null
      },
      isRegistSuccess:false,
      hasreaded:false,
      userReadDialog:false,
      backCount:3
    }
  },
  computed:{
    theme(){
      return this.$store.state.theme
    },
    sendCodeBtnStyle(){
      let background = '#316fff'
      let CountdownType = this.activeName === 'phone'? 'Countdown1':'Countdown2'
      if(this.theme === 'yingji'){
        background = '#D63535'
      }else if(this.theme === 'wanghong'){
        background = 'linear-gradient(147deg, #7D7EFF 0%, #FF48A7 100%)'
      }else if(this.theme === 'shanghaijiaoda'){
        background = '#C51022'
      }
      
      return {
          background:0 >= this[CountdownType] ? background:'#BFC8D9',
          border:'none'
      }
    },
    registBtnStyle(){
      // background:disabled()?'#BFC8D9':'#316fff',opacity:'1',borderColor:'#EBEEF5'
      let background = '#316fff'
      if(this.theme === 'yingji'){
        background = '#D63535'
      }else if(this.theme === 'wanghong'){
        background = 'linear-gradient(147deg, #7D7EFF 0%, #FF48A7 100%)'
      }else if(this.theme === 'shanghaijiaoda'){
        background = '#C51022'
      }
      
      return {
          background:this.disabled() ? '#BFC8D9':background,
          border:'none'
      }
    }
  },
  created () {
    sessionStorage.clear();
    localStorage.clear();
    let url= config.confUrl
    this.configuration(url);
  },
  mounted () {
    
  },
  methods: {
    //   隐私协议跳转
    goPage(url){
        window.open(`${window.location.origin}/${url}`)
    },
    showPassw(){
      if(this.passw === 'password'){
        this.passw = 'text'
      }else{
        this.passw = 'password'
      }
    },
    disabled() {
      let result = false
      for (let key in this.formData){
        if(!this.formData[key]){
          result = true
          break
        }
      }
      if(!this.hasreaded){
        result = true
      }
      return result
    },
    configuration(url){
       this.$api.configure.configuration(url).then(res => {
        if(res.data){
          let frontendConfig = res.data.frontendConfig.find(item => {
              return item.key === "homepage";
          });
          localStorage.setItem("hasGetConfig", "1");
          localStorage.setItem("theme", res.data.theme || "");
          localStorage.setItem('domain', JSON.stringify(res.data.domain)?JSON.stringify(res.data.domain):'');
          this.$store.commit('setTheme',res.data.theme || "")
          localStorage.setItem("frontendConfig",frontendConfig ? JSON.stringify(frontendConfig) : "");
          localStorage.setItem("configurationArr",JSON.stringify(res.data.frontendConfig));
          this.initConfig();
        }
      })
    },
    initConfig() {
      /* 
        手机号+验证码 is_allow_telephone_register 
        手机号+密码   is_allow_email_register 
      */
     let configurationArr = JSON.parse( localStorage.getItem('configurationArr') )
      if (configurationArr.length>0) {
        configurationArr.forEach(item => {
          switch (item.key) {
            case 'custom_login_background_img':
              this.bigImg = item.value;
              break;
            case 'custom_login_img':
              this.smallImg = item.value;
              break;
            case 'is_allow_telephone_register':
              if(item.value === 'false'){
                let index = this.tabsList.findIndex(item => {return item.key === 'phone'})
                this.tabsList.splice(index,1)
              }
              break;
            case 'is_allow_email_register':
              if(item.value === 'false'){
                let index = this.tabsList.findIndex(item => {return item.key === 'mail'})
                this.tabsList.splice(index,1)
              }
              break;
            default:
              break;
          }
        })
        this.activeName = this.tabsList[0].key

        const imgsrc1 = "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/beijin.png";
        const imgsrc2 = "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/login/rectangle.png";
        this.$nextTick(() => {
          // 防止背景图闪现两次
          $(".loginBox").css("background-image",`url(${this.bigImg ? this.bigImg : imgsrc1})`);
          $('.login-left').css('background-image', `url(${this.smallImg ? this.smallImg : imgsrc2})`)
        });
      }
    },
    setActiveName (val) {
      if(val === this.activeName) return
      this.activeName = val
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
      // 重置表单
      this.formData = {
        account:null,
        authCode: null,
        password:null,
        rePassword:null
      }
      // this.co
    },
    async onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if(!this.hasreaded || !this.comparePassword()) return
          this.postAccountRegistByAuthCode()
        } else {
          return false
        }
      })
    },
    postAccountGetAuthCode (type) {
      let CountdownType = type === 'phone'? 'Countdown1':'Countdown2'
      let data = { account: this.formData.account,adminFlag: 0,type:2 }
      if(this[CountdownType] > 0) return
      this.formData.authCode = null
      if(type === 'phone'){
        if (isPhone.test(this.formData.account)) {
          this.Countdown1 = 60
          this.setCountdown(type)
          this.$sensors.track('UM_Click_Verification_Code_Button')
          this.$api.usercenter.getAuthCodeCommon({ data })
        }
      }else{
        if (isEmail.test(this.formData.account)) {
          this.Countdown2 = 60
          this.setCountdown(type)
          this.$sensors.track('UM_Click_Verification_Code_Button')
          this.$api.usercenter.getAuthCodeCommon({ data })
        }
      }
    },
    async postAccountRegistByAuthCode () {
      const data = {
        ...this.formData
      }
      // data.password && (data.password = md5(data.password).toLowerCase())
      // data.rePassword && (data.rePassword = md5(data.rePassword).toLowerCase())
      await this.$api.usercenter
        .postAccountRegister({ data })
        .then(res => {
          if (res.success) {
              this.$message.success('注册成功');
              this.isRegistSuccess = true
              localStorage.setItem("token", res.data);
              this.timer = setInterval(() => {
                this.backCount--
                if(this.backCount <= 0){
                  clearTimeout(this.timer)
                  // this.$router.push("/course");
                  localStorage.setItem("token", res.data);
                  this.toSkip()
                }
              }, 1000);
          }
        })
    },
    async toSkip(){
      this.getAccountUserInfo()
      await this.getFindUserBranchList();
      const orderPage = [ "gongxinDetails", "collegeDetails", "wanghongDetails" ]; //订单页

      if ( orderPage.includes(this.$route.query.name) && this.$route.query.type == "order" ) {
        this.checkUserCanByGoods(this.$route.query.id);
      } else if ( this.$route.query.name && this.$route.query.name !== "error_404" ) {
        this.$router.push(this.$route.query.name);
      } else {
        // 应急，中智，大平台登录之后跳转我的训练营
        // 这里的条件语句后期还要修改
        if (localStorage.getItem('frontendConfig') &&  localStorage.getItem('theme') && localStorage.getItem('theme') !== 'zhongzhi') {
          let val = JSON.parse(localStorage.getItem('frontendConfig')).value;
          this.$router.push(val || '/')
        } else {
          this.$router.push('/learning')
        }
      }
    },
    // 判断用户是都已购买课程
    checkUserCanByGoods(id) {
      this.$api.home.checkUserCanByGoods(id).then(res => {
        if (res.data) {
          // 未购买，跳转到订单页面
          this.$router.replace(`/orderPage?id=${id}&dymode=0`);
        } else {
          // 已购买，跳转到课程学习页
          this.$router.replace(
            `/course/catalog?id=${this.$route.query.referId}&dymode=1`
          );
        }
      });
    },
    getAccountUserInfo () {
      this.$api.usercenter.getAccountUserInfo({}).then(res => {
        this.$store.commit('saveUserInfo',res.data)
        localStorage.setItem('userInfo',JSON.stringify(res.data))
      })
    },
    getFindUserBranchList() {
      if (!localStorage.getItem("branchList")) {
        return this.$api.usercenter.getFindUserBranchList({}).then(res => {
          if (res.data && res.data.length) {
            const branchList = res.data
              .filter(
                item =>
                  Object.prototype.toString.call(item) === "[object Object]"
              )
              .map(item => {
                return {
                  name: item.name,
                  id: item.branch,
                  actor: item.actor,
                  organ: item.organ,
                  userId: item.userId,
                  code: item.code
                };
              });
            if (branchList.length) {
              localStorage.setItem("branchList", JSON.stringify(branchList));
              localStorage.setItem("code", branchList[0].code);
              localStorage.setItem("organ", branchList[0].organ);
              localStorage.setItem("actor", branchList[0].actor);
              localStorage.setItem("branch", branchList[0].id);
              localStorage.setItem("userId", branchList[0].userId);
              this.$sensors.login(branchList[0].code)
            }
          }
        });
      }
    },
    setCountdown (type) {
      let CountdownType = type === 'phone'? 'Countdown1':'Countdown2'
      let Countdown = null
      Countdown = setInterval(() => {
        this[CountdownType]--
        if (this[CountdownType] <= 0) {
          clearInterval(Countdown)
        }
      }, 1000)
    },
    comparePassword(){
      let result = true
      if(this.formData.password && this.formData.rePassword){
        if(this.formData.password !== this.formData.rePassword){
          this.$message.error('两次密码输入不一致')
          result = false
        }
      }
      return result
    }
  }
}
</script>

<style lang="stylus" scoped>
@import './../asset/css/login'
/deep/ .el-form-item
  margin-top 30px

.tabs
  background #fff
  font-size 24px
  color #999
  font-family: PingFangSC-Medium, PingFang SC;
  padding-left 0
  text-align: left
  li
    position relative
    cursor: pointer
    display inline-block
    font-size: 20px;
    text-align center
    line-height 40px
    height 40px
    margin-bottom 30px
    &:first-child::after
      position absolute
      right -20px
      top 14px
      content ''
      display inline-block
      width 1px
      height 15px
      background #E6E6E6

    &.highlight {
      border-bottom:none;
      color:#316FFF;
    }
    &.highlight.yingji {
      border-bottom:none;
      color:#D63535;
    }
    &.highlight.shanghaijiaoda {
      border-bottom:none;
      color:#D63535;
    }
    &.highlight.wanghong {
      border-bottom:none;
      color:#AD6ADE;
    }

    &.isHasOne:first-child::after
      display none
.form
  .rePassword
    /deep/ .el-input__suffix-inner
      >img
        width 22px
        height 22px
.read
  margin 30px 0 20px 0
  .agreement
    color #0F3EAA
    cursor pointer
  .agreement.yingji{
    color: #D63535;
  }
  .agreement.wanghong{
    color: #AD6ADE;
  }
  .agreement.shanghaijiaoda{
    color: #C51022;
  }
.read.yingji
  /deep/ .el-checkbox__input.is-checked+.el-checkbox__label
    color #D63535
  /deep/ .el-checkbox__input.is-focus .el-checkbox__inner
    border-color #D63535
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner
    background-color #D63535
    border-color #D63535
  .agreement
    color #D63535

.read.shanghaijiaoda
  /deep/ .el-checkbox__input.is-checked+.el-checkbox__label
    color #C51022;
  /deep/ .el-checkbox__input.is-focus .el-checkbox__inner
    border-color #C51022;
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner
    background-color #C51022;
    border-color #C51022;
  .agreement
    color #C51022;

.read.wanghong
  /deep/ .el-checkbox__input.is-checked+.el-checkbox__label
    color #AD6ADE
  /deep/ .el-checkbox__input.is-focus .el-checkbox__inner
    border-color #AD6ADE
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner
    background-color #AD6ADE
    border-color #AD6ADE
  .agreement
    color #AD6ADE
.btn{
  display flex
  align-items center
  .regist{
    width 190px
    height 36px
    margin-right 20px
    padding 0
    border-radius 22px
    background #316fff
    /deep/ span{
      color #fff
    }
  }
  .regist.is-disabled:hover{
    background #BFC8D9 !important
  }
  .toLogin {
    font-size: 12px;
    color: #1972ff;
  }
  .toLogin.yingji{
    color: #D63535;
  }
  .toLogin.shanghaijiaoda{
    color: #C51022;
  }
  .toLogin.wanghong{
    color: #AD6ADE;
  }
    
  /deep/ .el-button--primary span{
    font-size 14px
  }
}
.middle
  margin-top 108px
  margin-left 10px
  .registTitle
    font-size 30px
    font-weight 600
    color #316FFF
  .registTip
    margin-top 12px
    font-size 16px
    color #666666
  .toHome   
    margin-top 56px
    font-size 14px
    color #316FFF
.readdialog {
  /deep/ .el-dialog {
    height: 800px;
  }

  /deep/ .el-dialog__header {
    padding: 20px;
    font-size: 16px;
    background: #F8FAFF;
    font-weight: 600;
    border-radius 20px 20px 0 0 
  }

  /deep/ .el-dialog__footer {
    background: #F8FAFF;
    padding: 20px;
    border-radius 0 0 20px 20px
  }

  /deep/ .el-dialog__title{
    font-size 22px
  }

  /deep/ .el-dialog__body{
    padding 0 20px
  }

  /deep/ .el-button{
    padding: 0px 20px;
    border-radius: 22px;
    width: 158px;
    height: 36px;
  }

  .privacy-box{
    height 660px
    margin-bottom 20px
    overflow auto
  }

  .privacy-box h1, .privacy-box h2 {
    text-align: center;
    margin 20px 0
  }

  .privacy-box h2 {
    text-align: left;
  }

  .privacy-box p {
    color: #666;
    text-align: justify;
    font-size 16px
  }

  .privacy-box .yinyan {
    text-align: center;
    margin-bottom 20px
  }

  .privacy-box .title2 {
    font-weight: bold;
    color: #000;
    text-indent: 0px;
    margin 10px 0 2px 0
  }
}
</style>
