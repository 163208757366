const planList = [
    {
        id: 1,
        stageNum: '阶段一',
        stageName: '《数据分析与可视化》',
        describe: '本课程主要讲解Python数据分析和可视化内容。通过本课程学习，要求学员掌握数值计算库Numpy，数据分析库Pandas，科学计算库Scipy，数据可视化库Matplotlib使用方法等。',
        chapterList: [
            {
                chapter: '第一章',
                tasktitle: 'Python数据分析概述',
                taskcontent: [
                    '任务1、认识数据分析',
                    '任务2、熟悉Python数据分析相关工具',
                    '任务3、安装Python发行版-Anaconda',
                    '任务4、掌握Jupyter Notebook常用功能',
                ]
            },
            {
                chapter: '第二章',
                tasktitle: 'NumPy数值计算',
                taskcontent: [
                    '任务1、掌握 NumPy数组对象ndarray',
                    '任务2、掌握 NumPy矩阵与通用函数',
                    '任务3、利用 NumPy进行统计分析',
                ]
            },
            {
                chapter: '第三章',
                tasktitle: 'Pandas统计分析',
                taskcontent: [
                    '任务1、读写不同数据源的数据',
                    '任务2、掌握DataFrame的常用操作',
                    '任务3、转换与处理时间序列数据'
                ]
            },
            {
                chapter: '第四章',
                tasktitle: 'Pandas数据预处理',
                taskcontent: [
                    '任务1、数据合并',
                    '任务2、数据清洗',
                    '任务3、标准化与转换'
                ]
            }
        ]
    },
    {
        id: 2,
        stageNum: '阶段二',
        stageName: '《人工智能数学知识》',
        describe: '本课程主要讲解微积分、线性代数基础等内容。通过本课程学习，要求学员掌握导数，基本函数和极值定理的定义和公式，向量、矩阵的定义和运算等内容，具备基础的函数求导，向量和矩阵运算的能力。',
        chapterList: [
            {
                chapter: '第一章',
                tasktitle: ' 微积分基础',
                taskcontent: [
                    '第一节 导数的相关概念',
                    '第二节 导数的计算（一）',
                    '第三节 导数的计算（二）',
                    '第四节 导数的应用（一）',
                    '第五节 导数的应用（二）',
                    '第六节 导数的应用（三）',
                ]
            },
            {
                chapter: '第二章',
                tasktitle: '线性代数基础',
                taskcontent: [
                    '第一节 向量及其运算',
                    '第二节 矩阵及其运算',
                    '第三节 行列式',
                    '第四节 逆矩阵及其应用'
                ]
            }
        ]
    },
    // {
    //     id: 2,
    //     stageNum: '阶段二',
    //     stageName: '《数据采集与预处理》课程培训大纲',
    //     describe: '本课程主要讲解网络数据采集，基于Python的数据导入导出，基于Python的数据整理等内容。通过本课程学习，要求学员掌握爬虫核心技术，爬虫页面分析库和Selenium web测试方法，常用爬虫框架，反爬机制和应对策略等',
    //     chapterList: [
    //         {
    //             chapter: '第一章',
    //             tasktitle: '爬虫基础',
    //             taskcontent: [
    //                 '1.Python爬虫基础：urllib、requests库',
    //                 '2.解析网页数据：Xpath',
    //                 '3.爬虫实例：用Xpath获取某招聘网站数据',
    //             ]
    //         },
    //         {
    //             chapter: '第二章',
    //             tasktitle: '正则表达式',
    //             taskcontent: [
    //                 '1.解析网页数据：正则表达式',
    //                 '2.爬虫实例：用正则表达式获取某招聘网站数据',
    //                 '3.正则表达式：模式修饰符及应用',
    //                 '4.爬虫实例：电商网站图片爬取',
    //                 '5.正则表达式：常用正则实例',
    //                 '6.Ajax数据及相关案例'
    //             ]
    //         },
    //         {
    //             chapter: '第三章',
    //             tasktitle: '反爬应对策略',
    //             taskcontent: [
    //                 '1.反爬应对策略：浏览器模拟',
    //                 '2.反爬应对策略：代理IP设置',
    //             ]
    //         },
    //         {
    //             chapter: '第四章',
    //             tasktitle: '爬虫工具库',
    //             taskcontent: [
    //                 '1.Beautiful Soup用法',
    //                 '2.Beautiful Soup案例：爬取某招聘网站数据',
    //                 '3.电商网站爬虫案例（一）',
    //                 '4.电商网站爬虫案例（二）',
    //                 '5.selenium基本用法',
    //                 '6.selenium爬虫实例'
    //             ]
    //         },
    //     ]
    // },
    
    // {
    //     id: 3,
    //     stageNum: '阶段三',
    //     stageName: '《Python应用开发》课程',
    //     describe: '本课程主要讲解Python web开发，django视图，模板，模型，站点管理，表单，django部署，Flask框架使用，网络爬虫基础和网络爬虫进阶等内容。通过本课程学习，要求学员掌握前端核心基础和主流前端框架等。',
    //     chapterList: [
            
    //         {
    //             chapter: '第一章',
    //             tasktitle: ' Web开发基础',
    //             taskcontent: [
    //                 '第一节 Web模型和软件开发',
    //             ]
    //         },
    //         {
    //             chapter: '第二章',
    //             tasktitle: '基于Web的Django框架的使用',
    //             taskcontent: [
    //                 '第一节 Django简介',
    //                 '第二节 Django路由模块的使用',
    //                 '第三节 Django视图模块的使用',
    //                 '第四节 Django如何访问数据库',
    //                 '第五节 模板的使用',
    //             ]
    //         },
    //         {
    //             chapter: '第三章',
    //             tasktitle: '基于Web的Flask框架的使用',
    //             taskcontent: [
    //                 '第一节 Flask开发简介',
    //                 '第二节 Flask路由的使用',
    //                 '第三节 Flask视图处理技术',
    //                 '第四节 Flask模板的使用'
    //             ]
    //         },
    //     ]
    // },
    // {
    //     id: 4,
    //     stageNum: '阶段四',
    //     stageName: '《AI系统运维与部署（Ⅱ）》课程培训大纲',
    //     describe: '本课程主要讲解Web运维，大数据分布式系统运维，Docker 运维，自动化运维与监控等内容。通过本课程学习，要求学员掌握nginx负载均衡与单点故障常用解决方案，高可用web负载均衡解决方案等。',
    //     chapterList: [
            
    //         {
    //             chapter: '第一章',
    //             tasktitle: 'Web运维',
    //             taskcontent: [
    //                 '1.Nginx负载均衡',
    //                 '2.高可用web负载均衡方案——Lvs',
    //             ]
    //         },
    //         {
    //             chapter: '第二章',
    //             tasktitle: '自动化运维与监控',
    //             taskcontent: [
    //                 '1.ELK部署与日志收集',
    //                 '2.使用nagios进行系统监控',
    //                 '3.使用openfalcon进行系统监控'
    //             ]
    //         },
    //         {
    //             chapter: '第三章',
    //             tasktitle: '大数据分布式系统运维',
    //             taskcontent: [
    //                 '1.Hadoop 集群搭建及常用操作',
    //                 '2.Hive安装与使用',
    //                 '3.Hbase安装与使用',
    //                 '4.Spark环境搭建和常用操作'
    //             ]
    //         },
    //         {
    //             chapter: '第四章',
    //             tasktitle: 'Docker部署与运维',
    //             taskcontent: [
    //                 '1.Docker部署与基本应用',
    //                 '2.docker打包镜像与镜像仓库',
    //             ]
    //         }
            
    //     ]
    // },
    {
        id: 3,
        stageNum: '阶段三',
        stageName: '《机器学习原理与实战》',
        describe: '本课程主要讲解机器学习概述，监督学习，无监督学习，预处理与特征工程，模型评估与优化等内容。通过本课程学习，要求学员掌握监督学习的概念和常用算法，无监督学习的概念和常用算法等。',
        chapterList: [
            
            {
                chapter: '第一章',
                tasktitle: '机器学习概述',
                taskcontent: [
                    '任务1、认识机器学习',
                    '任务2、认识scikit-learn',
                    '任务3、安装python的anaconda发行版',
                    '任务4、构建第一个模型：鸢尾花（iris）分类'
                ]
            },
            {
                chapter: '第二章',
                tasktitle: '监督学习',
                taskcontent: [
                    '任务1、理解监督学习中的基本概念',
                    '任务2、基于K-最近邻算法的红酒分类',
                    '任务3、基于线性回归的波士顿房价预测'
                ]
            },
            {
                chapter: '第三章',
                tasktitle: '无监督学习',
                taskcontent: [
                    '任务1、基于多种聚类算法实现鸢尾花聚类',
                    '任务2、基于密度聚类DBSCAN的案例实践',
                ]
            },
            {
                chapter: '第四章',
                tasktitle: '预处理与特征工程',
                taskcontent: [
                    '任务1、应用数据缩放进行预处理',
                    '任务2、使用独热编码处理离散特征',
                    '任务3、缺失值的处理'
                ]
            }
            
        ]
    },
    {
        id: 4,
        stageNum: '阶段四',
        stageName: '《深度学习原理与实战》',
        describe: '',
        chapterList: [
            
            {
                chapter: '第一章',
                tasktitle: '深度学习入门',
                taskcontent: [
                    '任务1、初识深度学习',
                    '任务2、机器学习简史回顾',
                    '任务3、理解神经网络的工作原理'
                ]
            },
            {
                chapter: '第二章',
                tasktitle: 'Tensorflow框架',
                taskcontent: [
                    '任务1、Windows下Tensorflow平台搭建',
                    '任务2、Linux下Tensorflow平台搭建',
                    '任务3、理解张量(Tensor)与计算图',
                    '任务4、使用Keras搭建序贯式模型'
                ]
            },
            {
                chapter: '第三章',
                tasktitle: '构建前馈神经网络',
                taskcontent: [
                    '任务1、前馈神经网络机制解析',
                    '任务2、前馈网络实现MNIST手写数字识别',
                    '任务3、模型的保存与加载使用'
                ]
            },
            {
                chapter: '第四章',
                tasktitle: '卷积神经网络应用',
                taskcontent: [
                    '任务1、理解卷积神经网络的原理及优势',
                    '任务2、掌握卷积神经网络参数数量的计算',
                    '任务3、基于卷积神经网络的表情识别案例'
                ]
            }
            
        ]
    },
    {
        id: 5,
        stageNum: '阶段五',
        stageName: '《计算机视觉》',
        describe: '本课程主要讲解图像基础，图像基本处理，图像平滑技术与边缘检测技术，图像轮廓检测技术与形态学技术，图像特征提取技术，图像字符识别（OCR）等内容。',
        chapterList: [
            
            {
                chapter: '第一章',
                tasktitle: '计算机视觉概述',
                taskcontent: [
                    '第一节 概述与环境',
                ]
            },
            {
                chapter: '第二章',
                tasktitle: '计算机图像基本处理',
                taskcontent: [
                    '第一节 图像显示与色彩',
                    '第二节 绘制与填充',
                    '第三节 用户交互'
                ]
            },
            {
                chapter: '第三章',
                tasktitle: '图像阈值处理与直方图',
                taskcontent: [
                    '第一节 几何变换',
                    '第二节 图像二值化',
                    '第三节 图像直方图'
                ]
            },
            {
                chapter: '第四章',
                tasktitle: '图像边缘检测技术',
                taskcontent: [
                    '第一节 图像卷积',
                    '第二节 图像去噪 ',
                ]
            }
            
        ]
    },
    {
        id: 6,
        stageNum: '阶段六',
        stageName: '《自然语言处理实战》',
        describe: '本课程主要讲解文本表示，文本分类，文本生成，命名实体识别，机器翻译，关系抽取等内容。通过本课程学习，要求学员掌握基于word2vec和glove的词向量理论及应用等。',
        chapterList: [
            
            {
                chapter: '第一章',
                tasktitle: '自然语言处理概述',
                taskcontent: [
                    '任务一 课程导学',
                    '任务二 认识自然语言处理',
                ]
            },
            {
                chapter: '第二章',
                tasktitle: '文本表示',
                taskcontent: [
                    '任务一 了解传统文本表示',
                    '任务二 掌握词向量理论',
                    '任务三 基于word2vec的短文本相似度计算实战（1）数据集简介及数据预处理',
                    '任务四 基于word2vec的短文本相似度计算实战（2）模型实现及训练预测'
                ]
            },
            {
                chapter: '第三章',
                tasktitle: '文本分类',
                taskcontent: [
                    '任务一 了解文本分类',
                    '任务二 掌握CNN原理',
                    '任务三 掌握TextCNN原理',
                    '任务四 基于TextCNN的虚假新闻检测实战（1）数据集简介及数据预处理',
                    '任务五 基于TextCNN的虚假新闻检测实战（2）数据加载器与模型实现',
                    '任务六 基于TextCNN的虚假新闻检测实战（3）模型训练及预测'
                ]
            },
            {
                chapter: '第四章',
                tasktitle: '文本生成',
                taskcontent: [
                    '任务一 掌握TextRNN原理',
                    '任务二 了解文本生成',
                    '任务三 基于TextRNN的文本生成实战（1）数据集简介及数据预处理',
                    '任务四 基于TextRNN的文本生成实战（2）数据加载器与模型实现',
                    '任务五 基于TextRNN的文本生成实战（3）模型训练及预测'
                ]
            }
            
        ]
    }
]
export default planList;