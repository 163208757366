<template>
    <div class="workers">
        <div class="workers-content" v-if="isShow && !isnoData">
            <h2>家政服务人员信用报告</h2>
            <p class="workers-time">更新日期: {{ workersObj.updateTime && workersObj.updateTime.split(' ')[0] }}</p>
            <div class="workers-information" v-if="workersObj">
                <div class="workers-information-title">家政人员基本信息</div>
                <div class="workers-information-main _clear">
                    <!-- <div class="workers-information-main-img"> -->
                    <img
                        :src="
                            workersObj.headImg ||
                            require('../../asset/images/jiazheng-head-icon.png')
                        "
                        alt=""
                    />
                    <!-- </div> -->
                    <div class="workers-information-main-right">
                        <div class="workers-information-main-right-li">
                            姓名：{{ workersObj.personalName }}
                        </div>
                        <div class="workers-information-main-right-li _clear">
                            <span>所属家政公司：</span>
                            <div
                                v-if="
                                    workersObj.companyNameList &&
                                    workersObj.companyNameList.length > 0
                                "
                            >
                                <p
                                    v-for="(
                                        item, index
                                    ) in workersObj.companyNameList.slice(0, 5)"
                                    :key="index"
                                >
                                    {{ item }}
                                </p>
                                <p v-if="workersObj.companyNameList.length > 5">
                                    ......
                                </p>
                            </div>
                            <div v-else>
                                <p>未查询到数据</p>
                            </div>
                        </div>
                        <div class="workers-information-main-right-li">
                            从属关系：{{
                                workersObj.companyRealtionType || "未查询到数据"
                            }}
                        </div>
                    </div>
                </div>
            </div>
            <p
                class="workers-information-tip"
                v-if="workersObj.isRiskWaring == 1"
            >
                <i class="el-icon-warning"></i>预警:
                该家政服务人员在“犯罪背景核查”中存在风险, 请谨慎雇佣
            </p>
            <div class="workers-credit">
                <p>信用分值：{{ workersObj.creditScore || "未查询到数据" }}</p>
                <p>
                    信用等级：<span v-for="(item, index) in 5" :key="index"
                        ><img
                            :src="
                                workersObj.creditScoreLevel > index
                                    ? require('../../asset/images/stars-icon1.png')
                                    : require('../../asset/images/stars-icon2.png')
                            "
                    /></span>
                </p>
            </div>
            <div class="workers-model">
                <div id="workersMain" style="width: 421px; height: 312px"></div>
                <div class="workers-legend">
                    <p class="zhiye"><span></span>职业化</p>
                    <p class="chengxin"><span></span>诚信</p>
                </div>
                <!-- <img src="../../asset/images/model.png" alt=""> -->
            </div>
            <!-- 个人基本信息 -->
            <div class="workers-table">
                <h4>个人基本信息</h4>
                <div class="workers-table-list">
                    <ul>
                        <li class="_clear">
                            <div class="workers-table-list-left">身份证号</div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalIdCard || "未查询到数据"
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">姓名</div>
                            <div class="workers-table-list-right">
                                {{ workersObj.personalName || "未查询到数据" }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">性别</div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalGender || "未查询到数据"
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">教育水平</div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalWorkerEducation ||
                                    "未查询到数据"
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">家庭地址</div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalCurrentAddressCode ||
                                    "未查询到数据"
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">民族</div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalNation || "未查询到数据"
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">健康状况</div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalHealth || "未查询到数据"
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">
                                上门服务证
                            </div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalServiceCertificate ||
                                    "未查询到数据"
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">
                                是否购买家政保险
                            </div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalIsNsurance ||
                                    "未查询到数据"
                                }}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 个人职业信息 -->
            <div class="workers-table">
                <h4>个人职业信息</h4>
                <div class="workers-table-list">
                    <ul>
                        <li class="_clear">
                            <div class="workers-table-list-left">从业经验</div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalWorkExperience ||
                                    "未查询到数据"
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">
                                受雇机构最高信用等级
                            </div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalHighestCreditRating ||
                                    "未查询到数据"
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">
                                受表彰奖励情况
                            </div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalRecognitionAndReward ||
                                    "未查询到数据"
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">
                                受处罚情况
                            </div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalPunishment ||
                                    "未查询到数据"
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">
                                消费者评价
                            </div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalConsumerEvaluation?workersObj.personalConsumerEvaluation+'星':'未查询到数据'
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">
                                被投诉次数
                            </div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalComplaintsNum?workersObj.personalComplaintsNum+'次':'未查询到数据'
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">工作年限</div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalExperience ||
                                    "未查询到数据"
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">手机号</div>
                            <div class="workers-table-list-right">
                                {{ workersObj.personalPhone || "未查询到数据" }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">
                                上家工作薪资
                            </div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalLastWorkSalary ||
                                    "未查询到数据"
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">工作照片</div>
                            <div
                                class="workers-table-list-right workers-table-list-right1"
                                v-if="
                                    workersObj.personalWorkPhotos &&
                                    workersObj.personalWorkPhotos.length > 0
                                "
                            >
                                <span
                                    class="marginright10"
                                    v-for="(
                                        item, index
                                    ) in workersObj.personalWorkPhotos.slice(
                                        0,
                                        9
                                    )"
                                    :key="index"
                                >
                                    <img :src="item" alt="" />
                                </span>
                                <span
                                    v-if="
                                        !workersObj.personalWorkPhotos ||
                                        workersObj.personalWorkPhotos.length ==
                                            0
                                    "
                                    >未查询到数据</span
                                >
                            </div>
                            <div class="workers-table-list-right" v-else>
                                未查询到数据
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 犯罪背景核查 -->
            <div class="workers-table">
                <h4>犯罪背景核查</h4>
                <div class="workers-table-list">
                    <ul>
                        <li class="_clear">
                            <div class="workers-table-list-left">身份真伪</div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalIsIdCard ||
                                    "未查询到数据"
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">
                                违规或不良记录
                            </div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalBadRecord ||
                                    "未查询到数据"
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">
                                重症精神病人
                            </div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalMentalPatient ||
                                    "未查询到数据"
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">
                                三年内是否吸毒人员和贩毒人员
                            </div>
                            <div class="workers-table-list-right height50">
                                {{
                                    workersObj.personalDrugTraffickers ||
                                    "未查询到数据"
                                }}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 犯罪背景核查 -->
            <div class="workers-table">
                <h4>培训与证书</h4>
                <div class="workers-table-list">
                    <ul>
                        <li class="_clear">
                            <div class="workers-table-list-left">素质测评</div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personal1ualityEvaluation ||
                                    "未查询到数据"
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">专业测评</div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalProfessionalEvaluation ||
                                    "未查询到数据"
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">
                                学习总时长
                            </div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalTotalStudyTime?workersObj.personalTotalStudyTime+'小时':"未查询到数据"
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">
                                完成培训课程
                            </div>
                            <div class="workers-table-list-right">
                                {{
                                    workersObj.personalTaskName ||
                                    "未查询到数据"
                                }}
                            </div>
                        </li>
                        <li class="_clear">
                            <div class="workers-table-list-left">
                                职业技能证书
                            </div>
                            <div class="workers-table-list-right workers-table-list-certificate _clear">
                                <p class="certificate-one" v-if="workersObj.personalSCertificateName">
                                    {{workersObj.personalSCertificateName}}
                                    <img src="../../asset/images/pt.png" alt="" />
                                </p>
                                <p class="certificate-two" v-if="workersObj.jzbCertificateName">
                                    {{workersObj.jzbCertificateName}}
                                    <img src="../../asset/images/dsf.png" alt="" />
                                </p>
                                <div v-if="!workersObj.jzbCertificateName && !workersObj.personalSCertificateName">未查询到数据</div>
                                
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="noData" v-if="isShow && isnoData">
            <img src="../../asset/images/noData.png" alt="" />
            <p>该“身份证号+姓名”未查询到具体的家政服务人员，请确认后再查询！</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "workers",
    props: {
        name: {
            type: String,
            default: "",
        },
        idcard: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            isShow: false,
            count: 3,
            workersObj: {},
            isnoData: false,
            indicator: [],
            seriesData: [],
        };
    },
    created() {},
    methods: {
        findByNameAndIdCard() {
            let data = {
                userName: this.name,
                idcardNo: this.idcard,
            };
            this.$api.sdjz.findByNameAndIdCard({ data }).then((res) => {
                if (res.success == true) {
                    this.workersObj = res.data;
                    if (this.workersObj) {
                        this.isnoData = false;
                        this.indicator = [];
                        this.seriesData = [];
                        this.workersObj.dimensionScoreDtoList.forEach(
                            (item) => {
                                this.indicator.push({
                                    text: item.indicatorName,
                                    max: item.indicatorMaxScore,
                                    val: item.indicatorScore,
                                });
                                this.seriesData.push(item.indicatorScore);
                            }
                        );
                        setTimeout(() => {
                            this.$nextTick(() => {
                                this.drawChart();
                            });
                        }, 1000);
                    } else {
                        this.isnoData = true;
                    }
                    this.$forceUpdate();
                } else {
                    this.isnoData = true;
                }
                this.isShow = true;
            });
        },
        drawChart() {
            console.log("this.indicator", this.indicator);
            console.log("this.seriesData", this.seriesData);
            const that = this;
            var myChart = that.$echarts.init(
                document.getElementById("workersMain")
            );
            var itemArr = [this.seriesData[0], this.seriesData[1], this.seriesData[2], this.seriesData[3]];   //数据数组

            var n1 = [this.seriesData[0], '', '', '', '', '']
            var n2 = ['', this.seriesData[1], '', '', '', '']
            var n3 = ['', '', this.seriesData[2], '', '', '']
            var n4 = ['', '', '', this.seriesData[3], '', '']
            var n5 = ['', '', '', '', '', '']
            // var labelArr = ['外观', '拍照', '系统', '性能'] //显示图例用
            myChart.setOption({
               
                radar: [
                    {
                        
                        splitLine: {
                            lineStyle: {
                                color: [
                                    "#E3E3E3",
                                    "#E9E9E9",
                                    "#E3E3E3",
                                    "#E9E9E9",
                                ],
                                width: 3,

                            }
                        },
                        
                    },
                    {
                        indicator: this.indicator,
                        nameGap: 10,
                        splitNumber: 4,
                        center: ['50%', '50%'],
                        radius: 95,
                        name: {
                            rich: {
                                a: {
                                    color: '#5E5E5E',
                                    fontSize: '12px',
                                },
                                b: {
                                    color: '#39A76E',
                                    align: 'center',
                                    padding: 3,
                                    fontSize: '18px',
                                    fontWeight: '500'
                                    // backgroundColor: '#666',
                                },
                                c: {
                                    color: '#1C53E6',
                                    align: 'center',
                                    padding: 3,
                                    fontSize: '18px',
                                    fontWeight: '500'
                                    // backgroundColor: '#666',
                                }
                            },
                            formatter: (a,b)=>{
                                if(b.text=='培训与证书'){
                                    return `{a|${a}}\n{c|${b.val}}`
                                }else{
                                    return `{a|${a}}\n{b|${b.val}}`
                                }
                                
                            },

                            textStyle: {
                                color: '#5E5E5E',
                                fontSize: 12,
                                fontWeight: 400,
                            }
                        },
                        splitArea: {
                            // 坐标轴在 grid 区域中的分隔区域，默认不显示。
                            show: true,
                            areaStyle: {
                                // 分隔区域的样式设置。
                                color: [
                                    "rgba(226, 226, 226, 0.32)",
                                    "rgba(236, 236, 236, 0.27)",
                                    "rgba(226, 226, 226, 0.32)",
                                    "rgba(236, 236, 236, 0.27)",
                                ],
                                // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
                            },
                        },

                        axisLine: {
                            // (圆内的几条直线)坐标轴轴线相关设置
                            lineStyle: {
                                color: "rgba(165, 165, 165, 0.6)",
                                // 坐标轴线线的颜色。
                                width: 1,
                                // 坐标轴线线宽。
                                type: "solid",
                                // 坐标轴线线的类型。
                            },
                        },

                        splitLine: {
                            // (这里是指所有圆环)坐标轴在 grid 区域中的分隔线。
                            lineStyle: {
                                color: [
                                    "#E3E3E3",
                                    "#E9E9E9",
                                    "#E3E3E3",
                                    "#E9E9E9",
                                ],
                                // 分隔线颜色
                                width: 3,
                                // 分隔线线宽
                            },
                        },

                    }
                ],
                series: [
                    {
                        type: 'radar', //绘制总的图形不设置拐点
                        radarIndex: 1,
                        data: [
                            {
                                value: itemArr ,
                                lineStyle: {
                                    width: 2,
                                    color: 'rgba(52, 195, 121, 1)'
                                },
                                areaStyle: {
                                    normal: {
                                        opacity: 1,
                                        color: "rgba(180, 248, 213, 0.67)",
                                    }
                                }
                            }
                        ],
                        silent: true,
                        z: 1 //图层是一层一层叠加，所以这个也是一级一级递增
                    },
                    {
                        type: 'radar', //绘制第一个点
                        radarIndex: 1,
                        name: '',
                        silent: true,
                        z: 2, //图层是一层一层叠加，所以这个也是一级一级递增
                        data: [
                            {
                                value: n1,
                                symbolSize: 6,
                            }
                        ],
                        itemStyle: {
                            normal: {
                                borderWidth: 3,
                                color: 'rgba(57, 167, 110, 1)',
                                show: true,
                                borderColor: "rgba(52, 195, 121, 0.25)",
                            }
                        },
                        lineStyle: {
                            width: 0,
                            labelLine: {
                                show: false   //隐藏标示线
                            }
                        }
                    },
                    {
                        type: 'radar',
                        radarIndex: 1, //绘制第二个点
                        name: '',
                        silent: true,
                        z: 3, //图层是一层一层叠加，所以这个也是一级一级递增
                        data: [
                            {
                                value: n2,
                                symbolSize: 6,
                            }
                        ],
                        itemStyle: {
                            borderWidth: 3,
                            color: 'rgba(67, 72, 237, 1)',
                            show: true,
                            borderColor: "rgba(67, 72, 237, 0.17)",
                        },
                        lineStyle: {
                            width: 0,
                            labelLine: {
                                show: false   //隐藏标示线
                            }
                        },
                        
                    },
                    {
                        type: 'radar', //绘制第三个点
                        radarIndex: 1,
                        name: '',
                        silent: true,
                        z: 4, //图层是一层一层叠加，所以这个也是一级一级递增
                        data: [
                            {
                                value: n3,
                                symbolSize: 6,
                            }
                        ],
                        itemStyle: {
                            normal: {
                                borderWidth: 3,
                                color: 'rgba(57, 167, 110, 1)',
                                show: true,
                                borderColor: "rgba(52, 195, 121, 0.25)",
                            }
                        },
                        lineStyle: {
                            width: 0,
                            labelLine: {
                                show: false   //隐藏标示线
                            }
                        },
                        
                    },
                    {
                        type: 'radar', //绘制第四个点
                        radarIndex: 1,
                        name: '',
                        silent: true,
                        z: 5, //图层是一层一层叠加，所以这个也是一级一级递增
                        data: [
                            {
                                value: n4,
                                symbolSize: 6,
                            }
                        ],
                        itemStyle: {
                            normal: {
                                borderWidth: 3,
                                color: 'rgba(57, 167, 110, 1)',
                                show: true,
                                borderColor: "rgba(52, 195, 121, 0.25)",
                            }
                        },
                        lineStyle: {
                            width: 0,
                            labelLine: {
                                show: false   //隐藏标示线
                            }
                        },
                        
                    },
                
                    {
                        type: 'radar', //这个图层是为了盖住圆心，如果去掉，圆心的颜色为最后一个图层的颜色（不信就自己试试）
                        radarIndex: 1,
                        name: '',
                        silent: true,
                        z: 6, //图层是一层一层叠加，所以这个也是一级一级递增
                        data: [
                            {
                                value: n5,
                                symbolSize: 6,
                            }
                        ],
                        itemStyle: {
                            normal: {
                                borderWidth: 3,
                                color: 'rgba(180, 248, 213, 0.67)',
                                show: true,
                                borderColor: "rgba(180, 248, 213, 0.67)",
                            }
                        },
                        lineStyle: {
                            width: 0,
                            labelLine: {
                                show: false   //隐藏标示线
                            }
                        },
                        
                    },
                ]
            });
        },
    },
};
</script>
<style lang='stylus' scoped>
@import '../../asset/css/workers.styl';
</style>