<template>
  <div>
    <div class="minzheng minzheng-nav" :style="bkobj">
      <div class="menuContents">
        <img class="imglogoG fl" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/newlog@2x.png" alt="" />
        <div class="minzheng-nav-bar fl">
          <p class="item-nav fl" 
            v-for="item in navList" 
            :key="item.id"
            :class="{active: activeNav == item.path}"
            @click="clickNav(item)"
          >
            {{item.title}}
          </p>
          <el-dropdown class="move fl" placement="bottom">
            <span class="el-dropdown-linkapp">
              <span class="dropdown-title link-app">前往移动端</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="nav-mz-menu">
              <el-dropdown-item class="nav-mz-item">
                <div class="minzheng-dl-wrap">
                  <div class="img-wrap">
                    <div id="qrcode3"></div>
                    <img src="@assets/user/border-dec.png" class="border border1"/>
                    <img src="@assets/user/border-dec.png" class="border border2"/>
                  </div>
                  
                  <p>移动学习 随时随地</p>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div v-if="!token" class="un-login fl" >
            <!-- <p @click="jumpLogin('minzheng_homepage','login')" style="margin-left:0;">登录</p> -->
            <p @click="jumpLogin('minzheng_homepage','register-mz')">登录/注册</p>
        </div>
        <template v-else>
          <account :replacePage="'minzheng_homepage'"/>
        </template>
      </div>
    </div>
      <div v-if="!isHome" style="height:100px;"></div>
  </div>
</template>

<script>
import account from './account.vue'
import QRCode  from "qrcodejs2"
export default {
  name: 'minzhengNav',
  components: {
    account
  },
  data () {
    return {
      activeNav: this.$route.path,
      examId: 0,
      trainId: 0,
      token: '',
      isHome:true,
      bkobj:{
        position:'absolute',
        background:'transparent'
      },
      mode: '0', //  0:其他 1' 课程中心 '2': 练习中心
      navList: [
        {
          path: '/minzheng_homepage',
          needLogin: false,
          title: '首页',
          activePaths: ['/minzheng_homepage'],
          mode: '0'
        },
        {
          path: '/courseGoods',
          needLogin: true,
          title: '课程中心',
          activePaths: ['/courseGoods','/moreCourseGoods','/courseGoodsDetail'],
          mode: '1',
        },
        {
          path: '/practiceList',
          needLogin: true,
          title: '练习中心',
          activePaths: ['/practiceList','/pattern','/examList','/course/testPaperDetails','/course/handPaper','/exam/examresults','/course/resdExam','/course/testPaper'],
          mode: '2',
        },
        {
          path: '/personalCenter',
          needLogin: true,
          title: '个人中心',
          activePaths: ['/personalCenter'],
          mode: '3'
        },
      ],
      // goodsInfo:null
    }
  },
  created () {
    //   console.log(this.navList)
  },
  watch: {
    $route(to) {
      if(to.name=='minzheng_homepage'){
        this.isHome = true;
        this.bkobj = {
          position:'absolute',
          background:'transparent'
        }
      }else{
        this.isHome = false;
        this.bkobj = {
          position:'fixed',
          background:'rgba(202, 76, 45, 0.8)'
        }
      }
      setTimeout(() => { //路由跳转
        // this.navList[1].path = ''
        sessionStorage.setItem('index',this.$route.meta.highlight)
        this.activeNav = this.$route.meta.highlight
        this.token = localStorage.getItem('token')
      }, 100)
    }
  },
  async mounted () {
    this.qrcode()
    setTimeout(() => { //刷新
      this.activeNav = sessionStorage.getItem('index')  || this.activeNav
      this.token = localStorage.getItem('token')
    }, 100)
    // this.findSaleGoods()
    if(this.$route.name=='minzheng_homepage'){
        this.isHome = true;
        this.bkobj = {
          position:'absolute',
          background:'transparent'
        }
    }else{
        this.isHome = false;
        this.bkobj = {
          position:'fixed',
          background:'rgba(202, 76, 45, 0.8)'
        }
    }
  },
  methods: {
    async qrcode () {
            let domainArr = localStorage.getItem('domain')
            let domainUrl = domainArr && JSON.parse(domainArr).find(item => item.frontend_template_id == 4).domain
            this.QRCode = new QRCode('qrcode3', {
                width: 130,
                height: 130,        // 高度
                text:  `https://${domainUrl || 'minzheng.saas-h5.testing3.wdeduc.com'}/minzheng_homepage`,   // 二维码内容
                display: 'none'
                // render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
                // background: '#f0f',   // 背景色
                // foreground: '#ff0'    // 前景色
            })
        },
      // async findSaleGoods(){
      //    await this.$api.home.findSaleGoods().then(res=>{
      //       this.goodsInfo = res.data
      //   })
      // },
    clickNav(item) {
    //   if (this.$route.path === '/course/testPaper') {
    //     return;
    //   }
      let name = item.path;
      console.log(item.path,'item.path')
      if (item.needLogin && !localStorage.getItem('token')) {
        this.jumpLogin(name,'login');
      } else if (name) {
        this.$router.push(name)
      }
      
    },
    /* 工信是否购买过课程 */
    // checkUserCanByGoods(item) {
    //     this.$api.home.checkUserCanByGoods(this.goodsInfo.id).then(res=>{
    //         if (res.data == true) {
    //             this.$message.info({
    //                     message: '您尚未购买课程，先去购买课程吧',
    //                     offset: 120
    //             })
    //             this.$router.push({
    //                 path:'/gongxinDetails'
    //             })
    //         }else {
    //             let name = ''
    //             item.path = name = this.goodsInfo.goodsType === 1 ? '/course/catalog' : '/learning/taskDetail'
    //             name = `${name}?id=${this.goodsInfo.referId}&taskType=${this.goodsInfo.goodsType}`
    //             this.$router.push(name)
                
    //         }
    //     })
    // },
    jumpLogin(name,type) {
      this.$router.push({
        name: type,
        query: {
            name
        }
      })
    },
  }
}
</script>
<style lang="stylus" scoped>
</style>
<style lang="stylus">
.minzheng-nav {
    background: transparent;
    // box-shadow: 0px 2px 24px 0px rgba(34, 34, 55, 0.06);
    font-family: PingFangSC-Regular, PingFang SC;
    height: 100px;
    position: absolute;
    width: 100%;
    z-index: 666;
    .top-account .user-dropdown .user-account{
      line-height: 100px;
    }
    .top-account .user-img{
      margin-top: 30px
    }
    .top-account .user-dropdown .user-account .txt,.top-account .user-dropdown .user-account i{
      color:#fff;
    }
    .menuContents {
      max-width: 1140px !important;
      margin: 0 auto;
      min-width: 1140px;
      background: #fff;
    }
    .imglogoG {
      width: 260px !important;
      margin-top: 36px !important;
    }
    .minzheng-nav-bar {
      height: 100px;
      margin-left: 133px;
      .item-nav {
        display: inline-block;
        font-size: 16px;
        line-height: 100px;
        font-weight: 600;
        margin:0 48px 0 0;
        cursor: pointer;
        color: #FFFFFF;
      }
      .item-nav:first-child{
           margin: 0 56px 0 36px;
      }
      .item-nav.active {
        color: #FFBF69;
        font-size:16px;
        position :relative;
      }
      // .item-nav.active::after{
      //       content: '';
      //       width: 28px;
      //       display: block;
      //       height: 4px;
      //       background: #d14242;
      //       position: absolute;
      //       left: 50%;
      //       transform: translateX(-50%);
      //       top: 60px;
      //       border-radius: 2px;
      // }
    }
    .move {
        .el-icon-arrow-down.el-icon--right {
            display: none;
        }
        .el-dropdown-linkapp .link-app {
          line-height 100px !important;
          color :#ffffff;
          margin-right: 0;
          font-size:16px;
          font-weight :600;
        }
        :focus{
          outline: none !important;
        }
    }
    .un-login {
        // background: rgba(215, 215, 215, .29);
        
        // width: 120px;
        // height: 42px;
        text-align: center;
        line-height: 42px;
        border-radius: 21px;
        margin-top: 29px;
        
        // cursor: pointer;
        margin-left: 90px;
        p {
            color: #ffffff;
            font-size:14px;
            display inline-block
            width: 111px;
            height: 42px;
            text-align:center;
            font-weight: 600;
            line-height 42px;
            // border-radius: 18px;
            border: 2px solid #FFFFFF;
            cursor:pointer;
        }
    }
    .move.el-dropdown {
        color:  #000;
        span {
            font-size: 18px;
        }
    } 
}
.nav-mz-menu {
      text-align: center;
      border-radius: 6px;
      margin-top: 0px !important;
      .nav-mz-item {
          &:hover {
            background: #fff;
          }
          .minzheng-dl-wrap {
            padding: 0 74px;
            .img-wrap {
              width: 140px;
              height: 140px;
              border: 1px solid #F6F6F6;
              position: relative;
              margin: 46px auto 18px;
              .app-download {
                  width: 100%;
                  height: 100%;
              }
              .border {
                width: 14px;
                height: 14px;
                position: absolute;
                &.border1 {
                  left: 0;
                  top: 0;
                }
                &.border2 {
                  right: 0;
                  bottom: 0;
                  transform: rotate(180deg);
                }
              }
            }
            

            p {
                line-height: 22px;
                font-size: 16px;
                color: #666;
                margin-bottom: 40px;
            }
          }
          
      }
  }
  #qrcode3{
    margin :5px 0 0 5px;
  }
</style>
