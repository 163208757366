<template>
    <div class="anquan-header-box">
        <div class="top">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhaohu/header-banner.png" class="logo" alt="">
        </div>
        <div class="nav">
            <div class="nav-name-ul">
                <div class="nav-name-ul-li"
                    v-for="(item, index) in navList"
                    :key="index"
                >
                    <span
                        :class="isSelect === item ? 'active' : ''"
                        @click="selectNav(item)"
                    >
                        {{item.name}}
                    </span>
                    <ul v-if="item.subList && item.subList.length>0">
                        <li v-for="(childItem,childIndex) in item.subList" @click="selectNav(childItem,item)" :key="childIndex">{{childItem.name}}</li>
                    </ul>
                </div>
                <div class="fr button">
                    <p class="btn1 btn" @click="goPage(1)">培训机构</p>
                    <p class="btn2 btn" @click="goPage(2)">就业服务机构</p>
                </div>
            </div>
        </div>
        <div v-show="breadList.length" class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item v-for="(item,index22) in breadList" :key="index22+'111'" :to="{ path: item.url }">
                    {{item.name}}
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
    </div>
</template>
<script>
import URL from '../../../../config/url';
export default {
    name:'anquanNav',
    data(){
        return{
            navList:[],
            isSelect:'',
            breadList:[],
        }
    },
    mounted(){
        this.getNavs()
    },
    methods:{
        goPage(type){
            if(type == 1){
                window.open('https://jkzhspx.wdeduc.com/zhaohupx_homepage')
            }else if(type == 2){
                window.open('http://qiye.jiafuyun.com')
            }
        },
        // 获取导航头数据
        getNavs() {
            if (!this.navList.length) {
                this.$api.home.getMenuNew(URL.confUrl).then((res) => {
                    if (res.data.menuList) {
                        this.navList = res.data.menuList;
                        this.getActiveNav();
                    }
                })
            } else {
                this.getActiveNav();
            }
        },
        // 选中问题
        getActiveNav() {
            this.navList.forEach((d)  => {
                if (d.url === this.$route.path || this.$route.path.indexOf(d.url) !== -1) {
                    this.isSelect = d;
                    // this.breadList.push(d)
                }
            })
        },
        // 点击跳转
        selectNav(item,item2) {
            this.breadList = []
            console.log(item,item2,'item')
            if(item2){
                this.breadList.push(item2)
                this.breadList.push(item)
            }
            if (item.url.indexOf('http') === -1) {
                this.isSelect = item;
                this.$router.push({
                    path: item.url,
                });
            } else {
                if(item.open_mode == 1){
                    window.open(item.url, '_self');
                }else {
                    window.open(item.url, 'newW');
                }
            }
        },
    }
}
</script>
<style lang="stylus" scoped>
.anquan-header-box{
    width 100%
    // position: fixed;
    // z-index: 666;
    .top{
        width 100%
        height 240px
        margin 0 auto
        .logo{
            width 100%
            height 100%
            object-fit cover
        }
    }
    .breadcrumb{
        width 1200px
        padding 10px 0
        margin 0 auto
    }
    .nav{
        width 100%
        height 60px
        background #0169BA
        // .nav-contaniner{
        //     width 1200px
        //     margin 0 auto
        //     display flex
        //     li{
        //         width 150px
        //         text-align center
        //         cursor pointer
        //         &:hover{
        //             background #03528C
        //         }
        //         p{
        //             font-size: 18px;
        //             font-family: PingFangSC-Medium, PingFang SC;
        //             font-weight: 500;
        //             color: #FFFFFF;
        //             line-height: 60px;
        //             width 100%
        //             height 100%
        //             &.active{
        //                 background #03528C
        //             }

        //         }
        //     }
        // }
        .nav-name-ul{
            width 1200px
            margin 0 auto
            .button{
                display flex
                position relative
                top 11px
                .btn{
                    width 132px
                    height 38px
                    background: #FF7E2C;
                    border-radius: 4px;
                    text-align center
                    line-height 38px
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    margin-left 16px
                    cursor pointer
                }
            }
        }
        .nav-name-ul-li {
            display: inline-block;
            vertical-align: top;
            line-height: 60px;
            height: 60px;
            position: relative;

        }

        .nav-name-ul-li ul{
            position: absolute;
            left: 0;
            top: 60px;
            width: 110px;
            // border-radius: 7px;
            display: none;
            z-index: 5;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.22);
        }
        .nav-name-ul-li:hover ul{
            display: block;
        }
        .nav-name-ul-li ul li{
            height: 40px;
            text-align: center;
            line-height: 40px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4A4A4A;
            cursor pointer
            background: #FFFFFF;
        }
        .nav-name-ul-li ul li:hover{
            background: #0169BA;
            color: #FFFFFF;
        }
        .nav-name-ul-li > span {
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            display: inline-block;
            width: 110px;
            text-align: center;
            cursor: pointer;
        }
        .nav-name-ul-li:hover {
            background-color: #03528C;
        }
        .active {
            background: #03528C;
        }
    }
}
</style>
