<template>
    <div class="invoiceForm">
        <el-form :model="formData.filter" :rules="rules" ref="ruleForm"  class="form">
            <el-form-item label="发票抬头：" prop="invoiceTitle">
                <el-input v-model="formData.filter.invoiceTitle" maxlength="25"></el-input>
            </el-form-item>
            <el-form-item label="单位税号：" prop="taxNumber"  v-if="invoiceTitleType ==1" >
                <el-input v-model="formData.filter.taxNumber" maxlength="25"></el-input>
            </el-form-item>
            <el-form-item label="开户银行：" prop="bankName" v-if="invoiceType==2" >
                <el-input v-model="formData.filter.bankName" maxlength="25"></el-input>
            </el-form-item>
            <el-form-item label="银行账号：" prop="bankAccount" v-if="invoiceType==2" >
                <el-input v-model="formData.filter.bankAccount" maxlength="25"></el-input>
            </el-form-item>
            <el-form-item label="注册电话：" prop="registPhone" v-if="invoiceType==2">
                <el-input v-model="formData.filter.registPhone" maxlength="15"></el-input>
            </el-form-item>
            <el-form-item label="注册地址：" prop="registAdress" v-if="invoiceType==2">
                <el-input v-model="formData.filter.registAdress" maxlength="50"></el-input>
            </el-form-item>
            <el-form-item label="收件人姓名：" prop="recipientName">
                <el-input v-model="formData.filter.recipientName"  maxlength="25"></el-input>
            </el-form-item>
             <el-form-item label="手机号码：" prop="recipientPhone" >
                <el-input v-model="formData.filter.recipientPhone" type="tel" maxlength="11"></el-input>
            </el-form-item>
             <el-form-item label="收件地址：" prop="recipientAddress">
                <el-input v-model="formData.filter.recipientAddress" maxlength="50"></el-input>
            </el-form-item>
             <el-form-item label="邮箱地址（选填）：" prop="recipientEmail">
                <el-input v-model="formData.filter.recipientEmail" maxlength="25"></el-input>
            </el-form-item>
            <el-form-item>
                <div class="submitbtnBox">
                    <el-button @click="submitForm('ruleForm')"  class="submitData">申请开票</el-button>
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
const isPhone = /^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/
export function phoneNumberValidator (rule, value, callback) {
  if (isPhone.test(value)) {
    callback();
  } else {
    callback(new Error('手机号码不正确'));
  }
}

const isLandline = /^0[1-9][0-9]{1,2}-[2-8][0-9]{6,7}$/
export function LandlineValidator (rule, value, callback) {
  if (isLandline.test(value)) {
    callback()
  } else {
    callback(new Error('注册电话不正确'))
  }
}

export function phoneOrLandlineValidator (rule, value, callback) {
  if (isPhone.test(value) || isLandline.test(value)) {
    callback()
  } else {
    callback(new Error('注册电话不正确'))
  }
}
export function emailValidator (rule, value, callback) {
  if(value){
      if(/\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(value)){
          callback();
      }else{
          callback(new Error('邮箱不正确'))
      } 
  }else{
      callback();
  }
}
export default {
    name:'ruleForm',
    props:{
        invoiceType:{
            type:String,
            default:'1'
        },
        invoiceTitleType:{
            type:String,
            default:'2'
        }
    },
    data(){
        return{
            formData:{
                ref: 'ruleForm',
                labelWidth: 'auto',
                filter: {
                    invoiceType:'1',
                    invoiceTitleType:'2',
                    invoiceTitle:'',
                    taxNumber:'',
                    bankName:'',
                    bankAccount:'',
                    registPhone:'',
                    registAdress:'',
                    recipientName:'',
                    recipientPhone:'',
                    recipientAddress:'',
                    recipientEmail:'',
                    orderId:0,
                },
                btnConfig:{},
                formList: [
                    { name: '发票抬头：', key: 'invoiceTitle',val: undefined, type: 'input',max:25},
                    { name: '单位税号：', key: 'taxNumber',val: undefined, type: 'input',  max:25},
                    { name: '开户银行：', key: 'bankName',val: undefined, type: 'input' ,max:25},
                    { name: '银行账号：', key: 'bankAccount',val: undefined, type: 'input' ,max:25},
                    { name: '注册电话：', key: 'registPhone',val: undefined, type: 'input'},
                    { name: '注册地址：', key: 'registAdress',val: undefined, type: 'input' ,max:25},
                    { name: '收件人姓名：', key: 'recipientName',val: undefined, type: 'input' ,max:25},
                    { name: '手机号码：', key: 'recipientPhone',val: undefined, type: 'input',max:11,min:11 },
                    { name: '收件地址：', key: 'recipientAddress',val: undefined, type: 'input',max:50 },
                    { name: '邮箱地址（选填）：', key: 'recipientEmail',val: undefined, type: 'input',max:25 },
                    
                ]
            },
            rules: {
                invoiceTitle: [
                    { required: true, message: '请输入发票抬头', trigger: 'blur' },
                ],
                taxNumber: [
                    { required: true, message: '请输入单位税号', trigger: 'blur' }
                ],
                bankName: [
                    { required: true, message: '请输入开户银行', trigger: 'blur' },
                ],
                bankAccount: [
                    { required: true, message: '请输入银行账号', trigger: 'blur' }
                ],
                registPhone: [
                    { required: true, message: '请输入注册电话', trigger: 'blur' },
                    {
                        validator: phoneOrLandlineValidator,
                        message: '请输入正确的注册电话',
                        trigger: 'blur',
                    },
                ],
                registAdress: [
                    { required: true, message: '请输入注册地址', trigger: 'blur' }
                ],
                recipientName: [
                    { required: true, message: '请输入收件人姓名', trigger: 'blur' },
                ],
                recipientPhone: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    {
                        validator: phoneNumberValidator,
                        message: '请输入正确的手机号',
                        trigger: 'blur',
                    },
                ],
                recipientAddress: [
                    { required: true, message: '请输入收件地址', trigger: 'blur' },
                ],
                recipientEmail: [
                    { required: false, message: '请输入正确的邮箱地址', trigger: 'blur' },
                    {
                        validator: emailValidator,
                        message: '请输入正确的邮箱地址',
                        trigger: 'blur',
                    },
                ],
                
            },
        }
    },
    methods:{
        submitForm(formName) {
            const that = this;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            that.$emit('submitData',that.formData.filter)
          } else {
            return false;
          }
        });
      },
    }
}
</script>
<style scoped lang="stylus">
@import '../asset/css/invoice.styl'
</style>
