<template>
  <div class="minzhengPersonalInfoForm">
      <el-form class="form" ref="form" :model="form" :rules="rules" label-width="126px">
        <el-form-item label="真实姓名：">
            <el-input class="formItem" disabled  v-model="form.name" placeholder="请填写真实姓名"></el-input>
        </el-form-item>
        <el-form-item class="genderRadio" label="性别：" prop="gender">
            <el-radio-group class="radioGroup" v-model="form.gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="出生日期：" prop="birthday">
            <el-date-picker
                class="formItem"
                v-model="form.birthday"
                :picker-options="pickerOptions"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="民族：" prop="nation">
            <el-select class="formItem" v-model="form.nation" filterable placeholder="请选择选择民族">
                <el-option v-for="(item,index) in nationList" :key="index" :label="item" :value="item"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item class="phone" label="手机号：">
            <el-input class="formItem" disabled v-model="form.phone"  placeholder="请填写手机号"></el-input>
        </el-form-item>
        <el-form-item label="所属养老机构：" prop="areaCode">
          <el-select
            v-model="form.areaCode"
            @change="handleSelectAreacode"
            filterable
            remote
            clearable
            reserve-keyword
            placeholder="请输入机构所属地区"
            :remote-method="remoteMethod"
            style="width:320px"
            :loading="loading">
            <el-option
              v-for="item in filterOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="oldAgeInstitutionId">
          <el-select class="formItem" v-model="form.oldAgeInstitutionId" filterable placeholder="所属养老机构">
              <el-option v-for="(item,index) in organList" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作单位：">
            <el-input class="formItem" v-model="form.workUnit"  placeholder="请输入工作单位"></el-input>
        </el-form-item>
        <el-form-item label="学历：">
            <el-select class="formItem" v-model="form.education" placeholder="请选择学历信息">
                <el-option v-for="item in educationList" :key="item.key" :label="item.val" :value="item.key"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-button class="saveBtn" type="primary" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form>
  </div>
</template>

<script>
export default {
    name:'minzhengPersonal',
    data(){
      return{
        isFirstEdit:false,
        loading:false,
        form:{
          name:'',
          gender:1,
          birthday:'',
          nation:'',
          hometownProvince:'',
          hometownCity:'',
          phone:'',
          education:'',
          workUnit:'',//工作单位
          areaCode:'',
          oldAgeInstitutionId:''
        },
        rules:{
          // gender: [
          //   { required: true, message: '请选择性别', trigger: 'change' }
          // ],
          // birthday: [
          //   { required: true, message: '请选择日期', trigger: 'blur' }
          // ],
          // nation: [
          //   { required: true, message: '请选择选择民族', trigger: 'change' }
          // ],
          areaCode: [
            { required: true, message: '请输入机构所属地区', trigger: 'change' }
            // { validator : organValidator,  trigger: 'change' }
          ],
          oldAgeInstitutionId: [
            { required: true, message: '请选择所属养老机构', trigger: 'change' }
            // { validator : organValidator,  trigger: 'change' }
          ]
        },
        pickerOptions:{
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
        },
        areaList:[],
        filterOptions:[],
        organList:[],
        nationList:['汉','满','蒙古','回','藏','维吾尔','苗','彝','壮','布依','侗','瑶','白','土家','哈尼','哈萨克','傣','黎','傈僳','佤','畲','高山','拉祜','水','东乡','纳西','景颇','柯尔克孜','土','达斡尔','仫佬','羌','布朗','撒拉','毛南','仡佬','锡伯','阿昌','普米','朝鲜','塔吉克','怒','乌孜别克','俄罗斯','鄂温克','德昂','保安','裕固','京','塔塔尔','独龙','鄂伦春','赫哲','门巴','珞巴','基诺'],
        educationList: [{key: 1, val: '初中及以下'}, {key: 2, val: '高中'},{key: 3, val: '大专'}, {key: 4, val: '本科'}, {key: 5, val: '硕士'}, {key: 6, val: '博士'}],
      }
    },
    created(){
      //民政是否完善资料
      // const checkMinzhengIsSaveInfo = localStorage.getItem('checkMinzhengIsSaveInfo')
      // this.isFirstEdit = checkMinzhengIsSaveInfo === 'true'? false:true
    },
    async mounted(){
      await this.getSaasTree()
      this.getPersonalInfo()
      this.getSubUserInfo()
    },
    methods:{
      handleSelectAreacode(value){
        this.organList = []
        this.form.oldAgeInstitutionId = null
        if(!value) return
        this.getOrganList(value)
      },
      // 根据区域码获取养老机构列表
      getOrganList(code){
        this.$api.usercenter.findOldAgeInstitutionList({code}).then(res => {
          if(res.data){
            this.organList = res.data.map(item => {
              return {
                value:item.id,
                label:item.name
              }
            })
          }
        })
      },
      getPersonalInfo(){
          this.$api.usercenter.findMinzhengPersonal().then(res => {
              if(res.code == 0){
                  this.form = res.data
                  
                  this.form.gender = res.data.gender == 0? null: res.data.gender
                  this.form.education = res.data.education == 0? null: res.data.education
                  this.remoteMethod('')
                  if(res.data.areaCode){
                    this.getOrganList( res.data.areaCode )
                  }
                  console.log("res.data.areaCode",res.data.areaCode)
                  
                  this.$refs.form.resetFields()
              }
          })
      },
      //获取城市列表
      // getTree(){
      //     if(this.$store.state.areaList.length>0){
      //         this.areaList = this.$store.state.areaList
      //     }else{
      //         return this.$api.usercenter.getTree().then(res => {
      //             res.data.forEach(item => {
      //                 const L1Name = item.name
      //                 item.children && item.children.length && item.children.forEach(el => {
      //                     const L2Name = el.name
      //                     if(!el.children){
      //                         this.areaList.push({
      //                             name: `${L1Name}-${L2Name}`,
      //                             code: el.code,
      //                             id: el.id
      //                         })
      //                     }else if(el.children.length){
      //                         el.children.forEach(v => {
      //                             const L3Name = v.name
      //                             this.areaList.push({
      //                             name: `${L1Name}-${L2Name}-${L3Name}`,
      //                             code: v.code,
      //                             id: v.id
      //                             })
      //                         })
      //                     }
      //                 })
      //             })
      //             this.$store.commit('saveCityTree',this.areaList)
      //         })
      //     }
      // },
      //获取saasId
      getSubUserInfo(){
          this.$api.usercenter.findUser().then(res => {
              if(res.code == 0){
                  this.$store.commit('saveSubUserInfo',res.data)
                  localStorage.setItem('subUserInfo',JSON.stringify(res.data))
              }
          })
      },
      //获取城市列表
      getSaasTree(){
        // var subUserInfo = localStorage.getItem('subUserInfo')?JSON.parse(localStorage.getItem('subUserInfo')):{}
        // var saasId=subUserInfo.saasId
        if(this.$store.state.areaList.length>0){
              this.areaList = this.$store.state.areaList
          }else{
              return this.$api.usercenter.findSaasIdList().then(res => {
                  this.areaList=res.data
                  this.$store.commit('saveCityTree',this.areaList)
              })
          }
      },
      remoteMethod(query){
        if (query !== '') {
          setTimeout(() => {
              this.filterOptions = this.areaList.filter(item => {
                  return item.name.includes(query)
              });
          }, 200);
        } else {
          this.filterOptions = this.areaList
        }
      },
      cancel(){
        this.$router.push('/')
      },
      onSubmit(){
        // 不需要校验手机号
        let params = { ...this.form }
        delete params.name
        delete params.phone
        this.$refs.form.validate((valid) => {
            if (valid) {
              this.$api.usercenter.saveMinzhengPersonal( params ).then(res => {
                if(res.code == 0){
                  this.$message.success('保存成功')
                  localStorage.setItem('checkMinzhengIsSaveInfo','true')
                  this.$router.push({
                    path:'/courseGoods'
                  })
                }
            })
          } else {
              this.$message.error('请检查各项信息填写是否正确')
          }
        })
      }
    }
}
</script>

<style lang="stylus" scoped>
  // .minzhengPersonalInfoForm
  //   width 822px
  //   margin 0 auto
  .form  
    width 454px
    margin 0 auto
    /deep/ .el-form-item
      margin-bottom 16px
    /deep/ .el-form-item__label
      padding 0
      font-size 16px
      color #333
    // .phone 
    //   /deep/ .el-form-item__label::before
        // content '*'
        // color #F56C6C
        // margin-right 4px
    .formItem
      width 320px
    .genderRadio
      /deep/ .el-form-item__content
        line-height 40px
      .radioGroup
        /deep/ .el-radio__label
          font-size 16px
          color #333
    .saveBtn
      margin-top 24px
      width 320px
      height 40px
      background #316FFF
</style>