const UrlName = '/modular/admin/v1'
import URL from '../../config/url';
export default API => ({
    getConfiguration() {
      let url = URL.confUrl;
        return API({ 
          url: `${UrlName}/configuration/${url}`
        });
    },
    //业务端获取PageConfig
    getPageConfig(){
      const params = {};
      params.domain = URL.confUrl;
      return API({ 
        url: `${UrlName}/block/getPageConfig`, 
        params: params,
        method: 'POST',
      });
    },
    //业务端获取PageJson
    getPageJson(){
      const params = {};
      params.domain = URL.pageUrl;
      // params.domain = '33yingji.saas-pc.testing3.wdeduc.com'//URL.confUrl;
      return API({ 
        url: `${UrlName}/block/getPageJson`, 
        params: params,
        method: 'Get',
      });
    },
})
 