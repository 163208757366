import TRTC from 'trtc-js-sdk';
import $ from 'jquery';
import { getOS, getBrowser } from './common';

let hasMicDevice = false,
  hasVoiceDevice = false,
  hasVoiceConnect,
  hasMicConnect,
  hasNetworkConnect;

let voiceTestingResult = {};
let micTestingResult = {};
let networkTestingResult = {};

// 记录检测步骤，用于关闭时清空弹窗
// let completedTestingPageIdList = [];
// let curTestingPageId = '';
let localStream = null;
let client = null;
// let timeout = null;
// // 监听到network-quality事件的次数
let networkQualityNum = 0;

const deviceFailAttention =
  '1. 若浏览器弹出提示，请选择“允许”<br>' +
  '2. 若杀毒软件弹出提示，请选择“允许”<br>' +
  '3. 检查系统设置，允许浏览器访问摄像头及麦克风<br>' +
  '4. 检查浏览器设置，允许网页访问摄像头及麦克风<br>' +
  '5. 检查摄像头/麦克风是否正确连接并开启<br>' +
  '6. 尝试重新连接摄像头/麦克风<br>' +
  '7. 尝试重启设备后重新检测';
const networkFailAttention =
  '1. 请检查设备是否联网<br>' + '2. 请刷新网页后再次检测<br>' + '3. 请尝试更换网络后再次检测';

  // 网络参数对照表
const NETWORK_QUALITY = {
  '0': '未知',
  '1': '极佳',
  '2': '较好',
  '3': '一般',
  '4': '差',
  '5': '极差',
  '6': '断开'
};

const isFileProtocol = location.protocol === 'file:';

// 判断是否为safari浏览器
const isSafari =
  /Safari/.test(navigator.userAgent) &&
  !/Chrome/.test(navigator.userAgent) &&
  !/CriOS/.test(navigator.userAgent) &&
  !/FxiOS/.test(navigator.userAgent) &&
  !/EdgiOS/.test(navigator.userAgent);
const isFirefox = /Firefox/i.test(navigator.userAgent);

// safari和firefox浏览器上检测不到扬声器设备
const noVoiceDevice = isSafari || isFirefox;
noVoiceDevice && hideVoiceTesting();

/**
 * safari和firefox浏览器中隐藏扬声器相关检测
 */
function hideVoiceTesting() {
      // $('#connect-voice').hide();
      // $('#device-voice').hide();
      // $('#voice-testing').hide();
      // $('#voice-report').hide();
      // $('#device-mic').addClass('noVoiceDevice');
      // $('#device-network').addClass('noVoiceDevice');
      // $('#mic-testing').addClass('noVoiceDevice');
      // $('#network-testing').addClass('noVoiceDevice');
    }

async function getDevicesInfo() {
      let micList = await TRTC.getMicrophones();
      let voiceList = await TRTC.getSpeakers();

      hasMicDevice = micList.length > 0;
      hasVoiceDevice = voiceList.length > 0;

      micList.forEach(mic => {
            if (mic.deviceId.length > 0) {
                  hasMicConnect = true;
            }
      });
      // 如果是无法进行扬声器检测的浏览器，设置为true
      if (noVoiceDevice) {
            hasVoiceDevice = true;
            hasVoiceConnect = true;
      } else {
            hasVoiceConnect = voiceList.length > 0;
      }
      // 本地打开使用 navigator.onLine 的结果，https打开使用 isOnline() 的检测结果
      // CORS policy: Cross origin requests are only supported for protocol schemes: http, data, chrome, chrome-extension, chrome-untrusted, https;
      hasNetworkConnect = isFileProtocol ? navigator.onLine : await isOnline();
}

/**
 * 判断是否有网络连接
 */
function isOnline() {
      return new Promise(resolve => {
        try {
          let xhr = new XMLHttpRequest();
          xhr.onload = function() {
            resolve(true);
          };
          xhr.onerror = function() {
            resolve(false);
          };
          xhr.open('GET', '/', true);
          xhr.send();
        } catch (err) {
          // console.log(err);
        }
      });
    }

/**
 * 返回设备连接信息
 */
function getDeviceConnectInfo(vm) {
      const $refs = vm.$refs;
      let connectInfo = '连接出错，请重试';
      // 第一步：浏览器未检测到摄像头/麦克风/扬声器设备的提示
      if (!(hasMicDevice && hasVoiceDevice)) {
        connectInfo = `未检测到${
          hasVoiceDevice ? '' : '【扬声器】'
        }${hasMicDevice ? '' : '【麦克风】'}设备，请检查设备连接`;
        return connectInfo;
      }
      // 第二步：浏览器未拿到摄像头/麦克风权限的提示
      if (!(hasMicConnect)) {
        connectInfo = hasNetworkConnect
          ? '请允许浏览器及网页访问麦克风设备'
          : '请允许浏览器及网页访问麦克风设备，并检查网络连接';
        // 显示设备连接失败引导

        $refs.connectAttentionContainer.style.display = 'block';
        $refs.connectAttentionInfo.innerHTML = deviceFailAttention

        return connectInfo;
      }
      // 第三步：浏览器检测未连接网络的提示
      if (!hasNetworkConnect) {
        connectInfo = '网络连接失败，请检查网络连接';
        // 显示设备连接失败引导
        $refs.connectAttentionContainer.style.display = 'block';
        $refs.connectAttentionInfo.innerHTML = networkFailAttention;
        return connectInfo;
      }
      return connectInfo;
}

export async function deviceTestingInit(vm) {
  const $refs = vm.$refs;

  // 扬声器设备切换
  $($refs.voiceSelect).change(async function() {
      let newVoiceId = $(this)
        .children('option:selected')
        .val();
      localStorage.setItem('txy_webRTC_voiceId', newVoiceId);
      voiceTestingResult.device = {
          label: $(this)
            .children('option:selected')
            .text(),
          deviceId: $(this)
            .children('option:selected')
            .val(),
          kind: 'audiooutput'
      };
      await $refs.audioPlayer.setSinkId(newVoiceId);
  });

  // 麦克风设备切换
  $($refs.micSelect).change(async function() {
    let newMicID = $(this)
      .children('option:selected')
      .val();
    localStorage.setItem('txy_webRTC_micId', newMicID);
    micTestingResult.device = {
      label: $(this)
        .children('option:selected')
        .text(),
      deviceId: $(this)
        .children('option:selected')
        .val(),
      kind: 'audioinput'
    };
    await localStream.switchDevice('audio', newMicID);
  });
}

/**
 * 弹窗-设备连接检查
 */
export async function startDeviceConnect(vm) {
      const $refs = vm.$refs;
      // 重新获取连接信息
      await getDevicesInfo();

      // 在设备检测弹窗显示设备连接信息
      let showDeviceConnectInfo = function() {

            // 隐藏设备连接失败提示
            $refs.connectAttentionContainer.style.display = 'none';

            // 设备连接中
            $refs.deviceLoading.style.display = 'block';
            $refs.connectInfo.innerHTML = '设备正在连接中，请稍等';
            $refs.connectInfo.style.color = '#cccccc';

            $refs.deviceVoice.classList.remove('connect-success', 'connect-fail');
            $refs.deviceMic.classList.remove('connect-success', 'connect-fail');
            $refs.deviceNetwork.classList.remove('connect-success', 'connect-fail');

            $refs.connectAgainBtn.style.display = 'none';
            $refs.startTestBtn.classList.add('start-gray')
            $refs.startTestBtn.style.display = '';

            // 设备连接结束，展示连接结果
            setTimeout(() => {
                  $refs.deviceLoading.style.display = 'none';

                  $refs.deviceVoice.classList.remove('connect-success', 'connect-fail');
                  $refs.deviceVoice.classList.add(`${hasVoiceConnect ? 'connect-success' : 'connect-fail'}`);

                  $refs.deviceMic.classList.remove('connect-success', 'connect-fail');
                  $refs.deviceMic.classList.add(`${hasMicConnect ? 'connect-success' : 'connect-fail'}`);

                  $refs.deviceNetwork.classList.remove('connect-success', 'connect-fail');
                  $refs.deviceNetwork.classList.add(`${hasNetworkConnect ? 'connect-success' : 'connect-fail'}`);

                  let connectInfo = '';
                  // 设备检测结果（包括麦克风检测，摄像头检测，扬声器检测，网络检测）
                  const connectResult =
                    hasVoiceConnect && hasMicConnect && hasNetworkConnect;
                  if (connectResult) {
                        $refs.connectInfo.innerHTML = '设备及网络连接成功，请开始设备检测';
                        $refs.connectInfo.style.color = '#32CD32';
                        $refs.connectAgainBtn.style.display = 'none';
                        $refs.startTestBtn.classList.remove('start-gray');
                        $refs.startTestBtn.style.display = '';
                  } else {
                        // 有设备或者网络连接不成功，展示连接失败提示
                        connectInfo = getDeviceConnectInfo(vm);
                        $refs.connectInfo.innerHTML = connectInfo;
                        $refs.connectInfo.style.color = 'red';
                        // 切换按钮状态
                        $refs.startTestBtn.style.display = 'none';
                        $refs.connectAgainBtn.style.display = '';
                  }
            }, 2000);
      };

      showDeviceConnectInfo();

      // 如果有设备未连接，唤起请求弹窗
      if (!(hasMicConnect)) {
            navigator.mediaDevices
                  .getUserMedia({ audio: hasMicDevice })
                  .then(() => {
                        if (hasMicDevice) hasMicConnect = true;

                        // 显示设备连接信息
                        showDeviceConnectInfo();
                  })
                  .catch(err => {
                        console.log('getUserMedia err', err.name, err.message);
                        handleGetUserMediaError(err);
                  });
      }
}


/**
 * 抽离createStream的公共处理函数
 */
async function createLocalStream(constraints, container) {
      localStream = TRTC.createStream(constraints);
      try {
        await localStream.initialize();
      } catch (error) {
        handleGetUserMediaError(error);
      }
      container && localStream.play(container);
    }

export function voiceCanHear(canHear, vm) {
      voiceTestingResult.statusResult = canHear;
      const $refs = vm.$refs;
      $refs.voiceTestingBody.style.display = 'none';

      let audioPlayer = $refs.audioPlayer;
      if (!audioPlayer.paused) {
        audioPlayer.pause();
      }
      startMicTesting(vm);
}

export function micCanSee(canSee, vm) {
    micTestingResult.statusResult = canSee;
    vm.$refs.micTestingBody.style.display = 'none';
    localStream.close();
    startNetworkTesting(vm);
}

export function viewTestReport(vm) {
    showTestingReport(vm);
    localStream.close();
    client && client.leave();
    client && client.off('network-quality');
}

/**
 * 结束设备检测，隐藏设备检测弹窗
 */
export function finishDeviceTesting(vm) {
  const $refs = vm.$refs;
  // 停止摄像头/麦克风的流采集并释放摄像头/麦克风设备
  localStream && localStream.close();
  client && client.leave();
  client && client.off('network-quality');
  // 停止播放器的音乐

  let audioPlayer = $refs.audioPlayer;
  if (!audioPlayer.paused) {
    audioPlayer.pause();
  }
  audioPlayer.currentTime = 0;
}

/**
 * 展示检测报告
 */
function showTestingReport(vm) {
  const $refs = vm.$refs;
  $refs.deviceTesting.style.display = 'none';
  $refs.networkTestingBody.style.display = 'none';

  $refs.deviceTestingReport.style.display = '';

  // 扬声器检测结果(safari和firefox浏览器不显示扬声器检测结果)
  if (!noVoiceDevice) {
    $refs.voiceName.innerHTML = voiceTestingResult.device.label;

    if (voiceTestingResult.statusResult) {
      $refs.voiceTestingResult.innerHTML = '正常';
      $refs.voiceTestingResult.style.color = 'green';

    } else {
      $refs.voiceTestingResult.innerHTML = '异常';
      $refs.voiceTestingResult.style.color = 'red';
    }
  }

  // 麦克风检测结果
  $refs.micName.innerHTML = micTestingResult.device.label;

  if (micTestingResult.statusResult) {
    $refs.micTestingResult.innerHTML = '正常';
    $refs.micTestingResult.style.color = 'green';

  } else {
    $refs.micTestingResult.innerHTML = '异常';
    $refs.micTestingResult.style.color = 'red';
  }

  // 网络检测结果
  // $('#network-name').text(networkTestingResult.IPAddress);
  $refs.networkName.innerHTML = '网络质量';
  $refs.networkTestingResult.innerHTML = `${NETWORK_QUALITY[String(networkTestingResult.upLinkNetwork)]}`
  $refs.networkTestingResult.style.color = `${Number(networkTestingResult.upLinkNetwork) > 3 ? 'red' : 'green'}`;
}

/**
 * 系统信息展示
 */
async function startNetworkTesting(vm) {
  const $refs = vm.$refs;

  $refs.networkTestingBody.style.display = '';
  $refs.testingReportBtn.style.display = 'none';

  $refs.networkTesting.classList.remove('icon-gray');
  $refs.networkTesting.classList.add('icon-blue', 'complete');

  networkQualityNum = 0;

  $refs.uplinkNetwork.classList.add('network-loading');
  $refs.uplinkNetwork.innerHTML = '';


  // 获取系统信息
  $refs.system.innerHTML = '';
  let OSInfo = getOS();

  let infoDiv = document.createElement('div');
  infoDiv.innerHTML = OSInfo.osName;
  $refs.system.appendChild(infoDiv);

  // 获取浏览器及版本信息
  $refs.browser.innerHTML = '';
  let browser = getBrowser();
  let browserDiv = document.createElement('div');
  browserDiv.innerHTML = `${browser.browser} ${browser.version}`;
  $refs.browser.appendChild(browserDiv);

  // 获取ip地址信息
  // $('#ip').empty();
  // let IPAddress = await getIPAddress();
  // $('<div></div>').text(IPAddress).appendTo('#ip');
  // networkTestingResult.IPAddress = IPAddress;

  // 是否支持屏幕分享能力
  $refs.screenShare.innerHTML = '';

  let isScreenShareSupported = TRTC.isScreenShareSupported();
  let shareDiv = document.createElement('div');
  shareDiv.innerHTML = isScreenShareSupported ? '支持' : '不支持';
  $refs.screenShare.appendChild(shareDiv);

  // 上下行网络质量

  vm.$api.live.findUserSig('student-').then(async (res) => {
      if (!res.success) {
          alert('网络不太好，检查一下吧');
          return;
      }
      let options = {
        sdkAppId: res.data.sdkappid,
        userId: res.data.userId,
        userSig: res.data.userSig,
        roomId: parseInt(vm.roomId)
      };
      client = TRTC.createClient({ mode: 'rtc', ...options });
      client.on('network-quality', event => {
        networkQualityNum++;
        // 收到3次'network-quality'事件的时候认为拿到了网络实际质量
        if (networkQualityNum === 3) {
          networkTestingResult.upLinkNetwork = event.uplinkNetworkQuality;
          networkTestingResult.downLinkNetwork = event.downlinkNetworkQuality;
          $refs.uplinkNetwork.classList.remove('network-loading');
          $refs.uplinkNetwork.innerHTML = NETWORK_QUALITY[String(networkTestingResult.upLinkNetwork)];
          $refs.testingReportBtn.style.display = '';
          client && client.leave();
          client && client.off('network-quality');
        }
      });
      await client.join({
        roomId: options.roomId
      });
      console.log('进房间')

      await createLocalStream(
        {
          audio: true,
          video: false
        },
        $refs.audioContainer
      );
      console.log('创建流')
      await client.publish(localStream);
      console.log('发布流')
      // 音频轨道静音
      localStream.muteAudio();
      console.log('静音')
  }).catch(() => {
      alert('网络不太好，检查一下吧');
  });
}

/**
 * 麦克风设备测试
 */
async function startMicTesting(vm) {
      const $refs = vm.$refs;
      $refs.micTestingBody.style.display = '';

      $refs.micTesting.classList.remove('icon-gray');
      $refs.micTesting.classList.add('icon-blue', 'complete');

      await updateMicDeviceList(vm);

      // 展示麦克风的声音大小显示

      if ($refs.micBarContainer.children.length === 0) {
            for (let index = 0; index < 28; index++) {
                  let micBarDiv = document.createElement('div');
                  micBarDiv.style.width = '10px';
                  micBarDiv.style.height = '30px';
                  micBarDiv.style.border = '1px solid #cccccc';
                  micBarDiv.style.borderRadius = '1px';
                  micBarDiv.style.marginRight = '3px';

                  $refs.micBarContainer.appendChild(micBarDiv);
            }
      }

      // 创建本地音频流
      await createLocalStream(
        {
          audio: true,
          microphoneId: micTestingResult.device.deviceId,
          video: false
        },
        $refs.audioContainer
      );

      // 监听音量，并量化显示出来
      const voiceInterval = setInterval(() => {
        let volume = localStream.getAudioLevel();
        let num = Math.ceil(28 * volume);

        const children = $refs.micBarContainer.children;
        for (let i = 0; i < children.length; i++) {
            children[i].style.background = 'none';
        }

        for (let i = 0; i < num; i++) {
            $refs.micBarContainer.children[i].style.background = '#006EFF'
        }
      }, 100);
      vm.$once('hook:beforeDestroy', () => {
          clearInterval(voiceInterval);
      });
}

/**
 * 更新/初始化麦克风设备
 */
async function updateMicDeviceList(vm) {
      // 展示麦克风设备选择
      let micDevices = await TRTC.getMicrophones();
      // micDevices = micDevices.filter(mic => mic.deviceId !== 'default');

      let isAndroid = getOS().type === 'mobile' && getOS().osName === 'Android';
      // 如果是安卓设备，不允许切换麦克风(切换麦克风存在获取不到音量的情况)
      if (isAndroid) {
        micDevices = [].concat(micDevices[0]);
      }

      const $refs = vm.$refs;
      $refs.micSelect.innerHTML = '';

      micDevices.forEach(mic => {
        let option = document.createElement('option');
        option.setAttribute('value', mic.deviceId);
        option.innerHTML = mic.label;
        $refs.micSelect.appendChild(option);
      });

      // 如果有用户设备选择缓存，优先使用缓存的deviceId
      let cacheMicDevice = micDevices.filter(
        mic => mic.deviceId === localStorage.getItem('txy_webRTC_micId')
      );
      if (isAndroid || cacheMicDevice.length === 0) {
        $refs.micSelect.value = micDevices[0].deviceId;
        micTestingResult.device = micDevices[0];
      } else {
        $refs.micSelect.value = localStorage.getItem('txy_webRTC_micId')
        micTestingResult.device = cacheMicDevice[0];
      }
}

/**
 * 播放器设备测试
 */
export async function startVoiceTesting(vm) {
      const $refs = vm.$refs;
      $refs.voiceTestingBody.style.display = '';

      $refs.voiceTesting.classList.remove('icon-gray');
      $refs.voiceTesting.classList.add('icon-blue', 'complete')

      await updateVoiceDeviceList(vm);
}

/**
 * 初始化/更新扬声器设备数组
 */
async function updateVoiceDeviceList(vm) {
      // 获取扬声器设备并展示在界面中
      let voiceDevices = await TRTC.getSpeakers();
      // voiceDevices = voiceDevices.filter(voice => voice.deviceId !== 'default');

      const $refs = vm.$refs;
      $refs.voiceSelect.innerHTML = '';
      voiceDevices.forEach(voice => {
            let option = document.createElement('option');
            option.setAttribute('value', voice.deviceId);
            option.innerHTML = voice.label
            $refs.voiceSelect.appendChild(option);
      });

      // 如果有用户设备选择缓存，优先使用缓存的deviceId
      let cacheVoiceDevice = voiceDevices.filter(
        mic => mic.deviceId === localStorage.getItem('txy_webRTC_voiceId')
      );
      if (cacheVoiceDevice.length > 0) {
            $refs.voiceSelect.value = localStorage.getItem('txy_webRTC_voiceId')
            voiceTestingResult.device = cacheVoiceDevice[0];
      } else {
            $refs.voiceSelect.value = voiceDevices[0].deviceId;
            voiceTestingResult.device = voiceDevices[0];
      }
}

/**
 * 处理getUserMedia的错误
 * @param {Error} error
 */
function handleGetUserMediaError(error) {
      switch (error.name) {
        case 'NotReadableError':
          // 当系统或浏览器异常的时候，可能会出现此错误，您可能需要引导用户重启电脑/浏览器来尝试恢复。
          alert(
            '暂时无法访问摄像头/麦克风，请确保系统授予当前浏览器摄像头/麦克风权限，并且没有其他应用占用摄像头/麦克风'
          );
          return;
        case 'NotAllowedError':
          alert('用户/系统已拒绝授权访问摄像头或麦克风');
          return;
        case 'NotFoundError':
          // 找不到摄像头或麦克风设备
          alert('找不到摄像头或麦克风设备');
          return;
        case 'OverConstrainedError':
          alert(
            '采集属性设置错误，如果您指定了 microphoneId，请确保它们是一个有效的非空字符串'
          );
          return;
        default:
          alert('初始化本地流时遇到未知错误, 请重试');
          return;
      }
    }