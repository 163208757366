<template>
    <div class="college">
        <!-- 头条 -->
        <div class='banner'>
            <ul>
                <li class="img"><img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/zz-news.png" alt=""></li>
                <li class="line"><img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/toutiao.png" alt=""></li>
                <li class="col-banner">
                    <el-carousel height="25px" direction="vertical" :autoplay="true" :interval="3000">
                        <el-carousel-item v-for="item in newsList" :key="item.id" class="college-slide">
                            <span class="detail title" :title="item.title">{{item.title}}</span>
                            <span class="detail" @click="goDetail(item.id)">查看详情<i class="el-icon-arrow-right"></i></span>
                        </el-carousel-item>
                    </el-carousel>
                </li>
            </ul>
        </div>
        <!-- 学院专区 -->
        <div class='projectList'>
            <div class="projectList-label">
                <h2 class="projectList-title">
                    <span class="projectList-line"></span>
                    学院专区
                </h2>
                <div class="projectList-btn" @click="examine()">
                    <span class="projectList-more">更多</span>
                    <img class="projectList-img" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/more-img.png" alt="">
                </div>
            </div>
            <div class="projectList-list">
                <div class="list" v-for="(item, index) in listCard" :key="index" @click="examineDetails(item.id)">
                    <div class="list-img">
                        <img :src="item.imgUrl" alt="">
                    </div>
                    <div class="title">
                        <h2 class="list-title">{{item.title}}</h2>
                        <p class="list-con">{{item.content}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 导师公开课 -->
        <div class='courseList'>
            <div class="projectList-label">
                <h2 class="projectList-title">
                    <span class="projectList-line"></span>
                    导师公开课
                </h2>
                <div class="projectList-btn" @click="publics()">
                    <span class="projectList-more">更多</span>
                    <img class="projectList-img" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/more-img.png" alt="">
                </div>
            </div>
            <div class="courseList-list">
                <div class="list" v-for="(item, index) in listCourse" :key="index" @click="publicsDetails(item.id)">
                    <div class="list-img">
                        <img :src="item.imgUrl" alt="">
                    </div>
                </div>
            </div>
        </div>
        <!-- 中智读书会 -->
        <div class='readList'>
            <div class="projectList-label">
                <h2 class="projectList-title">
                    <span class="projectList-line"></span>
                    中智读书会
                </h2>
                <div class="projectList-btn" @click="readingClub()">
                    <span class="projectList-more">更多</span>
                    <img class="projectList-img" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/more-img.png" alt="">
                </div>
            </div>
            <div class="readList-list">
                <div class="list" v-for="(item, index) in listRead" :key="index">
                    <div class="list-img">
                        <img :src="item.imgUrl" alt="">
                    </div>
                    <div class="title">
                        <h2 class="list-title">{{item.title}}</h2>
                        <p class="list-name">{{item.name}}</p>
                        <p class="list-con">{{item.content}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 平台特色 -->
        <div class="characteristic">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/ptts.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name:'college',
    props: {
    },
    data () {
        return {
            newsList: [
                {
                    id: '1',
                    title:'新华网：国企改革为何要读懂这张“施工图”'
                },
                {
                    id: '2',
                    title:'培训业务——中国行业领先的培训知名品牌'
                },
                {
                    id: '3',
                    title:'经济日报：展望2021 国资国企改革发展怎么干'
                },
                {
                    id: '4',
                    title:'人力资源社会保障部进一步加强高技能人才与专业技术人才职业发展贯通'
                },
            ],
            listCard: [
              {
                  imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/college_img1.png',
                  title: '企业管理学院',
                  content: '包含8门课程',
                  id: 0
              },
              {
                  imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/college_img2.png',
                  title: '南湖学院',
                  content: '包含10门课程',
                  id: 1
              },
              {
                  imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/college_img3.png',
                  title: '传媒学院',
                  content: '包含0门课程',
                  id: -3
              },
              {
                  imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/college_img4.png',
                  title: '技工学院',
                  content: '包含0门课程',
                  id: -4
              }
            ],
            listCourse: [
                {
                    id:'1',
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/course_img1.png',
                },
                {
                    id:'2',
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/course_img2.png',
                },
                {
                    id:'3',
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/course_img3.png',
                },
                {
                    id:'5',
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/course_img4.png',
                },
                {
                    id:'4',
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/course_img5.png',
                }
            ],
            listRead: [
              {
                  imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/read_img1.png',
                  title: '基石',
                  name: '徐晓冬',
                  content: '本书融合了具有前瞻性和战略性的*的企业管理模式和实践，用多元的视角深入剖析典型借鉴意义的案例，帮助企业构建基于"商业组织10S协同模型”的协同战略'
              },
              {
                  imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/Bitmap@3x.png',
                  title: '对标',
                  name: '徐晓冬',
                  content: '锻造世界一流企业基石，揭秘底层逻辑与顶层设计，独创性构建组织治理10s协同模型；案例精选+秘诀提炼全景式立体剖析21家世界一流企业；框架指引+行动指南'
              },
              {
                  imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/read_img2.png',
                  title: '协同的力量',
                  name: '【美】道娜﹒马尔科娃 【美】安吉·麦克阿瑟',
                  content: '这本基于50多年调查研究的巅峰之作就协作这一话题展开，是关于共同思考之变革力量的开先河之作，协同的意义不仅仅是所有思想和力量的想加，而是几何倍数的增长。'
              },
            //   {
            //       imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/read_img3.png',
            //       title: '重构：国内外企业生态战略案例研究',
            //       name: '徐井宏、李东红',
            //       content: '本书选取10个靠前外知名企业案例，对这些企业尝试借助打造商业生态系统谋求发展的实践历程进行了梳理，以期展现这些企业在建立和管理商业生态系统过程中的理念与做法。'
            //   }
            ],
        };
    },
    methods: {
        examineDetails(id){
            if (id >= 0) {
                this.$router.push({
                    path:'/collegeDetails',
                    query: {
                        id: id
                    }
                })
            } else {
                this.$message({
                    showClose: true,
                    message: '学院正在建设中，敬请期待',
                    type: 'warning'
                });
            }
        },
        publicsDetails(id) {
            this.$router.push({
                path:'/classDetail',
                query:{
                    id:id
                }
            })
        },
        examine () {
            this.$router.push({
                path:'/projectDetails',
                query:{
                    name: 'projectDetails',
                }
            })
        },
        publics () {
            this.$router.push({
                path:'/publicClass',
                query:{
                    name: 'publicClass',
                }
            })
        },
        readingClub() {
            this.$router.push({
                path:'/readingBook',
                query:{
                    name: 'readingBook',
                }
            })
        },
        goDetail(id){
            this.$router.push({
                path:'/projectDetails',
                query:{
                    name: 'projectDetails',
                    id: id
                }
            })
        }
    },
}

</script>
<style lang='stylus' scoped>
    @import "../../asset/css/college.styl"
</style>
