<template>
    <div class='gzjtRegulations'>
        <div class='gzjtRegulationsBox'>
            <ul>
                <li v-for="(item,index) in regulationsList" :key="index" class="_clear" @click="goDetailpage(item.id)">
                    <div class="regulations-left">
                        <img :src="'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/regulations/'+item.img+'.png'" alt="">
                    </div>
                    <div class="regulations-right">
                        <h4>
                            {{item.title}}
                        </h4>
                        <p>{{item.time}}</p>
                        <div>
                            {{item.content}}
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <footers/>
    </div>
</template>

<script>
import footers from '../block/front/footers.vue'
export default {
    name: 'gzjt_Regulations',
    components: {
        footers
    },
    data () {
        return {
            regulationsList:[
                {
                    id:2,
                    img:'regulations2',
                    title:'中华人民共和国安全生产法',
                    time:'2020-01-09',
                    content:'《全国人民代表大会常务委员会关于修改〈中华人民共和国安全生产法〉 的决定》已由中华人民共和国第十二届全国人民代表大会常务委员会第十次会议于 2014 年 8 月 31日通过，现予公布，自 2014 年 12 月 1 日起施行。',
                },
                {
                    id:1,
                    img:'regulations1',
                    title:'中华人民共和国道路交通安全法',
                    time:'2020-01-09',
                    content:'《全国人民代表大会常务委员会关于修改〈中华人民共和国道路交通安全法〉的决定》已由中华人民共和国第十一届全国人民代表大会常务委员会第二十次会议于2011年4月22日通过，现予公布，自2011年5月1日起施行。',
                },
                {
                    id:3,
                    img:'regulations3',
                    title:'中华人民共和国道路交通安全法实施条例',
                    time:'2020-01-09',
                    content:'根据《中华人民共和国道路交通安全法》(以下简称道路交通安全法)的规定，制定本条例。',
                },
                {
                    id:4,
                    img:'regulations4',
                    title:'道路运输企业主要负责人和安全生产管理人员安全考核管理办法',
                    time:'2020-01-09',
                    content:'为规范道路运输企业主要负责人和安全生产管理人员的安全生产知识和管理能力考核（以下简称安全考核），根据《中华人民共和国安全生产法》《中华人民共和国道路运输条例》等法律法规，制定本办法。',
                },
                {
                    id:5,
                    img:'regulations5',
                    title:'生产安全事故报告和调查处理条例',
                    time:'2020-01-09',
                    content:'为了规范生产安全事故的报告和调查处理，落实生产安全事故责任追究制度， 防止和减少生产安全事故，根据《中华人民共和国安全生产法》和有关法律， 制定本条例。',
                },
                {
                    id:6,
                    img:'regulations6',
                    title:'道路运输车辆技术管理规定',
                    time:'2020-01-09',
                    content:'中华人民共和国交通运输部令 2019 年第 19 号《交通运输部关于修改〈道路运输车辆技术管理规定〉的决定》已于 2019 年 6 月 12 日经第 12 次部务会议通过，现予公布，自公布之日起施行。',
                },
                {
                    id:7,
                    img:'regulations7',
                    title:'道路运输从业人员管理规定',
                    time:'2020-01-09',
                    content:'《交通运输部关于修改〈道路运输从业人员管理规定〉的决定》已于2019年6月12日经第12次部务会议通过，现予公布，自公布之日起施行。',
                },
                {
                    id:8,
                    img:'regulations8',
                    title:'交通运输企业安全生产标准化建设评价管理办法',
                    time:'2020-01-09',
                    content:'为深入贯彻落实《中华人民共和国安全生产法》，大力推进企业安全生产标准化建设，现将《交通运输企业安全生产标准化建设评价管理办法》印发给你们，请遵照执行。',
                },
                {
                    id:9,
                    img:'regulations9',
                    title:'道路运输车辆动态监督管理办法(2016 修正)',
                    time:'2020-01-09',
                    content:'《交通运输部公安部国家安全生产监督管理总局关于修改〈道路运输车辆动态监督管理办法〉的决定》已于2016年4月7日经交通运输部第7次部务会议通过，现予公布。',
                },
                {
                    id:10,
                    img:'regulations10',
                    title:'国务院办公厅关于印发深化收费公路制度改革取消高速公路省界收费站实施方案的通知',
                    time:'2020-01-09',
                    content:'各省、自治区、直辖市人民政府，国务院各部委、各直属机构：《深化收费公路制度改革取消高速公路省界收费站实施方案》已经国务院同意，现印发给你们，请结合实际，认真组织实施。 ',
                },
            ]
        };
    },

    methods: {
        goDetailpage(id){
            this.$router.push({
                path:'/gzjt_Detailpage',
                query:{
                    id:id
                }
            })
        }
        
    },

    mounted(){
        
    },
};

</script>
<style lang="stylus" scoped>
@import '../asset/css/gzjtRegulations.styl'
</style>