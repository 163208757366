<template>
    <div class="CreditInquiry publicStylus">
        <div class="container">
            <div class="top">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/Credit-inquiry-banner.png" class="banner" alt="">
                <div class="content">
                    <div class="info" @click="goPage(2)">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/personnel-icon.png" class="icon" alt="">
                        <p>查家政服务人员</p>
                    </div>
                    <div class="info" @click="goPage(1)">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/company-icon.png" class="icon" alt="">
                        <p>查家政公司</p>
                    </div>
                </div>
                
            </div>
            <div class="bottom">
                <div class="item" v-for="item in list" :key="item.id">
                    <h2 class="title zh">{{item.zh}}</h2>
                    <p class="cn">{{item.cn}}</p>
                    <p class="line"></p>
                    <p class="content">{{item.content}}</p>
                </div>
            </div>
        </div>
        <footers></footers>
    </div>
</template>
<script>
import footers from './sdjz2Footer.vue'
export default {
    name:'CreditInquiry',
    components:{footers},
    data(){
        return{
            list:[
                {
                    id:1,
                    zh:'信息收集',
                    cn:'Information collection',
                    content:'通过全面的官方渠道，对“职业知识技能、服务质量、健康指数、保险和其他”五大维度对从业人员进行合法信息收集。'
                },
                {
                    id:2,
                    zh:'模型分析',
                    cn:'Model analysis',
                    content:'模型共涉及将近20个一级指标，30余个二级指标，全方位展示从业人员信用信息、基本素养、从业技能。'
                },
                {
                    id:3,
                    zh:'一键式查询',
                    cn:'One click query',
                    content:'从业人员信用信息、家政公司信用信息，一键式查询，为家政行业全场景提供信用服务，真正实现“让守信者畅通无阻，让失信者寸步难行”。'
                },
            ]
        }
    },
    methods:{
        goPage(type){
             const host = window.location.host;
            window.open(`https://${host}/personnel?type=${type}`)
            // this.$router.push({
            //     path:'/personnel',
            //     query:{
            //         type:type
            //     }
            // })
        }


    }
}
</script>
<style lang="stylus" scoped>
@import "../asset/css/publicStylus.styl"
</style>