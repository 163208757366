<template>
    <!-- 测评作答详情 -->
    <div>
        <div class="evaluation">
            <div class="backButton">
                <span @click="goBack">
                        <i class="back el-icon-arrow-left"></i>
                    返回</span>
            </div>
            <div class="evaluation-con">
                <div class="left">
                    <div class="title">
                        <span class="line"></span>
                        <h4 :title="examDetail.assessName">{{examDetail.assessName}}</h4>
                    </div>
                    <div class="testPaper">
                        <div class="item" :id="'s' + item.id" v-for="(item, index) in questionsList" :key="index">
                            <div class="stem">
                                <h4>{{index + 1}}.【
                                    {{item.type == 1 ? '单选题' : item.type == 2 ? '多选题' : item.type == 3 ? '判断题' : item.type == 4 ? '高阶题' : ''}}
                                    】<span v-html="item.title">{{item.title}}</span></h4>
                                <li v-for="(item1, index1) in item.optionList" :key="index1">{{item1.optionNumber}}<span v-html="item1.optionContent">{{item1.optionContent}}</span></li>
                            </div>
                            <div class="option">
                                <li class="answer"
                                    v-for="(item1, index1) in item.optionList"
                                    :key="index1"
                                    @click="
                                        handleClickOption(
                                            item,
                                            item.optionList,
                                            item.type,
                                            item1,
                                            index,
                                            index1
                                        )
                                    ">
                                    <span
                                        v-if="item.type!=2"
                                        class="option-btn"
                                        :class="{
                                            'active': item1.isAnswers
                                        }"
                                    />
                                    <span
                                        v-if="item.type==2"
                                        class="el-checkbox__input"
                                        :class="{
                                            'is-checked': item1.isAnswers
                                        }">
                                        <span class="el-checkbox__inner"></span>
                                    </span>
                                    <span class="option-text">{{item1.optionNumber}}</span>
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="scantron">
                        <div class="titles">答题卡</div>
                        <div class="type">
                            <span class="finish"><span></span>已答</span>
                            <span class="none"><span></span>未答</span>
                        </div>
                    </div>
                    <div :class="['answer-num', winH==768?'min-answer-num':'']">
                        <span v-for="(item, index) in questionsList" :key="index"
                            :class="['pointer', item.result ? 'ydt' : '']"
                            @click.prevent="anchor('s' + item.id)">
                            {{index + 1}}
                        </span>
                    </div>
                    <div class="answer-btn">
                        <div class="btn" @click="submitExam">提交</div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            class="tip-dialog"
            :visible.sync="coursedialogVisible"
            width="400px"
            :before-close="handleClose">
            <img src="../asset/images/ic-ts@2x.8d378b1f.png" class="icon-img" alt="">
            <p class="titles">提示</p>
            <p class="text" v-if="complete">是否要进行交卷</p>
            <p class="text" v-else>为保证测评准确性，请全部作答后提交</p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose" v-if="complete">{{cancelText}}</el-button>
                <el-button type="primary" @click="rightBtn()" :style="complete?'width:120px':'width:100%'">{{submitText}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'evaluation',
    show: true,
    components: {},
    data() {
        return {
            coursedialogVisible: false,
            cancelText:'',
            submitText:'',
            complete: false,
            examDetail: {},
            stList: 0,
            questionsList: [],
            saveData: {
                assessId: '',
                refId: '',
                refType: '',
                userAnswerVoList: [], // 本试卷答题信息列表
                endTime: '', // 提交时间
                startTime: ''
            },
            winH: window.screen.height,
            assessId: this.$route.query.assessId,
            refId: this.$route.query.refId,
            refType: this.$route.query.refType,
            startTime: '',
            isclick:true,
        };
    },
    filters: {
        ellipsis(msg){
            if(msg && msg.length>34){
            return msg.substr(0, 34)+'...'
            }
            return msg
        }
    },
    mounted() {},
    created() {
        this.questionList();
    },
    methods: {
        // 选择          题目        选项集合    类型   选中项  题目   选项
        handleClickOption(question, optionList, type, item, index, index2) {
            const item1 = item;
            const myanswer = []; // 多选题答案
            /* type  1单选  2多选  3判断   4高阶*/
            if (type !== 2) {
                optionList.forEach((item2, index3) => {
                    const item4 = item2;
                    if (index2 === index3) {
                        this.$set(item1, "isAnswers", true);
                        this.$set(question, "myAnswers", item1.optionNumber);
                    } else {
                        this.$set(item4, "isAnswers", false);
                    }
                });
                question.result = true;
            } else {
                if (!item1.isAnswers) {
                    this.$set(item1, "isAnswers", true);
                } else {
                    this.$set(item1, "isAnswers", false);
                }
                optionList.forEach(item2 => {
                    if (item2.isAnswers) {
                        myanswer.push(item2.optionNumber);
                    }
                });
                this.$set(question, "myAnswers", myanswer.join(","));
                if (myanswer.length > 0) {
                    question.result = true;
                } else {
                    question.result = false;
                }
            }
            // 获取已答题数目
            this.changeNum = 0;
            this.questionsList.forEach(item=>{
                if (item.myAnswers) {
                    this.changeNum++;
                }
            })
        },
        questionList() {
            this.$api.assess.questionList(
                {
                    assessId: this.assessId,
                    refId: this.refId,
                    refType: this.refType
                }
            ).then((res) => {
                if(res.data){
                    this.examDetail = res.data;
                    this.questionsList = res.data.examQuestionsList;
                    this.stList = this.examDetail.examQuestionsList.length;
                    this.startTime = this.dateFormat();
                }
            });
        },
        // 点击题号锚点
        anchor(anchorName) {
            const anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                anchorElement.scrollIntoView();
            }
        },
        // 返回
        goBack() {
            this.$router.go(-1);
        },
        rightBtn() {
            if (this.complete) {
                if(this.isclick===true){
                    this.isclick=false;
                    this.saveUserAnswer();
                }
            } else {
                this.handleClose();
            }
        },
        // 提交
        submitExam() {
            let num = 0;
            this.questionsList.forEach(val => {
                if (val.result) {
                    num += 1;
                }
            });
            this.coursedialogVisible = true;
            if (num === Number(this.stList)) {
                this.complete = true;
                this.cancelText = "取消";
                this.submitText = "提交";
            } else {
                this.complete = false;
                this.submitText = "继续答题";
            }
        },
        // 关闭弹框
        handleClose () {
            this.coursedialogVisible = false;
        },
        // 提交测评
        saveUserAnswer() {
            this.coursedialogVisible = false;
            this.getPaperList();
            this.$api.assess.saveUserAnswer(this.saveData).then((res) => {
                if(res.data){
                    this.$router.push({
                        path: '/assess',
                        query: {
                            assessId: this.assessId,
                            refId: this.refId,
                            refType: this.refType
                        }
                    })
                }
                this.isclick=true;
            });
        },
        // 试卷信息
        getPaperList() {
            this.saveData.assessId = this.assessId;
            this.saveData.refId = this.refId;
            this.saveData.refType = this.refType;
            this.saveData.startTime = this.startTime;
            this.saveData.endTime = this.dateFormat();
            this.saveData.userAnswerVoList = [];
            this.questionsList.forEach(item => {
                const item1 = item;
                if (item1.myAnswers && item1.myAnswers.length > 1) {
                    let userAnswer = item1.myAnswers.split(",");
                    item1.myAnswers = Array.from(userAnswer)
                        .sort()
                        .join(",");
                }
                if (item1.myAnswers !== undefined) {
                    this.saveData.userAnswerVoList.push({
                        userAnswer: item1.myAnswers,
                        questionId: item1.id,
                        questionType: item1.type,
                    });
                }
            });
        },
        // 时间转换
        dateFormat() {
            const date = new Date();
            const year = date.getFullYear();
            /* 在日期格式中，月份是从0开始的，因此要加0
             * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
             * */
            const month =
                date.getMonth() + 1 < 10
                    ? `0${date.getMonth() + 1}`
                    : date.getMonth() + 1;
            const day =
                date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            const hours =
                date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
            const minutes =
                date.getMinutes() < 10
                    ? `0${date.getMinutes()}`
                    : date.getMinutes();
            const seconds =
                date.getSeconds() < 10
                    ? `0${date.getSeconds()}`
                    : date.getSeconds();
            // 拼接
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
    }
};
</script>
<style lang="stylus" scoped>
@import "../asset/css/evaluation.styl"
</style>