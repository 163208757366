<template>
  <div>
    <div class="wanghong wanghong-nav">
      <div class="menuContents">
        <img class="imglogoG fl" src="@/assets/images/logos/wanghong_logo.png" alt="" />
        <div class="gongxin-nav-bar fl">
          <p class="item-nav fl" 
            v-for="item in navList" 
            :key="item.id"
            :class="{'active': setActive(item)}"
            @click="clickNav(item)"
          >
            {{item.title}}
          </p>
          <el-dropdown class="move fl" placement="bottom">
            <span class="el-dropdown-linkapp">
              <span class="dropdown-title link-app">移动端</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="wanghong-nav-gx-menu">
              <el-dropdown-item class="nav-gx-item">
                <div class="gongxin-dl-wrap">
                  <div class="img-wrap">
                    <img src="@assets/user/wanghong_wechat.png" alt="" class="app-download" />
                    <img src="@assets/user/border-dec.png" class="border border1"/>
                    <img src="@assets/user/border-dec.png" class="border border2"/>
                  </div>
                  
                  <p>移动学习 随时随地</p>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div v-if="!token" class="un-login fl" @click="jumpLogin('wanghong_homepage')">
            登录/注册
        </div>
        <template v-else>
          <account :replacePage="'wanghong_homepage'"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import account from './account.vue'
export default {
  name: 'wanghongNav',
  components: {
    account
  },
  data () {
    return {
      // newVal: null,
      // branchList: [],
      // branchListShow: false,
      // primary: '',
      // userInfo: {},
      // branchName: '',
      // branchId: '',
      activeNav: this.$route.path,
      examId: 0,
      trainId: 0,
      // showUser: false,
      token: '',
      mode: '0', //  0:其他 1' 学习中心 '2': 模拟考试
      navList: [
        {
          path: '/wanghong_homepage',
          needLogin: false,
          title: '首页',
          activePaths: ['/wanghong_homepage', '/wanghongDetails', '/orderPage'],
          mode: '0'
        },
        {
          path: '/course/catalog',
          needLogin: true,
          title: '学习中心',
          activePaths: ['/course', '/course/catalog', '/course/resdExam', '/course/testPaper', '/course/handPaper', '/course/testPaperDetails'],
          mode: '1'
        },
        {
          path: '/course/catalog',
          needLogin: true,
          title: '考试中心',
          activePaths: ['/course', '/course/catalog', '/course/resdExam', '/course/testPaper', '/course/handPaper', '/course/testPaperDetails'],
          mode: '2'
        },
        
        {
          path: '/liveList',
          needLogin: true,
          title: '直播中心',
          activePaths: ['/liveList'],
          mode: '0',
        },
        {
          path: '/wanghongPartner',
          needLogin: false,
          title: '合作伙伴',
          activePaths: ['/wanghongPartner'],
          mode: '0',
        }
      ],
      goodsId:null,//商品id
    }
  },
  created () {
  },
  watch: {
    $route() {
      setTimeout(() => { //路由跳转
        this.activeNav = this.$route.path
        this.token = localStorage.getItem('token')
      }, 100)
    }
  },
  async mounted () {
    setTimeout(() => { //刷新
      this.activeNav = this.$route.path
      this.token = localStorage.getItem('token')
    }, 100)
    
    // this.$api.home.getTrain().then((res) =>  {
    //   this.trainId = res.data.id;
    // })
    this.$api.home.findSaleWanghongGoods().then(res=>{
      this.trainId = res.data.referId;
      this.goodsId = res.data.id
      sessionStorage.setItem('goodsId',res.data.id)
      this.$api.home.findGoodsGiftByGoodsId(this.goodsId).then((res) =>  {
            this.examId = res.data;
        })
    })
    
    this.mode = this.$route.query.dymode || '0';
  },
  methods: {
      // gettoken(token){
      //     this.token = token
      // },
    // toggleUser(show) {
    //   this.showUser = show;
    // },
    setActive(item) {
      // 学习中心，模拟考试的内部跳转需要处理。
      let flag = false;
      this.mode = this.$route.query.dymode || '0';
      if (this.mode === item.mode) {
        flag = !!item.activePaths.includes(this.activeNav);
      }
      
      return flag;
    },
    clickNav(item) {
      if (this.$route.path === '/course/testPaper') {
        return;
      }
      let name = item.path;
      if (item.title === '考试中心') {
        name = `${name}?id=${this.examId}&goodsId=${this.goodsId}&dymode=2`
        // sessionStorage.setItem('dy-mode', '2');
        this.mode = '2';
      } else if (item.title === '学习中心') {
        name = `${name}?id=${this.trainId}&goodsId=${this.goodsId}&dymode=1`
        // sessionStorage.setItem('dy-mode', '1');
        this.mode = '1';
      } else if (item.path) {
        // sessionStorage.setItem('dy-mode', '0');
        this.mode = '0';
      }
      // if (item.path) {
      //   sessionStorage.setItem('dy-mode', item.mode);
      // }
      console.log(name, 'lllll')
      if (item.needLogin && !localStorage.getItem('token')) {
        this.jumpLogin(name);
      } else if (name) {
        this.$router.push(name)
      }
      
    },
    jumpLogin(name) {
      this.$router.push({
            name: 'login',
            query: {
                name
            }
        })
    },
  }
}
</script>
<style lang="stylus" scoped>
</style>
<style lang="stylus">
.wanghong-nav {
    background: #fff !important;
    box-shadow: 0px 2px 24px 0px rgba(34, 34, 55, 0.06);
    font-family: PingFangSC-Regular, PingFang SC;
    height: 80px;
    position: fixed;
    width: 100%;
    z-index: 666;
    .menuContents {
      max-width: 1200px !important;
      margin: 0 auto;
      min-width: 1266px;
      background: #fff;
    }
    .imglogoG {
      // width: 322px !important;
      height 54px
    //   height: 26px;
      margin-top: 15px !important;
    //   height: 32px;
    }
    .gongxin-nav-bar {
      height: 80px;
      margin-left: 41px;
      .item-nav {
        display: inline-block;
        font-size: 18px;
        line-height: 76px;
        margin: 0 26px;
        cursor: pointer;
        color:  #666;
      }
      .item-nav.active {
        // color: linear-gradient(133deg, #6D6DFF 0%, #FF48A7 100%);
        border-bottom: 4px solid #A85FDC;
        color: #8E37ED;
        // line-height: 22px;
        background: linear-gradient(133deg, #6D6DFF 0%, #FF48A7 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
      }
    }
    .move {
        .el-icon-arrow-down.el-icon--right {
            display: none;
        }
        .el-dropdown-linkapp .link-app {
          line-height 76px !important;
          margin-right: 0;
        }
        :focus{
          outline: none !important;
        }
    }
    .un-login {
        background: rgba(215, 215, 215, .29);
        color: #747474;
        width: 120px;
        height: 42px;
        text-align: center;
        line-height: 42px;
        border-radius: 21px;
        margin-top: 18px;
        font-size:18px;
        cursor: pointer;
        margin-left: 40px;
    }
    .move.el-dropdown {
        color:  #666;
        margin-left: 24px;
        span {
            font-size: 18px;
        }
    } 
}
.wanghong-nav-gx-menu {
      text-align: center;
      border-radius: 6px;
      margin-top: 22px !important;
      .nav-gx-item {
          &:hover {
            background: #fff;
          }
          .gongxin-dl-wrap {
            padding: 0 74px;
            .img-wrap {
              width: 140px;
              height: 140px;
              border: 1px solid #F6F6F6;
              position: relative;
              margin: 46px auto 18px;
              .app-download {
                  width: 100%;
                  height: 100%;
              }
              .border {
                width: 14px;
                height: 14px;
                position: absolute;
                &.border1 {
                  left: 0;
                  top: 0;
                }
                &.border2 {
                  right: 0;
                  bottom: 0;
                  transform: rotate(180deg);
                }
              }
            }
            

            p {
                line-height: 22px;
                font-size: 16px;
                color: #666;
                margin-bottom: 40px;
            }
          }
          
      }
  }

</style>
