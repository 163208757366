<template>
 <div class='footers'>
     <el-row class="footers-con" type="flex" justify="center">
         <div class="footer-con-content">
             <div class="left">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/aic/logo_footer.png" alt="">
            </div>
            <!-- <div class="line line-l"></div> -->
            <div class="middle">
                
                <router-link to="/aic_about" target="a" class="about">关于我们</router-link>
                <br>
                <p>技术支持方：伟东云教育集团</p>
                <p class="address">
                    地址: 北京市西城区北三环中路燕莎盛世大厦5层
                </p>
                <p class="tel">客服电话: 400-8555-686</p>
            </div>
            <!-- <div class="line line-r"></div> -->
            <div class="right">
                 <p class="title">友情链接</p>
                 <p class="link"><a href="https://oa.zfoline.net/#/registry/subject?organization=08d92fcb-0eb1-4680-8e55-547f5af5769fhttps://oa.zfoline.net/#/registry/subject?organization=08d92fcb-0eb1-4680-8e55-547f5af5769f" target="_blank">工业和信息化部教育与考试中心</a></p>
                 <p class="link"><a href="http://www.tech-skills.org.cn/#%2FgradeInfo%2F22" target="_blank">工业和信息化技术技能人才网上学习平台</a></p>
            </div>
         </div>
     </el-row>
     <saas-nsr-footer :isContract="false" :bgColor="'#242C40'" />
     <!-- <el-row class="footers-f">
         <h2>北京伟东凌鸿教育科技有限公司@版权所有 京 ICP备10026535号-6</h2>
     </el-row> -->
 </div>
</template>

<script>
export default {
    name:'footers',
    props: {
    },
    data () {
        return {
            options: [
                {
                    value: 'http://www.sasac.gov.cn/',
                    label: '国务院国有资产监督管理委员会'
                },
                {
                    value: 'https://www.wdecloud.com/',
                    label: '伟东云教育'
                },
                {
                    value: 'http://www.ciic.com.cn/',
                    label: '中智集团'
                },
            ],
            value: ''
        };
    },

    methods: {
        selectChanged(value) {
            if (value) {
                window.open(value,"_blank")
                // window.location.href = value;
            }
        },
        jump () {
            window.open("https://www.miiteec.org.cn/", "_blank");
        }
    },

    mounted(){
    },
}

</script>
<style lang='stylus' scoped>
    @import "../../asset/css/footers.styl"
</style>
<style lang='stylus'>
    .footers .el-input__inner {
        width: 159px;
        height: 34px;
        line-height: 34px;
    }
    .footers .el-input__icon {
        line-height: 34px;
    }
    .el-select {
        display: inline-block;
        vertical-align: middle;
    }
</style>