<template>
    <div class="personnel">
        <div class="banner">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/query-banner.png" alt="">
        </div>
        <div class="container">
            <div class="tab">
                <p class="tabName" @click="tabClick(item)" :class="{active:tabIndex == item.id}" v-for="item in tabList" :key="item.id">
                    {{item.name}}
                </p>
            </div>
            <div class="content" v-show="tabIndex == 1">
                <div class="input">
                    <el-input v-model="companyValue"  class="company-input" placeholder="请输入公司的名称"></el-input>
                    <el-button type="primary" class="search" @click="search(1)">查询</el-button>
                </div>
                <div class="list">
                    <img v-if="!companyShow" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/query-icon.png" class="query-icon" alt="">
                    <div v-else>
                        <img @click="dialogVisible3 = true" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/personnel/searchImg1.png" class="cover" alt="">
                        <img @click="dialogVisible3 = true" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/personnel/searchImg2.png" class="cover" alt="" style="margin-top:20px;">
                        <img @click="dialogVisible3 = true"  src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/personnel/searchImg3.png" class="cover" alt="" style="margin-top:20px;">
                    </div>
                </div>
            </div>
            <div class="content" v-show="tabIndex == 2">
                <div class="input">
                    <el-input v-model="cardValue"  class="card-input" placeholder="请输入家政员的身份证号"></el-input>
                    <el-input v-model="phoneValue"  class="phone-input" placeholder="请输入家政员的姓名"></el-input>
                    <el-button type="primary" class="search" @click="search(2)">查询</el-button>
                </div>
                <div class="info">
                    <img v-if="!personnelShow"  src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/query-icon.png" class="query-icon" alt="">
                    <img v-else src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/personnel/personnel.png" class="info-box" @click="dialogVisible5 = true" alt="">
                    <!-- <div class="info-container" v-else>
                        <div class="empty" v-if="emptyShow">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/empty-icon.png" class="icon" alt="">
                            <p> 该“身份证号+姓名”未查询到具体的家政人员，请确认后再查询！</p>
                        </div>
                        <div v-else class="sample">
                            <h1 class="title-h1">
                                家政服务人员信用报告<span style="font-size:36px">（样例）</span>
                            </h1>
                            <p class="title-time">更新日期:  2021-07-09</p>
                            <div class="sample-box" :class="!emptyShow?'sample-retrieve':''">
                                <div class="title-box">
                                    <div class="info1">

                                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/personnel/retrieve-head.png" class="head-icon" alt="">
                                        <span class="name">赵丽华</span>
                                    </div>
                                    <div class="info2">
                                        <span class="number">信用分值：78</span>
                                        <span class="level">
                                            <span>信用等级： </span>
                                            <img src="../asset/images/stars-icon1.png" class="icon" alt="">
                                            <img src="../asset/images/stars-icon1.png" class="icon" alt="">
                                            <img src="../asset/images/stars-icon1.png" class="icon" alt="">
                                            <img src="../asset/images/stars-icon1.png" class="icon" alt="">
                                            <img src="../asset/images/stars-icon2.png" class="icon" alt="">
                                        </span>
                                    </div>
                                    <div class="info3">
                                        <span>所属家政公司: 金贝家政连锁服务机构</span>
                                        <span>从属关系:  员工制员工</span>
                                    </div>
                                    
                                </div>
                                <div class="info-box">
                                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/personnel/retrieve-1.png" class="Radar" alt="">
                                    <div class="info">  
                                        <div class="item item1">
                                            <div class="subheading-box">
                                                <p class="title">个人基本信息</p>
                                                <span class="text">个人基本身份及行业信息</span>
                                            </div>
                                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/personnel/retrieve-2.png" class="cover" alt="">
                                        </div>
                                        <p class="btn" v-if="!payShow" @click="payShow = true">付费后查看更多信息</p>
                                        <div class="item item1">
                                            <div class="subheading-box">
                                                <p class="title">个人职业信息</p>
                                                <span class="text">个人与职业相关的信息</span>
                                            </div>
                                            <img v-if="!payShow" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/personnel/Not-retrieve-3.png" class="cover" alt="">
                                            <img v-else  src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/personnel/retrieve-3.png" class="cover" alt="">
                                        </div>
                                        <div class="item item1">
                                            <div class="subheading-box">
                                                <p class="title">犯罪背景核查</p>
                                                <span class="text">商务部、公安部要求开展的家政服务员犯罪背景核查的内容</span>
                                            </div>
                                            <img v-if="!payShow" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/personnel/Not-retrieve-4.png" class="cover" alt="">
                                            <img v-else src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/personnel/retrieve-4.png" class="cover" alt="">
                                        </div>
                                        <div class="item item1">
                                            <div class="subheading-box">
                                                <p class="title">培训与证书</p>
                                                <span class="text">家政服务员所参与的行业培训和取得证书的情况</span>
                                            </div>
                                            <img v-if="!payShow" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/personnel/Not-retrieve-5.png" class="cover" alt="">
                                            <img v-else src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/personnel/retrieve-5.png" class="cover" alt="">
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                            
                        </div>
                    </div> -->
                </div>
            </div>



            <div class="pingtaishujutongji" v-show="(!companyShow &&  isShowPingtaishuju && tabIndex == 1) || ( !personnelShow && tabIndex == 2)">
                <img class="pingtaishujutongjiImg" src="../asset/images/pingtaitongji.png"/>

                <div class="shujutongjuList">
                    <div>
                        <p>16694</p>
                        <p>家政公司</p>                 
                    </div>
                    <div>
                        <p>390264</p>
                        <p>服务人员</p>                 
                    </div>
                    <div>
                        <p>16694</p>
                        <p>客户</p>                 
                    </div>
                    <div>
                        <p>39694</p>
                        <p>派单次数</p>                 
                    </div>

                </div>
            </div>
        </div>
        <el-dialog
            title=""
            :visible.sync="dialogVisible"
            width="810"
            :before-close="handleClose">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/gongsi-tanchaung.png" style="width:100%" alt="">
            
        </el-dialog>
        <el-dialog
            title=""
            :visible.sync="dialogVisible2"
            width="810"
            :before-close="handleClose2">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/renyuan-tanchuang.png" style="width:100%" alt="">
            
        </el-dialog>
        <el-dialog
            title="分数等级说明"
            :visible.sync="dialogVisible5"
            width="400px"
            :before-close="handleClose5">
            <p class="dialogVisible5-text">家政行业专家根据商务部及各省文件，并结合在行业龙头企业的调研，构建出权威可信的模型。信用模型包含7大维度、近60项指标，涵盖基本信息、业务信息、培训信息等家政行业全方位维度。家政服务人员和家政公司信用的每一分都和具体的指标情况息息相关。</p>
        </el-dialog>
        <el-dialog
            title=""
            :visible.sync="dialogVisible3"
            width="810"
            :before-close="handleClose3">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/personnel/chagongsiPopImg2.png" style="width:100%" alt="">          
        </el-dialog>

        <footers></footers>
    </div>
</template>
<script>
import footers from './sdjz2Footer.vue'
export default {
    name:'personnelStatic',
    components:{footers},
    data(){
        return{
            dialogVisible5:false,
            payShow:false,
            dialogVisible3:false,
            dialogVisible2:false,
            dialogVisible:false,
            companyValue:'',
            companyShow:false,
            personnelShow:false,
            emptyShow:false,
            cardValue:'',
            phoneValue:'',
            tabIndex:1,
            tabList:[
                {
                    id:1,
                    name:'查家政公司'
                },
                {
                    id:2,
                    name:'查家政服务人员'
                }
            ],
            isShowPingtaishuju: true
        }
    },
    mounted(){
        this.tabIndex = this.$route.query.type || 2
    },
    methods:{
        handleClose(){
            this.dialogVisible = false
        },
        handleClose2(){
            this.dialogVisible2 = false
        },
        handleClose5(){
            this.dialogVisible5 = false;
        },
        handleClose3(){
            this.dialogVisible3 = false
        },
        handleClose4(){
            this.dialogVisible4 = false
        },

        tabClick(item){
            this.tabIndex = item.id
            this.companyValue = ''
            this.cardValue = ''
            this.phoneValue = ''
            this.companyShow=false;
            this.personnelShow=false;
            this.emptyShow=false;
        },
        search(type){
            if(type == 1){
                if(this.companyValue){
                    this.companyShow = true
                    this.isShowPingtaishuju = false
                }else {
                    this.companyShow = false
                    this.isShowPingtaishuju = true
                }
            }else {
                if(this.cardValue == '' || this.phoneValue == ''){
                    this.emptyShow = true;
                    this.isShowPingtaishuju = true
                }else {
                    this.emptyShow = false
                    this.isShowPingtaishuju = false
                }
                if(this.cardValue != '' || this.phoneValue != ''){
                    this.personnelShow = true
                }else {
                    this.personnelShow = false
                }
            }
            
        }
    }
}
</script>
<style lang="stylus" scoped>
@import "../asset/css/personnel-static.styl"
.info-box{
    width 1000px
}
</style>
<style lang="stylus">

.dialogVisible4 .el-dialog__body {
        padding: 0px 20px;
}
</style>