<template>
  <div>
    <i
      :class="{
        'el-icon-s-fold': !isCollapse,
        'el-icon-s-unfold': isCollapse,
        icon: true
      }" @click="setCollapse"></i>
  </div>
</template>

<script>
export default {
  props: {
    isCollapse: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    setCollapse () {
      this.$parent.setCollapse()
    }
  }
}
</script>

<style lang="stylus" scoped>
.icon
  padding 15px
  font-size 30px
</style>
