import { render, staticRenderFns } from "./family.vue?vue&type=template&id=05b63162&scoped=true&"
import script from "./family.vue?vue&type=script&lang=js&"
export * from "./family.vue?vue&type=script&lang=js&"
import style0 from "./family.vue?vue&type=style&index=0&id=05b63162&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05b63162",
  null
  
)

export default component.exports