<template>
  <div>
    <div class="liveListCont" ref="livelistContRef"  :style="{ minHeight: liveListContHeight }">
      <h1 class="allLiveTile">所有直播</h1>
      <div class="liveList" ref="">
        <div v-if="listData.length">
          <div class="liveListItem" v-for="(item, idx) in  listData" :key="idx">
            <div>
              <p class="mainLiveTitle textCut">{{item.title}}</p>
              <p class="mainLiveTime">{{item.beginTime.split(' ')[0].split('-')[1]}}月{{item.beginTime.split(' ')[0].split('-')[2]}}日 {{item.beginTime.split(' ')[1].substr(0,5)}}</p>

              <div class="itemStatus">
                  <p class="itemStatusText fl">{{item.status == 1 ? '正在直播中' : '未开始'}}</p>
                  <p class="itemStatusBtn itemLiveingStatusBtn fr"  v-if="item.status == 1" @click="enterliveRoom(item)">进入直播间</p>
                  <p class="itemStatusBtn fr" v-else>进入直播间</p>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="noLiveCont">
          <div class="noLive">
            <img src="../asset/img/noLive_icon.png"/>
            <p class="noTextTips">暂无直播</p>
          </div>
        </div>
      </div>

      

    </div>
      <footers v-if="theme==='gongxin'"/>
  </div>
</template>

<script>
import footers from '../../gongxin/block/front/footers'


export default {
  name: 'liveList111',
  components: {
    footers,

  },
  data () {
    return {
          theme: '',
          listData: [],
          paging: {
            params: {
              pageNum: 1,
              pageSize: 1000
            },

          },

    }
  },
  filters: {

  },
  created () {
    this.theme = localStorage.getItem('theme');
    this.getLiveList()

    this.getLiveList()
    this._onlineTimer = setInterval(()=>{
      this.getLiveList()
    }, 5 * 1000 )


  },
  computed: {
    liveListContHeight() {
      return window.innerHeight - 402+ "px";
    },

  },
  mounted() {
    this.windowResize();

  },

  beforeDestroy() {
    clearInterval(this._onlineTimer);
    

  },


  methods: {

    windowResize() {
      window.onresize = () => {
        this.clientHeight = `${document.documentElement.clientHeight}`;
        if (this.$refs.livelistContRef) {
          this.$refs.livelistContRef.style.minHeight = this.clientHeight - 402 + "px";
        }

      };
    },

    getLiveList(){
      this.$api.live.findByCondition(
        {
          pageNum: this.paging.params.pageNum,
          pageSize:  this.paging.params.pageSize,
        }
      )
      .then(res=>{
          console.log('res===', res)
          this.listData = res.data.list
      })
    },

    enterliveRoom(data) {
      this.$api.live.audienceEnterRoom({id: data.id}).then(async (res) => {
            if (res.success) {
                this.$router.push({
                    path: '/studentSee',
                    query:{
                        
                        roomId: data.id,
                        type: 1,
                        //title: encodeURIComponent(data.title),
                    }
                })
            }
      });

    },

  },

};
</script>

<style lang="stylus" scoped>
 @import "../asset/css/liveing.styl"
</style>
