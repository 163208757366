<template>
    <div class="collegeDetails">
        <div class="banner-wrap" v-if="xyxqimg[id]">
            <img :src="xyxqimg[id]" />
        </div>
        <!--学院介绍-->
        <div class="college">
            <div class="trainng-card-wrap">
                <div class="trainng-card">
                    <div class="left">
                        <div class="list">
                            <p class="time">1<span class="unit">年</span></p>
                            <p class="title">课程有效期</p>
                        </div>
                        <div class="line"></div>
                        <div class="list">
                            <p class="time">6<span class="unit">个月</span></p>
                            <p class="title">学习时长</p>
                        </div>
                        <div class="line"></div>
                        <div class="list">
                            <p class="teacher-time">名师授课</p>
                            <p class="title">高效明确</p>
                        </div>
                    </div>
                    <div class="right">
                        <div class="price">
                            <p class="original">
                                ¥<span class="now-price">15800</span>
                            </p>
                            <p class="actual">
                                ¥19800
                            </p>
                        </div>
                        <div class="btn-study" @click="jumpStudy">
                            立即购买
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="college-label">
                <h2 class="college-title">
                    <span class="college-line"></span>
                    学院介绍
                </h2>
            </div> -->
            <!-- <div class="teacher">
                <img class="photo" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/teacher.png" alt="">
                <div class="synopsis">
                    <h2 class="name">李开复老师</h2>
                    <p class="position">中智大学伟东云企业管理学院院长</p>
                    <p class="introduce">李开复曾就读于卡内基梅隆大学，获计算机科学博士学位，后担任副教授。他是一位信息产业的经理人、创业者和电脑科学的研究者。曾在苹果、SGI、微软和Google等多家IT公司担当要职。2009年9月从谷歌离职后创办创新工场，并任董事长兼首席执行官。</p>
                </div>
            </div> -->
            <!-- <div class="img">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/xyjs.png" alt="">
            </div> -->
        </div>
        <!--课程目录-->
        <div class="course">
            <div class="course-label">
                <h2 class="course-title">
                    <span class="course-line"></span>
                    课程目录
                </h2>
            </div>
            <el-collapse v-model="activeNames">
                <el-collapse-item :name="index" v-for="(el,index) in itemList[id]" :key="index" v-show="el.childrenList">
                    <template slot="title">
                        <i :class="activeNames.includes(index)?'el-icon-caret-bottom':'el-icon-caret-right'"></i>
                        <span class="sourse-title">{{el.name}}</span>
                        <span class="label buy">购买后可解锁</span>
                    </template>
                    <ul v-if="el.childrenList">
                        <li @click="jumpStudy" v-for="(ele, index) in el.childrenList" :key="index">
                            <p class="sourse">
                                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/icon2.png" alt="">
                                <span class="sourse-name" :title="ele.name">{{ele.name}}</span>
                            </p>
                            <p class="label buy">购买后可解锁</p>
                        </li>
                    </ul>
                </el-collapse-item>
            </el-collapse>
        </div>
        <footers />
    </div>
</template>

<script>
import footers from '../block/front/footers'
import {list} from '../asset/js/courseList.js'
export default {
  name: 'collegeDetails',
  show: true,
  props: {
  },
  components: {
      footers,
  },
    data() {
        return {
            id:'',
            activeNames: [0],
            xyxqimg: [
                "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/xyxq-banner1.png",
                "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/xyxq-banner2.png",
                "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/xyxq-banner3.png",
                "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/xyxq-banner4.png"
            ],
            itemList: []
        };
    },
    created(){
        this.id = this.$route.query.id
        this.itemList = list
    },
    mounted() {
    },
    methods: {
        jumpStudy() {
            this.$message({
                showClose: true,
                message: '暂未开启支付功能',
                type: 'warning'
            });
        }
    }
};
</script>
<style lang="stylus">
</style>
<style lang="stylus" scoped>
@import '../asset/css/collegeDetails'
</style>
