<template>
    <div v-if="showpage" class="exam-detail-page testPaperDetails m-container">
        <div class="exam-detail clearfix">
            <div class="backButton">
                <span @click="goBack">
                     <i class="back el-icon-arrow-left"></i>
                    返回</span>
            </div>
            <div class="exam-left fl">
                <div class="exam-top">
                    <div class="exam-top-left">
                        <div class="exam-top-title">
                            <h1 :title="examDetail.examName">
                              {{ examDetail.examName | ellipsis }}
                            </h1>
                            <span>{{examDetail.type==1?'模拟考试':'正式考核'}}</span>
                        </div>
                        <div class="bottom">
                            <span>总分数:{{ examDetail.totalScore }}</span>
                            <span>及格分:{{ examDetail.passingScore }}</span>
                            <span class="line"></span>
                            <span>答题人:{{ examDetail.userName }}</span>
                            <span>考试时间:{{ examDetail.enterTime }}</span>
                            <span>用时:{{ examDetail.useDuration }}</span>
                        </div>
                    </div>
                    <div class="exam-top-right">
                        <div class="titles"><span>{{examDetail.score}}</span>分</div>
                        <div class="time">
                          <span>答对:{{examDetail.rightCount}}</span>
                          <span>答错:{{examDetail.errorCount}}</span>
                          <span>未答:{{examDetail.emptyCount}}</span>
                        </div>
                        <img class="exam-img" :src="unanswered?img6:examDetail.isPass==0?img1:img2" alt="">
                    </div>
                </div>
                <div class="scroll">
                    <div v-if="examDetail.paperType == 1">
                        <div
                            v-for="(item, index) in questionsList"
                            :key="index"
                            class="list"
                        >
                            <div class="test-questions">
                                <div
                                    v-if="item.questionList.length > 0"
                                    class="question-type"
                                >
                                    <span class="line"></span>
                                    <span class="h1">
                                        {{
                                            item.type == 1
                                            ? "单选题"
                                            : item.type == 2
                                            ? "多选题"
                                            : item.type == 3
                                            ? "判断题"
                                            : "高阶题"
                                        }} </span>
                                    <span class="small">
                                        (共{{ item.questionList.length }}题，共{{ item.totalMark }}分)
                                    </span>
                                </div>
                            </div>
                            <div
                                v-for="(question, index1) in item.questionList"
                                :id="'s' + question.questionId"
                                :key="index1"
                                class="questions"
                            >
                                <ul class="cpStOption">
                                    <div class="subject">
                                        <span class="score">{{ question.score | grade }}分</span>
                                        <h1 class="subject-title">
                                            <span v-if="index == 1">
                                                {{
                                                    questionsList[index - 1].questionList.length +
                                                    index1 +
                                                    1
                                                }}.
                                            </span>
                                            <span v-else-if="index == 2">
                                                {{
                                                    questionsList[index - 2].questionList.length +
                                                    questionsList[index - 1].questionList.length +
                                                    index1 +
                                                    1
                                                }}.
                                            </span>
                                            <span v-else-if="index == 3">
                                                {{
                                                    questionsList[index - 3].questionList.length +
                                                    questionsList[index - 2].questionList.length +
                                                    questionsList[index - 1].questionList.length +
                                                    index1 +
                                                    1
                                                }}.
                                            </span>
                                            <span v-else>{{ index1 + 1 }}.</span>
                                            <p class="subject-content" v-html="question.title"> {{ question.title }}</p>
                                        </h1>
                                    </div>
                                    <div class="topicOptions">
                                        <li
                                            v-for="(option,
                                                    index2) in question.options"
                                            :key="index2"
                                            class="options"
                                        >
                                            <span
                                                class="option-text"
                                            >
                                                {{ option.optionNumber }}
                                            </span>
                                            <p v-html="option.optionContent" class="option-text-content">{{ option.optionContent }}</p>
                                        </li>
                                        <img class="option-img" :src="!question.userAnswer?img5:question.result==1?img3:img4" alt="">
                                        <div class="exam-info">
                                            <div class="exam-answer">
                                                <span class="answer-label">正确答案：<span class="answer-num">{{question.rightAnswer}}</span></span>
                                                <span class="answer-label">你的答案：<span :class="!question.userAnswer?'answer-none':question.result==1?'correct':'error'">{{question.userAnswer||'未作答'}}</span></span>
                                            </div>
                                            <div class="signIcon">
                                                <span class="mark-btn"
                                                :class="{ active: question.mark }"
                                                ></span>
                                                <span class="text">{{question.mark==1?'已标记':'标记'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div :class="['answerList', question.remark?'answer-bgc':'']">
                                        <span class="answer-label">答案解析：</span>
                                        <span v-html="question.remark" class="answer-con">{{question.remark||'无'}}</span>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div v-if="examDetail.paperType == 2" class="practicalOperation">
                        <div
                            v-for="(item, index) in drillQuestions"
                            :id="'s' + item.questionSequence"
                            :key="index"
                            class="questions"
                        >
                            <ul class="cpStOption">
                                <div class="subject">
                                    <span class="score">{{ item.questionScore | grade }}分</span>
                                    <h1 class="subject-title">
                                        <span>{{ index + 1 }}.</span>
                                        <p class="subject-content" v-html="item.source"></p>
                                    </h1>
                                </div>
                                <div class="topicOptions">
                                    <img class="option-img" :src="!item.reply?img5:item.result==1?img3:img4" alt="">
                                    <div class="answerList answer-bgc">
                                        <span class="answer-label">我的作答：</span><span class="myscore">(我得分<span>{{ item.score | grade }}</span>分)</span>
                                        <p v-html="!item.reply ? '未作答' : item.code" class="answer-con"></p>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div
                ref="examRight"
                class="exam-right fr"
            >
                <div class="answer-box">
                    <div class="text">
                        <img src="../asset/images/dtk.png" alt="" />
                        答题卡
                    </div>
                    <div
                        ref="stCount"
                        class="answer-num"
                    >
                        <div v-if="examDetail.paperType == 1">
                            <div
                                v-for="(mark, index) in questionsList"
                                :key="mark.id"
                                class="list"
                            >
                                <h1 v-if="mark.questionList.length > 0">
                                    {{
                                        mark.type == 1
                                        ? "单选题"
                                        : mark.type == 2
                                        ? "多选题"
                                        : mark.type == 3
                                        ? "判断题"
                                        : "高阶题"
                                    }}
                                </h1>
                                <a
                                    v-for="(item, index1) in mark.questionList"
                                    :key="index1"
                                    :class="['pointer',item.userAnswer?(item.result ? 'ydt' : 'bjt'):'wd']"
                                    @click.prevent="anchor('s' + item.questionId)"
                                >
                                    <span v-if="index == 1">
                                        {{
                                            questionsList[index - 1].questionList.length +
                                            index1 +
                                            1
                                        }}
                                    </span>
                                    <span v-else-if="index == 2">
                                        {{
                                            questionsList[index - 2].questionList.length +
                                            questionsList[index - 1].questionList.length +
                                            index1 +
                                            1
                                        }}
                                    </span>
                                    <span v-else-if="index == 3">
                                        {{
                                            questionsList[index - 3].questionList.length +
                                            questionsList[index - 2].questionList.length +
                                            questionsList[index - 1].questionList.length +
                                            index1 +
                                            1
                                        }}
                                    </span>
                                    <span v-else>{{ index1 + 1 }}</span>
                                    <img
                                        v-if="item.mark"
                                        src="../asset/images/Path@2x.png"
                                        class="Path"
                                        alt=""
                                    >
                                </a>
                            </div>
                        </div>
                        <div v-if="examDetail.paperType == 2" class="practicalOperation-list">
                            <h1>实操题</h1>
                            <a
                                v-for="(item, index) in drillQuestions"
                                :key="index"
                                :class="['pointer',item.reply?(item.result ? 'ydt' : 'bjt'):'wd']"
                                @click.prevent="anchor('s' + item.questionSequence)"
                            >
                                <span>{{ index + 1 }}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import components from './../config/vueBlock';
export default {
  name: 'testPaperDetails',
  show: true,
  components,
    data() {
        return {
            showpage: true,
            examDetail: {},
            questionsList: [], // 试题列表
            drillQuestions: [], // 实操题试题列表
            userPaperId: this.$route.query.userPaperId,
            businessId: this.$route.query.businessId,
            examId: this.$route.query.examId,
            img1: require('../asset/images/ysbjg.png'),
            img2: require('../asset/images/ysjg.png'),
            img3: require('../asset/images/hdzq.png'),
            img4: require('../asset/images/hdcw.png'),
            img5: require('../asset/images/wzd.png'),
            img6: require('../asset/images/yswzd.png'),
            winH: window.screen.height,
            unanswered: false,
        };
    },
    filters: {
        ellipsis(msg){
            if(msg && msg.length>34){
            return msg.substr(0, 34)+'...'
            }
            return msg
        },
        grade(msg){
            if (msg%1 === 0) {
                return msg+'.0'
            } else {
                return msg.toFixed(1)
            }
        },
    },
    mounted() {
    },
    created() {
        this.findUserPaperInfo()
    },
    methods: {
        findUserPaperInfo() {
            this.$api.examination
                .findUserPaperInfo(this.userPaperId)
                .then(res => {                    
                    this.showpage = res.data ? true : false;
                    this.examDetail = res.data;
                    this.examDetail.useDuration = this.timeFormatter(this.examDetail.useDuration);
                    if (this.examDetail.emptyCount == this.examDetail.totalCount) {
                        this.unanswered = true;
                    } else {
                        this.unanswered = false;
                    }
                    // 内容类型(;1:试卷;2:实训)
                    if (this.examDetail.paperType == 1) {
                        var subjectList = res.data.examQuestionInfos;
                        this.questionsList = [];
                        subjectList.forEach((item)=>{
                            if (this.questionsList.length == 0) {
                                this.questionsList.push({
                                    totalMark: '',
                                    type: item.type,
                                    questionList: [
                                        item
                                    ]
                                })
                            } else {
                                var have = this.questionsList.some((ele)=>{
                                    if (ele.type == item.type) {
                                        return true;
                                    }
                                })
                                if (have) {
                                    this.questionsList.forEach((ele, index1)=>{
                                        if (ele.type == item.type) {
                                            this.questionsList[index1].questionList.push(item);
                                        }
                                    })
                                } else {
                                    this.questionsList.push({
                                        totalMark: '',
                                        type: item.type,
                                        questionList: [
                                            item
                                        ]
                                    })
                                }
                            }
                        })
                        this.questionsList.forEach((ele)=>{
                            var totalScore = 0;
                            ele.questionList.forEach(item=>{
                                totalScore += item.score;
                            })
                            ele.totalMark = totalScore.toFixed(2);
                        })
                    } else if (this.examDetail.paperType == 2) {
                        this.drillQuestions = this.examDetail.drillQuestions;
                    }
                })
        },
        timeFormatter(time) {
            const hours = Math.floor(time / 60 / 60);
            const minutes = Math.floor((time / 60) % 60);
            const seconds = Math.floor(time % 60);
            var hours1 = hours < 10 ? "0" + hours : hours;
            var minutes1 = minutes < 10 ? "0" + minutes : minutes;
            var seconds1 = seconds < 10 ? "0" + seconds : seconds;
            return hours1 + "时" + minutes1 + "分" + seconds1 + "秒";
        },
        // 返回
        goBack() {
            if (this.$route.name == 'testPaperDetails') {
                this.$router.go(-1)
            }
        },
        // 点击题号锚点
        anchor(anchorName) {
            const anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                anchorElement.scrollIntoView();
            }
        },
    }
};
</script>
<style lang="stylus" scoped>
@import "../asset/testPaperDetails.styl"
</style>
<style lang="stylus">
/deep/.el-scrollbar__wrap  {        
    overflow-x: hidden; 
}
.testPaperDetails{
    .option-text-content{
        span{
            font-size: 100%;
        }
        img{
            height: auto !important;
            max-width: 100%;
            vertical-align middle;
        }
        ol{
            margin-left: 40px;
            list-style-type:decimal
        }
        ul{
            margin-left: 40px;
            list-style-type:disc
        }
    }
    .answer-con{
        span{
            font-size: 100%;
        }
        img{
            height: auto !important;
            max-width: 90%;
            vertical-align middle;
        }
        ol{
            margin-left: 40px;
            list-style-type:decimal
        }
        ul{
            margin-left: 40px;
            list-style-type:disc
        }    
    }
    .subject-content{
        span{
            font-size: 100%;
        }
        img{
            max-width: 100%;
            height: auto !important;
            vertical-align middle;
        }
        ol{
            margin-left: 40px;
            list-style-type:decimal
        }
        ul{
            margin-left: 40px;
            list-style-type:disc
        }   
    }
}

</style>
