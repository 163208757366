<template>
  <div class="header-temp"
    v-if="data.pageHeader"
  >
    <!-- 背景图 -->
    <div
      class="header-top-bg"
      v-if="data.pageHeader.background"
    >
      <img :src="data.pageHeader.background" alt="" class="header-top-background">
      <div class="logo-box" v-show="data.pageHeader.logo">
        <img :src="data.pageHeader.logo" alt="" class="logo-box-img">
      </div>
      <ul class="top-btn" v-if="data.pageHeader.topRightButton.length">
        <li
          class="top-btn-item"
          v-for="(item,index) in data.pageHeader.topRightButton" :key="index">
          <span
            :style="{'color': data.pageHeader.subjectColor}"
            @click="topRightOpen(item)">
            {{item.buttonName}}
          </span>
          <div class="top-code-box" v-if="item.qrCode">
            <img :src="item.qrCode" alt="">
          </div>
        </li>
      </ul>
    </div>
    <div class="header-menu" :style="{'background': data.pageHeader.subjectColor}">
      <div class="menu-center">
        <ul class="menu-list">
          <li class="menu-list-item"
            v-for="(item,index) in data.pageHeader.menuList" :key="index"
            v-show="item.menuDisplay"
          >
            <!-- 一级导航菜单 -->
            <span
              class="list-item-title"
              :class="activeIndex == index+'_'+item.url ? 'active': ''"
              @click="handleActiveNav(item,index)"
              v-show="item.menuDisplay"
            >
              {{item.name}}
            </span>
            <div class="place"></div>
            <!-- 二级导航菜单 -->
            <ul class="submenu-list" v-if="item.children !=null">
              <li
                class="submenu-item"
                :style="hoverColor"
                v-for="(select,subIdx) in item.children"
                :key="subIdx"
                :class="selectIndex == index +'_'+subIdx ? 'select': ''"
                @click="handleActiveSelect(select,subIdx,index)"
                v-show="select.menuDisplay"
              >
                <span
                  v-if="selectIndex == index +'_'+subIdx"
                  :style="{'color': data.pageHeader.subjectColor}"
                >
                  {{select.name}}
                </span>
                <span v-else>{{select.name}}</span>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="menu-right-btn" v-if="!token">
          <li
            class="menu-btn-item"
            v-for="(item,index) in data.pageHeader.navigationBar"
            :key="index*10">
            <span @click="topRightOpen(item)">
              {{item.buttonName}}
            </span>
            <div class="code-box" v-if="item.qrCode">
              <img :src="item.qrCode" alt="">
            </div>
          </li>
        </ul>
        <template v-else>
          <account />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import account from './account.vue'
export default {
  components: {account},
  props: {
    tempData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      activeIndex: -1,
      selectIndex: -1,
      token: '',
      data: this.tempData
    }
  },
  watch: {
    $route() {
      setTimeout(() => { //路由跳转
        this.activeIndex = sessionStorage.getItem('index');
        this.token = localStorage.getItem('token')
      }, 100)
    }
  },
  computed: {
    hoverColor() {
      let subjectColor = this.data.pageHeader.subjectColor;
      return {
        '--hoverColor': subjectColor
      }
    }
  },
  mounted() {
    setTimeout(() => { //刷新
      this.activeIndex = sessionStorage.getItem('index')
      this.token = localStorage.getItem('token')
    }, 100)
    if (sessionStorage.getItem('selectIndex')== null) return;
    this.selectIndex = sessionStorage.getItem('selectIndex');
  },
  methods:{
    // 导航栏选中状态
    handleActiveNav(item,index) {
      this.activeIndex = index+'_'+item.url;
      sessionStorage.setItem('index',index+'_'+item.url);
      if (item.isLogin == 1 && !this.token) {
        this.$router.push({
          name: 'login',
        })
        return
      }
      if (!item.url) return;
      if (item.urlType == 2) {
        item.openMode == 1 ? window.location.href = item.url : window.open(item.url,'_blank');
      } else {
        if (item.openMode == 1) {
          this.$router.push({
            path: item.url
          })
        } else {
          const { href } = this.$router.resolve({
            path: item.url
          });
          window.open(href, '_brank');
        }
      }
    },
    // 导航下拉选中状态
    handleActiveSelect(item, subIndex,index) {
      this.selectIndex = index +'_'+subIndex;
      sessionStorage.setItem('selectIndex',index)
      if (item.isLogin == 1 && !this.token) {
        this.$router.push({
          name: 'login',
        })
        return
      }
      if (!item.url) return;
      if (item.urlType == 2) {
        item.openMode == 1 ? window.location.href = item.url : window.open(item.url,'_blank');
      } else {
        if (item.openMode == 1) {
          this.$router.push({
            path: item.url
          })
        } else {
          const { href } = this.$router.resolve({
              path: item.url
          });
          window.open(href, '_brank');
        }
      }
    },
    // 点击右上按钮
    topRightOpen(data) {
      if (!data.jumpLink) return;
      if (data.jumpLink.includes('http')) {
        data.openType == 1 ? window.location.href = data.jumpLink : window.open(data.jumpLink,'_blank');
      } else {
        if (data.openType == 1) {
          this.$router.push({
            path: data.jumpLink
          })
        } else {
          const { href } = this.$router.resolve({
            path: data.jumpLink
          });
          window.open(href, '_brank');
        }
      }
    }
  },
  destroyed() {
    sessionStorage.removeItem('index');
    sessionStorage.removeItem('selectIndex');
  }
}
</script>
<style lang="stylus" scoped>
  .el-popper {
    margin-top: 28px;
  }
  /deep/.top-account {
    margin-top: -8px;
  }
  .header-temp {
    .header-top-bg {
      //min-height: 96px;
      width: 100%;
      background-color: #fff;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      .header-top-background {
        width:100%;
        max-height:350px;
        display:block;
      }
      .logo-box {
        width: 1200px;
        box-sizing: border-box;
        position: absolute;
        bottom: 50%;
        left: 50%;
        transform: translate(-50%,50%);
      }
      .top-btn {
        text-align: right;
        position: absolute;
        bottom: 10px;
        right: 24px;
        .top-btn-item {
          margin-left: 25px;
          cursor: pointer;
          position: relative;
          display: inline-block;
          .top-code-box {
            position: absolute;
            top: -100px;
            right: 0px;
            height: 88px;
            width: 88px;
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            border: 1px solid #979797;
            background: #fff;
            display: none;
            img {
              width: 88px;
              height: 88px;
            }
          }
        }
        .top-btn-item:hover {
          .top-code-box {
            display: inline-block;
          }
        }
        span {
          font-size: 14px;
        }
      }
    }
    .header-menu {
      height: 64px;
      width: 100%;
      .menu-center {
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .menu-list {
          display: flex;
          position: relative;
          cursor: pointer;
          .menu-list-item {
            height: 74px;
            .list-item-title {
              padding: 0 24px;
              height: 64px;
              line-height: 64px;
              box-sizing: border-box;
              font-size: 16px;
              display: inline-block;
              color: #fff;
            }
            .list-item-title.active {
              background:rgba(255,255,255,0.5);
            }
          }
          .place {
            position: absolute;
            z-index: 99;
            height: 8px;
            width: 132px;
          }
          .place:hover+.submenu-list{
            display: flex;
          }
          .menu-list-item:hover {
            .submenu-list {
              display: flex;
              max-height: 140px;
              overflow-y: auto;
            }
            .submenu-list::-webkit-scrollbar {
              display: none;
            }
          }
        }
        .submenu-list {
          width: 128px;
          background: #FFFFFF;
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
          border-radius: 3px;
          border: 1px solid #E5E7EE;
          flex-direction: column;
          position: absolute;
          display: none;
          padding: 6px 0;
          box-sizing: border-box;
          z-index: 99;
          margin-top: 8px;
          .submenu-item {
            width: 100%;
            padding: 6px 0 6px 16px;
            color: #606266;
            font-size: 11px;
            box-sizing: border-box;
            line-height: 16px;
          }
          .submenu-item.select {
            background: #F5F7FA;
          }
          .submenu-item:hover {
            color: var(--hoverColor);
          }
        }
        .submenu-list:hover {
          display: flex;
        }
      }
      .menu-right-btn {
        padding: 19px 0;
        box-sizing: border-box;
        display: flex;
        cursor: pointer;
        .menu-btn-item {
          position: relative;
          span {
            font-size: 14px;
            color:#fff;
            text-align: center;
            display: inline-block;
            padding: 3px 22px;
            border-radius: 15px;
            border: 1px solid rgba(255, 255, 255, 0.6);
            margin-left: 13px;
            box-sizing: border-box;
          }
          .code-box {
            position: absolute;
            top: 55px;
            right: 0px;
            width: 88px;
            height: 88px;
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            border: 1px solid #979797;
            display: none;
            background: #FFFFFF;
            z-index: 99;
            img {
              width: 88px;
              height: 88px;
            }
          }
        }
        .menu-btn-item:hover {
          .code-box {
            display: inline-block;
          }
        }
      }
    }
  }
</style>
