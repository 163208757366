<template>
<div>
  <div class="zhongzhiNav">
    <div class="menuContent">
      <img v-if="!token" class="logo fl" :src="logoImg" alt="" />
      <div v-else class="collegelogo fl">
        <!-- <img :src="logoImg" alt=""> -->
        <span>{{logoName}}-企业学院</span>
      </div>
      <div class="zhongzhi-nav-bar fl">
        <p class="item-nav fl" 
          v-for="item in navList" 
          :key="item.id"
          :class="{'active': setActive(item)}"
          @click="clickNav(item)"
        >
          {{item.title}}
          <span class="line"></span>
        </p>
      </div>
      <div class="fr">
        <el-dropdown class="move" trigger="click">
          <span class="el-dropdown-linkapp">手机端
            <!-- <span class="dropdown-title link-app">手机端</span> -->
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item class="nav-zhongzhi-menu-item">
              <div class="download-wrap">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/Bitmap.png?x-oss-process=image/resize,m_fill,h_110,w_110,limit_0" alt="" class="img"/>
                <p>移动学习 随时随地</p>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div v-if="!token" class="land un-login" @click="jumpLogin('zhongzhi_homepage')">
          学员登录
        </div>
        <template v-else>
          <account :replacePage="'zhongzhi_homepage'" @getBranchInfo="getBranchInfo" class="fr" style="display: inline-block;"/>
        </template>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import account from './account.vue'
export default {
  name: "zhongzhiNav",
  components: {
    account
  },
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      logoImg: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/logo-now@3x.png',
      activeNav: this.$route.path,
      // examId: 0,
      // trainId: 0,
      token: '',
      mode: '0', //  0:其他 1' 学习中心 '2': 考试中心
      navList: [
        {
          path: '/zhongzhi_homepage',
          needLogin: false,
          title: '首页',
          activePaths: ['/zhongzhi_homepage', '/publicClass', '/classDetail', '/readingBook'],
          mode: '0'
        },
        {
          path: '/projectDetails',
          needLogin: false,
          title: '学院专区',
          activePaths: ['/projectDetails', '/collegeDetails', '/orderPage'],
          mode: '0',
        },
        {
          path: '/course',
          needLogin: true,
          title: '学习中心',
          activePaths: ['/course', '/course/catalog', '/course/resdExam', '/course/testPaper', '/course/handPaper', '/course/testPaperDetails'],
          mode: '1'
        },
        {
          path: '/course',
          needLogin: true,
          title: '考试中心',
          activePaths: ['/course', '/course/catalog', '/course/resdExam', '/course/testPaper', '/course/handPaper', '/course/testPaperDetails'],
          mode: '2'
        }
      ],
      logoName: '',
    }
  },
  async created () {},
  watch: {
    $route() {
      setTimeout(() => { //路由跳转
        this.activeNav = this.$route.path
        this.token = localStorage.getItem('token')
      }, 100)
    }
  },
  async mounted () {
    setTimeout(() => { //刷新
      this.activeNav = this.$route.path
      this.token = localStorage.getItem('token')
    }, 100)
    // this.$api.home.getGongxinExam().then((res) =>  {
    //   this.examId = res.data;
    // })
    // this.$api.home.findSaleGoods().then(res=>{
    //   this.trainId = res.data.referId;
    // })
    this.mode = this.$route.query.dymode || '0';
  },
  methods: {
    getBranchInfo(val) {
      this.getLogoName(val)
    },
    getLogoName (value) {
      if (value.length>8) {
        this.logoName = value.substring(0,8)+'...'
      } else {
        this.logoName = value
      }
    },
    setActive(item) {
      // 学习中心，模拟考试的内部跳转需要处理。
      let flag = false;
      this.mode = this.$route.query.dymode || '0';
      if (this.mode === item.mode) {
        flag = !!item.activePaths.includes(this.activeNav);
      }
      return flag;
    },
    clickNav(item) {
      if (this.$route.path === '/course/testPaper') {
        return;
      }
      let name = item.path;
      if (item.title === '考试中心') {
        name = `${name}?type=exam&dymode=2`
        this.mode = '2';
      } else if (item.title === '学习中心') {
        name = `${name}?type=study&dymode=1`
        this.mode = '1';
      } else if (item.path) {
        this.mode = '0';
      }
      if (item.needLogin && !localStorage.getItem('token')) {
        this.jumpLogin(name);
      } else if (name) {
        this.$router.push(name)
      }
    },
    jumpLogin(name) {
      this.$router.push({
            name: 'login',
            query: {
                name
            }
        })
    },
  }
}
</script>
<style lang="stylus" scoped>
@import './../../../../assets/css/zhongzhiNav'
</style>
