<template>
    <div class="homePage">
        <homeBanner/>
            <div class="trian-block">
                <div class="commom-title">
                    <div class="title-text">
                        培训任务
                    </div>
                    <div class="title-desc">
                        随时随地线上学习，满足教育教学需求
                    </div>
                </div>
                <div class="train-content" v-if="!isLogin">
                    <div class="nologin-content">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/pxrwnologin@2x.png"/>
                        <div class="text-bottom">
                            <span>养老护理员技能培训</span>
                            <div @click="$router.push('/register')">立刻报名</div>
                        </div>
                    </div>
                    
                </div>
                <div class="train-content" v-else>
                    <div class="login-content">
                        <div class="text-block" v-for="item in taskList" :key="item.id">
                            <img :src="item.picUrl"/>
                            <div class="text-box">
                                <h3><span>最新任务</span><span>{{item.name}}</span></h3>
                                <div class="text-content">
                                    <div class="time-bottom">
                                        <!-- <p v-if="trainObj.startTime">报名开始时间：{{trainObj.startTime}}</p>
                                        <p v-if="trainObj.validityType==1">报名结束时间：永久有效</p>
                                        <p v-else-if="trainObj.validityType==2 ||trainObj.validityType==3">报名结束时间：{{trainObj.endTime}}</p>
                                        <p v-else-if="trainObj.validityType==4">报名后{{trainObj.days}}天内有效</p> -->
                                        <p v-if="item.startTime">报名开始时间：{{item.startTime | dataFormat}}</p>
                                        <p v-if="item.endTime">报名结束时间：{{item.endTime | dataFormat}}</p>
                                        <p v-if="!item.startTime&&!item.endTime">长期有效</p>
                                    </div>
                                    <div class="sign-up" @click="toGood(item.id)">立即报名</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="btn-bottom">
                    <div v-if="!isLogin" @click="$router.push('/register')">立即报名 ></div>
                    <div v-else @click="toGoods">查看更多任务 ></div>
                </div> -->
            </div>
        
            <div class="try-block">
                <div class="commom-title">
                    <div class="title-text">
                        试听课
                    </div>
                    <div class="title-desc">
                        各级别试听课程展示
                    </div>
                </div>
                <div class="try-content">
                    <el-tabs @tab-click="handleClick">
                        <el-tab-pane v-for="item in tryList" :key="item.tab1">
                            <div slot="label" class="tabs-label">
                                <p>{{item.tab1}}</p>
                                <p>{{item.title1}}</p>
                            </div>
                             <el-carousel :initial-index="initialIndex" arrow="always" :autoplay="false" :loop="false" @change="handleClick">
                                <el-carousel-item>
                                    <div class="slide">
                                        <div class="intro-left">
                                                    <h2>{{item.tab1}}</h2>
                                                    <div>{{item.title1}}</div>
                                                    <h3>{{item.tab2}}</h3>
                                                    <p>{{item.text1}}</p>
                                                    <span @click="palyIt(item.index1)">播放课程</span>
                                        </div>
                                        <div class="video-right">
                                            <video :ref="'vueMiniPlayer'+item.index1" :src="item.url1" controls="controls" :poster="item.poster1"></video>
                                        </div>
                                    </div>
                                </el-carousel-item>
                                <el-carousel-item>
                                    <div class="slide">
                                            <div class="intro-left">
                                                <h2>{{item.tab1}}</h2>
                                                <div>{{item.title2}}</div>
                                                <h3>{{item.tab2}}</h3>
                                                <p>{{item.text2}}</p>
                                                <span @click="palyIt(item.index2)">播放课程</span>
                                            </div>
                                            <div class="video-right">
                                                <video :ref="'vueMiniPlayer'+item.index2" :src="item.url2" controls="controls" :poster="item.poster2"></video>
                                            </div>
                                        </div>
                                </el-carousel-item>
                            </el-carousel>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
            <div class="best-block">
                <div class="commom-title">
                    <div class="title-text">
                        精品课程
                    </div>
                    <div class="title-desc">
                        第一时间学习精品课程
                    </div>
                </div>
                <div class="best-content">
                    <div class="best-box">
                        <div class="one-left">
                            <div class="one-block" @click="toGood(oneBest.id)">
                                <img :src="oneBest.picUrl" /> 
                                <div>
                                    <p><span>{{oneBest.name}}</span><span></span></p>
                                    <p>{{oneBest.count}}人已报名</p>
                                </div>
                            </div>
                        </div>
                        <div class="four-right">
                                <div class="four-block" v-for="item in bestList" :key="item.id"  @click="toGood(item.id)">
                                    <!-- <span>最热</span> -->
                                    <img :src="item.picUrl" /> 
                                    <div>
                                        <p><span>{{item.name}}</span><span>{{item.count}}人已报名</span></p>
                                    </div>
                                </div>
                        </div>
                    </div>
                <div class="moreBest" @click="toGoods()">查看更多课程 <i class="el-icon-arrow-right"></i></div>
                </div>
            </div>
        <div class="goodness-block">
                <div class="commom-title">
                    <div class="title-text">
                        课程优势
                    </div>
                    <div class="title-desc">
                        理论结合实践，专家团队鼎力打造
                    </div>
                </div>
                <div class="goodness-content">
                    <div class="goodness-item" v-for="item in goodnessList" :key="item.tip">
                        <img :src="item.imgUrl"/>
                        <div class="goodness-tip">{{item.tip}}</div>
                        <div class="goodness-text">{{item.text}}</div>
                    </div>
                </div>
            </div>
            <div class="teacher-block">
                <div class="commom-title">
                    <div class="title-text">
                        师资介绍
                    </div>
                    <div class="title-desc">
                        勤奋、务实、开拓、创新的高素质应用型师资队伍
                    </div>
                </div>
                <div class="teacher-content">
                    <!-- <div class="teacher-item" v-for="item in teacherList" :key="item.name">
                        <img :src="item.imgUrl"/>
                        <div class="te-card">
                            <p class="te-name">{{item.name}}</p>
                            <p class="te-position">{{item.position}}</p>
                            <p class="te-intro">{{item.intro}}</p>
                        </div>
                    </div> -->
                    <div class="swiper-wrap">
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide"  v-for="(item) in teacherList" :key="item.name">
                                <div class="teacher-item" >
                                    <img :src="item.imgUrl"/>
                                    <div class="te-card">
                                        <p class="te-name">{{item.name}}</p>
                                        <p class="te-position">{{item.position}}</p>
                                        <!-- <p class="te-intro">{{item.intro}}</p> -->
                                    </div>
                                    <div class="te-mb">
                                        <div class="mb-block">
                                            <h3>{{item.name}}</h3>
                                            <p>{{item.position}}</p>
                                            <p>{{item.intro}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div> -->
                        <!-- <div class="swiper-pagination"></div> -->
                    </div>
                    <!-- Add Arrows -->
                   <div class="button-box _clear">
                        <div class="swiper-button-next"><i class="el-icon-arrow-right"></i></div>
                        <div class="swiper-button-prev"><i class="el-icon-arrow-left"></i></div>
                    </div>
                    
                    
                </div>
                </div>
            </div>
            <div class="system-block">
                <div class="commom-title">
                    <div class="title-text">
                        课程体系
                    </div>
                    <div class="title-desc">
                        专业课程体系，为你提供你想学的数千门技能课程
                    </div>
                </div>
                <div class="system-content active">
                    <div class="system-item" v-for="(item) in systemList" :key="item.title">
                        <div class="system-detail">
                            <img :src="item.imgUrl"/>
                            <div class="system-text">
                                <i>{{item.tip}}</i>
                                <h3>{{item.title}}</h3>
                                <p>{{item.text}}</p>
                            </div>
                        </div>
                        <div class="system-mb">
                            <h3 class="title">{{item.title}}</h3>
                            <p>{{item.text}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="study-block">
                <div class="commom-title">
                    <div class="title-text">
                        学习流程
                    </div>
                    <div class="title-desc">
                        为你提供正确的学习流程指引 
                    </div>
                </div>
                <div class="study-content">
                    <div class="study-top">
                        <div class="study-step">
                            <p>01</p>
                            <p>02</p>
                            <p>03</p>
                            <p>04</p>
                        </div>
                        <div class="study-line"></div>
                    </div>
                    <div class="study-bottom">
                        <div class="study-item" v-for="item in studyList" :key="item.tip">
                            <div class="img-left">
                                <img :src="item.imgUrl"/>
                            </div>
                            <div class="text-right">
                                <h3>{{item.tip}}</h3>
                                <p>{{item.text}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <footers/>
        <!-- <div class="fixe-box">
            <div class="fix-top">
                <p style="margin-bottom:6px;">扫码手机看</p>
                <p id="qrcode2" style="width:78px;height:78px;">

                </p>
            </div>
            <div class="fix-bottom" @click="backTop">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/Shape@3x.png"/>
                <span>回到顶部</span>
            </div>
        </div> -->
    </div>
</template>

<script>
import homeBanner from '../block/front/homeBanner.vue'
import footers from '../block/front/footers.vue'
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
// import Video from '../../coursegoods/page/video.vue';
// import Video from '../../coursegoods/page/video.vue';
export default {
  name: 'minzheng_homepage',
  show: true,
  components: {
      homeBanner,
      footers,
    // Video,
    //   Video
  },
    data() {
        return {
            isSgin:true,
            tabPosition: 'top',
            isLogin:false,
            theme:'',
            initialIndex:0,
            // trainObj:{
            //     picUrl:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/train@2x.png',
            //     startTime:'2020/10/20 16:53',
            //     endTime:'2020/10/20 16:53',
            //     name:'生活护理及病情观察',
            //     id:9,
            //     referId:1,
            //     validityType:2,
            //     days:0
            // },
            taskList:[
                {
                    picUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/goodness1@2x.png',
                    name:'重实操的学习模式',
                    count:199,
                    endTime: "2021-07-12",
                    id: 2,
                    startTime: "2021-07-12"
                },{
                     picUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/goodness1@2x.png',
                    name:'重实操的学习模式',
                    count:199,
                    endTime: "2021-07-12",
                    id: 3,
                    startTime: "2021-07-12"
                },
            ],
            oneBest:{
                    picUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/goodness1@2x.png',
                    name:'重实操的学习模式',
                    count:199,
                    endTime: "2021-07-12",
                    id: 1,
                    startTime: "2021-07-12"
            },
            bestList:[
                {
                    picUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/goodness1@2x.png',
                    name:'重实操的学习模式',
                    count:199,
                    endTime: "2021-07-12",
                    id: 2,
                    startTime: "2021-07-12"
                },{
                     picUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/goodness1@2x.png',
                    name:'重实操的学习模式',
                    count:199,
                    endTime: "2021-07-12",
                    id: 3,
                    startTime: "2021-07-12"
                },{
                    picUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/goodness1@2x.png',
                    name:'重实操的学习模式',
                    count:199,
                    endTime: "2021-07-12",
                    id: 4,
                    startTime: "2021-07-12"
                },{
                     picUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/goodness1@2x.png',
                    name:'重实操的学习模式',
                    count:199,
                    endTime: "2021-07-12",
                    id: 5,
                    startTime: "2021-07-12"
                },
            ],
            swiper:null,
            goodnessList: [
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/good11@2x.png',
                    tip:'国家体系—高起高标',
                    text:'以2019新国标构建培训体系，完整、严谨、通俗易懂、要点清晰的课程风格',
                },{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/good11@2x(1).png',
                    tip:'师资雄厚—名师大咖',
                    text:'民政行业、知名院校、国内外高端机构权威专家，打造全方位养老人才培训课程',
                },{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/good11@2x(2).png',
                    tip:'混合学习—闭环生态',
                    text:'线上平台学习、下线练习、集中实训完成闭环，数字化实训手册随身辅导',
                },{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/good11@2x(3).png',
                    tip:'理实一体—重点突出',
                    text:'课程实景拍摄、步骤详细分解、关键要点标注，沉浸式的学习体验，便于学员掌握',
                }
            ],
            teacherList: [
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/te@2x.png',
                    name:'谭美青',
                    position:'副主任医师 · 养老护理技师',
                    intro:'人社部职业能力建设司养老护理员国家基本职业培训专家；民政部培训中心特聘养老护理专家'
                },{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/te@2x(1).png',
                    name:'郑春贤',
                    position:'中国国家培训网特聘专家',
                    intro:'中国管理科学研究院商学院特聘教授；国家发改委国家信息中心特聘教授；中国案例法学研究会理事'
                },{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/te@2x(2).png',
                    name:'凌晓晨',
                    position:'高端星级养老机构护理部主任',
                    intro:'老年人能力评估师；曾任三甲医院老年人保健主管护师；老年护理行业18年从业经验'
                },{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/te@2x(3).png',
                    name:'王文焕',
                    position:'养老机构及培训机构特聘专家',
                    intro:'养老护理行业优秀青年骨干教师；老年医学会护理学分会常务委员'
                },
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/te@2x(4).png',
                    name:'李宁',
                    position:'星级养老院院长',
                    intro:'养老护理员二级技师；曾任民政部鉴定中心初、中、高级养老护理员；鉴定考评专家'
                },{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/te@2x(5).png',
                    name:'宋珺璐',
                    position:'老年服务与管理专业讲师',
                    intro:'民政部培训中心养老护理员职业技能鉴定培训教师；民政行业养老服务技能大赛裁判及国赛备赛培训教师'
                },{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/te@2x(6).png',
                    name:'金华',
                    position:'高端星级养老机构副院长',
                    intro:'标准化协会专家委员；中国老年学和老年医学学会标准化委员会理事；某养老行业协会星级评定专家成员'
                },{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/te@2x(7).png',
                    name:'刘芳宏',
                    position:'老年服务与管理专业讲师',
                    intro:'民政部培训中心养老护理员；职业技能鉴定培训教师；民政行业养老护理员职业技能大赛裁判'
                }
            ],
            systemList: [
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/tx@2x.png',
                    title:'初级养老护理员',
                    tip:'初级',
                    text:'根据《养老护理员国家职业技能标准（2019）》相关要求打造线上培训课程，内容涵盖生活照护、基础照护及康复服务。课程通俗易懂、实用性强，操作细节详实，学员易懂易学。'
                },{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/tx@2x(3).png',
                    title:'中级养老护理员',
                    tip:'中级',
                    text:'依据新国标注重心理支持等深度护理内容，满足老年人健康照护需求。以线下实训指导手册为依托进行线下实操练习，对难度大、易混淆的内容进行线下集中辅导。'
                },{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/tx@2x(4).png',
                    title:'高级养老护理员',
                    tip:'高级',
                    text:'深度剖析高难度护理知识，增加培训指导内容，更大程度地提升养老护理员职业技能，提高养老服务职业化、专业化及规范化水平。'
                },{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/tx@2x(5).png',
                    title:'技师养老护理员',
                    tip:'技师',
                    text:'突出照护评估、质量管理、培训能力等内容，使养老护理员在掌握照护方法的同时，也能对老年人健康状态、照护等级等内容做出准确评估，对照护结果进行质量监督。'
                },{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/tx@2x(6).png',
                    title:'高级技师养老护理员',
                    tip:'高级技师',
                    text:'重点讲解照护评估、质量管理及培训指导等内容，实现为老年人提供一体化的高质量照护服务。'
                },
            ],
            tryList:[
                {
                    url1: 'https://platform-vod.wdcloudnet.com/customerTrans/928e23e5005956f70e333d6b586f6f88/2bff074a-17a7a7ec990-0005-cd1a-d1c-c3eb7.mp4',
                    url2: 'https://platform-vod.wdcloudnet.com/customerTrans/928e23e5005956f70e333d6b586f6f88/2bff074a-17a7a7ec990-0005-cd1a-d1c-c3eb7.mp4',
                    poster1:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/try1.jpg',
                    poster2:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/try1.jpg',
                    tab1:'初级',
                    tab2:'课程简介',
                    index1:0,
                    index2:1,
                    title1:'协助老年人完成体位转换',
                    title2:'协助老年人床上被动翻身',
                    text1:'定时协助老年人转换体位，可以促进卧床老年人的血液循环，预防压疮、坠积性肺炎、尿路感染、肌肉萎缩、关节变形、肢体挛缩等并发症的发生。通过本节课的学习，您将会了解老年人体位转换的目的、原则及方式。',
                    text2:'协助老年人进行体位转换是养老护理员的重要职责，在床上进行被动翻身是体位转换的一种重要方式。本节课程将为大家讲解如何协助老年人进行正确的床上被动翻身。'
                },{
                    url1: 'https://platform-vod.wdcloudnet.com/customerTrans/928e23e5005956f70e333d6b586f6f88/4429356f-17a7a83b2dd-0005-cd1a-d1c-c3eb7.mp4',
                    url2: 'https://platform-vod.wdcloudnet.com/customerTrans/928e23e5005956f70e333d6b586f6f88/53f1747c-17a50c06061-0005-cd1a-d1c-c3eb7.mp4',
                    poster1:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/try2.jpg',
                    poster2:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/try2.jpg',
                    tab1:'中级',
                    tab2:'课程简介',
                    index1:2,
                    index2:3,
                    title1:'老年人口腔清洁的方法及注意事项',
                    title2:'为老年人进行口腔清洁',
                    text1:'口腔清洁是阻止病原体侵入人体的主要途径之一，老年人由于身体抵抗力较弱，更要及时进行口腔清洁。本节课将为您讲解为老年人进行口腔清洁的适用范围、方法以及老年人常见的口腔健康问题。',
                    text2:'协助老年人进行口腔清洁是养老护理员的重要职责，通过口腔擦拭等方式帮助老年人进行口腔卫生清洁，可以维护口腔健康。本节课将为您讲解用棉棒为老年人进行口腔清洁的操作步骤及流程。'
                },{
                    url1: 'https://platform-vod.wdcloudnet.com/customerTrans/928e23e5005956f70e333d6b586f6f88/3647a850-17a7a80d7c4-0005-cd1a-d1c-c3eb7.mp4',
                    url2: 'https://platform-vod.wdcloudnet.com/customerTrans/928e23e5005956f70e333d6b586f6f88/1d608563-17a50bd94ec-0005-cd1a-d1c-c3eb7.mp4',
                    poster1:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/try3.jpg',
                    poster2:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/try3.jpg',
                    tab1:'高级',
                    tab2:'课程简介',
                    index1:4,
                    index2:5,
                    title1:'失智老年人特殊异常行为表现及应对措施',
                    title2:'失智老年人常见环境风险及处理',
                    text1:'失智的异常行为除了一些功能障碍外，约97%的老年人会出现精神行为症，失智老年人的特殊异常行为主要表现为思维错乱、幻觉、谵妄、躁狂等。本节课来带您了解失智老年人的特殊异常行为表现及应对措施。',
                    text2:'失智老年人由于对环境的感知变弱，且不懂得如何寻求帮助，身边可能会出现各种各样的风险，作为一名养老护理员应该学会辨识失智老年人的常见环境风险以及遇到这些风险应该采取的应对措施。'
                },{
                    url1: 'https://platform-vod.wdcloudnet.com/customerTrans/928e23e5005956f70e333d6b586f6f88/5c3c6f05-17a50bf1fd3-0005-cd1a-d1c-c3eb7.mp4',
                    url2: 'https://platform-vod.wdcloudnet.com/customerTrans/928e23e5005956f70e333d6b586f6f88/2a34cb01-17a7a828eb8-0005-cd1a-d1c-c3eb7.mp4',
                    poster1:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/try4.jpg',
                    poster2:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/try4.jpg',
                    tab1:'技师',
                    tab2:'课程简介',
                    index1:6,
                    index2:7,
                    title1:'言语功能障碍特点和基本训练方法',
                    title2:'老年人照护计划的制定、调整方法',
                    text1:'言语障碍是指构成言语的各个环节受到损伤或发生功能障碍。言语功能障碍训练的原则有哪些？如何协助老年人进行言语训练？本节课带您走进言语功能障碍特点和基本训练方法的相关内容。',
                    text2:'照护计划是为老年人服务的指南，是养老机构向老年人家属的承诺。一份合理的照护计划是完成老年人照护服务的基础，作为一名养老护理人员，应该掌握老年人照护计划的制定的原则、步骤以及如何进行调整。'
                },{
                    url1: 'https://platform-vod.wdcloudnet.com/customerTrans/928e23e5005956f70e333d6b586f6f88/4b22f4d5-17a7a7f47c1-0005-cd1a-d1c-c3eb7.mp4',
                    url2: 'https://platform-vod.wdcloudnet.com/customerTrans/928e23e5005956f70e333d6b586f6f88/5c3c5768-17a50bcef4f-0005-cd1a-d1c-c3eb7.mp4',
                    poster1:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/try5.jpg',
                    poster2:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/try5.jpg',
                    tab1:'高级技师',
                    tab2:'课程简介',
                    index1:8,
                    index2:9,
                    title1:'老年人专项功能评估内容',
                    title2:'质量评价的基本方法',
                    text1:'对老年人进行评估，是为其服务的第一步。通过评估系统收集老年人相关信息，可为确定照护等级提供依据。老年人专项功能评估的内容有哪些？常用的评估量表都有哪几种？本节课程我们一起来学习老年人专项功能评估。',
                    text2:'随着我国老龄化速度加快，养老机构需求急速增加。在数量增长的同时，如何提高养老机构服务水平，提升入住老年人的满意度，需要建立一套养老机构服务与管理质量评价体系，本节课来了解养老机构服务与管理质量评价体系的相关内容。'
                },
            ],
            studyList:[{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/xxlc@2x.png',
                    tip:'登录系统',
                    text:'登录或注册自己的学习账号并进入系统',
                },{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/xxlc@2x(1).png',
                    tip:'课程学习',
                    text:'选择培训任务及加入选修课程进行学习',
                },{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/xxlc@2x(2).png',
                    tip:'参加考试',
                    text:'试题中心中可选择试卷进行考试',
                },{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/xxlc@2x(3).png',
                    tip:'成绩查询',
                    text:'成绩查询后可对知识点进行查漏补缺',
                }],
        };
    },
    filters: {
      dataFormat(msg) {
        // if (msg) {
        //     const m = (msg||'').split(' ')[0].split('-');
        //     const s = m[0]+'年'+m[1]+'月'+m[2]+'日';
        //     return s;
        // } 
        if (msg) {
            return msg.split(' ')[0].split('-').join('/')+' '+msg.split(' ')[1].split(':').slice(0,2).join(':')
        } else {
            return ""
        }
      }
    },
    mounted() {
        // this.initSwiper();
        setTimeout(()=>{
            this.initSwiper();
        },500)
        if(localStorage.getItem('token')){
            this.getGoodsInBranch(1);
            this.getGoodsInBranch(2);
        }else{
            this.getGoodsInSaas()
        }
    },
    created() {
        this.theme=localStorage.getItem('theme');
        this.isLogin = localStorage.getItem('token')?true:false;
    },
    beforeDestroy() {
        // window.removeEventListener("scroll",this.handleFun)
    },
    methods: {
        //立即报名跳转
        toGood(id){
            // if(this.isSgin){
                //未报名
                
            if(localStorage.getItem('token')){
                this.$router.push({
                    path:'/courseGoodsDetail',
                    query:{
                        goodsId:id
                    }
                })
            }else{
                this.$router.push({
                    path:'/register',
                })
            }
            // }else{
            //     //已报名
            //     if(this.trainObj.goodsType==1){
            //         this.$router.push({
            //         path:'/course/catalog',
            //         query:{
            //             taskId:this.trainObj.referId,//训练营id
            //             taskType:this.trainObj.goodsType,
            //             goodsId:this.trainObj.id,
            //             backPath:this.$route.path
            //         }
            //         })
            //     }else if(this.trainObj.goodsType==2){
            //         // this.$router.push(`/course/catalog?id=${this.detailObj.referId}&dymode=1`)
            //         this.$router.push({
            //         path:'/learning/taskDetail',
            //         query:{
            //             id:this.trainObj.referId,//培训任务id
            //             goodsId:this.trainObj.id,
            //             backPath:this.$route.path,
            //             type:this.trainObj.goodsType,
            //         }
            //         })
            //     }else if(this.trainObj.goodsType==3){
            //         this.$router.push({
            //         path:'/assess',
            //         query:{
            //             assessId:this.trainObj.referId,//测评id
            //             refId:this.trainObj.id,
            //             refType:2,
            //             goodsId:this.trainObj.id,
            //             pathName:this.$route.path
            //         }
            //         })
            //     }
            // }
        },
        //查看更多培训任务
        toGoods(){
            if(localStorage.getItem('token')){
                this.$router.push({
                    path:'/courseGoods',
                })
            }else{
                this.$router.push({
                    path:'/register',
                })
            }
        },
        //试听课切换暂停播放
        handleClick(){
            this.initialIndex = 0;
            this.$refs.vueMiniPlayer0[0].pause();
            this.$refs.vueMiniPlayer1[0].pause();
            this.$refs.vueMiniPlayer2[0].pause();
            this.$refs.vueMiniPlayer3[0].pause();
            this.$refs.vueMiniPlayer4[0].pause();
            this.$refs.vueMiniPlayer5[0].pause();
            this.$refs.vueMiniPlayer6[0].pause();
            this.$refs.vueMiniPlayer7[0].pause();
            this.$refs.vueMiniPlayer8[0].pause();
            this.$refs.vueMiniPlayer9[0].pause();
        },
        //播放视频
        palyIt(index){
            if(index==0){
                this.$refs.vueMiniPlayer0[0].play();
            }else if(index==1){
                this.$refs.vueMiniPlayer1[0].play();
            }else if(index==2){
                this.$refs.vueMiniPlayer2[0].play();
            }else if(index==3){
                this.$refs.vueMiniPlayer3[0].play();
            }else if(index==4){
                this.$refs.vueMiniPlayer4[0].play();
            }else if(index==5){
                this.$refs.vueMiniPlayer5[0].play();
            }else if(index==6){
                this.$refs.vueMiniPlayer6[0].play();
            }else if(index==7){
                this.$refs.vueMiniPlayer7[0].play();
            }else if(index==8){
                this.$refs.vueMiniPlayer8[0].play();
            }else if(index==9){
                this.$refs.vueMiniPlayer9[0].play();
            }
        },
        //回到顶部
        // backTop(){
        //   var timer = setInterval(function(){
        //     let osTop = document.documentElement.scrollTop || document.body.scrollTop;
        //     let ispeed = Math.floor(-osTop / 5); 
        //     document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
        //     this.isTop = true;
        //     if(osTop === 0){
        //       clearInterval(timer);
        //     }
        //   },30)
        // },
        //首页未登录获取精品课程
        getGoodsInSaas() {
            this.$api.home.getGoodsInSaas({
                type:2,
                size:5
            }).then(res=>{
                console.log(res);
                if(res.data){
                    this.bestList = res.data;
                    this.oneBest = this.bestList[0];
                    this.bestList.splice(0,1);
                }
            })
        },
         //首页登录获取最新任务或精品课程
         getGoodsInBranch(type) {
            this.$api.home.getGoodsInBranch({
                type:type,
                size:type==1?2:5
            }).then(res=>{
                console.log(res);
                if(res.data){
                    if(type==1){
                        this.taskList = res.data;
                    }else{
                        this.bestList = res.data;
                        this.oneBest = this.bestList[0];
                        this.bestList.splice(0,1);
                    }
                }
            })
        },
        //获取当前用户是否已经报名最新任务
        // getIsBuy(){
        //     this.$api.home.checkUserCanByGoods(this.trainObj.id).then(res=>{
        //         if(res.code==0){
        //             this.isSgin = res.data
        //         }
        //     })
        // },
        //初始化swiper
        initSwiper() {
            this.swiper = new Swiper(".swiper-container", {
                slidesPerView: 4,
                spaceBetween: 0,
                // autoplay:true,
                // loop:true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                // pagination: {
                //     el: '.swiper-pagination',
                //     clickable: true,
                // },
            });
        },
    }
};
</script>
<style lang="stylus" scoped>
@import '../asset/css/minzhengHomepage'
</style>
