export const list = [
    {
        id:1,
        img:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/book1.png',
        title:'基石',
        teacher:'徐晓冬',
        date:'2020-10-9',
        content:'本书融合了具有前瞻性和战略性的*的企业管理模式和实践，用多元的视角深入剖析典型借鉴意义的案例，帮助企业构建基于"商业组织10S协同模型”的协同战略'
    },
    {
        id:2,
        img:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/book2.png',
        title:'协同的力量',
        teacher:'【美】道娜﹒马尔科娃 【美】安吉·麦克阿瑟',
        date:'2020-10-9',
        content:'这本基于50多年调查研究的巅峰之作就协作这一话题展开，是关于共同思考之变革力量的开先河之作，协同的意义不仅仅是所有思想和力量的想加，而是几何倍数的增长。',
    },
    {
        id:3,
        img:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/book3.png',
        title:'重构：国内外企业生态战略案...',
        teacher:'徐井宏、李东红',
        date:'2020-10-9',
        content:'本书选取10个靠前外知名企业案例，对这些企业尝试借助打造商业生态系统谋求发展的实践历程进行了梳理，以期展现这些企业在建立和管理商业生态系统过程中的理念与做法。',
    },
    {
        id:4,
        img:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/book4.png',
        title:'细节：如何轻松影响他人',
        teacher:'【美】 罗伯特·西奥迪尼',
        date:'2020-10-9',
        content:'手把手教会你掌握可以影响他人并逆转自我人生的极具创造力和科学性的影响力法则，并将在职场沟通、员工激励、商业谈判、家庭教育等各个领域对你产生持续且深远的超级影响力',
    },
    {
        id:5,
        img:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/book5.png',
        title:'可持续领导力',
        teacher:'【美】戴维·尤里奇 诺姆·斯莫尔伍',
        date:'2020-10-9',
        content:'领导者很重要，更重要的是领导力，而可持续领导力则是最重要的。形成可持续领导力。运用七个法则，可以让你的行动井然有序，并使你拥有可持续领导力。',
    },
    {
        id:6,
        img:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/book6.png',
        title:'灰度决策：如何处理复杂、棘…',
        teacher:'小约瑟夫·巴达拉克',
        date:'2020-10-9',
        content:'本书开宗明义、返璞归真，用5大人文主义问题直击困难的本质，构建了灰度决策的思考路径。这些发人深省的问题可以拓展你的思路、给你全新的视角，让你的判断力变得敏锐。',
    },
    {
        id:7,
        img:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/book7.png',
        title:'赋能：打造应对不确定性的敏捷',
        teacher:'斯坦利·麦克里斯特尔',
        date:'2020-10-9',
        content:'就像华为的任正非号召华为17万员工向美军学习一样，你也一定需要这样一部经过战火和鲜血考验的管理运营法则，在错综复杂的环境下取得成功。',
    },
    {
        id:8,
        img:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/book8.png',
        title:'原则',
        teacher:'【美】瑞·达利欧',
        date:'2020-10-9',
        content:'达利欧相信，自己的成功并非因为个人的特质，而是因为他从失败中学会了做人、做事的原则，而大多数人和公司都可以运用他的这些原则更好地实现自己的目标。',
    },
    {
        id:9,
        img:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/book9.png',
        title:'变革加速器：构建灵活的战略…',
        teacher:'【美】约翰P.科特',
        date:'2020-10-9',
        content:'达利欧相信，自己的成功并非因为个人的特质，而是因为他从失败中学会了做人、做事的原则，而大多数人和公司都可以运用他的这些原则更好地实现自己的目标。',
    },
    {
        id:10,
        img:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/book10.png',
        title:'这就是OKR',
        teacher:'约翰·杜尔',
        date:'2020-10-9',
        content:'这本书是传奇风险投资人约翰·杜尔的作品，揭示了OKR这一目标设定系统如何促使英特尔、谷歌等科技巨头实现*性增长，以及怎样促进所有组织的蓬勃发展。',
    },
    {
        id:11,
        img:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/book11.png',
        title:'高难度沟通',
        teacher:'【美】贾森.杰伊',
        date:'2020-10-9',
        content:'没有解决不了的事，只有不会沟通的人！本书提供了8种思考路径、31个实战练习、4步帮读者打破沟通僵局，让读者从现在开始，停止争论对错、成为沟通高手！',
    },
    {
        id:12,
        img:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/book12.png',
        title:'策略思维',
        teacher:'阿维纳什K迪克西特',
        date:'2020-10-9',
        content:'本书将帮助你学会策略地思考，在人生博弈中扩大胜面。本书以讲故事取胜。它的学术根源是迪克西特教授在普林斯顿大学公共与国际事务学院开设的“策略博弈”课程。',
    },
];
