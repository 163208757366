<template>
    <div class="HouseKeepingTrain publicStylus">
        <div class="container">
            <div class="top">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/HouseKeepingTrain-banner.png" class="banner" alt="">
                <div class="content">
                    <div class="info" @click="goPage">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/train-icon.png" class="icon" alt="">
                        <p>家政培训系统</p>
                    </div>
                </div>
                
            </div>
            <div class="bottom">
                <div class="item" v-for="item in list" :key="item.id">
                    <h2 class="title zh">{{item.zh}}</h2>
                    <p class="cn">{{item.cn}}</p>
                    <p class="line"></p>
                    <p class="content">{{item.content}}</p>
                </div>
            </div>
        </div>
        <footers></footers>
    </div>
</template>
<script>
import footers from './sdjz2Footer.vue'
export default {
    name:'HouseKeepingTrain',
    components:{
        footers
    },
    data(){
        return{
            list:[
                {
                    id:1,
                    zh:'课程体系丰富',
                    cn:'Rich curriculum system',
                    content:'养老护理、母婴护理、保洁、收纳等6项家政，理论知识、专业技能、职业素养多方面学习。'
                },
                {
                    id:2,
                    zh:'学习模式多样',
                    cn:'Various learning modes',
                    content:'提供线上人才测评、录播课程、直播课程、模拟练习、理论考试、电子证书获取、行业评定，线下实操考试、行业技能评定等服务。'
                },
                {
                    id:3,
                    zh:'证书认证权威',
                    cn:'Certification authority',
                    content:'具备评定资质的第三方机构依据国家人社部门的标注要求发布证书。'
                }
            ]
        }
    },
    methods:{
        goPage(){
            window.open('https://sdjzpx.wdeduc.com/sdjz_homepage','newW')
        }
    }
}
</script>
<style lang="stylus" scoped>
@import "../asset/css/publicStylus.styl"
</style>