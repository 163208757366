export const list = [
    {
        id:'4',
        img:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/class-1.png',
        title:'宏观调控',
        teacher:'张鹏老师',
        date:'2020-10-9'
    },
    {
        id:'5',
        img:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/class-2.png',
        title:'物价与就业',
        teacher:'张鹏老师',
        date:'2020-10-9'
    },
];
