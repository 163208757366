<template>
    <div class="exam-list" v-loading="loading1">
        <div class="tab">
            <!-- @click="tabClick(tab)" -->
            <p class="text" v-for="tab in tabList" :key="tab.id" :class="{active:tabIndex === tab.id}" >
                {{tab.value}}
            </p>
        </div>
        <div class="content">
            <div class="list" v-for="item in examList" :key="item.id">
                <div class="left item">
                    <img class="cover" :src="item.coverUrl ? item.coverUrl : require('./../modules/train/asset/4.png')" alt="">
                    <img v-show="item.showNew" class="icon" src="../assets/images/learning/new-icon.png" alt="">
                    
                </div>
                <div class="middle item">
                    <div class="title">
                        <h1>{{item.examName}}</h1>
                        <span class="tip tip1" v-if="item.examType === 1">模拟考试</span>
                        <span class="tip tip2" v-if="item.examType === 2">正式考试</span>
                    </div>
                    <p class="exam-time" v-if="item.isLimitTime">考试时间：{{item.startTime.replace(/-/g,'.')}} - {{item.endTime.replace(/-/g,'.')}}</p>
                    <p v-else class="exam-time">考试时间：长期有效</p>
                    <p class="source">来源：{{item.sourceName}}</p>
                </div>
                <div class="right item">
                    <div class="button" @click="goExam(item)">
                        <img class="lock-icon" v-show="item.showLock" src="./../assets/images/learning/lock-icon3.png" alt="">
                        {{item.buttonStatus === 1? '开始考试': item.buttonStatus === 2 ?'重考' :'查看'}}
                    </div>
                </div>
            </div>
            <article v-if="noData" class="no-data">
                <img class="notraining" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/nodata.png" alt />
                <p>您还没有学习内容</p>
            </article>
            <p class="loadingmore" v-if="loading">加载中...</p>
            <p class="loadingmore" v-if="noMore">- 已加载全部内容 -</p>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
export default {
    name:'examList',
    data(){
        return {
            loading1: true,
            tabIndex:1,
            tabList:[
                {
                    id:1,
                    value:'全部',
                },
                // {
                //     id:2,
                //     value:'未开始',
                // },
                // {
                //     id:3,
                //     value:'进行中',
                // },
                // {
                //     id:4,
                //     value:'已结束',
                // }
            ],
            examList:[],
            paging: {
                params: {
                    pageNum: 1,
                    pageSize: 10
                },
                total: 0
            },
            loading:false,
            noData:null
        }
    },
    mounted(){
        this.getData()
        $(window).scroll(() => {
            //判断是否滑动到页面底部
            if (
                Math.round($(window).scrollTop()) ===
                $(document).height() - $(window).height()
            ) {
                // TODO 滑动到底部时可请求下一页的数据并加载，加载可使用append方法
                // console.log('页面底部')
                
                setTimeout(this.reachBottom(), 1000)
            }
        });
    },
    computed: {
        noMore () {
            return this.examList.length === this.paging.total && this.paging.total !== 0
        },
    },
    methods:{
        // tabClick(item){
        //     this.tabIndex = item.id
        // }
        goExam(item){
            if(item.showLock){
                if(item.lockCourseRate){
                    this.$message.warning({
                        message:'该考试未开启，开启条件为前面的课程学习进度达到 '+item.lockCourseRate+'%',
                        offset:70
                    })
                }
                if(item.lockExamCondition){
                    this.$message.warning({
                        message:'该考试未开启，开启条件为前面的考试得分及格',
                        offset:120
                    })
                }
            }else {
                this.$router.push({
                    path:'/examDetails',
                    query:{
                        bussinessId:item.bussinessId,
                        examId:item.examId,
                        type:item.sourceType,
                        title:item.sourceName,
                        pathName:this.$route.path,
                        backMoudle:'exam',
                        backPath:this.$route.path,
                        timeSlot:item.isLimitTime ? item.startTime.replace(/-/g,'.')+'至'+item.endTime.replace(/-/g,'.'): '长期有效'
                    }
                })
            }
            

        },
        reachBottom() {
            if (this.paging.total > this.paging.params.pageNum * this.paging.params.pageSize) {
                this.paging.params.pageNum = this.paging.params.pageNum + 1;
                this.loading = true;
                this.getData("reachBottom");
            } else {
                this.loading = false;
            }
        },
        getData(){
            const params = {
                pageNum: this.paging.params.pageNum,
                pageSize: this.paging.params.pageSize,
            }
            this.$api.learning.findUserExams({params}).then(({ data: { list, total } }) => {
                this.loading1 = false;
                if(list && list.length){
                    this.noData = false
                }else {
                    this.noData = true
                }
                if(this.paging.params.pageNum>1){
                    this.examList = this.examList.concat(list);
                } else {
                    this.examList = list;
                }
                this.paging.total = total;
            });
        }
    }
}
</script>
<style lang="stylus" scoped>
@import './../assets/css/learningList.styl'
</style>