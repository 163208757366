<template>
    <div class='evaluationAlready'>
        <ul>
            <li v-for="(item,index) in assessList" :key="index">
                <div class="evaluationAlready-left">
                    <img :src="item.assessCoverimg" alt="">
                </div>
                <div class="evaluationAlready-content">
                    <h4>{{item.assessName}}</h4>
                    <p>有效期至：{{item.validEndTime.split(' ')[0].replace(/-/g,'.')}}</p>
                </div>
                <div class="evaluationAlready-right">
                    <el-button type="primary" @click="goAssess(item)">{{$dateFormat.dateFormat() > item.validEndTime ? '查看' :'去测评'}}</el-button>
                </div>
            </li>
        </ul>
        <article v-show="noData" class="no-data">
            <img class="notraining" src="./../../../assets/images/cpnodata.png" alt />
            <p>还没有测评哟，赶快去测评吧～</p>
        </article>
        <p class="loadingmore" v-if="loading">加载中...</p>
        <p class="loadingmore" v-if="noMore">- 已加载全部内容 -</p>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name:'evaluationAlready',
    data () {
        return {
            assessList:[],
            paging: {
                params: {
                    pageNum: 1,
                    pageSize: 10
                },
                total: 0
            },
            loading:false,
            isValid:null,
            noData:null
        };
    },

    methods: {
        goAssess(item){
            if(this.$dateFormat.dateFormat() > item.endTime ){
                this.$message.warning('已到期，无法学习')
            }else {
                this.$router.push({
                    path:'/assess',
                    query:{
                        assessId:item.id,
                        refId:item.refId,//来源ID 商品ID/测评ID
                        refType:item.refType,//来源类型 1 指派 2 商品购买
                        pathName:this.$route.path,
                        backMoudle:'evaluationAlready',
                        // validity:this.validity,
                        // backPath:this.$route.path
                        
                    }
                })
            }
        },
        reachBottom() {
            if (this.paging.total > this.paging.params.pageNum * this.paging.params.pageSize) {
                this.paging.params.pageNum = this.paging.params.pageNum + 1;
                this.loading = true;
                this.getData("reachBottom");
            } else {
                this.loading = false;
            }
        },
        getData(){
            const params = {
                pageNum: this.paging.params.pageNum,
                pageSize: this.paging.params.pageSize,
                isValid:this.isValid
            }
            this.$api.assess.myAssess(params).then(res => {
                if(res.data && res.data.list && res.data.list.length){
                    this.noData = false
                }else {
                    this.noData = true;
                }
                if(this.paging.params.pageNum>1){
                    this.assessList = this.assessList.concat(res.data && res.data.list);
                } else {
                    this.assessList = res.data && res.data.list;
                }
                this.paging.total = res.data && res.data.total;
            });
        }
    },
    mounted(){
        this.getData()
        $(window).scroll(() => {
            //判断是否滑动到页面底部
            if (
                Math.round($(window).scrollTop()) ===
                $(document).height() - $(window).height()
            ) {
                // TODO 滑动到底部时可请求下一页的数据并加载，加载可使用append方法
                // console.log('页面底部')
                
                setTimeout(this.reachBottom(), 1000)
            }
        });
    },
}

</script>
<style lang='stylus' scoped>
@import "../asset/css/evaluationAlready.styl"
</style>