<template>
    <div class="about-box">
        <div class="banner"></div>
        <!-- <img src="@assets/gongxin/about-top.png" class="top-bg" alt=""> -->
        <div class="content">
            <h1>伟东云教育简介</h1>
            <p> 
                伟东云教育隶属于北京伟东凌鸿教育科技有限公司，人工智能人才培养平台是伟东云教育旗下人工智能培训项目。北京伟东凌鸿教育科技有限公司创立于2012年，以全球互联网教育平台服务商为定位，面向各国政府、企业、院校、机构提供优质教育资源与服务，通过搭建开放型互联网教育云平台，打造“一体两翼”的全球化教育格局，努力构建惠及世界的终身教育生态。
            </p>
            <p>
                作为联合国教科文组织战略合作伙伴，伟东云教育先后承办两届四次国际教育信息化大会；作为上海合作组织战略合作伙伴，伟东云教育以务实合作落实“上海精神”，助推区域教育协同发展；作为中国教育部合作伙伴，伟东云教育努力践行“一带一路”教育行动，促进全球教育资源共建共享。
            </p>
            <p>
                目前，伟东云教育正以职业教育为主，积极赋能新时代职业教育发展，业务已累计覆盖全国25个省、169个市区县，在全球25个国家及地区实现布局，服务众多世界500强企业。
            </p>
        </div>
        <footers />
    </div>
</template>
<script>
import footers from '../block/front/footers.vue'
export default {
    name:'about',
    components: {
        footers
    },
}
</script>
<style lang="stylus" scoped>
@import '../asset/css/about.styl'
</style>