<template>
  <div class="taskDetail">
    <div class="back" @click="goBack">
      <img
        src="./../../../assets/images/fanhuidenglu2x.png"
        class="icon"
        alt=""
      />
      返回
    </div>
    <div class="task-container">
      <div class="task-info">
        <span class="train-name"
          >培训任务名称名称名称名称名称名称名称名称名称名称名称名称名称名称名称名称名称名…</span
        >
        <span class="train-progress">已完成 25%</span>
      </div>
      <div @click="goDetail">进入考场</div>
      <facedialog
        v-if="verification"
        :address="verificationAddress"
        :failAddress="verificationFailAddress"
        :type="2"
        :pauseFrame="true"
      ></facedialog>
    </div>
  </div>
</template>
<script>
import facedialog from "@/layout/facedialog.vue";
export default {
  name: "test",
  data() {
    return {
      businessId: 1,
      faceJudge: {},
      verification: false,
      verificationAddress: {},
      verificationFailAddress: {},
    };
  },
  components: {
    facedialog,
  },
  mounted() {
    this.findfaceNeed();
  },
  methods: {
    goBack() {
      this.$router.push({
        path: "/learning",
        query: {
          backModule: this.$route.query.backMoudle,
        },
      });
    },
    findfaceNeed() {
      this.$api.training.findfaceNeed({
        params: { taskId: this.businessId },
      }).then(res=>{
        this.faceJudge = res.data; 
        console.log("识别", res)
      }).catch(error=>{
          console.log(error)
      })
    },
    goDetail() {
      if (this.faceJudge.isFaceRecogBeforeExam == 1) {
        this.verification = true;
        this.verificationAddress = {
          path: "/course/resdExam",
          query: {
            examId: 3,
            businessId: this.businessId,
            dymode: 1,
            switchScreenNum: 3,
            taskType:1//taskType为1，则为从培训任务跳过来的
          },
        };
        this.verificationFailAddress = {
          path: "/course/catalog",
          query: {
            id: this.businessId,
            taskType:1
          },
        };
      }else {
        this.$router.push({
          path:'/course/resdExam',
          query:{
            examId:3,
            businessId: this.businessId,
            dymode: 1,
            switchScreenNum: 3,
            taskType:1
          }
        })
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '../asset/css/taskDetail.styl';
</style>