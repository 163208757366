const urlName = '/train/v1'
export default API => ({
    findUserTrain({ data, params }) {
        return API({ // 详情列表
            url: `${urlName}/train/student/findUserTrain`,
            params,
            data
        });
    },

    trainList({ data, params }) {
        return API({ //学习列表
            url: `${urlName}/train/student/trainList`,
            params,
            data
        });
    },
    // 获取目录
    findCourseItemByCourseId({data, params}) {
        return API({ url: `/course/v1/open/courseItem/getValidCourseItemInfo`, params, data });
    },
    // 获取课程详情
    findCourseInfo({data, params}) {
        return API({ url: `/course/v1/open/course/findCourseInfo`, params, data });
    },
    findById({data,params}) {
        return API({ //简介
            url: `${urlName}/train/findById` ,
            params,
            data
        });
    },

    findTrainBasicInfoById({params}) {
        return API({ //简介
            url: `/train/v1/train/findTrainById` ,
            params,
        });
    },
    // 查询训练营考试详情
    findTrainExamInfoById({params}) {
        return API({ //简介
            url: `${urlName}/trainItemExam/findTrainExamInfoById` ,
            params,
        });
    },
    // 查询指定考试历史记录
    findUserPaperListById({params}) {
        return API({ //简介
            url: `${urlName}/trainItemExam/findUserPaperListById` ,
            params,
        });
    },

    // 获取首页训练营
    findIndexTrain({data,params}) {
        return API({ //简介
            url: `${urlName}/train/findIndexTrain` ,
            params,
            data
        });
    },
    // 获取模拟考试id
    findExamTrain({data,params}) {
        return API({ //简介
            url: `${urlName}/train/findExamTrain` ,
            params,
            data
        });
    },
    // 我的考试列表
    findMyExamList({data, params}) {
        return API({
            url: `${urlName}/trainItemExam/findMyExamList`,
            params,
            data
        })
    },
    //人脸识别接口
    findfaceNeed({data, params}) {
        return API({
            url: '/task/v1/taskRule/findTaskRule',
            params,
            data
        })
    },
    //获取实训环境url-c
    getDrillUrl(params) {
        return API({
            url: `/drill/v1/drill/getDrillUrl`,
            params,
            method:'post',
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        })
    },
    //获取实训列表
    getDrillListToC() {
        return API({
            url: `/drill/v1/drill/getDrillListToC`,
            method:'post',
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        })
    },


})

