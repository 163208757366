<template>
  <div>
    <div class="gongxin gongxin-nav custom-nav">
      <div class="menuContents">
        <img class="imglogoG fl" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/logo_pc.png" alt="" />
        <div class="gongxin-nav-bar fl ">
          <p class="item-nav fl custom-nav-text" 
            v-for="item in navList" 
            :key="item.id"
            :class="{active: activeNav == item.path}"
            @click="clickNav(item)"
          >
            {{item.title}}
          </p>
          <el-dropdown class="move fl" placement="bottom">
            <span class="el-dropdown-linkapp">
              <span class="dropdown-title link-app">移动端</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="nav-gx-menu">
              <el-dropdown-item class="nav-gx-item">
                <div class="gongxin-dl-wrap">
                  <div class="img-wrap">
                    <img src="@assets/user/gongxin_qrcode.png" alt="" class="app-download" />
                    <img src="@assets/user/border-dec.png" class="border border1"/>
                    <img src="@assets/user/border-dec.png" class="border border2"/>
                  </div>
                  
                  <p>移动学习 随时随地</p>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div v-if="!token" class="un-login fl" >
            <p @click="jumpLogin('gongxin_homepage','login')">登录/注册</p>
            <!-- <p @click="jumpLogin('gongxin_homepage','register')">注册</p> -->
        </div>
        <template v-else>
          <account :replacePage="'gongxin_homepage'"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import account from './account.vue'
export default {
  name: 'gongxinNav',
  components: {
    account
  },
  data () {
    return {
      activeNav: this.$route.path,
      examId: 0,
      trainId: 0,
      token: '',
      mode: '0', //  0:其他 1' 学习中心 '2': 模拟考试
      navList: [
        {
          path: '/gongxin_homepage',
          needLogin: false,
          title: '首页',
          activePaths: ['/gongxin_homepage', '/gongxinDetails', '/orderPage'],
          mode: '0'
        },
        // {
        //   path: '/personalEvaluation',
        //   needLogin: true,
        //   title: '个人测评',
        //   activePaths: ['/personalEvaluation'],
        //   mode: '0'
        // },
        {
          path: '/courseGoods',
          needLogin: true,
          title: '课程中心',
          activePaths: ['/courseGoods','/moreCourseGoods'],
          mode: '6'
        },
        {
          path: '/learning',
          needLogin: true,
          title: '学习中心',
          activePaths: ['/learning'],
          mode: '1'
        },
        {
          path: '/online_training',
          needLogin: false,
          title: '在线实训',
          activePaths: ['/online_training'],
          mode: '2',
        },
        {
          path: '/examList',
          needLogin: true,
          title: '考试中心',
          activePaths: ['/examList'],
          mode: '3'
        },
        {
          path: '/certificationExam',
          needLogin: true,
          title: '认证考试',
          activePaths: ['/certificationExam'],
          mode: '4'
        },
        
        {
          path: '/liveList',
          needLogin: true,
          title: '直播专区',
          activePaths: ['/liveList'],
          mode: '5',
        }
      ],
      goodsInfo:null
    }
  },
  created () {
  },
  watch: {
    $route() {
      setTimeout(() => { //路由跳转
        // this.navList[1].path = ''
        console.log(this.$route.path)
        sessionStorage.setItem('index',this.$route.path)
        this.activeNav = this.$route.path
        // if(this.activeNav === '/course/catalog' || this.activeNav === '/learning/taskDetail'){
        //     this.navList[1].path = this.$route.meta.highlight
        // }
        this.token = localStorage.getItem('token')
      }, 100)
    }
  },
  async mounted () {
    setTimeout(() => { //刷新
    
      this.activeNav = sessionStorage.getItem('index')  || this.activeNav
      this.token = localStorage.getItem('token')
    }, 100)
    // this.findSaleGoods()
  },
  methods: {
      async findSaleGoods(){
         await this.$api.home.findSaleGoods().then(res=>{
            this.goodsInfo = res.data
        })

      },
    clickNav(item) {
      // if (this.$route.path === '/course/testPaper') {
      //   return;
      // }
      let name = item.path;
      console.log(item.path,'item.path')
      if(item.path === this.$route.path){
          return;
      }
        // if (item.title === '学习中心') {
            // goodsType 1 训练营 2 培训任务
            // this.checkUserCanByGoods(item)
            
        // } 

      if (item.needLogin && !localStorage.getItem('token')) {
        this.jumpLogin(name,'login');
      } else if (name) {
        this.$router.push(name)
      }
      
    },
    /* 工信是否购买过课程 */
    checkUserCanByGoods(item) {
        this.$api.home.checkUserCanByGoods(this.goodsInfo.id).then(res=>{
            if (res.data == true) {
                this.$message.info({
                        message: '您尚未购买课程，先去购买课程吧',
                        offset: 120
                })
                this.$router.push({
                    path:'/gongxinDetails'
                })
            }else {
                let name = ''
                item.path = name = this.goodsInfo.goodsType === 1 ? '/course/catalog' : '/learning/taskDetail'
                name = `${name}?id=${this.goodsInfo.referId}&taskType=${this.goodsInfo.goodsType}`
                this.$router.push(name)
                
            }
        })
    },
    jumpLogin(name,type) {
      this.$router.push({
        name: type,
        query: {
            name
        }
      })
      // if(type === 'register'){
      //     this.$router.push({
      //       name: type,
      //       query: {
      //           name
      //       }
      //     })
      // }else {
      //     this.$router.push({
      //         name: type,
      //         query: {
      //             name
      //         }
      //     })
      // }
    },
  }
}
</script>
<style lang="stylus" scoped>
</style>
<style lang="stylus">
.gongxin-nav {
    background: #fff !important;
    box-shadow: 0px 2px 24px 0px rgba(34, 34, 55, 0.06);
    font-family: PingFangSC-Regular, PingFang SC;
    height: 80px;
    position: fixed;
    width: 100%;
    z-index: 666;
    .menuContents {
      max-width: 1200px !important;
      margin: 0 auto;
      min-width: 1266px;
      background: #fff;
    }
    .imglogoG {
      width: 277px !important;
      margin-top: 17px !important;
    }
    .gongxin-nav-bar {
      height: 80px;
      margin-left: 40px;
      .item-nav {
        display: inline-block;
        font-size: 16px;
        line-height: 76px;
        margin: 0 16px;
        cursor: pointer;
        color:  #666;
      }
      .item-nav.active {
        color: #086FFE;
        border-bottom: 4px solid #086FFE;
        font-weight: 600;
      }
    }
    .move {
        .el-icon-arrow-down.el-icon--right {
            display: none;
        }
        .el-dropdown-linkapp .link-app {
          line-height 76px !important;
          margin-right: 0;
        }
        :focus{
          outline: none !important;
        }
    }
    .un-login {
        background: rgba(215, 215, 215, .29);
        
        width: 120px;
        height: 42px;
        text-align: center;
        line-height: 42px;
        border-radius: 21px;
        margin-top: 18px;
        
        cursor: pointer;
        margin-left: 40px;
        p {
            color: #747474;
            font-size:18px;
            display inline-block
        }
    }
    .move.el-dropdown {
        color:  #666;
        margin-left: 24px;
        span {
            font-size: 18px;
        }
    } 
}
.nav-gx-menu {
      text-align: center;
      border-radius: 6px;
      margin-top: 22px !important;
      .nav-gx-item {
          &:hover {
            background: #fff;
          }
          .gongxin-dl-wrap {
            padding: 0 74px;
            .img-wrap {
              width: 140px;
              height: 140px;
              border: 1px solid #F6F6F6;
              position: relative;
              margin: 46px auto 18px;
              .app-download {
                  width: 100%;
                  height: 100%;
              }
              .border {
                width: 14px;
                height: 14px;
                position: absolute;
                &.border1 {
                  left: 0;
                  top: 0;
                }
                &.border2 {
                  right: 0;
                  bottom: 0;
                  transform: rotate(180deg);
                }
              }
            }
            

            p {
                line-height: 22px;
                font-size: 16px;
                color: #666;
                margin-bottom: 40px;
            }
          }
          
      }
  }

</style>
