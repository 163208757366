<template>
    <div class='pattern'>
        <div class="backButton" v-if="!theme">
            <span @click="goBack">
                <i class="back el-icon-arrow-left"></i>
                返回
            </span>
        </div>
        <div class="pattern-title">
            {{patternDetail.practiceName}}
        </div>
        <div class="pattern-list">
            <ul>
                <li @click="gopracticeDetail(1)">
                    <img src="../asset/images/xxlx.png" alt="">
                    <div class="middle">
                        <h3>顺序学习</h3>
                        <p>全部试题按照顺序进行练习，初次进入练习建议使用顺序练习。</p>
                    </div>
                    <div class="right">
                        <span class="lastTime">上次练习到第<span>{{patternDetail.commitLastSequence?patternDetail.totalCount: patternDetail.sequence || 0}}</span>题&emsp;</span>共计{{patternDetail.totalCount}}题<img src="../asset/images/right.png" class="icon" alt="">
                    </div>
                </li>
                <li @click="gopracticeDetail(2)">
                    <img src="../asset/images/sjlx.png" alt="">
                    <div class="middle">
                        <h3>随机练习</h3>
                        <p>知识点交叉练习，每次进入练习顺序被打乱，适用对基础知识已有基本掌握。</p>
                    </div>
                    <div class="right">
                        共计{{patternDetail.totalCount || 0}}题<img src="../asset/images/right.png" class="icon" alt="">
                    </div>
                </li>
                <li @click="gopracticeDetail(4)">
                    <img src="../asset/images/ctb.png" alt="">
                    <div class="middle">
                        <h3>错题本</h3>
                        <p>针对错题巩固练习，答题正确后，试题将移除错题本。</p>
                    </div>
                    <div class="right">
                        共计{{patternDetail.errorsCount || 0}}题<img src="../asset/images/right.png" class="icon" alt="">
                    </div>
                </li>
                <li @click="gopracticeDetail(3)">
                    <img src="../asset/images/wdbj.png" alt="">
                    <div class="middle">
                        <h3>我的标记</h3>
                        <p>练习过程中对掌握不深刻的试题标记后，试题将进入”我的标记”，可再次回顾练习。</p>
                    </div>
                    <div class="right">
                        共计{{patternDetail.markCount || 0}}题<img src="../asset/images/right.png" class="icon" alt="">
                    </div>
                </li>
            </ul>
        </div>
        <div class="red"></div>
        <el-dialog class="tip-dialog-pattern" :visible.sync="isContinue" width="400px" :close-on-click-modal="false">
            <img src="../asset/images/ic-ts@2x.8d378b1f.png" class="icon-img" alt="" />
            <p class="titles">提示</p>
            <p class="text"> {{title}}</p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="btncancel()">{{ iscancel }}</el-button>
                <el-button type="primary" @click="confirmbtn()" style="width: 120px">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name:'pattern',
    data () {
        return {
            theme:false,
            patternDetail:{},
            isContinue:false,
            title:'',
            iscancel:'取消',
            type:null,
        };
    },

    methods: {
        btncancel(){
            if(this.patternDetail.commitLastSequence){
                this.$router.push({
                    path:'/practiceTrainingTask',
                    query:{
                        practiceId:this.$route.query.practiceId,
                        userPracticeId:this.patternDetail.lastSequenceUserPracticeId,
                        businessId:this.$route.query.businessId,
                        businessType: this.$route.query.businessType,
                        practiceModel:1,
                        practiceShow:true,
                        backPath:this.$route.query.backPath,
                        backModule:this.$route.query.backModule,
                    }
                })
            }else{
                this.joinExam();
            }
            this.isContinue=false;
            
        },
        confirmbtn(){
            if(this.patternDetail.commitLastSequence){
                this.joinExam();
            }else{
                this.$router.push({
                    path:'/practiceTrainingTask',
                    query:{
                        practiceId:this.$route.query.practiceId,
                        userPracticeId:this.patternDetail.lastSequenceUserPracticeId,
                        businessId:this.$route.query.businessId,
                        businessType: this.$route.query.businessType,
                        practiceModel:1,
                        sequence:this.patternDetail.sequence,
                        backPath:this.$route.query.backPath,
                        backModule:this.$route.query.backModule,
                    }
                })
            }
            this.isContinue=false;
        },
        joinExam() {
            this.$api.practice.joinExam({
                    params: {
                        businessId: this.$route.query.businessId,
                        businessType: this.$route.query.businessType,
                        practiceId:this.$route.query.practiceId,
                        practiceModel:this.type,
                    }
                }).then((res) => {
                if(res.data){
                    this.$router.push({
                        path:'/practiceTrainingTask',
                        query:{
                            practiceId:this.$route.query.practiceId,
                            businessId:this.$route.query.businessId,
                            businessType: this.$route.query.businessType,
                            practiceModel:this.type,
                            userPracticeId:res.data,
                            backPath:this.$route.query.backPath,
                            backModule:this.$route.query.backModule,
                        }
                    })
                }
            });
        },
        modelInfo(){
            this.$api.practice
                .modelInfo({
                    params: {
                        businessId: this.$route.query.businessId,
                        businessType: this.$route.query.businessType,
                        practiceId:this.$route.query.practiceId
                    }
                })
                .then(res => {
                    if(res.data){
                        this.patternDetail=res.data;
                    }
                });
        },
        gopracticeDetail(type){
            this.type=type;
            if((type===1 || type===2) && this.patternDetail.totalCount<1){
                this.$message.warning({message:'暂无试题',offset:70})
                return;
            }else if(type===3 && this.patternDetail.markCount===0){
                this.$message.warning({message:'暂无标记试题',offset:70})
                return;
            }else if(type===4 && this.patternDetail.errorsCount===0){
                this.$message.warning({message:'暂无错题',offset:70})
                return;
            }
            if(type===1 && this.patternDetail.attendSequence){
                if(this.patternDetail.commitLastSequence){
                    this.title="上次练习已全部完成，是否从头练习？"
                    this.iscancel="查看上次练习";
                    this.isContinue=true;
                }else{
                    if(this.patternDetail.sequence>0){
                        this.title="上次练到第"+this.patternDetail.sequence+"题，是否继续练习？"
                        this.isContinue=true;
                    }else{
                        this.$router.push({
                            path:'/practiceTrainingTask',
                            query:{
                                practiceId:this.$route.query.practiceId,
                                userPracticeId:this.patternDetail.lastSequenceUserPracticeId,
                                businessId:this.$route.query.businessId,
                                businessType: this.$route.query.businessType,
                                practiceModel:1,
                                sequence:this.patternDetail.sequence,
                                backPath:this.$route.query.backPath,
                                backModule:this.$route.query.backModule,
                            }
                        })
                    }
                    
                }
                
            }else{
                this.joinExam()
            }


            // else if(type===1 && this.patternDetail.attendSequence && this.patternDetail.sequence==0){
            //     this.$router.push({
            //         path:'/practiceTrainingTask',
            //         query:{
            //             practiceId:this.$route.query.practiceId,
            //             userPracticeId:this.patternDetail.lastSequenceUserPracticeId,
            //             businessId:this.$route.query.businessId,
            //             businessType: this.$route.query.businessType,
            //             practiceModel:1,
            //             sequence:this.patternDetail.sequence,
            //             backPath:this.$route.query.backPath
            //         }
            //     })
            // }
        },
        goBack(){
            if(this.$route.query.backPath){
                this.$router.push({
                    path:this.$route.query.backPath,
                    query:{
                        backModule:this.$route.query.backModule,
                    }
                })
            }else{
                this.$router.push({
                    path:'/learning/taskDetail',
                    query:{
                        validity:true,
                        id:this.$route.query.businessId,
                        backPath:this.$route.query.backPath,
                        type:2
                    }
                })
            }
            
        },
    },

    mounted(){
        this.modelInfo();
    },
}

</script>
<style lang="stylus">
@import '../asset/css/pattern.styl'
</style>