<template>
    <div class="invoiceForm">
        <el-button type="text" class="backBtn" @click="goBackBtn()">
            <img src="./../asset/images/ic-fh@2x.png" alt="">
            返回
        </el-button>
        <el-tabs v-model="activeName" @tab-click="handleClick()"  class="formTabs">
            <el-tab-pane label="普通发票" name="first">
                <p class="tipTxt"><img src="./../asset/images/ic-gth@2x.png" alt="">纸质发票将以快递到付方式邮寄给您</p>
                <div class="invoices-item invoiceTitleType  mb-24">
                    <span class="itemSpan">抬头类型：</span>
                    <div class="itemSpanType">
                        <span :class="form.invoiceTitleType == 2 ?'active':''" @click="handleType(2)">个人</span>
                        <span :class="form.invoiceTitleType == 1 ?'active':''" @click="handleType(1)">单位</span>
                    </div>
                </div>
                <div class="invoices-item invoiceContent mb-24">
                    <span class="itemSpan">发票内容：</span>
                    <span>{{applyInfo.invoiceContent}}</span>
                </div>
                 <div class="invoices-item mb-24">
                    <span class="itemSpan">发票金额：</span>
                    <span class="invoiceAmount">￥{{applyInfo.invoiceAmount}}</span>
                </div>
                <rule-form :invoiceType="form.invoiceType" :invoiceTitleType="form.invoiceTitleType" @submitData="submitData"></rule-form>
            </el-tab-pane>
            <el-tab-pane label="增值税专用发票" name="second">
                <p class="tipTxt">纸质发票将以快递到付方式邮寄给您</p>
                <div class="invoices-item invoiceContent mb-24">
                    <span class="itemSpan">发票内容：</span>
                    <span>{{applyInfo.invoiceContent}}</span>
                </div>
                 <div class="invoices-item mb-24">
                    <span class="itemSpan">发票金额：</span>
                    <span class="invoiceAmount">￥{{applyInfo.invoiceAmount}}</span>
                </div>
                <rule-form :invoiceType="form.invoiceType" :invoiceTitleType="form.invoiceTitleType" @submitData="submitData"></rule-form>
            </el-tab-pane>
        </el-tabs>
        <el-dialog
            :visible.sync="dialogVisible"
            width="400px"
            class="modalTip"
            :append-to-body="appendToBody"
            :before-close="handleClose">
            <img src="@assets/ic-ts@2x.png" class="icon-img" alt="">
            <p class="title">{{text1}}</p>
            <p class="text">{{text2}}</p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary"  @click="handleConfirm">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import ruleForm from './form.vue'
export default {
    name:'invoicesForm',
    props:{
       applyInfo:{
            type:Object,
            default:()=>{}
       }
    },
    data(){
        return{
            appendToBody:false,
            dialogVisible:false,
            text1:'提示',
            text2:'提交后发票信息将无法更改',
            activeName: 'first',
            form:{
                invoiceType:"1",  //发票类型 1普通发票 2增值税专用发票
                invoiceTitleType:"2", //抬头类型 1单位 2个人
                invoiceTitle:'',
                taxNumber:'',
                bankName:'',
                bankAccount:'',
                registPhone:'',
                recipientName:'',
                recipientPhone:'',
                recipientAddress:'',
                recipientEmail:'',
                orderId:0,
            },
            formData:{
                filter: {
                    invoiceType:'1',
                    invoiceTitleType:'2',
                    invoiceTitle:'',
                    taxNumber:'',
                    bankName:'',
                    bankAccount:'',
                    registPhone:'',
                    registAdress:'',
                    recipientName:'',
                    recipientPhone:'',
                    recipientAddress:'',
                    recipientEmail:'',
                    orderId:0,
                },
            },
        }
    },
    components:{
        ruleForm
    },
    created(){
    },
    methods: {
        // 切换类型
        handleType(type){
            this.form.invoiceTitleType = type;
        },
        handleConfirm(){
            const that = this;
            if(this.text2=='提交后发票信息将无法更改'){
                const params = this.formData.filter;
                params.invoiceType = this.form.invoiceType;
                params.invoiceTitleType = this.form.invoiceTitleType;
                params.orderId = this.applyInfo.id
                this.$api.invoice.postData(params).then((res)=>{
                    if(res.code == 0){
                        that.$message({
                            message:'申请成功',
                            type:"success",
                            onClose:function(){
                            //    that.applyInfo = false;
                               that.dialogVisible = false;
                               that.$emit('myOrderList');
                               that.$emit('applyInvoiceShow');
                            }
                        });
                    }else{
                        that.$message.error(res.message);
                    } 
                })
            }else{
                this.$emit('applyInvoiceShow');
            }
        },
        goBackBtn(){
            this.text2 = '已填写信息将不被保存，是否返回';
            this.dialogVisible = true;
        },
        handleClose(){
            this.dialogVisible = false;
        },
        submitData(obj){
            const that = this;
            that.text2 = "提交后发票信息将无法更改";
            that.dialogVisible = true;
            this.formData.filter = obj;
        },
      handleClick() {
       if(this.activeName == 'second'){
            this.form.invoiceType = 2;
            this.form.invoiceTitleType = 1;
       }else{
            this.form.invoiceType = 1;
            this.form.invoiceTitleType = 2;
        }
      }
    }
}
</script>
<style scoped lang="stylus">
@import '../asset/css/invoice.styl'
</style>

