export const list = [
    [
        {
            name: '组织行为学',
            childrenList: [
                {name: '绪论'},
                {name: '第一讲：组织行为学的特点与作用（1）'},
                {name: '第一讲：组织行为学的特点与作用（2）'},
                {name: '第一讲：组织行为学的特点与作用（3）'}
            ]
        },
        {
            name: '企业投融资的三个基础逻辑',
            childrenList: [
                {name: '企业投融资的三个基础逻辑'}
            ]
        },
        {
            name: '并购重组与整合',
            childrenList: [
                {name: '并购重组与整合'}
            ]
        },
        {
            name: '采购与供应链管理',
            childrenList: [
                {name: '采购与供应链管理'}
            ]
        },
        {
            name: '财务会计与分析',
            childrenList: [
                {name: '财务会计与分析'}
            ]
        },
        {
            name: '人格心理学',
            childrenList: [
                {name: '人格心理学1'},
                {name: '人格心理学2'}
            ]
        },
        {
            name: '市场营销学',
            childrenList: [
                {name: '市场营销学'}
            ]
        },
        {
            name: '文化消费品开发',
            childrenList: [
                {name: '文化消费品设计'}
            ]
        },
    ],[
        {
            name: '《民法典》的意义和主要内容',
            childrenList: [
                {name: '绪论'},
                {name: '《民法典》的意义和主要内容（上）'},
                {name: '《民法典》的意义和主要内容（中）'},
                {name: '《民法典》的意义和主要内容（下）'}
            ]
        },
        {
            name: '《论共产党员的修养》导读',
            childrenList: [
                {name: '《论共产党员的修养》导读（上）'},
                {name: '《论共产党员的修养》导读（下）'},
            ]
        },
        {
            name: '底线思维',
            childrenList: [
                {name: '底线思维（上）'},
                {name: '底线思维（中）'},
                {name: '底线思维（下）'}
            ]
        },
        {
            name: '党员领导干部如何提高舆情监控和应对能力',
            childrenList: [
                {name: '党员领导干部如何提高舆情监控和应对能力（上）'},
                {name: '党员领导干部如何提高舆情监控和应对能力（下）'}
            ]
        },
        {
            name: '弘扬中华优秀传统文化',
            childrenList: [
                {name: '弘扬中华优秀传统文化（上）'},
                {name: '弘扬中华优秀传统文化（下）'}
            ]
        },
        {
            name: '提高辩证思维能力',
            childrenList: [
                {name: '提高辩证思维能力（上）'},
                {name: '提高辩证思维能力（下）'}
            ]
        },
        {
            name: '把学思践悟习近平新时代中国特色社会主义思想引向深入——学习《习近平谈治国理政》',
            childrenList: [
                {name: '把学思践悟习近平新时代中国特色社会主义思想引向深入——学习《习近平谈治国理政》（第三卷）（上）'},
                {name: '把学思践悟习近平新时代中国特色社会主义思想引向深入——学习《习近平谈治国理政》（第三卷）（中）'},
                {name: '把学思践悟习近平新时代中国特色社会主义思想引向深入——学习《习近平谈治国理政》（第三卷）（下）'}
            ]
        },
        {
            name: '邓小平与中国特色社会主义道路的成功开辟',
            childrenList: [
                {name: '邓小平与中国特色社会主义道路的成功开辟（上）'},
                {name: '邓小平与中国特色社会主义道路的成功开辟（下）'}
            ]
        },
        {
            name: '毛泽东的政治智慧与领导艺术',
            childrenList: [
                {name: '毛泽东的政治智慧与领导艺术（上）'},
                {name: '毛泽东的政治智慧与领导艺术（中）'},
                {name: '毛泽东的政治智慧与领导艺术（下）'}
            ]
        },
        {
            name: '延安精神及其现实启示',
            childrenList: [
                {name: '延安精神及其现实启示（上）'},
                {name: '延安精神及其现实启示（中）'},
                {name: '延安精神及其现实启示（下）'}
            ]
        },

    ],[
        {
            name: "养老护理员自我心理调适相关知识",
            childrenList: [
                { name: "养老护理员自我心理调适相关知识", type: "1" }
            ]
        },
        {
            name: "养老护理员在机构、社区和家庭提供服务基本规范常识",
            childrenList: [
                { name: "养老护理员在机构、社区和家庭提供服务基本规范常识", type: "1" }
            ]
        },
        {
            name: "老年人进食、 进水观察评估记录",
            childrenList: [
                { name: "老年人进食进水的观察", type: "1" },
                { name: "老年人进食进水观察评估记录", type: "1" }
            ]
        },
        {
            name: "对发生噎食、误吸情况的老年人采取应急措施，报告、寻求帮助",
            childrenList: [
                { name: "噎食、误吸的急救", type: "1" },
                { name: "对老年人发生噎食、 误吸的急救及报告", type: "1" }
            ]
        },
        {
            name: "协助老年人如厕协助老年人如厕",
            childrenList: [
                { name: "帮助老年人正常如厕", type: "1" },
                { name: "协助卧床老年人使用便器排便", type: "1" },
                { name: "协助卧床老年人使用便盆", type: "1" },
                { name: "协助老年人使用尿壶", type: "1" },
                { name: "便壶的清洁和消毒", type: "1" }
            ]
        },
        {
            name: "为老年人布置睡眠环境及观察老年人睡眠状况，报告并记录异常变化",
            childrenList: [
                { name: "布置睡眠环境&观察老年人睡眠状况，报告并记录异常变化", type: "1" },
                { name: "为老年人布置睡眠环境", type: "1" }
            ]
        },
        {
            name: "为老年人穿脱衣服",
            childrenList: [
                { name: "协助老年人穿脱衣服、鞋袜", type: "1" },
                {
                    name: "为老年人更换开襟衣服",
                    type: "0"
                },
                {
                    name: "为老年人更换套头衣服",
                    type: "1"
                },
                {
                    name: "为老年人穿脱裤子 、鞋袜",
                    type: "0"
                }
            ]
        },
        {
            name: "协助老年人测量体重并记录",
            childrenList: [
                { name: "测量体重的方法及注意事项", type: "0" },
                { name: "协助老年人测量体重", type: "0" }
            ]
        },
        {
            name: "使用热水袋等为老年人保暖",
            childrenList: [
                { name: "老年人保暖的操作方法及注意事项", type: "0" },
                { name: "使用热水袋为老年人保暖", type: "0" }
            ]
        },

        {
            name: "使用助行器、轮椅等辅助器具协助老年人转移",
            childrenList: [
                { name: "使用助行器协助老年人转移", type: "0" },
                { name: "使用手杖协助老年人转移", type: "0" },
                { name: "使用步行器协助老年人转移", type: "0" },
                { name: "使用轮椅协助老年人转移", type: "0" }
            ]
        }
    ],[
        {
            name: "手把手教你做产品经理",
            childrenList: [
                { name: "认识产品经理", type: "1" },
                {
                    name: "产品经理主要做什么",
                    type: "0"
                }
            ]
        },
        {
            name: "互联网产品交互设计实战",
            childrenList: [
                {
                    name: "交互设计：产品经理必学三原则（一）",
                    type: "0"
                },
                {
                    name: "交互设计：产品经理必学三原则（二）",
                    type: "0"
                },
                {
                    name: "交互设计：产品经理必学三原则（三）",
                    type: "0"
                }
            ]
        },
        {
            name: "零基础 SEM核心系列课程",
            childrenList: [
                { name: "SEM 基本概念（一）", type: "0" },
                { name: "SEM 基本概念（二）", type: "0" },
                { name: "SEM 基本概念（三）", type: "0" }
            ]
        },
        {
            name: "SEM实战课",
            childrenList: [
                { name: "关键词出价设置思路", type: "0" },
                { name: "关键词匹配模式设置思路", type: "0" }
            ]
        },
        {
            name: "SEO搜索引擎优化实战",
            childrenList: [
                { name: "一节课带你了解SEO（一）", type: "0" },
                { name: "一节课带你了解SEO（二）", type: "0" },
                { name: "一节课带你了解SEO（三）", type: "0" }
            ]
        },
        {
            name: "SEO+营销型网站建设实战",
            childrenList: [
                { name: "营销型网站搭建", type: "0" }
            ]
        },
        {
            name: "零基础变身营销达人",
            childrenList: [
                { name: "企业危机公关解决方案（一）", type: "0" },
                { name: "企业危机公关解决方案（二）", type: "0" }
            ]
        },
        {
            name: "互联网运营基础实务",
            childrenList: [
                { name: "运营入门知识：运营团队构成", type: "0" },
                { name: "运营入门知识：运营专业知识普及", type: "0" }
            ]
        },
        {
            name: "运营人到运营总监的自我突破",
            childrenList: [
                { name: "运营人专业能力提升 文案营销（一）", type: "0" },
                { name: "运营人专业能力提升 文案营销（二）", type: "0" }
            ]
        },
        {
            name: "抖音短视频营销引流系列课",
            childrenList: [
                { name: "抓住短视频流量风口，极致引流方案", type: "0" },
                { name: "短视频内容定位和分类", type: "0" }
            ]
        }
    ]
]