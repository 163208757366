<template>
    <div v-if="previewModalVisbile" class="previewModal">
        <el-dialog
        :visible.sync="previewModalVisbile"
        :showClose="showClose"
        customClass="definepreviewModal"
         :before-close="handleClosePreview">
        <div class="certificateModal">
            <div class="certificateModal-content">   
                <p @click="handleClosePreview" class="closeBtn">
                    <img src="../assets/images/close.png" alt="">
                </p>
                <img :src="previewUrl" alt="" class="certificateImage">
                <el-button @click="downLoad" v-if="isDownLoad" type="primary">下载证书</el-button>
            </div>
        </div>
   </el-dialog>
    </div>
</template>
<script>
export default {
    name:'previewCertificate',
    props:{
        previewModalVisbile:{
            type:Boolean,
            default:false,
        },
        isDownLoad:{
            type:Boolean,
            default:false,
        },
        previewUrl:{
            type:String,
            default:''
        }
    },
    data(){
        return{
            showClose:false
        }
    },
    methods:{
        downLoad(){
            this.$emit('downLoadCer');
        },
        handleClosePreview(){
            this.$emit('handleClosePreview')
        }
    }
}
</script>
<style lang="stylus">
.definepreviewModal 
    background:transparent;
    box-shadow none;
</style>
<style lang="stylus" scoped>
    .certificateModal-content
        display :inline-block;
        position relative
    .certificateModal 
        text-align:center;
        .closeBtn
            cursor pointer
            position :absolute;
            right:0;
            top:-52px;
            img 
                width:24px;
                height:24px;
        img.certificateImage 
            max-width:760px;
            max-height:594px;
            display :block;
            margin:0 auto 0;
        /deep/button
            width:240px;
            height:40px;
            padding:0;
            background:#316FFF;
            display:block;
            margin:50px auto 0;
            &:active
                border-color:#316fff;
            &:hover
                border-color:#316fff;
            &:focus
                border-color:#316fff;
            span 
                font-size:16px;
</style>
