<template>
    <div class="footers-box">
        <div class="content">
            <div class="top">
                <div class="right">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/static-pc/header-logo.png" alt="">
                </div>
                <p class="line"></p>
                <div class="left">
                    <!-- <router-link :to="'/about'" tag="a" class="about">关于我们</router-link> -->
                    <p>伟东云教育集团</p>
                    <p class="add">地址：北京市西城区北三环中路燕莎盛世大厦5层</p>
                    <p>电话：400-8555-686</p>
                </div>
            </div>
            <!-- <div class="bottom">
                北京伟东凌鸿教育科技有限公司@版权所有 京 ICP备10026535号-6
            </div> -->
        </div>
        <saas-nsr-footer :isContract="false" :bgColor="'#1e7bfa'" :copyright="'© 2021 wedon.com 版权所有'" :ICPNumber="'京ICP备10026535号-35'" :showLicence="false"/>
    </div>
</template>

<script>
export default {
    name:'weidongFooter'
}
</script>

<style lang="stylus" scoped>
    @import '../asset/css/weidongFooter.styl'
</style>