<template>
<div class="top-account" :class="{'white': isWhite, 'yingji-account': theme === 'yingji','shjd-account': theme === 'shanghaijiaoda'}">
  <el-dropdown class="fr user-dropdown" @visible-change="toggleUser" trigger="click" >
      <div class="flex user-account">
        <p class="txt">
          {{ subUserInfo.name || '--' }}
        </p>
        <p v-if="isHiddenAccount!=='1'">
          <i :class="[{'el-icon-arrow-down': !showUser},{'el-icon-arrow-up': showUser}]"></i>
        </p>

      </div>
      <el-dropdown-menu v-if="isHiddenAccount!=='1'" slot="dropdown" class="account-dropdown-menu" >
        <el-dropdown-item>
          <ul @click="setRealName">
            <li>
              {{ subUserInfo.name || '--' }}
              <span
                v-if="typeof isRealName === 'boolean'"
                :class="{isRealName: true, RealNamed: isRealName, Unnamed: !isRealName}"
                >
                <img
                  v-if="isRealName"
                  style="width: 10px; height: 11px;position: relative; top: 1px;"
                  src="@assets/RealNamed.png" alt="">
                {{isRealName ? '已实名' : '未实名'}}
              </span>
            </li>
            <li>{{ getTel }}</li>
          </ul>
        </el-dropdown-item>
        <el-dropdown-item divided>
            <ul @click="showPersonalCenter">
              <li>
                <span>个人中心</span>
              </li>
            </ul>
        </el-dropdown-item>
        <el-dropdown-item divided>
          <ul
            @click="showBranchList"
            v-if="branchList && branchList.length > 1"
          >
            <li>切换机构</li>
            <li>当前：{{ branchName || '--' }}</li>
          </ul>
          <ul v-else>
            <li>所在机构</li>
            <li>{{ branchName || '--' }}</li>
          </ul>
        </el-dropdown-item>
        <el-dropdown-item divided>
          <ul>
            <li @click="signOut">
              退出登录
            </li>
          </ul>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <img
      class="fr user-img"
      v-if="subUserInfo.headImage"
      :src="subUserInfo.headImage"
      alt=""
    />
    <img class="fr user-img" v-if="!subUserInfo.headImage && theme !== 'wanghong'" src="@assets/userL.png" alt="" />
    <img class="fr user-img" v-if="!subUserInfo.headImage && theme === 'wanghong'" src="@assets/wanghong.png" alt="" />
    <el-dialog
      title=""
      :visible.sync="branchListShow"
      :modal="false"
      width="400px"
      center
    >
      <div class="account-dialog">
        <p class="branchTitle">切换机构</p>
        <ul class="ct">
          <li
            v-for="item in branchList"
            :key="item.id"
            @click="primaryBranch(item.id)"
            :class="{ primary: primary === item.id, mt10: true }"
          >
            <span v-show="branchId === item.id" class="current">当前</span
            >{{ item.name }}
          </li>
        </ul>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setBranch">选好了</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="isRealNameShow"
      :modal="false"
      width="464px"
      center
    >
      <div class="account-dialog">
        <p class="branchTitle">实名认证</p>
        <p class="qrcodetitle ct">请使用微信或手机浏览器扫描下方二维码，完成实名认证.</p>
        <div class="qrcode">
          <img src="@assets/qrcodeXia.png" class="xia" alt="">
          <img src="@assets/qrcodeShang.png" class="shang" alt="">
          <div id="qrcode"></div>
        </div>
        <p class="qrcodetitle ct">个人隐私信息安全保障中</p>
      </div>
    </el-dialog>
</div>
</template>
<script>
import Vue from 'vue';
import MD5 from 'js-md5'
import QRCode  from "qrcodejs2"
export default {
  props: {
    replacePage: {
      default: '/login',
      type: String,
    }
  },
  data() {
    return {
      domainUrl: null,
      showUser: false,
      newVal: null,
      branchList: [],
      branchListShow: false,
      primary: '',
      branchName: '',
      branchId: '',
      isRealName: null,
      isRealNameShow: false,
      QRCode: null,
      isPersonalCenter:false,
      isWhite: false,
      theme:'',
      isHiddenAccount:localStorage.getItem('isHiddenAccount')?localStorage.getItem('isHiddenAccount'):""
      // phoneNumber:''
    }
  },
  computed:{
    getTel(){
        const reg = /^(\d{3})\d{4}(\d{4})$/
        if (this.userInfo.phone) {
          return this.userInfo.phone.replace(reg, '$1****$2')
        } else {
          return '--'
        }
    },
    userInfo(){
      return this.$store.state.userInfo
    },
    // 不同机构
    subUserInfo(){
      return this.$store.state.subUserInfo
    }
  },
  created() {
    if (localStorage.getItem('token')) {
        window.addEventListener('setItem', this.watchStorage)
        this.getFindUserBranchList()
        // this.getAccountUserInfo()
        this.postIsRealNameAuthentication()
    }
    this.theme = localStorage.getItem('theme')
    if (!localStorage.getItem('theme') || localStorage.getItem('theme') === 'yingji' || localStorage.getItem('theme') === 'zhongzhi' || localStorage.getItem('theme') === 'shanghaijiaoda' ) {
      this.isWhite = true;
    }
    if(localStorage.getItem('theme')=='gongxin' || !localStorage.getItem('theme')){
        this.isPersonalCenter = true;
    }else{
      this.isPersonalCenter = false;
    }
  },
  destroyed(){
    window.removeEventListener('setItem', this.watchStorage);
  },
  mounted () {
    this.getSubUserInfo()
  },
  methods: {
    showPersonalCenter(){
      this.$router.push({
        path:'/personalCenter'
      })
    },
    toggleUser(show) {
      this.showUser = show;
    },
    setRealName () {
      if (this.isRealName) return
      this.isRealNameShow = !this.isRealNameShow
      this.$nextTick(() => {
        this.qrcode()
      })
    },
    async qrcode () {
      if (this.QRCode) return
      let domainArr = localStorage.getItem('domain')
      let domainUrl = domainArr && JSON.parse(domainArr).find(item => item.frontend_template_id == 4).domain
      this.QRCode = new QRCode('qrcode', {
        width: 154,
        height: 154,        // 高度
        // text:  `https://${domainUrl || 'hrss-stu.testing2.wdeduc.com'}/login?phone=${this.userInfo.phone}`,   // 二维码内容
        text:  `https://${domainUrl}/login?phone=${this.userInfo.phone}`,   // 二维码内容
        display: 'none'
        // render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        // background: '#f0f',   // 背景色
        // foreground: '#ff0'    // 前景色
      })
    },
    postIsRealNameAuthentication () {
      this.$api.monitor.postIsRealNameAuthentication({}).then(res => {
        const m = `${localStorage.getItem('token')}true`
        this.isRealName = res.data === MD5(m)

        localStorage.setItem('isRealName',this.isRealName)

      })
    },
    async watchStorage(){
      this.newVal=sessionStorage.getItem('signOutStorage')
      if(this.newVal=='signOut') {
        this.pathList()
      }
    },
    async signOut () {
      sessionStorage.setItem('expect', 'yes')
      this.newVal=sessionStorage.getItem('signOutStorage')
      if(this.newVal=='login' && this.$route.path==='/course/testPaper') {
        Vue.prototype.resetSetItem('signOutStorage', 'bullet')
      } else {
        this.pathList()
      }
    },
    async pathList() {
      const frontendConfig = localStorage.getItem('frontendConfig') ? JSON.parse(localStorage.getItem('frontendConfig')) : ''
      let path = frontendConfig ? frontendConfig.value : '';
      let theme = localStorage.getItem('theme') || '';
      const params = { token: localStorage.getItem('token') }
      await this.$api.usercenter.postAccounLogout({ params })
      sessionStorage.clear()
      localStorage.clear()
      if (
        path &&
        theme &&
        theme !== 'yingji'
      ) {
        // this.$router.push(path);
        if(theme=='shandongjiaotong' || theme=='shanghaijiaoda'){
          window.location.href = path;
        }else{
            // this.$router.push(path);
            // window.location.replace(window.location.origin+path);
            if (path.indexOf("/") == -1) {
              window.location.replace(window.location.origin+'/'+path);
            } else {
              window.location.replace(window.location.origin+path);
            }
        }

      } else {
        this.$router.push('/login');
      }

    },
    getAccountUserInfo () {
        this.$api.usercenter.getAccountUserInfo({}).then(res => {
          this.userInfo = res.data
        })
    },
    async getFindUserBranchList () {
       //todo
       if (!localStorage.getItem('branchList')) {
         await this.$api.usercenter.getFindUserBranchList({}).then(res => {
          if (res.data && res.data.length) {
            const branchList = res.data.filter(item => Object.prototype.toString.call(item) === '[object Object]').map(item => item).map(item => {
              return {
                name: item.name,
                id: item.branch,
                actor: item.actor,
                organ: item.organ,
                userId: item.userId,
                code: item.code
              }
            })
            this.branchList = branchList;
            if (branchList.length) {
              localStorage.setItem('branchList', JSON.stringify(branchList))
              localStorage.setItem('organ', branchList[0].organ)
              localStorage.setItem('actor', branchList[0].actor)
              localStorage.setItem('branch', branchList[0].id)
              localStorage.setItem('userId', branchList[0].userId)
              localStorage.setItem('code', branchList[0].code)
              this.$sensors.login(branchList[0].code)
              this.branchName = branchList[0].name;
              this.branchId = branchList[0].id;
            }
          }
          this.$emit('getBranchInfo', this.branchName);
        });
       }  else {
          this.branchList = JSON.parse(localStorage.getItem('branchList'))
          if (!this.branchList.length) return
          if (localStorage.getItem('branch')) {
            this.branchList.forEach(item => {
              if (item.id == localStorage.getItem('branch')) {
                this.branchId = item.id;
                this.branchName = item.name
              }
            })
          } else {
            this.branchId = this.branchList[0].id;
            this.branchName = this.branchList[0].name
          }
          this.$emit('getBranchInfo', this.branchName);
       }

    },
    showBranchList () {
        this.branchListShow = !this.branchListShow
        this.primary = ''
    },
    primaryBranch (branchId) {
        this.primary = branchId
    },
    async setBranch () {
        sessionStorage.setItem('index','/'+this.replacePage)
        if (!this.primary) {
          this.showBranchList()
          return
        }
        this.branchList.forEach(async item => {
          if (item.id == this.primary) {
            this.branchId = item.id
            this.branchName = item.name;
            localStorage.setItem('organ', item.organ)
            localStorage.setItem('actor', item.actor)
            localStorage.setItem('branch', item.id)
            localStorage.setItem('userId', item.userId)
            localStorage.setItem('code', item.code)
            if (this.replacePage.indexOf("/") == -1) {
              setTimeout(async () => {
                location.replace("/" + this.replacePage);
                // sessionStorage.setItem('dy-mode', '0')
              }, 500);
            } else {
              setTimeout(async () => {
                location.replace(this.replacePage);
                // sessionStorage.setItem('dy-mode', '0')
              }, 500);
            }

          }
        })

    },
    getSubUserInfo(){
      if (localStorage.getItem('token')) {
          this.$api.usercenter.findUser().then(res => {
          if(res.code == 0){
            this.$store.commit('saveSubUserInfo',res.data)
            localStorage.setItem('subUserInfo',JSON.stringify(res.data))
          }
        })
      }
    },
  }
}
</script>
<style lang="stylus" scoped>
@import './../../../../assets/css/account.styl';

.account-dropdown-menu {
  text-align: left;
  border-radius: 6px;
  margin-top: 22px !important;
  /deep/ .el-dropdown-menu__item--divided:before{
    margin 0px
  }
  .el-dropdown-menu__item {

    padding: 0px;
    &>ul{
      padding: 14px 20px;
    }
  }
}
.shjd-account .user-img{
  margin-top:11px
}
.shjd-account.white .user-dropdown .user-account{
  line-height 60px
}
.shjd-account .user-dropdown .user-account i{
  display flex;
  align-items center;
}
</style>
