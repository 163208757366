<template>
    <div class="IndustryAssess publicStylus">
        <div class="container">
            <div class="top">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/Industry-assess-banner.png" class="banner" alt="">
                <div class="content">
                    <div class="info">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/personnel-icon.png" class="icon" alt="">
                        <p>家政从业人员(敬请期待)</p>
                    </div>
                    <div class="info">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/company-icon.png" class="icon" alt="">
                        <p>家政企业(敬请期待)</p>
                    </div>
                    <div class="info">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/institutions-icon2.png" class="icon" alt="">
                        <p>行业机构(敬请期待)</p>
                    </div>
                </div>
                
            </div>
            <div class="bottom">
                <div class="item" v-for="item in list" :key="item.id">
                    <h2 class="title zh">{{item.zh}}</h2>
                    <p class="cn">{{item.cn}}</p>
                    <p class="line"></p>
                    <p class="content">{{item.content}}</p>
                </div>
            </div>
        </div>
        <footers></footers>
    </div>
</template>
<script>
import footers from './sdjz2Footer.vue'
export default {
    name:'IndustryAssess',
    components:{footers},
    data(){
        return{
            list:[
                
                {
                    id:1,
                    zh:'权威评估',
                    cn:'Authority to assess',
                    content:'同时，也支持第三方权威机构、专家、协会对家政服务人员和家政企业进行技能评估。'
                },
                {
                    id:2,
                    zh:'安全可信',
                    cn:'Safety credible',
                    content:'系统通过区块链技术，提供全流程、全链路、全要素数据保障，过程透明可追溯，保证结果的安全、准确、可信、权威。'
                },
                {
                    id:3,
                    zh:'便捷评定',
                    cn:'Convenient assessment',
                    content:'家政行业评定系统提供远程面试、在线评估等功能，赋能家政服务人员高效、便捷完成技能评定。'
                },
            ]
        }
    }
}
</script>
<style lang="stylus" scoped>
@import "../asset/css/publicStylus.styl"
</style>