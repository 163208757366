import { render, staticRenderFns } from "./practiceResult.vue?vue&type=template&id=405f940e&scoped=true&"
import script from "./practiceResult.vue?vue&type=script&lang=js&"
export * from "./practiceResult.vue?vue&type=script&lang=js&"
import style0 from "./practiceResult.vue?vue&type=style&index=0&id=405f940e&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "405f940e",
  null
  
)

export default component.exports