<template>
    <!-- 查看错题 -->
    <div>
        <div class="wrongTopic">
            <div class="backButton">
                <span @click="goBack">
                        <i class="back el-icon-arrow-left"></i>
                    返回</span>
            </div>
            <div class="wrongTopic-con">
                <div class="left">
                    <div class="title">
                        <span class="line"></span>
                        <h4 :title="examDetail.practiceName">{{examDetail.practiceName | ellipsis}}</h4>
                    </div>
                    <div class="testPaper">
                        <h2 class="tip">查看错题</h2>
                        <div v-for="(item, index) in questionsList" :key="index">
                            <div class="item" v-if="item.reply && !item.result">
                                <div class="stem">
                                    <h4>{{index + 1}}.【
                                        {{item.type == 1 ? '单选题' : item.type == 2 ? '多选题' : item.type == 3 ? '判断题' : item.type == 4 ? '高阶题' : ''}} / {{item.score}}分
                                        】<span v-html="item.title">{{item.title}}</span></h4>
                                    <li v-for="(item1, index1) in item.options" :key="index1">{{item1.optionNumber}}<span v-html="item1.optionContent">{{item1.optionContent}}</span></li>
                                    <div class="exam-answer">
                                        <span class="answer-label">正确答案：<span class="answer-num">{{item.rightAnswer}}</span></span>
                                        <span class="answer-label">你的答案：<span :class="!item.userAnswer?'answer-none':item.result==1?'correct':'error'">{{item.userAnswer||'未作答'}}</span></span>
                                    </div>
                                </div>
                                <div :class="['answerList', item.remark?'answer-bgc':'']">
                                    <div class="answer-label">答案解析：</div>
                                    <div class="answer-con" v-html="item.remark || '无'">{{item.remark||'无'}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="topCard">
                        <div class="scantron">
                            <div class="titles">练习分析</div>
                        </div>
                        <div class="chart">
                            <div class="exam-results-content1">
                                <!--交卷完成列表-->
                                <div class="exam-results-circle">
                                    <gauge :rate="examDetail.rightRate">
                                        <template v-slot:txt>
                                            <p class="exam-results-porgress" :style="`color:${numColor}`">{{examDetail.rightRate}}<span>%</span></p>
                                            <p class='exam-num-tips1'>正确率</p>
                                        </template>
                                    </gauge>
                                </div>
                                <div class="exam-results-ulbox">
                                    <div class="finish"><span></span>答对： {{examDetail.rightCount}}道</div>
                                    <div class="wrong"><span></span>答错： {{examDetail.errorCount}}道</div>
                                    <div class="none"><span></span>未答： {{examDetail.emptyCount}}道</div>
                                </div>
                            </div>
                            <div class="keepBtn" @click="keepPractice">继续练习</div>
                        </div>
                    </div>
                    <div class="bottomCard">
                        <div class="scantron">
                            <div class="titles">答题卡</div>
                            <div class="type">
                                <span class="finish"><span></span>答对</span>
                                <span class="wrong"><span></span>答错</span>
                                <span class="none"><span></span>未答</span>
                            </div>
                        </div>
                        <div :class="['answer-num', winH==768?'min-answer-num':'']">
                            <span v-for="(item, index) in questionsList" :key="index"
                                :class="['pointer',item.userAnswer?(item.result ? 'ydt' : 'bjt'):'wd']">
                                {{index + 1}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gauge from './gauge.vue'
export default {
    name: 'wrongTopic',
    show: true,
    components: {
        gauge
    },
    data() {
        return {
            practiceId: this.$route.query.practiceId,
            userPracticeId: this.$route.query.userPracticeId,
            examDetail: {},
            questionsList: [],
            winH: window.screen.height,
            numColor: ''
        };
    },
    filters: {
        ellipsis(msg){
            if(msg && msg.length>34){
            return msg.substr(0, 34)+'...'
            }
            return msg
        }
    },
    mounted() {},
    created() {
        this.findUserPaperInfo();
        this.returnButton();
    },
    methods: {
        // 浏览器回退按钮到训练营
        returnButton(){
            const that = this;
            if (this.$route.name == 'wrongTopic') {
                if (window.history && window.history.pushState) {
                    window.onpopstate = function() {
                        window.history.pushState('forward', null, '#');  
                        window.history.forward(1);
                        that.goBack();
                    }
                }
                window.history.pushState('forward', null, '#'); //在IE中必须得有这两行  
                window.history.forward(1);
            }
        },
        // 返回
        goBack() {
            if (this.$route.query.pathName=='/video') {
                this.$router.push({
                    path: this.$route.query.pathName,
                    query: {
                        trainId:this.$route.query.trainId,
                        courseId:this.$route.query.courseId,
                        detailId:this.$route.query.detailId,
                        studyProcess:this.$route.query.studyProcess,
                        courseName:this.$route.query.courseName,
                        taskType:this.$route.query.taskType || null,
                        backPathName: this.$route.query.backPathName
                    }
                })
            } else {
                this.$router.push({
                    path: this.$route.query.pathName,
                    query: {
                        taskType:this.$route.query.taskType || null,
                        id:this.$route.query.id,
                        taskId: this.$route.query.taskId,
                        backModule:this.$route.query.backMoudle
                    }
                })
            }
        },
        findUserPaperInfo() {
            this.$api.practice.findUserPaperInfo(this.userPracticeId).then((res) => {
                if(res.data){
                    this.examDetail = res.data;
                    this.questionsList = this.examDetail.examQuestionInfos;
                    this.examDetail.rightRate = Number(this.examDetail.rightRate);
                    if (this.examDetail.rightRate>=50) {
                        this.numColor = '#415FF6';
                    } else {
                        this.numColor = '#FFA132';
                    }
                }
            });
        },
        keepPractice() {
            if (this.$route.query.pathName=='/video') {
                this.$router.push({
                    path: '/practice',
                    query: {
                        practiceId: this.practiceId,
                        trainId:this.$route.query.trainId,
                        courseId:this.$route.query.courseId,
                        detailId:this.$route.query.detailId,
                        studyProcess:this.$route.query.studyProcess,
                        courseName:this.$route.query.courseName,
                        taskType:this.$route.query.taskType || null,
                        pathName:this.$route.query.pathName,
                        backPathName: this.$route.query.backPathName
                    }
                })
            } else {
                this.$router.push({
                    path: '/practice',
                    query: {
                        practiceId: this.practiceId,
                        pathName:this.$route.query.pathName,
                        taskType:this.$route.query.taskType || null,
                        id:this.$route.query.id,
                        taskId: this.$route.query.taskId,
                        backMoudle:this.$route.query.backMoudle
                    }
                })
            }
        },
    }
};
</script>
<style lang="stylus" scoped>
@import "../asset/css/wrongTopic.styl"
</style>