<!-- 
 * @description: 证书查询
 * @fileName: homepageContent2.vue 
 * @author:ninghf 
 * @date: 2021-07-07 14:57:22
!-->
<template>
    <div class="certificate-main">
         <h3>职业证书</h3>
         <div class="certificate-box">
             <div class="certificate-module certificate-module1">
                <!-- <router-link to="/learning">进入学习</router-link> -->
                <a href="https://sdjzpx.wdeduc.com/courseGoodsDetail?goodsId=666">进入学习</a>
                <a href="https://sdjzpx.wdeduc.com/examDetails?examId=1951&bussinessId=771&type=2&title=%E5%B1%85%E5%AE%B6%E4%B8%8A%E9%97%A8%E6%9C%8D%E5%8A%A1%E8%AF%81&pathName=%2Flearning%2FtaskDetail&backPath=%2Flearning&timeSlot=%E9%95%BF%E6%9C%9F%E6%9C%89%E6%95%88">参加考试</a>
                <router-link to="">证书查询</router-link>
             </div>
             <div class="certificate-module certificate-module2">
                <!-- <router-link to="/learning">进入学习</router-link> -->
                <a href="https://sdjzpx.wdeduc.com/courseGoodsDetail?goodsId=663">进入学习</a>
                <a href="https://sdjzpx.wdeduc.com/examDetails?examId=1951&bussinessId=769&type=2&title=%E5%85%BB%E8%80%81%E6%8A%A4%E7%90%86%E5%91%98%E4%BA%94%E7%BA%A7%EF%BC%88%E5%88%9D%E7%BA%A7%EF%BC%89&pathName=%2Flearning%2FtaskDetail&backPath=%2Flearning&timeSlot=%E9%95%BF%E6%9C%9F%E6%9C%89%E6%95%88">参加考试</a>
                <a href="http://edu.mohrss.gov.cn/" target="_blank">证书查询</a>
                <!-- <router-link to="/shandongCertificate">证书查询</router-link> -->
             </div>
             <div class="certificate-module certificate-module3">
                <!-- <router-link to="/learning">进入学习</router-link> -->
                <a href="https://sdjzpx.wdeduc.com/courseGoodsDetail?goodsId=664">进入学习</a>
                <a href="https://sdjzpx.wdeduc.com/examDetails?examId=1951&bussinessId=769&type=2&title=%E5%85%BB%E8%80%81%E6%8A%A4%E7%90%86%E5%91%98%E4%BA%94%E7%BA%A7%EF%BC%88%E5%88%9D%E7%BA%A7%EF%BC%89&pathName=%2Flearning%2FtaskDetail&backPath=%2Flearning&timeSlot=%E9%95%BF%E6%9C%9F%E6%9C%89%E6%95%88">参加考试</a>
                <!-- <router-link to="/shandongCertificate">证书查询</router-link> -->
                <a href="http://edu.mohrss.gov.cn/" target="_blank">证书查询</a>
             </div>
         </div>
    </div>
</template>
<script>
export default {
    name:'certificate'
}
</script>
<style scoped  lang="stylus">
.certificate-main
    padding-bottom:56px;
    h3
        margin-top: 56px;
        margin-bottom: 90px;
        font-size: 32px;
        font-weight: 600;
        line-height 40px
        color: #183B56;
        position relative
        text-align center
        &::after
            position: absolute;
            left: 50%;
            top: 70px;
            margin-left: -36px;
            content: '';
            width: 64px;
            height: 6px;
            background: #2D67FF;
            display: inline-block;
.certificate-box
    width:900px;
    margin: 0 auto;
    .certificate-module
        height 240px
        width 900px
        padding-top:170px;
        margin-bottom:24px;
        box-sizing border-box
        display flex
        justify-content: center;
        a
            display block;
            width 136px
            height 42px
            color:#fff;
            font-size 14px 
            line-height 42px;
            background #013C95
            text-align center
            position relative
            &:active:after
                position absolute
                top:0;
                bottom:0;
                left 0;
                right:0
                content:'';
                background rgba(0,0,0,.2)
                z-index 2
            &:nth-child(2)
                margin:0 80px
    .certificate-module1
        background:url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/certificate-1-home.png') no-repeat center center
        background-size cover
    .certificate-module2
        background:url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/certificate-2-home.png') no-repeat center center
        background-size cover
    .certificate-module3
        background:url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/certificate-3-home.png') no-repeat center center
        background-size cover
</style>