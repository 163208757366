<template>
    <div class="sdjzNav">
        <div class="nav-box">
            <div class="left-logo">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhaohu/px-head-logo.png" class="logo" alt="">
            </div>
            <ul class="nav-contaniner">
                <li v-for="(nav,index) in navList" :key="index"  @click="selectNav(nav)">
                    <p class="name" :class="{active:nav.url == activeNav}">{{nav.name}}</p>
                </li>
            </ul>
            <div class="right-box" v-if="!token">
                <p class="student-button button" @click="goPage('student')">学员登录</p>
                <p class="button admin-button" @click="goPage('main')">机构登录</p>
            </div>
            <template v-else>
                <account :replacePage="'zhaohupx_homepage'"/>
            </template>
        </div>

    </div>
</template>

<script>
import URL from '../../../../config/url';
import account from './account.vue'
export default {
    name:'zhaohupxNav',
    data(){
        return{
            navList:[],
            activeNav:'',
            token: '',
        }
    },
    components:{
        account
    },
    watch: {
        $route() {
        setTimeout(() => { //路由跳转
            sessionStorage.setItem('index',this.$route.meta.highlight)
            this.activeNav = this.$route.meta.highlight
            this.token = localStorage.getItem('token')
        }, 100)
        }
    },
    mounted(){
        setTimeout(() => { //刷新
            this.activeNav = sessionStorage.getItem('index') || this.activeNav
            this.token = localStorage.getItem('token')
        }, 100)
        this.getNavs()

    },
    methods:{
        goPage(type){
            let hostname = window.location.hostname.split('.')[2];

            if(type === 'student'){
                this.$router.push('login')
            }else {
                // 此时不是真正的生产环境
                let url = `https://hrss-main.${hostname}.wdeduc.com/entrance/login`;
                window.open(url, '_blank');
            }
        },
        // 获取导航头数据
        getNavs() {
            if (!this.navList.length) {
                this.$api.home.getMenuNew(URL.confUrl).then((res) => {
                    if (res.data.menuList) {
                        this.navList = res.data.menuList;
                        this.getActiveNav();
                    }
                })
            } else {
                this.getActiveNav();
            }
        },
        // 选中问题
        getActiveNav() {
            this.navList.forEach((d)  => {
                if (d.url === this.$route.path || this.$route.path.indexOf(d.url) !== -1) {
                    this.activeNav = d.url;
                }
            })
        },
        // 点击跳转
        selectNav(item) {
            console.log(item,'item')
            if (item.url.indexOf('http') === -1) {
                this.activeNav = item;
                this.$router.push({
                    path: item.url,
                });
            } else {
                window.open(item.url, 'newW');
            }
        },
    }
}
</script>

<style lang="stylus" scoped>
/deep/.top-account .user-dropdown .user-account .txt
    color #fff
/deep/.top-account .user-dropdown .user-account i
    color #fff
/deep/.top-account
    position relative
    bottom 10px
.sdjzNav
    width 100%
    height 80px
    background #316FFF
    position fixed
    top 0
    left 0
    z-index 999
    .nav-box
        width 1200px
        margin 0 auto
        display flex
        align-items:center;
        // justify-content space-between
        .left-logo
            height 80px
            margin-right 80px
        .logo
            width 180px
            margin-top 24px
    .nav-contaniner
        display flex
        margin-right 120px
        .name
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(255,255,255,.7)
            line-height: 22px
            cursor pointer
            width 120px
            height 80px
            line-height 80px
            text-align center
            &:hover
                color #fff
            &.active
                background #2E5AD1
                color #fff
    .right-box
        display flex
        .button
            width: 108px;
            height: 36px;
            background: #FFFFFF;
            border-radius: 20px;
            border: 1px solid #FFFFFF;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #316FFF;
            text-align center
            line-height 36px
            margin-left 20px
            cursor pointer
</style>
