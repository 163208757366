<template>
 <div class='projectList'>
    <h2 class="projectList-title">培训项目</h2>
    <div class="projectList-bgc"></div>
    <p class="projectList-con">以用户为核心的卓越企业培训体验</p>
    <div class="projectList-list">
        <div class="list" v-for="(item, index) in listCard" :key="index">
            <img class="list-img" :src="item.imgUrl" alt="">
            <h2 class="list-title">{{item.title}}</h2>
            <div class="list-line"></div>
            <p class="list-con">{{item.content}}</p>
            <div class="list-btn" @click="examine(index)">前往查看 ></div>
        </div>
    </div>
 </div>
</template>

<script>
export default {
    name:'projectList',
    props: {
    },
    data () {
        return {
            listCard: [
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/Oval1.png',
                    title: '“对标世界一流管理提升”项目',
                    content: '对标提升行动作为观察落实习总书记重要指示批示精神和党的十九届四中全会决策部署的重要举措，“对标世界一流管理提升”项目促进公司高端能力，创新管理方法的有效手段，提升企业管理水平和综合竞争力，打造国内一流管理人才'
                },
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/Oval2.png',
                    title: '企业高端党建课程项目',
                    content: '党的十九大明确提出“不断提高党的建设质量”的重要论断，不断提高国企党建质量，更好地应用于企业的生产经营管理，把党的政治优势、组织优势转化为国企核心竞争优势和发展优势，注重培养国企干部党建教育的政治性、专业性和实效性'
                },
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/Oval3.png',
                    title: '企业预防职务犯罪课程项目',
                    content: '透过对职务犯罪的学习，进一步加强政治理论的学习，树立正确的世界观、人生观、价值观，充分认识职务犯罪的危害性，认识到职务犯罪对国家、对自我都是有百害无一利。不断加强自身修养，提高专业业务水平，增强为人民服务的本领'
                },
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/Oval4.png',
                    title: '职业资格认证培训项目',
                    content: '中共中央、国务院《关于深化教育改革全国推进素质教育的决定》指出:“在全社会实行学历证书和职业资格证书并举的制度。培训的根本目的不仅仅是通过考试获得证书,更是对学院职业技能的培养,提高他们的职业匹配度'
                }
            ],
        };
    },

    methods: {
        examine (num) {
            this.$router.push({
                path:'/projectDetails',
                query:{
                    name: 'projectDetails',
                    index:num
                }
            })
        }
    },
    mounted(){
    },
}

</script>
<style lang='stylus' scoped>
    @import "../../asset/css/projectList.styl"
</style>