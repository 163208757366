<template>
 <div class='jiazheng-list'>
     <div class="family-list">
         <div class="title">
             <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/jiazheng/title-logo.png" alt="">
         </div>
         <div class="word">
             <p>广东省商务厅印发了《广东省商务厅关于施行家政服务员持证上岗制度的通知》粤商服务字[2020]14号</p>
             <p class="color">要求到2021年底，实现家政服务员全员持证上岗</p>
         </div>
         <div class="line"></div>
         <div class="remark">
             <!--<img src="../../asset/images/family-bg.png" alt="">-->
             <div class="serve">
                 <p>
                     <span>“居家上门服务证”</span>
                     是广东省“南粤家政工程”的一个重要部分，是依托互联网、
                     大数据等信息化手段
                 </p>
                 <p>构建从业人员“持证上门服务”、服务机构“公司化管理”、政府监管“多方联动”的家政服务管理新体系</p>

             </div>
         </div>
     </div>
     <div class="train">
         <div class="container">
             <div class="title">
                 <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/jiazheng/train-title.png" alt="">
             </div>
             <div class="box">
                 <div class="left">
                     <div class="tip">
                         <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/jiazheng/title-01.png" alt="">
                         <div class="content">
                             <p>课程紧扣广东地区家政行业实际，以科学的理念为基</p>
                             <p>础，以“南粤家政工程”实践为指导，针对家政服务从</p>
                             <p>业人员职业素养知识进行全方位培训。</p>
                         </div>
                     </div>
                     <div class="tip">
                         <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/jiazheng/title-02.png" alt="">
                         <div class="content">
                             <p>专业讲师与图文配合演示，画面生动； </p>
                             <p>案例讲解/情景互动等教学方式授课，内容丰富； </p>
                             <p>教师讲解通俗易懂，专业实用； </p>
                             <p>高清视频支持多终端学习，方便易学。</p>
                         </div>
                     </div>
                 </div>
                 <div class="right">
                    <div class="video">
                        <video
                                ref="vueMiniPlayer"
                                height="364"
                                width="646"
                                id="myvideo"
                                src="https://platform-vod.wdcloudnet.com/customerTrans/1fdb45d4f3e10788ee9d2b3cc3b95ff9/18aa93c9-176f615ffa2-0005-cd1a-d1c-c3eb7.mp4"
                                :controls="controls"
                                poster="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/jiazheng/video-bg.png"
                        />
                        <div class="play" :class="{ hide: isPlay }" @click="playClick()"></div>
                    </div>
                 </div>
             </div>
             <div class="blue-bg"></div>
             <div class="study" @click="handleStudy">
                 立即学习 >
             </div>

         </div>
     </div>
     <div class="intro">
         <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/jiazheng/intro.png" alt="">
     </div>
     <div class="jingpin">
         <div class="title">
             <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/jiazheng/course-title.png" alt="">
         </div>
         <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/jiazheng/course-img.png" alt="">
     </div>
     <div class="jingpin">
         <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/jiazheng/jingpin.png" alt="">
     </div>
     <div class="special">
         <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/jiazheng/special.png" alt="">
     </div>
     <div class="step">
         <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/jiazheng/step.png" alt="">
     </div>

 </div>
</template>

<script>
export default {
    name:'familyList',
    props: {
    },
    data () {
        return {
            domKey:"",
            isPlay: false,
            controls: false,
        };
    },
    methods: {
        playClick(){
            this.isPlay = !this.isPlay;
            this.$refs.vueMiniPlayer.play();
            this.controls = true;
        },
        handleStudy(){
            if (localStorage.getItem('token')) {
                // 跳转学习中心
                this.$api.home.getJiazhengExam().then((res) =>  {
                    if(res.success){
                        if(res.data.studyTrainId){
                            // sessionStorage.setItem('dy-mode', '1');
                            sessionStorage.setItem('jzTrainInfo', JSON.stringify(res.data));
                            this.$router.push(`/course/catalog?id=${res.data.studyTrainId}&dymode=1&taskType=1`);
                        }else {
                            this.$message.warning('账号未开通课程');
                        }

                    }
                })
            }else{
                this.$router.push({
                    path: '/login',
                    query: {
                        name: 'jiazheng_homepage'
                    }
                })
            }
        }
    },
}

</script>
<style lang='stylus' scoped>
    @import "../../asset/css/family.styl"
</style>
