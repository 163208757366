<template>
    <div class="BusinessManagement publicStylus">
        <div class="container">
            <div class="top">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/enterprise-banner.png" class="banner" alt="">
                <div class="content">
                    <div class="info" @click="goPage('pc')">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/pc-icon.png" class="icon" alt="">
                        <p>企业PC端</p>
                    </div>
                    <div class="info" @click="goPage('h5')">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/phone-icon.png" class="icon" alt="">
                        <p>企业移动端</p>
                    </div>
                    <div class="info" @click="codeShow = !codeShow">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/program-icon.png" class="icon" alt="">
                        <p>企业小程序</p>
                    </div>
                    <div class="code-box" v-show="codeShow">
                        <p class="close" @click="codeShow = false"><i class="el-icon-close"></i></p>
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/Small-program-code.png" class="code" alt="">
                        <p class="text">扫一扫，进入家政商城(实例)</p>
                    </div>
                </div>
                
            </div>
            <div class="bottom">
                <div class="item" v-for="item in list" :key="item.id">
                    <h2 class="title zh">{{item.zh}}</h2>
                    <p class="cn">{{item.cn}}</p>
                    <p class="line"></p>
                    <p class="content">{{item.content}}</p>
                </div>
            </div>
        </div>
        <footers></footers>
    </div>
</template>
<script>
import footers from './sdjz2Footer.vue'
export default {
    name:'zhaohuBusinessManagement',
    components:{footers},
    data(){
        return{
            codeShow:false,
            list:[
                {
                    id:1,
                    zh:'企业管理',
                    cn:'The enterprise management',
                    content:'帮助就业服务企业实现信用管理、服务人员管理、服务产品管理、服务交易管理、培训管理等各环节的信息化、自动化管理。'
                },
                {
                    id:2,
                    zh:'增值服务',
                    cn:'Value-added services',
                    content:'营销推广、商业保险、三方远程面试、热文推广、微店铺、宣传海报、店铺名片、招聘家政员、电子网签等。'
                },
            ]
        }
    },
    methods:{
        goPage(command){
            console.log(command,'----')
            if(command === 'pc'){
                window.open('http://qiye.jiafuyun.com')
            }else {
                this.$router.push('/zhaohuManagement')
            }
        }
    }
}
</script>
<style lang="stylus" scoped>
@import "../asset/css/publicStylus.styl"
</style>