<template>
    <div class="shjdNav-page-header clearfix">
        <div class="page-header-top">
            <div class="logo-left">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shjd/logoTop.png" alt="">
            </div>
            <div class="phone-right">
                <span class="call">咨询热线：</span>
                <span class="phone">021-62112531/ 13127999738</span>
            </div>
        </div>
        <div class="page-header-nav ">
            <div class="nav-name clearfix">
                <div class="nav-name-ul fl">
                    <div class="nav-name-ul-li"
                        v-for="(item, index) in navName"
                        :key="index"
                    >
                        <span
                            :class="isSelect === item ? 'active' : ''"
                            @click="selectNav(item)"
                        >
                            {{item.name}}
                        </span>
                    </div>
                </div>
                <div class="fr">
                    <div v-if="!token" class="un-login" @click="jumpLogin(1)">
                        登录 / 注册
                    </div>
                    <template v-else>
                        <account :replacePage="replacePage"/>
                    </template>
                </div>
            </div>
        </div>
        <div class="fixed-link">
            <div class="fixed-link-top" @click="backTop">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/arrow-top.png" alt="">
                <el-button type="text">回到顶部</el-button>
            </div>
            <div class="fixed-link-bottom">
                <div class="item" @click="qimoChatClick()">
                    <img src="http://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shjd/link.png" alt="">
                    <p>在线咨询</p>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import URL from '../../../../config/url';
import account from './account.vue'
export default {
    name: 'junminNav',
    components:{
        account
    },
    data() {
        return {
            isservice:false,
            navName: [],
            isSelect: null,
            token: '',
            showOnTip1:false,
            showOnTip2:false,
            replacePage:'/login'
        }
    },
    watch: {
        $route() {
            this.getNavs();
        }
    },
    created() {
        this.getNavs();
        this.token = localStorage.getItem('token');
        const frontendConfig = localStorage.getItem('frontendConfig') ? JSON.parse(localStorage.getItem('frontendConfig')) : ''
        let path = frontendConfig ? frontendConfig.value : '';
        this.replacePage = path;
    },
    methods: {
        /**
         *@functionName:
         *@description:在线咨询
         *@date: 2021-05-11 10:18:31
        */
        qimoChatClick(){
            window.qimoChatClick()
        },
        backTop(){
          var timer = setInterval(function(){
            let osTop = document.documentElement.scrollTop || document.body.scrollTop;
            let ispeed = Math.floor(-osTop / 5);
            document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
            this.isTop = true;
            if(osTop === 0){
              clearInterval(timer);
            }
          },30)
        },
        getNavs() {
            if (this.navName.length===0) {
                this.$api.home.getMenuNew(URL.confUrl).then((res) => {
                    if (res.data.menuList) {
                        this.navName = res.data.menuList;
                        if(this.navName.length>0){
                            this.isSelect=this.navName[0];
                        }
                        this.getActiveNav();
                    }
                })
            } else {
                this.getActiveNav();
            }
        },
        getActiveNav() {
            this.navName.forEach((d)  => {
                if (d.url === this.$route.path || this.$route.path.indexOf(d.url) !== -1) {
                    this.isSelect = d;
                }
            })
        },
        selectNav(item) {
                if (item.url.indexOf('http') === -1) {
                    this.isSelect = item;
                        this.$router.push({
                            path: item.url,
                        });
                } else {
                    window.open(item.url, 'newW');
                }
        },
        jumpLogin(type) {
            if(type===1){
                this.$router.push({
                    name: 'login'
                })
            }else{
                const hostname = window.location.hostname;
                if(hostname==='qdjm.saas-pc.testing3.wdeduc.com'){
                    window.open('http://qdjm.saas-main.testing3.wdeduc.com/entrance/register');
                }else if(hostname==='qdjm.saas-pc.fat.wdeduc.com'){
                    window.open('http://qdjm.saas-main.fat.wdeduc.com/entrance/register');
                }else if(hostname==='qdjm.saas-pc.uat.wdeduc.com'){
                    window.open('http://qdjm.saas-main.uat.wdeduc.com/entrance/register');
                }else if(hostname==='qdjm.wdeduc.com'){
                    window.open('https://zpdwqdjm.wdeduc.com/entrance/register');
                }else{
                    window.open('http://qdjm.saas-main.testing3.wdeduc.com/entrance/register');
                }
                // window.open('http://qdjm.saas-main.testing3.wdeduc.com/entrance/login');

            }

        },
    }
}
</script>
<style lang="stylus" scoped >
.shjdNav-page-header {
    cursor: pointer;
    /* position: fixed; */
    width: 100%;
    /* z-index: 666; */
    background: #fff;
}
.shjdNav-page-header .page-header-top {
    width:1200px;
    height:80px;
    margin: 0px auto;
    padding-right:10px;
    box-sizing:border-box;
    display flex;
    align-items :center;
    justify-content:space-between;
    .logo-left{
        width: 123px;
        height: 40px;
        img{
            width 100%
            height 100%
        }
    }
    .phone-right{
        .call{
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 26px;
        }
        .phone{
            font-size: 24px;
            font-family: Bebas;
            color: #C50F23;
            line-height: 32px;
        }
    }
}
.shjdNav-page-header .page-header-nav {
    width: 100%;
    background-color: #C50F23;
    height: 60px;
}
.shjdNav-page-header .page-header-nav .nav-name {
    margin: 0px auto;
}
.shjdNav-page-header .page-header-nav .nav-name {
    width: 1200px;
}
.shjdNav-page-header .page-header-nav .nav-name .nav-name-ul-li {
    display: inline-block;
    vertical-align: top;
    line-height: 60px;
    height: 60px;
    position: relative;

}

.shjdNav-page-header .page-header-nav .nav-name .nav-name-ul-li ul{
    position: absolute;
    left: 5px;
    top: 56px;
    width: 113px;
    border-radius: 7px;
    display: none;
    z-index: 5;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.22);
}
.shjdNav-page-header .page-header-nav .nav-name .nav-name-ul-li:hover ul{
    display: block;
}
.shjdNav-page-header .page-header-nav .nav-name .nav-name-ul-li ul li{
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4A4A4A;
    background: #FFFFFF;
}
.shjdNav-page-header .page-header-nav .nav-name .nav-name-ul-li ul li:hover{
    // background: #890D18;
    color: #FFFFFF;
}
.shjdNav-page-header .page-header-nav .nav-name .nav-name-ul-li ul li:first-child{
    border-radius: 7px 7px 0px 0px;
    margin-top: 4px;
}
.shjdNav-page-header .page-header-nav .nav-name .nav-name-ul-li ul li:last-child{
    border-radius: 0px 0px 7px 7px;
}
.shjdNav-page-header .page-header-nav .nav-name .nav-name-ul-li > span {
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    display: inline-block;
    width: 140px;
    text-align: center;
    cursor: pointer;
}
.shjdNav-page-header .page-header-nav .nav-name .nav-name-ul-li:hover {
    background-color: #890D18;;
}
.shjdNav-page-header .page-header-nav .nav-name .active {
    background: #890D18;
}
.shjdNav-page-header .page-header-nav .un-login {
    width: 120px;
    height: 40px;
    background: #C50F23;
    border-radius: 4px;
    border: 1px solid #FFFFFF;
    cursor: pointer;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    margin-top:10px;
    line-height: 40px;
    text-align: center;
    float:right;
}
.un-logosuccss{
    color: #FFFFFF;
    position: relative;
    height: 56px;
}
.un-logosuccss-head{
    margin-top: 12px;
    border-radius: 50%;
}
.un-logosuccss-main{
    margin: 7px 14px 0 11px;
}

.un-logosuccss-main p:first-child{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
}
.un-logosuccss-main p.headTop{
    line-height: 40px;
}
.un-logosuccss-main .userIdentity{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 58px;
    max-width: 130px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 2px;
    line-height: 20px;
    text-align: center;
    padding: 0 6px 0 4px;
    box-sizing: border-box;
}
.un-logosuccss-jt{
    margin-top: 25px;
}

.junmin-dropdown{
    position: absolute;
    right: 0px;
    top: 56px;
    width: 113px;
    border-radius: 7px;
    display: none;
    z-index: 4;
}
.un-logosuccss:hover .junmin-dropdown{
    display: block;
}
.junmin-dropdown li{
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4A4A4A;
    background: #FFFFFF;
}
.junmin-dropdown li:hover{
    background: #1972FF;
    color: #FFFFFF;
}
.junmin-dropdown li:first-child{
    border-radius: 7px 7px 0px 0px;
    margin-top: 4px;
}
.junmin-dropdown li:last-child{
    border-radius: 0px 0px 7px 7px;
}
.fixed-link
    position:fixed;
    z-index:4;
    bottom:140px;
    right:10px
    background:#C50F23;
    border-radius 8px
    font-size:16px;
    padding:4px;
    width:104px;
    box-sizing:border-box;
    text-align center;
    .fixed-link-top
        display:flex;
        flex-direction:column;
        align-items: center;
        padding:14px 0;
        p
            color:#fff;
        img
            width:25px;
            margin-bottom:12px;
        button
            padding:0;
            /deep/span
                color:#fff;
                font-size:16px;
    .fixed-link-bottom
        background #fff;
        color #333333
        line-height:33px;
        border-radius:8px;
        padding:0 12px;
        div.item
            padding:20px 0;
            display: flex;
            flex-direction: column;
            cursor: pointer;
            &:nth-child(1)
                border-bottom:1px solid #F6F6F6;
            p
                line-height:16px;
        img
            width:30px
            margin:0 auto 12px;
.fixed-link-bottom-service
    width: 485px;
    height:746px;
    position: fixed;
    bottom: 20px;
    right: 124px;
    z-index: 999;
.online-link
    width:268px;
    height:275px;
    box-sizing:border-box;
    background:#fff;
    position:fixed;
    right:130px;
    bottom:140px;
    text-align:center;
    border-radius:8px;
    padding:50px 0 28px;
    box-shadow: 0px 0px 8px 2px rgba(3, 3, 3, 0.06);
    border: 1px solid rgba(220, 215, 239, 0.33);
    z-index:6;
    &::after
        position: absolute;
        right: -6px;
        top:50%;
        transform:translateY(-50%);
        content: '';
        width: 14px;
        height: 14px;
        background: #FFF;
        transform: rotate(45deg);
        z-index:5;
        box-shadow: 0px -2px 2px rgba(0, 0, 0, .05);
    img
        width:140px;
        display:block;
        margin:0 auto 20px;
    div p
        color:#666;
        font-size:14px;
        line-height:20px;
</style>
