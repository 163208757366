<template>
  <div class="courseDetails">
      <div class="back" v-if="!theme" @click="goBack">
          <img src="./../../../assets/images/fanhuidenglu2x.png" class="icon" alt="">
          返回
      </div>
    <div class="courseTraining"  v-if="!showNoData">
      <div class="train-info">
          <div>
            <span class="train-name">{{trainInfo.name}}</span>
            <span class="train-progress" v-if="courseCount>0">已完成 {{trainProcess}}%</span>
        </div>
        <div>
            <span
                class="train-date"
                v-if="trainInfo.trainStartTime&&trainInfo.trainEndTime || $route.query.validity === '' || $route.query.validity === 'true'"
            >{{trainInfo.trainStartTime | dataFormat}}至{{trainInfo.trainEndTime| dataFormat}}</span>
            <span :class="isClickCertificate?'diploma-btn':'diploma-btn certidisabled'" v-if="trainInfo.isConfigCert== 1"  @click="checkCertificate('businessId',businessId,'btn','train')">查看证书</span>
        </div>
      </div>
      <section>
          <aside>
              <p v-if="courseCount>0" class="course-num">共有{{courseCount}}门课程</p>
              <ul :class="courseCount ? 'training-list-height' : 'training-list-nocourse'" class="training-list">
                  <el-scrollbar style="height:100%">
                    <div class="list-li">
                    <el-tooltip
                        class="item"
                        effect="dark"
                        placement="right-start"
                        v-for="el in trainList" :key="el.id"
                        :content="el.label"
                    >
                        <li @click="changeInfo(el)" :class="[active===el.id && el.type===1?'training-list-active':active===el.id && el.type===2?'training-exam-active':'', el.type===2?'exam':'']">
                            <img class="img" :src="el.pic" alt="" v-if="el.type===1">
                            <img v-if="el.type===2" :src="el.pic?el.pic : require('../asset/4.png')" alt="" >
                            <span
                                v-if="el.type===2&&el.isPassed!==2"
                                class="exam-tip"
                                :class="{'nopass': el.isPassed === 0, 'pass': el.isPassed === 1, 'noanswer': el.isPassed === 3}"
                            >{{el.isPassed | ispassTip}}</span>
                        </li>
                    </el-tooltip>
                    </div>
                  </el-scrollbar>
              </ul>
          </aside>
          <article v-if="examType == 1">
              <div class="course-title">
                <h2>
                    <span class="course-name" v-if="currentCourse.courseStatus != 8">{{currentCourse.label}}</span>
                    <span class="termination" v-else><span>******</span><span>很抱歉，此内容已被强制终止</span></span>
                    <span class="course-progress">已完成 {{currentCourse.studyProcess}}%</span>
                    <span

                        class="course-score"
                        @click="playboxscoreclick"
                    >{{myScore>0?'已评分':'未评分'}}</span>
                    <span class="course-info" :class="{active : currentCourse.courseStatus === 8 }"  @click="getcourseInfo(currentCourse.courseStatus)">课程简介</span>
                </h2>
                <div class="evaluation-box">
                    <div class="evaluation _clear">
                        <p v-for="itemcount1 in count" :key="itemcount1">
                            <img src="../asset/pfwjx.png" alt />
                        </p>
                        <p v-for="itemcount2 in 5-count" :key="itemcount2+count">
                            <img src="../asset/pfwjx1.png" alt />
                        </p>
                        <p>{{count1.toFixed(1)}}分</p>
                    </div>
                </div>
            </div>
            <div class="termination-icon" v-if="currentCourse.courseStatus === 8">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/zhongzhi-icon.png" alt="">
                <p class="text">课程暂时无法学习呦～</p>
            </div>
            <div v-else>
                <courseItem  :itemList="catalogList" :courseExpireStatus='currentCourse.courseExpireStatus' :courseName="currentCourse.label" :isFaceRecognize="trainInfo.isFaceRecognize"></courseItem>
                <p class="course-all">-已加载全部内容-</p>
            </div>
          </article>
          <article v-if="examType == 2" class="no-course">
              <h2 class="no-course-title">
                  <span class="course-name">{{examInfo.name}}</span>
                  <span class="course-type">{{examInfo.type === 1?'模拟考试':'正式考试'}}</span>
              </h2>
              <p class="exam-details-ico" v-if="examInfo.result!==-1">
                <img
                    :src="examInfo.result | examresult"
                />
              </p>
              <div class="exam-card">
                <div class="exam-list">
                  <div class="card-list">
                    <p class="fraction">{{examInfo.totalQuestionNum || 0}}</p>
                    <p class="title">总题数(道)</p>
                    <img src="../asset/zts.png" alt="">
                  </div>
                  <div class="card-list zfz">
                    <p class="fraction">{{examInfo.totalScore || 0}}</p>
                    <p class="title">总分值(分)</p>
                    <img src="../asset/zfz.png" alt="">
                  </div>
                  <div class="card-list jgf">
                    <p class="fraction">{{examInfo.passingScore || 0}}</p>
                    <p class="title">及格线(分)</p>
                    <img src="../asset/jgf.png" alt="">
                  </div>
                  <div class="card-list kssc">
                    <p class="fraction">{{examInfo.duration || 0}}</p>
                    <p class="title">考试时长(分钟)</p>
                    <img src="../asset/kssc.png" alt="">
                  </div>
                  <div class="left"></div>
                  <div class="right"></div>
                </div>
                <div class="exam-date">
                  <span class="title">考试日期：</span>
                  <span class="time" v-if="examInfo.examStartDate && examInfo.examEndDate && examInfo.isLimitTime">{{examInfo.examStartDate.slice(0, -3)}}至{{examInfo.examEndDate.slice(0, -3)}}</span>
                  <span class="time" v-if="!examInfo.isLimitTime">长期有效</span>
                  <span class="over" v-show="examInfo.ifEnd === 1">已结束</span>
                  <div class="exam-details-footer" v-if="examInfo.ifEnd !== 1">
                    <div class="exam-details-exam" :class="examInfo.showDebugButton ? 'exam-details-width' : ''">
                      <p v-if="examInfo.ifStart === 0" class="exam-details-btn nostart"><span>考试尚未开始</span></p>
                      <p v-if="examInfo.ifStart === 1 && examInfo.totalExamNum==0" class="exam-details-btn" @click="realName">
                        <span>进入考场</span>
                      </p>
                      <p v-if="examInfo.ifStart === 1 && examInfo.leftExamNum === 0 && examInfo.totalExamNum!==0" class="exam-details-btn nostart">
                          <span>已无考试剩余次数</span>
                      </p>
                      <p v-if="examInfo.ifStart === 1 && examInfo.leftExamNum>0 && examInfo.totalExamNum!==0" class="exam-details-btn" @click="realName">
                          <span>
                            进入考场
                            <!-- <label>(考试剩余机会：{{examInfo.leftExamNum}}次/ 共{{examInfo.totalExamNum}}次)</label> -->
                          </span>
                      </p>
                      <p v-if="examInfo.totalExamNum>0"
                        class="examChance"
                      >考试剩余机会：{{examInfo.leftExamNum}}次/ 共{{examInfo.totalExamNum}}次</p>
                    </div>
                    <div class="exam-details-debug" v-if="examInfo.showDebugButton">
                      <p  @click="commissionEqu" class="exam-details-debugbtn" >
                        <span>调试设备</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="history-list">
                <div class="title">
                  <h2><img src="../asset/history.png" alt="">历史考试记录</h2>
                  <el-tooltip placement="left" effect="dark" popper-class="atooltip">
                    <div slot="content" class="con">异常退出的考试，考试记录会延迟显示，请耐心等待。</div>
                    <i class="el-icon-info tip-icon"></i>
                  </el-tooltip>
                </div>
                <ul class="list-item">
                  <el-scrollbar style="height:100%">
                    <div v-if="!noList">
                        <li v-for="(item, index) in historyList" :key="item.id">
                        <div class="item">
                            <span class="num">{{index | formartIndex}}</span>
                            <span class="date">{{item.examStartTime}}</span>
                            <span class="time">用时：{{item.useDuration}}</span>
                            <span class="score" v-if="item.scoreAuth">
                              得分：
                              <i v-if="item.paperType == 2 && !item.isReadOver"><span class="modify">阅卷中</span></i>
                              <i v-else><span class="score-num">{{item.score}}</span>分</i>
                              <span class="invalid" v-if="(item.paperType == 1 && item.invalidType) || (item.paperType == 2 && item.isReadOver && item.invalidType)">（异常）</span>
                            </span>
                            <span class="score" v-if="!item.scoreAuth && item.paperType == 2 && !item.isReadOver">
                              得分：
                              <i><span class="modify">阅卷中</span></i>
                            </span>
                        </div>
                        <div class="btn" @click="viewDetails(item)">查看详情</div>
                        </li>
                    </div>
                    <div v-else class="list-none">
                        <img src="../asset/nodata.png" alt="">
                        <p>暂无考试记录，赶快去考试吧～</p>
                    </div>
                  </el-scrollbar>
                </ul>
              </div>
          </article>
          <article  v-if="noTrain" class="no-date">
            <div class="no-date-img">
              <img src="../asset/分组 4.png" alt="">
              <p>暂无课程</p>
            </div>
          </article>
      </section>
      <dialogInfo :info="info" :currentCourse="currentCourse" :nameType='"课程简介："' @infoShow="infoShow" :type="2" :description="description"></dialogInfo>
      <dialog-commission-equ  :urlH5Str="urlH5Str" :isShowDialog="isShowDialog" :currentCourse="currentCourse" :examID="String(courseId)" @equShow="equShow"></dialog-commission-equ>
    </div>
    <div class="noData" v-if="showNoData">
       <div class="noData-con">
          <img src="../asset/nodata.png" alt="">
          <p>暂无数据</p>
       </div>
    </div>
    <footers v-if="theme==='gongxin'"/>
    <auth-guide :ModalVisbileAuth="authGuideModalVisbile" @handleCloseAuth="handleCloseAuth" :type="2" :wechatErInfo="previewInfoImg"></auth-guide>
        <preview-certificate
            :previewModalVisbile="previewModalVisbile"
            @handleClosePreview="handleClosePreview"
            @downLoadCer="downLoadCer('businessId',businessId,previewInfoImg.userName,previewInfoImg.name)"
            :isDownLoad="isDownLoad"
            :previewUrl="previewUrl">
        </preview-certificate>
    <playboxscore v-if="playboxscore" :myScore="myScore" :issubmit="myScore>0?false:true"></playboxscore>
    <facedialog v-if="verification" :address="verificationAddress" :failAddress="verificationFailAddress" :pauseFrame="true" :type="studyType"></facedialog>
    <realName v-if="realNameDialog"></realName>
    <el-dialog
      class="warning-dialog"
      :visible.sync="warningdialogVisible"
      width="400px"
    >
      <img src="../asset/tipimg.png" class="icon-img" alt="">
      <p class="titles">提示</p>
      <p class="text">{{dialogCon}}</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="warningdialogVisible = false"
          >知道了</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import dialogInfo from './dialogInfo.vue';
import dialogCommissionEqu from './dialogCommissionEqu'
import footers from '../../gongxin/block/front/footers'
import cercertificateIsShowStatus from "@/utils/certificateMixin";
import facedialog from '@/layout/facedialog.vue'
import realName from "@/layout/realName.vue";
import md5 from "js-md5";
import DialogCommissionEqu from './dialogCommissionEqu.vue';
export default {
  name: 'coursecatalog',
  components: {
    dialogCommissionEqu,
    dialogInfo,
    footers,
    facedialog,
    realName,
    DialogCommissionEqu
  },
  data () {
    return {
        isDownLoad: true,
        myScore: 0,
        count: 0,
        count1:0,
        playboxscore: false,
        activeNames: [0],
        trainInfo: {},
        courseList: [],
        courseCount: null,
        trainList: [],
        currentCourse: {},
        courseId: "",
        catalogList: [],
        active: "",
        info: false,
        isShowDialog: false,
        description: "",
        trainProcess: "",
        examType: null,
        trainItemExamId: "",
        examInfo:{},// 新接口数据
        historyList: [],
        noList: false,
        noTrain: false,
        showNoData: false,
        theme: "",
        img1: require("../../exam/asset/images/ysjg.png"),
        img2: require("../../exam/asset/images/ysbjg.png"),
        realNameDialog: false,
        verification: false,
        verificationAddress: {},
        verificationFailAddress: {},
        closeRealName: null,
        isFaceRecogBeforeExam: '',
        isFaceRecogDuringExam: '',
        isFaceRecogCommitExam: '',
        warningdialogVisible: false,
        dialogCon: '',// 弹框提示文案
        urlH5Str:'',
        studyType: null
    };
  },
  mixins: [cercertificateIsShowStatus],
  computed: {},
  filters: {
    examresult(val) {
      if(val === 0){
				return require('../asset/bujige@2x.png');
			} else if(val === 1){
				return require('../asset/jige@2x.png');
			} else if(val === 2){
				return require('../asset/weizuoda@2x.png');
			}
    },
		isPassedStatus(val) {
			if(val === 0){
				return require('../asset/bujige@2x.png');
			} else if(val === 1){
				return require('../asset/jige@2x.png');
			} else if(val === 3){
				return require('../asset/weizuoda@2x.png');
			}
		},
		ispassTip(val) {
			if(val === 0){
				return '不及格';
			} else if(val === 1){
				return '已及格';
			} else if(val === 3){
				return '未作答';
			}
		},
    dataFormat(msg) {
      if (msg) {
        const date = new Date(msg)
        const m = date.getMonth()+1;
        return date.getFullYear()+'.'+m+'.'+date.getDate();
      }
    },
    ellipsis(msg){
      if(msg.length>37){
        return msg+'...'
      }
      return msg
    },
    formartIndex(index) {
      if (index+1<10) {
        return '0'+(index+1)
      }
      return index+1
    }
  },
  watch: {
    '$route.query.id': function () {
      this.$nextTick(()=>{
        this.findTrainBasicInfoById();
      })
    },
    'closeRealName'(val){
      if (val==false) {
        this.realNameDialog = false;
      }
    },
  },
  created () {
    this.businessId = this.$route.query.id ? this.$route.query.id : this.$route.query.taskId ;
    this.theme = localStorage.getItem('theme');
    this.findTrainBasicInfoById();
  },
  methods: {
      goBack(){
          if(this.$route.query.backPath){
              sessionStorage.setItem('userCenterCurrentTab','我的学习')
              this.$router.push({
                  path:this.$route.query.backPath,
                  query:{
                      goodsId:this.$route.query.goodsId,
                      backModule:this.$route.query.backMoudle || this.$route.query.backModule
                  }
              })
          }else {
              this.$router.push({
                    path:'/learning',
                    query:{
                        backModule:this.$route.query.backMoudle || this.$route.query.backModule
                    }
                })
          }
      },
      playboxscoreclick(){
            if(this.currentCourse.studyProcess>=30){
                this.playboxscore=true;
            }else{
                this.$message.error('课程学习进度未达到30%，不能进行评价')
            }
        },
        getcourseInfo(status){
            if(status === 8){
                this.$message.error('很抱歉，此内容已被强制终止')
            }else {
                this.info = true;
            }
        },
        clickclose() {
            this.playboxscore = false;
            this.$forceUpdate;
        },
        async getEvaluationInfo() {
            let params = {
                courseId: this.courseId
            };
            await this.$api.course.getEvaluationInfo({ params }).then(res => {
                this.count1=res.data.aveScore?res.data.aveScore:5;
                this.count=res.data.aveScore?Math.ceil(res.data.aveScore):5;
                this.myScore=res.data.myScore?Math.ceil(res.data.myScore):0;
                this.$forceUpdate;
            });
        },
        async evaluateCourse(count) {
            const data = {
                courseId: this.courseId,
                score: count
            };
            await this.$api.course.evaluateCourse({ data }).then(() => {
                this.playboxscore = false;
                this.getEvaluationInfo();
            });
        },
    findTrainBasicInfoById(){
      this.$api.training.findTrainBasicInfoById({params:{id: this.businessId}}).then(({data})=>{
        this.trainInfo = data;
        if (!this.trainInfo) {
          this.showNoData = true
        } else {
            this.findUserTrain();
        }
        if(this.trainInfo.isConfigCert == 1){
            this.checkCertificate("businessId", this.businessId,'','train');
        }
      })
    },
    findUserTrain(){
        let trainId = this.businessId
      this.$api.training
        .findUserTrain({ params: { trainId: trainId } })
        .then(
            ({
                data: { menuList, courseCount, totalStudyProcess }
            }) => {
                this.trainList = menuList;
                this.courseCount = courseCount;
                this.trainProcess = totalStudyProcess;
                if(this.$route.query.trainItemExamId){
                        let exam = this.trainList.find(el =>{
                            return el.trainItemExamId == this.$route.query.trainItemExamId
                        });
                        this.currentCourse = exam ? exam : {};
                } else {
                    this.currentCourse =
                        this.trainList.find(el => el.type) || {};
                }
                this.active = this.currentCourse.id;
                this.courseId = this.currentCourse.contentId;
                this.examType = this.currentCourse.type;
                this.trainItemExamId = this.currentCourse.trainItemExamId;
                if (this.examType == 1 && this.currentCourse.courseStatus != 8) {
                    this.findCourseItemByCourseId();
                    this.findCourseInfo();
                } else if (this.examType == 2) {
                    this.findExamListByIds();
                    this.findUserPaperListById();//考试历史记录
                }

                if (this.trainList.length > 0) {
                    this.noTrain = false;
                } else {
                    this.noTrain = true;
                }
            }
        );
    },
    changeInfo(el){
      this.active = el.id;
      this.courseId = el.contentId;// 内容id
      this.examType = el.type;
      this.currentCourse = el;
      this.trainItemExamId = el.trainItemExamId;
      if(this.currentCourse.courseStatus == 8){
          this.$message.error('课程被强制下架，禁止观看');
          return false;
      }
      if(el.type===1){
        this.findCourseInfo()
        this.findCourseItemByCourseId()
      }else if (el.type==2) {
        this.findExamListByIds()
        this.findUserPaperListById();//考试历史记录
      }
    },
    // 判断是否已实名
    realName() {
      if (this.examInfo.beLate) {
        this.dialogCon = '您已超过最迟开考时间！';
        this.warningdialogVisible = true;
      } else if (this.examInfo.isOpenPCCamera) {
        this.judgefaceauth('device');
      } else {
        if (this.isFaceRecogBeforeExam == 0 && this.isFaceRecogCommitExam == 1 ||
        this.isFaceRecogBeforeExam == 0 && this.isFaceRecogDuringExam == 1 ) {
          this.judgefaceauth('goExam');
        } else {
          this.exampath();
        }
      }
    },
    // 是否实名
    judgefaceauth(type) {
      const goPath = type;
      this.$api.face.judgefaceauth({data:{}}).then((res)=>{
        var authToken = localStorage.getItem("token");
        var authTokenParam = authToken + "true";
        var authMd5Param = md5(authTokenParam).toLowerCase();
        var authResData = res.data && res.data.toLowerCase();
        if(authMd5Param !== authResData){
          this.realNameDialog = true;
          this.closeRealName = true;
        } else {
          // 类型为去考试--进入考场
          if (goPath == 'goExam') {
            this.exampath();
          } else {
            // 判断是否调整好了设备
              let params = {
                  businessId: this.$route.query.bussinessId,
                  examId:this.courseId,
                  businessType: this.$route.query.taskType
              }
              this.$api.exam.queryLinkStatusAndPhoneLive(params).then(res=>{
                  if(res.data.isOpenPhoneCamera==1){ // 开启了h5
                      if (res.data.pcLiveStatus&&res.data.phoneLiveStatus) {
                          this.exampath();
                      } else {
                          this.dialogCon = '检测到您设备未调整完善，为保证考试顺畅，请调整好设备后再进入考场';
                          this.warningdialogVisible = true;
                      }
                  }else if(res.data.isOpenPhoneCamera==0){ // 没有开启h5
                      if (res.data.pcLiveStatus) {
                          this.exampath();
                      } else {
                          this.dialogCon = '检测到您设备未调整完善，为保证考试顺畅，请调整好设备后再进入考场';
                          this.warningdialogVisible = true;
                      }
                  }
              })
          }
        }
      })
    },
    // 进入考场
    exampath() {
        if(this.examInfo.isLimitTime && this.$dateFormat.dateFormat() < this.examInfo.examStartDate){
            this.$message.warning('该考试时间未到')
        }else {
            // 判断是否需要人脸验证
            if (this.isFaceRecogBeforeExam) {
                this.verification = true;
                this.verificationAddress = {
                  path:'/course/resdExam',
                  query:{
                      examId:this.courseId,
                      businessId: this.businessId,
                      dymode: this.$route.query.dymode,
                      taskType: this.$route.query.taskType || null, //taskType为1，则为从培训任务跳过来的
                      // 入口
                      source: 'coursecatalog'
                  }
                };
                this.verificationFailAddress = {};
                this.studyType = 2;
            } else {
              this.$router.push({
                path:'/course/resdExam',
                query:{
                    examId:this.courseId,
                    businessId: this.businessId,
                    dymode: this.$route.query.dymode,
                    taskType: this.$route.query.taskType, //taskType为1，则为从培训任务跳过来的
                    // 入口
                    source: 'coursecatalog'
                }
              })
            }
        }
    },
    // 查看详情
    viewDetails(item) {
      if (item.paperType == 2 && !item.isReadOver) {
        this.$message({
          message: '阅卷中，请耐心等待',
          type: 'warning'
        });
      } else {
        this.$router.push({
          path:'/course/handPaper',
          query:{
            userPaperId:item.id,
            businessId: this.businessId,
            examId: this.courseId,
            type: 'historyList',
            dymode: this.$route.query.dymode,
            //taskType为1，则为从培训任务跳过来的
            taskType: this.$route.query.taskType|| null,
            // 入口
            source: 'coursecatalog'
          }
        })
      }
    },
    infoShow(val){
      this.info = val
    },
    equShow(val){
      this.isShowDialog = val
    },
    async findCourseItemByCourseId () {
      const { data } = await this.$api.training.findCourseItemByCourseId(
        {
            params:{
                courseId:this.courseId,
                taskId:this.businessId,
                taskType:this.$route.query.taskType || null
            }
        });
       this.catalogList = data;
    },
    async findCourseInfo () {
      let params = {
        courseId: this.courseId,
        taskId: this.businessId,
        taskType:this.$route.query.taskType || null
      }
      const { data:{description} } = await this.$api.training.findCourseInfo({ params });
      this.description = description;
        this.getEvaluationInfo();
    },
    // 获取考试详情
    findExamListByIds() {
      this.$api.examination.findExamListByIds(this.businessId, this.courseId, this.$route.query.taskType || null).then((res) => {
        if(res.data){
          this.examInfo = res.data;
          // isOpenPCCamera 是否开启电脑摄像头(1:是;0:否)
          // isOpenPhoneCamera 是否开启手机摄像头(1:是;0:否)
          // showDebugButton 是否显示调试设备按钮(true:是;false:否)
          // beLate 是否迟到(true:是;false:否)
          if (this.examInfo.isOpenPCCamera) {
            this.isFaceRecogBeforeExam = 0;
            this.isFaceRecogDuringExam = 0;
            this.isFaceRecogCommitExam = 0;
          } else {
            this.isFaceRecogBeforeExam = this.examInfo.isFaceRecogBeforeExam;
            this.isFaceRecogDuringExam = this.examInfo.isFaceRecogDuringExam;
            this.isFaceRecogCommitExam = this.examInfo.isFaceRecogCommitExam;
          }
          sessionStorage.setItem('isFaceRecogBeforeExam',JSON.stringify(this.isFaceRecogBeforeExam));
          sessionStorage.setItem('isFaceRecogDuringExam',JSON.stringify(this.isFaceRecogDuringExam));
          sessionStorage.setItem('isFaceRecogCommitExam',JSON.stringify(this.isFaceRecogCommitExam));
        }
      });
    },
    // 考试历史记录
    async findUserPaperListById () {
      const { data } = await this.$api.examination.findUserPaperListById(this.businessId, this.courseId, this.$route.query.taskType || null);
      data.list.forEach((item) => {
        item.useDuration = this.timeFormatter(item.useDuration)
      })
      this.historyList = data.list;
      if (this.historyList.length>0) {
        this.noList = false;
      } else {
        this.noList = true;
      }
    },
    timeFormatter(time) {
      const hours = Math.floor(time / 60 / 60);
      const minutes = Math.floor((time / 60) % 60);
      const seconds = Math.floor(time % 60);
      var hours1 = hours < 10 ? "0" + hours : hours;
      var minutes1 = minutes < 10 ? "0" + minutes : minutes;
      var seconds1 = seconds < 10 ? "0" + seconds : seconds;
      return hours1 + "时" + minutes1 + "分" + seconds1 + "秒";
    },
    // 调试设备
      async  commissionEqu () {
        let CheckLiveParam = {
            channel: 1,
            businessId: this.businessId,
            examId:this.courseId,
            businessType: this.$route.query.taskType
        }
          if(localStorage.getItem('LiveParam')){
              localStorage.removeItem('LiveParam')
          }
        localStorage.setItem('CheckLiveParam',JSON.stringify(CheckLiveParam))
        let localDomain = JSON.parse(window.localStorage.getItem('domain')).filter(item=>item.frontend_template_id=='4')
        let paramURL = 'https://' + localDomain[0].domain + '/device_check?'
        // let paramURL = 'https://' + 'hrss-pc.testing2.wdeduc.com/' + '/device_check?'
          // let paramURL = 'https://hrss-stu.testing2.wdeduc.com' + '/device_check?'
        let qureyS =
              this.courseId + '&='
              + this.$route.query.taskType + '&='
              + this.businessId+ '&='
              + localStorage.getItem('organ')+ '&='
              + localStorage.getItem('actor')+ '&='
              + localStorage.getItem('token')+ '&='
              + localStorage.getItem('branch')
          let params =
              {
            'token': qureyS
          }
          console.log('query1231231231',qureyS)
          console.log('this.businessId',this.businessId)

          let res = await this.$api.exam.saveTKData(params)
          console.log(res.data,'-0==-=-=-=')
        this.urlH5Str = paramURL + 'tokens=' +res.data
      this.isShowDialog = true
    }
  },
};
</script>
<style lang="stylus" scoped>
.training
  /deep/.el-collapse
    border none
  /deep/.el-collapse-item__arrow
    display none
  /deep/.el-collapse-item__header
    background: rgba(49, 111, 255, 0.07);
    border: 1px solid #D7DCEE;
    padding: 0 12px;
    height: 58px;
    line-height: 58px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1B2257;
    img
      margin-right 4px
  /deep/.el-collapse-item
    margin-bottom 16px
  /deep/.el-collapse-item__wrap
    border: 1px solid #D7DCEE;
    border-top none
  /deep/.el-collapse-item__content
    padding: 12px 16px;
  /deep/.el-collapse-item__header.is-active
    border-bottom-color #d7dcee
</style>
<style lang="stylus" scoped>
 @import "../asset/css/courseDetails.styl"

</style>
<style lang="stylus">
.el-tooltip__popper.is-dark
  .con
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
.el-tooltip__popper .popper__arrow
  top 12px !important;
// 兼容火狐
@-moz-document url-prefix() {
    .training section {
        .no-course .history-list {
            overflow hidden
            .list-item {
                margin-right -17px
                margin-bottom: -17px
            }
        }
        aside .training-list {
            overflow hidden
            margin-right 0
            .el-scrollbar__wrap {
                margin-right: -17px;
            }
        }
        aside .training-list-height {
            .el-scrollbar__wrap {
                height: 807px;
            }
        }
        aside .training-list-nocourse {
            .el-scrollbar__wrap {
                height:  877px;
            }
        }
    }
}
</style>
