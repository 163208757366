<template>
    <div class="zhaohuevaluation publicStylus">
        <div class="container">
            <div class="top">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhaohu/evaluation-banner.png" class="banner" alt="">
                <div class="content">
                    <div class="info">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/services-icon2.png" class="icon" alt="">
                        <p>专项技能</p>
                    </div>
                    <div class="info">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/services-icon2.png" class="icon" alt="">
                        <p>实操能力</p>
                    </div>
                    <div class="info">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/services-icon3.png" class="icon" alt="">
                        <p>心理素质</p>
                    </div>
                    <div class="info">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/services-icon4.png" class="icon" alt="">
                        <p>综合能力</p>
                    </div>
                </div>
                
            </div>
            <div class="bottom">
                <div class="item" v-for="item in list" :key="item.id">
                    <h2 class="title zh">{{item.zh}}</h2>
                    <p class="cn">{{item.cn}}</p>
                    <p class="line"></p>
                    <p class="content">{{item.content}}</p>
                </div>
            </div>
        </div>
        <footers></footers>
    </div>
</template>
<script>
import footers from './sdjz2Footer.vue'
export default {
    name:'zhaohuevaluation',
    components:{footers},
    data(){
        return{
            list:[
                {
                    id:1,
                    zh:'高纬评定',
                    cn:'High latitude evaluation',
                    content:'健康照护行业测评系统提供在线评估等功能，赋能健康照护服务人员高效、便捷完成各项测评。'
                },
                {
                    id:2,
                    zh:'权威评估',
                    cn:'Authoritative assessment',
                    content:'同时，也支持第三方权威机构、专家、协会对健康照护服务人员和服务企业进行技能评估。'
                },
                {
                    id:3,
                    zh:'安全可信',
                    cn:'Safe and trustworthy',
                    content:'系统通过区块链技术，提供全流程、全链路、全要素数据保障，过程透明可追溯，保证结果的安全、准确、可信、权威。'
                },
            ]
        }
    },
    methods:{
        goPage(type){
            const host = window.location.host;
            window.open(`https://${host}/screen?type=${type}`)
            // this.$router.push({
            //     path:'/screen',
            //     query:{
            //         type:type
            //     }
            // })
        }
    }
}
</script>
<style lang="stylus" scoped>
@import "../asset/css/publicStylus.styl"
</style>