<template>
  <div>
    <div
      class="positionListItem"
      v-for="(item, idx) in sendPositionListData || []"
      :key="idx"
    >
      <div class="positionListItemStyle" @click="jumpDetailHandle(item)">
        <p class="positionListTitle">{{ item.positionName }}</p>
        <div class="positionIntroduce">
          <p
            class="positionPrice fl"
            v-if="!item.salaryStartNum && item.salaryEndNum"
          >
            {{ item.salaryEndNum }}K以下
          </p>
          <p
            class="positionPrice fl"
            v-if="item.salaryStartNum && !item.salaryEndNum"
          >
            {{ item.salaryStartNum }}K以上
          </p>
          <p
            class="positionPrice fl"
            v-if="item.salaryStartNum && item.salaryEndNum"
          >
            {{ item.salaryStartNum }}K-{{ item.salaryEndNum }}K
          </p>
          <div class="conditions fl">
            <span>{{ educationText[item.education] }}</span>
            <span>{{
              item.gender == null ? "性别不限" : gender[item.gender]
            }}</span>
            <span v-if="!(item.startAge && item.endAge)">年龄不限</span>
            <span v-else>年龄{{ item.startAge }}-{{ item.endAge }}周岁</span>
            <span>{{ positionType[item.positionType - 1] }}</span>
            <span>
              {{ item.workPlaceProvinceName }}
              {{ item.workPlaceCityName }}</span
            >
          </div>
        </div>
        <div class="welfare">
          <span
            v-for="(item, idx) in item.workBenefits.split(',')"
            :key="idx"
            >{{ item }}</span
          >
        </div>
      </div>
      <div class="publishTime" @click="jumpDetailHandle(item)">
        发布时间：{{ item.publishTime }}
      </div>

      <div class="companyInforCont">
        <div class="conpanyInforList fl">
          <p class="companyName">{{ item.companyName }}</p>
          <p class="companyType">{{ item.positionDesc }}</p>
          <p class="companyType" v-if="false">
            <span>移动互联网</span><span>D轮及以上</span
            ><span>10000人以上</span>
          </p>
        </div>
        <div class="companyLogo fl"><img :src="item.companyLogo" /></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "commonPositionList",
  props: ["sendPositionListData"],
  data() {
    return {
      listData: [],
      isLogin: null,
      educationText: ["不限", "高中", "大专", "本科", "研究生", "博士"],
      gender: ["男", "女"],
      positionType: ["全职", "兼职", "合伙人"],
    };
  },

  computed: {},

  filters: {},

  created() {},

  mounted() {},
  destroyed() {},

  methods: {
    jumpDetailHandle(item) {
      var theme = localStorage.getItem("theme");
      if (theme == "junmin") {
        var token = localStorage.getItem("token");
        if (token) {
          this.$router.push({
            path: "/jobDetail",
            query: {
              id: item.id,
              companyId: item.companyId,
            },
          });
        } else {
          this.$router.push({
            path: "/login",
            query: {
              name: `jobDetail?id=${item.id}&companyId=${item.companyId}`,
            },
          });
        }
      } else {
        this.$router.push({
          path: "/jobDetail",
          query: {
            id: item.id,
            companyId: item.companyId,
          },
        });
      }
    },
  },
};
</script>


<style  lang="stylus" scoped>
@import '../asset/css/job.styl';
</style>

