<template>
    <div class="college-new">
        <div class="return" @click="goBack"> {{message}}</div>
        <div class="college-news">
            <h2>{{data.title}}</h2>
            <h4>{{data.date}}</h4>
            <div v-html="data.content">
            </div>
        </div>
    </div>

</template>

<script>
import obj from '../../asset/js/collegeNews'
export default {
    name:'collegeNews',
    props: {
        id:String,
    },
    data () {
        return {
            data:{},
            list:[],
            message:'< 返回上一页'
        };
    },
    created(){
        this.list = obj.newsList;
        this.getCurData(this.id)
    },
    watch:{
       id(val){
           this.getCurData(val);
       }
    },
    methods: {
        getCurData(val){
            console.log(this.list);
            this.list.forEach(ele=>{
                if(ele.id === val) this.data = ele;
            });
        },
        goBack(){
            this.$router.push({
                path:'/zhongzhi_homepage',
            })
        }
    },
}

</script>
<style lang='stylus' scoped>
    @import "../../asset/css/collegeNews.styl"
</style>
