const urlName = ''
export default API => ({
    /**
     * 专题预览
     * @param {specialId:专题id} param0
     */
    getSpecialShow({
        data
    }) {
        return API({
            url: `${urlName}/cms/v1/special/getSpecialShow`,
            method: 'post',
            params: data,
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        })
    },
    /**
     * 文章列表
     * @param {classId:专题id} param0
     */
    getArticlesList({
        params,
        data
    }) {
        return API({
            url: `${urlName}/cms/v1/article/list?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
            method: 'POST',
            params: params,
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },

    /**
     * 文章详情
     *
     */
    getArticleDetail(data) {
        return API({
            url: `${urlName}/cms/v1/article/findById?id=${data.id}`,
            method: 'get',
            data
        });
    },

    /**
     * 新文章详情接口
     *
     */
    getArticleDetailNew(data) {
        return API({
            url: `${urlName}/modular/admin/v1/block/getArticleInfo`,
            method: 'post',
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },

    getArticleByClass(data) {
        return API({
            url: `${urlName}/cms/v1/article/getArticleListForClassId`,
            method: 'post',
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },



    getArticleByIds(data){ // 根据多个文章id查询文章列表
        return API({
            url: `/article/getArticleListByArticleIds`,
            method: 'post',
            params: data
        });
    },
    getArticleBySingleClassName(data){ // 根据单个分类id查询文章列表(含排序规则)
        return API({
            url: `/article/getArticleListByClassId`,
            method: 'post',
            params: data
        });
    },
    getArticleInfo(data){ // 根据多个分类id查询文章列表
        return API({
            url: `/modular/admin/v1/block/getArticleInfo`,
            method: 'post',
            data: data
        });
    },

})
