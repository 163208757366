import { render, staticRenderFns } from "./testPaperDetails.vue?vue&type=template&id=1577e641&scoped=true&"
import script from "./testPaperDetails.vue?vue&type=script&lang=js&"
export * from "./testPaperDetails.vue?vue&type=script&lang=js&"
import style0 from "./testPaperDetails.vue?vue&type=style&index=0&id=1577e641&lang=stylus&scoped=true&"
import style1 from "./testPaperDetails.vue?vue&type=style&index=1&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1577e641",
  null
  
)

export default component.exports