<template>
    <div class="HouseKeepingTrain publicStylus">
        <div class="container">
            <div class="top">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhaohu/HouseKeepingTrain-banne.png" class="banner" alt="">
                <div class="content">
                    <div class="info" @click="goPage">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/train-icon.png" class="icon" alt="">
                        <p>健康照护培训系统</p>
                    </div>
                </div>
                
            </div>
            <div class="bottom">
                <div class="item" v-for="item in list" :key="item.id">
                    <h2 class="title zh">{{item.zh}}</h2>
                    <p class="cn">{{item.cn}}</p>
                    <p class="line"></p>
                    <p class="content">{{item.content}}</p>
                </div>
            </div>
        </div>
        <footers></footers>
    </div>
</template>
<script>
import footers from './sdjz2Footer.vue'
export default {
    name:'zhaohuHouseKeepingTrain',
    components:{
        footers
    },
    data(){
        return{
            list:[
                {
                    id:1,
                    zh:'课程全面',
                    cn:'Comprehensive course',
                    content:'养老护理、母婴护理、医疗器械等照护职业一应俱全，理论知识、专业技能、职业素养多方面学习。'
                },
                {
                    id:2,
                    zh:'多途径学习',
                    cn:'Multipath learning',
                    content:'提供人才测评、录播课程、直播课程、模拟练习、正式考试、考取证书、行业评定等服务。'
                },
                {
                    id:3,
                    zh:'结果通用',
                    cn:'Results the general',
                    content:'具备评定资质的第三方机构依据国家人社部门的标注要求发布证书。证书将作为服务机构企业招聘或雇主雇佣的重要参照依据。'
                }
            ]
        }
    },
    methods:{
        goPage(){
            window.open('https://sdjzpx.wdeduc.com/sdjz_homepage','newW')
        }
    }
}
</script>
<style lang="stylus" scoped>
@import "../asset/css/publicStylus.styl"
</style>