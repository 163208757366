const block = {}
const elObj = require.context('./../block/', true, /\.vue$/)
const elPageObj = require.context('./../page/', true, /\.vue$/)
elObj.keys().forEach(key => {
  const el = elObj(key).default
  if(!el) return
  block[el.name] = el
})
elPageObj.keys().forEach(key => {
  const el = elPageObj(key).default
  if(!el) return
  block[el.name] = el
})
export default block

