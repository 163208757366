<template>
  <div>
    <div class="jiazheng-nav">
      <div class="flex lr-flex nav-c top-nav">
        <img class="imglogoG" src="@/assets/images/logos/jiazheng-logo.png" alt="" />
        <div>
          <template v-if="!token">
            <el-button @click="jumpLogin('jiazheng_homepage')">
              学员登录
            </el-button>
            <el-button @click="jumpOrgan">
              机构登录
            </el-button>
            <el-button @click="jumpRegister">
              机构注册
            </el-button>
          </template>
          <template v-else>
            <div class="user-top">
              <account :replacePage="'jiazheng_homepage'"/>
            </div>
          </template>
        </div>
      </div>
      <div class="nav-list">
        <div class="nav-c">
          <div class="gongxin-nav-bar fl">
            <p class="item-nav fl" 
              v-for="item in navList" 
              :key="item.id"
              :class="{'active': setActive(item)}"
              @click="clickNav(item)"
            >
              {{item.title}}
            </p>
            <el-dropdown class="move fl" placement="bottom" trigger="click" ref="messageDrop">
              <span class="el-dropdown-linkapp">
                <span class="dropdown-title link-app">移动端</span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="nav-jz-menu">
                <el-dropdown-item class="nav-item">
                  <div class="dl-wrap">
                    <div class="img-wrap">
                      <img v-if="netImg" src="@assets/user/jiazheng_qrcode.png" alt="" class="app-download" />
                      <img v-if="uatImg" src="@assets/user/jiazheng_uat.png" alt="" class="app-download" />
                      <img src="@assets/user/border-dec.png" class="border border1"/>
                      <img src="@assets/user/border-dec.png" class="border border2"/>
                    </div>
                    
                    <p>移动学习 随时随地</p>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import $ from 'jquery'
import account from './account.vue'
export default {
  name: 'jiazhengNav',
  components: {
    account,
  },
  data () {
    return {
      uatImg:false,
      netImg:false,
      userInfo: {},
      activeNav: this.$route.path,
      mode: '0', //  0:其他 1' 学习中心 '2': 模拟考试 ‘3’正式考试
      token: '',
      couseIds: {},
      navList: [
        {
          path: '/jiazheng_homepage',
          needLogin: false,
          title: '首页',
          activePaths: ['/jiazheng_homepage'],
          mode: '0',
        },
        {
          path: '/course/catalog',
          needLogin: true,
          title: '学习中心',
          activePaths: ['/course', '/course/catalog', '/course/resdExam', '/course/testPaper', '/course/handPaper', '/course/testPaperDetails'],
          mode: '1',
        },
        
        {
          path: '/course/catalog',
          needLogin: true,
          title: '模拟考试',
          activePaths: ['/course', '/course/catalog', '/course/resdExam', '/course/testPaper', '/course/handPaper', '/course/testPaperDetails'],
          mode: '2',
        },
        {
          path: '/course/catalog',
          needLogin: true,
          title: '正式考试',
          activePaths: ['/course', '/course/catalog', '/course/resdExam', '/course/testPaper', '/course/handPaper', '/course/testPaperDetails'],
          mode: '3',
        },
        {
          path: '',
          needLogin: false,
          title: '证书查询',
          activePaths: [],
          newWindow: true,
          mode: '0',
        },
        {
          path: '/liveList',
          needLogin: true,
          title: '直播专区',
          activePaths: ['/liveList'],
          mode: '0',
        }
      ]
    }
  },
  created () {
  },
  watch: {
    $route() {
      setTimeout(() => { //路由跳转
        this.setDefaultInfo();
      }, 100)
    }
  },
  async mounted () {
    this.$refs.messageDrop.show();
    setTimeout(() => { //刷新
      this.setDefaultInfo();
    }, 100)
    // if (sessionStorage.getItem('dy-mode')) {
    //   this.mode = sessionStorage.getItem('dy-mode') || '0';
    // }
    let host = window.location.host;
    if(host=='jiazheng.saas-pc.uat.wdeduc.com'){
        this.uatImg=true
        this.netImg=false
    }else{
        this.uatImg=false
        this.netImg=true
    }
  },
  methods: {
    jumpOrgan() {
      let host = window.location.host;
        if(host === 'jiazheng.wdeduc.com'){
          window.open('http://jiazheng-ht.wdeduc.com/entrance/login', '_blank')
        }else{
            // 此时不是真正的生产环境
            let type = host.split('.')[2];
            let url = `http://jiazheng.saas-main.${type}.wdeduc.com/entrance/login`;
            window.open(url, '_blank');
        }
    },
    jumpRegister() {
      this.$router.push('register-jz')
    },
    setDefaultInfo() {
      this.activeNav = this.$route.path;
      this.mode = this.$route.query.dymode || '0';
      this.token = localStorage.getItem('token');
      if (this.token) {
        this.$api.home.getJiazhengExam().then((res) =>  {
            if(res.success){
                localStorage.setItem('jzTrainInfo', JSON.stringify(res.data));
            }
        })
      }
    },
    setActive(item) {
      // 学习中心，模拟考试的内部跳转需要处理。
      let flag = false;
      this.mode = this.$route.query.dymode || '0';
      if (this.mode === item.mode) {
        flag = !!item.activePaths.includes(this.activeNav);
      }
      
      return flag;
    },
    clickNav(item) {
      if (this.$route.path === '/course/testPaper') {
        return;
      }
      if (item.mode > 0 && !localStorage.getItem('jzTrainInfo')) {
        this.jumpLogin();
        return;
      }

      let name = item.path;
      
      let jzTrainInfo = JSON.parse(localStorage.getItem('jzTrainInfo')) || {};
      
      if (item.mode === '1') {

        name = `${name}?id=${jzTrainInfo.studyTrainId}&dymode=1&taskType=1`
        if (!jzTrainInfo.studyTrainId) {
          this.$message.warning('账号未开通课程')
          return
        }

      } else if (item.mode === '3') {

        name = `${name}?id=${jzTrainInfo.formalExamTrainId}&dymode=3`
        if (!jzTrainInfo.formalExamTrainId) {
          this.$message.warning('账号未开通课程')
          return
        }

      } else if (item.mode === '2') {

        name = `${name}?id=${jzTrainInfo.practiceExamTrainId}&dymode=2`
        if (!jzTrainInfo.practiceExamTrainId) {
          this.$message.warning('账号未开通课程')
          return
        }

      } else if (item.newWindow) {

          window.open('https://cvedu.zhonggaisuo.cn')
          return;

      }
      this.mode = item.mode;
      // sessionStorage.setItem('dy-mode', item.mode);

      this.$router.push(name);
      
    },
    jumpLogin() {
      this.$router.push(`/login?name=jiazheng_homepage`).catch()
    },
  }
}
</script>
<style lang="stylus" scoped>
@import './../../../../assets/css/jiazhengNav.styl';
</style>
<style lang="stylus">
</style>
