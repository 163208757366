const urlName = '/order/v1'
export default API => ({
    // 查询商品详情
    findGoodsDetailById(data) {
        return API({
            url:`${urlName}/open/goodsLearner/findGoodsDetailById?goodsId=${data.goodsId}`,
            method:'get',
        });
    },
    //查询培训任务目录列表
    findTaskListById(data) {
        return API({
            url:`/task/v1/client/taskDetailWithoutLogin?id=${data.id}`,
            method:'get',
        });
    },
    //查询训练营目录列表
    findTrainListById(data) {
        return API({
            url:`/train/v1/train/student/findUserTrainByTrainId?trainId=${data.id}`,
            method:'get',
        });
    },
    //查询课程大纲资源列表
    findResourceListById(data) {
        return API({
            url:`/course/v1/open/courseItem/findCourseItemWithResourceListByCourseId?courseId=${data.id}`,
            
        });
    },
    //立即报名下单
    order(data) {
        return API({
            url:`/order/v1/open/order/placeOrder`,
            method:'post',
            data
        });
    },
    getPlayAuthToken (data) {
        return API({ url: `/resource/v1/resAccessToken/getPlayAuthToken?resId=${data.detailId}`, method:'get' })
    },
    // 老接口 可能需要替换   图片用老接口
    getImageOrDocUrl (data) {
        return API({ url: `/resource/v1/resource/getImageOrDocUrl?id=${data.detailId}`, method:'get' })
    },
    // 新接口 文档
    getDocumentAccessToken (data) {
        return API({ url: `/resource/v1/resAccessToken/getDocumentAccessToken?resId=${data.detailId}`, method:'get' })
    },
})