<template>
     <div class="shandong-certificate">
        <img class="cover" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/Certificate/certificate.png" alt="">
        <div class="certificate-content-img">
            <div class="item" @click="goPage(1)">
                     <p class="btn">
                        去查询
                     </p>
            </div>
            <div class="item" @click="goPage(2)">
                    <p class="btn">
                         去查询
                     </p>
            </div>
            <div class="item" @click="goPage(3)">
                     <p class="btn">
                         去查询
                     </p>
            </div>
            <div class="item" @click="goPage(4)">
                    <p class="btn">
                         去查询
                     </p>
            </div>
        </div>
        <footers></footers>
    </div>
</template>
<script>
import footers from '../block/front/footers.vue'
export default {
    name:'shandongCertificate',
    components:{footers},
    methods:{
        goPage(type){
            if(type === 1){
                // window.open('http://edu.mohrss.gov.cn/')// 缺少链接
            }else {
                 window.open('http://edu.mohrss.gov.cn/')
            }
        }
    }
}
</script>
<style lang="stylus" scoped>
.shandong-certificate{
    width 100% 
    font-size 0
    .cover{
        width 100% 
        height 400px
        object-fit cover   
    }   
}
.certificate-content-img
    margin-top 64px
    .item
        position relative
        text-align center
        width 1200px
        height 320px
        margin 0 auto
        background-size cover
        background-repeat no-repeat
        margin-bottom 64px
        &:nth-child(1)
            background-image url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/Certificate/certificate-1.png')
        &:nth-child(2)
            background-image url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/Certificate/certificate-2.png')
        &:nth-child(3)
            background-image url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/Certificate/certificate-3.png')
        &:nth-child(4)
            background-image url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/Certificate/certificate-4.png')
        .btn
            cursor pointer
            display inline-block
            position absolute
            bottom 32px
            width: 102px;
            height: 40px;
            background: #306EFF;
            border-radius: 3px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 40px;
    img 
        width:100%;
        max-width 1200px
        display block;
        margin 64px auto 0;
        vertical-align middle;
</style>
