const urlName = '/order/v1'
export default API => ({
    //查询所有可用商品分类
    findAll(data) {
        return API({
            url: `${urlName}/open/goodsCategory/findAll` ,
            method:  'get',
            data
        });
    },

    //查询商品
    findByCondition(data) {
        let goodsName = decodeURIComponent(data.name)
        return API({
            url: `${urlName}/open/goodsLearner/findByCondition?pageSize=${data.pageSize}&pageNum=${data.pageNum}&categoryCodes=${data.categoryCodes}&name=${goodsName}&type=${data.type}&goodsIds=${data.goodsIds}` ,
            method:  'get',
            data
        });
    },

})

