<template>
  <div>
    <div class="training"  v-if="!showNoData">

      <section>
          <aside>
              <p v-if="courseCount>0" class="course-num">共有{{courseCount}}门课程</p>
              <ul :class="courseCount ? 'training-list-height' : 'training-list-nocourse'" class="training-list">
                  <el-scrollbar style="height:100%">
                    <div class="list-li">
                    <el-tooltip 
                        class="item" 
                        effect="dark"
                        placement="right-start"
                        v-for="el in trainList" :key="el.id"
                        :content="el.label"
                    >
                        <li @click="changeInfo(el)" :class="[active===el.id && el.type===1?'training-list-active':active===el.id && el.type===2?'training-exam-active':'', el.type===2?'exam':'']">
                            <img class="img" :src="el.pic" alt="" v-if="el.type===1">
                            <img v-if="el.type===2" src="../../asset//4.png" alt="" >
                            <span
                                v-if="el.type===2&&el.isPassed!==2"
                                class="exam-tip"
                                :class="{'nopass': el.isPassed === 0, 'pass': el.isPassed === 1, 'noanswer': el.isPassed === 3}"
                            >{{el.isPassed | ispassTip}}</span>
                        </li>
                    </el-tooltip>
                    </div>
                  </el-scrollbar>
              </ul>
          </aside>
          <article v-if="examType == 1">
              <div class="course-title">
                <h2>
                    <span class="course-name" v-if="currentCourse.courseStatus != 8">{{currentCourse.label}}</span>
                    <span class="termination" v-else><span>******</span><span>很抱歉，此内容已被强制终止</span></span>
                    <span class="course-progress">已完成 {{currentCourse.studyProcess}}%</span>
                    <span
                        
                        class="course-score"
                        @click="playboxscoreclick"
                    >{{myScore>0?'已评分':'未评分'}}</span>
                    <span class="course-info" :class="{active : currentCourse.courseStatus === 8 }"  @click="getcourseInfo(currentCourse.courseStatus)">课程简介</span>
                </h2>
                <div class="evaluation-box">
                    <div class="evaluation _clear">
                        <p v-for="itemcount1 in count" :key="itemcount1">
                            <img src="../../asset/pfwjx.png" alt />
                        </p>
                        <p v-for="itemcount2 in 5-count" :key="itemcount2+count">
                            <img src="../../asset/pfwjx1.png" alt />
                        </p>
                        <p>{{count1.toFixed(1)}}分</p>
                    </div>
                </div>
            </div>
            <div class="termination-icon" v-if="currentCourse.courseStatus === 8">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/zhongzhi-icon.png" alt="">
                <p class="text">课程暂时无法学习呦～</p>
            </div>
            <div v-else>
                <courseItem :itemList="catalogList" :courseExpireStatus='currentCourse.courseExpireStatus' :courseName="currentCourse.label"></courseItem>
                <p class="course-all">-已加载全部内容-</p>
            </div>
          </article>
          <article v-if="examType == 2" class="no-course">
              <h2 class="no-course-title">
                  <span class="course-name">{{examInfo.name}}</span>
                  <span class="course-type">{{examInfo.type === 1?'模拟考试':'正式考试'}}</span>
              </h2>
              <p class="exam-details-ico" v-if="examInfo.result!==-1">
                <img
                    :src="examInfo.result | examresult"
                />
              </p>
              <div class="exam-card">
                <div class="exam-list">
                  <div class="card-list">
                    <p class="fraction">{{examInfo.totalQuestionNum || 0}}</p>
                    <p class="title">总题数(道)</p>
                    <img src="../../asset/zts.png" alt="">
                  </div>
                  <div class="card-list zfz">
                    <p class="fraction">{{examInfo.totalScore || 0}}</p>
                    <p class="title">总分值(分)</p>
                    <img src="../../asset/zfz.png" alt="">
                  </div>
                  <div class="card-list jgf">
                    <p class="fraction">{{examInfo.passingScore || 0}}</p>
                    <p class="title">及格线(分)</p>
                    <img src="../../asset/jgf.png" alt="">
                  </div>
                  <div class="card-list kssc">
                    <p class="fraction">{{examInfo.duration || 0}}</p>
                    <p class="title">考试时长(分钟)</p>
                    <img src="../../asset/kssc.png" alt="">
                  </div>
                  <div class="left"></div>
                  <div class="right"></div>
                </div>
                <div class="exam-date">
                  <span class="title">考试日期：</span>
                  <span class="time" v-if="examInfo.examStartDate && examInfo.examEndDate">{{examInfo.examStartDate.slice(0, -3)}}至{{examInfo.examEndDate.slice(0, -3)}}</span>
                  <span class="over" v-show="examInfo.ifEnd === 1">已结束</span>
                  <div class="exam-details-footer" v-if="examInfo.ifEnd !== 1">
                      <p v-if="examInfo.ifStart === 0" class="exam-details-btn nostart"><span>考试尚未开始</span></p>
                      <p v-if="examInfo.ifStart === 1 && examInfo.totalExamNum==0" class="exam-details-btn" @click="exampath">
                        <span>进入考场</span>
                      </p>
                      <p v-if="examInfo.ifStart === 1 && examInfo.leftExamNum === 0 && examInfo.totalExamNum!==0" class="exam-details-btn nostart">
                          <span>已无考试剩余次数</span>
                      </p>
                      <p v-if="examInfo.ifStart === 1 && examInfo.leftExamNum>0 && examInfo.totalExamNum!==0" class="exam-details-btn" @click="exampath">
                          <span>
                            进入考场
                            <!-- <label>(考试剩余机会：{{examInfo.leftExamNum}}次/ 共{{examInfo.totalExamNum}}次)</label> -->
                          </span>
                      </p>
                      <p v-if="examInfo.totalExamNum>0"
                        class="examChance"
                      >考试剩余机会：{{examInfo.leftExamNum}}次/ 共{{examInfo.totalExamNum}}次</p>
                  </div>
                </div>
              </div>
              <div class="history-list">
                <div class="title">
                  <h2><img src="../../asset/history.png" alt="">历史考试记录</h2>
                  <el-tooltip placement="left" effect="dark" popper-class="atooltip">
                    <div slot="content" class="con">异常退出的考试，考试记录会延迟显示，请耐心等待。</div>
                    <i class="el-icon-info tip-icon"></i>
                  </el-tooltip>
                </div>
                <ul class="list-item">
                  <el-scrollbar style="height:100%">
                    <div v-if="!noList">
                        <li v-for="(item, index) in historyList" :key="item.id">
                        <div class="item">
                            <span class="num">{{index | formartIndex}}</span>
                            <span class="date">{{item.examStartTime}}</span>
                            <span class="time">用时：{{item.useDuration}}</span>
                            <span class="score">得分：<span class="score-num">{{item.score}}</span>分</span>
                        </div>
                        <div class="btn" @click="viewDetails(item.id)">查看详情</div>
                        </li>
                    </div>
                    <div v-else class="list-none">
                        <img src="../../asset/nodata.png" alt="">
                        <p>暂无考试记录，赶快去考试吧～</p>
                    </div>
                  </el-scrollbar>
                </ul>
              </div>
          </article>
          <article  v-if="noTrain" class="no-date">
            <div class="no-date-img">
              <img src="../../asset/分组 4.png" alt="">
              <p>暂无课程</p>
            </div>
          </article>
      </section>
      <dialogInfo :info="info" :currentCourse="currentCourse" @infoShow="infoShow" :description="description"></dialogInfo>
    </div>
    <div class="noData" v-if="showNoData">
       <div class="noData-con">
          <img src="../../asset/nodata.png" alt="">
          <p>暂无数据</p>
       </div>
    </div>
    <footers v-if="theme==='gongxin'"/>
    <auth-guide :ModalVisbileAuth="authGuideModalVisbile" @handleCloseAuth="handleCloseAuth" :wechatErInfo="previewInfoImg"></auth-guide>
        <preview-certificate
            :previewModalVisbile="previewModalVisbile" 
            @handleClosePreview="handleClosePreview"
            @downLoadCer="downLoadCer('businessId',businessId,previewInfoImg.userName,previewInfoImg.name)"
            :isDownLoad="isDownLoad" 
            :previewUrl="previewUrl">
        </preview-certificate>
    <playboxscore v-if="playboxscore" :myScore="myScore" :issubmit="myScore>0?false:true"></playboxscore>
  </div>
</template>

<script>
import courseItem from './courseItem.vue';
import dialogInfo from './dialogInfo.vue';
import footers from '../../../gongxin/block/front/footers'
import cercertificateIsShowStatus from "@/utils/certificateMixin";
// import $ from 'jquery';
export default {
  name: 'coursecatalog',
  components: {
    courseItem,
    dialogInfo,
    footers
  },
  data () {
    return {
        isDownLoad: true,
        myScore: 0,
        count: 0,
        count1:0,
        playboxscore: false,
        activeNames: [0],
        trainInfo: {},
        courseList: [],
        courseCount: null,
        trainList: [],
        currentCourse: {},
        courseId: "",
        catalogList: [],
        active: "",
        info: false,
        description: "",
        trainProcess: "",
        examType: null,
        trainItemExamId: "",
        examInfo: {},
        historyList: [],
        noList: false,
        noTrain: false,
        showNoData: false,
        theme: "",
        img1: require("../../../exam/asset/images/ysjg.png"),
        img2: require("../../../exam/asset/images/ysbjg.png")
    };
  },
  mixins: [cercertificateIsShowStatus],
  computed: {},
  filters: {
      examresult(val) {
            if(val === 0){
				return require('../../asset/bujige@2x.png');
			} else if(val === 1){
				return require('../../asset/jige@2x.png');
			} else if(val === 2){
				return require('../../asset/weizuoda@2x.png');
			}
        },
		isPassedStatus(val) {
			if(val === 0){
				return require('../../asset/bujige@2x.png');
			} else if(val === 1){
				return require('../../asset/jige@2x.png');
			} else if(val === 3){
				return require('../../asset/weizuoda@2x.png');
			}
		},
		ispassTip(val) {
			if(val === 0){
				return '不及格';
			} else if(val === 1){
				return '已及格';
			} else if(val === 3){
				return '未作答';
			}
		},
      dataFormat(msg) {
        if (msg) {
          const date = new Date(msg)
          const m = date.getMonth()+1;
          return date.getFullYear()+'.'+m+'.'+date.getDate();
        }
      },
      ellipsis(msg){
        if(msg.length>37){
          return msg+'...'
        }
        return msg
      },
      formartIndex(index) {
        if (index+1<10) {
          return '0'+(index+1)
        }
        return index+1
      }
  },
  watch: {
    '$route.query.id': function () {
      
      this.$nextTick(()=>{
        this.findTrainBasicInfoById();
        // this.findUserTrain();
      })
    //   location.reload();
    }
  },
  created () {
    this.businessId = this.$route.query.id ? this.$route.query.id : this.$route.query.trainId ;
    this.theme = sessionStorage.getItem('theme');
    if(this.theme === 'gongxin'){   
        let id = this.$route.query.dymode == 2 ? this.$route.query.trainId : this.businessId
        this.checkUserCanByGoods(id)
    } else {
        this.findTrainBasicInfoById();
    }
    
    // this.findUserTrain();
  },
  methods: {
      /* 工信是否购买过课程 */
      checkUserCanByGoods(id) {
            this.$api.home.checkUserCanByGoods(id).then(res=>{
                if (res.data == true) {
                    this.$message.info('您尚未购买课程，先去购买课程吧')
                    this.$router.push({
                        path:'/gongxinDetails'
                    })
                }else {
                    this.findTrainBasicInfoById();
                    
                }
            })
        },
      playboxscoreclick(){
            if(this.currentCourse.studyProcess>=30){
                this.playboxscore=true;
            }else{
                this.$message.error('课程学习进度未达到30%，不能进行评价')
            }
        },
        getcourseInfo(status){
            if(status === 8){
                this.$message.error('很抱歉，此内容已被强制终止')
            }else {
                this.info = true;
            }
        },
        clickclose() {
            this.playboxscore = false;
            this.$forceUpdate;
        },
        async getEvaluationInfo() {
            let params = {
                courseId: this.courseId
            };
            await this.$api.course.getEvaluationInfo({ params }).then(res => {
                this.count1=res.data.aveScore?res.data.aveScore:3;
                this.count=res.data.aveScore?Math.ceil(res.data.aveScore):3;
                this.myScore=res.data.myScore?Math.ceil(res.data.myScore):0;
                this.$forceUpdate;
            });
        },
        async evaluateCourse(count) {
            const data = {
                courseId: this.courseId,
                score: count
            };
            await this.$api.course.evaluateCourse({ data }).then(() => {
                this.playboxscore = false;
                this.getEvaluationInfo();
            });
        },
    findTrainBasicInfoById(){
      this.$api.training.findTrainBasicInfoById({params:{id: this.businessId}}).then(({data})=>{
        this.trainInfo = data;
        if (!this.trainInfo) {
          this.showNoData = true
        } else {
            this.findUserTrain();
        }
        if(this.trainInfo.isConfigCert == 1){
            this.checkCertificate("businessId", this.businessId,'','train');
        }
      })
    },
    findUserTrain(){
        let trainId = this.businessId
      this.$api.training
        .findUserTrain({ params: { trainId: trainId } })
        .then(
            ({
                data: { menuList, courseCount, totalStudyProcess }
            }) => {
                this.trainList = menuList;
                this.courseCount = courseCount;
                this.trainProcess = totalStudyProcess;
                console.log(this.trainList, 'this.trainList')
                if(this.$route.query.trainItemExamId){
                    // this.currentCourse =
                        let exam = this.trainList.find(el =>{
                            return el.trainItemExamId == this.$route.query.trainItemExamId
                        });
                        this.currentCourse = exam ? exam : {};
                } else {
                    this.currentCourse =
                        this.trainList.find(el => el.type) || {};
                }
                console.log(this.currentCourse, 'this.currentCourse')
                this.active = this.currentCourse.id;
                this.courseId = this.currentCourse.contentId;
                this.examType = this.currentCourse.type;
                this.trainItemExamId = this.currentCourse.trainItemExamId;
                if (this.examType == 1 && this.currentCourse.courseStatus != 8) {
                    this.findCourseItemByCourseId();
                    this.findCourseInfo();
                } else if (this.examType == 2) {
                    this.findTrainExamInfoById();
                    this.findUserPaperListById();
                }
                
                if (this.trainList.length > 0) {
                    this.noTrain = false;
                } else {
                    this.noTrain = true;
                }
            }
        );
    },
    changeInfo(el){
      this.active = el.id;
      this.courseId = el.contentId;
      this.examType = el.type;
      this.currentCourse = el;
      this.trainItemExamId = el.trainItemExamId;
      if(this.currentCourse.courseStatus == 8){
          this.$message.error('课程被强制下架，禁止观看');
          return false;
      }
      if(el.type===1){
        this.findCourseInfo() 
        this.findCourseItemByCourseId()
      }else if (el.type==2) {
        this.findTrainExamInfoById()
        this.findUserPaperListById()
      }
    },
    // 进入考场
    exampath() {
      this.$router.push({
        path:'/course/resdExam',
        query:{
          examId:this.courseId,
          businessId: this.businessId
        }
      })
    },
    // 查看详情
    viewDetails(id) {
      this.$router.push({
        path:'/course/handPaper',
        query:{
          userPaperId:id,
          businessId: this.businessId,
          examId: this.courseId,
          type: 'historyList'
        }
      })
    },
    infoShow(val){
      this.info = val
    },
    async findCourseItemByCourseId () {
      const { data } = await this.$api.training.findCourseItemByCourseId({ params:{courseId:this.courseId} });
       this.catalogList = data;
    },
    async findCourseInfo () {
      let params = {
        courseId: this.courseId,
        taskId: this.businessId
      }
      const { data:{description} } = await this.$api.training.findCourseInfo({ params });
      this.description = description;
        this.getEvaluationInfo();
    },
    async findTrainExamInfoById () {
      const { data } = await this.$api.training.findTrainExamInfoById({ params:{id:this.trainItemExamId} });
      this.examInfo = data;
    },
    async findUserPaperListById () {
      const { data } = await this.$api.training.findUserPaperListById({ 
        params:{
          id:this.trainItemExamId,
          pageNum:1,
          pageSize:999,
        } 
      });
      data.list.forEach((item) => {
        item.useDuration = this.timeFormatter(item.useDuration)
      })
      this.historyList = data.list;
      if (this.historyList.length>0) {
        this.noList = false;
      } else {
        this.noList = true;
      }
    },
    timeFormatter(time) {
      const hours = Math.floor(time / 60 / 60);
      const minutes = Math.floor((time / 60) % 60);
      const seconds = Math.floor(time % 60);
      var hours1 = hours < 10 ? "0" + hours : hours;
      var minutes1 = minutes < 10 ? "0" + minutes : minutes;
      var seconds1 = seconds < 10 ? "0" + seconds : seconds;
      return hours1 + "时" + minutes1 + "分" + seconds1 + "秒";
    },
    handleChange(val){
        console.log(val)
    }
  },
};
</script>
<style lang="stylus" scoped>
.training
  /deep/.el-collapse
    border none
  /deep/.el-collapse-item__arrow
    display none
  /deep/.el-collapse-item__header
    background: rgba(49, 111, 255, 0.07);
    border: 1px solid #D7DCEE;
    padding: 0 12px;
    height: 58px;
    line-height: 58px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1B2257;
    img 
      margin-right 4px
  /deep/.el-collapse-item
    margin-bottom 16px
  /deep/.el-collapse-item__wrap
    border: 1px solid #D7DCEE;
    border-top none
  /deep/.el-collapse-item__content
    padding: 12px 16px;
  /deep/.el-collapse-item__header.is-active
    border-bottom-color #d7dcee
</style>
<style lang="stylus" scoped>
 @import "../../asset/css/courseDetails.styl"

</style>
<style lang="stylus">
.el-tooltip__popper.is-dark
  .con
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
.el-tooltip__popper .popper__arrow
  top 12px !important;
// 兼容火狐
@-moz-document url-prefix() {
    .training section {
        .no-course .history-list {
            overflow hidden
            .list-item {
                margin-right -17px
                margin-bottom: -17px
            }
        }
        aside .training-list {
            overflow hidden
            margin-right 0
            .el-scrollbar__wrap {
                margin-right: -17px;
            }
        }
        aside .training-list-height {
            .el-scrollbar__wrap {
                height: 807px;
            }
        }
        aside .training-list-nocourse {
            .el-scrollbar__wrap {
                height:  877px;
            }
        }
    }
}
</style>
