export default API => ({
    // 获取配置信息
    configuration(data){
        return API({
            url:`/modular/admin/v1/configuration/${data}`,
            method:'get',
        })
    },
    getTemplateLayout() {
        let data = [
            {
                "page_name": "home2",
                "label": "家政首页",
                "uri": "/jiazheng_homepage",
                "template_name": "layout_3",
                "layout": [
                    {
                        "cid": "cid_1",
                        "blocks": [
                            {
                                "name": "jiazheng-homeBanner",
                                "setting": null
                            },
                            {
                                "name": "jiazheng-familyList",
                                "setting": null
                                // "setting": {
                                //     "is_search_on_course_info": "true",
                                //     "title_size": "5"
                                // }
                            //     // },
                            //     // {
                            //     //     "name": "sys_login",
                            //     //     "setting": null
                            },
                            {
                                "name": "jiazheng-footers",
                                "setting": null
                            }
                        ]
                    }
                ],
                "type": 2
            },
            {
                "page_name": "training_index2",
                "label": "Traning Index2",
                "uri": "/jiazheng_homepage3",
                "template_name": "layout_3",
                "layout": [
                    {
                        "cid": "cid_1",
                        "blocks": [
                            {
                                "name": "jiazheng-familyList",
                                "setting": null
                            },
                            {
                                "name": "jiazheng-homeBanner",
                                "setting": null
                                // "setting": {
                                //     "is_search_on_course_info": "true",
                                //     "title_size": "5"
                                // }
                            //     // },
                            //     // {
                            //     //     "name": "sys_login",
                            //     //     "setting": null
                            },
                            {
                                "name": "jiazheng-footers",
                                "setting": null
                            }
                        ]
                    }
                ],
                "type": 2
            },
            {
                "page_name": "liveList2",
                "label": "直播专区",
                "uri": "/liveList",
                "template_name": "layout_3",
                "layout": [
                    {
                        "cid": "cid_1",
                        "blocks": [
                            {
                                "name": "gongxin-homeBanner",
                                "setting": null
                            },
                            {
                                "name": "gongxin-footers",
                                "setting": null
                            }]
                    }
                ],
                "type": 2
            },
            {
                "page_name": "course2",
                "label": "训练营",
                "uri": "/course",
                "template_name": "layout_3",
                "layout": [
                    {
                        "cid": "cid_1",
                        "blocks": [
                            {
                                "name": "train-training",
                                "setting": null
                            }
                        ]
                    }
                ],
                "type": 2
            },
            {
                "page_name": "handPaper",
                "label": "训练营",
                "uri": "/course/handPaper",
                "template_name": "layout_3",
                "layout": [
                    {
                        "cid": "cid_1",
                        "blocks": [
                            {
                                "name": "exam-backbutton",
                                "setting": null
                            },
                            {
                                "name": "exam-handPaper",
                                "setting": null
                            },
                            {
                                "name": "exam-examscroll",
                                "setting": null
                            }
                        ]
                    }
                ],
                "type": 2
            },
            {
                "page_name": "examblock",
                "label": "训练营",
                "uri": "/course/catalog:id",
                "template_name": "layout_2",
                "layout": [
                    {
                        "cid": "cid_1",
                        "blocks": [
                            {
                                "name": "train-coursecatalogtop",
                                "setting": null
                            }
                        ]
                    },
                    {
                        "cid": "cid_2",
                        "blocks": [
                            {
                                "name": "train-coursecataside",
                                "setting": null
                            }
                        ]
                    },
                    {
                        "cid": "cid_3",
                        "blocks": [
                            {
                                "name": "train-coursecataContent",
                                "setting": null
                            },{
                                "name":"train-coursecatafooter",
                                "setting":null
                            }
                        ]
                    }
                ],
                "type": 2
            }
            

        ]
        return new Promise(function (resolve) {
            resolve(data)
        })
    }
})