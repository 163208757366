<template>
 <div class="footers">
     <el-row class="footers-con" type="flex" justify="flex-start">
         <div class="left">
            <img class="left-logo" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhaohu/logo2.png" alt="">
            <div class="foot-w">助力家政行业提质扩容</div>
         </div>
         <div class="right">
            <span>邮编250000</span>
            <span>地址：山东省济南市历阳大街6号</span>
         </div>
     </el-row>
     <!-- <el-row class="footers-f">
         <span>版权所有： 伟东国信</span>
         <span>技术支持：伟东云教育</span>
     </el-row> -->
     <saas-nsr-footer :textColor="'#363636'" :bgColor="'#EAEAEA'"/>
 </div>
</template>

<script>
export default {
    name:'footers',
    props: {
    },
    data () {
        return {
        };
    },

    methods: {
    },

    mounted(){
    },
}

</script>
<style lang='stylus' scoped>
    @import "../../asset/css/footers.styl"
</style>
<style lang='stylus'>
</style>
