<template>
    <div class="accountSafe">
        <template v-if="!showApplyLogout">
            <ul class="safeul">
                <li class="safeli">
                    <div class="top">
                        <div class="imgwrapper">
                            <img src="./../asset/images/ic-dlmm@2x.png" alt="">
                        </div>
                        <div class="txt">
                            <div class="title">
                                登录密码
                            </div>
                            <div class="desc">
                                建议您定期更换密码，设置安全性高的密码可以使帐号更安全
                            </div>
                        </div>
                    </div>
                    <div class="bottom">
                        <el-button class="opebtn" type="primary" @click="modifyPswoDialog = true">
                            修改密码
                        </el-button>
                    </div>
                </li>
                <li class="safeli">
                    <div class="top">
                        <div class="imgwrapper">
                            <img src="./../asset/images/ic-aqsj@2x.png" alt="">
                        </div>
                        <div class="txt">
                            <div class="title">
                                {{userphone? `安全手机 ${userphone}`:'当前还未绑定安全手机'}}
                            </div>
                            <div class="desc">
                                安全手机可以用于登录帐号，重置密码或其他安全验证
                            </div>
                        </div>
                    </div>
                    <div class="bottom">
                        <el-button class="opebtn" type="primary" @click="toPhone">
                            {{userphone? '更换':'去绑定'}}
                        </el-button>
                    </div>
                </li>
                <li class="safeli">
                    <div class="top">
                        <div class="imgwrapper">
                            <img src="./../asset/images/ic-yx@2x.png" alt="">
                        </div>
                        <div class="txt">
                            <div class="title">
                                {{usermail? usermail:'当前还未绑定邮箱地址'}}
                            </div>
                            <div class="desc">
                                绑定邮箱可以用于登录帐号，重置密码或其他安全验证
                            </div>
                        </div>
                    </div>
                    <div class="bottom">
                        <el-button class="opebtn" type="primary" @click="toemail">
                            {{usermail? '更换':'去绑定'}}
                        </el-button>
                    </div>
                </li>
                <li class="safeli">
                    <div class="top">
                        <div class="imgwrapper">
                            <img src="./../asset/images/ic-zxzh@2x.png" alt="">
                        </div>
                        <div class="txt">
                            <div class="title">
                                注销账号
                            </div>
                            <div class="desc">
                                彻底关闭不再使用的该账号
                            </div>
                        </div>
                    </div>
                    <div class="bottom">
                        <el-button 
                            class="opebtn" 
                            type="primary" 
                            @click="showApplyLogout = true"
                        >
                            申请注销
                        </el-button>
                    </div>
                </li>
            </ul>
        </template>
        <template v-else>
            <div class="applyNotice" v-if="!logoutSuccess">
                <div class="header">
                    <img class="icon" src="./../asset/images/ic-yzx.png" alt="">
                    <p class="headertitle">我们将对以下信息进行审核</p>
                </div>
                <ul class="ul">
                    <li>
                        1、账号当前为有效状态
                    </li>
                    <li>
                        2、账号内无未完成状态订单
                    </li>
                    <li>
                        3、账户无任何纠纷
                    </li>
                    <li>
                        4、已完成所有课程或学习任务
                    </li>
                </ul>
                <div class="footer">
                    <el-checkbox v-model="isread">申请注销即表示您自愿放弃账号内所有虚拟资产并同意 </el-checkbox>
                    <span @click="readDialog = true" style="color:#316FFF;cursor:pointer"> 《账号注销须知》</span>
                    <div class="footertip">*注销成功后，您将无法再登录此账户，且以往的所有学习记录、订单记录都将无法恢复。</div>
                </div>
                <div class="confirmBtn">
                    <el-button type="primary" @click="confirmApplyLogout" :class="{grayBtn:!isread}">申请注销</el-button>
                    <el-button class="back" @click="backApplyLogout">返回</el-button>
                </div>
            </div>
            <div v-else class="applysuccess">
                <div class="header">
                    <img class="icon" src="./../asset/images/ic-xfcg.png" alt="">
                </div>
                <div class="successtip">
                    <p>您的账号注销申请已完成，即时起您将无法再登录此账户。</p>
                    <p>该账户可重新进行注册，但以往的所有学习记录、订单记录都将无法恢复。</p>
                </div>
                <div class="confirmBtn">
                    <el-button class="iknow" type="primary" @click="backHome">我知道了</el-button>
                </div>
            </div>
        </template>
        <!-- 修改密码 -->
        <el-dialog
            class="modifyPswoDialog modifyDialog"
            :visible.sync="modifyPswoDialog"
            :close-on-click-modal="false"
            width="400px"
            center>
            <div slot="title" class="title">
                修改登录密码
            </div>
            <el-input v-model="modifyPassword.password" :type="psdtype1" auto-complete="new-password" placeholder="请输入原登录密码">
                <template slot="append">
                    <img v-if="psdtype1 == 'text'" src="@assets/ic_zy@2x.png" @click="handleshowPsd(1)" alt="" />
                    <img v-else src="@assets/ic-by@2x.png" @click="handleshowPsd(1)" alt="" />
                </template>
            </el-input>
            <el-input v-model="modifyPassword.passwordNew" @blur="comparePassword" :type="psdtype2" auto-complete="new-password" placeholder="设置8至20位新登录密码">
                <template slot="append">
                    <img v-if="psdtype2 == 'text'" src="@assets/ic_zy@2x.png" @click="handleshowPsd(2)" alt="" />
                    <img v-else src="@assets/ic-by@2x.png" @click="handleshowPsd(2)" alt="" />
                </template>
            </el-input>
            <el-input v-model="modifyPassword.repsw" @blur="comparePassword" :type="psdtype3" auto-complete="new-password" placeholder="请再次输入登录密码">
                <template slot="append">
                    <img v-if="psdtype3 == 'text'" src="@assets/ic_zy@2x.png" @click="handleshowPsd(3)" alt="" />
                    <img v-else src="@assets/ic-by@2x.png" @click="handleshowPsd(3)" alt="" />
                </template>
            </el-input>
            <span slot="footer" class="dialog-footer">
                <el-button 
                    type="primary" 
                    :class="{grayBtn:!modifyPassword.password || !modifyPassword.passwordNew || !modifyPassword.repsw}"
                    @click="handleModifyPsd"
                >
                    确 定
            </el-button>
            </span>
        </el-dialog>
        <!-- 更换手机 -->
        <el-dialog
            class="modifyPhoneDialog modifyDialog"
            :visible.sync="modifyPhoneDialog"
            :close-on-click-modal="false"
            width="400px"
            center>
            <div class="progress">
                <div class="step1" :class="{highlight:modifyPhoneStep >= 1}">1
                    <div class="stepTxt">验证身份</div>
                </div>
                <div class="step2" :class="{highlight:modifyPhoneStep >= 2}">2
                    <div class="stepTxt">修改手机号</div>
                </div>
                <div class="step3" :class="{highlight:modifyPhoneStep >= 3}">3
                    <div class="stepTxt">完成更换</div>
                </div>
            </div>
            <template v-if="modifyPhoneStep == 1">
                <div class="desc">
                    <p class="desc1">已绑定的手机：{{userphone}}</p>
                    <p class="desc2">若该手机号已无法使用请联系客服</p>
                </div>
                <el-input class="noRightRadius" v-model="phoneCode"  placeholder="请输入验证码">
                    <template slot="append">
                        <span 
                            @click="postAccountGetAuthCode2('oldphone')" 
                            :style="{color:0 >= Countdown2 ?'#316fff':'#BFC8D9',cursor:'pointer'}"
                        >
                            {{ 0 >= Countdown2 ? '获取验证码' : Countdown2+'s' }}
                        </span>
                    </template>
                </el-input>
            </template>
            <template v-else-if="modifyPhoneStep == 2">
                <div class="form">
                    <el-input v-model="modifyAccount.account"  placeholder="请输入新的手机号"></el-input>
                    <el-input class="noRightRadius" v-model="modifyAccount.authCode"  placeholder="请输入验证码">
                        <template slot="append">
                            <span 
                                @click="postAccountGetAuthCode2('newphone')"
                                :style="{color:0 >= Countdown2 ?'#316fff':'#BFC8D9',cursor:'pointer'}"
                            >
                                {{ 0 >= Countdown2 ? '获取验证码' : Countdown2+'s' }}
                            </span>
                        </template>
                    </el-input>
                </div>
            </template>
            <template v-else>
                <div class="desc successdesc">
                    <div class="desc1">手机号码更换成功</div>
                    <div class="desc1">请在下次使用新的手机号码进行登录</div>
                </div>
            </template>
            <span slot="footer" class="dialog-footer">
                <el-button 
                    type="primary" 
                    :class="{grayBtn:modifyPhoneBtnColor}"
                    @click="handleModifyPhone(modifyPhoneStep)"
                >确 定
                </el-button>
            </span>
        </el-dialog>
        <!-- 更换邮箱 -->
        <el-dialog
            class="modifyEmailDialog modifyDialog"
            :visible.sync="modifyEmailDialog"
            :close-on-click-modal="false"
            width="400px"
            center>
            <div class="progress">
                <div class="step1" :class="{highlight:modifyEmailStep >= 1}">1
                    <div class="stepTxt">验证身份</div>
                </div>
                <div class="step2" :class="{highlight:modifyEmailStep >= 2}">2
                    <div class="stepTxt">修改邮箱账号</div>
                </div>
                <div class="step3" :class="{highlight:modifyEmailStep >= 3}">3
                    <div class="stepTxt">完成更换</div>
                </div>
            </div>
            <template v-if="modifyEmailStep == 1">
                <div class="desc">
                    <p class="desc1">已绑定的邮箱：{{usermail}}</p>
                    <p class="desc1">若该邮箱已无法使用请联系客服</p>
                </div>
                <el-input class="noRightRadius" v-model="mailCode"  placeholder="请输入验证码">
                    <template slot="append">
                        <span 
                            @click="postAccountGetAuthCode3('oldmail')"
                            :style="{color:0 >= Countdown3 ?'#316fff':'#BFC8D9',cursor:'pointer'}"
                        >
                        {{ 0 >= Countdown3 ? '获取验证码' : Countdown3+'s' }}
                    </span>
                    </template>
                </el-input>
            </template>
            <template v-else-if="modifyEmailStep == 2">
                <div class="form">
                    <el-input v-model="modifyAccount.account"  placeholder="请输入新的邮箱号"></el-input>
                    <el-input class="noRightRadius" v-model="modifyAccount.authCode"  placeholder="请输入验证码">
                        <template slot="append">
                            <span 
                                @click="postAccountGetAuthCode3('nowmail')"
                                :style="{color:0 >= Countdown3 ?'#316fff':'#BFC8D9',cursor:'pointer'}"
                            >
                            {{ 0 >= Countdown3 ? '获取验证码' : Countdown3+'s' }}
                            </span>
                        </template>
                    </el-input>
                </div>
            </template>
            <template v-else>
                <div class="desc successdesc">
                    <div class="desc1">邮箱更换成功</div>
                    <div class="desc1">请在下次使用新的邮箱进行登录</div>
                </div>
            </template>
            <span slot="footer" class="dialog-footer">
                <el-button 
                    type="primary" 
                    :class="{grayBtn:modifyEmailBtnColor}"
                    @click="handleModifyEmail(modifyEmailStep)"
                >
                确 定
                </el-button>
            </span>
        </el-dialog>
        <!-- 绑定邮箱 -->
        <el-dialog
            class="bindEmailDialog modifyDialog"
            :visible.sync="bindEmailDialog"
            :close-on-click-modal="false"
            width="400px"
            center>
            <div slot="title" class="title">
                绑定邮箱地址
            </div>
            <el-input v-model="bindAccount.account" placeholder="请输入邮箱地址"></el-input>
            <el-input class="noRightRadius" v-model="bindAccount.authCode" placeholder="请输入验证码">
                <template slot="append">
                    <span :style="{color: 0 >= Countdown1? '#316FFF':'#666',cursor:'pointer'}" @click="postAccountGetAuthCode1">{{ 0 >= Countdown1 ? '获取验证码' : Countdown1+'s' }}</span>
                </template>
            </el-input>
            <span slot="footer" class="dialog-footer">
                <el-button 
                    type="primary" 
                    :class="{grayBtn:!bindAccount.authCode}"
                    @click="handleBind"
                >
                确 定
            </el-button>
            </span>
        </el-dialog>
        <!-- 绑定手机号 -->
        <el-dialog
            class="bindPhoneDialog modifyDialog"
            :visible.sync="bindPhoneDialog"
            :close-on-click-modal="false"
            width="400px"
            center>
            <div slot="title" class="title">
                绑定安全手机
            </div>
            <el-input v-model="bindAccount.account" placeholder="请输入手机号"></el-input>
            <el-input class="noRightRadius" v-model="bindAccount.authCode" placeholder="请输入验证码">
                <template slot="append">
                    <span :style="{color: 0 >= Countdown4? '#316FFF':'#666',cursor:'pointer'}" @click="postAccountGetAuthCode4">{{ 0 >= Countdown4 ? '获取验证码' : Countdown4+'s' }}</span>
                </template>
            </el-input>
            <span slot="footer" class="dialog-footer">
                <el-button 
                    type="primary" 
                    :class="{grayBtn:!bindAccount.authCode}"
                    @click="handleBind"
                >
                    确 定
                </el-button>
            </span>
        </el-dialog>
        <!-- 账号注销 -->
        <el-dialog
            class="readDialog"
            :visible.sync="readDialog"
            width="800px"
            center>
            <div slot="title" class="title">
                账号注销须知
            </div>
            <div class="cancelNotice" style="overflow:auto">
                <p class="toUser">亲爱的用户：</p>
                <p style="text-indent: 32px;">您在申请注销流程中点击同意前，应当认真阅读《账户注销须知》（以下称“《注销须知》”）。</p>
                <p>请您务必审慎阅读、充分理解《注销须知》中相关条款内容，其中包括：</p>
                <p>1、与您约定免除或限制责任的条款；</p>
                <p>2、其他以粗体标识的重要条款。</p>
                <p>【特别提示】当您按照注销页面提示填写信息、阅读并同意本《注销须知》及相关条款与条件且完成全部注销程序后，即表示您已充分阅读、理解并接受本《注销须知》的全部内容。阅读本《注销须知》的过程中，如果您不同意相关任何条款和条件约定，请您立即停止账户注销程序。</p>
                <p>我们在此善意地提醒您，您注销账户的行为会给您的售后维权带来诸多不便，且注销账户后，您的个人信息我们只会在商城的前台系统中去除，使其保持不可被检索、访问的状态，或对其进行匿名化处理。您知晓并理解，根据相关法律规定，相关交易记录须在后台保存5年甚至更长的时间。</p>
                <h3>1、如果您仍执意注销账户，您的账户需同时满足以下条件：</h3>
                <p>（1）自愿放弃账户在商城系统中的资产和虚拟权益（包括但不限于账户余额、优惠券等），并确保账户中无无欠款；</p>
                <p>（2）账户内无未完成的订单和服务；</p>
                <p>（3）账户无任何纠纷，包括投诉举报或被投诉举报；</p>
                <h3>2、账户一旦被注销将不可恢复，请您在操作之前自行备份账户相关的所有信息和数据。请您保存好订单商品和服务的交易凭证、票据等资料，否则您有可能须支付额外的账户和订单查询费用，或无法享受售后服务。</h3>
                <h3>3、在账户注销期间，如果您的账户涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，有权自行终止本账户的注销而无需另行得到您的同意。</h3>
                <h3>4、注销账户，您将无法再使用本账户，也将无法找回您账户中及与账户相关的任何内容或信息，包括但不限于：</h3>
                <p>（1）您将无法登录、使用本账户；</p>
                <p>（2）本账户的个人资料和历史信息（包括但不限于用户名、头像、购物记录、关注信息等）都将无法找回；</p>
                <p>（3）您通过账户使用、授权登录或绑定账户后使用的相关或第三方的其他服务的所有记录将无法找回。您将无法再登录、使用前述服务，您曾获得的余额、优惠券、积分、资格、订单等视为您自行放弃，将无法继续使用。您理解并同意，无法协助您重新恢复前述服务。请您在提交注销申请前，务必先了解您须解绑的其他相关账户信息，具体可与我们的客服联系。</p>
                <h3>6、注销本账户并不代表本账户注销前的账户行为和相关责任得到豁免或减轻。</h3>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="iKnow" type="primary" @click="readDialog = false">知道了</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { isPhone, isEmail, passReg } from '@/utils/validator'
export default {
    name:'accountSafe',
    data(){
        return{
            showApplyLogout:false,
            modifyPswoDialog:false,
            modifyPhoneDialog:false,
            modifyEmailDialog:false,
            bindPhoneDialog:false,
            bindEmailDialog:false,
            readDialog:false,
            isread:false,
            logoutSuccess:false,
            modifyPassword:{
                password:'',
                passwordNew:'',
                repsw:''
            },
            modifyAccount:{
                account:'',
                authCode:''
            },
            bindAccount:{
                account:'',
                authCode:''
            },
            email:'',
            showPsd:false,
            psdtype1:'password',
            psdtype2:'password',
            psdtype3:'password',
            userphone:'',
            usermail:'',
            modifyPhoneStep:1,
            modifyEmailStep:1,
            phoneCode:'',//验证身份
            mailCode:'',//验证身份
            Countdown1:0,
            Countdown2:0,
            Countdown3:0,
            Countdown4:0,
        }
    },
    computed:{
        modifyPhoneBtnColor(){
            if(this.modifyPhoneStep == 1 && !this.phoneCode){
                return true
            }else if(this.modifyPhoneStep == 2 && !this.modifyAccount.authCode){
                return true
            }else{
                return false
            }
        },
        modifyEmailBtnColor(){
            if(this.modifyEmailStep == 1 && !this.mailCode){
                return true
            }else if(this.modifyEmailStep == 2 && !this.modifyAccount.authCode){
                return true
            }else{
                return false
            }
        },
        phoneNumber(){
            return this.$store.state.userInfo.phone
        }
    },
    mounted(){
        this.getCurrentUserAccount()
        this.getCurrentUserMail()
    },
    methods:{
        // 获取已绑定手机
        getCurrentUserAccount(){
            this.$api.usercenter.getCurrentUserAccount().then(res=>{
                if(res.data){
                    this.userphone = res.data
                }
            })
        },
        // 获取已绑定邮箱
        getCurrentUserMail(){
            this.$api.usercenter.getCurrentUserMail().then(res=>{
                if(res.data){
                    this.usermail = res.data
                }
            })
        },
        // 修改密码
        handleModifyPsd(){
            if(!this.modifyPassword.password || !this.modifyPassword.passwordNew || !this.modifyPassword.repsw) return
            if(!this.comparePassword()) return
            if(!passReg.test(this.modifyPassword.passwordNew)){
                this.$message.error('请设置8-20位，数字、大小写字母、字符组合密码')
                return
            }
            let params = {...this.modifyPassword}
            this.$api.usercenter.changePassword(params).then(res=>{
                if(res.data){
                    this.modifyPswoDialog = false
                    this.$message.success('密码修改成功')
                    //重置表单
                    this.modifyPassword = {
                        password:'',
                        passwordNew:'',
                        repsw:''
                    }
                }
            })
        },
        handleshowPsd(num){
            let type = this['psdtype'+num]
            if(type === 'password'){
                this['psdtype'+num] = 'text'
            }else{
                this['psdtype'+num] = 'password'
            }
        },
        comparePassword(){
            let result = true
            if(this.modifyPassword.passwordNew && this.modifyPassword.repsw){
                if(this.modifyPassword.passwordNew !== this.modifyPassword.repsw){
                this.$message.error('两次密码输入不一致')
                    result = false
                }
            }
            return result
        },
        // 绑定手机号、邮箱
        handleBind(){
            let params = {...this.bindAccount}
            this.$api.usercenter.bindUserAccount(params).then(res=>{
                if(res.data){
                    this.bindEmailDialog = false
                    this.getUserInfo()
                    this.getCurrentUserAccount()
                    this.getCurrentUserMail()
                    //重置表单
                    this.bindAccount = {
                        account:'',
                        authCode:''
                    }
                    this.Countdown1 = 0
                    this.Countdown4 = 0
                }
            })
        },
        toPhone(){
            if(!isPhone.test(this.bindAccount.account)){
                this.bindAccount.account = ''
                this.bindAccount.authCode = ''
            }
            if(!isPhone.test(this.modifyAccount.account)){
                this.modifyAccount.account = ''
                this.modifyAccount.authCode = ''
            }
            if(this.userphone){
                this.modifyPhoneDialog = true
            }else{
                this.bindPhoneDialog = true
            }
        },
        toemail(){
            if(!isEmail.test(this.bindAccount.account)){
                this.bindAccount.account = ''
                this.bindAccount.authCode = ''
            }
            if(!isEmail.test(this.modifyAccount.account)){
                this.modifyAccount.account = ''
                this.modifyAccount.authCode = ''
            }
            if(this.usermail){
                this.modifyEmailDialog = true
            }else{
                this.bindEmailDialog = true
            }
        },
        // 更换手机
        handleModifyPhone(step){
            if(step == 1){
                this.checkAuthCode('phone')
                this.Countdown2 = 0
                this.modifyAccount.account = ''
                this.modifyAccount.authCode = ''
            }else if(step == 2){
                this.unBindUserAccount('phone')
            }else{
                this.modifyPhoneDialog = false
                this.modifyPhoneStep = 1
                //重置表单
                this.modifyAccount.account = ''
                this.modifyAccount.authCode = ''
                this.Countdown2 = 0
            }
        },
        // 更换邮箱
        handleModifyEmail(step){
            if(step == 1){
                this.checkAuthCode('mail')
                this.Countdown3 = 0
                this.modifyAccount.account = ''
                this.modifyAccount.authCode = ''
            }else if(step == 2){
                this.unBindUserAccount('mail')
            }else{
                this.modifyEmailDialog = false
                this.modifyEmailStep = 1
                //重置表单
                this.Countdown3 = 0
                this.modifyAccount.account = ''
                this.modifyAccount.authCode = ''
            }
        },
        // 验证身份
        checkAuthCode(type){
            let result = false
            let account,authCode,step
            if(type === 'phone'){
                account = this.phoneNumber
                authCode = this.phoneCode
                step = 'modifyPhoneStep'
            }else{
                account = this.usermail
                authCode = this.mailCode
                step = 'modifyEmailStep'
            }   
            let data = {account,authCode}
            this.$api.usercenter
                .checkAuthCode(data)
                .then(res => {
                    result = res.data
                    if(result){
                        this[step] = 2
                    }
                });
        },
        // 更换手机号、邮箱
        unBindUserAccount(type){
            let step = type === 'phone'? 'modifyPhoneStep':'modifyEmailStep'
            let params = {...this.modifyAccount}
            this.$api.usercenter.unBindUserAccount(params).then(res=>{
                if(res.data){
                    this[step] = 3
                    type === 'phone' && this.getUserInfo()
                    this.getCurrentUserAccount()
                    this.getCurrentUserMail()
                }
            })
        },
        getUserInfo () {
            this.$api.usercenter.getAccountUserInfo({}).then(res => {
                this.$store.commit('saveUserInfo',res.data)
                localStorage.setItem('userInfo',JSON.stringify(res.data))
            })
        },
        // 申请注销
        confirmApplyLogout(){
            if(!this.isread) return
            let token = localStorage.getItem('token')
            this.$api.usercenter.cleanAccount({token}).then(res=>{
                if(res.data){
                    this.logoutSuccess = true
                    localStorage.clear()
                }
            })
        },
        backApplyLogout(){
            this.showApplyLogout = false
            this.isread = false
        },
        // 绑定邮箱 发送验证码
        async postAccountGetAuthCode1() {
            const data = { account: this.bindAccount.account,type:2 };
            this.bindAccount.authCode = null;
            if (isEmail.test(this.bindAccount.account)) {
                if (this.Countdown1 > 0) return;
                this.Countdown1 = 60;
                this.setCountdown1();
                this.$sensors.track('UM_Click_Verification_Code_Button')
                this.$api.usercenter.getAuthCodePublic({ data })
            }else{
                this.$message.error('请输入正确的邮箱账号')
            }
        },
        // 更换手机 发送验证码
        async postAccountGetAuthCode2(type) {
            let account = type === 'oldphone'? this.phoneNumber:this.modifyAccount.account
            const data = { account,type:2 };
            this.modifyAccount.authCode = null;
            if (isPhone.test(account)) {
                if (this.Countdown2 > 0) return;
                this.Countdown2 = 60;
                this.setCountdown2();
                this.$sensors.track('UM_Click_Verification_Code_Button')
                this.$api.usercenter.getAuthCodePublic({ data })
            }else{
                this.$message.error('请输入正确的手机号')
            }
        },
        // 更换邮箱 发送验证码
        async postAccountGetAuthCode3(type) {
            let account = type === 'oldmail'? this.usermail:this.modifyAccount.account
            const data = { account,type:2 };
            this.modifyAccount.authCode = null;
            if (isEmail.test(account)) {
                if (this.Countdown3 > 0) return;
                this.Countdown3 = 60;
                this.setCountdown3();
                this.$sensors.track('UM_Click_Verification_Code_Button')
                await this.$api.usercenter.getAuthCodePublic({ data })
            }else{
                this.$message.error('请输入正确的邮箱账号')
            }
        },
        // 绑定手机 发送验证码
        async postAccountGetAuthCode4() {
            const data = { account: this.bindAccount.account,type:2 };
            this.bindAccount.authCode = null;
            if (isPhone.test(this.bindAccount.account)) {
                if (this.Countdown4 > 0) return;
                this.Countdown4 = 60;
                this.setCountdown4();
                this.$sensors.track('UM_Click_Verification_Code_Button')
                this.$api.usercenter.getAuthCodePublic({ data })
            }else{
                this.$message.error('请输入正确的手机号')
            }
        },
        setCountdown1() {
            this.CountdownInterval1 = setInterval(() => {
                this.Countdown1--;
                if (this.Countdown1 <= 0) {
                clearInterval(this.CountdownInterval1);
                }
            }, 1000);
        },
        setCountdown2() {
            this.CountdownInterval2 = setInterval(() => {
                this.Countdown2--;
                if (this.Countdown2 <= 0) {
                clearInterval(this.CountdownInterval2);
                }
            }, 1000);
        },
        setCountdown3() {
            this.CountdownInterval3 = setInterval(() => {
                this.Countdown3--;
                if (this.Countdown3 <= 0) {
                clearInterval(this.CountdownInterval3);
                }
            }, 1000);
        },
        setCountdown4() {
            this.CountdownInterval4 = setInterval(() => {
                this.Countdown4--;
                if (this.Countdown4 <= 0) {
                clearInterval(this.CountdownInterval4);
                }
            }, 1000);
        },
        backHome(){
            this.$router.push('/');
        }
    }
}
</script>

<style lang="stylus" scoped>
.accountSafe
    .safeul
        .safeli
            border: 1px solid #eeeeee
            padding: 30px 40px 24px
            margin-bottom 16px
            .top
                display: flex
                .imgwrapper
                    display inline-block
                    width 44px
                    height 44px
                    >img
                        width 100%
                        height 100%
                .txt
                    margin-left 5px
                    justify-content center
                    height 44px
                    .title
                        margin -2px 0 8px 0
                        font-size: 18px
                        color: #333333
                        font-weight: 500
                    .desc
                        font-size: 14px
                        color: #999999
            .bottom
                margin 24px 0 0 49px
                .opebtn 
                    padding 6px 16px
                    width 88px
                    height 32px
                /deep/ .el-button--primary span
                    font-size: 14px
.grayBtn
    background #DEDDDE
    border none
.modifyDialog
    .title
        margin-top 20px
        font-size 24px
        font-weight 500
        color #1B2257
    /deep/ .el-dialog__body
        padding 10px 30px 15px 
        // min-height 230px
        box-sizing border-box
    /deep/ .el-input-group
        display inline-flex
    /deep/ .el-input__inner
        margin-bottom 12px
        width 340px
        height 52px
        border-radius 4px
    /deep/ .el-input__inner::placeholder
        font-family PingFangSC-Regular, PingFang SC
        font-size 16px
    /deep/ .el-button--primary
        width 340px
        height 52px
        >span
            font-size 18px
    /deep/ .el-input-group__append
        >img
            width 22px
            height 22px
    .noRightRadius /deep/ .el-input__inner
        border-radius 4px 0 0 4px
        &:focus
            border-color #DCDFE6

.modifyPswoDialog.modifyDialog
    /deep/ .el-input-group__append
        box-sizing border-box
        position absolute
        right 12px
        top 16px
        border none
        background #fff
.bindEmailDialog.modifyDialog,.bindPhoneDialog.modifyDialog
    /deep/ .el-input-group__append
        width 121px
        height 52px
        background #fff
        line-height 52px
        text-align center
        box-sizing border-box
        padding 0
.modifyEmailDialog,.modifyPhoneDialog
    .progress
        display flex
        justify-content center
        .step1,.step2,.step3
            position relative
            width 30px
            height 30px
            background #E8E8E8
            border 2px solid #F8F8F8
            border-radius 50%
            text-align center
            line-height 30px
            font-size 16px
            font-weight 600
            color #fff
            .stepTxt
                position absolute
                left 50%
                top 26px
                transform translateX(-50%)
                width 75px
                font-size 12px
                color #E8E8E8
            &.highlight
                background #316FFF
                border 2px solid #D5E1FF
            &.highlight
                .stepTxt
                    color #316FFF
            &.highlight::before
                background #316FFF
            &.highlight::after
                background #316FFF
        .step1
            &::before
                content ''
                display inline-block
                width 24px
                height 2px
                background #E8E8E8
                position absolute
                left -28px
                top 50%
                transform translatey(-50%)
            &::after
                content ''
                display inline-block
                width 36px
                height 2px
                background #E8E8E8
                position absolute
                right -40px
                top 50%
                transform translatey(-50%)
        .step2
            margin 0 76px
            &::before
                content ''
                display inline-block
                width 36px
                height 2px
                background #E8E8E8
                position absolute
                left -40px
                top 50%
                transform translatey(-50%)
            &::after
                content ''
                display inline-block
                width 36px
                height 2px
                background #E8E8E8
                position absolute
                right -40px
                top 50%
                transform translatey(-50%)
        .step3
            &::before
                content ''
                display inline-block
                width 36px
                height 2px
                background #E8E8E8
                position absolute
                left -40px
                top 50%
                transform translatey(-50%)
            &::after
                content ''
                display inline-block
                width 20px
                height 2px
                background #E8E8E8
                position absolute
                right -28px
                top 50%
                transform translatey(-50%)
    .desc
        margin-top 40px
        margin-bottom 24px
        text-align center
        .desc1,.desc2
            font-size 16px
            color #666666
    .desc.successdesc
        margin-top 70px
    .form
        margin-top 40px
.modifyEmailDialog.modifyDialog,.modifyPhoneDialog.modifyDialog
    /deep/ .el-input-group__append
        width 121px
        height 52px
        background #fff
        line-height 52px
        text-align center
        box-sizing border-box
        padding 0  
    /deep/ .el-button--primary
        width 340px
        height 52px
        >span
            font-size 18px
.applyNotice
    .header
        margin-top 30px
        margin-bottom 10px
        text-align center
        .icon
            width 150px
            height 150px
        .headertitle
            font-size 24px
            font-weight 500
            color #333333
    .ul
        margin 30px 0 20px 146px
        margin-top 30px
        margin-bottom 20px
        >li
            font-size 16px
            color #666666
            line-height 32px
    .footer
        margin-left 146px
        .footertip
            font-size 16px
            color #FF5454
            line-height 22px
    .confirmBtn
        margin-top 22px
        text-align center
        /deep/ .el-button
            width 160px
            height 40px
        /deep/ .el-button.back
            margin-left 24px
            >span
                font-size 16px
.applysuccess
    margin-top 105px
    text-align center
    .header
        .icon
            width 150px
            height 150px
    .successtip
        margin 24px 0
        >p
            font-size 16px
            color #666
            line-height 26px
    .confirmBtn
        /deep/ .el-button.iknow
            width 160px
            height 40px
.readDialog
    /deep/ .el-dialog {
        height 600px
    }
    /deep/ .el-dialog__header {
        padding:40px 0 0 0 
        border-radius 10px 10px 0 0 
    }
    /deep/ .el-dialog__footer {
        padding: 30px;
        border-radius 0 0 10px 10px
    }
    /deep/ .el-dialog__body{
        padding 20px 24px 0 24px
    }
    /deep/ .el-button{
        // padding: 0px 20px;
        width 240px
        height 40px
        background #316FFF
    }

    .title
        font-size 24px
        font-weight 700
        color #1B2257
        line-height 34px
    .cancelNotice
        height 405px
        font-size 16px
        >p,h3
            font-size 16px
        >h3
            margin 6px 0
        >p
            line-height 26px
            color #666
        .toUser
            margin-bottom 6px
            font-size 16px    
            font-weight 700
            line-height 22px
            color #333 
</style>