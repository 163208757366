<template>
 <div class='enterprise'>
     <img class="title-img" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/fwqy.png" alt="">
    <!-- <h2 class="enterprise-titile">我们服务的企业</h2>
    <div class="enterprise-bgc"></div>
    <p class="enterprise-con">1000+家企业共同选择的合作伙伴</p> -->
    <el-carousel class="list"  height="207px" :interval="5000" arrow="always">
        <el-carousel-item v-for="(item, index) in imgUrlList" :key="index">
            <img class="list-item-img" :src="item.imgUrl" alt="">
        </el-carousel-item>
    </el-carousel>
     <!-- <div class="enterprise-img">
         <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/group4.png" alt="">
     </div> -->
 </div>
</template>

<script>
export default {
    name:'enterprise',
    props: {
    },
    data () {
        return {
            imgUrlList: [
                {imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/group4.png'},
                {imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/group5.png'}
            ],
        };
    },

    methods: {
    },

    mounted(){
    },
}

</script>
<style lang='stylus' scoped>
    @import "../../asset/css/enterprise.styl"
</style>
<style lang='stylus'>
    .enterprise {
        /deep/.el-carousel__container {
            width: 1100px;
            margin: 0 50px;
            .el-carousel__arrow {
                content: none;
                background-color: rgba(31,45,61,0);
                width: 14px;
                height: 26px;
                background-repeat: no-repeat;
                background-position: center 0px;
                background-size: cover;
                i {
                    display: none;
                }
            }
            .el-carousel__arrow--left {
                background-image: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/left.png');
                left: -50px;
            }
            .el-carousel__arrow--right {
                background-image: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/right.png');
                right: -50px;
            }
        }
        /deep/.el-carousel__indicators--horizontal {
            display: none;
        }
    }
</style>
