<template>
    <div class="standard-list-page">
        <div class="s-body">
            <div class="flex content">
                <div class="left-wrap">
                    <div
                        class="type-name-item type-title-item"
                        @click="jumpClick('', -1, '全部标准认证')"
                    >
                        全部标准分类
                    </div>
                    <ul class="list">
                        <!-- <li> -->
                        <li
                            v-for="(item, index) in categoryTreeData"
                            :key="item.id"
                            class="clearfix"
                            :class="{ active: index == currents }"
                            @click="jumpClick(item.code, index, item.name)"
                        >
                            <div class="type-name-item lr-flex flex">
                                <span>{{ item.name }}(<b>{{ item.number }}</b>)</span>
                                <i class="el-icon-arrow-right" />
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="list-wrap">
                    <div class="flex search-box">
                        <el-input
                            v-model="params.basicName"
                            placeholder="搜索关键字"
                            style="width: 391px"
                            prefix="ios-search"
                            prefix-icon="el-icon-search"
                        />
                        <el-button
                            type="primary"
                            round
                            @click="jumpClick('', -1, '全部标准认证')"
                        >
                            搜索
                        </el-button>
                    </div>
                    <div
                        v-for="item in standardData"
                        :key="item.id"
                        class="list-item"
                        @click="jumpDeatil(item.id, item.categoryName)"
                    >
                        <div class="flex lr-flex top-info">
                            <p>
                                <span class="num"> {{ item.basicNum }}</span>
                                <span class="name">
                                    {{ item.basicName }}
                                </span>
                            </p>
                            <div
                                v-if="item.basicStatus === 1"
                                class="status1"
                            >
                                {{ statusFormat(item.basicStatus) }}
                            </div>
                            <div
                                v-else-if="item.basicStatus === 0"
                                class="status0"
                            >
                                {{ statusFormat(item.basicStatus) }}
                            </div>
                            <div
                                v-else
                                class="status2"
                            >
                                {{ statusFormat(item.basicStatus) }}
                            </div>
                        </div>
                        <p class="name-en">
                            英文名称：{{ item.basicEnglishName }}
                        </p>
                        <p class="dates">
                            <span>发布日期：{{
                                timeFormat(item.basicPublishTime)
                            }}</span>
                            <span
                                class="dates-span"
                            >实施日期：{{
                                timeFormat(item.basicImplementTime)
                            }}</span>
                        </p>
                    </div>
                    <div class="page-wrap">
                        <el-pagination
                          background
                          :current-page="params.page"
                          :total="pageTotal"
                          :page-size="params.rows"
                          layout="prev, pager, next"
                          @current-change="changePage"
                          @size-change="changePage"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>



export default {
    name: 'standardList',
    data() {
        return {
            categoryTreeData: [], // 标准分类
            standardData: [],
            params: {
                basicNum: '',
                basicName: '',
                englishName: '',
                categoryId: '',
                categoryCode: '',
                page: 1,
                rows: 10,
            },
            pageTotal: 20,
            currents: -1,
            baiozhun: '全部标准认证',
        };
    },
    // activated() {
    //     this.getFirstCategoryTree();
    //     this.getStandardList();
    // },
    created() {
        // let obj = {
        //     a: { b:1},
        //     c: 2,
        //     d: {
        //         f:2,
        //         m: {
        //             s: 1,
        //             n: {
        //                 s: 3
        //             }
        //         }
        //     }
        // }
    },
    mounted() {
        this.getFirstCategoryTree();
        this.getStandardList();
        
    },
    methods: {
        getFirstCategoryTree() {
            this.$api.standard.getFirstCategoryTree().then((res) => {
                if (res.isSuccess) {
                    console.log('res', res);
                    this.categoryTreeData = res.obj;
                }
            });
        },
        getStandardList() {
            this.$api.standard.getStandardList(this.params).then((res) => {
                if (res.isSuccess) {
                    console.log('res', res);
                    this.standardData = res.rows;
                    this.pageTotal = res.total;
                }
            });
        },
        statusFormat(status) {
            /* eslint-disable */
            let txt = "";
            if (status === 1) {
                txt = "现行";
            } else if (status === 2) {
                txt = "废止";
            } else {
                txt = "即将实施";
            }
            return txt;
        },
        timeFormat(val) {
            let text = "";
            if (val) {
                text = val.split("T")[0];
            } else {
                text = val;
            }
            return text;
        },
        // 搜索关键字
        jumpClick(code, index, name) {
            console.log("id", code);
            this.params.categoryCode = code;
            this.currents = index;
            this.baiozhun = name;
            this.getStandardList();
        },
        jumpDeatil(id, name) {
            this.$router.push({
                path: "/standard/detail",
                query: {
                    id,
                    name
                }
            });
        },
        changePage(index) {
            console.log(index);
            this.params.page = index;
            this.getStandardList();
        },
        changeSize(size) {
            this.params.rows = size;
            this.getStandardList();
        }
    }
};
</script>

<style scoped lang="stylus">
@import './../asset/css/standard';

.is-actived {
    background: crimson;
}
</style>
