<template>
    <div class="homePage">
        <homeBanner />
        <!--<projectList />-->
        <!--<guarantee />-->
        <college />
        <enterprise />
        <footers />
    </div>
</template>

<script>
import homeBanner from '../block/front/homeBanner.vue'
// import projectList from '../block/front/projectList.vue'
// import guarantee from '../block/front/guarantee.vue'
import college from '../block/front/college.vue'
import enterprise from '../block/front/enterprise.vue'
import footers from '../block/front/footers'
export default {
  name: 'zhongzhi_homepage',
  show: true,
  components: {
      homeBanner,
      // projectList,
      // guarantee,
      college,
      enterprise,
      footers
  },
    data() {
        return {
        };
    },
    mounted() {

    },
    created() {
    },
    beforeDestroy() {

    },
    methods: {

    }
};
</script>
<style lang="stylus">
</style>
<style lang="stylus" scoped>
@import '../asset/css/zhongzhiHomepage'
</style>
