<template>
  <div>
    <component :is="nav.crowd" :nav="nav" />
  </div>
</template>

<script>
import NAV from './../../config'
const head = require.context('./crowd/', false, /\.vue$/)
const components = {}
head.keys().forEach(key => {
  const Template = head(key).default
  if (Template.name === NAV.navigation.crowd) {
    components[Template.name] = Template
  }
})
export default {
  components,
  data () {
    return {
      nav: NAV.navigation
    }
  }
}
</script>

<style>

</style>