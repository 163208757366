<template>
    <div class="junminNav-page-header clearfix">
        <div class="page-header-top">
           <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/junmin/junmin-header.png" alt="">
        </div>
        <div class="page-header-nav ">
            <div class="nav-name clearfix">
                <div class="nav-name-ul fl">
                    <div class="nav-name-ul-li"
                        v-for="(item, index) in jmnavName"
                        :key="index"
                    >
                        <span
                            :class="isSelect === item ? 'active' : ''"
                            @click="selectNav(item)"
                        >
                            {{item.name}}
                        </span>
                        <ul v-if="item.subList && item.subList.length>0">
                            <li v-for="(childItem,childIndex) in item.subList" @click="selectNav(childItem)" :key="childIndex">{{childItem.name}}</li>
                        </ul>
                    </div>
                </div>
                <div class="fr clearfix">
                    <div v-if="!token" class="un-login" @click="jumpLogin(1)">
                        退役军人登录
                    </div>
                    <div v-if="!token" class="un-login" @click="jumpLogin(2)">
                        服务机构登录
                    </div>
                    <div v-else class="un-logosuccss clearfix">
                        <img class="un-logosuccss-head fl" :src="subUserInfo.headImage || require('../../../../assets/images/junminhead.png')" alt="" width="32px" height="32px">
                        <div class="un-logosuccss-main fl">
                            <p :class="!subUserInfo.userIdentity?'headTop':''">{{ subUserInfo.name || '--' }}</p>
                            <p class="userIdentity" v-if="subUserInfo.userIdentity">{{ subUserInfo.userIdentity}}</p>
                        </div>
                        <img class="un-logosuccss-jt fl" src="../../../../assets/images/junminjt.png" alt="" width="10px">
                        <ul class="junmin-dropdown">
                            <li @click="showPersonalCenter">个人中心</li>
                            <li @click="signOut">退出登录</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="fixed-link">
            <div class="fixed-link-top" @click="backTop">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/arrow-top.png" alt="">
                <el-button type="text">回到顶部</el-button>
            </div>
            <div class="fixed-link-bottom">
                <div class="item" @click="qimoChatClick()">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/link.png" alt="">
                    <p>在线咨询</p>
                </div>
                <!-- <div class="item">
                   <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/er.png" alt="">
                   <p>扫码关注</p>
                </div> -->
            </div>

        </div>
        <img v-if="theme==='junmin' && isservice" class="fixed-link-bottom-service" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/junmin/service.png" alt="">
       <div class="online-link" v-if="showOnTip1" :style="showOnTip2?'bottom:70px':'120px'">
           <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/wechat.jpg" alt="">
           <div>
               <p>咨询电话:400-8555-686</p>
               <p>或扫描二维码关注公众号进行咨询</p>
           </div>
       </div>
    </div>
</template>
<script>
import URL from '../../../../config/url';
import Vue from 'vue';
export default {
    name: 'junminNav',
    components: {
    },
    data() {
        return {
            isservice:false,
            jmnavName: [],
            isSelect: null,
            token: '',
            checkJunminIsSaveInfo:null,
            theme:"",
            showOnTip1:false,
            showOnTip2:false,
        }
    },
    watch: {
        $route() {
            this.getNavs();
        }
    },
    computed:{
        // 不同机构
        subUserInfo(){
            return this.$store.state.subUserInfo
        }
    },
    created() {
        this.getNavs();
        this.theme=localStorage.getItem('theme');
        this.token = localStorage.getItem('token');
        this.checkJunminIsSaveInfo=localStorage.getItem('checkJunminIsSaveInfo') || null;
        if(localStorage.getItem('token')){
            this.getSubUserInfo();
        }
    },
    methods: {
        /**
         *@functionName:
         *@description:在线咨询
         *@date: 2021-05-11 10:18:31
        */
        qimoChatClick(){
            var subUserInfo = JSON.parse(localStorage.getItem('subUserInfo'));
            var userInfo = JSON.parse(localStorage.getItem('userInfo'));
            var customField = {};
            if(subUserInfo || userInfo){
                customField = {
                    "姓名":localStorage.getItem('subUserInfo') ?  (subUserInfo && subUserInfo.name) : userInfo.name,
                    "手机号":localStorage.getItem('subUserInfo') ? (subUserInfo && subUserInfo.phone) :userInfo.phone,
                    "邮箱":localStorage.getItem('subUserInfo') ? (subUserInfo && subUserInfo.mail) : userInfo.mail,
                    "所在机构":localStorage.getItem('subUserInfo') ? (subUserInfo && subUserInfo.branchName) :userInfo.branchName,
                  };
                window.qimoClientId = {
                    nickName:localStorage.getItem('subUserInfo') ?  (subUserInfo && subUserInfo.name) : userInfo.name,
                    userId : localStorage.getItem('subUserInfo') ?  (subUserInfo && subUserInfo.accountId) : userInfo.accountId,
                    customField: JSON.stringify(customField)
                };
            }
            window.qimoChatClick()
        },
        // showOnLine2(){
        //     this.showOnTip2  = !this.showOnTip2;
        //     this.showOnTip1 = false;
        // },
        // showOnLine3(){
        //     this.showOnTip2  = !this.showOnTip2;
        //     this.showOnTip1 = false;
        // },
        backTop(){
          var timer = setInterval(function(){
            let osTop = document.documentElement.scrollTop || document.body.scrollTop;
            let ispeed = Math.floor(-osTop / 5);
            document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
            this.isTop = true;
            if(osTop === 0){
              clearInterval(timer);
            }
          },30)
        },
        getSubUserInfo(){
            this.$api.usercenter.findUser().then(res => {
                if(res.code == 0){
                    this.$store.commit('saveSubUserInfo',res.data)
                    localStorage.setItem('subUserInfo',JSON.stringify(res.data))
                }
            })
        },
        showPersonalCenter(){
            this.$router.push({
                path:'/personalCenter'
            })
        },
        getNavs() {
            if (this.jmnavName.length===0) {
                this.$api.home.getMenuNew(URL.confUrl).then((res) => {
                    if (res.data.menuList) {
                        this.jmnavName = res.data.menuList;
                        if(this.jmnavName.length>0){
                            this.isSelect=this.jmnavName[0];
                        }
                        this.getActiveNav();
                    }
                })
            } else {
                this.getActiveNav();
            }
        },
        getActiveNav() {
            this.jmnavName.forEach((d)  => {
                if (d.url === this.$route.path || this.$route.path.indexOf(d.url) !== -1) {
                    this.isSelect = d;
                }
            })
        },
        signOut(){
            window.qimosdk.closeChatBox();
            sessionStorage.setItem('expect', 'yes')
            this.newVal=sessionStorage.getItem('signOutStorage')
            if(this.newVal=='login' && this.$route.path==='/course/testPaper') {
                Vue.prototype.resetSetItem('signOutStorage', 'bullet')
            } else {
                this.pathList()
            }
        },
        async pathList() {
            const frontendConfig = localStorage.getItem('frontendConfig') ? JSON.parse(localStorage.getItem('frontendConfig')) : ''
            let path = frontendConfig ? frontendConfig.value : '';
            let theme = localStorage.getItem('theme') || '';
            const params = { token: localStorage.getItem('token') }
            await this.$api.usercenter.postAccounLogout({ params })
            sessionStorage.clear()
            localStorage.clear()
            if (
                path &&
                theme &&
                theme !== 'yingji'
            ) {
                // this.$router.push(path);
                window.location.replace('/'+path);
            } else {
                this.$router.push('/login');
            }

        },
        selectNav(item) {
            console.log('item.name===', item.name)
            // item.url = '/courseGoods'
            this.checkJunminIsSaveInfo=localStorage.getItem('checkJunminIsSaveInfo') || null;
            if(localStorage.getItem('token') && this.checkJunminIsSaveInfo==='false'){
                return;
            }
            if(item.is_login===1 && !localStorage.getItem('token') && item.name!=='职业测评'){
                this.$router.push({
                    name: 'login',
                    query:{
                        name:this.isSelect.url
                    }
                })
            }else{
                if (item.url.indexOf('http') === -1) {
                    this.isSelect = item;
                    if(!localStorage.getItem('token') && item.name==='职业测评'){
                        this.$router.push({name:'evaluationNot'})
                    }else if(item.name==='首页'){
                        this.$router.push({
                            path: '/',
                        });
                    }else{
                        this.$router.push({
                            path: item.url,
                            query:{
                                name:item.url,
                                //tabName: item.url == '/courseGoods' ? encodeURIComponent('创业培训课程') : ''
                            }
                        });
                    }

                } else {
                    window.open(item.url, 'newW');
                }
            }
        },
        jumpLogin(type) {
            window.qimosdk.closeChatBox();
            if(type===1){
                this.$router.push({
                    name: 'login',
                    // query:{
                    //     name:this.isSelect.url
                    // }
                })
            }else{
                const hostname = window.location.hostname;
                if(hostname==='qdjm.saas-pc.testing3.wdeduc.com'){
                    window.open('http://qdjm.saas-main.testing3.wdeduc.com/entrance/register');
                }else if(hostname==='qdjm.saas-pc.fat.wdeduc.com'){
                    window.open('http://qdjm.saas-main.fat.wdeduc.com/entrance/register');
                }else if(hostname==='qdjm.saas-pc.uat.wdeduc.com'){
                    window.open('http://qdjm.saas-main.uat.wdeduc.com/entrance/register');
                }else if(hostname==='qdjm.wdeduc.com'){
                    window.open('https://zpdwqdjm.wdeduc.com/entrance/register');
                }else{
                    window.open('http://qdjm.saas-main.testing3.wdeduc.com/entrance/register');
                }
                // window.open('http://qdjm.saas-main.testing3.wdeduc.com/entrance/login');

            }

        },
    }
}
</script>
<style lang="stylus" scoped >
.junminNav-page-header {
    cursor: pointer;
    /* position: fixed; */
    width: 100%;
    /* z-index: 666; */
    background: #fff;
}
.junminNav-page-header .page-header-top {
    margin: 0px auto;
    height: 239px;
    line-height: 239px;
    width: 100%;
}
.junminNav-page-header .page-header-top img{
    width: 100%;
    height: 239px;
    object-fit cover
    --o-object-fit cover
}
.junminNav-page-header .page-header-nav {
    width: 100%;
    background-color: #1972FF;
    height: 56px;
}
.junminNav-page-header .page-header-nav .nav-name {
    margin: 0px auto;
}
.junminNav-page-header .page-header-nav .nav-name {
    width: 1200px;
}
.junminNav-page-header .page-header-nav .nav-name .nav-name-ul-li {
    display: inline-block;
    vertical-align: top;
    line-height: 56px;
    height: 56px;
    position: relative;

}

.junminNav-page-header .page-header-nav .nav-name .nav-name-ul-li ul{
    position: absolute;
    left: 5px;
    top: 56px;
    width: 113px;
    border-radius: 7px;
    display: none;
    z-index: 5;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.22);
}
.junminNav-page-header .page-header-nav .nav-name .nav-name-ul-li:hover ul{
    display: block;
}
.junminNav-page-header .page-header-nav .nav-name .nav-name-ul-li ul li{
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4A4A4A;
    background: #FFFFFF;
}
.junminNav-page-header .page-header-nav .nav-name .nav-name-ul-li ul li:hover{
    background: #1972FF;
    color: #FFFFFF;
}
.junminNav-page-header .page-header-nav .nav-name .nav-name-ul-li ul li:first-child{
    border-radius: 7px 7px 0px 0px;
    margin-top: 4px;
}
.junminNav-page-header .page-header-nav .nav-name .nav-name-ul-li ul li:last-child{
    border-radius: 0px 0px 7px 7px;
}
.junminNav-page-header .page-header-nav .nav-name .nav-name-ul-li > span {
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    display: inline-block;
    width: 123px;
    text-align: center;
    cursor: pointer;
}
.junminNav-page-header .page-header-nav .nav-name .nav-name-ul-li:hover {
    background-color: #2E5AD1;
}
.junminNav-page-header .page-header-nav .nav-name .active {
    background: #2E5AD1;
}
.junminNav-page-header .page-header-nav .un-login {
    width: 90px;
    height: 28px;
    line-height: 28px;
    background: #FFFFFF;
    border-radius: 14px;
    cursor: pointer;
    margin-top: 14px;
    font-size: 12px;
    font-weight: 400;
    color: #2E5AD1;
    margin-left: 10px;
    text-align: center;
    float:right;
}
.un-logosuccss{
    color: #FFFFFF;
    position: relative;
    height: 56px;
}
.un-logosuccss-head{
    margin-top: 12px;
    border-radius: 50%;
}
.un-logosuccss-main{
    margin: 7px 14px 0 11px;
}

.un-logosuccss-main p:first-child{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
}
.un-logosuccss-main p.headTop{
    line-height: 40px;
}
.un-logosuccss-main .userIdentity{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 58px;
    max-width: 130px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 2px;
    line-height: 20px;
    text-align: center;
    padding: 0 6px 0 4px;
    box-sizing: border-box;
}
.un-logosuccss-jt{
    margin-top: 25px;
}

.junmin-dropdown{
    position: absolute;
    right: 0px;
    top: 56px;
    width: 113px;
    border-radius: 7px;
    display: none;
    z-index: 4;
    box-shadow:0 2px 12px 0 rgba(0 0 0 0.1)
}
.un-logosuccss:hover .junmin-dropdown{
    display: block;
}
.junmin-dropdown li{
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4A4A4A;
    background: #FFFFFF;
}
.junmin-dropdown li:hover{
    background: #1972FF;
    color: #FFFFFF;
}
.junmin-dropdown li:first-child{
    border-radius: 7px 7px 0px 0px;
    margin-top: 4px;
}
.junmin-dropdown li:last-child{
    border-radius: 0px 0px 7px 7px;
}
.fixed-link
    position:fixed;
    z-index:4;
    bottom:140px;
    right:10px
    background:#316FFF
    border-radius 8px
    font-size:16px;
    padding:4px;
    width:104px;
    box-sizing:border-box;
    text-align center;
    .fixed-link-top
        display:flex;
        flex-direction:column;
        align-items: center;
        padding:14px 0;
        p
            color:#fff;
        img
            width:25px;
            margin-bottom:12px;
        button
            padding:0;
            /deep/span
                color:#fff;
                font-size:16px;
    .fixed-link-bottom
        background #fff;
        color #333333
        line-height:33px;
        border-radius:8px;
        padding:0 12px;
        div.item
            padding:20px 0;
            display: flex;
            flex-direction: column;
            cursor: pointer;
            &:nth-child(1)
                border-bottom:1px solid #F6F6F6;
            p
                line-height:16px;
        img
            width:30px
            margin:0 auto 12px;
.fixed-link-bottom-service
    width: 485px;
    height:746px;
    position: fixed;
    bottom: 20px;
    right: 124px;
    z-index: 999;
.online-link
    width:268px;
    height:275px;
    box-sizing:border-box;
    background:#fff;
    position:fixed;
    right:130px;
    bottom:140px;
    text-align:center;
    border-radius:8px;
    padding:50px 0 28px;
    box-shadow: 0px 0px 8px 2px rgba(3, 3, 3, 0.06);
    border: 1px solid rgba(220, 215, 239, 0.33);
    z-index:6;
    &::after
        position: absolute;
        right: -6px;
        top:50%;
        transform:translateY(-50%);
        content: '';
        width: 14px;
        height: 14px;
        background: #FFF;
        transform: rotate(45deg);
        z-index:5;
        box-shadow: 0px -2px 2px rgba(0, 0, 0, .05);
    img
        width:140px;
        display:block;
        margin:0 auto 20px;
    div p
        color:#666;
        font-size:14px;
        line-height:20px;
</style>
