<template>
    <div class="about-box">
        <div class="banner"></div>
        <!-- <img src="../asset/images/about-top.png" class="top-bg" alt=""> -->
        <div class="content">
            <h1>重庆奇驴科技有限公司</h1>
            <p> 
                重庆奇驴科技有限公司，位于重庆仙桃数据谷，是一家以新媒体产业为主导的多元化投资控股企业，业务涵盖网络直播经纪、网红孵化、网红培训、网红策划、短视频内容创作、IP研发打造、职业教育培训、电商运营等。
            </p>
            <p> 
                奇驴与国内多家媒体平台达成深度合作关系，从公司走出的成型达人多达上千余位，涉及电影、网剧、带货达人、选秀大赛等领域。奇驴全面布局MCN教育版块，推出网红培训、电商营销培训、经纪人培训、电子竞技师等核心培训。
            </p>
            <p>
                重庆奇驴，立志成为全国最具实力的MCN领域教育培训和内容生产机构，打造全国领先的“教育+”MCN产业发展的全平台。
            </p>
        </div>
        <footers />
    </div>
</template>
<script>
import footers from '../block/front/footers.vue'
export default {
    name:'wanghong_partner',
    components: {
        footers
    },
}
</script>
<style lang="stylus" scoped>
@import '../asset/css/partner.styl'
.about-box
    padding-bottom:304px;
    min-height:calc(100vh - 80px );
    box-sizing border-box
    position relative
    min-width:1200px
    .footers
        position absolute
        bottom 0;
        left 0;
        right 0
</style>