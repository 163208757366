<template>
    <div class="sdjz_yanglao2">
        <yanglaoFamily />
        <footers />
    </div>
</template>

<script>
import yanglaoFamily from './sdjz_yanglaoFamily.vue'
import footers from '../block/front/footers.vue'
export default {
  name: 'sdjz_yanglao2',
  show: true,
  components: {
      yanglaoFamily,
      footers,
  },
    data() {
        return {
        };
    },
    mounted() {

    },
    created() {
    },
    beforeDestroy() {

    },
    methods: {

    }
};
</script>
<style lang="stylus">
</style>
<style lang="stylus" scoped>
.sdjz_yanglao2 {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
}
</style>
