<template>
    <div class="backButton">
        <span @click="goBack">
                <i class="back el-icon-arrow-left"></i>
            返回</span>
    </div>
</template>

<script>

export default {
  name: 'backbutton',

    data() {
        return {};
    },
    mounted() {},
    created() { },
    destroyed(){
        window.removeEventListener('popstate', this.popstate());
    },
    methods: {
        // 浏览器回退按钮到训练营
        returnButton(){
            if (this.$route.name == 'handPaper') {
                if (window.history && window.history.pushState) {
                    console.log('window.history.pushState', window.history.pushState)
                    window.addEventListener('popstate', this.popstate());
                }
                // window.history.pushState('forward', null, '#'); //在IE中必须得有这两行  
                // window.history.forward(1);
            }
        },
        popstate () {
            if (this.$route.path === '/course/testPaper') {
                this.$router.replace({
                    path: '/course/handPaper',
                    query: {
                        userPaperId: this.$route.query.userPaperId,
                        businessId: this.$route.query.businessId,
                        examId: this.$route.query.examId,
                        type: this.$route.query.type
                    }
                })
            }
        },

        // 返回训练营
        async goBack() {
            await new Promise((res, rej) => {
                try {
                    window.removeEventListener('popstate', this.popstate());
                    res(true)
                } catch (error) {
                    rej(error)
                }
            })
            await new Promise((res, rej) => {
                try {
                    this.$router.push({
                        path:'/course/catalog',
                        query:{
                            id:this.businessId
                        }
                    })
                    res(true)
                } catch (error) {
                    rej(error)
                }
            })
        }
    }
};
</script>
<style lang="stylus">
.el-button--primary {
    background: #316FFF;
    border-color: #316FFF;
    span {
        color: #fff;
        font-size: 16px;
    }
}
#tip-dialog {
    text-align: center;
    .el-dialog {
        border-radius: 10px;
        .el-dialog__header {
            display: none;
        }
        .el-dialog__body {
            padding-top: 40px;
            padding-bottom: 30px;
            .icon-img {
                width: 50px;
                height: 50px;
                display: block;
                margin: 0 auto;
                margin-bottom: 19px;
            }
            .title {
                font-size: 24px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #1B2257;
                line-height: 34px;
                margin-bottom: 16px;
            }
            .text {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
            }
        }
        .el-dialog__footer {
            padding: 0 65px 30px;
            text-align: center;
            .dialog-footer {
                .el-button {
                    width: 240px;
                    height: 40px;
                    text-align: center;
                }
            }
        }
    }
}
</style>
<style lang="stylus" scoped>
    .backButton {
        padding: 24px 0;
        span {
            cursor: pointer;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #316FFF;
            line-height: 28px;
            .back{
                // margin-top: 30px;
                // margin-left: 24px;
                margin-right: -7px;
                color: #316fff;
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
</style>
