<template>
  <div class="personalInfoForm">
      <el-form class="form" ref="form" :model="form" label-width="84px">
        <el-form-item label="用户姓名：">
            <el-input v-model="form.name" placeholder="请输入用户姓名" style="width:320px"></el-input>
        </el-form-item>
        <el-form-item class="genderRadio" label="性别：">
            <el-radio-group class="radioGroup" v-model="form.gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
                <el-radio :label="3">保密</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="生日：" >
            <el-date-picker
                v-model="form.birthday"
                :picker-options="pickerOptions"
                style="width:320px"
                type="date"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="所在城市：">
            <el-select
                v-model="form.areaCode"
                filterable
                remote
                reserve-keyword
                placeholder="请输入关键词"
                :remote-method="remoteMethod"
                style="width:320px"
                :loading="loading">
                <el-option
                    v-for="item in filterOptions"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="身份：">
            <el-select v-model="form.userIdentity" placeholder="选择身份" style="width:320px">
                <el-option
                    v-for="(item,index) in userIdentityList"
                    :key="index"
                    :label="item"
                    :value="item">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-button class="saveBtn" type="primary" @click="onSubmit">保存</el-button>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
    name:'personalInfoForm',
    data(){
        return{
            loading:false,
            pickerOptions:{
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
            filterOptions:[],
            form:{
                name:'',
                gender:'',
                birthday:'',
                areaCode:'',
                userIdentity:''
            },
            areaList:[],
            userIdentityList:[]
        }
    },
    async mounted(){
        await Promise.all([ this.getTree(), this.getUserIdentityList()])
        this.getPersonalInfo()
    },
    methods:{
        // 我的资料
        getPersonalInfo(){
            if(JSON.stringify(this.$store.state.subUserInfo) === '{}'){
                this.$api.usercenter.findUser().then(res => {
                    if(res.code == 0){
                        let {name,gender,birthday,areaCode,userIdentity} = res.data
                        this.form = {name,gender,birthday,areaCode,userIdentity}
                        this.remoteMethod('',areaCode)
                    }
                })
            }else{
                let {name,gender,birthday,areaCode,userIdentity} = this.$store.state.subUserInfo
                this.form = {name,gender,birthday,areaCode,userIdentity}
                this.remoteMethod('',areaCode)
            }
        },
        remoteMethod(query,code){
            if (query !== '') {
                setTimeout(() => {
                    this.filterOptions = this.areaList.filter(item => {
                        return item.name.includes(query)
                    });
                }, 200);
            } else {
                this.filterOptions = this.areaList.filter(item => {
                    return item.code == code
                })
            }
        },
        //获取城市列表
        getTree(){
            if(this.$store.state.areaList.length>0){
                this.areaList = this.$store.state.areaList
            }else{
                return this.$api.usercenter.getTree().then(res => {
                    res.data.forEach(item => {
                        const L1Name = item.name
                        item.children && item.children.length && item.children.forEach(el => {
                            const L2Name = el.name
                            if(!el.children){
                                this.areaList.push({
                                    name: `${L1Name}-${L2Name}`,
                                    code: el.code,
                                    id: el.id
                                })
                            }else if(el.children.length){
                                el.children.forEach(v => {
                                    const L3Name = v.name
                                    this.areaList.push({
                                    name: `${L1Name}-${L2Name}-${L3Name}`,
                                    code: v.code,
                                    id: v.id
                                    })
                                })
                            }
                        })
                    })
                    this.$store.commit('saveCityTree',this.areaList)
                })
            }
        },
        // 获取身份
        getUserIdentityList(){
            return this.$api.usercenter.getUserIdentityList().then(res => {
                if(res.success){
                    //res.data ["['a','b']"]
                    if(res.data && res.data.length>0){
                        let dataArr = JSON.parse(res.data[0])
                        this.userIdentityList = dataArr
                    }
                }
            })
        },
        onSubmit(){
            if(localStorage.getItem("theme")=="shanghaijiaoda"){
                this.$api.usercenter.updateShjdUser(this.form).then(res => {
                    if(res.success){
                        this.$message.success('修改成功')
                        this.updateUserInfo()
                    }
                })
            }else{
                this.$api.usercenter.updateUser(this.form).then(res => {
                    if(res.success){
                        this.$message.success('修改成功')
                        this.updateUserInfo()
                    }
                })
            }
        },
        updateUserInfo(){
            this.$api.usercenter.findUser().then(res => {
                if(res.code == 0){
                    this.$store.commit('saveSubUserInfo',res.data)
                    localStorage.setItem('subUserInfo',JSON.stringify(res.data))
                }
            })
        }
    }
}
</script>

<style lang="stylus" scoped>
    .form  
        width 404px
        margin 0 auto
        /deep/ .el-form-item
            margin-bottom 16px
        /deep/ .el-form-item__label
            padding 0
            font-size 16px
            color #333
        .genderRadio
            /deep/ .el-form-item__content
                line-height 40px
            .radioGroup
                /deep/ .el-radio__label
                    font-size 16px
                    color #333
        .saveBtn
            margin-top 24px
            width 320px
            height 40px
            background #316FFF
</style>