<template>
    <div class="anquan-header-box">
        <div class="top">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/anquan/anquan_header_logo.png" class="logo" alt="">
        </div>
        <div class="nav">
            <ul class="nav-contaniner">
                <li v-for="(nav,index) in navList" :key="index"  @click="selectNav(nav)">
                    <p :class="{active:nav == isSelect}">{{nav.name}}</p>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import URL from '../../../../config/url';
export default {
    name:'anquanNav',
    data(){
        return{
            navList:[],
            isSelect:''
        }
    },
    mounted(){
        this.getNavs()
    },
    methods:{
        // 获取导航头数据
        getNavs() {
            if (!this.navList.length) {
                this.$api.home.getMenuNew(URL.confUrl).then((res) => {
                    if (res.data) {
                        this.navList = res.data.menuList;
                        this.getActiveNav();
                    }
                })
            } else {
                this.getActiveNav();
            }
        },
        // 选中问题
        getActiveNav() {
            this.navList.forEach((d)  => {
                if (d.url === this.$route.path || this.$route.path.indexOf(d.url) !== -1) {
                    this.isSelect = d;
                }
            })
        },
        // 点击跳转
        selectNav(item) {
            console.log(item,'item')
            if (item.url.indexOf('http') === -1) {
                this.isSelect = item;
                this.$router.push({
                    path: item.url,
                });
            } else {
                window.open(item.url, 'newW');
            }
        },
    }
}
</script>
<style lang="stylus" scoped>
.anquan-header-box{
    width 100%
    // position: fixed;
    // z-index: 666;
    .top{
        width 1200px
        height 90px
        margin 0 auto
        .logo{
            width 300px
            margin-top 14px
        }
    }
    .nav{
        width 100%
        height 60px
        background #0064D2
        .nav-contaniner{
            width 1200px
            margin 0 auto
            display flex
            li{
                width 150px
                text-align center
                cursor pointer
                &:hover{
                    background #0057B6
                }
                p{
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 60px;
                    width 100%
                    height 100%
                    &.active{
                        background #0057B6
                    }

                }
            }
        }
    }
}
</style>
