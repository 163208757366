<template>
  <div>
    <div class="menuBox menuBox-menu">
      <div class="menuContent">
        <!-- <img :class="theme ? 'imglogo flagFl' : 'imglogo fl'" :src="logoUrl" alt="" /> -->
        <img class="imglogo fl" :src="logoUrl" alt="" />
        <el-menu
          :mode="'horizontal'"
          background-color="#316FFF"
          active-text-color="#fff"
          text-color="#fff"
          :default-active="activeNav"
          style="width: auto"
          @select="handleSelect"
        >
          <template v-for="item in navName">
            <template >
              <el-menu-item v-if="!['zhongzhi_homepage', 'projectDetails'].includes(item.url)" :index="item.url" :key="item.url">
                <!-- <i
                  v-if="item.meta.icon && crowd === 'B'"
                  :class="item.meta.icon"
                ></i> -->
                <span slot="title" :title="item.name">{{
                  item.name
                }}</span>
              </el-menu-item>
            </template>
          </template>
        </el-menu>
        <template>
          <account :replacePage="this.activeNav" @getBranchInfo="getBranchInfo"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import account  from './account.vue';
import URL from '../../../../config/url';
export default {
  name: 'Submenus',
  components: {
    account,
  },
  data () {
    return {
      logoUrl: require('@assets/logo.png'),
      theme: '',
      activeNav: '/'+this.$route.path.split('/')[1],
      logoName: '',
      navName:[],
      isSelect: null,
      navigate:[],//所有导航
    }
  },
  created () {
    this.getNavs();
    this.theme = localStorage.getItem('theme') || ''
  },
  watch: {
    $route() {
      sessionStorage.setItem('index',this.$route.meta.highlight)
      setTimeout(() => { //路由跳转
        this.activeNav = this.$route.meta.highlight
      }, 100)
    }
  },
  async mounted () {
    setTimeout(() => { //刷新
      this.activeNav = sessionStorage.getItem('index') || this.activeNav
    }, 100)
  },
  methods: {
    handleSelect(keyPath) {
      if (this.$route.path === '/course/testPaper') {
        return;
      }
      this.$router.push(keyPath)
    },
    getBranchInfo(val) {
      this.getLogoName(val)
    },
    getLogoName (value) {
      if (value.length>23) {
        this.logoName = value.substring(0,23)+'...'
      } else {
        this.logoName = value
      }
    },
    getNavs() {
        if (!this.navName.length) {
            this.$api.home.getMenuNew(URL.confUrl).then((res) => {
                if (res.data.menuList) {
                    this.navName = res.data.menuList;
                    this.getActiveNav();
                }
            })
        } else {
            this.getActiveNav();
        }
    },


    getActiveNav() {
        this.navName.forEach((d)  => {
            if (d.url === this.$route.path || this.$route.path.indexOf(d.url) !== -1) {
                this.isSelect = d;
            }
        })
        // this.navName.forEach((d)  => {
        //     let couseGoodsNav = {
        //             path: '/courseGoods',
        //             meta: {
        //             title: '课程',
        //             icon: '',
        //             }
        //         }

        //     switch(d.url) {
        //         case "/courseGoods" :

        //         this.menuList.splice(0, 0, couseGoodsNav)
        //         console.log('匹配上了么，', this.menuList)
        //         break;

        //         default :
        //         break;
        //     }

        // })
    },







    },








}
</script>
<style lang="stylus" scoped>
@import './../../../../assets/css/menu';
</style>
<style lang="stylus">
</style>
