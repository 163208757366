<template>
    <div class="personal-evaluation-box">
        <!-- 个人画像 -->
        <div class="huaxiang-container">
            
            <div class="title-container">
                <h1 class="title">个人人才画像</h1>
                <p class="info">基于专业层面的知识和技能，素质层面的素养和动机，构建个人精准人才画像！</p>
            </div>
            <div class="img-box">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/huaxiang.png" alt="">
            </div>
        </div>
        <!-- 专业能力 -->
        <div class="nengli-container">
            <div class="title-container">
                <h1 class="title">专业能力</h1>
                <p class="info">掌握基本技能、专业知识点，为职业道路赋能</p>
            </div>
            <div class="list">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/nengli.png"  alt="">
                <!-- <p class="item" v-for="(item,index) in nengliList" :key="index">{{item}}</p> -->
            </div>
            <div class="button-box">
                <div class="study-btn btn" @click="study(1)">去学习</div>
                <div class="exam-btn btn" @click="study(2)">去考试</div>
            </div>
        </div>
        <!-- 职业素养 -->
        <div class="suyang-container">
            <div class="title-container">
                <h1 class="title">职业素质</h1>
                <p class="info">维度指标体系：追求新意·严谨型·智慧型·研究型·成就感·智力刺激</p>
            </div>
            <div class="img-box">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/zhiyesuyang.png" alt="">
            </div>
            <div class="button-box" @click="evaluation">
                开始测评
            </div>
        </div>
        <el-dialog
            title=""
            :visible.sync="dialogVisible"
            width="1300px"
            top="5vh"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
            <iframe class="iframe" src="https://www.yujianjob.vip/yujian/schsAdmin/schs/student/visitorReqister?id=0de01f612b3243ab8691d85e9fded18d&productId=827f749b04174832952ba949149b7b91" frameborder="0"></iframe>
        </el-dialog>
        <footers />
    </div>
</template>
<script>
import footers from '../block/front/footers.vue'
export default {
    name:'personalEvaluation',
    components: {
      footers
  },
    data(){
        return {
            nengliList:['Python应用开发 ','数据采集与预处理','人工智能数学基础','深度学习原理与实战','自然语言处理实战','数据分析与可视化','AI系统部署与运维','机器学习原理与实战','计算机视觉技术'],
            token:'',
            dialogVisible:false,
            trainId:null,
            examId:null,
            goodsId:null,//商品id
        }
    },
    created(){
        this.token = localStorage.getItem('token')
        this.findIndexTrain()
        // this.findExamTrain()
    },
    methods:{
        findIndexTrain(){
            this.$api.home.findSaleGoods().then(res => {
                this.trainId = res.data.referId
                this.goodsId = res.data.id
                this.findGoodsGiftByGoodsId()
            })
        },
        findGoodsGiftByGoodsId(){
            this.$api.home.findGoodsGiftByGoodsId(this.goodsId).then(res => {
                this.examId = res.data
            })
        },
        study(type){
            if(this.token){
                // sessionStorage.setItem('dy-mode', type === 1 ? '1':'2');
                this.$router.push({
                    path: '/course/catalog',
                    query:{
                        id: type === 1 ?this.trainId:this.examId,
                        dymode: type === 1 ? '1':'2',
                        goodsId:this.goodsId
                    }
                })
            }else {
                this.$router.push({
                    name:'login',
                    query: {
                        name: 'personalEvaluation',
                    }
                })
            }
        },
        evaluation(){
            if(this.token){
                this.dialogVisible = true;
                // window.open('http://www.yujianjob.vip/yujian/schsAdmin/schs/student/visitorReqister?id=a1354b020c8843418b8b4436f56b7a5b&productId=f0b0115392a045feb85c944ff14667b6','_blank')
            }else {
                this.$router.push({
                    name:'login',
                    query: {
                        name: 'personalEvaluation',
                    }
                })
            }
        },
        handleClose(){
            this.dialogVisible = false;
        }
    }
}
</script>
<style lang="stylus">
@import '../asset/css/personalEvaluation.styl'
</style>