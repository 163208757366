import staticPageJson from './config/staticPage.json'
const routerArr = []

let pageObj = {}
const elObj = require.context('./page', false, /\.vue$/)
elObj.keys().forEach(key => {
  const el = elObj(key).default
  el.component = `./page${key.substr(1)}`
  pageObj[el.name] = el
})

for (const key in staticPageJson) {
  if (pageObj[staticPageJson[key].template_name]) {
    const url = pageObj[staticPageJson[key].template_name].component
    const component = () => import(`${url}`)
    routerArr.push({
      ...staticPageJson[key],
      component
    })
  }
}

export default routerArr
