<template>
    <div class="examblock-page">
        <div class="examList">
            <div class="infinite-list-wrapper" style="overflow:auto">
                <ul class="examList-ul" :infinite-scroll-disabled="loading">
                    <li @click="goDetial(item)" class="examList-item" v-for="(item, index) in examList" :key="index">
                        <div class="itemNum">{{index+1 | formatNum}}</div>
                        <div class="itemInfo">
                            <p class="examName">
                                {{item.examName}}
                                <span :class="item.examType === 1 ? 'examType1' : ''" class="examType">{{item.examType | examType}}</span>
                            </p>
                            <p class="examTime">考试时间：{{item.examStartTime | formatTime}} 至 {{item.examEndTime | formatTime}}</p>
                            <p class="trainName">{{item.trainName}}</p>
                        </div>
                        <div class="exambtn">
                            查看详情
                        </div>
                    </li>
                </ul>
                <p class="loadingmore" v-if="loading">加载中...</p>
                <p class="loadingmore" v-if="noMore">- 已加载全部内容 -</p>
            </div>
            <article v-if="examPaging.total === 0" class="exam-no-course">
                <img class="noexam" src="../asset/noexam@2x.png" alt />
                <p>暂无考试</p>
            </article>
        </div>
        <!-- <paging :paging="examPaging" v-if="examPaging.total > 0" /> -->
    </div>
</template>
<script>
import $ from 'jquery';
// import paging from "../../../layout/paging";
export default {
    data() {
        return {
            examList: [],
            examStatus: null,
            // count: 10,
            loading: false,
            examPaging: {
                params: {
                    pageNum: 1,
                    pageSize: 10
                },
                total: null
            }
        };
    },
    filters: {
        formatTime(val) {
            return val.split(' ')[0].split('-').join('.')+' '+val.split(' ')[1].split(':').slice(0,2).join(':')
        },
        formatNum(val) {
            if (val <= 9) {
                return "0" + val;
            } else {
                return val;
            }
        },
        examType(val) {
            return val === 1 ? "模拟考试" : "正式考核";
        }
    },
    computed: {
        noMore () {
            return this.examList.length === this.examPaging.total && this.examPaging.total !== 0
        },
        // disabled () {
        //     return this.loading || this.noMore
        // }
    },
    components: {
        // paging,
    },
    methods: {
        reachBottom() {
            if (this.examPaging.total > this.examPaging.params.pageNum * this.examPaging.params.pageSize) {
                this.examPaging.params.pageNum = this.examPaging.params.pageNum + 1;
                this.loading = true;
                this.getData("reachBottom");
            } else {
                this.loading = false;
            }
        },
        goDetial(item){
            this.$router.push({
                path: "/course/catalog",
                query: {
                    trainItemExamId: item.trainItemExamId,
                    id: item.trainId,
                    type: 'exam',
                    dymode: this.$route.query.dymode
                }
            });
        },
        getData() {
            this.$api.training
                .findMyExamList({
                    params: {
                        pageNum: this.examPaging.params.pageNum,
                        pageSize: this.examPaging.params.pageSize,
                        examStatus: this.examStatus
                    }
                })
                .then(({ data }) => {
                    // console.log(data);
                    if(this.examPaging.params.pageNum>1){
                        this.examList = this.examList.concat(data.list);
                    } else {
                        this.examList = data.list;
                    }
                    
                    this.examPaging.total = data.total;
                });
        }
    },
    mounted() {
        // this.getData();
        $(window).scroll(() => {
            //判断是否滑动到页面底部
            if (
                Math.round($(window).scrollTop()) ===
                $(document).height() - $(window).height()
            ) {
                // TODO 滑动到底部时可请求下一页的数据并加载，加载可使用append方法
                console.log('页面底部')
                
                setTimeout(this.reachBottom(), 1000)
            }
        });
    }
};
</script>

<style scoped lang="stylus">
@import '../asset/css/examBlock.styl';
</style>
