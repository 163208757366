<template>
    <div class="emptyIndex">
    </div>
</template>

<script>
// import Swiper from "swiper";
// import "swiper/dist/css/swiper.min.css";
export default {
  name: 'emptyIndex',
    data() {
        return {
        };
    },
    mounted() {
    
    },
    created() {
        this.$api.shjdIndex.getUrlPcAndH5().then((res)=>{
            if(res.code==0){
                var isMobile = /Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)
                if (isMobile) {
                    // 当前网页在手机端打开
                    window.location.href = res.data[1]
                } else {
                    // 当前网页在PC端打开
                    window.location.href = res.data[0]
                }
            }
        })
    },
    methods: {
       
    }
};
</script>
<style lang="stylus" scoped>

</style>
