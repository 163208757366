<template>
    <div class="shandong-certificate">
        <img class="cover" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhaohu/certificate.png" alt="">
        <footers></footers>
    </div>
</template>
<script>
import footers from '../block/front/footers.vue'
export default {
    name:'zhaohuCertificate',
    components:{footers}
}
</script>
<style lang="stylus" scoped>
.shandong-certificate{
    width 100% 
    font-size 0
    .cover{
        width 100%    
    }   
}
</style>
