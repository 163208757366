<template>
  <div class="loginBox register-jz-page">
    <div class="login">
      <p class="login-left"/>
      <div class="login-box">
        <ul class="tabs">
          <li>机构注册</li>
        </ul>
        <div>
          <el-form ref="form" :rules="rules" :model="formData">
            <el-form-item prop="userName" ref="userName">
              <el-input
                placeholder="请输入姓名"
                v-model="formData.userName"
                clearable
              >
                <template slot="prepend"
                  ><img src="../asset/images/icon-user.png" alt=""
                /></template>
              </el-input>
            </el-form-item>
            <el-form-item prop="branchName" ref="branchName">
              <el-input
                placeholder="请输入机构名称"
                v-model="formData.branchName"
                clearable
              >
                <template slot="prepend"
                  ><img src="../asset/images/icon-organ.png" alt=""
                /></template>
              </el-input>
            </el-form-item>
            <el-form-item prop="phone" ref="phone">
              <el-input
                placeholder="请输入手机号"
                v-model="formData.phone"
                clearable
              >
                <template slot="prepend"
                  ><img src="../asset/images/icon-tel.png" alt=""
                /></template>
              </el-input>
            </el-form-item>
            <el-form-item prop="authCode" ref="authCode">
              <el-input
                placeholder="请输入验证码"
                @input="setAuthCode"
                v-model.number="formData.authCode"
              >
                <img slot="prepend" src="../asset/images/icon-safe.png" alt="" />
                <el-button slot="append" @click="postAccountGetAuthCode" round
                  >{{ 0 >= Countdown ? '获取验证码' : Countdown+'s' }}
                </el-button>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                class="login-btn"
                :disabled="disabled()"
                @click="onSubmit"
                >立即注册
              </el-button>
            </el-form-item>
          </el-form>
          <p class="to-login-page" @click="login">
            已有账号？<b>马上登录</b>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import conf  from '../../../config/url'
const isPhone = /^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/
export function phoneNumberValidator (rule, value, callback) {
  if (isPhone.test(value)) {
    callback()
  } else {
    callback(new Error('手机号码不正确'))
  }
}

export default {
  name: 'register-jz',
  data () {
    return {
      btn: false,
      Countdown: 0,
      smallImg: '',
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            validator: phoneNumberValidator,
            trigger: 'blur',
            message: '请输入正确的手机号'
          }
        ],
        authCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 6, max: 6, message: '请输入六位验证码', trigger: 'blur' }
        ],
        userName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        branchName: [
          { required: true, message: '请输入机构名称', trigger: 'blur' },
        ]
      },
      formData: {
        phone: null,
        authCode: null,
        userName: '',
        branchName: ''
      },
      bigImg: '',
      configurationArr: '',
      theme: ''
    }
  },
  computed: {},
  created () {
    if (!localStorage.getItem('hasGetConfig')) {
      // let base =  require('js-base64').Base64;
      // const hostname = window.location.hostname;
      // const port = !!window.location.port ;
      // let url= port? base.encode('jiazheng.saas-pc..wdeduc.com'):base.encode(hostname);
      this.configuration()
    } else {
      this.getImage();
    }
    // this.getImage();
  },
  mounted () {
  },
  watch: {},
  methods: {
    login() {
        let host = window.location.host;
        if(host === 'jiazheng.wdeduc.com'){
            window.location.replace(`http://jiazheng-ht.wdeduc.com/entrance/login`);
        }else{
            // 此时不是真正的生产环境
            let type = host.split('.')[2];
            window.location.replace(`http://jiazheng.saas-admin.${type}.wdeduc.com/entrance/login`);
        }
    },
    configuration(){
      // let frontendConfig = localStorage.getItem('frontendConfig') ? JSON.parse(localStorage.getItem('frontendConfig')): ''
       this.$api.configure.configuration(conf.confUrl).then(res => {
            if(res.data){
              let frontendConfig = res.data.frontendConfig.filter(item => {
                  if(item.key === 'homepage'){
                      return true;
                  }
                })[0];
                localStorage.setItem('hasGetConfig', '1')
                localStorage.setItem('theme',res.data.theme || '');
                localStorage.setItem('frontendConfig', frontendConfig ? JSON.stringify(frontendConfig) : '');
                localStorage.setItem('configurationArr',JSON.stringify(res.data.frontendConfig));
                this.getImage();
            }
        })
    },
    getImage(){
      this.configurationArr = JSON.parse(localStorage.getItem('configurationArr'));
      // this.theme = localStorage.getItem('theme')
      if (this.configurationArr) {
        this.configurationArr.forEach(ele => {
          console.log(ele.key, ele.value)
          if (ele.key == "custom_login_background_img") {
            this.bigImg =ele.value;
          }
          if (ele.key == "custom_login_img") {
            this.smallImg = ele.value;
          }
        })
        this.$nextTick(() => {
          if (this.bigImg) {
            $('.loginBox').css('background-image', `url(${this.bigImg})`)
            $('.login-left').css('background-image', `url(${this.smallImg})`)
          }

        })
      }
    },
    setAuthCode (val) {
      this.formData.authCode = val.replace(/[^\d]/g, '')
    },
    disabled () {
      if (
        this.btn &&
        this.formData.phone &&
        this.formData.phone.length == 11 &&
        this.formData.authCode &&
        this.formData.authCode.length == 6
      ) {
        return false
      } else {
        return true
      }
    },
    async onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.postAccountLoginByAuthCode()
        } else {
          return false
        }
      })
    },
    postAccountGetAuthCode () {
      const data = { phone: this.formData.phone,adminFlag: 1 }
      this.formData.authCode = null
      if (isPhone.test(this.formData.phone)) {
        if (this.Countdown > 0) return
        this.Countdown = 60
        this.setCountdown()
        this.$sensors.track('UM_Click_Verification_Code_Button')
        this.$api.usercenter
          .postAccountGetAuthCode({ data })
          .then(res => {
            this.btn = true
            if (res.data) {
              // sessionStorage.setItem('phone', this.formData.account)
            }
          })
      } else {
        this.$refs.form.validate(() => false)
        this.$refs.authCode.clearValidate()
      }
    },
    async postAccountLoginByAuthCode () {
      const data = {
        ...this.formData
      }
      await this.$api.usercenter
        .postAccountSaveHomemaking({ data })
        .then(res => {
          if (res.success) {
              this.$message.success('注册成功');
              setTimeout(()=>{
                  let host = window.location.host;
                  if(host === 'jiazheng.wdeduc.com'){
                      window.location.replace(`http://jiazheng-ht.wdeduc.com/entrance/login`);
                  }else{
                      // 此时不是真正的生产环境
                      let type = host.split('.')[2];
                      window.location.replace(`http://jiazheng.saas-admin.${type}.wdeduc.com/entrance/login`);
                  }
              },1000);
          }
        })
    },
    setCountdown () {
      let Countdown = null
      Countdown = setInterval(() => {
        this.Countdown--
        if (this.Countdown <= 0) {
          clearInterval(Countdown)
        }
      }, 1000)
    }
  }
}
</script>

<style lang="stylus" scoped>
.loginBox {
  width: 100%;
  height: 100vh;
  background-image: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/beijin.png');
  background-repeat: no-repeat;
  background-position: center 0px;
  background-size: cover;
}
/deep/ .el-form-item__error{
  margin-left 20px
}

.login {
  display: flex;
  width: 1100px;
  height: 660px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
  opacity: 0.98;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);

  .login-left {
    width: 660px;
    height 660px
    background-image: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/pexels.png');
    background-repeat: no-repeat;
    background-position: center 0px;
    background-size: cover;
  }

  .login-box {
    padding: 70px 50px 0;
    width: 440px;
    height 660px;
    box-sizing: border-box
  }
}

.tabs {
  background: #fff;
  font-size: 24px;
  color: #999;
  font-family: PingFangSC-Medium, PingFang SC;
  padding-left: 24px;
  text-align: left;

  li{
    display: inline-block;
    text-align: center;
    line-height: 40px;
    height: 40px;
    margin-bottom: 50px;
  }
}

.highlight {
  border-bottom: 3px #3E6CE7 solid;
  color: #333333;
}

.el-form-item {
  margin-top: 50px;
  margin-right: 0;
}

/deep/ .el-input-group__prepend {
  padding: 0 0;
  left: 20px;
  bottom: 2px;
  background-color: #ffffff;
  border: 0;
}

/deep/ .login-btn {
  margin-top: 50px;

  span {
    font-size: 18px !important;
  }
}

.el-button {
  padding: 18px 20px;

  span {
    font-size: 18px;
  }
}

.el-button--primary.is-disabled {
  background: #3E6CE7;
  opacity: 0.3;
}

/deep/ .el-button--primary {
  width: 100%;
  border-radius: 50px;
  background: #3E6CE7;
}

/deep/ .el-input__inner {
  width: 100%;
  background: #fff;
  border-top: 0px;
  border-left: 0px;
  border-right: 0;
  border-radius: 0;
  padding-left: 30px;
}
/deep/.el-input-group__append, .el-input-group__prepend {
  border: none;
}
/deep/ .el-input-group__append {
  background-color: #ffffff;
  border-top: none;
  border-right: none;
  position: absolute;
  top: 2px;
  right: 58px;

  button {
    border: 1px #3E6CE7 solid;
    border-radius: 16px;
    padding: 8px;
    width: 100px;
    height: 32px;
    padding: 0;
    text-align: center;
    line-height: 32px;

    span {
      font-family: PingFangSC-Regular, PingFang SC;
      color: #3E6CE7;
    }
  }
}

.forget {
  position: relative;
  top: -10px;
  color: #666666;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;

  a {
    color: #666666;
  }
}

.res {
  font-size: 16px;
  color: #666666;
  font-family: PingFangSC-Medium, PingFang SC;

  a {
    color: #3E6CE7;
  }
}

.login-box1 {
  text-align: left;

  &-title {
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 36px;
    letter-spacing: 1px;
  }

  &-tip {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 30px;
    margin: 10px 0 50px;
  }

  &-list {
    li {
      width: 340px;
      height: 50px;
      background: #EEEEEE;
      border-radius: 25px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 50px;
      text-align: center;
      margin-bottom: 20px;
      cursor: pointer;

      &:hover {
        color: #FFFFFF;
        background: #3E60E7;
      }
    }
  }
}

/deep/ .el-form-item__error {
  left: 18px;
}

.tabs li {
  cursor: pointer;
  color #333333
}

.password {
  margin: 12px;
  font-size: 16px;
}
.register-jz-page {
  .login-box {
    padding-top: 56px
  }
  .el-form {
    margin-top: -40px;
    .el-form-item {
      margin-top: 33px;
    }
  }
  .to-login-page {
    text-align: center;
    margin-top: 24px;
    color:  #666;
    font-size: 16px;
    b {
      font-weight: normal;
      color: #3E6CE7;
      cursor: pointer;
    }
  }

}
</style>
