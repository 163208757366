<template>
    <div class="train-info">
        <div>
          <span class="train-name">{{trainInfo.name}}</span>
          <span class="train-progress" v-if="courseCount>0">已完成 {{trainProcess}}%</span>
      </div>
      <div>
          <span
          class="train-date"
          v-if="trainInfo.trainStartTime&&trainInfo.trainEndTime"
          >{{trainInfo.trainStartTime | dataFormat}}至{{trainInfo.trainEndTime| dataFormat}}</span>
          <span :class="isClickCertificate?'diploma-btn':'diploma-btn certidisabled'" v-if="trainInfo.isConfigCert== 1"  @click="checkCertificate('businessId',businessId,'btn','train')">查看证书</span>
      </div>
    </div>
</template>

<script>
import cercertificateIsShowStatus from "@/utils/certificateMixin";
// import $ from 'jquery';
export default {
  name: 'coursecatalogtop',
  data () {
    return {
        trainInfo: {},
        courseCount: null
    };
  },
  mixins: [cercertificateIsShowStatus],
  filters: {
    examresult(val) {
      if (val === 0) {
        return require("../../asset/bujige@2x.png");
      } else if (val === 1) {
        return require("../../asset/jige@2x.png");
      } else if (val === 2) {
        return require("../../asset/weizuoda@2x.png");
      }
    },
    isPassedStatus(val) {
      if (val === 0) {
        return require("../../asset/bujige@2x.png");
      } else if (val === 1) {
        return require("../../asset/jige@2x.png");
      } else if (val === 3) {
        return require("../../asset/weizuoda@2x.png");
      }
    },
    ispassTip(val) {
      if (val === 0) {
        return "不及格";
      } else if (val === 1) {
        return "已及格";
      } else if (val === 3) {
        return "未作答";
      }
    },
    dataFormat(msg) {
      if (msg) {
        const date = new Date(msg);
        const m = date.getMonth() + 1;
        return date.getFullYear() + "." + m + "." + date.getDate();
      }
    },
    ellipsis(msg) {
      if (msg.length > 37) {
        return msg + "...";
      }
      return msg;
    },
    formartIndex(index) {
      if (index + 1 < 10) {
        return "0" + (index + 1);
      }
      return index + 1;
    },
  },
  watch: {
    '$route.query.id': function () {
      
      this.$nextTick(()=>{
        this.findTrainBasicInfoById();
        // this.findUserTrain();
      })
    //   location.reload();
    }
  },
  created () {
    this.businessId = this.$route.query.id ? this.$route.query.id : this.$route.query.trainId ;
    this.theme = sessionStorage.getItem('theme');
    if(this.theme === 'gongxin'){   
        let id = this.$route.query.dymode == 2 ? this.$route.query.trainId : this.businessId
        this.checkUserCanByGoods(id)
    } else {
        this.findTrainBasicInfoById();
    }
    
    // this.findUserTrain();
  },
  methods: {
    findTrainBasicInfoById(){
      this.$api.training.findTrainBasicInfoById({params:{id: this.businessId}}).then(({data})=>{
        this.trainInfo = data;
        if (!this.trainInfo) {
          this.showNoData = true
        } else {
            this.findUserTrain();
        }
        if(this.trainInfo.isConfigCert == 1){
            this.checkCertificate("businessId", this.businessId,'','train');
        }
      })
    },
    findUserTrain(){
        let trainId = this.businessId
      this.$api.training
        .findUserTrain({ params: { trainId: trainId } })
        .then(
            ({
                data: { menuList, courseCount, totalStudyProcess }
            }) => {
                this.trainList = menuList;
                this.courseCount = courseCount;
                this.trainProcess = totalStudyProcess;
                console.log(this.trainList, 'this.trainList')
                if(this.$route.query.trainItemExamId){
                    // this.currentCourse =
                        let exam = this.trainList.find(el =>{
                            return el.trainItemExamId == this.$route.query.trainItemExamId
                        });
                        this.currentCourse = exam ? exam : {};
                } else {
                    this.currentCourse =
                        this.trainList.find(el => el.type) || {};
                }
                console.log(this.currentCourse, 'this.currentCourse')
                this.active = this.currentCourse.id;
                this.courseId = this.currentCourse.contentId;
                this.examType = this.currentCourse.type;
                this.trainItemExamId = this.currentCourse.trainItemExamId;
                if (this.examType == 1 && this.currentCourse.courseStatus != 8) {
                    this.findCourseItemByCourseId();
                    this.findCourseInfo();
                } else if (this.examType == 2) {
                    this.findTrainExamInfoById();
                    this.findUserPaperListById();
                }
                
                if (this.trainList.length > 0) {
                    this.noTrain = false;
                } else {
                    this.noTrain = true;
                }
            }
        );
    },
  },
};
</script>

<style lang="stylus">
  .train-info
    display: flex;
    margin-bottom 8px
    height: 60px;
    background: #FFFFFF !important;
    position relative
    box-sizing border-box
    padding-top: 18px;
    padding-right 24px
    justify-content: space-between
    .train-name
        display inline-block
        max-width 780px
        text-overflow ellipsis
        white-space nowrap
        overflow hidden
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1B2257;
        padding-left 14px
        box-sizing border-box
        margin-right 12px
        vertical-align: middle
    .train-name::before
        content: '';
        position: absolute;
        width: 3px;
        height: 18px;
        background: #316FFF;
        left: 0;
        top: 23px;
    .train-progress
        display inline-block
        // width: 81px;
        height: 20px;
        text-align center
        background: rgba(67, 188, 96, 0.19);
        border-radius: 2px;
        border: 1px solid #43BC60;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #43BC60;
        padding: 0 4px;
        vertical-align: middle
    .train-date    
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #666666;
        font-weight: 400;
    .diploma-btn
        display: inline-block
        width 88px
        height 32px
        background: #316FFF
        line-height: 32px
        font-size: 16px
        font-family: PingFangSC-Regular, PingFang SC;
        color: #fff
        text-align: center
        border-radius: 4px
        margin-left 16px
        cursor pointer
        &.certidisabled
          background:#DEDDDE
</style>
