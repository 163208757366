<template>
 <div class='footers'>
     <el-row class="footers-con" type="flex" justify="center">
         <div class="footer-con-content">
             <div class="left">
                <p>道路运输安全宣教平台</p>
                <p class="address">
                   <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/Shape.png" alt=""><span class="tel">客服热线：010-12345678</span>
                </p>
            </div>
            <div class="right">
                <p>邮编：100089</p>
                <p>地址：北京市海淀区怡和8号院</p>
            </div>
         </div>
     </el-row>
     <el-row class="footers-f">
         <h2>北京伟东凌鸿教育科技有限公司@版权所有 京 ICP备10026535号-6</h2>
     </el-row>
 </div>
</template>

<script>
export default {
    name:'footers',
    props: {
    },
    data () {
        return {
            options: [
                {
                    value: 'http://www.sasac.gov.cn/',
                    label: '国务院国有资产监督管理委员会'
                },
                {
                    value: 'https://www.wdecloud.com/',
                    label: '伟东云教育'
                },
                {
                    value: 'http://www.ciic.com.cn/',
                    label: '中智集团'
                },
            ],
            value: ''
        };
    },

    methods: {
        selectChanged(value) {
            if (value) {
                window.open(value,"_blank")
                // window.location.href = value;
            }
        }
    },

    mounted(){
    },
}

</script>
<style lang='stylus' scoped>
    @import "../../asset/css/footers.styl"
</style>
<style lang='stylus'>
    .footers .el-input__inner {
        width: 159px;
        height: 34px;
        line-height: 34px;
    }
    .footers .el-input__icon {
        line-height: 34px;
    }
    .el-select {
        display: inline-block;
        vertical-align: middle;
    }
</style>