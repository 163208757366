<template>
      <div class="mask">
            <div class="dialog">
                  <div class="title">{{ title }}</div>
                  <div class="content">{{ content }}</div>
                  <button @click="close()">{{okbutton}}</button>
            </div>
      </div>
</template>
<script>
import Vue from 'vue';
const config = {
      props: ['title', 'content', 'okbutton'],
      methods: {
            close(result) {
                  this.$emit('close', result);
                  this.$destroy();
                  if (this.$el && this.$el.parentNode) {
                        this.$el.parentNode.removeChild(this.$el)
                  }
            }
      }
}

const Dialog = Vue.extend(config);

export function openTipDialog(props, vm) {
      const dialog = new Dialog({
            propsData: props
      });
      dialog.$mount();

      let wdc_app = document.getElementById('wdc-app');
      wdc_app.appendChild(dialog.$el)

      vm && vm.$once('hook:beforeDestroy', () => {
            dialog.close();
      })

      return {   // 返回 对话框 handler 句柄
            close() {   // 直接关闭
                  dialog.close();   // 关闭 对话框, 不返回结果
            },
            afterClosed() {  // 监听 对话框 关闭的结果
                  return new Promise((resolve) => {
                        dialog.$on('close', (result) => {
                              resolve(result);
                        })
                  });
            }
      }
}

export default config;

</script>
<style scoped>
      .mask {
            position: fixed;
            z-index: 100000000000000;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
      }
      .dialog {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 400px;
            height: 222px;
            background: white;
            border-radius: 10px;
      }

      .title {
            margin-top: 50px;
            font-size: 18px;
            font-weight: bold;
            line-height: 30px;
            color: rgba(69, 77, 95, 1);
      }

      .content {
            font-size: 14px;
            color: rgba(153, 153, 153, 1);
            margin-top: 12px;
      }

      button {
            width: 180px;
            height: 40px;
            border-radius: 20px;
            font-size: 16px;
            color: rgba(255, 255, 255, 1);
            background: rgba(49, 111, 255, 1);
            margin-top: 40px;
            border-width: 0px;
            cursor: pointer;
            line-height: 24px;
      }

      button:focus{
            outline: none;
      }
</style>