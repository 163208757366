<template>
    <div class='practiceList'>
        <div class="content">
            <div class="list" v-for="item in practiceList" :key="item.id">
                <div class="left item">
                    <img class="cover" :src="item.coverUrl || 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-main/cover-lianxi.png'" alt="">
                </div>
                <div class="middle item">
                    <div class="title">
                        <h1>{{item.practiceName}}</h1>
                    </div>
                    <p class="bottom-container">
                        <span class="shichang" v-show="item.sourceName">来源: {{item.sourceName}}</span>
                    </p>
                </div>
                <div class="right item">
                    <div class="button" @click="gopracticeDetail(item)">
                        <img class="lock-icon1" v-show="item.showLock" src="../../../assets/images/learning/lock-icon3.png" alt="">
                        进入练习
                    </div>
                </div>
            </div>
            <article v-show="noData" class="no-data">
                <img class="notraining" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/nodata.png" alt />
                <p>您还没有练习内容</p>
            </article>
            <p class="loadingmore" v-if="loading">加载中...</p>
            <p class="loadingmore" v-if="noMore">- 已加载全部内容 -</p>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name:'practiceList',
    data () {
        return {
            loading:false,
            paging: {
                params: {
                    pageNum: 1,
                    pageSize: 10
                },
                total: 0
            },
            practiceList:[],
            noData:null,
        };
    },
    computed: {
        noMore () {
            return this.practiceList.length === this.paging.total && this.paging.total !== 0
        },
    },
    methods: {
        gopracticeDetail(item){
            if(item.showLock){
                this.$message.warning({message:this.multiPopTips(item),offset:70})
                return false
            }else {
                this.$router.push({
                    path:'/pattern',
                    query:{
                        businessId:item.businessId,
                        practiceId:item.practiceId,
                        businessType:item.sourceType,
                        backPath:this.$route.path,
                        backModule:'test',
                    }
                })
            }
            return;
            
        },
        multiPopTips(item) {
            if(item.lockCourseRate && item.lockExamCondition===1 && item.lockFormCommit){
                return `该章未开启，开启条件为前面的学习内容学习进度达到${item.lockCourseRate}%，考试及格，表单提交`;
            }else if(item.lockCourseRate && item.lockExamCondition===0 && item.lockFormCommit){
                return `该章未开启，开启条件为前面的学习内容学习进度达到${item.lockCourseRate}%，表单提交`;
            }else if(!item.lockCourseRate && item.lockExamCondition===0 && item.lockFormCommit){
                return '该章未开启，开启条件为表单提交';
            } else if(!item.lockCourseRate && item.lockExamCondition===1 && !item.lockFormCommit ){
                return '该章未开启，开启条件为考试及格';
            } else if(item.lockCourseRate && item.lockExamCondition===0 && !item.lockFormCommit){
                return `该章未开启，开启条件为前面的学习内容学习进度达到${item.lockCourseRate}%`;
            } else if(item.lockCourseRate && item.lockExamCondition && !item.lockFormCommit) {
                return `该章未开启，开启条件为前面的学习内容学习进度达到${item.lockCourseRate}%，考试及格`;
            } else if(!item.lockCourseRate && item.lockExamCondition && item.lockFormCommit) {
                return '该章未开启，开启条件为考试及格，表单提交';
            }             
         },
        formateTime(val) {
            if(val){
                if((val / 3600).toFixed(2)>0){
                    return (val / 3600).toFixed(2);
                }else{
                    return (val / 3600).toFixed(0);
                }
                
            }else{
                return val;
            }
        },
        reachBottom() {
            if (this.paging.total > this.paging.params.pageNum * this.paging.params.pageSize) {
                this.paging.params.pageNum = this.paging.params.pageNum + 1;
                this.loading = true;
                this.getData("reachBottom");
            } else {
                this.loading = false;
            }
        },
        getData(){
            this.$api.practice
                .findUserExams({
                    params: {
                        pageNum: this.paging.params.pageNum,
                        pageSize: this.paging.params.pageSize,
                    }
                })
                .then(({ data: { list, total } }) => {
                    if(list && list.length){
                        this.noData = false
                    }else {
                        this.noData = true;
                    }
                    if(this.paging.params.pageNum>1){
                        this.practiceList = this.practiceList.concat(list);
                    } else {
                        this.practiceList = list || [];
                    }
                    this.paging.total = total;
                });
        },
        
    },

    mounted(){
        this.getData()
        $(window).scroll(() => {
            //判断是否滑动到页面底部
            if (
                Math.round($(window).scrollTop()) ===
                $(document).height() - $(window).height()
            ) {
                // TODO 滑动到底部时可请求下一页的数据并加载，加载可使用append方法
                // console.log('页面底部')
                
                setTimeout(this.reachBottom(), 1000)
            }
        });
    },
}

</script>
<style lang="stylus" scoped>
// @import './../assets/css/learningList.styl'
@import '../asset/css/practiceList.styl'
</style>