<template>
  <div class="detail-all">
    <div class="courseGoodsDetailCont">
      <el-row>
        <el-col :span="24">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/courseGoods' }">课程</el-breadcrumb-item>
            <el-breadcrumb-item>课程详情</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <div class="detail-head">
        <el-row>
          <el-col :span="6">
            <div class="right-img">
              <el-image :src="detailObj.picUrl">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
            </div>
            </el-col>
          <el-col :span="18">
            <div class="left-detail">
              <div class="detail-title"><span v-if="detailObj.taskType" :class="'type'+' type-bg'+detailObj.taskType">{{type}}</span>{{detailObj.name}}</div>
              <div class="detail-lasttime">有效期：{{time}}</div>
              <div class="detail-price">
                <div class="price-box fl">
                  <span class="price">{{!detailObj.isFree?'￥'+detailObj.salePrice:'免费'}}</span><span class="or-price" v-if="!detailObj.isFree&&detailObj.basePrice">￥{{detailObj.basePrice}}</span>
                </div>
                <div class="btn-bx fr">
                  <span class="persons" v-if="isJoinPer=='true'">{{detailObj.purchaseNo}}人已报名</span>
                  <el-button type="warning" v-if="detailObj.status==1&&!detailObj.isOrder" @click="toPay">立即报名</el-button>
                  <el-button :type="isOverType=='oks'?'warning':'info'" v-else-if="detailObj.status==1&&detailObj.isOrder" @click="isToStudy">{{isOver}}</el-button>
                  <el-button type="info" v-else disabled>{{statusText}}</el-button>
                </div>
              </div>
            </div>
            </el-col>
        </el-row>
      </div>
      <div class="detail-content">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="简介" name="first">
            <div class="detail" v-html="detailObj.desc">
              <!-- {{detailObj.desc}} -->
            </div>
          </el-tab-pane>
          <el-tab-pane label="目录" name="second" v-if="isTab=='true'&&detailObj.goodsType!=3">
            <div class="list" v-if="Object.keys(catalogList).length>0">
              <courseItem :itemList="catalogList" :isFirst="isFirst" :goodsId="goodsId" :sort="1" :isOverType="isOverType" :detailObj="detailObj"></courseItem>
            </div>
            <div v-else class="noData">
              <img src="../asset/img/tp-zwdd@2x.png" width="242"/>
              <div>课程目录正在建设中，敬请期待～</div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <footers v-if="theme==='gongxin'"/>
  </div>
</template>

<script>
import footers from '../../gongxin/block/front/footers'
import courseItem from './courseItem.vue';

export default {
  name: 'courseGoodsDetail',
  components: {
    footers,
    courseItem
  },
  data () {
    return {
          theme: '',
          goodsId:parseInt(this.$route.query.goodsId),
          detailObj:{
              basePrice: 0,//划线价格
              days: 0,//购买多少天有效
              desc: "",//简介
              endTime: "",
              goodsType: 1,//商品引用类型1.训练营 2.培训任务
              id: 0,
              isFree: 0,//是否免费
              isOrder: 0,//是否已经购买 0 否 1 是
              name: "",//商品名称
              picUrl: "",
              purchaseNo: 0,//报名人数
              referId: 0,//商品引用ID
              salePrice: 0,//销售价格
              startTime: "",
              status: 1,//状态 0下架 1上架 2 待上架
              taskType: 1,//任务类型 1.必修课 2.选修课 3.公开课
              tryWatch: 1,//是否支持试看
              tryWatchList: [//可试看列表
                {
                  itemId: 0,
                  value: 0
                }
              ],
              validityType: 1//商品有效期类型（1:永久有效、2:期间、3:固定时间、4:购买后期间）
            },
            activeName:'first',
            catalogList:[//目录列表
            ],
            isFirst:true,//是否一级
            isTab:'true',
            isJoinPer:'true'
    }
  },
  filters: {

  },
  created () {
    this.theme = localStorage.getItem('theme');
    var configurationArr = JSON.parse(localStorage.getItem('configurationArr'));
      var isTab = configurationArr.filter((item)=>{
        return item.key == 'is_show_catalog_tab';
      })
      this.isTab = isTab[0].value;
      var isJoinPer = configurationArr.filter((item)=>{
        return item.key == 'is_show_commodity_number_buyer_detail_page';
      })
      this.isJoinPer = isJoinPer[0].value;
      // console.log(this.isTab)
      // console.log(this.isJoinPer)
  },
  computed: {
    //课程类型
    type(){
      if(this.detailObj.taskType==3){
        return '公开课'
      }else if(this.detailObj.taskType==2){
        return '选修课'
      }else{
        return '必修课'
      }
    },
    //是否过期文案显示
    isOver(){
      if(this.detailObj.endTime&&this.detailObj.validityType==4){
        let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
        let nowTime = new Date().getTime();
        if(lastTime>nowTime){
          return '立即学习'
        }else{
          return '已过期'
        }
      }else if(this.detailObj.endTime&&(this.detailObj.validityType==2||this.detailObj.validityType==3)){
        let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
        let startTime = new Date(this.detailObj.startTime.replace(/-/g,'/')).getTime(); 
        let nowTime = new Date().getTime();
        if(lastTime>=nowTime&&startTime<=nowTime){
          return '立即学习'
        }else{
          if(startTime>=nowTime){
            return '未开始'
          }else{
            return '已过期'
          }
        }
      }else{
        return '立即学习'
      }
    },
    //是否过期
    isOverType(){
      if(this.detailObj.isOrder){
        if(this.detailObj.endTime&&this.detailObj.validityType==4){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime();  
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime){
            return 'oks'
          }else{
            return 'no'
          }
        }else if(this.detailObj.endTime&&(this.detailObj.validityType==2||this.detailObj.validityType==3)){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
          let startTime = new Date(this.detailObj.startTime.replace(/-/g,'/')).getTime(); 
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime&&startTime<=nowTime){
            return 'oks'
          }else{
            if(startTime>=nowTime){
              return 'no1'
            }else{
              return 'no2'
            }
          }
        }else if(this.detailObj.validityType==1){
          return 'oks'
        }else{
          return 'ok'
        }
      }else{
        return 'ok'
      }
    },
    //上架状态
    statusText(){
        if(this.detailObj.status==1){
          return '上架'
        }else if(this.detailObj.status==0){
          return '下架'
        }else{
          return '待上架'
        }
    },
    //有效期
      time(){
        if(this.detailObj.validityType==1){
          return "永久有效"
        }else if(this.detailObj.validityType==2){
          let sarr = this.detailObj.startTime.split(' ');
          let stimeArr = sarr[0].split('-');
          stimeArr[0] = stimeArr[0]+'年';
          stimeArr[1] = stimeArr[1]+'月';
          stimeArr[2] = stimeArr[2]+'日';
          let snewTime = stimeArr.join('');
          let earr = this.detailObj.endTime.split(' ');
          let etimeArr = earr[0].split('-');
          etimeArr[0] = etimeArr[0]+'年';
          etimeArr[1] = etimeArr[1]+'月';
          etimeArr[2] = etimeArr[2]+'日';
          let enewTime = etimeArr.join('');
          return snewTime+"-"+enewTime
        }else if(this.detailObj.validityType==3){
          if(this.detailObj.startTime&&this.detailObj.endTime){
            let sarr = this.detailObj.startTime.split(' ');
            let stimeArr = sarr[0].split('-');
            stimeArr[0] = stimeArr[0]+'年';
            stimeArr[1] = stimeArr[1]+'月';
            stimeArr[2] = stimeArr[2]+'日';
            let snewTime = stimeArr.join('');
            let earr = this.detailObj.endTime.split(' ');
            let etimeArr = earr[0].split('-');
            etimeArr[0] = etimeArr[0]+'年';
            etimeArr[1] = etimeArr[1]+'月';
            etimeArr[2] = etimeArr[2]+'日';
            let enewTime = etimeArr.join('');
            return snewTime+"-"+enewTime
          }else{
            let earr = this.detailObj.endTime.split(' ');
            let etimeArr = earr[0].split('-');
            etimeArr[0] = etimeArr[0]+'年';
            etimeArr[1] = etimeArr[1]+'月';
            etimeArr[2] = etimeArr[2]+'日';
            let enewTime = etimeArr.join('');
            return "至"+enewTime
          }
        }else{
          return "报名后"+this.detailObj.days+"天内有效"
        }
      }
  },
  mounted() {
    this.getGoodsDetail();
    // this.getList();
    if (window.history && window.history.pushState) {
            // 往历史记录里面添加一条新的当前页面的url
            history.pushState(null, null, document.URL);
            // 给 popstate 绑定一个方法 监听页面刷新
            window.addEventListener("popstate", this.back, false); //false阻止默认事件
        }
  },

  beforeDestroy() {
    window.removeEventListener("popstate", this.back, false); //false阻止默认事件
  },

  methods: {
    //返回
      back(){
        this.$router.go(-1);
      },
      isToStudy(){
        if(this.detailObj.endTime&&this.detailObj.validityType==4){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime){
            this.toStudy()
          }
        }else if(this.detailObj.endTime&&(this.detailObj.validityType==2||this.detailObj.validityType==3)){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
          let startTime = new Date(this.detailObj.startTime.replace(/-/g,'/')).getTime(); 
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime&&startTime<=nowTime){
              this.toStudy()
          }
        }else{
          this.toStudy()
        }
        
      },
    //立即学习
    toStudy(){
      if(this.detailObj.goodsType==1){
        this.$router.push({
          path:'/course/catalog',
          query:{
            taskId:this.detailObj.referId,//训练营id
            taskType:this.detailObj.goodsType,
            goodsId:this.$route.query.goodsId,
            backPath:this.$route.path
          }
        })
      }else if(this.detailObj.goodsType==2){
        // this.$router.push(`/course/catalog?id=${this.detailObj.referId}&dymode=1`)
        this.$router.push({
          path:'/learning/taskDetail',
          query:{
            id:this.detailObj.referId,//培训任务id
            goodsId:this.$route.query.goodsId,
            backPath:this.$route.path,
            type:this.detailObj.goodsType,
          }
        })
      }else if(this.detailObj.goodsType==3){
        this.$router.push({
          path:'/assess',
          query:{
            assessId:this.detailObj.referId,//测评id
            refId:this.$route.query.goodsId,
            refType:2,
            goodsId:this.$route.query.goodsId,
            pathName:this.$route.path
          }
        })
      }
    },
    //获取商品基本信息
    getGoodsDetail(){
      this.$api.courseGoodsDetail.findGoodsDetailById(
        {
          goodsId: this.goodsId,
        }
      )
      .then(res=>{
          // console.log(res);
          if(res.code===0){
            this.detailObj = res.data;
            this.getList();
          }
      })
    },
    //目录数据查询
    getList() {
      // console.log(this.detailObj.goodsType)
      if(this.detailObj.goodsType==1){
        //训练营目录
        this.$api.courseGoodsDetail.findTrainListById(
          {
            id: this.detailObj.referId,
          }
        )
        .then(res=>{
            // console.log(res);
            if(res.code===0){
              let tempArr = [];
                let endData = [];
                let timeArr = [];
                let num = [];
                res.data.menuList.forEach(tiem => {
                    tiem.childItemList = [];
                    num.push(tiem.lastStudyTime);
                    if (tiem.lastStudyTime !== null) {
                        timeArr.push(tiem.lastStudyTime);
                    }
                });
                this.timelength = timeArr.sort(function(a, b) {
                    return b > a;
                });
                res.data.menuList.forEach(item => {
                    tempArr.push(item.stageName);
                });
                let setName = [...new Set(tempArr)]; //.sort();
                setName.forEach((el, index) => {
                    let datalist = [];
                    res.data.menuList.forEach(items => {
                        if (el === items.stageName) {
                            items.stageName = items.label
                            datalist.push(items);
                        }
                    });
                    endData.push({
                        stageName: el,
                        id: index,
                        type:0,
                        childItemList: datalist,
                        sort:1
                    });
                });
                this.catalogList = endData;
                // console.log(this.catalogList)
            }else{
              console.log(res.data.message)
            }
        })
      }
      else if(this.detailObj.goodsType==2){
        //培训任务目录
        this.$api.courseGoodsDetail.findTaskListById(
          {
            id: this.detailObj.referId,
          }
        )
        .then(res=>{
            // console.log(res);
            if(res.code==0){
              this.catalogList = res.data.taskItemVoList;
            }
        })
      }

    },
    handleClick() {
      // this.dialogVisible=true;
      if(document.getElementsByTagName('video')[0]){
        document.getElementsByTagName('video')[0].pause()
      }
    },
    //立即报名
    toPay(){
      if(localStorage.getItem('token')){
        var obj = {
            goodsId:this.goodsId,
            orderSource:1
          }
          //下单
        this.$api.courseGoodsDetail.order(obj).then(res=>{
            // console.log(res)
            if(res.code==0){
              if(this.detailObj.isFree){
                this.$message({
                  message: '报名成功',
                  type: 'success'
                })
                this.detailObj.isOrder = 1;
                if(this.isOverType=='oks'){
                  setTimeout(()=>{
                    if(this.detailObj.goodsType==1){
                      this.$router.push({
                        path:'/course/catalog',
                        query:{
                          taskId:this.detailObj.referId,
                          taskType:this.detailObj.goodsType,
                          goodsId:this.$route.query.goodsId,
                          backPath:this.$route.path
                        }
                      })
                    }else if(this.detailObj.goodsType==2){
                      // this.$router.push(`/course/catalog?id=${this.detailObj.referId}&dymode=1`)
                      this.$router.push({
                        path:'/learning/taskDetail',
                        query:{
                          id:this.detailObj.referId,//任务id
                          goodsId:this.$route.query.goodsId,
                          backPath:this.$route.path,
                          type:this.detailObj.goodsType,
                        }
                      })
                    }else if(this.detailObj.goodsType==3){
                      this.$router.push({
                        path:'/assess',
                        query:{
                          assessId:this.detailObj.referId,//测评id
                          refId:this.$route.query.goodsId,
                          refType:2,
                          goodsId:this.$route.query.goodsId,
                          pathName:this.$route.path
                        }
                      })
                    }
                  },2000)
                }
              }else{
                this.$router.push(`/orderPage?id=${this.goodsId}&dymode=1`)
              }
            }
          })
      }else{
        //未登录转登录
        this.$router.push({
                        path: '/login',
                        query: {
                            name: 'courseGoodsDetail',
                            type: 'order',
                            id: this.goodsId,
                            referId: this.detailObj.referId
                        }
                    })
      }
    }
  },

};
</script>

<style lang="stylus" scoped>
 @import "../asset/css/courseGoodsDetail.styl"
</style>
