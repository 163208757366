<template>
    <div class="detailPage">
        <div class="total">
            <div class="back" @click="goback">{{arrow}}  返回政策法规</div>
            <div class="totalContent">
                <div class="title_one">{{detailobj.title}}</div>
                <div class="title_two">{{detailobj.title1}}</div>
                <div class="title_three">{{detailobj.sort}}</div>
                <div class="text" v-html="detailobj.content"></div>
            </div>
        </div>
        <footers/>
    </div>
</template>

<script>

import footers from '../block/front/footers.vue'
import obj from '../asset/js/details'
export default {
  name: 'gzjt_Detailpage',
  show: true,
  components: {
      footers
  },
    data() {
        return {
            detailobj:{},
            arrow:'<'
        };
    },
    mounted() {
    },
    created() {
        obj.detailList.forEach(val=>{
            if(val.id==this.$route.query.id){
                this.detailobj=val;
            }
        })
    },
    beforeDestroy() {
        
    },
    methods: { 
        goback(){
            this.$router.push({
                path:'/gzjt_Regulations'
            })
        }
    }
};
</script>
<style lang="stylus" scoped>
@import "../asset/css/gzjtDetailpage.styl"
</style>