<template>
  <div v-if="$route.name" :class="theme === 'sdjzmh'?'sdjzmhBox':''">
    <!--中智-->
    <zhongzhiNav v-if="theme==='zhongzhi'&& pageHeaderSetting != 1" />
    <!--工信-->
    <gongxinNav v-else-if="theme==='gongxin'&& pageHeaderSetting != 1" />
     <!--民政-->
    <minzhengNav v-else-if="theme==='minzheng'&& pageHeaderSetting != 1" />
    <!--家政-->
    <jiazhengNav v-else-if="theme==='jiazheng'&& pageHeaderSetting != 1" />
    <!--广州交通-->
    <gzjtNav v-else-if="theme==='gzjt' && pageHeaderSetting != 1" />
    <!-- 网红项目 -->
    <wanghongNav v-else-if="theme === 'wanghong' && pageHeaderSetting != 1" />
    <!--应急-->
    <yingjiNav v-else-if="theme === 'yingji' && pageHeaderSetting != 1"/>
    <!-- 培训证书项目 -->
    <gongxinAicNav v-else-if="theme === 'aic' && pageHeaderSetting != 1" />
    <junminNav v-else-if="theme === 'junmin' && pageHeaderSetting != 1"></junminNav>
    <!-- 安全生产项目 -->
    <anquanNav v-else-if="theme === 'anquanshengchan' && pageHeaderSetting != 1"></anquanNav>
    <!-- 山东交通项目 -->
    <sdjtNav v-else-if="theme === 'shandongjiaotong' && pageHeaderSetting != 1"></sdjtNav>
    <!-- 上海交大项目 -->
    <shjdNav v-else-if="theme === 'shanghaijiaoda' && pageHeaderSetting != 1"></shjdNav>
    <!-- 伟东 -->
    <weidongNav v-else-if="theme === 'weidong' && pageHeaderSetting != 1"/>
    <!-- 山东家政门户Saas -->
    <sdjzmhNav  v-else-if="theme === 'sdjzmh' && pageHeaderSetting != 1"></sdjzmhNav>
    <!-- 山东家政 -->
    <sdjzNav v-else-if="theme === 'sdjzpx' && pageHeaderSetting != 1"></sdjzNav>
    <!-- 山东家政诚信 -->
    <shandong2Nav v-else-if="theme === 'sdjzmh2' && pageHeaderSetting != 1"></shandong2Nav>
    <!-- 照护平台培训 -->
    <zhaohupxNav v-else-if="theme === 'zhaohupx' && pageHeaderSetting != 1"></zhaohupxNav>
    <!-- 照护平台门户 -->
    <zhaohumhNav v-else-if="theme === 'zhaohumh'"></zhaohumhNav>
  
    <tempNav :tempData="tempObj" v-if="pageHeaderSetting == 1"/>

    <!--大平台-->
    <MENU v-else-if="!theme&&token"/>
     <!-- 模板定制Header -->
    <!-- <temp-render
      :ref="'temp-' + code"
      :code="code"
      :attr="tempObj"
      v-if="isRender && pageHeaderSetting == 1"
      @action="handleOpenLocalPath"
    /> -->
    
     <!-- <div
      ref="main"
      class="main"
      :style="`padding-top: ${style.headerHeight}`"
    > -->
    <div
      ref="main"
      :class="['main',theme==='junmin'?'junminptop':'',$route.path == '/sdjzmh_homepage' || $route.path == '/management' ? 'sdjzmh2' : '']"
      :style="{'padding-top': theme === 'shanghaijiaoda'?'':style.headerHeight,'background': theme === 'yingji' ? '#FFF': ''}"
    >
      <router-view :key='key' />
    </div>
    <!-- <junminFooter ></junminFooter> -->
  </div>
</template>

<script>
import MENU from './module/menu'
import zhongzhiNav from './module/zhongzhiNav'
import gongxinNav from './module/gongxinNav'
import minzhengNav from './module/minzhengNav'
import jiazhengNav from './module/jiazhengNav'
import gzjtNav from './module/gzjtNav'
import wanghongNav from './module/wanghongNav'
import yingjiNav from './module/yingjiNav'
import gongxinAicNav from './module/gongxinAicNav'
import junminNav from './module/junminNav'
import weidongNav from './module/weidongNav'
import anquanNav from './module/anquanNav'
import sdjtNav from './module/sdjtNav'
import sdjzmhNav from './module/sdjzmhNav.vue'
import sdjzNav from './module/sdjzNav.vue'
import shjdNav from './module/shjdNav'
import shandong2Nav from './module/shandong2.vue'
import zhaohupxNav from './module/zhaohupxNAV.vue'
import zhaohumhNav from './module/zhaohumhNav.vue'
import tempNav from './module/tempNav.vue'
// import junminFooter from './module/junminFooter'
export default {
  name: 'C',
  props: {
    nav: {
      type: Object,
      default: () => ({})
    }
  },
  components: { 
    MENU,
    zhongzhiNav,
    gongxinNav,
    minzhengNav,
    jiazhengNav,
    gzjtNav,
    wanghongNav,
    yingjiNav,
    gongxinAicNav,
    junminNav,
    weidongNav,
    anquanNav,
    sdjtNav,
    sdjzmhNav,
    sdjzNav,
    shjdNav,
    shandong2Nav,
    zhaohupxNav,
    zhaohumhNav,
    tempNav
    // junminFooter
  },
  data () {
    return {
      style: {
        // containerHeight: 0,
        // containerWidth: 200,
        headerHeight:'80px',
        // mainHeight: 0,
        // mainWidth: 0
      },
      theme: '',
      isRender: false,
      token: '',
      code: 'Header',
      pageHeaderSetting: 2,
      tempObj: {
        pageHeader: {}
      }
    }
  },
  computed: {
    key() {
      return this.$route.name !== undefined ? this.$route.name + new Date() : this.$route + new Date()
    },
  },
  mounted () {
    this.init();
    this.initTemp();
    this.$watch("$route", () => {
      this.init()
      this.initTemp();
    })

  },
  methods: {
    async init () {
      // localStorage.setItem('theme','junmin')
      this.theme = localStorage.getItem('theme');
      this.token = localStorage.getItem('token');
      // console.log(this.theme)
      if (this.theme === 'jiazheng') {
        this.style.headerHeight = '147px';
      }
      if(this.theme == "gzjt"){
        this.style.headerHeight = '91px';
      }
      if (this.theme === 'yingji') {
        this.style.headerHeight = '211px';
      }
      if(this.theme === 'junmin'){
        this.style.headerHeight = '295px';
      }
      if(this.theme === 'anquanshengchan' || this.theme === 'shandongjiaotong' || this.theme === 'sdjzmh2' || this.theme === 'zhaohumh'||this.theme === 'minzheng'){
        this.style.headerHeight = '0';
      }
    },
    async initTemp () {
      let tempData = JSON.parse(localStorage.getItem('setTempConfigs'));
      if (!tempData.pageHeader) return;
      tempData.pageHeader.token =  this.token;
      this.pageHeaderSetting = tempData.pageHeaderSetting;
      if (tempData.pageHeaderSetting != 1) return;
      this.tempObj.pageHeader = tempData.pageHeader;
      this.style.headerHeight = '0';
      this.isRender = true;
    },
    handleOpenLocalPath(data) {
      if (data.data.openMode == 1) {
        this.$router.push({
          path: data.data.localPath
        })
      } else {
          const { href } = this.$router.resolve({
            path: data.data.localPath
        });
        window.open(href, '_brank');
      }
    }
  },
  created () {
    
  },
  destroyed () {
    // window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style lang="stylus" scoped>
@import './../../../assets/css/c'
// .sdjzmhBox
//     width 100%
//     height 100vh
//     overflow hidden
// /deep/.header-temp {
//   // position: fixed;
//   width: 100%;
//   z-index: 99;
// }
.sdjzmh2
    padding-top 0 !important
</style>
