<template>
    <div class="gzjt-homePage">
        <div class="banner">
            <!-- <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/banner.png" alt=""> -->
        </div>
        <div class="platform-info">
            <div class="title-box">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/platform-info-title.png" alt="">
            </div>
            <div class="content">
                <!-- <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/Group 29@2x.png" alt=""> -->
            </div>
        </div>
        <div class="service-system">
            <div class="title-box">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/service-system-title.png" alt="">
            </div>
            <div class="content">
                <div class="item item1">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/Bitmap@2x.png" alt="">
                </div>
                <div class="item item2">
                    <h2 class="title">交通事故警示案例解析</h2>
                    <p class="info">帮助提高道路运输从业人员的安全意识和反事故能力，提升运输车辆面对突发事件的应急救援处置能力</p>
                </div>
                <div class="item item3">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/Bitmap@2x(2).png" alt="">
                </div>
                <div class="item item4">
                    <h2 class="title">运输安全专项能力考试</h2>
                    <p class="info">考试过程全流程规范管理，严格落实道路运输企业安全生产责任</p>
                </div>
                <div class="item item5">
                    <h2 class="title">道路安全政策法规科普</h2>
                    <p class="info">通过学习道路运输安全相关政策法规，增强道路交通的有效管理，提高从业人员的守法意识</p>
                </div>
                <div class="item item6">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/Bitmap@2x(1).png" alt="">
                </div>
                <div class="item item7">
                    <h2 class="title">从业人员安全宣教课程</h2>
                    <p class="info">打造道路运输常态化培训课程体系，提升道路运输行业从业人员安全技能及服务意识</p>
                </div>
                <div class="item item8">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/Bitmap@2x(3).png" alt="">
                </div>
            </div>
        </div>
        <div class="excellent-course">
            <div class="title-box">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/Group 4@2x.png" alt="">
            </div>
            <div class="content">
                <div class="item" v-for="item in courseList" :key="item.id">
                    <img :src="item.url" alt="">
                    <div class="info">
                        <h2 class="title">{{item.title}}</h2>
                        <p class="text">{{item.text}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="learning-process">
            <div class="title-box">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/Group 9@2x(1).png" alt="">
            </div>
            <div class="content">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/Group 26@2x.png" alt="">
            </div>
        </div>
        <div class="platform-features">
            <div class="title-box">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/Group 9@2x.png" alt="">
            </div>
            <div class="content">
                <div class="item" v-for="item in featuresList" :key="item.id">
                    <img :src="item.url" alt="">
                    <h2 class="title">{{item.title}}</h2>
                    <p class="line"></p>
                    <p class="text" v-html="item.text"></p>
                </div>
            </div>
        </div>
        <footers/>

    </div>
</template>

<script>

import footers from '../block/front/footers.vue'

export default {
  name: 'gzjt_homepage',
  show: true,
  components: {
      footers
  },
    data() {
        return {
            featuresList:[
                {
                    id:1,
                    title:'智慧学习',
                    text:'多终端随时随地学习<br>支持H5 APP PC多场景学习',
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/feature1.png'
                },
                {
                    id:2,
                    title:'智慧考试',
                    text:'在线监管，安全省心<br>防切屏 人脸识别等多种方法AI监控',
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/feature2.png'
                },
                {
                    id:3,
                    title:'智慧管理',
                    text:'数据仪表，提升效率<br>学习全景图展示从业人员学习全路径',
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/feature3.png'
                }
            ],
            courseList:[
                {
                    id:1,
                    title:'道路运输法规、政策',
                    text:'共包含 13 节课程',
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/course-1.png'
                },
                {
                    id:2,
                    title:'社会责任和职业道德',
                    text:'共包含 11 节课程',
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/course-2.png'
                },
                {
                    id:3,
                    title:'职业心理和生理健康',
                    text:'共包含 7 节课程',
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/course-3.png'
                },
                {
                    id:4,
                    title:'运输车辆',
                    text:'共包含 12 节课程',
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/course-4.png'
                },
                {
                    id:5,
                    title:'道路运输行车危险源辨识',
                    text:'共包含 14 节课程',
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/course-5.png'
                },
                {
                    id:6,
                    title:'道路运输防御性驾驶方法及不安全驾驶习惯纠正',
                    text:'共包含 26 节课程',
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/course-6.png'
                },
                {
                    id:7,
                    title:'紧急情况及应急处置',
                    text:'共包含 16 节课程',
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/course-7.png'
                },
                {
                    id:8,
                    title:'道路运输基础知识 ',
                    text:'共包含 41 节课程',
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/course-8.png'
                },
                {
                    id:9,
                    title:'道路运输节能减排 ',
                    text:'共包含 7 节课程',
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/course-9.png'
                },
                {
                    id:10,
                    title:'押运员职业技能和素质',
                    text:'共包含 12 节课程',
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/course-10.png'
                },
                {
                    id:11,
                    title:'事故分析和处理',
                    text:'共包含 13 节课程',
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gzjt/course-11.png'
                }
            ]
        };
    },
    mounted() {
         
    },
    created() {
       
    },
    beforeDestroy() {
        
    },
    methods: { 
    }
};
</script>
<style lang="stylus" scoped>
@import '../asset/css/gzjt-homepage.styl'
</style>
