<template>
    <div class="liveList">
        <liveList></liveList>
    </div>
</template>
<script>
export default {
    name:'liveList2'
}
</script>
<style lang="stylus" scoped>
.liveList
    width 1200px
    margin 0 auto
    /deep/.live-list .tab .text
        margin-top 24px
</style>