<template>

<!-- ${style[$route.query.style]} -->
  <div id="wdc-app" :class="{'wdc-app': true, [theme]: theme}">
    <router-view
      v-if="excludeNavPage.includes($route.name)"
    ></router-view>
    <NAV v-else />
    <el-tooltip class="item" effect="dark" placement="top-end" v-if="false">
      <template slot="content">
        <p style="max-width: 220px">
          平台升级维护  <br/>升级时间 2021年9月23日 18:00 - 2021年9月24日  23:59  升级期间，部分功能可能出现异常，给您造成的不便，敬请谅解！感谢您的支持！
        </p>
      </template>
      <el-button type="warning" plain size="mini" class="system-btn-warn">平台升级维护</el-button>
    </el-tooltip>
    <!-- <el-backtop></el-backtop> -->
  </div>
</template>

<script>

const hostname = window.location.hostname;
if(hostname.includes('qdjm')) require('@/theme/junmin/index.css')
import NAV from './view/head'
import api from './api'
import conf from './config/url.js'
export default {
  data() {
    return {
      excludeNavPage: ['login','register', 'error', 'video',  'teacherLive', 'studentSee', 'register-jz','forget','training_detail', 'testPaper','livePlayback','register-mz','personnel','screen','service-agreement','privacy','emptyIndex'],
    };
  },
  computed:{
    theme(){
      return this.$store.state.theme
    }
  },
  components: { NAV },
  async created () {
    // this.addStyle()
    if(!localStorage.getItem('hasGetConfig')){
      await api.configure.configuration(conf.confUrl).then(res => {
        if (res.data) {
          localStorage.setItem('theme', res.data.theme || '');
          const theme=localStorage.getItem('theme');
          this.$store.commit('setTheme',theme)
          if(theme=='jiazheng'){
            const script=document.createElement("script");
            const script1=document.createElement("script");

            script.type = "text/javascript";
            script1.type = "text/javascript";
            script.src = "//089410.kefu.easemob.com/webim/easemob.js";
            try {
              script1.appendChild(
                document.createTextNode(
                  "window.easemobim = window.easemobim || {};easemobim.config = {configId:'09d6b664-925a-4a78-accf-c653cfe08f16',buttonText: '联系客服',dialogWidth: '360px',dialogHeight: '550px',dialogPosition: { x: '10px', y: '10px' }};"
                )
              );
            } catch (ex) {
              script1.text =
                "window.easemobim = window.easemobim || {};easemobim.config = {configId:'09d6b664-925a-4a78-accf-c653cfe08f16',buttonText: '联系客服',dialogWidth: '360px',dialogHeight: '550px',dialogPosition: { x: '10px', y: '10px' }};";
            }
            document.body.appendChild(script);
            document.body.appendChild(script1);
          }
        }
      })
    }else{
      const theme=localStorage.getItem('theme');
      this.$store.commit('setTheme',theme)
      if(theme=='jiazheng'){
        const script=document.createElement("script");
        const script1=document.createElement("script");
        script.type = "text/javascript";
        script1.type = "text/javascript";
        script.src = "//089410.kefu.easemob.com/webim/easemob.js";
        try {
          script1.appendChild(
            document.createTextNode(
              "window.easemobim = window.easemobim || {};easemobim.config = {configId:'09d6b664-925a-4a78-accf-c653cfe08f16',buttonText: '联系客服',dialogWidth: '360px',dialogHeight: '550px',dialogPosition: { x: '10px', y: '10px' }};"
            )
          );
        } catch (ex) {
          script1.text =
            "window.easemobim = window.easemobim || {};easemobim.config = {configId:'09d6b664-925a-4a78-accf-c653cfe08f16',buttonText: '联系客服',dialogWidth: '360px',dialogHeight: '550px',dialogPosition: { x: '10px', y: '10px' }};";
        }
        document.body.appendChild(script);
        document.body.appendChild(script1);
      }
    }
  },
  async mounted(){
    document.addEventListener('visibilitychange', () => {
        var isHidden = document.hidden;
        if (!isHidden) {
          let localStorageId = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo')).accountId
          let vuexId = this.$store.state.userInfo.accountId
          if(localStorageId && vuexId && localStorageId !== vuexId){//重新加载页面，保持账号同步
            // this.$router.replace('/')
            this.$router.go(0)
          }
        }
    })
    
  },
  methods: {
    
  },
};
</script>

<style lang="stylus">
.backtop {
  overflow-y: scroll;
}

.flex {
  display: flex;
}

.lr-flex {
  justify-content: space-between;
}

.el-message {
  min-width: 150px;
}

.el-message.is-closable .el-message__content {
  padding-right: 36px;
}

body::-webkit-scrollbar{
  width:0px;
  height:0px;
}
.system-btn-warn {
  position: fixed;
  right: 20px;
  bottom: 20px;
    
}
.el-button--warning.is-plain.system-btn-warn span {
  color: #999 !important;
}
</style>
