<template>
  <div>
    <aside>
      <p v-if="courseCount > 0" class="course-num">
        共有{{ courseCount }}门课程
      </p>
      <ul
        :class="courseCount ? 'training-list-height' : 'training-list-nocourse'"
        class="training-list"
      >
        <el-scrollbar style="height: 100%">
          <div class="list-li">
            <el-tooltip
              class="item"
              effect="dark"
              placement="right-start"
              v-for="el in trainList"
              :key="el.id"
              :content="el.label"
            >
              <li
                @click="changeInfo(el)"
                :class="[
                  active === el.id && el.type === 1
                    ? 'training-list-active'
                    : active === el.id && el.type === 2
                    ? 'training-exam-active'
                    : '',
                  el.type === 2 ? 'exam' : '',
                ]"
              >
                <img class="img" :src="el.pic" alt="" v-if="el.type === 1" />
                <img v-if="el.type === 2" src="../../asset//4.png" alt="" />
                <span
                  v-if="el.type === 2 && el.isPassed !== 2"
                  class="exam-tip"
                  :class="{
                    nopass: el.isPassed === 0,
                    pass: el.isPassed === 1,
                    noanswer: el.isPassed === 3,
                  }"
                  >{{ el.isPassed | ispassTip }}</span
                >
              </li>
            </el-tooltip>
          </div>
        </el-scrollbar>
      </ul>
    </aside>
  </div>
</template>

<script>

import cercertificateIsShowStatus from "@/utils/certificateMixin";
// import $ from 'jquery';
export default {
  name: "coursecataside",
  data() {
    return {
      courseCount: null,
      trainList: []
    };
  },
  mixins: [cercertificateIsShowStatus],
  computed: {},
  filters: {
    examresult(val) {
      if (val === 0) {
        return require("../../asset/bujige@2x.png");
      } else if (val === 1) {
        return require("../../asset/jige@2x.png");
      } else if (val === 2) {
        return require("../../asset/weizuoda@2x.png");
      }
    },
    isPassedStatus(val) {
      if (val === 0) {
        return require("../../asset/bujige@2x.png");
      } else if (val === 1) {
        return require("../../asset/jige@2x.png");
      } else if (val === 3) {
        return require("../../asset/weizuoda@2x.png");
      }
    },
    ispassTip(val) {
      if (val === 0) {
        return "不及格";
      } else if (val === 1) {
        return "已及格";
      } else if (val === 3) {
        return "未作答";
      }
    },
    dataFormat(msg) {
      if (msg) {
        const date = new Date(msg);
        const m = date.getMonth() + 1;
        return date.getFullYear() + "." + m + "." + date.getDate();
      }
    },
    ellipsis(msg) {
      if (msg.length > 37) {
        return msg + "...";
      }
      return msg;
    },
    formartIndex(index) {
      if (index + 1 < 10) {
        return "0" + (index + 1);
      }
      return index + 1;
    },
  },
  watch: {
    "$route.query.id": function () {
      this.$nextTick(() => {
        this.findTrainBasicInfoById();
        // this.findUserTrain();
      });
      //   location.reload();
    },
  },
  created() {
    this.businessId = this.$route.query.id
      ? this.$route.query.id
      : this.$route.query.trainId;
    this.theme = sessionStorage.getItem("theme");
    if (this.theme === "gongxin") {
      let id =
        this.$route.query.dymode == 2
          ? this.$route.query.trainId
          : this.businessId;
      this.checkUserCanByGoods(id);
    } else {
      this.findTrainBasicInfoById();
    }

    // this.findUserTrain();
  },
  methods: {
    /* 工信是否购买过课程 */
    checkUserCanByGoods(id) {
      this.$api.home.checkUserCanByGoods(id).then((res) => {
        if (res.data == true) {
          this.$message.info("您尚未购买课程，先去购买课程吧");
          this.$router.push({
            path: "/gongxinDetails",
          });
        } else {
          this.findTrainBasicInfoById();
        }
      });
    },
    playboxscoreclick() {
      if (this.currentCourse.studyProcess >= 30) {
        this.playboxscore = true;
      } else {
        this.$message.error("课程学习进度未达到30%，不能进行评价");
      }
    },
    getcourseInfo(status) {
      if (status === 8) {
        this.$message.error("很抱歉，此内容已被强制终止");
      } else {
        this.info = true;
      }
    },
    clickclose() {
      this.playboxscore = false;
      this.$forceUpdate;
    },
    async getEvaluationInfo() {
      let params = {
        courseId: this.courseId,
      };
      await this.$api.course.getEvaluationInfo({ params }).then((res) => {
        this.count1 = res.data.aveScore ? res.data.aveScore : 3;
        this.count = res.data.aveScore ? Math.ceil(res.data.aveScore) : 3;
        this.myScore = res.data.myScore ? Math.ceil(res.data.myScore) : 0;
        this.$forceUpdate;
      });
    },
    async evaluateCourse(count) {
      const data = {
        courseId: this.courseId,
        score: count,
      };
      await this.$api.course.evaluateCourse({ data }).then(() => {
        this.playboxscore = false;
        this.getEvaluationInfo();
      });
    },
    findTrainBasicInfoById() {
      this.$api.training
        .findTrainBasicInfoById({ params: { id: this.businessId } })
        .then(({ data }) => {
          this.trainInfo = data;
          if (!this.trainInfo) {
            this.showNoData = true;
          } else {
            this.findUserTrain();
          }
          if (this.trainInfo.isConfigCert == 1) {
            this.checkCertificate("businessId", this.businessId, "", "train");
          }
        });
    },
    findUserTrain() {
      let trainId = this.businessId;
      this.$api.training
        .findUserTrain({ params: { trainId: trainId } })
        .then(({ data: { menuList, courseCount, totalStudyProcess } }) => {
          this.trainList = menuList;
          this.courseCount = courseCount;
          this.trainProcess = totalStudyProcess;
          console.log(this.trainList, "this.trainList");
          if (this.$route.query.trainItemExamId) {
            // this.currentCourse =
            let exam = this.trainList.find((el) => {
              return el.trainItemExamId == this.$route.query.trainItemExamId;
            });
            this.currentCourse = exam ? exam : {};
          } else {
            this.currentCourse = this.trainList.find((el) => el.type) || {};
          }
          console.log(this.currentCourse, "this.currentCourse");
          this.active = this.currentCourse.id;
          this.courseId = this.currentCourse.contentId;
          this.examType = this.currentCourse.type;
          this.trainItemExamId = this.currentCourse.trainItemExamId;
          if (this.examType == 1 && this.currentCourse.courseStatus != 8) {
            this.findCourseItemByCourseId();
            this.findCourseInfo();
          } else if (this.examType == 2) {
            this.findTrainExamInfoById();
            this.findUserPaperListById();
          }

          if (this.trainList.length > 0) {
            this.noTrain = false;
          } else {
            this.noTrain = true;
          }
        });
    },
    changeInfo(el) {
      this.active = el.id;
      this.courseId = el.contentId;
      this.examType = el.type;
      this.currentCourse = el;
      this.trainItemExamId = el.trainItemExamId;
      if (this.currentCourse.courseStatus == 8) {
        this.$message.error("课程被强制下架，禁止观看");
        return false;
      }
      if (el.type === 1) {
        this.findCourseInfo();
        this.findCourseItemByCourseId();
      } else if (el.type == 2) {
        this.findTrainExamInfoById();
        this.findUserPaperListById();
      }
    },
    // 进入考场
    exampath() {
      this.$router.push({
        path: "/course/resdExam",
        query: {
          examId: this.courseId,
          businessId: this.businessId,
        },
      });
    },
    // 查看详情
    viewDetails(id) {
      this.$router.push({
        path: "/course/handPaper",
        query: {
          userPaperId: id,
          businessId: this.businessId,
          examId: this.courseId,
          type: "historyList",
        },
      });
    },
    infoShow(val) {
      this.info = val;
    },
    async findCourseItemByCourseId() {
      const { data } = await this.$api.training.findCourseItemByCourseId({
        params: { courseId: this.courseId },
      });
      this.catalogList = data;
    },
    async findCourseInfo() {
      let params = {
        courseId: this.courseId,
        taskId: this.businessId,
      };
      const {
        data: { description },
      } = await this.$api.training.findCourseInfo({ params });
      this.description = description;
      this.getEvaluationInfo();
    },
    async findTrainExamInfoById() {
      const { data } = await this.$api.training.findTrainExamInfoById({
        params: { id: this.trainItemExamId },
      });
      this.examInfo = data;
    },
    async findUserPaperListById() {
      const { data } = await this.$api.training.findUserPaperListById({
        params: {
          id: this.trainItemExamId,
          pageNum: 1,
          pageSize: 999,
        },
      });
      data.list.forEach((item) => {
        item.useDuration = this.timeFormatter(item.useDuration);
      });
      this.historyList = data.list;
      if (this.historyList.length > 0) {
        this.noList = false;
      } else {
        this.noList = true;
      }
    },
    timeFormatter(time) {
      const hours = Math.floor(time / 60 / 60);
      const minutes = Math.floor((time / 60) % 60);
      const seconds = Math.floor(time % 60);
      var hours1 = hours < 10 ? "0" + hours : hours;
      var minutes1 = minutes < 10 ? "0" + minutes : minutes;
      var seconds1 = seconds < 10 ? "0" + seconds : seconds;
      return hours1 + "时" + minutes1 + "分" + seconds1 + "秒";
    },
    handleChange(val) {
      console.log(val);
    },
  },
};
</script>
<style lang="stylus" scoped>
.training {
  /deep/.el-collapse {
    border: none;
  }

  /deep/.el-collapse-item__arrow {
    display: none;
  }

  /deep/.el-collapse-item__header {
    background: rgba(49, 111, 255, 0.07);
    border: 1px solid #D7DCEE;
    padding: 0 12px;
    height: 58px;
    line-height: 58px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1B2257;

    img {
      margin-right: 4px;
    }
  }

  /deep/.el-collapse-item {
    margin-bottom: 16px;
  }

  /deep/.el-collapse-item__wrap {
    border: 1px solid #D7DCEE;
    border-top: none;
  }

  /deep/.el-collapse-item__content {
    padding: 12px 16px;
  }

  /deep/.el-collapse-item__header.is-active {
    border-bottom-color: #d7dcee;
  }
}
</style>
<style lang="stylus" scoped>
@import '../../asset/css/courseDetails.styl';
</style>
<style lang="stylus">
.el-tooltip__popper.is-dark {
  .con {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
  }
}

.el-tooltip__popper .popper__arrow {
  top: 12px !important;
}

// 兼容火狐
@-moz-document url-prefix() {
  .training section {
    .no-course .history-list {
      overflow: hidden;

      .list-item {
        margin-right: -17px;
        margin-bottom: -17px;
      }
    }

    aside .training-list {
      overflow: hidden;
      margin-right: 0;

      .el-scrollbar__wrap {
        margin-right: -17px;
      }
    }

    aside .training-list-height {
      .el-scrollbar__wrap {
        height: 807px;
      }
    }

    aside .training-list-nocourse {
      .el-scrollbar__wrap {
        height: 877px;
      }
    }
  }
}
    aside
        width: 266px;
        background: #FFFFFF; 
        padding 24px 0 18px 24px
        box-sizing border-box
        margin-right 8px
        .course-num
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1B2257;
            margin-bottom 24px
        .training-list-height
          height: 790px;
        .training-list-nocourse
          height: 860px;
        .training-list
            
            li
                cursor pointer
                width: 226px;
                height: 161px;
                margin-bottom 24px
                position relative
                padding-top 5px
                padding-left 6px
                box-sizing border-box
                -webkit-tap-highlight-color: transparent;
                outline: none;
                img 
                  width: 200px;
                  height: 120px;
                .img 
                  width: 200px;
                  height: 150px;
                &:last-child
                  margin-bottom: 0px;
            .exam
              height: 131px;
            .exam-tip
              display inline-block
              width: 76px;
              height: 32px;
              line-height 32px
              text-align center
              border-radius: 0px 100px 100px 0px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              color: #FFFFFF;
              position absolute
              top 16px
              left 6px
            .pass
              background: #43BC60;
              border: 1px solid #43BC60;
            .nopass 
              background: #FFB083;
              border: 1px solid #FFB083;
            .noanswer
              background: #DEDEDE;
              border: 1px solid #DEDEDE;
</style>
