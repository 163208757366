<template>
    <div class="certificationExam">
        <div class="banner"> </div>
        <div class="content">
            <div class="container">
                <div class="top">
                    <div class="sign-up">
                        <p class="text">
                            报名时间：预计2021年6月
                        </p>
                        <div class="button">
                            <img src="../asset/images/sign-up-btn.png" alt="">
                        </div>
                    </div>
                    <div class="exam">
                        <p class="text">
                            统考时间：预计2021年6月
                        </p>
                        <div class="button">
                            <img src="../asset/images/exam-btn.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="process">
                    <p class="title">认证考试流程</p>
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/exam-process.png" alt="">
                </div>
            </div>
        </div>
        <footers/>
    </div>
</template>
<script>
import footers from '../block/front/footers.vue'
export default {
    name:'certificationExam',
    components: {
      footers
    },
    data(){
        return {

        }
    },

}
</script>
<style lang="stylus" scoped>
@import '../asset/css/certificationExam'
</style>