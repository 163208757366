let base = require('js-base64').Base64;

const hostname = window.location.hostname
const port = window.location.port;
const isIP=/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;
let arr =  hostname.split('.')
let  isDTYProEnv = !hostname.split('.').splice(3).join('.'); //线上的定制化主题
let API = arr[0].includes('hrss') || arr[0].includes('wd') ?  `https://hrss-api.${arr.splice(1).join('.')}` : `https://hrss-api.${isDTYProEnv ? arr.splice(1).join('.') : arr.splice(2).join('.')}`
let API_HOST_CMS;

if(isIP.test(hostname) || !!port){
    API = 'https://hrss-api.testing3.wdeduc.com';
} else if (hostname.includes('dev')) {
    API = 'https://hrss-api.testing3.wdeduc.com';
}
let confUrl
// if(hostname.indexOf('hrss-pc.testing2')!==-1){
//     API = 'https://hrss-api.testing3.wdeduc.com';
//       confUrl = base.encode('hrss-pc.testing3.wdeduc.com'); // 临时使用
//
// }else if(hostname.indexOf('qdjm.saas-pc.testing2')!==-1){
//     API = 'https://hrss-api.testing3.wdeduc.com';
//     confUrl = base.encode('qdjm.saas-pc.testing3.wdeduc.com')
// }else {
//     API = 'https://hrss-api.testing3.wdeduc.com';
    // confUrl = port? base.encode('shandong2.saas-pc.testing3.wdeduc.com') :  base.encode(hostname);
// }
// const confUrl = port? base.encode('aic.saas-pc.testing3.wdeduc.com') :  base.encode(hostname);
// confUrl = port? base.encode('qdjm.saas-pc.testing3.wdeduc.com') :  base.encode(hostname);
// const confUrl = port? base.encode('ai.saas-pc.testing3.wdeduc.com') :  base.encode(hostname);
// const confUrl = port? base.encode('aqscdjt.saas-pc.testing3.wdeduc.com') :  base.encode(hostname);
// confUrl = port? base.encode('gongxin.saas-pc.testing3.wdeduc.com') :  base.encode(hostname);
// const confUrl = port? base.encode('wanghong.saas-pc.testing3.wdeduc.com') :  base.encode(hostname);
// const confUrl = port? base.encode('qdjm.saas-pc.testing3.wdeduc.com') :  base.encode(hostname);
confUrl = port? base.encode('96736716.wdn-pc.testing3.wdeduc.com') :  base.encode(hostname);

// confUrl = port? base.encode('21264153.saas-pc.testing3.wdeduc.com') :  base.encode(hostname);

//const confUrl = port? base.encode('sdjt.saas-pc.testing3.wdeduc.com') :  base.encode(hostname);
// const confUrl = port? base.encode('33yingji.saas-pc.testing3.wdeduc.com') :  base.encode(hostname);
// const pageUrl = port? '33yingji.saas-pc.testing3.wdeduc.com' : hostname;

// const pageUrl = port? '21264153.saas-pc.testing3.wdeduc.com' : hostname;

const pageUrl = port? '96736716.wdn-pc.testing3.wdeduc.com' : hostname;

// // const confUrl = port? base.encode('gongxin.saas-pc.testing3.wdeduc.com') :  base.encode(hostname);
// // const confUrl = port? base.encode('wanghong.saas-pc.testing3.wdeduc.com') :  base.encode(hostname);
// // const confUrl = port? base.encode('hrss-pc.testing3.wdeduc.com') :  base.encode(hostname);
// // const confUrl = port? base.encode('hrss-pc.testing3.wdeduc.com') :  base.encode(hostname);

const yingjiURL = window.location.origin;
export default {
    API,
    confUrl,
    pageUrl,
    yingjiURL,
    API_HOST_CMS,
};
