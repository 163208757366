export default API => ({
    // 获取配置信息
    save(data) {
        return API({
            url:`/modular/admin/v1/block/saveBlockForClick`,
            method:'post',
            data,
        })
    },
    getLayout(data) {
        return API({
            url:`/modular/admin/v1/block/showPageLayoutForClick?pageId=${data.pageId}`,
            method:'post',
        })
    },
    getLayoutSettings(data) {
        return API({
            url:`/modular/admin/v1/block/getBlockForClick?pageId=${data.pageId}`,
            method:'get',
        })
    },
    getBlockList() {
        return API({
            url: `/modular/admin/v1/block/getBlockListForClick`,
            method: 'post',
        })
    },
    saveLayout(data) {
        return API({
            url: '/modular/admin/v1/block/savePageLayoutForClick',
            method: 'post',
            data,
        })
    }
})