<template>
    <div class="credit-box">
        <div class="top">
            <p>质量保障·核心基础</p>
        </div>
        <div class="bottom">
            <div class="item">
                <div class="title">
                    <p class="zh">全方位收集</p>
                    <p class="cn">Comprehensive collection</p>
                    <p class="line"></p>
                </div>
                <div class="info">
                    通过全面的官方渠道，对“个人信息、职业知识技能、服务质量、健康指数、人脉关系、保险和其他等”7大维度的从业人员信息进行收集
                </div>
            </div>
            <p class="line2"></p>
            <div class="item">
                <div class="title">
                    <p class="zh">多指标分析</p>
                    <p class="cn">Multi index analysis</p>
                    <p class="line"></p>
                </div>
                <div class="info">
                    模型共涉及将近20个一级指标，30余个二级指标，全方位展示从业人员信用信息、基本素养、从业技能
                </div>
            </div>
            <p class="line2"></p>
            <div class="item">
                <div class="title">
                    <p class="zh">一键式查询</p>
                    <p class="cn">One click query</p>
                    <p class="line"></p>
                </div>
                <div class="info">
                    从业人员信用信息、家政公司信用信息，一键式查询，为家政行业全场景提供信用服务，真正实现“让守信者畅通无阻，让失信者寸步难行”
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'credit'
}
</script>
<style lang="stylus" scoped>
.credit-box
    width 100%
    height 100vh
    // overflow hidden
    // padding-top 80px
    .top
        width 100%
        height 68%
        background url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/credit-banner.png')
        background-repeat no-repeat
        background-size cover
        background-position center
        p
            text-align center
            font-size: 64px;
            font-family: AlibabaPuHuiTiH;
            color: #FFFFFF;
            line-height: 88px;
            letter-spacing: 3px;
            text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4)
            padding-top 12%
    .bottom
        display flex
        justify-content space-between
        width 100%
        height 240px
        background-color #fff
        align-items:center;/*垂直居中*/
        box-sizing border-box
        .line2
            width: 2px;
            height: 160px;
            background: #EDEDED;
        .item
            padding 40px 48px 56px
            box-sizing border-box
            text-align center
            
            .zh
                font-size: 20px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #D3411D;
                line-height: 28px;
            .cn
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 22px;
                margin-bottom 16px
            .line
                width: 56px;
                height: 4px;
                background: #D3411D;
                border-radius: 2px;
                margin 0 auto 30px
            .info
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 22px
</style>