<template>
    <div style="background: #fff;overflow: hidden">
        <div class="banner">
            <!--<img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/reading-banner.png" alt="">-->
        </div>
        <div class="reading-book">
            <div class="content">
                <div class="title">
                    <i></i>
                    <span>企业管理系列丛书推荐</span>
                </div>
                <ul class="old-class">
                    <li v-for="ele in list" :key="ele.id">
                        <img :src="ele.img" alt="">
                        <div class="section">
                            <p class="ques">{{ele.title}}</p>
                            <p class="teacher">{{ele.teacher}}</p>
                            <p class="intro">
                                {{ele.content}}
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <footers/>
    </div>
</template>

<script>
    import {list} from '../asset/js/readingBook'
    import footers from '../block/front/footers'

    export default {
        name: "readingBook",
        data() {
            return {
                list: []
            }
        },
        created() {
            this.list = list;
        },
        methods: {},
        components: {
            footers,
        }
    }
</script>

<style lang="stylus" scoped>
    @import '../asset/css/readingBook'
</style>
