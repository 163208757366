<template>
    <div class="standard-detail-page">
        <div class="s-body">
            <h2>{{ params.basicName }}</h2>
            <div class="flex lr-flex">
                <div class="blank" />
                <div class="flex lr-flex sub-titles">
                    <span> 标准号：{{ params.basicNum }} </span>
                    <span>
                        标准状态：<b
                            v-if="params.basicStatus === 1"
                            style="color:#5DC014"
                        >{{ params.basicStatusName }}</b>
                        <b
                            v-else-if="params.basicStatus === 0"
                            style="color:#DF8215"
                        >{{ params.basicStatusName }}</b>
                        <b
                            v-else
                            style="color:#e14957"
                        >{{
                            params.basicStatusName
                        }}</b>
                    </span>
                    <span
                        v-if="thBtn"
                        class="read"
                        @click="jumpClick()"
                    >
                        <i class="el-icon-reading" />
                        在线阅读
                    </span>
                </div>
            </div>
            <div
                v-for="(item, index) in standardList"
                :key="index"
                class="type-info-block"
            >
                <div
                    class="flex lr-flex title"
                    @click="toggle(item)"
                >
                    <div class="txt">
                        {{ item.info }}:
                    </div>
                    <div class="toggle">
                        <i
                            :class="
                                item.show ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                            "
                            size="18"
                        />
                        {{ item.show ? "收起" : "展开" }}
                    </div>
                </div>
                <div
                    v-if="item.show"
                    class="wrap-p"
                >
                    <p
                        v-for="(subItem, idx) in item.list"
                        :key="idx"
                        class="info"
                    >
                        <span class="bold">{{ subItem.title }}</span>
                        <span
                            v-if="item.titleShow"
                            class="desc"
                        >{{
                            subItem.txt
                        }}</span>
                        <span
                            v-else
                            class="desc desc1"
                        >{{ subItem.txt }}</span>
                    </p>
                </div>
            </div>
            <div v-if="params.purchaseIsBuy && params.purchaseIsBuy === 1">
                <div
                    v-for="(item, index) in purchaseIsBuyList"
                    :key="index"
                    class="type-info-block"
                >
                    <div
                        class="flex lr-flex title"
                        @click="toggle(item)"
                    >
                        <div class="txt">
                            {{ item.info }}:
                        </div>
                        <div class="toggle">
                            <Icon
                                :type="
                                    item.show
                                        ? 'ios-arrow-up'
                                        : 'ios-arrow-down'
                                "
                                size="18"
                            />
                            {{ item.show ? "收起" : "展开" }}
                        </div>
                    </div>
                    <div
                        v-if="item.show"
                        class="wrap-p"
                    >
                        <p
                            v-for="(subItem, idx) in item.list"
                            :key="idx"
                            class="info"
                        >
                            <span class="bold">{{ subItem.title }}</span>
                            <span
                                v-if="item.titleShow"
                                class="desc"
                            >{{
                                subItem.txt
                            }}</span>
                            <span
                                v-else
                                class="desc desc1"
                            >{{
                                subItem.txt
                            }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import api from '@/api/home';
// 在线阅读则不显示购买
/* eslint-disable */
export default {
    name: 'standardDetail',
    data() {
        return {
            params: {
                basicName: "", //标准名称，
                basicNum: "", //标准号
                basicStatus: "", //标准状态
                basicStatusName: "",
                purchaseIsBuy: "",
                purchaseSellPrice: "",
                purchaseLinkman: "",
                purchaseContactPhone: "",
                purchaseMobilePhone: "",
                purchaseFax: "",
                purchaseEmail: "",
                purchaseIntroduction: ""
            },
            fileUrl: "", //在线阅读
            // fileName:'',
            thBtn: true,
            standardList: [],
            purchaseIsBuyList: []
        };
    },
    created() {
        if (this.$route.query.id) {
            this.getStandardById(this.$route.query.id);
        }
    },
    watch: {
        fileUrl(val) {
            if (val) {
                if (
                    this.params.purchaseIsBuy &&
                    this.params.purchaseIsBuy == 1
                ) {
                    this.thBtn = false;
                } else {
                    this.thBtn = true;
                }
            } else {
                this.thBtn = false;
            }
        }
    },
    methods: {
        getStandardById(id) {
            this.$api.standard.getStandardById(id).then(res => {
                if (res.isSuccess) {
                    console.log("res", res.obj.fileUrl);
                    this.params.basicName = res.obj.basicName; //标准名称，
                    this.params.basicNum = res.obj.basicNum; //标准号
                    this.params.basicStatusName = this.statusFormat(
                        res.obj.basicStatus
                    ); //标准状态
                    this.params.basicStatus = res.obj.basicStatus; //标准状态
                    this.fileUrl = res.obj.fileUrl;
                    this.params.purchaseIsBuy = res.obj.purchaseIsBuy;
                    // this.fileName = res.obj.fileName
                    this.standardList = [
                        {
                            show: true,
                            info: "标准简介",
                            titleShow: false,
                            list: [
                                {
                                    title: "适用范围：",
                                    txt: res.obj.basicIntroduction
                                }
                            ]
                        },
                        {
                            info: "基本信息",
                            show: true,
                            titleShow: true,
                            list: [
                                {
                                    title: "标准号：",
                                    txt: res.obj.basicNum || "无"
                                },
                                {
                                    title: "标准名称：",
                                    txt: res.obj.basicName || "无"
                                },
                                {
                                    title: "英文名称：",
                                    txt: res.obj.basicEnglishName || "无"
                                },
                                {
                                    title: "标准状态：",
                                    txt:
                                        this.statusFormat(
                                            res.obj.basicStatus
                                        ) || "无"
                                },
                                {
                                    title: "发布日期：",
                                    txt:
                                        (res.obj.basicPublishTime &&
                                            res.obj.basicPublishTime.split(
                                                "T"
                                            )[0]) ||
                                        "无"
                                },
                                {
                                    title: "实施日期：",
                                    txt:
                                        (res.obj.basicImplementTime &&
                                            res.obj.basicImplementTime.split(
                                                "T"
                                            )[0]) ||
                                        "无"
                                },
                                {
                                    title: "出版语种：",
                                    txt:
                                        res.obj.basicPublishingLanguages || "无"
                                }
                            ]
                        },
                        {
                            info: "标准分类号",
                            show: true,
                            titleShow: true,
                            list: [
                                {
                                    title: "标准ISC号：",
                                    txt: res.obj.classIscNum || "无"
                                },
                                {
                                    title: "中标分类号：",
                                    txt: res.obj.classBidNum || "无"
                                }
                            ]
                        },
                        {
                            info: "关联标准",
                            show: true,
                            titleShow: true,
                            list: [
                                {
                                    title: "替代以下标准：",
                                    txt:
                                        res.obj.relatedAlternateStandard || "无"
                                },
                                {
                                    title: "被以下标准替代：",
                                    txt:
                                        res.obj.relatedSubstituteStandard ||
                                        "无"
                                },
                                {
                                    title: "引用标准：",
                                    txt: res.obj.relatedQuoteStandard || "无"
                                },
                                {
                                    title: "采用标准：",
                                    txt: res.obj.relatedAdoptStandard || "无"
                                },
                                {
                                    title: "采标名称：",
                                    txt:
                                        res.obj.relatedAdoptStandardName || "无"
                                },
                                {
                                    title: "采标程度：",
                                    txt: res.obj.relatedAdoptDegree || "无"
                                }
                            ]
                        },
                        {
                            info: "出版信息",
                            show: true,
                            titleShow: true,
                            list: [
                                {
                                    title: "页数：",
                                    txt: res.obj.publishPage || "无"
                                },
                                {
                                    title: "字数：",
                                    txt: res.obj.publishWords || "无"
                                },
                                {
                                    title: "开本：",
                                    txt: res.obj.publishPaper || "无"
                                },
                                {
                                    title: "版次：",
                                    txt: res.obj.publishEdition || "无"
                                },
                                {
                                    title: "彩页数：",
                                    txt: res.obj.publishColorPage || "无"
                                },
                                {
                                    title: "插页数：",
                                    txt: res.obj.publishInsertPage || "无"
                                },
                                {
                                    title: "有无电子版：",
                                    txt:
                                        res.obj.publishIsElectronicEdition ||
                                        "无"
                                },
                                {
                                    title: "有无彩色图片：",
                                    txt: res.obj.publishIsColorPic || "无"
                                },
                                {
                                    title: "纸质版出版日期：",
                                    txt:
                                        (res.obj.publishPaperTime &&
                                            res.obj.publishPaperTime.split(
                                                "T"
                                            )[0]) ||
                                        "无"
                                }
                            ]
                        },
                        {
                            info: "标准修订信息",
                            show: true,
                            titleShow: true,
                            list: [
                                {
                                    title: "有无修改单：",
                                    txt: res.obj.revisionIsModifyList || "无"
                                },
                                {
                                    title: "修改单备注：",
                                    txt: res.obj.revisionModifyDesc || "无"
                                }
                            ]
                        },
                        {
                            info: "其他信息",
                            show: true,
                            titleShow: true,
                            list: [
                                {
                                    title: "标准类型：",
                                    txt: res.obj.additionalStandardType || "无"
                                },
                                {
                                    title: "标准属性：",
                                    txt:
                                        res.obj.additionalStandardAttribute ||
                                        "无"
                                },
                                {
                                    title: "标准编号：",
                                    txt: res.obj.additionalStandardNum || "无"
                                },
                                {
                                    title: "起草人：",
                                    txt: res.obj.additionalDrafter || "无"
                                },
                                {
                                    title: "起草单位：",
                                    txt: res.obj.additionalUnit || "无"
                                },
                                {
                                    title: "归口单位：",
                                    txt: res.obj.additionalUnderUnit || "无"
                                },
                                {
                                    title: "提出部门：",
                                    txt:
                                        res.obj.additionalPropoundDepartment ||
                                        "无"
                                },
                                {
                                    title: "发布部门：",
                                    txt:
                                        res.obj.additionalPublishDepartment ||
                                        "无"
                                }
                            ]
                        }
                    ];
                    this.purchaseIsBuyList = [
                        {
                            info: "购买信息",
                            show: true,
                            titleShow: true,
                            list: [
                                {
                                    title: "出售价格：",
                                    txt: res.obj.purchaseSellPrice || "无"
                                },
                                {
                                    title: "联系人：",
                                    txt: res.obj.purchaseLinkman || "无"
                                },
                                {
                                    title: "联系电话：",
                                    txt: res.obj.purchaseContactPhone || "无"
                                },
                                {
                                    title: "手机号码：",
                                    txt: res.obj.purchaseMobilePhone || "无"
                                },
                                {
                                    title: "传真：",
                                    txt: res.obj.purchaseFax || "无"
                                },
                                {
                                    title: "Email：",
                                    txt: res.obj.purchaseEmail || "无"
                                },
                                {
                                    title: "简介：",
                                    txt: res.obj.purchaseIntroduction || "无"
                                }
                            ]
                        }
                    ];
                }
            });
        },
        statusFormat(status) {
            console.log("status", status === 1);
            let txt = "";
            if (status === 1) {
                txt = "现行";
            } else if (status === 2) {
                txt = "废止";
            } else {
                txt = "即将实施";
            }
            return txt;
        },
        toggle(item) {
            item.show = !item.show;
        },
        jumpClick() {
            console.log("ccccccccccccccc");
            if (this.fileUrl && this.fileUrl !== "") {
                window.open(this.fileUrl);
            } else {
                // this.$Message.info("正在转码中");
            }
        }
    }
};
</script>

<style scoped lang="stylus">

@import './../asset/css/detail';

</style>
