<template>
    <div class="projectDetails">
        <template v-if="!id">
            <img class="top-img" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/xyzq-banner.png" alt="">
            <!-- <homeBanner /> -->
            <collegeDetail/>
            <!--<tabDetails :displayItems="displayItems" />-->
        </template>
        <template v-else>
            <collegeNews :id="id"/>
        </template>
        <footers />
    </div>
</template>

<script>
// import homeBanner from '../block/front/homeBanner.vue'
// import tabDetails from '../block/front/tabDetails.vue'
import collegeDetail from '../block/front/collegeDetail.vue'
import collegeNews from '../block/front/collegeNews.vue'
import footers from '../block/front/footers'
export default {
  name: 'projectDetails',
  show: true,
  props: {
  },
  components: {
    //   homeBanner,
      // tabDetails,
      collegeDetail,
      collegeNews,
      footers
  },
    data() {
        return {
            id:'',
            // displayItems: this.$route.query.index,
        };
    },
    created(){
        this.id = this.$route.query.id
    },
    methods: {

    }
};
</script>
<style lang="stylus">
</style>
<style lang="stylus" scoped>
@import '../asset/css/projectDetails'
</style>
