const UrlName = '/course/v1/open'
export default API => ({
    getBanner() {
        return API({ url: '/banner' });
    },
    getTemplate() {
        return API({ url: '/templates' });
    },
    getSaasFindByCondition({ params, data }) {
        return API({ url: `${UrlName}/saas/findByCondition`, params, data });
    },
    postSaasSave({ params, data }) {
        return API({ url: `${UrlName}/saas/save`, method:'post', params, data });
    },
    postSaasFindById({ params, data }){
        return API({ url: `${UrlName}/saas/findById`, method:'post', params, data });
    },
    postSaasDelete({ params, data }){
        return API({ url: `${UrlName}/saas/delete`, method:'post', params, data });
    },
    //获取目录
    findCourseItemByCourseId({data, params}) {
        return API({ url: `${UrlName}/courseItem/getValidCourseItemInfo`, params, data });
    },
    // 观看课程信息保存
    postCourseLearningLogSaveLog({data, params}) {
        return API({ url: `${UrlName}/courseLearningLog/saveLog`, method:'post', params, data });
    },
    // 记录当前用户的学习时长
    postUserLearningLog({data, params}) {
        return API({ url: `/bi/v1/statistics/timingNotify`, method:'post', params, data });
    },
    // 课程评价查询
    getEvaluationInfo({data, params}) {
        return API({ url: `${UrlName}/courseEvaluation/getEvaluationInfo`, params, data });
    },
    //课程评价
    evaluateCourse({data, params}) {
        return API({ url: `${UrlName}/courseEvaluation/evaluateCourse`, method:'post', params, data });
    },
    // 根据资源id 查看资源详情
    getSourseDetail({data, params}) {
        return API({ url: `resource/v1/resource/findById`, params, data });
    },
})
