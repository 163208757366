<template>
    <div class="sdjzNav">
        <div class="nav-box">
            <div class="left-logo">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/saasLogo.png" class="logo" alt="">
            </div>
            <ul class="nav-contaniner">
                <li v-for="(nav,index) in navList" :key="index"  @click="selectNav(nav)">
                    <p class="name" :class="{active:nav.url == $route.path}">{{nav.name}}</p>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
import URL from '../../../../config/url';
export default {
    name:'sdjzNav',
    data(){
        return{
            navList:[],
            isSelect:''
        }
    },
    mounted(){
        this.getNavs()
    },
    methods:{
        // 获取导航头数据
        getNavs() {
            if (!this.navList.length) {
                this.$api.home.getMenuNew(URL.confUrl).then((res) => {
                    if (res.data.menuList) {
                        this.navList = res.data.menuList;
                        this.getActiveNav();
                    }
                })
            } else {
                this.getActiveNav();
            }
        },
        // 选中问题
        getActiveNav() {
            this.navList.forEach((d)  => {
                if (d.url === this.$route.path || this.$route.path.indexOf(d.url) !== -1) {
                    this.isSelect = d.url;
                }
            })
        },
        // 点击跳转
        selectNav(item) {
            console.log(item,'item')
            if (item.url.indexOf('http') === -1) {
                this.isSelect = item;
                this.$router.push({
                    path: item.url,
                });
            } else {
                window.open(item.url, 'newW');
            }
        },
    }
}
</script>

<style lang="stylus" scoped>
.sdjzNav
    width 100%
    height 80px
    background rgba(255,255,255,.55)
    position fixed
    top 0
    left 0
    z-index 999
    .nav-box
        width 1200px
        margin 0 auto
        display flex
        align-items:center;
        justify-content space-between
        .left-logo
            height 80px
        .logo
            width 180px
            margin-top 15px
    .nav-contaniner
        display flex
        .name
            margin-left 80px
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 22px
            cursor pointer
            &.active
                color #D3411D
</style>
