const planList = [
    {
        id: 1,
        stageNum: '阶段一',
        stageName: '政策法规及职业素养',
        describe: '随着直播的快速发展，主播需求呈现爆发式增长，2020年疫情影响，直播带货遍地开花，不合规直播时有发生。本部分内容将带您了解主播相关的政策法规和职业素养，助您成为直播媒体的合规担当。',
        chapterList: [
            
            {
                chapter: '第一章',
                tasktitle: '主播相关政策法规解读',
                taskcontent: [
                    '第一节 主播相关政策法规解读（一）',
                    '第二节 主播相关政策法规解读（二）'
                ]
            },
            {
                chapter: '第二章',
                tasktitle: '主播职业素养',
                taskcontent: [
                    '第一节 主播职业素养',
                ]
            },
        ]
    },
    {
        id: 2,
        stageNum: '阶段二',
        stageName: '职业技能',
        describe: '一个优秀的主播体现在多个方面，包括主持技巧、语言沟通能力、带货能力等等，本部分将为您介绍如何做好主播的技能技巧培养以及如何进一步提升主播能力。',
        chapterList: [
            {
                chapter: '第一章',
                tasktitle: '主播技能技巧培养',
                taskcontent: [
                    '第一节 主播技能技巧培养（一）',
                    '第二节 主播技能技巧培养（二）',
                ]
            },
            {
                chapter: '第二章',
                tasktitle: '主播能力提升',
                taskcontent: [
                    '第一节 主播能力提升（一）',
                    '第二节 主播能力提升（二）',
                    '第三节 主播能力提升（三）',
                ]
            },
            {
                chapter: '第三章',
                tasktitle: '好好说话-语言的逻辑与声音表情',
                taskcontent: [
                    '第一节 好好说话-语言的逻辑与声音表情',
                ]
            }
        ]
    },
    {
        id: 3,
        stageNum: '阶段三',
        stageName: '个人形象及直播间设计',
        describe: '网络主播要实现收益和影响力，除了过硬的职业技能，还离不开个人形象和直播间的设计，如何给粉丝留下良好的印象，达到带动销量的目的，本部分将带您一探究竟。',
        chapterList: [
            {
                chapter: '第一章',
                tasktitle: '直播间设计',
                taskcontent: [
                    '第一节 直播间设计'
                ]
            },
            {
                chapter: '第二章',
                tasktitle: '直播间与店面直播产地直播技巧',
                taskcontent: [
                    '第一节 直播间与店面直播产地直播技巧',
                ]
            },
            {
                chapter: '第三章',
                tasktitle: '主播个人形象设计提升',
                taskcontent: [
                    '第一节 主播个人形象设计提升',
                ]
            },
            {
                chapter: '第四章',
                tasktitle: '主播造型设计和直播内容如何协调搭配',
                taskcontent: [
                    '第一节 主播造型设计和直播内容如何协调搭配'
                ]
            },
            {
                chapter: '第五章',
                tasktitle: '主播（主持人）造型设计实地案例分享',
                taskcontent: [
                    '第一节 主播造型设计实地案例分享'
                ]
            },
        ]
    },
    {
        id: 4,
        stageNum: '阶段四',
        stageName: '销售技巧',
        describe: '随着直播的快速发展，越来越多的商品门类参与到直播的销售模式中来，本部分内容将带您了解食品、珠宝饰品、奢侈品、服饰、美妆等等门类的销售技巧，祝您成为带货担当。',
        chapterList: [
            
            {
                chapter: '第一章',
                tasktitle: '商品销售逻辑分析与步骤',
                taskcontent: [
                    '第一节 商品销售逻辑分析与步骤（一）',
                    '第二节 商品销售逻辑分析与步骤（二）',
                ]
            },
            {
                chapter: '第二章',
                tasktitle: '食品与健康食品销售逻辑与技巧',
                taskcontent: [
                    '第一节 食品与健康食品销售逻辑与技巧（一）',
                    '第二节 食品与健康食品销售逻辑与技巧（二）'
                ]
            },
            {
                chapter: '第三章',
                tasktitle: '珠宝饰品奢侈品销售逻辑与技巧',
                taskcontent: [
                    '第一节 珠宝饰品奢侈品销售逻辑与技巧（一）',
                    '第二节 珠宝饰品奢侈品销售逻辑与技巧（二）'
                ]
            },
            {
                chapter: '第四章',
                tasktitle: '服饰、鞋帽、箱包类销售逻辑与技巧',
                taskcontent: [
                    '第一节 服饰、鞋帽、箱包类销售逻辑与技巧（一）',
                    '第二节 服饰、鞋帽、箱包类销售逻辑与技巧（二）',
                ]
            },
            {
                chapter: '第五章',
                tasktitle: '美妆、护肤品类销售逻辑与技巧',
                taskcontent: [
                    '第一节 美妆、护肤品类销售逻辑与技巧（一）',
                    '第二节 美妆、护肤品类销售逻辑与技巧（二）',
                ]
            }
            
        ]
    }
]
export default planList;