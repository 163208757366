<template>
    <div>
        <el-collapse v-model="activeNames">
            <el-collapse-item :name="index" v-for="(el,index) in itemList" :key="el.id" v-show="el.childrenList.length && el.showTag">
                <template slot="title" v-if="!el.type && el.showTag">
                    <i :class="activeNames.includes(index)?'el-icon-caret-bottom':'el-icon-caret-right'"></i>
                    <!-- <img src="../asset/编组 15.png" alt="" v-if="activeNames.includes(index)">
                    <img src="../asset/编组 14.png" alt="" v-else>  -->
                    <span class="sourse-title">{{el.name}}</span>
                </template>
                <ul v-if="el.childrenList.length">
                    <li v-for="(ele,index) in el.childrenList" :key="ele.id" >
                        <div v-if="ele.type && el.showTag" class="sourse" @click="toDetail(index,ele)">
                            <i class="el-icon-check defindcheck" v-if="(ele.studyInfo && ele.studyInfo.learnRate===0)||ele.studyInfo===null"></i>
                            <i class="el-icon-check ischeck" v-if="ele.studyInfo && ele.studyInfo.learnRate"></i>
                            <!-- <img src="../asset/对勾未读.png" alt="" v-if="(ele.studyInfo && ele.studyInfo.learnRate===0)||ele.studyInfo===null">
                            <img src="../asset/对勾已读.png" alt="" v-if="ele.studyInfo && ele.studyInfo.learnRate"> -->
                            <img src="../assets/images/course/icon-sp.png" alt="" v-if="ele.type===1">
                            <img src="../assets/images/course/编组 2.png" alt="" v-if="ele.type===2">
                            <img src="../assets/images/course/icon-lx@2x.png" alt="" v-if="ele.type===10">
                            <img src="../assets/images/course/icon-zb@2x.png" alt="" v-if="ele.type===11">
                            <img src="../assets/images/course/icon-sx@2x.png" alt="" v-if="ele.type===12">
                            <span :class="isSelect===index?'selected sourse-name':'sourse-name'" :title="ele.name">{{ele.name}} <span v-if="ele.dateExpireStatus == 2 || courseExpireStatus == 2" class="guoqi">已过期不可查看</span></span>
                            <span v-if="ele.studyInfo && ele.studyInfo.learnRate && ele.type != 10 && ele.type != 11 && ele.type != 12 " class="learnRate">{{ele.studyInfo && ele.studyInfo.learnRate}}%</span>
                            <span @click.stop="jumpReplay(ele)" v-if="ele.isLiveReplay && ele.type === 11 && ele.liveStatus === 2 && !ele.lock" class="learnRate">观看回放</span>
                            <div class="isLocked" v-show="ele.lock">
                                <img src="./../assets/images/learning/lock-icon1.png"  class="icon" alt="">
                            </div>
                        </div>
                    </li>
                </ul>
                <courseItem v-if="el.type===0" :itemList="el.childrenList"></courseItem>
            </el-collapse-item>
        </el-collapse>
        <face1 v-if="verification" :address="verificationAddress" :failAddress="verificationFailAddress" :type=1 :pauseFrame="true"></face1>
    </div>
</template>
<script>
export default {
    name: 'courseItem',
    props: {
        itemList: {
            type: Array,
            default: () => []
        },
        courseName: {
            type: String,
            default: ''
        },
        courseExpireStatus:{
            type:Number
        },
    },
     data () {
        return {
            isFaceRecognize: null,
            activeNames: [0],
            isSelect:'',
            verification: false,
            verificationAddress: {},
            verificationFailAddress: {},
        }
     },
     created() {
         //taskType等于1是训练营过来的
        if(this.$route.query.taskType == 1){
           console.log("zxc")
        }else{
           this.findfaceNeed()
        }
     },
     methods:{
         //查询培训任务规则
        async findfaceNeed() {
            await this.$api.examination.findfaceNeed(this.$route.query.taskId).then(res=>{
                this.isFaceRecognize = res.data.isCourseFaceRecognize;
            })
        },
         // 观看回放
         jumpReplay(item) {
            console.log('观看回放')
            if(item.isReplay){
                this.$api.live.saveViewUser(item.detailId).then((res) => {
                    if(res.success){
                        this.$router.push({
                            path: '/livePlayback',
                            query:{
                                roomId: item.liveRoomId,
                                sourceId: item.detailId,
                            }
                        })
                    }
                })
            } else {
                this.$message.info('视频处理中，请次日查看~')
            }
         },
         //课程保存
        async postCourseLearningLogSaveLog (item) {
            const data = {
                courseId: this.$route.query.id || item.courseId, // 课程id
                taskId: this.$route.query.taskId || '',// 训练营或任务
                taskType:this.$route.query.taskType || null,//2：任务；1：训练营
                courseItemId: item.id, // 素材对应id
                cur: 1,// 当前观看位置
                max:1,// 视频/音频/文档页数
                pollingTime: 1,//循环调用接口时间
                
            }
            return await this.$api.course
                .postCourseLearningLogSaveLog({ data })
                .then(res => {
                console.log(res)
                })
        },
        // 获取实训详情
        getdrillDetail(item){
            this.$api.learning.getdrillDetail({id:item.detailId}).then(res => {
                if(res.data){
                    if(this.isFaceRecognize){
                        this.verificationAddress = {
                            path:window.location.origin + `/training_detail?courseId=${res.data.courseId}&labId=${res.data.labId}`,
                            query:{
                                type:item.type
                            }
                        }
                    }else {
                        window.open(window.location.origin + `/training_detail?courseId=${res.data.courseId}&labId=${res.data.labId}`)
                    }
                    
                }
            })
        },
         toDetail(e,item){
            this.isSelect = e;
            if(item.dateExpireStatus == 2 || this.courseExpireStatus == 2){
                this.$message.info('已过期不可查看')
            }else {
                if(this.$route.query.taskType == 1 && (item.type === 11 || item.type === 12)){
                    this.$message.warning('无内容，无法学习')
                    return false;
                }
                if(item.lock){
                    this.$message.warning(`该内容未开启，开启条件为前面的内容学习进度达到 ${item.lockCourseRate}%`)
                    return false;
                }
                // 判断是否需要人脸验证
                // isRealName  是否已实名
                // const isRealName = localStorage.getItem('isRealName') || null
                var courseUserLearnInfo = JSON.parse(sessionStorage.getItem('courseUserLearnInfo'))
                if (this.isFaceRecognize && !(courseUserLearnInfo && courseUserLearnInfo.learnRate === 100)) {
                    this.verification = true;
                    if(item.type === 10 || item.type === 12){
                        this.postCourseLearningLogSaveLog(item)
                    }
                    if(item.type === 10){
                        this.verificationAddress = {
                            path:'/practice',
                            query:{
                                practiceId:item.detailId,
                                pathName:this.$route.path,
                                taskType:this.$route.query.taskType || null,
                                id:this.$route.query.id,
                                taskId:this.$route.query.taskId,
                                backMoudle:this.$route.query.backMoudle,
                                backPath:this.$route.query.backPath
                                
                            }
                        }
                    }else if(item.type === 11){
                        if(!item.detailId){
                            this.$message.warning('暂未关联直播，请稍后查看')
                        } else {
                            this.$api.live.audienceEnterRoom({id: item.detailId}).then(async (res) => {
                                    if (res.success) {
                                        this.postCourseLearningLogSaveLog(item)
                                        this.verificationAddress = {
                                            path:window.location.origin + `/studentSee?type=1&roomId=${item.detailId}`,
                                            query:{
                                                type:item.type
                                            }
                                        }
                                    }
                            });
                        }
                    }else if(item.type === 12){
                        this.getdrillDetail(item)
                    }else {
                        this.verificationAddress = {
                            path:'/video',
                            query:{
                                trainId:this.$route.query.taskId || this.$route.query.id,
                                courseId:item.courseId,
                                detailId:item.detailId,
                                studyProcess:item.studyInfo?item.studyInfo.learnRate:'',
                                courseName:this.courseName,
                                taskType:this.$route.query.taskType || null,//2：任务；1：训练营
                                backPathName:this.$route.path,
                                backMoudle:this.$route.query.backMoudle,
                                backPath:this.$route.query.backPath
                            }
                        }
                    }
                    
                    this.verificationFailAddress = {};
                } else {
                    
                    //0 目录；1，视频；2，音频；3，文档；4，图片；6，scorm；9，试卷；10，练习；11，直播；12，实锤
                     if(item.type === 10 || item.type === 12){
                        this.postCourseLearningLogSaveLog(item)
                    }
                    if(item.type === 10){
                        this.$router.push({
                            path:'/practice',
                            query:{
                                practiceId:item.detailId,
                                pathName:this.$route.path,
                                taskType:this.$route.query.taskType || null,
                                id:this.$route.query.id,
                                taskId:this.$route.query.taskId,
                                backMoudle:this.$route.query.backMoudle,
                                backPath:this.$route.query.backPath
                            }
                        })
                    }else if(item.type === 11){
                        if(!item.detailId){
                            this.$message.warning('暂未关联直播，请稍后查看')
                        } else {
                            this.$api.live.audienceEnterRoom({id: item.detailId}).then(async (res) => {
                                if (res.success) {
                                    this.postCourseLearningLogSaveLog(item)
                                    window.open(window.location.origin + `/studentSee?type=1&roomId=${item.detailId}`)
                                }
                            });
                        }
                        
                    }else if(item.type === 12){
                        this.getdrillDetail(item)
                    }else {
                        this.$router.push({
                            path:'/video',
                            query:{
                                trainId:this.$route.query.taskId || this.$route.query.id,
                                courseId:item.courseId,
                                detailId:item.detailId,
                                studyProcess:item.studyInfo?item.studyInfo.learnRate:'',
                                courseName:this.courseName,
                                taskType:this.$route.query.taskType || null,//2：任务；1：训练营
                                backPathName:this.$route.path,
                                backMoudle:this.$route.query.backMoudle,
                                backPath:this.$route.query.backPath
                            }
                        })
                    }
                    
                }
            }
            
         }
     }
}
</script>
<style lang="stylus" scoped>
 @import "../assets/css/courseItem.styl"

</style>