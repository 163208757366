export default {
    state: {
        pageList: [],
        settings: [],
        pageEndSetting: null
    },

    getters: {
        storeGetPageConfByName: (state) => (name) => {
            return state.pageList.find(
                page => page.pageName === name,
            );
        },
        storeGetBlockSettings: (state) => (block) => {
            console.log('state.settings--->',state.settings)
            return state.settings.find(
                item => item.blockInstanceId === block.id,
            );
        }
    },

    mutations: {
        storeSetPageList(state, pageList) {
            state.pageList = pageList;
        },
        storeSetPageSettings(state, settings) {
          state.settings = settings;
          console.log('state.settings------->777',state.settings)
        },
        storePageEndSetting(state, pageEndSetting) {
          console.log(pageEndSetting,'pageEndSetting仓库数据-=====')
          state.pageEndSetting = pageEndSetting
        }
    },

    actions: {
    },
};
