<template>
 <div class='footers'>
     <el-row class="footers-con" type="flex" justify="flex-start">
         <div class="left">
            <img class="left-logo" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/jiazheng/logoF.png" alt="">
             <div class="foot-w">伟东家政培训平台</div>
            <!--<div class="links">-->
                <!--<span class="label">友情链接：</span>-->
                <!--<el-select  v-model="value" placeholder="请选择" @change="selectChanged" clearable>-->
                    <!--<el-option-->
                    <!--v-for="item in options"-->
                    <!--:key="item.value"-->
                    <!--:label="item.label"-->
                    <!--:value="item.value">-->
                    <!--</el-option>-->
                <!--</el-select>-->
            <!--</div>-->
         </div>
         <div class="center">
            <p>邮编：100029</p>
            <p>地址：北京市西城区北三环中路23号燕莎大厦5层</p>
            <p>电话：400-8555-686</p>
            <!--<p>客服热线：010-12345678</p>-->
         </div>
         <div class="right">
             <img src="../../../../assets/images/user/jiazheng_qrcode.png" alt="">
             <span>扫码查看H5端</span>
         </div>
     </el-row>
     <!-- <el-row class="footers-f">
         <h2>北京伟东凌鸿教育科技有限公司@版权所有 京 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff;">ICP备10026535号</a></h2>
     </!--> 
     <saas-nsr-footer :isContract="false" />
 </div>
</template>

<script>
export default {
    name:'footers',
    props: {
    },
    data () {
        return {
            options: [
                {
                    value: 'http://www.sasac.gov.cn/',
                    label: '国务院国有资产监督管理委员会'
                },
                {
                    value: 'https://www.wdecloud.com/',
                    label: '伟东云教育'
                },
                {
                    value: 'http://www.ciic.com.cn/',
                    label: '中智集团'
                },
            ],
            value: ''
        };
    },

    methods: {
        selectChanged(value) {
            if (value) {
                window.open(value,"_blank")
                // window.location.href = value;
            }
        }
    },

    mounted(){
    },
}

</script>
<style lang='stylus' scoped>
    @import "../../asset/css/footers.styl"
</style>
<style lang='stylus'>
    .footers .el-input__inner {
        width: 159px;
        height: 34px;
        line-height: 34px;
    }
    .footers .el-input__icon {
        line-height: 34px;
    }
    .el-select {
        display: inline-block;
        vertical-align: middle;
    }
</style>
