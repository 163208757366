const urlName = '/usercenter/v1/account'

export default API => ({
  /**
   * 账号登录
   * @param { account: 账号, password: 密码 }  param2
   * 响应数据data：  用户token，用于标识用户的登录状态信息。
   */
  postAccountLogin ({ params, data }) {
    return API({ url: `${urlName}/login`, method: 'post', params, data });
  },
  /**
   * 验证码登录
   * @param { account: 账号, authCode: 密码 } param2
   * 响应数据data：  用户token，用于标识用户的登录状态信息。
   */
  postAccountLoginByAuthCode ({ params, data }) {
    return API({ url: `${urlName}/loginByAuthCode`, method: 'post', params, data });
  },
  /**
   * 注销登录
   * @param { token: 用户token } param1
   * 响应数据data：  boolean   注销登录的结构，true 成功；false 失败
   */
  postAccounLogout ({ params, data }) {
    return API({ url: `${urlName}/logout`, method: 'post', params, data });
  },
  /**
   * 获取验证码
   * @param { phone: 手机号 } param1
   * 响应数据data：  boolean   是否发送验证码成功，true 成功；false
   */
  postAccountGetAuthCode ({ params, data }) {
    return API({ url: `${urlName}/getAuthCode`, method: 'post', params, data });
  },
  
  /**
   * 获取验证码
   * @param { account: 手机号 } param1
   * 响应数据data：  boolean   是否发送验证码成功，true 成功；false
   */
  getAuthCodeCommon ({ params, data }) {
    return API({ url: `${urlName}/getAuthCodeCommon`, method: 'post', params, data });
  },

  postRegisterResult ({ params, data }) {
    return API({ url: `${urlName}/getRegisterResult`, method: 'post', params, data });
  },
  /**
   * 修改密码
   * @param { password: 原密码, passwordNew: 新密码 } param2
   * 响应数据data：  boolean   修改密码的结果，true 成功；false 失败
   */
  postAccountChangePassword (params) {
    return API({ url: `${urlName}/changePassword`, method: 'post', params });
  },
  /**
   * 重置密码
   * @param { password: 新密码, authCode: 验证码 } param1
   * 响应数据data：  boolean   是否发送验证码成功，true 成功；false
   */
  postAccountResetPassword ({ params, data }) {
    return API({ url: `${urlName}/resetPassword`, method: 'post', params, data });
  },
  /**
   * 注册
   * @param { password: 密码, account: 账号, authCode: 验证码 } param1
   * 响应数据data：  boolean   修改密码的结果，true 成功；false 失败
   */
  postAccountRegister ({  data }) {
    return API({ url: `${urlName}/register`, method: 'post',  data });
  },
  /**
   * 获取登录用户信息
   * @param {} param0
   * 响应数据data
   */
  getAccountUserInfo ({ params, data, headers }) {
    return API({ url: `${urlName}/getUserInfo`, params, data, headers });
  },
  // 分校列表
  getFindUserBranchList ({ data, params }) {
    return API({
      url: `${urlName}/findUserBranchList`,
      data,
      params
    });
  },
  // 注册时获取民政租户的机构列表
  findMinZhengBranchList () {
    return API({
      url: '/usercenter/v1/branch/findMinZhengBranchList',
    });
  },
  getFindDomainIfBindSaas ({ data, params }) {
    return API({
      url: `${urlName}/findDomainIfBindSaas`,
      data,
      params
    });
  },
  /**
   * 验证码登录
   * @param { account: 账号, authCode: 密码 } param2
   * 响应数据data：  用户token，用于标识用户的登录状态信息。
   */
  postAccountSaveHomemaking ({ params, data }) {
    return API({ url: `usercenter/v1/branch/saveHomemaking`, method: 'post', params, data });
  },
  /**
   * 获取登录方式
   * @param { } 
   * 响应数据data：  
   */
  // findLoginType (  data ) {
  //   return API({ url: `/usercenter/v1/branchProfile/findLoginType`, method: 'post' , data });
  // },
  /**
   * 忘记密码
   * @param { authCode password phone} 
   * 响应数据data：  
   */
  resetPassword ({  data }) {
    return API({ url: `${urlName}/resetPasswordNew`, method: 'post' , data });
  },
  /**
   * 根据原密码修改密码
   * @param { } 
   * 响应数据data：  
   */
  changePassword (  params ) {
    return API({ url: `${urlName}/changePassword`, method: 'post' , params });
  },
  /**
   * 验证身份
   * @param {} 
   * 响应数据data：  
   */
  checkAuthCode (  params ) {
    return API({ url: `${urlName}/checkAuthCode` , method: 'post' , params });
  },
  /**
   * 判断是否绑定手机号
   * 响应数据data：Boolean
   */
  judgeAccountIfBindPhone () {
    return API({ url: `${urlName}/judgeAccountIfBindPhone` });
  },
  /**
   * 判断是否绑定邮箱
   * 响应数据data：Boolean
   */
  judgeAccountIfBindMail () {
    return API({ url: `${urlName}/judgeAccountIfBindMail` });
  },
  /**
  * 获取已绑定手机
  * 响应数据data：String
  */
  getCurrentUserAccount () {
    return API({ url: `${urlName}/getCurrentUserAccount` });
  },
  /**
  * 获取已绑定邮箱
  * 响应数据data：String
  */
  getCurrentUserMail () {
    return API({ url: `${urlName}/getCurrentUserMail` });
  },
  /**
  * 绑定手机号、邮箱
  * 响应数据data
  */
  bindUserAccount (data) {
    return API({ url: `${urlName}/bindUserAccount`, method: 'post', data });
  },
  /**
  * 换绑手机号、邮箱
  * 响应数据data
  */
  unBindUserAccount (data) {
    return API({ url: `${urlName}/unBindUserAccount`, method: 'post', data });
  },
  /**
  * 换绑手机号、邮箱发送验证码
  * 响应数据data
  */
  getAuthCodePublic ({ params, data }) {
    return API({ url: `${urlName}/getAuthCodePublic`, method: 'post', params, data });
  },
  /**
  * 申请注销
  * 响应数据data：Boolean
  */
  cleanAccount (params) {
    return API({ url: `${urlName}/cleanAccount`, method: 'post', params });
  },
  /**
  * 查询个人资料
  * 响应数据data：{}
  */
  findUser () {
    return API({ url: `/usercenter/v1/user/findUser` });
  },
  /**
  * 更新个人资料
  * @param {areaCode:城市,birthday:'生日',gender:'性别',headImage:'头像',name:'姓名',userIdentity:'身份'} 
  * 响应数据data：{}
  */
  updateUser (params) {
    return API({ url: `/usercenter/v1/user/updateUser`, method: 'post', params });
  },
  // 上海交大更新个人资料
  updateShjdUser (params) {
    return API({ url: `/usercenter/v1/user/updateUserSJTU`, method: 'post', params });
  },
  /**
  * 获取身份
  * 响应数据data：{}
  */
  getUserIdentityList () {
    return API({ url: `${urlName}/getUserIdentityList` });
  },
  /**
  * 获取城市列表
  * 响应数据data：{}
  */
  getTree () {
    return API({ url: `/usercenter/v1/region/getTree` });
  },
  getCityList() {
      return API({ url: 'company/v1/region/regionList' });
  },
  /**
  * 获取军民用户个人资料
  * 响应数据data：{}
  */
  findMyDetail () {
    return API({ url: '/company/v1/user/findMyDetail' });
  },
  /**
  * 保存军民用户个人资料
  * 响应数据data：{}
  */
  saveMyDetail (data) {
    return API({ url: '/company/v1/user/save', method:'post', data });
  },
  /**
  * 判断军民用户是否填写个人资料
  * 响应数据data：{}
  */
  checkSaveDetail () {
    return API({ url: '/company/v1/user/checkSaveDetail'});
  },
  /**
  * 判断民政用户是否填写个人资料
  * 响应数据data：{}
  */
  checkMinzhengSaveDetail () {
    return API({ url: '/usercenter/v1/user/checkSaveDetail'});
  },
  /**
  * 获取民政用户个人资料
  * 响应数据data：{}
  */
  findMinzhengPersonal () {
    return API({ url: '/usercenter/v1/userMinZhengProfile/findSelfDatum' });
  },
  // 根据区域码获取养老机构列表
  findOldAgeInstitutionList( params ){
    return API({ url: '/usercenter/v1/userMinZhengProfile/findOldAgeInstitutionList', params }); 
  },
  // 保存民政个人资料
  saveMinzhengPersonal( data ){
    return API({ url: '/usercenter/v1/userMinZhengProfile/save', method:'post', data }); 
  },
  //根据获取养老地区数据
  findSaasIdList(){
    return API({ url: '/saas/v1/oldAgeInstitution/getRegionsInBranch' }); 
  },
})
