<template>
    <div class="gongxin_homePage_index">
        <homeBanner :id="trainArr[0].id" />
        <div class="quality-course">
            <div class="quality-course-title">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/title-1.png" alt="">
            </div>
            <div class="quality-course-content" v-for="item in trainArr" :key="item.id">
                <!-- <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/label-icon.png" class="label-icon" alt=""> -->
                <div class="quality-course-left">
                    <img :src="item.picUrl" alt="">
                </div>
                <div class="quality-course-right">
                    <div class="quality-course-right-top">
                        <span class="my-name">{{item.name}}</span>
                        <div class="price">
                            <p class="text">报名费用</p>
                            <div class="free" v-if="item.isFree">
                                免费
                            </div>
                            <div v-else>
                                <p class="preferential-price" v-show="item.salePrice">¥{{item.salePrice}}</p>
                                <p class="original-price" v-show="item.basePrice">¥{{item.basePrice}}</p>
                            </div>
                            
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/apply-btn.gif" @click="gongxinDetails(item.id,item.seq)" class="apply-btn" alt="">
                            <!-- <p>培训开始时间：{{trainObj.startTime | dataFormat}}</p>
                            <p>培训截止日期：{{trainObj.endTime | dataFormat}}</p> -->
                        </div>
                        <div class="button-box">
                            <p class="button" @click="gongxinDetails(item.id,item.seq)">
                                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/mulu-icon.png" alt="">
                                <span>项目介绍</span>
                            </p>
                            <p class="button" @click="gongxinDetails(item.id,item.seq)" v-if="item.seq==1">
                                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/shiting-icon.png" alt="">
                                <span>课程试听</span>
                            </p>
                            <!-- <p class="button" @click="gongxinDetails">
                                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/anli-icon.png" alt="">
                                <span>实战案例</span>
                            </p>
                            <p class="button" @click="showOnLine1">
                                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/youhui-icon.png" alt="">
                                <span>领取优惠</span>
                            </p> -->
                        </div>
                    </div>
                    <div class="quality-course-right-bottom">
                        <!-- <span @click="details">查看详情 ></span> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <div>
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/home_1.png" alt="">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/home_2.png" alt="">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/home_3.png" alt="">
        </div> -->
        <!-- <div class="teacher-container">

        </div> -->
        <div class="home_team">
            <div class="home_team-banner">
                <div class="swiper-wrap">
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <div  class="swiper-slide"  v-for="(team,index) in teamList" :key="index">
                                <img :src="team.imgUrl" class="imgUrl" alt="">
                                <img :src="team.imgUrl2" class="imgUrl2" alt="">
                            </div>
                        </div>
                        <!-- <div class="swiper-pagination"></div> -->
                    </div>
                    <!-- Add Arrows -->
                    <div class="button-box _clear">
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                    
                    
                </div>
            </div>
        </div>
        <!-- <div class="evaluation">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/title-3.png" class="title" alt="">
            
            <div class="content">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/3-1.png" class="bulb" alt="">
                 <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/3-2.gif" class="img-2" alt="">
                <h1>你的基础可以学习人工智能吗？</h1>
                <p>专业基础和职业素质双层面，全方位测试</p>
                <div class="button" @click="goEvaluation">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/hand-icon.png" class="hand" alt="">
                    <span class="text">立即测评</span>
                </div>
            </div>
        </div> -->
        <div class="lecture">
            <div class="lecture-title">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/title-4.png" alt="">
            </div>
            <div class="lecture-content">
                 <el-carousel :interval="4000" type="card" ref="carousel" @change="carouselChange" :autoplay="false" height="360px">
                    <el-carousel-item class="live-bg" id="item1" ref="item1">
                        <div class="info" v-if="liveObj.length" @click="goliveDetail(liveObj[0])">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/play-icon.png" class="icon-play" alt="">
                            <div class="content _clear">
                                <p class="live-title fl">{{liveObj[0].title}}</p>
                                <p class="live-time fr">{{liveObj[0].beginTime}}</p>
                            </div>
                            <p class="jieshao">
                                <span>行业解读</span>
                                <span>试听公开课</span>
                                <span>专家讲座</span>
                            </p>
                        </div>
                        <div class="course-item info" @click="gongxinDetails(trainArr[0].id,trainArr[0].seq)" v-else>
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/play-icon.png" class="icon-play" alt="">
                            <p class="text">点击了解课程介绍</p>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item class="live-bg" id="item2"  ref="item2">
                        <div class="info" v-if="liveObj.length && liveObj.length >= 2" @click="goliveDetail(liveObj[1])">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/play-icon.png" class="icon-play" alt="">
                            <div class="content _clear">
                                <p class="live-title fl">{{liveObj[1].title}}</p>
                                <p class="live-time fr">{{liveObj[1].beginTime}}</p>
                            </div>
                            <p class="jieshao">
                                <span>行业解读</span>
                                <span>试听公开课</span>
                                <span>专家讲座</span>
                            </p>
                        </div>
                        <div class="course-item info" @click="gongxinDetails(trainArr[0].id,trainArr[0].seq)" v-else>
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/play-icon.png" class="icon-play" alt="">
                            <p class="text">了解更多课程详情</p>
                        </div>
                    </el-carousel-item>
                    
                    <el-carousel-item class="live-bg" id="item3"  ref="item3">
                        <div class="info" v-if="liveObj.length && liveObj.length == 3" @click="goliveDetail(liveObj[2])">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/play-icon.png" class="icon-play" alt="">
                            <div class="content _clear">
                                <p class="live-title fl">{{liveObj[2].title}}</p>
                                <p class="live-time fr">{{liveObj[2].beginTime}}</p>
                            </div>
                            <p class="jieshao">
                                <span>行业解读</span>
                                <span>试听公开课</span>
                                <span>专家讲座</span>
                            </p>
                        </div>
                        <div class="course-item info" @click="gongxinDetails(trainArr[0].id,trainArr[0].seq)" v-else>
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/play-icon.png" class="icon-play" alt="">
                            <p class="text">查看更多试听课</p>
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <div class="double-arrow-box" @click="goLive">更多专题讲座 <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/double-arrow.png" class="double-arrow" alt=""></div>
                <!-- <div class="lecture-content-left">
                    <div v-if="liveHas">
                        <p class="live-title">{{liveObj.title}}</p>
                        <p class="live-time">{{liveObj.beginTime}}</p>
                    </div>
                    <div v-else> 
                        <p class="live-title">人工智能专题直播</p>
                        <p class="live-time">更多专题讲座，即将开始…</p>
                    </div>
                    <button  v-if="liveHas" @click="goLive" class="btnDetail">查看详情 ></button>
                    <a href="javascript:;" v-else class="noLive btnDetail">查看详情 ></a>
                </div>
                <div class="lecture-content-right">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/lecture-content.png" alt="">
                </div> -->
            </div>
        </div>
        <div class="bottoma">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/home_5.png" alt="">
        </div>
        <div class="part6">
            <div class="part-header">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/part6-header.png" alt="">
            </div>
            <div class="part-content">
                <div class="part-content-top">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/part61.png" alt="">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/part62.png" alt="">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/part63.png" alt="">
                </div>
                <div class="part-content-bottom">
                    <ul class="_clear">
                        <li>
                            <div class="icon1 icon"></div>
                            <p class="icon-title">源码分享</p>
                            <p>分享课程源码及相关</p>
                            <p>教辅资料，帮助学习</p>
                        </li>
                        <li>
                            <div class="icon2 icon"></div>
                            <p class="icon-title">实验指导</p>
                            <p>实战案例指导手册</p>
                            <p>手把手教你做项目</p>
                        </li>
                        <li>
                            <div class="icon3 icon"></div>
                            <p class="icon-title">实训平台</p>
                            <p>线上实训平台，完整</p>
                            <p>实训环境，快速上手</p>
                        </li>
                        <li>
                            <div class="icon icon4"></div>
                            <p class="icon-title">结业证书</p>
                            <p>学完后颁发培训结业</p>
                            <p>证书，展示学习成果</p>
                        </li>
                        <li>
                           <div class="icon icon5"></div>
                            <p class="icon-title">模拟训练</p>
                            <p>从试题到考试环境</p>
                            <p>针对性模拟训练</p>
                        </li>
                        <li>
                            <div class="icon icon6"></div>
                            <p class="icon-title">考前辅导</p>
                            <p>考前冲刺辅导，知</p>
                            <p>识串讲、重点讲解</p>
                        </li>
                        <li>
                            <div class="icon icon7"></div>
                            <p class="icon-title">社群链接</p>
                            <p>社群及学习小组抱团</p>
                            <p>学习、学习经验分享</p>
                        </li>
                        <li>
                            <div class="icon icon8"></div>
                            <p class="icon-title">直播答疑</p>
                            <p>阶段性组织直播讲解、互动答疑</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 证书 -->
        <div class="certificate">
            <!-- <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/zhengshu_pc.png" alt=""> -->
        </div>
        <footers/>
        <div class="fixed-link">
            <div class="fixed-link-top" @click="backTop">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/arrow-top.png" alt="">
                <el-button type="text" id="to-top-btn">回到顶部</el-button>
            </div>
            <div class="fixed-link-bottom">
                <div class="item" @click="showOnLine1" @mouseover="isservice=true" @mouseleave="isservice=false"  id="itemSelf1">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/link.png" alt="">
                    <p>在线咨询</p>
                </div>
                <div class="item" @click="showOnLine2">
                   <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/er.png" alt="" id="itemSelf2">
                   <p>扫码关注</p>
                </div>
            </div>
            
        </div>
        <img v-if="theme==='junmin' && isservice" class="fixed-link-bottom-service" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/junmin/service.png" alt="">
       <div class="online-link" v-if="showOnTip1||showOnTip2" id="onlineTip" :style="showOnTip2?'bottom:70px':'120px'">
           <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/wechat.jpg" alt="">
           <div>
               <p>咨询电话:400-8555-686</p>
               <p>或扫描二维码关注公众号进行咨询</p>
           </div>
       </div>
    </div>
</template>

<script>
import homeBanner from '../block/front/homeBanner.vue'
import footers from '../block/front/footers.vue'
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
export default {
  name: 'gongxin_homepage',
  show: true,
  components: {
      homeBanner,
      footers
  },
    data() {
        return {
            isservice:false,
            theme:'',
            visHeight:0,
            // token:'',
            showOnTip1:false,
            showOnTip2:false,
            dialogVisible:false,
            trainArr:[{
      "basePrice": 0,
      "categoryCode": "string",
      "categoryId": 0,
      "days": 0,
      "endTime": "2021-07-14T11:57:02.277Z",
      "goodsCategoryName": "string",
      "goodsType": 0,
      "goodsTypeName": "string",
      "id": 0,
      "isFree": 0,
      "name": "string",
      "offTime": "2021-07-14T11:57:02.277Z",
      "onTime": "2021-07-14T11:57:02.277Z",
      "picUrl": "string",
      "referId": 0,
      "saleCount": 0,
      "salePrice": 0,
      "seq": 0,
      "startTime": "2021-07-14T11:57:02.277Z",
      "status": 0,
      "statusName": "string",
      "trainPicUrl": "string",
      "tryWatch": 0,
      "validityType": 0,
      "visibleRange": 0
    }],
            liveObj:[],
            liveHas:false,
            swiper:null,
            teamList: [
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/翟健-1.png',
                    imgUrl2: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/翟健-2.png'
                },{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/贾志刚-1.png',
                    imgUrl2: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/贾志刚-2.png'
                },{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/刘英-1.png',
                    imgUrl2: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/刘英-2.png'
                },{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/孙斌-1.png',
                    imgUrl2: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/孙斌-2.png'
                },{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/李鸣-1.png',
                    imgUrl2: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/李鸣-2.png'
                },{
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/任政-1.png',
                    imgUrl2: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/任政-2.png'
                }
            ],
            imgHeight: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/banner1.png',
            carouseId: 0,
            left:11
        };
    },
    filters: {
      dataFormat(msg) {
        if (msg) {
            const m = (msg||'').split(' ')[0].split('-');
            const s = m[0]+'年'+m[1]+'月'+m[2]+'日';
            return s;
        } 
      }
    },
    mounted() {
         document.addEventListener('click',(e) => {
            //获取弹窗对象
            const userCon = document.getElementById('onlineTip');
            const itemSelf1 = document.getElementById('itemSelf1');
            const itemSelf2 = document.getElementById('itemSelf2');
            //判断弹窗对象中是否包含点击对象
            if(userCon && !userCon.contains(e.target) && !itemSelf1.contains(e.target) && !itemSelf2.contains(e.target)) {
                //如果包含则跳转回之前的页面
                this.showOnTip1 = false;
                this.showOnTip2 = false;
            }
        });
        // window.addEventListener("scroll",this.handleFun)
   
    },
    created() {
        this.theme=localStorage.getItem('theme');
        const script=document.createElement("script");
        const script1=document.createElement("script");
        script.type = "text/javascript";
        script1.type = "text/javascript";
        script.src = '//089410.kefu.easemob.com/webim/easemob.js';
        try {
            script1.appendChild(document.createTextNode("window.easemobim = window.easemobim || {};easemobim.config = {hide:true,autoConnect: true};"));
        } catch (ex) {
            script1.text = "window.easemobim = window.easemobim || {};easemobim.config = {hide:true,autoConnect: true};";
        }
        document.body.appendChild(script);
        document.body.appendChild(script1);

        this.findSaleGoods();
        // this.getLive();
        this.$nextTick(function() {
             this.initSwiper();
        });
        // this.token = localStorage.getItem('token');
    },
    beforeDestroy() {
        // window.removeEventListener("scroll",this.handleFun)
    },
    methods: {
        /**
         *@functionName:         
         *@description:在线咨询
         *@date: 2021-05-11 10:16:16
        */
    //    qimoChatClick(){
    //        window.qimoChatClick();
    //    },
        // handleFun(){
        //     var scrollTop = document.documentElement.scrollTop;
        //     if(scrollTop > 1400 && scrollTop < 2400){
        //          document.querySelector('.bulb').style = 'left:11px';
        //         document.querySelector('.img-2').style = 'right: 64px';
        //     }else {
        //         document.querySelector('.bulb').style = 'left: -511px';
        //         document.querySelector('.img-2').style = 'right: -1000px';
        //     }
        // },
        // 跳转直播详情
        goliveDetail(item){
            if(localStorage.getItem('token')){
                this.$api.live.audienceEnterRoom({id: item.id}).then(async (res) => {
                        if (res.success) {
                            this.$router.push({
                                path: '/studentSee',
                                query:{
                                    
                                    roomId: item.id,
                                    type: 1,
                                    title: encodeURIComponent(item.title),
                                }
                            })
                        }
                });
            }else {
                this.$router.push({
                    path: '/login',
                    query: {
                        name: '/gongxin_homepage'
                    }
                })
            }
        },
        carouselChange:function(now){
			/* console.log(now+"，"+old); */
            this.carouseId = now;
		},
        goEvaluation(){
            if(localStorage.getItem('token')){
                this.$router.push('/personalEvaluation')
            } else {
                this.$router.push({
                    path: '/login',
                    query: {
                        name: '/personalEvaluation'
                    }
                })
            }
        },
        details() {
            this.$router.push({
                path:'/gongxinDetails',
                query:{
                    id:this.trainArr[0].id
                }
            })
        },
        backTop(){
          var timer = setInterval(function(){
            let osTop = document.documentElement.scrollTop || document.body.scrollTop;
            let ispeed = Math.floor(-osTop / 5); 
            document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
            this.isTop = true;
            if(osTop === 0){
              clearInterval(timer);
            }
          },30)
        },
        goLive(){
            if(localStorage.getItem('token')){
                // let routeData = this.$router.resolve({ path: '/liveList' });
                // window.open(routeData.href, '_self');
                this.$router.push('/liveList')
            } else {
                this.$router.push({
                    path: '/login',
                    query: {
                        name: '/liveList'
                    }
                })
            }
        },
        showOnLine1(){
            if(this.theme==='junmin'){
                return;
            }
            /* eslint-disable */
            // easemobim.bind({configId: "0ab2e4b7-f4e7-4b6d-8f4f-2cabb1319de0"})
            window.qimoChatClick();
            /* eslint-disable no-new */
            // this.showOnTip1  = !this.showOnTip1;
            // this.showOnTip2 = false;
        },
        showOnLine2(){
            this.showOnTip2  = !this.showOnTip2;
            this.showOnTip1 = false;
        },
        gongxinDetails(id,type){
            if(type==1){
                this.$router.push({
                    path:'/gongxinDetails',
                    query:{
                        id:id
                    }
                })
            }else{
                this.$router.push({
                    path:'/gongxinDetailsTwo',
                    query:{
                        id:id
                    }
                })
            }
           
        },
        findSaleGoods() {
            this.$api.gongxinIndex.findSaleGoods().then(res=>{
                if(res.data){
                    this.trainArr = res.data;
                    sessionStorage.setItem('goodsId', this.trainArr[0].id)
                    // if(this.trainArr[0].name.length>16){
                    //     this.trainArr[0].name = this.trainArr[0].name.substring(0,16)+'...'
                    // }
                    // if(this.trainArr[1].name.length>16){
                    //     this.trainArr[1].name = this.trainArr[1].name.substring(0,16)+'...'
                    // }
                }
            })
        },
        // getLive(){
        //     this.$api.home.getLive(1,3).then(res=>{
        //         if(res.data && res.data.list.length>0){
        //             this.liveObj = res.data.list;
        //             this.liveHas = true;
        //             // if(this.liveObj.title.length>16){
        //             //     this.liveObj.title = this.liveObj.title.substring(0,16)+'...'
        //             // }
        //         }else{
        //             this.liveHas = false;
        //         }
        //     })
        // },
        initSwiper() {
            this.swiper = new Swiper(".swiper-container", {
                slidesPerView: 4,
                spaceBetween: 0,
                autoplay:true,
                loop:true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                // pagination: {
                //     el: '.swiper-pagination',
                //     clickable: true,
                // },
            });
        },
    }
};
</script>
<style lang="stylus" scoped>
@import '../asset/css/gongxinHomepage'
</style>
