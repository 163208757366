<template>
    <div class="homePage">
        <homeBanner />
        <family />
        <footers />
    </div>
</template>

<script>
import homeBanner from '../block/front/homeBanner.vue'
import family from '../block/front/family.vue'
import footers from '../block/front/footers.vue'
export default {
  name: 'jiazheng_homepage',
  show: true,
  components: {
      homeBanner,
      family,
      footers,
  },
    data() {
        return {
        };
    },
    mounted() {

    },
    created() {
    },
    beforeDestroy() {

    },
    methods: {

    }
};
</script>
<style lang="stylus">
</style>
<style lang="stylus" scoped>
@import '../asset/css/jiazhengHomepage'
</style>
