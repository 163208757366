<template>
    <div class="taskDetailBg">
        <div class="taskDetail">
            <div class="backButton" v-if="!theme || theme=='junmin'">
                <span @click="goBack">
                    <i class="back el-icon-arrow-left"></i>
                    返回
                </span>
            </div>
            <div class="task-container">
                <div class="task-top">
                    <span class="task-name">{{taskDetail.name}}</span>
                    <span class="task-progress" v-show="taskDetail.courseNum">已完成 {{taskDetail.studyProcess}}%</span>
                </div>
                <section>
                    <aside>
                        <div class="cover">
                            <img :src="taskDetail.coverUrl" alt="">
                        </div>
                        <div class="time" v-show="$route.query.validity === '' || $route.query.validity === 'true'">
                            <p>任务周期：</p>
                            <p v-if="taskDetail.cycleType ===1">{{taskDetail.startTime.split(' ')[0].replace(/-/g,'.')}} 至 {{taskDetail.endTime.split(' ')[0].replace(/-/g,'.')}}</p>
                            <p v-if="taskDetail.cycleType ===2">有效期至：{{taskDetail.endTime.split(' ')[0].replace(/-/g,'.')}}</p>
                            <p v-if="taskDetail.cycleType ===3">长期有效</p>
                        </div>
                        <div class="info" @click="info = true">
                            培训任务简介
                        </div>
                    </aside>
                    <article>
                        <taskItem :itemList="taskDetail.taskItemVoList" :taskDetail="taskDetail" :showSourse="showSourse"></taskItem>
                        <p class="course-all">-已加载全部内容-</p>
                    </article>
                </section>
            </div>
        <dialogInfo :info="info" :currentCourse="taskDetail" :type="3" :nameType='"培训任务简介："' @infoShow="infoShow"></dialogInfo>
        </div>
    </div>
</template>
<script>
import dialogInfo from './../../train/page/dialogInfo.vue'
export default {
    name:'taskDetail',
    components:{dialogInfo},
    data(){
        return{
            taskDetail:{},//任务详情
            info: false,
            showSourse:null,
            theme:''
        }
    },
    created(){
        this.getTaskDetail()
        this.theme = localStorage.getItem('theme');
    },
    methods:{
        infoShow(val){
            this.info = val
        },
        goBack(){
            if(this.$route.query.backPath){
                sessionStorage.setItem('userCenterCurrentTab','我的学习')
                this.$router.push({
                    path:this.$route.query.backPath,
                    query:{
                        goodsId:this.$route.query.goodsId,
                    }
                })
            }else{
                this.$router.push({
                    path:'/learning',
                    query:{
                        backModule:this.$route.query.backMoudle
                    }
                })
            }
            
        },
        getTaskDetail(){
            const params = {
                id:this.$route.query.id || null
            }
            this.$api.learning.taskDetail({params}).then(res => {
                this.taskDetail = res.data;
                this.taskDetail.taskItemVoList.length && this.taskDetail.taskItemVoList.forEach((item,index) => {
                    if(item.type != 1 && !index){
                        this.showSourse = true
                    }else if(item.type == 1 && !index){
                        this.showSourse = false
                    }
                })
            })
        }
    }
}
</script>
<style lang="stylus" scoped>
@import '../asset/css/taskDetail.styl'
</style>