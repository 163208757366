<template>
    <div class="learning-box">
        <div class="tab-container">
            <p class="text" v-for="item in tabList" :key="item.id" :class="{active : tabValue === item.key}" @click="tabClick(item)">
                {{item.value}}
                <span class="line"></span>
            </p>
        </div>
        <trainingTask v-if="tabValue === 'trainingTask'" :validity="validity ? validity.value : ''"></trainingTask>
        <trainingCamp v-if="tabValue === 'trainingCamp'" :validity="validity ? validity.value : ''"></trainingCamp>
        <evaluationList v-if="tabValue === 'evaluation'" :validity="validity ? validity.value : ''"></evaluationList>
        <exam-list v-if="tabValue === 'exam'"></exam-list>
        <liveList v-if="tabValue === 'live'"></liveList>
        <practiceList v-if="tabValue === 'test'"></practiceList>
    </div>
</template>
<script>
import practiceList from '../../practice/page/practiceList.vue'
import config from '@/config/url'

export default {
    name:'learning',
    components:{
        practiceList
    },
    data(){
        return{
            tabValue:'',
            tabList:[
                // {
                //     id:1,
                //     key:'trainingTask',
                //     value:'培训任务'
                // },
                // {
                //     id:2,
                //     key:'trainingCamp',
                //     value:'训练营'
                // },
                // {
                //     id:3,
                //     key:'evaluation',
                //     value:'测评'
                // },
                // {
                //     id:4,
                //     key:'exam',
                //     value:'考试'
                // }
            ],
            validity:''

        }
    },
    created(){
      console.log('learning++++++')
        this.$api.configure.configuration(config.confUrl).then(res => {
          if (res.data) {
            let frontendConfig = res.data.frontendConfig.find(item => {
              return item.key === "homepage";
            });
             localStorage.setItem("frontendConfig",frontendConfig ? JSON.stringify(frontendConfig) : "");
            const configurationArr = JSON.parse( localStorage.getItem('configurationArr') )
            // tab 数组
            const learningTab = configurationArr && configurationArr.filter(item => {
              if(item.key === 'task_tag_array_learn_center'){
                return true
              }
            })[0];
            // 是否显示训练营，任务，测评 有效期
            this.validity =  configurationArr && configurationArr.filter(item => {
              if(item.key === 'is_show_task_term_validity'){
                return true
              }
            })[0];
            // this.validity = validity || ''

            this.tabList = learningTab && JSON.parse(learningTab.value) || []
            this.tabValue = this.$route.query.backModule || (this.tabList.length && this.tabList[0].key)
          }
        });




    },
    methods:{
        tabClick(item){
            this.tabValue = item.key;
        },
    }

}
</script>
<style lang="stylus" scoped>
@import '../asset/css/learning.styl'
</style>
