import { render, staticRenderFns } from "./gongxinNav.vue?vue&type=template&id=66b4cd3a&scoped=true&"
import script from "./gongxinNav.vue?vue&type=script&lang=js&"
export * from "./gongxinNav.vue?vue&type=script&lang=js&"
import style1 from "./gongxinNav.vue?vue&type=style&index=1&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66b4cd3a",
  null
  
)

export default component.exports