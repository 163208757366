<template>
    <el-dialog
        title=""
        :visible.sync="dialogShow.dialogVisible3"
        :show-close="false"
        :before-close="handleClose3"
        class="companyCredit">
        <div class="title">
            <h3>家政公司信用报告</h3>
            <span>更新日期：{{updateTime}}</span>
        </div>
        <div class="essentialInformation">
            <h3>企业基本信息</h3>
            <ul class="list">
                <li>
                    <span class="label">企业名称：</span>
                    <span class="con">{{reportDetails.companyName}}</span>
                </li>
                <li>
                    <span class="label">营业执照号：</span>
                    <span class="con">{{reportDetails.companyCreditCode}}</span>
                </li>
                <li>
                    <span class="label">联系方式：</span>
                    <span class="con">{{reportDetails.companyUserName}}&nbsp;{{reportDetails.companyPhone}}</span>
                </li>
                <li>
                    <span class="label">公司地址：</span>
                    <span class="con">{{reportDetails.companyStoreAddress}}{{reportDetails.companyHouseNumber}}</span>
                </li>
            </ul>
        </div>
        <div class="credit">
            <div class="score">信用分值： {{reportDetails.creditScore}}</div>
            <div class="grade">
                信用等级：
                <span v-for="(item, index) in activeStars" :key="index+'a'">
                    <img src="../../asset/images/stars-icon1.png" alt="">
                </span>
                <span v-for="(item, index) in noActiveStars" :key="index+'b'">
                    <img src="../../asset/images/stars-icon2.png" alt="">
                </span>
            </div>
        </div>
        <div class="colRight">
            <div id="main" style="width: 500px;height:280px;"></div>
        </div>
        <div class="tableList">
            <h3><span class="line"></span>企业信用记录</h3>
            <div class="radius">
                <table class="table" border="1" cellspacing="0" cellpadding="0">
                    <tr>
                        <td class="label">企业名称</td>
                        <td class="con">{{reportDetails.companyName || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">社会统一代码</td>
                        <td class="con">{{reportDetails.companyCreditCode || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">企业类型</td>
                        <td class="con">{{reportDetails.companyType || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">成立时间</td>
                        <td class="con">{{reportDetails.companySetUpTime || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">地址</td>
                        <td class="con">{{reportDetails.companyStoreAddress || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">法定代表人</td>
                        <td class="con">{{reportDetails.companyLegalPerson || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">业务范围</td>
                        <td class="con">{{reportDetails.companyBusinessScope || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">企业状态</td>
                        <td class="con">{{reportDetails.companyState || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">纳税等级</td>
                        <td class="con">{{reportDetails.companyTexLevel || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">政府部门认定的企业信用等级</td>
                        <td class="con">{{reportDetails.companyCreditLevel || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">受表彰奖励情况</td>
                        <td class="con">{{reportDetails.companyCommendStatus || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">金融征信记录</td>
                        <td class="con">{{reportDetails.companyFinancialCreditRecord || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">行政处罚记录</td>
                        <td class="con">{{reportDetails.companyPunishmentRecord || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">“信用中国”记录</td>
                        <td class="con">{{reportDetails.companyCreditChinaRecord || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">12312投诉情况</td>
                        <td class="con">{{reportDetails.companyComplainStatus || '未查询到数据'}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="tableList">
            <h3><span class="line"></span>顾客管理</h3>
            <div class="radius">
                <table class="table" border="1" cellspacing="0" cellpadding="0">
                    <tr>
                        <td class="label">整体投诉处理率</td>
                        <td class="con">{{reportDetails.customerComplainDealPercent || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">顾客满意度分值</td>
                        <td class="con">{{reportDetails.customerSatisfiedScore || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">顾客隐私保护</td>
                        <td class="con">{{reportDetails.customerPrivacyProtect || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">顾客信用管理</td>
                        <td class="con">{{reportDetails.customerCreditManage || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">交易顾客评价比例</td>
                        <td class="con">{{reportDetails.customerTradeCommentPercent || '未查询到数据'}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="tableList">
            <h3><span class="line"></span>内部管理</h3>
            <div class="radius">
                <table class="table" border="1" cellspacing="0" cellpadding="0">
                    <tr>
                        <td class="label">岗前培训</td>
                        <td class="con">{{reportDetails.insidePreJoinTrade || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">回炉培训</td>
                        <td class="con">{{reportDetails.insideBakTrade || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">心理健康培训</td>
                        <td class="con">{{reportDetails.insidePsychologicalHealthyTrade || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">职业道德培训</td>
                        <td class="con">{{reportDetails.insideProfessionalMoralTrade || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">培训人数</td>
                        <td class="con">{{reportDetails.insideTradePercent || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">“员工制”情况</td>
                        <td class="con">{{reportDetails.insidePersonnelPercent || '未查询到数据'}}</td>
                    </tr>
                    <tr>
                        <td class="label">员工整体测评完成率</td>
                        <td class="con">{{reportDetails.insidePersonnelAppraiseCompletePercent || '未查询到数据'}}</td>
                    </tr>
                    <!-- <li>
                        <span class="label">员工整体测评完成率</span>
                        <span class="con">{{reportDetails.insidePersonnelAppraiseCompletePercent}}</span>
                    </li> -->
                    <tr>
                        <td class="label">员工签约及履约</td>
                        <td class="con">{{reportDetails.insideHonourPercent || '未查询到数据'}}</td>
                    </tr>
                    <!-- <tr>
                        <td class="label">员工信用档案管理</td>
                        <td class="con">{{reportDetails.customerTradeCommentPercent || '未查询到数据'}}</td>
                    </tr> -->
                </table>
            </div>
        </div>
    </el-dialog>
</template>
<script>
export default {
    name:'companyCredit',
    props:{
        dialogShow:{
            type:Object,
            default:() => ({
                dialogVisible3:false
            })
        },
        companyId:{
            type:Number,
            default:0
        }
    },
    data(){
        return{
            reportDetails: {},
            activeStars: 0,
            noActiveStars: 0,
            indicator: [],
            seriesData: [],
            updateTime: ''
        }
    },
    mounted() {
        this.findByCompanyId();
    },
    methods:{
        findByCompanyId() {
            this.$api.jiazheng.findByCompanyId(this.companyId).then((res) => {
                if (res.success) { 
                    this.reportDetails = res.data;
                    this.updateTime = this.reportDetails.updateTime.split(' ')[0];
                    this.activeStars = this.reportDetails.creditScoreLevel;
                    this.noActiveStars = 5 - this.reportDetails.creditScoreLevel;
                    this.indicator = [];
                    this.seriesData = [];
                    this.reportDetails.dimensionScoreDtoList.forEach(item=>{
                        this.indicator.push({
                            name: item.indicatorName,
                            max: item.indicatorMaxScore
                        })
                        this.seriesData.push(item.indicatorScore)
                    })
                    this.$nextTick(()=>{
                        this.drawChart();
                    })
                }
            })
        },
        handleClose3(){
            this.dialogShow.dialogVisible3 = false;
        },
        drawChart() {
            const that = this;
            var myChart = that.$echarts.init(document.getElementById("main"));
            myChart.setOption(
                {
                    radar: [{ 
                        // 雷达图绘制类型，支持 'polygon'(多边形) 和 'circle'(圆)。[ default: 'polygon' ]
                        shape: 'polygon',
                        // 坐标系起始角度，也就是第一个指示器轴的角度。[ default: 90 ]
                        name: { // (圆外的标签)雷达图每个指示器名称的配置项。
                            formatter: '{value}',
                            textStyle: {
                                fontSize: 12,
                                color: '#183B56',
                                fontWeight: "bolder"
                            }
                        },
                        nameGap: 10,
                        // 指示器名称和指示器轴的距离。[ default: 15 ]
                        splitNumber: 4,
                        // (这里是圆的环数)指示器轴的分割段数。[ default: 5 ]
                        axisLine: { // (圆内的几条直线)坐标轴轴线相关设置
                            lineStyle: {
                                color: 'rgba(205, 212, 236, 0.39)',
                                // 坐标轴线线的颜色。
                                width: 2,
                                // 坐标轴线线宽。
                                type: 'solid',
                                // 坐标轴线线的类型。
                            }
                        },
                        splitLine: { // (这里是指所有圆环)坐标轴在 grid 区域中的分隔线。
                            lineStyle: {
                                color: 'rgba(205, 212, 236, 0.39)',
                                // 分隔线颜色
                                width: 2,
                                // 分隔线线宽
                            }
                        },
                        splitArea: { // 坐标轴在 grid 区域中的分隔区域，默认不显示。
                            show: true,
                            areaStyle: { // 分隔区域的样式设置。
                                color: '#fff',
                                // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
                            }
                        },
                        indicator: this.indicator,
                        center: ["50%", "65%"],
                        radius: ["0%", "80%"]
                    }],
                    series: [{
                        type: 'radar',
                        // 系列类型: 雷达图
                        itemStyle: { // 折线拐点标志的样式。
                            color: '#3693F2',
                            emphasis: { // 高亮时的样式
                                lineStyle: {
                                    width: 5
                                },
                                opacity: 1
                            }
                        },
                        data: [{ // 雷达图的数据是多变量（维度）的
                            // 数据项名称
                            value: this.seriesData,
                            // 其中的value项数组是具体的数据，每个值跟 radar.indicator 一一对应。
                            symbol: 'circle',
                            // 单个数据标记的图形。
                            symbolSize: 0,
                            // 单个数据标记的大小，可以设置成诸如 10 这样单一的数字，也可以用数组分开表示宽和高，例如 [20, 10] 表示标记宽为20，高为10。
                            label: { // 单个拐点文本的样式设置 
                                normal: {
                                    show: true,
                                    // 单个拐点文本的样式设置。[ default: false ]
                                    position: 'top',
                                    // 标签的位置。[ default: top ]
                                    distance: 8,
                                    // 距离图形元素的距离。当 position 为字符描述值（如 'top'、'insideRight'）时候有效。[ default: 5 ]
                                    color: '#1F3482',
                                    // 文字的颜色。如果设置为 'auto'，则为视觉映射得到的颜色，如系列色。[ default: "#fff" ]
                                    fontSize: 12,
                                    // 文字的字体大小
                                    fontWeight: "bolder",
                                }
                            },
                            lineStyle: { // 单项线条样式。
                                normal: {
                                    opacity: 0.5 // 图形透明度
                                }
                            },
                            areaStyle: { // 单项区域填充样式
                                normal: {
                                    color: 'rgba(80, 156, 234, 0.51)' // 填充的颜色。[ default: "#000" ]
                                }
                            }
                        }]
                    }]
                }
            );
        }
    }
}
</script>
<style lang="stylus" scoped>
@import "../../asset/css/companyCredit.styl"
</style>