<template>
    <div class="red_sdjzHomepage">
        <div class="banner">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/red_sdjzHomepage/redBanner.png" />
        </div>
        <div class="serviceCompany">
            <div class="starTitle">诚信服务明星企业</div>
            <div class="line"></div>
            <div class="star">
                <div class="starCompany">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/red_sdjzHomepage/logo-1.png" />
                    <div class="starName">亿家乐</div>
                    <div class="starAddress">家政服务有限责任公司</div>
                </div>
                <div class="starCompany">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/red_sdjzHomepage/logo-2.png" />
                    <div class="starName">保丽洁物业</div>
                    <div class="starAddress">家政服务有限责任公司</div>
                </div>
                <div class="starCompany">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/red_sdjzHomepage/logo-3.png" />
                    <div class="starName">仁合妇婴</div>
                    <div class="starAddress">家政服务有限责任公司</div>
                </div>
                <div class="starCompany">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/red_sdjzHomepage/logo-4.png" />
                    <div class="starName">青岛三替家政</div>
                    <div class="starAddress">家政服务有限责任公司</div>
                </div>
            </div>
        </div>
        <div class="servicePersonal">
            <div class="personalTitle">诚信服务明星个人</div>
            <div class="line personalLine"></div>
            <div class="starPersonal">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/red_sdjzHomepage/personal-1.png" />
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/red_sdjzHomepage/personal-2.png" />
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/red_sdjzHomepage/personal-3.png" />
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/red_sdjzHomepage/personal-4.png" />
            </div>
        </div>
        <div class="informationPolicy">
            <div class="policyTitle">政策资讯</div>
            <div class="line"></div>
            <div class="msg">
                <div class="singleMsg">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/red_sdjzHomepage/bottom-1.png" />
                    <ul class="testBox">
                        <li v-for="(item,index) in policyList" :key="index" @click="goMsg(item.link)">
                            <p class="msgText">{{item.text}}</p>
                            <p class="msgDate">{{item.date}}</p>
                        </li>
                    </ul>
                </div>
                <div class="singleMsg">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/red_sdjzHomepage/bottom-2.png" />
                    <ul class="testBox">
                        <li v-for="(item,index) in industryTrends" :key="index" @click="goMsg(item.link)">
                            <p class="msgText">{{item.text}}</p>
                            <p class="msgDate">{{item.date}}</p>
                        </li>
                    </ul>
                </div>
                <div class="singleMsg">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/red_sdjzHomepage/bottom-3.png" />
                    <ul class="testBox">
                        <li v-for="(item,index) in notification" :key="index" @click="goMsg(item.link)">
                            <p class="msgText">{{item.text}}</p>
                            <p class="msgDate">{{item.date}}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <footers></footers>
    </div>
</template>

<script>
import footers from './sdjz2Footer.vue'
export default {
  name: 'red_sdjzHomepage',
  show: true,
    data() {
        return {
            policyList:[
                {text:"商务部、国家发展改革委印发《关于建立家政服务业信用体系的指导意见》",date:'2021-06-17 15:34:29',link:'https://sdjz.wdeduc.com/map/34fc24f2-024c-4979-9e1d-f584dd92ee42'},
                {text:"关于实施康养职业技能培训计划的通知",date:'2021-06-17 15:34:33',link:'https://sdjz.wdeduc.com/map/34fc24f2-024c-4979-9e1d-f584dd92ee42'},
                {text:"国家发改委、商务部：促进家政服务业提质扩容突出六大狂欢",date:'2021-06-17 15:34:38',link:'https://sdjz.wdeduc.com/map/34fc24f2-024c-4979-9e1d-f584dd92ee42'},
                {text:"国家发改委解读“家政36条”要提质扩容做到“无信用不上岗",date:'2021-06-17 15:34:40',link:'https://sdjz.wdeduc.com/map/34fc24f2-024c-4979-9e1d-f584dd92ee42'},
                {text:"国务院办公厅关于促进家政服务业提质扩容的意见",date:'2021-06-17 15:34:57',link:'https://sdjz.wdeduc.com/map/34fc24f2-024c-4979-9e1d-f584dd92ee42'},
                {text:"查看更多 >",link:'https://sdjz.wdeduc.com/map/34fc24f2-024c-4979-9e1d-f584dd92ee42'},
            ],
            industryTrends:[
                {text:"事关1.7亿人！这些人也能评职称了",date:'2021-06-17 15:34:29',link:'https://sdjz.wdeduc.com/map/34fc24f2-024c-4979-9e1d-f584dd92ee42'},
                {text:"2020年底前家政服务信用信息要山东统一管理",date:'2021-06-17 15:34:33',link:'https://sdjz.wdeduc.com/map/34fc24f2-024c-4979-9e1d-f584dd92ee42'},
                {text:"山东省多提升家政服务业水平",date:'2021-06-17 15:34:52',link:'https://sdjz.wdeduc.com/map/34fc24f2-024c-4979-9e1d-f584dd92ee42'},
                {text:"山东现代家服中心多措并举构建家政服务体系建设新格局",date:'2021-06-17 15:34:56',link:'https://sdjz.wdeduc.com/map/34fc24f2-024c-4979-9e1d-f584dd92ee42'},
                {text:"商务部部长钟山《主题》刊文：推进水平横向开放",date:'2021-06-17 15:34:25',link:'https://sdjz.wdeduc.com/map/34fc24f2-024c-4979-9e1d-f584dd92ee42'},
                {text:"查看更多 >",link:'https://sdjz.wdeduc.com/map/34fc24f2-024c-4979-9e1d-f584dd92ee42'},
            ],
            notification:[
                {text:"人力资源社会保障部办公厅关于加强新职业培训工作的通知",date:'2021-06-17 15:34:43',link:'https://sdjz.wdeduc.com/map/34fc24f2-024c-4979-9e1d-f584dd92ee42'},
                {text:"商务部办公关于促进社区消费解决厅电话智能技术故障的通知",date:'2021-06-17 15:34:32',link:'https://sdjz.wdeduc.com/map/34fc24f2-024c-4979-9e1d-f584dd92ee42'},
                {text:"完善数字家庭服务功能促进互联平台互通",date:'2021-06-17 15:34:23',link:'https://sdjz.wdeduc.com/map/34fc24f2-024c-4979-9e1d-f584dd92ee42'},
                {text:"关于2021年“智慧助老”有关工作的通知",date:'2021-06-17 15:34:47',link:'https://sdjz.wdeduc.com/map/34fc24f2-024c-4979-9e1d-f584dd92ee42'},
                {text:"五部门联合宣布计划实施康养职业技能培训的通知",date:'2021-06-17 15:34:53',link:'https://sdjz.wdeduc.com/map/34fc24f2-024c-4979-9e1d-f584dd92ee42'},
                {text:"查看更多 >",link:'https://sdjz.wdeduc.com/map/34fc24f2-024c-4979-9e1d-f584dd92ee42'},
            ],
        };
    },
    components:{
        footers
    },
    mounted() {

    },
    created() {
    },
    beforeDestroy() {

    },
    methods: {
        goMsg(link){
            window.open(link)
        }
    }
};
</script>
<style lang="stylus">
@import '../asset/css/red_sdjzHomepage.styl';
</style>