<template>
  <div class="onlyCourseGoodsBg">
    <div class="courseGoodsCont" ref="courseGoodsContRef">
      <div class="goodsSearchCont"  ref="searchBtnRef" v-if="isShowSearchBox">
        <div class="searchResultTips fl" v-if="isShowSearchResult">共有{{paging.total}}个包"{{goodsKey}}"的课程</div>
        <div class="searchIptBox fr">
          <input type="text" ref="searchInp" class="searchIpt" v-model="goodsKey" v-on:keyup.enter="getData('searchBtn')" placeholder="请输入关键字"/>
          <span class="searchBtn"  @click="getData('searchBtn')"></span>
        </div>
      </div>
      <div class="classifyArea">
        <div class="courseClassifyItem" v-if="isShowClassifyBox">
            <div class="courseClassifyTitle">课程分类:</div>
            <div class="courseClassifyItemListCont">
                <ul class="courseClassifyItemList firstCourseClassifyItemList" >
                  <li v-for="(item, idx) in  firstCourseClassify" :key="idx">
                    <span v-if="item.showTag" :class="{active: item.isActive }"  @click="clickFirstClassifyHandle(idx, item)">{{item.name}}</span>
                  </li>
                </ul>
                 <ul class="courseClassifyItemList marginTop16"   :class="{borderTopStyle: secondCourseClassify && secondCourseClassify}" v-if="secondCourseClassify.length">
                   <li v-for="(item, idx) in  secondCourseClassify" :key="idx">
                    <span v-if="item.showTag" :class="{active: item.isActive }"  @click="clickSecondClassifyHandle(idx, item)">{{item.name}}</span>
                   </li>
                 </ul>
                 <ul class="courseClassifyItemList marginTop16 threeClassifyStyle"  v-if="threeCourseClassify.length">
                   <li v-for="(item, idx) in  threeCourseClassify" :key="idx">
                    <span v-if="item.showTag" :class="{active: item.isActive }"  @click="clickThreeClassifyHandle(idx, item)">{{item.name}}</span>
                   </li>
                 </ul>   
            </div>
        </div>
        <div class="comprehensiveSortItem"  v-if="isShowClassifyBox">
            <div class="comprehensiveSortItemTitle">综合排序:</div>
            <div class="comprehensiveSortItemListCont">
                <ul class="comprehensiveSortItemList">
                  <li  v-for="(item, idx) in  typeList" :key="idx">
                    <span  :class="{active: item.isActive }" @click="clickTypeSortHandle(idx, item)">{{item.name}}</span>
                  </li>
                </ul>
            </div>    
        </div>    
      </div>
      <div class="courseGoodsListCont">
         <div class="configTile" v-if="isShowTitlechBox || isShowMoreGoodsBox"><font v-if="isShowTitlechBox">课程列表</font><span v-if="isShowMoreGoodsBox"  class="moreGoods" @click="moreCourseGoodsPage()">更多</span></div> 
          <div class="courseGoodsList couseGoodsListStyle"   v-if="courseListArray.length && !Array.isArray(courseListArray[0])">
              <div>
                <div class="courseGoodsListItem"   v-for="(item, idx) in  courseListArray" :key="idx" @click="jumpGoodsDetailHandle(item)">
                  <div class="goodsPoster">
                    <img :src="item.picUrl"/>
                  </div>
                  <div class="goodsProduceInfor">
                    <div class="produceTitle">
                      <span class="courseHotIcon pinkHotIcon fl"  v-if="item.taskType == 1">必修课</span>
                      <span class="courseHotIcon greenHotIcon  fl"  v-if="item.taskType == 2">选修课</span>
                      <span class="courseHotIcon blueHotIcon fl"  v-if="item.taskType == 3">公开课</span>
                      <p class="textCut fl">{{item.name}}</p>
                    </div>               
                    <div class="goodsPrice" v-if="item.isFree"><span class="normalPrice">免费</span></div>
                    <div class="goodsPrice" v-else><span class="normalPrice">¥{{item.salePrice}}</span><span class="discountPrice" v-if="item.basePrice">¥{{item.basePrice}}</span></div>
                    <div class="nowSignUp">
                        <span class="signUpNum" v-if="isShowNumberBuyerBox">{{item.purchaseNo}}人已报名</span>             
                        <span class="nowLearnBtn">立即报名</span>
                        <!-- <span class="nowLearnBtn" @click.stop="nowLearnHandle(isLogin, item)">{{isLogin ? '立即学习' : '立即报名'}}</span> -->
                    </div> 
                  </div>
                </div>
              </div>

          </div>
  
         <div class="noGoodsTips" v-if="(isShowSearchBox || isShowClassifyBox) && isShowSearchResult && !paging.total">没有搜索到相关课程哟～</div>
         <div class="nothingTips" v-if="!isShowSearchResult && !courseListArray.length">还没有课程哟～ </div>
         <div v-if="paging.currentSize > 0 && (paging.currentSize <  paging.params.pageSize)" class="daodilaTips">到底啦，更多内容即将上线</div>
         <paging class="paging pagingCustom" :paging="paging" v-if="!isShowOneOrManyPageBox && paging.totalPages > 1" />
      </div>
    </div>
  </div>
</template>

<script>
import paging from "../../../layout/paging";
import conf  from '../../../config/url';
import Vue from 'vue'

export default {
  name: 'courseGoods',
  components: {
    paging,

  },
  data () {
    return {
          firstCourseClassify:[],

          secondCourseClassify: [],

          threeCourseClassify: [],

          paging: {
              params: {
                  pageNum: 1,
                  pageSize: 20
              },
              total: 0,
              totalPages: 0,
              currentSize: 0,
          },
          typeList: [
              {
                type: 1,
                name: '最新排序',      
                isActive: true

              },
              {
                type: 2,
                name: '人气排序',
                isActive: false

              },              

          ],
          currentType: 1,
          categoryCode: '',
          firstCategoryCode: '',
          secondCategoryCode: '',
          threeCategoryCode: '',

          goodsKey: '',
          isShowSearchResult: false,
          
          courseListArray:[[]],

          isShowSearchBox: false,

          isShowClassifyBox: false,

          isShowTitlechBox: false,

          isShowMoreGoodsBox: false,

          isShowNumberBuyerBox: false,

          isShowOneOrManyPageBox: false,

          isShowHowManyListBox: false,

          isShowManyPageManyListBox: false,

          isShowContSourceBox: false,

          isLogin: localStorage.getItem('token'),
          goodsIds:'',
    }
  },
  watch:{
    'goodsKey'(newVal){
        if(!newVal){
          this.paging.params.pageNum = 1
          this.getData()
        }
    },
  },
  filters: {


  },
  created () {
    this.theme = localStorage.getItem('theme');
    this.configMenuInfor();
  },
  computed: {

  },
  mounted() {
      const configurationArr = JSON.parse( localStorage.getItem('configurationArr') )
        // 商品多个id
        this.goodsIds = configurationArr && configurationArr.filter(item => {
            if(item.key === 'goods_live_list'){
                return true
            }
        })[0];
  },

  beforeDestroy() {

  },


  methods: {
    getClassifyFindAll(){
      this.$api.coursegoods.findAll({})
      .then(res=>{
          let allName = {
            'code': '',          
            'name': "全部",
            'showTag': true,
          }
          if (res.data && res.data.length) {
            this.firstCourseClassify = res.data;
            const { code, classType } = this.$route.query
            console.log('this.$route.query.code===', code)
            if (code) {
              this.setFirstCourse(allName, code, true)
            }else if (classType) {
              this.setFirstCourse(allName, classType)
            }
            else {
              this.firstCourseClassify.unshift(allName);   
              Vue.set(this.firstCourseClassify[0], 'isActive', true)
              this.categoryCode = this.firstCourseClassify[0].code;
            }
            this.getData();
          }
          
      })
    },
    setFirstCourse(allName, type, isBoolean){
      let currentTabIdx = 0
      this.firstCourseClassify.forEach((val, idx) => {
        console.log('val.code == this.$route.query.code===', idx == type)
        if ((idx+1) == type && !isBoolean) {
            console.log(val, idx)
            currentTabIdx = idx + 1
            this.secondCourseClassify = val.children || []
        }
        if (val.code == type && isBoolean) {
            console.log(val, idx)
            currentTabIdx = idx + 1
            this.secondCourseClassify = val.children || []
        }
      })
      
      this.firstCourseClassify.unshift(allName);   
      Vue.set(this.firstCourseClassify[currentTabIdx], 'isActive', true);
      this.categoryCode = this.firstCourseClassify[currentTabIdx].code;
    },

    async clickFirstClassifyHandle(idx, item) {     
      this.paging.params.pageNum = 1;
      this.secondCourseClassify = []
      this.threeCourseClassify = []
      this.firstCategoryCode = item.code
      this.categoryCode = this.firstCategoryCode;
      await this.getData()
      this.firstCourseClassify.forEach((val, index) => {
        if (idx == index) {
          Vue.set(val, 'isActive', true);
          if (val.children) {
              if (val.children.length == 1 && !val.children[0].showTag) {
                  this.secondCourseClassify = []
              } else {
                  this.secondCourseClassify = val.children
                  this.secondCourseClassify.forEach((val) => {
                  // if (index == 0) {
                  //    Vue.set(val, 'isActive', true);
                  //  } else {
                          Vue.set(val, 'isActive', false)
                  //   }
                  })                
              }





          } else {
              this.secondCourseClassify = []
              this.threeCourseClassify = []
          }

        } else {
          Vue.set(val, 'isActive', false)

        }
      })

    },

    async clickSecondClassifyHandle(idx, item) {
        this.paging.params.pageNum = 1;
        this.threeCourseClassify = []
        this.secondCategoryCode = item.code
        this.categoryCode =  item.code;
        await this.getData()
        this.secondCourseClassify.forEach((val, index) => {
          if (idx == index) {
            Vue.set(val, 'isActive', true);
            if (val.children) {
                this.threeCourseClassify = val.children
                this.threeCourseClassify.forEach((val) => {
                Vue.set(val, 'isActive', false)
                })

            } else {
              this.threeCourseClassify = []
            }

          } else {
            Vue.set(val, 'isActive', false)
          }
      })

    },


    async clickThreeClassifyHandle(idx, item) {
        this.paging.params.pageNum = 1;
        this.threeCategoryCode = item.code
        this.categoryCode = item.code;
        await this.getData()
        this.threeCourseClassify.forEach((val, index) => {
          if (idx == index) {
            Vue.set(val, 'isActive', true);

          } else {
            Vue.set(val, 'isActive', false)
          }
      })
 
    },


    async clickTypeSortHandle(idx, item) {
        this.paging.params.pageNum = 1;
        this.typeList.forEach((val, index) => {
          if (idx == index) {
            Vue.set(val, 'isActive', true);
            this.currentType = item.type;
            this.getData()
          } else {
            Vue.set(val, 'isActive', false)
          }
        })

    },

    getData(parse) {
      return new Promise((resolve, reject)=>{
          if (parse == 'searchBtn' && this.$refs.searchBtnRef && !(this.goodsKey.trim()) ) {
              this.$message.error('请输入关键字');
              return false
          }
          if (parse == 'searchBtn') {
            this.paging.params.pageNum = 1
            this.isShowSearchResult = true
          } else {
            this.isShowSearchResult = false
          }
          this.$api.coursegoods.findByCondition(
            {
                pageNum: this.paging.params.pageNum,
                pageSize:  this.paging.params.pageSize,
                categoryCodes: this.categoryCode,
                goodsIds:this.goodsIds && this.goodsIds.value || '',// 商品id，多个逗号隔开
                name: encodeURIComponent(this.goodsKey),
                type: this.currentType,
            }
          )
          .then(res=>{
              if (res.data && res.data.list) {
                this.courseListArray = res.data.list;
                this.paging.total = res.data.total; 
                this.paging.totalPages = res.data.pages
                this.paging.currentSize = res.data.size
                resolve(res)
              } else {
                  reject(res.message)
              }
          }).catch(err=>{
              reject(err)
              console.log('err', err)

          })
      })   

    },

    moreCourseGoodsPage() {
        this.$router.push({
          path: '/moreCourseGoods',
          query:{       
            categoryCode: this.categoryCode,
            name: this.goodsKey,
            type: this.currentType,
            pageSize: this.paging.params.pageSize,
            goodsIds:this.goodsIds && this.goodsIds.value || '',// 商品id，多个逗号隔开

          }
      })
    },

    

    configMenuInfor(){
       this.$api.configure.configuration(conf.confUrl).then(res => {
                if(res.data){
                  localStorage.setItem('configurationArr',JSON.stringify(res.data.frontendConfig));
                  const configurationArr = JSON.parse(localStorage.getItem('configurationArr'))
                  configurationArr.filter( (item) => {
                      switch(item.key) {
                        case  "is_show_commodity_search":  // 是否显示商品搜索
                            this.isShowSearchBox = item.value == 'true' ? true : false
                            break;
                        case  "is_show_commodity_sort": // 是否显示商品分类及排序
                            this.isShowClassifyBox = item.value == 'true' ? true : false
                            break;

                        case  "is_show_commodity_title": // 是否显示商品列表标题
                            this.isShowTitlechBox = item.value == 'true' ? true : false
                            break;
                        case  "is_show_commodity_more_link": // 是否显示更多商品
                            this.isShowMoreGoodsBox = item.value == 'true' ? true : false
                            break;

                        case  "is_show_commodity_number_buyer_list_page":  // 是否显示XXX人已报名
                            this.isShowNumberBuyerBox = item.value == 'true' ? true : false
                            break;
                        case  "is_one_or_many_page":  // 单页展示或多页展示
                            this.isShowOneOrManyPageBox = item.value == 'true' ? true : false
                            break;
  
                        case  "how_many_commodity_default": // 默认展示多少条
                            this.paging.params.pageSize = Number(item.value) > 0 ? Number(item.value) : this.paging.params.pageSize
                            break;

                        case  "how_many_commodity_per_page":  // 每页新加载多少条（多页展示情况下）
                            //this.paging.params.pageSize =  Number(item.value) > 0 ? Number(item.value) : this.paging.params.pageSize
                            break;
                        case  "commodity_source": // 内容来源：全部商品、商品列表中选择某些商品、某些商品分类
                            this.isShowContSourceBox = item.value == 'true' ? true : false
                            break;
                        default:
                            break;
                    } 
                  });                 
                }
                this.getClassifyFindAll();           
        })
    },

    jumpGoodsDetailHandle(item) {
        this.$router.push({
            path: '/courseGoodsDetail',
            query: {
                goodsId: item.id,
            }
        })   

    },

    changeInpt() {
      if (!this.goodsKey) {
        this.paging.params.pageNum = 1
        this.getData()
      }

    },


  },

};
</script>

<style lang="stylus" scoped>
 @import "../asset/css/courseGoods.styl"
</style>
