<template>
  <div>
    <div class="gongxinAic gongxinAic-nav">
      <div class="menuContents">
        <img class="imglogoG fl" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/aic/logo.png" alt="" />
        <div class="gongxin-nav-bar fl">
          <p class="item-nav fl" 
            v-for="item in navList" 
            :key="item.id"
            :class="{active:activeNav == item.path}"
            @click="clickNav(item)"
          >
            {{item.title}}
          </p>
        </div>
        <div v-if="!token" class="un-login fl" >
            <p @click="jumpLogin('aic_homepage','login')">登录</p>
        </div>
        <template v-else>
          <account :replacePage="'aic_homepage'"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import account from './account.vue'
export default {
  name: 'gongxinAic',
  components: {
    account
  },
  data () {
    return {
      activeNav: this.$route.path,
      examId: 0,
      trainId: 0,
      token: '',
      mode: '0', //  0:其他 1' 学习中心 '2': 模拟考试
      navList: [
        {
          path: '/aic_homepage',
          needLogin: false,
          title: '首页',
          activePaths: ['/aic_homepage'],
        },
        {
          path: '/courseGoods',
          needLogin: true,
          title: '直播专区',
          activePaths: ['/courseGoods'],
        },
        {
          path: '/examList',
          needLogin: true,
          title: '模拟考试',
          activePaths: ['/examList'],
        },
        {
          path: '',
          needLogin: false,
          title: '学习中心',
          activePaths: [],
        },
        {
          path: '/aic_about',
          needLogin: false,
          title: '关于我们',
          activePaths: ['/aic_about'],
        },
        
      ],
      goodsId:null,//商品id
    }
  },
  watch: {
    $route() {
      setTimeout(() => { //路由跳转
        sessionStorage.setItem('index',this.$route.meta.highlight)
        this.activeNav = this.$route.meta.highlight
        this.token = localStorage.getItem('token')
      }, 100)
    }
  },
  async mounted () {
    setTimeout(() => { //刷新
      this.activeNav = sessionStorage.getItem('index') || this.activeNav
      this.token = localStorage.getItem('token')
    }, 100)
  },
  methods: {
    clickNav(item) {
      if (this.$route.path === '/course/testPaper') {
        return;
      }
      let name = item.path;
      if (item.title === '模拟考试') {
        name = `${name}`
      } else if (item.title === '学习中心') {
        window.open('http://www.tech-skills.org.cn/')
      }
      if (item.needLogin && !localStorage.getItem('token')) {
        this.jumpLogin(name,'login');
      } else if (name) {
        this.$router.push(name)
      }
      
    },
    jumpLogin(name,type) {
        this.$router.push({
            name: type,
            query: {
                name
            }
        })
      
    },
  }
}
</script>
<style lang="stylus" scoped> 
.gongxinAic-nav{
    background: #fff !important;
    box-shadow: 0px 2px 24px 0px rgba(34, 34, 55, 0.06);
    font-family: PingFangSC-Regular, PingFang SC;
    height: 80px;
    position: fixed;
    width: 100%;
    z-index: 666;
    .menuContents {
      max-width: 1200px !important;
      margin: 0 auto;
    //   min-width: 1266px;
      background: #fff;
    }
    .imglogoG {
      width: 143px !important;
      margin-top: 12px !important;
    }
    .gongxin-nav-bar {
      height: 80px;
      margin-left: 105px;
      .item-nav {
        display: inline-block;
        font-size: 18px;
        line-height: 76px;
        margin: 0 35px;
        cursor: pointer;
        color:  #666;
      }
      .item-nav.active {
        color: #005FFF;
        border-bottom: 4px solid #005FFF;
        font-weight: 600;
      }
    }
    .move {
        /deep/.el-icon-arrow-down.el-icon--right {
            display: none;
        }
        /deep/.el-dropdown-linkapp .link-app {
          line-height 76px !important;
          margin-right: 0;
        }
        :focus{
          outline: none !important;
        }
    }
    .un-login {
        background: rgba(215, 215, 215, .29);
        
        width: 120px;
        height: 42px;
        text-align: center;
        line-height: 42px;
        border-radius: 21px;
        margin-top: 18px;
        
        cursor: pointer;
        margin-left: 40px;
        p {
            color: #747474;
            font-size:18px;
            display inline-block
        }
    }
    .move.el-dropdown {
        color:  #666;
        margin-left: 24px;
        span {
            font-size: 18px;
        }
    } 
}
.nav-gx-menu {
      text-align: center;
      border-radius: 6px;
      margin-top: 22px !important;
      .nav-gx-item {
          &:hover {
            background: #fff;
          }
          .gongxin-dl-wrap {
            padding: 0 74px;
            .img-wrap {
              width: 140px;
              height: 140px;
              border: 1px solid #F6F6F6;
              position: relative;
              margin: 46px auto 18px;
              .app-download {
                  width: 100%;
                  height: 100%;
              }
              .border {
                width: 14px;
                height: 14px;
                position: absolute;
                &.border1 {
                  left: 0;
                  top: 0;
                }
                &.border2 {
                  right: 0;
                  bottom: 0;
                  transform: rotate(180deg);
                }
              }
            }
            

            p {
                line-height: 22px;
                font-size: 16px;
                color: #666;
                margin-bottom: 40px;
            }
          }
          
      }
  }

</style>
