<template>
<div class="onlyCourseGoodsBg">
  <div class="jobIdxCont">
      <div class="goodsSearchCont"  ref="searchBtnRef">
        <div class="searchIptCont">
            <div class="currentCity" @click="clickChangeCityHandle()">{{currentCityInfor && currentCityInfor.name}}<span></span></div>
            <div class="searchIptBox fr">
              <input type="text" ref="searchInp" class="searchIpt" v-model="jobIptVal" v-on:keyup.enter="getData('searchBtn')" placeholder="搜索岗位、公司"/>
              <span class="searchBtn"  @click="getData('searchBtn')">搜索</span>
            </div>   
        </div>
      </div>

            
      <div class="classifyArea">
        <div class="courseClassifyItem">
            <div class="courseClassifyTitle">学历要求:</div>
            <div class="courseClassifyItemListCont">
                <ul class="courseClassifyItemList" >
                  <li v-for="(item, idx) in  educationData" :key="idx">
                    <span v-if="item.showTag" :class="{active: item.isActive }"  @click="searchResultHandle(idx, item,'educate')">{{item.name}}</span>
                  </li>
                </ul>
            </div>       
        </div>

        <div class="courseClassifyItem">
            <div class="courseClassifyTitle">工作经验:</div>
            <div class="courseClassifyItemListCont">
                <ul class="courseClassifyItemList" >
                  <li v-for="(item, idx) in  jobExpericeData" :key="idx">
                    <span v-if="item.showTag" :class="{active: item.isActive }"  @click="searchResultHandle(idx, item, 'experience')">{{item.name}}</span>
                  </li>
                </ul>

            </div>
        
        </div>


        <div class="courseClassifyItem">
            <div class="courseClassifyTitle">薪资范围:</div>
            <div class="courseClassifyItemListCont">
                <ul class="courseClassifyItemList">
                  <li v-for="(item, idx) in  salaryData" :key="idx">
                    <span v-if="item.showTag" :class="{active: item.isActive }"  @click="searchResultHandle(idx, item, 'salary')">{{item.name}}</span>
                  </li>
                </ul>

            </div>     
        </div>


        <div class="comprehensiveSortItem"  v-if="false">
            <div class="comprehensiveSortItemTitle">综合排序:</div>
            <div class="comprehensiveSortItemListCont">
                <ul class="comprehensiveSortItemList">
                  <li  v-for="(item, idx) in  typeList" :key="idx">
                    <span  :class="{active: item.isActive }" @click="clickTypeSortHandle(idx, item)">{{item.name}}</span>
                  </li>
                </ul>
            </div>    
        </div>    
      </div>

      
      <div class="courseGoodsListCont">
         <div class="configTile"><font>职位列表</font> <!--<span  class="moreGoods" @click="moreJobListHandle()" v-if="paging.totalPages > 1">更多</span>--></div> 
          <div class="courseGoodsList couseGoodsListStyle" v-if="positionListData.length">
            <commonPositionList  :sendPositionListData="positionListData"></commonPositionList>
          </div>
          <div class="noGoodsTips" v-if="isShowSearchResult && !positionListData.length">没有找到相关职位，修改筛选条件试一下哟～ </div>
         <div class="nothingTips" v-if="!isShowSearchResult && !positionListData.length">还没有相关职位哟～ </div>         
        <paging class="paging pagingCustom" :paging="paging" v-if="paging.totalPages > 1" />   
      </div>
      
  </div>

  <changeCity v-if="isShowChangeCityPop" @closeChangeCityPop="closeChangeCityPopCont($event)" :provinceAndCity="provinceAndCity"></changeCity>
</div>
</template>

<script>
import commonPositionList from "./commonPositionList";
import changeCity from "./changeCity.vue";
import paging from "../../../layout/paging";
// import conf  from '../../../config/url';
import Vue from 'vue'

export default {
  name: 'jobIdx',
  components: {
    paging,
    commonPositionList,
    changeCity,
    
  },
  data () {
    return {
      
          educationData:  [ //0(不限） 1高中 2大专 3本科 4 研究生 5博士
            {
                code: 0,        
                name: "不限",
                showTag: true,
                isActive: true,
            },


            {
                code: 1,        
                name: "高中",
                showTag: true,
                isActive: false,
            },
            {
                code: 2,        
                name: "大专",
                showTag: true,
                isActive: false,
            },
            {
                code: 3,        
                name: "本科",
                showTag: true,
                isActive: false,
            },         
            {
                code: 4,        
                name: "研究生",
                showTag: true,
                isActive: false,
            },

            {
                code: 5,        
                name: "博士",
                showTag: true,
                isActive: false,
            },

          ],


          jobExpericeData:  [ // 0.(不限) 1.(应届毕业生) 2.(3年以下) 3. (3-5年) 4.(5年以上
     
            {
                code: 0,        
                name: "不限",
                showTag: true,
                isActive: true,
            },

          
            {
                code: 1,        
                name: "应届毕业生",
                showTag: true,
                isActive: false,
            },

            {
                code: 2,        
                name: "3年以下",
                showTag: true,
                isActive: false,
            },

          
            {
                code: 3,        
                name: "3-5年",
                showTag: true,
                isActive: false,
            },

            {
                code: 4,        
                name: "5年以上",
                showTag: true,
                isActive: false,
            },   
          ],

          salaryData: [// 不限/2K以下/2-4K/4-6K/6-8K/8-10K/10-12K/12-14K/14-16K/16-18K/18-20K/20-30K/30-50K/50K以上
            {
                code: '',        
                name: "不限",
                showTag: true,
                isActive: true,
                salaryStartNum: null,
                salaryEndNum: null

            },

            {
                code: '',        
                name: "2K以下",
                showTag: true,
                isActive: false,
                salaryStartNum: null,
                salaryEndNum: 2
            },

            {
                code: '',        
                name: "2-4K",
                showTag: true,
                isActive: false,
                salaryStartNum: 2,
                salaryEndNum: 4
            },

            {
                code: '',        
                name: "4-6K",
                showTag: true,
                isActive: false,
                salaryStartNum: 4,
                salaryEndNum: 6
            },


            {
                code: '',        
                name: "6-8K",
                showTag: true,
                isActive: false,
                salaryStartNum: 6,
                salaryEndNum: 8
            },

            {
                code: '',        
                name: "8-10K",
                showTag: true,
                isActive: false,
                salaryStartNum: 8,
                salaryEndNum: 10
            },
          

            {
                code: '',        
                name: "10-12K",
                showTag: true,
                isActive: false,
                salaryStartNum: 10,
                salaryEndNum: 12
            },
            {
                code: '',        
                name: "12-14K",
                showTag: true,
                isActive: false,
                salaryStartNum: 12,
                salaryEndNum: 14
            },

            {
                code: '',        
                name: "14-16K",
                showTag: true,
                isActive: false,
                salaryStartNum: 14,
                salaryEndNum: 16
            },

            {
                code: '',        
                name: "16-18K",
                showTag: true,
                isActive: false,
                salaryStartNum: 16,
                salaryEndNum: 18
            },
            {
                code: '',        
                name: "18-20K",
                showTag: true,
                isActive: false,
                salaryStartNum: 18,
                salaryEndNum: 20
            },
            {
                code: '',        
                name: "20-30K",
                showTag: true,
                isActive: false,
                salaryStartNum: 20,
                salaryEndNum: 30
            },

            {
                code: '',        
                name: "30-50K",
                showTag: true,
                isActive: false,
                salaryStartNum: 30,
                salaryEndNum: 50
            },
            {
                code: '',        
                name: "50K以上",
                showTag: true,
                isActive: false,
                salaryStartNum: 50,
                salaryEndNum: null
            },        
               
          ],

          paging: {
              params: {
                  pageNum: 1,
                  pageSize: 5
              },
              total: 0,

              totalPages: 0,
              currentSize: 0,
          },
          jobIptVal: '',
          isShowChangeCityPop: false,
          currentEducationVal: 0,
          currentJobExpericeVal: 0,
          salaryEndNum: null,
          salaryStartNum: null,
          provinceAndCity: [],
          currentCityInfor: null,
          positionListData: [],
          isShowSearchResult: false,
    }
  },
  watch:{
    'jobIptVal'(newVal,oldVal){
        console.log(newVal, oldVal)
        if(!newVal){
          this.paging.params.pageNum = 1
          this.getData()
        }
    },
  },
  filters: {
  },

//   beforeRouteEnter(to, from, next) {
//     if (!localStorage.getItem('token')) {
//       this.$router.push({
//           path:"/login",
//           query:{
//               name: 'jobIdx'
//           }
//       })              
//     } else {
//         next(true);
//     }
// },


  created () {
  },
  computed: {

  },
  mounted() {    
      this.getLocatingCity()
      this.getRegionList()
  },

  beforeDestroy() {
      let parse = {
          education: this.currentEducationVal,
          experience: this.currentJobExpericeVal,
          salaryEndNum: this.salaryEndNum,
          salaryStartNum: this.salaryStartNum,
          keyWord: this.jobIptVal,
          pageNum: this.paging.params.pageNum,
          pageSize:this.paging.params.pageSize,
          workPlaceCity: this.currentCityInfor ? this.currentCityInfor.code : null

      }  
    localStorage.setItem('currentPositonInfor', JSON.stringify(parse))
  },


  methods: {
    clickChangeCityHandle() {
        this.isShowChangeCityPop = true   
    },

    closeChangeCityPopCont(parse) {
        this.isShowChangeCityPop = false
        if (parse && this.currentCityInfor) {
            this.currentCityInfor.name =  parse.cityName ? parse.cityName : parse.priviceName
            this.currentCityInfor.code =  parse.cityCode ? parse.cityCode : parse.provinceCode       
        }
        this.getData()
    },

    searchResultHandle(idx, item, type) {
      let currentSearchList = []    
        switch(type) {
              case  "educate":
                  currentSearchList = this.educationData
                  this.currentEducationVal = item.code

                  break;
              case  "experience": 
                  currentSearchList = this.jobExpericeData
                  this.currentJobExpericeVal = item.code
                  break;

              case  "salary": 
                  currentSearchList = this.salaryData
                  this.salaryEndNum = item.salaryEndNum
                  this.salaryStartNum = item.salaryStartNum          
                  break;

              default:
                  break;
       }

        currentSearchList.forEach((val, index) => {
            if (idx == index) {
              Vue.set(val, 'isActive', true);
            } else {
              Vue.set(val, 'isActive', false)
            }
        })
        this.isShowSearchResult = true
        this.getData()
    
    },


    getLocatingCity() {
      let parse = {}
      this.$api.job.locatingCity(parse)
      .then(res=>{       
        if (res.data) {
          this.currentCityInfor = res.data
          //this.currentCityInfor.code = "3401"
          console.log('this.provinceAndCity====', this.provinceAndCity)          
        } else {
            this.$message.error(res.message);
        }
        this.getData();
      })

    },

    getRegionList() {
      let parse = { }
      this.$api.job.regionList(parse)
      .then(res=>{
          if (res.data) {
            this.provinceAndCity = res.data
            console.log('this.provinceAndCity====', this.provinceAndCity)   
          } else {
             this.$message.error(res.message);
          }
          
          
      })
    },


    getFindByCondition() {
      let parse = {
          education: this.currentEducationVal,
          experience: this.currentJobExpericeVal,
          salaryEndNum: this.salaryEndNum,
          salaryStartNum: this.salaryStartNum,
          keyWord: this.jobIptVal,
          pageNum: this.paging.params.pageNum,
          pageSize:this.paging.params.pageSize,
          workPlaceCity: this.currentCityInfor ?  this.currentCityInfor.code : null

      }
      this.$api.job.findByCondition(parse)
      .then(res=>{
          if (res.data) {
            this.positionListData = res.data.list
            this.paging.totalPages =  res.data.pages
            this.paging.total = res.data.total; 
            console.log('positionListData===', this.positionListData)          
          } else {
             this.$message.error(res.message);
          }         
      })
    },


    getData(parse) {
        if (parse == 'searchBtn') {
          if (!(this.jobIptVal.trim()) ) {
              this.$message.error('请输入关键字');
              return false
          }
          this.isShowSearchResult = true            
        } else {
          this.isShowSearchResult = false
        }
        this.getFindByCondition()
    },
    

    moreJobListHandle() {
       this.$router.push({
          path: '/morePositionList',
          query:{       
            education: this.currentEducationVal,
            experience: this.currentJobExpericeVal,
            salaryEndNum: this.salaryEndNum,
            salaryStartNum: this.salaryStartNum,
            keyWord: this.jobIptVal,
            pageNum: this.paging.params.pageNum,
            pageSize:this.paging.params.pageSize,
            workPlaceCity: this.currentCityInfor ?  this.currentCityInfor.code : null

          }
      })

    },

  },

};
</script>

<style lang="stylus" scoped>
 @import "../asset/css/job.styl"
</style>
