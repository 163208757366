<template>
 <div class='mzhomeBanner'>
    <el-carousel trigger="click" arrow="always">
      <el-carousel-item v-for="(item, index) in imgList" :key="index" arrow="never" >
        <img :src="item.pictureUrl" @click="goDetails(item)" alt="">
      </el-carousel-item>
      <!-- <img :src="imgHeight" class="toolImg" alt=""> -->
    </el-carousel>
    <div class="wave">
      
    </div>
 </div>
</template>

<script>
export default {
    name:'homeBanner',
    props: {
    },
    data () {
        return {
          imgList: [],
          // imgHeight: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/banner@2x.png'
        };
    },

    methods: {
        goDetails(item){
          if(item.link){
            console.log(item.link)
            window.open(item.link,'_blank')
          }
        },
        getBanner(){
          this.$api.home.getMzHomeBanner(1).then(res=>{
              this.imgList=res.data
          })
        }
    },

    mounted(){
        this.getBanner()
    },
}

</script>
<style lang='stylus' scoped>
    @import "../../asset/css/homeBanner.styl"
</style>
<style lang='stylus'>
    // 兼容谷歌
.mzhomeBanner {
  /deep/.el-carousel__indicators .el-carousel__indicators--horizontal {
    bottom: 80px!important;
  }
  .wave{
    width 100%;
    height :302px;
    background :url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/Wave@2x.png') center center no-repeat;
    background-size :100% 100%;
    position: absolute;
    z-index: 2;
    top: 560px;
  }
  .el-carousel__arrow{
    border :2px solid #ffffff;
    opacity: 0.35;
    background-color:transparent;
    width: 40px;
    height: 40px;
    top :220px;
    i{
      font-size :22px;
    }
  }
  .el-carousel__arrow--left{
    left :96px;
  }
  .el-carousel__arrow--right{
    right :96px;
  }
  .el-carousel__container{
    height: 740px!important;
    img {
      width: 100%!important;
      vertical-align middle
    }
  }
  /deep/.el-carousel__indicators--horizontal {
    bottom: 23px;
    // display: none;
    .el-carousel__indicator--horizontal {
      padding: 12px 10px;
      .el-carousel__button {
        width: 9px;
        height: 9px;
        border-radius: 50%;
      }
    }
  }
  // 兼容火狐
  @-moz-document url-prefix() {
    .el-carousel__container{
      height: 740px!important;
      img {
        width: 100%!important;
      }
    }
    .el-carousel__indicators--horizontal {
      bottom: 23px;
      // display: none;
      .el-carousel__indicator--horizontal {
        padding: 12px 10px;
        .el-carousel__button {
          width: 9px;
          height: 9px;
          border-radius: 50%;
        }
      }
    }
  }
  // 兼容IE
  .el-carousel__container{
    height: 740px!important;
    img {
      width: 100%!important;
      cursor: pointer;
    }
  }
  .el-carousel__indicators--horizontal {
    bottom: 23px;
    // display: none;
    .el-carousel__indicator--horizontal {
      padding: 12px 10px;
      .el-carousel__button {
        width: 9px;
        height: 9px;
        border-radius: 50%;
      }
    }
  }
}
@media screen and (min-width:1601px) and (max-width:1920px){
  .mzhomeBanner{
     .wave{
        top: 700px!important;
     }
     .el-carousel__container{
        height: 860px!important;
     }
     .el-carousel__arrow{
       top:340px!important;
    }
  }
}
@media screen and (min-width:1921px) and (max-width:3000px){
  .mzhomeBanner{
     .wave{
        top: 900px!important;
     }
     .el-carousel__container{
        height: 1000px!important;
     }
     .el-carousel__arrow{
       top:440px!important;
    }
  }
}
</style>