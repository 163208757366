<template>
    <div class='evaluationNot'>
        <div class='evaluationNot-top'>
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/junmin/evaluationTop.png" width="100%" height="304px" alt="">
        </div>
        <div class='evaluationNot-main clearfix'>
            <div class="evaluationNot-main-left fl">
                <h4>你是否会有这样的困惑？</h4>
                <div class="evaluationNot-main-left-content">
                    <p>“我性格内向/外向，适合什么工作？”</p>
                    <p>“哪些职业正好匹配我的性格？”</p>
                    <p>“以我的个性从事什么行业好？”</p>
                    <p>“我性格中的优势和劣势是什么？”</p>
                    <p>“我是不是该继续现在从事的职业？”</p>
                </div>
                <div class="evaluationNot-main-left-bottom">
                    不论是正待走进职场的毕业生，还是工作了一段时间的人，面对这类问题都会感到困惑——性格因素和职业选择之间到底存在什么样的关联呢？
                </div>
            </div>
            <div class="evaluationNot-main-right fl">
                <h4>我们的服务</h4>
                <div class="evaluationNot-main-right-content">
                    <p>职业测评是以经典心理学测评为基础，以通用能力、职业行为特征及人格特征等角度为出发点，对军民人员进行专业、系统评估的测评。</p>
                    <p>所有测评均依照严格、规范的程序进行开发、修订，并经大量样本测验，具有良好的信度和效度。</p>
                    <p>测评结果将对您提供针对性的综合分析，并在此基础上给予科学指导建议，希望以此解决您的困惑。</p>
                </div>
            </div>
        </div>
        <div class='evaluationNot-bottom'>
            <el-button type="primary" @click='goevaluationAlready'>进入测评</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name:'evaluationNot',
    data () {
        return {
        };
    },

    methods: {
        goevaluationAlready(){
            this.$router.push('/evaluationAlready');
        },
    },

    mounted(){},
}

</script>
<style lang='stylus' scoped>
@import "../asset/css/evaluationNot.styl"
</style>