<template>
    <el-dialog
        title="入驻申请"
        :visible.sync="dialogShow.dialogVisible"
        width="680px"
        :close-on-click-modal="false"
        :before-close="handleClose">
        <el-form :label-position="'right'" ref="formData" :rules="rules" label-width="150px" :model="formData">
            <el-form-item label="基地名称:" prop="name">
                <el-input v-model="formData.name" placeholder="请输入基地名称" :maxlength="20"></el-input>
            </el-form-item>
            <el-form-item>
                <p style="position: absolute; left: -80px; top: 12px;"><span style="color:#F56C6C">*</span>基地地址:</p>
                <el-select v-model="address.provinceId" @change="provinceChange" placeholder="请选择省" style="width:136px;margin:0 10px 11px 0;">
                    <el-option v-for="item in regionList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
                <el-select v-model="address.cityId" :disabled="!address.provinceId"  @change="cityChange" style="width:136px;margin:0 11px 10px 0;" placeholder="请选择市">
                    <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
                <el-select v-model="address.areaId" :disabled="!address.cityId" @change="regionCodeChange"  style="width:136px;margin:0 0px 10px;" placeholder="请选择区">
                    <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
                <el-input v-model="formData.address" placeholder="请输入详细街道地址" :maxlength="50"></el-input>
                <p v-if="isaddress" style="position: absolute; left: 0; bottom: -16px; font-size: 12px; color: #F56C6C;">{{addressname}}</p>
            </el-form-item>
            <el-form-item label="联系电话:" prop="phone">
                <el-input v-model="formData.phone" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <el-form-item label="培训场所教室数量:" prop="classroomNumber">
                <el-input v-model="formData.classroomNumber" placeholder="请输入培训场所教室数量"></el-input>
            </el-form-item>
            <el-form-item label="培训场所总面积:" prop="area">
                <el-input v-model="formData.area" placeholder="请输入培训场所总面积"></el-input>
            </el-form-item>
            <el-form-item label="培训场所照片:" prop="photoUrl">
                <el-upload
                    class="avatar-uploader"
                    :action="action"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload">
                    <img v-if="formData.photoUrl" :src="formData.photoUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('formData')">提交申请</el-button>
            </el-form-item>
        </el-form>
        
        <!-- <span slot="footer" class="dialog-footer"> -->
            <!-- <el-button @click="dialogShow.dialogVisible = false">取 消</el-button> -->
            
        <!-- </span> -->
    </el-dialog>
</template>
<script>
import config from '@/config/url'
export default {
    name:'formDialog',
    props:{
        dialogShow:{
            type:Object,
            default:() => ({
                dialogVisible:false
            })
        }
    },
    data(){
        // let that=this;
        const accountValidator = (rule, value, callback) => {
            const isPhone = /^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/
            if ( !isPhone.test(value) ) {
                callback('请输入正确的联系电话')
            } else {
                callback()
            }
        }
        // const addressValidator=(rule, value, callback) => {
        //     if(that.formData.address=='' || !that.formData.regionCode){
        //         callback('请输入基地地址')
        //     }else{
        //         callback()
        //     }
        // }
        const classroomNumberValidator= (rule, value, callback) => {
            const reg = /^[1-9]\d*$/
            if ( !reg.test(value) ) {
                callback('请输入培训场所教室数量')
            } else {
                callback()
            }
        }
        const areaValidator= (rule, value, callback) => {
            const reg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/
            if ( !reg.test(value) ) {
                callback('请输入培训场所总面积,支持小数点两位')
            } else {
                callback()
            }
        }
        return{
            isaddress:false,
            addressname:'',
            action:config.API + '/gx-homemaking/v1/common/uploadImage',
            address:{
                provinceId:null,
                cityId:null,
                areaId:null,
            },
            regionList:[],
            cityList:[],
            areaList:[],
            formData:{
                name:'',
                area:'',
                address:'',
                phone:'',
                classroomNumber:'',
                regionCode:null,
                photoUrl:'',
            },
            rules: {
                name: [
                    { required: true, message: '请输入基地名称', trigger: 'blur' },
                ],
                // address: [
                //     {
                //         required: true,
                //         validator: addressValidator,
                //     }
                // ],
                phone: [
                    {
                        required: true,
                        validator: accountValidator,
                        trigger: "blur"
                    }
                ],
                classroomNumber: [
                    {
                        required: true,
                        validator: classroomNumberValidator,
                        trigger: "blur"
                    }
                    // { required: true, message: '请输入培训场所教室数量', trigger: 'blur' },
                ],
                area: [
                    {
                        required: true,
                        validator: areaValidator,
                        trigger: "blur"
                    }
                    // { required: true, message: '请输入培训场所总面积', trigger: 'blur' },
                ],
                photoUrl: [
                    {
                        required: true,
                        message: "请上传培训场所照片",
                        trigger: "change"
                    }
                ],
            },
        }
    },
    mounted(){
        
        
    },
    methods:{
        isShow(){
            this.dialogShow.dialogVisible = true;
            this.$nextTick(()=>{
                this.$refs.formData.clearValidate();
            })
            this.getRegionList();
        },
        provinceChange(id){
            this.cityList=[];
            this.areaList=[];
            this.address.cityId=null;
            this.address.areaId=null;
            this.formData.regionCode=null;
            this.formData.address="";
            this.regionList.forEach(val=>{
                if(val.id===id){
                    this.cityList=val.children;
                }
            })
        },
        cityChange(id){
            this.areaList=[];
            this.address.cityId=id;
            this.address.areaId=null;
            this.formData.regionCode=null;
            this.formData.address="";
            this.cityList.forEach(val=>{
                if(val.id===id){
                    this.areaList=val.children;
                }
            })
        },
        regionCodeChange(id){
            this.areaList.forEach(val=>{
                if(val.id==id){
                    this.formData.regionCode=val.code;
                }
            })
            this.formData.address="";
        },
        getRegionList(){
            let params={
                type:1
            }
            this.$api.sdjz.getRegionList({params}).then((res) => {
                if(res.success==true){
                    this.regionList=res.data;
                }
            })
        },
        submitForm(formName) {
            
            if(this.formData.address=='' && this.formData.regionCode){
                this.addressname="请输入详细街道地址";
                this.isaddress=true;
                return;
            }else if(this.formData.address!='' && !this.formData.regionCode){
                this.addressname="请选择省市区";
                this.isaddress=true;
                return;
            }else if(this.formData.address=='' || !this.formData.regionCode){
                this.addressname="请输入基础地址";
                this.isaddress=true;
                return;
            }else{
                this.isaddress=false;
            }

            
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.trainingBasesave();
                    console.log(this.formData,'111')
                }
            });
        },
        trainingBasesave(){
            let data={
                ...this.formData,
            }
             this.$api.sdjz.trainingBasesave({data}).then((res) => {
                if(res.success==true){
                    this.$alert('添加成功，稍后会有管理员联系您，请耐心等待', '已提交', {
                        confirmButtonText: '确定',
                        center:true,
                        customClass:'messageBox',
                        confirmButtonClass:'confirmBtn',
                        callback: () => {
                            this.formData.photoUrl='';
                            this.address={
                                provinceId:null,
                                cityId:null,
                                areaId:null,
                            };
                            this.formData={
                                name:'',
                                area:'',
                                address:'',
                                phone:'',
                                classroomNumber:'',
                                regionCode:null,
                                photoUrl:'',
                            };
                        }
                    });
                    this.$refs.formData.clearValidate();
                    this.addressname="";
                    this.isaddress=false;
                    this.dialogShow.dialogVisible = false;
                }
                
                
            })
        },
        handleClose(){
            this.formData.photoUrl='';
            this.address={
                provinceId:null,
                cityId:null,
                areaId:null,
            };
            this.formData={
                name:'',
                area:'',
                address:'',
                phone:'',
                classroomNumber:'',
                regionCode:null,
                photoUrl:'',
            };
            this.addressname="";
            this.isaddress=false;
            this.$refs.formData.clearValidate();
            this.dialogShow.dialogVisible = false;
        },
        handleAvatarSuccess(res) {
            this.formData.photoUrl = res.data;
        },
        beforeAvatarUpload(file) {
            const imgType = "image/jpeg,image/jpg,image/gif,image/png";
            const isJPG = imgType.indexOf(file.type) != -1;
            console.log('isJPG',isJPG)
            // const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isJPG) {
            this.$message.error('培训场所照片格式不正确!');
            }
            if (!isLt2M) {
            this.$message.error('培训场所照片大小不能超过 5MB!');
            }
            return isJPG && isLt2M;
        }
    }
}
</script>
<style lang="stylus">
.messageBox{
    width auto    
    .el-message-box__title span{
        font-size 18px
    }
}
.confirmBtn{
    width 120px    
}
</style>
<style lang="stylus" scoped>
/deep/.el-dialog__footer{
    text-align center
}
/deep/.el-dialog__header{
    text-align center
}
/deep/.el-dialog__body{
    padding-right 80px
}
/deep/.el-form-item{
    margin-bottom 24px
}
/deep/.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  /deep/.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  /deep/.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  /deep/.avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  /deep/.el-form-item__error
    position absolute;
</style>