<template>
  <div class="junminPersonalInfo">
    <div class="header">
        <p>填写信息（标记<span style="color:#F56C6C">*</span>项必须填写）</p>
        <p>
            <span>友情提示：1.此信息仅用于建立学籍档案和就业创业服务，我们将妥善保管 </span>
            <span>2.请真实填写，并与报到时提报内容一致 </span>
            <span>3.内容如有变化，可在个人资料中修改 </span>
        </p>
    </div>
    <junminPersonalInfoForm></junminPersonalInfoForm>
  </div>
</template>

<script>
export default {
    name:'junminPersonalInfo',
    data(){
        return{
            
        }
    },
    mounted(){
        
    },
    methods:{

    }
}
</script>

<style lang="stylus" scoped>
.junminPersonalInfo
    padding-top 24px
    padding-bottom 48px
    background #fff
.header
    width 1200px
    height 84px
    margin 0 auto
    margin-bottom 24px
    padding 16px
    box-sizing border-box
    background #FFF7F5
    >p
        font-size 15px
        color #909399
        line-height 26px
</style>