const newsList = [
    {
        id:'1',
        title:'新华网：国企改革为何要读懂这张“施工图”',
        date:'2021-01-07',
        content:`<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">
关于中央企业发展，2020年12月30日召开的第十七次中央全面深化改革委员会（以下简称：中央深改委）会议再一次提出了新要求。
</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">央企的经营发展，一直是党中央高度关注的话题。从党的十八大到十九大，中央经济工作会议、政府工作报告以及五年规划这样的长远发展规划，都会提及到国企发展的话题。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">此次会议强调央企党委（党组）把方向、管大局、促落实的领导作用，要完善体制机制改革。其目的在于提升党对国有企业的领导力，提升企业治理效能，发展壮大国有企业。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">当然，这也与今年6月份中央深改委会议审议通过的《国企改革三年行动方案（2020－2022年）》是一脉相成的关系。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">三年行动具体有哪些看点，事关国有企业改革的“施工图”到底长什么样？通过梳理相关会议、政策文件及相关负责人接受采访时透露出的信息，我们可以大致了解其轮廓。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">一个“行动纲领”</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">6月30日，中央全面深化改革委员会第十四次会议对三年行动方案提出了明确部署。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">会议指出：“今后3年是国企改革关键阶段，要坚持和加强党对国有企业的全面领导，坚持和完善基本经济制度，坚持社会主义市场经济改革方向，抓重点、补短板、强弱项，推进国有经济布局优化和结构调整，增强国有经济竞争力、创新力、控制力、影响力、抗风险能力。”</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">《三年行动方案》被称作未来三年指导和推进新阶段国企改革的纲领性文件，具有可衡量、可考核、可检验、要办事的特点。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">五个作用</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">推动国有企业改革取得积极进展，对做强做优做大国有经济，增强国有企业活力、提高效率，加快构建新发展格局，都具有重要意义。而国资央企改革进入新的关键阶段，使其在多方面发挥关键作用，正是推动国企改革三年行动的题中之义。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">9月27日，国务院国有企业改革领导小组第四次会议及全国国有企业改革三年行动动员部署电视电话会议在北京召开。会议透露出五方面讯息，先来看看具体怎么说：</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">一是国有企业要成为有核心竞争力的市场主体。国有企业首先必须发挥经济功能，创造市场价值，更好为党和人民服务。要加强党的领导，落实董事会职权，健全市场化经营机制，积极稳妥深化混合所有制改革。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">二是要在创新引领方面发挥更大作用。创新决定命运，硬实力畅通大循环。要以创新为突破口，进行大胆充分的激励，在关键核心技术攻关、高端人才引进、科研成果转化应用等方面有更大作为。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">三是要在提升产业链供应链水平上发挥引领作用。国有企业要对民营企业健康发展发挥带动作用和重要影响力。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">四是要在保障社会民生和应对重大挑战等方面发挥特殊保障作用。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">五是要在维护国家经济安全方面发挥基础性作用。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">整体上看，这些内容涵盖了国有企业发展的多方面作用。这里提到的第一方面内容第一条，与中央深改委昨日审议通过的《关于中央企业党的领导融入公司治理的若干意见（试行）》直接相关，也再一次体现出党中央对国企发展的高度重视以及政策出台的连续性。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">七个落实要点</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">“行动纲领”有了，该做啥，如何做就非常重要。政策效果好不好，关键看落实。国资委将如何落实《三年行动方案》提出的改革目标和重点改革任务呢？</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">过去这半年来，国资委多次开会部署具体工作。其中，9月29日，国资委对中央企业改革三年行动工作进行动员部署会，一系列具有针对性、操作性的部署安排由此发出。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">国资委党委书记、主任郝鹏强调，中央企业要准确理解把握其重要意义和核心要义，坚持问题导向、目标导向、结果导向，突出抓好改革重点任务，以更坚强的决心、更坚定的意志、更大的力度推动落地见效，在实施国企改革三年行动中作表率。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">会议提到具体要抓好七个方面的工作，包括：
要突出抓好中国特色现代企业制度建设；
要突出抓好国有经济布局优化和结构调整；
要突出抓好深化混合所有制改革；
要突出抓好健全市场化经营机制；
要加快形成以管资本为主的国有资产监管体制；
要突出抓好“双百行动”“区域性综改试验”“科改示范行动”、世界一流企业创建等专项工程；
要突出抓好党的领导和党的建设。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">会议要求各中央企业党委（党组）要把实施国企改革三年行动作为重大政治责任，加强组织领导，加大工作力度，健全工作机制，企业主要负责同志要作为第一责任人，亲自抓、带头干，做到重点任务亲自部署、重大方案亲自把关、关键环节亲自协调、落实情况亲自督查；</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">集团公司和子公司要层层立下“军令状”，逐级压实责任、传导压力，做到一级抓一级、层层抓落实，确保上下贯通、“一竿子扎到底”；
要结合实际抓紧制定完善本企业的具体实施方案，确保可衡量、可考核、可检验；
要切实加强督查考核，将重点改革任务完成情况作为硬指标，倒排工期，动态跟踪，对明确要求今年或2021年、2022年年内必须完成的重点任务，要全力攻关，确保如期完成。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">此外，国资委还下发《关于加大工作力度努力完成2020年国企改革三年行动重点任务的通知》。不难发现，国资委在落实方面对于覆盖面要求更加具体，就是要压实职责，严格按照时间表路线图稳步推进。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">多项改革效果</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">日前，国务院国资委副主任翁杰明接受新华社采访时表示，今年的改革任务基本完成。
截至2020年11月底，中央企业今年改革任务完成率超过70%，各地今年改革任务完成率超过63%，年底前能够基本完成。
数据显示，2013年以来，截至今年10月12日，中央企业累计实施混改4000多项，引入各类社会资本超过1.5万亿元。中央企业所属子企业中混合所有制企业户数占比已经超过70%。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">中央企业有621户子企业选聘职业经理人近5000人，119户控股上市公司实施了股权激励。开展总部机关化专项整治和对标世界一流管理提升行动，集团总部部门数量平均压缩超过17%，人员编制平均减少20%。
目前，22组41家中央企业完成战略性重组，2041户“僵尸”特困企业处置治理任务基本完成。
数据显示，1-11月，全国国有及国有控股企业（以下称国有企业）营业总收入同比增速进一步提高，利润总额降幅持续收窄，已恢复到去年同期的93.9%，经济运行稳步改善。从营业总收入来看，11月份，国有企业营业总收入较去年同期增长6.0%。
可以说，央企体制机制不断完善，动力活力有效激发，并带动各项改革持续深化，企业发展质量效益显著提升。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">下一步要做什么？</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">党的十九届五中全会和中央经济工作会议均对深化国有企业改革作出新的重大部署。尤其体现在抓重点、补短板、强弱项等领域。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em">即将迎来“十四五”开局之年，我们将看到国有企业获得怎样的发展？通过翁杰明的描述，我们可以有所了解，新时期国有企业要充分发挥国有经济的战略性作用，推进国有经济布局优化和结构调整，国有资本资源配置和运行效率明显提高，国有经济在关系国家经济、科技、国防、安全等领域的主导权巩固增强，在提升我国产业基础能力和产业链水平上的中坚作用有效发挥，国有经济整体功能作用显著增强。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em"></p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em"></p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em"></p>`
    },
    {
        id: '2',
        title: '培训业务——中国行业领先的培训知名品牌',
        date: '2021-01-07',
        content: `<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">中智大学堂伟东云——专注场景 智慧教育</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">中智大学堂伟东云作为人才培养与发展的领路者，运用金字塔原理打造人才培养与发展核心价值，从个人提升、品牌认证课程、企业精实、标杆学习四个层次层层递进、持续循环，汇集了企业内外的优质课程，涉及内容、领域广泛，搭建强有力的个人与企业的学习平台，将企业整体战略目标和实务需求完美结合，为企业培训与员工发展提供专业、系统的解决方案，帮助提升员工岗位胜任力，加强员工的保留与激励，改善企业的整体绩效。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">中智大学堂伟东云始终如一地不断研发适合中国市场的培训课程，以专业的服务，高品质、高性价比的培训课程，自主版权的课程体系，优秀专业的培训讲师，赢得了广大企业的良好口碑，连续多年被客户赞为值得信赖的优秀企业培训课程供应商，致力于为企业提供更实用、更贴近需求的企业培训解决方案，解决在中国独特市场环境下存在的实际问题，帮助企业提高职业化管理平台，提升企业竞争力，赢得客户长期的信任和良好的口碑。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">为企业提供全方位人才战略实施的解决方案提供商。扎根于企业与个人，采取有特色的B-B-C的经营模式，为客户提供高标准课程培训服务。助力企业实现人才升级、组织发展与智慧传承，通过沉浸式020智慧教育解决方案，专注于学习场景的知识传导，碎片化学习时间的充分应用，体系式企业级学习服务的规划与运营，通过先进的移动互联网及云端服务技术，构建以课程云系统、企业培训学习系统、平台运营系统和精品课程系统四大模块为核心的在线教育生态圈，构建美好职涯精神家园！</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">核心理念</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">为用户提供卓越的企业培训解决方案，包括培训体系的规划、培训管理的搭建、培训内容的建设、培训场景的创建、培训服务的全方位提供，让客户体验以用户为中心的卓越的培训体验。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">发挥资源优势与多年的行业经验，在党建、领导力、人力资源、海关关务、安全管理、传媒艺术、建筑行业、房地产行业、汽车行业等领域开发出了系列爆款产品，打造全场景覆盖，混合型学习和管理，行业级培训解决方案。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">服务范围</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">1.海外标杆培训</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">对标世界优秀企业开展标杆学习，包括与中国台湾、德国、韩国等地进行管理交流。形成了“主题培训、小组研讨、标杆企业参访、文化体验”等多样化的学习形式。使参与者在体验中学习、在学习中体验，从改变个人的想法开始，促进组织变革</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">运用企业深度诊断与辅导手段，包括全面生产管理（TQM）、全面质量管理（TPM）、精益生产，协同研发辅导推动产业中下游价值链提升，形成产业集群竞争力。降低成本，扩大产能，提高质量，加快资金周转，延长设备寿命，稳定队伍、调动员工积极性。辅导与咨询由现场指导、课堂培训、辅导改善组成，培训融入辅导，助力精益提升</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">3.企业内训与公开课</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">作为企业大学的培训合作伙伴，企业内训服务以胜任能力理论和专业系统为基础，与企业整体战略目标相结合，并始终以客户需求为服务导向，以定制化与标准化，提供多元化企业内训培训服务，为企业搭建全面的培训发展体系，包括管理者能力提升培训体系和员工多谐成长培训体系。精品公开课搭建了强有力的个人与企业的学习平台，致力于管理提升、销售实战、人力资源等精品培训。着眼于细化和丰富课程及服务内容，成为众多企业的培训供应商与合作伙伴</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">4.培训质量体系—TQS</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">培训机构质量管理体系将教育训练之计划、设计、执行、检核、成果评估等阶段，拟订培训质量规范，确保培训流程之可靠性与正确性。对企业大学或继续教育学院，能确实做好教育培训的计划、设计、执行、检核、成果评估(即 PDDRO)等阶段，以确保培训质量，透过本课程能培养具备年度教育培训课程的规划能力，同时能对应企业大学或继续教育学院实施之现况，并探讨培训体系建立与职能分析管理</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">5.国际认证课程</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">中智坚持线上与线下相结合，以移动学习、标准化培训为导向，利用国际与国内培训资源区位优势差异，引进国际权威认证课程，搭建运营平台，实现规模化经营。Facet5国际测评师认证课程：是在全球40多个国家广泛使用的权威测评工具，源于“大五”人格理论，通过意志力、控制、爱心、精力和情绪性五个方面13个子因素对个性进行分析和描述，从不同的视角来观察个人特质的不同方面。本认证课程旨在帮助学员以专业的角度认知Facet5报告，最大化地发挥出各个应用模块的强大威力。经认证的学员，将被授予Facet5资质认证证书，并成为Facet5授权培训师</p>
`
    },
    {
        id: '3',
        title: '经济日报：展望2021 国资国企改革发展怎么干',
        date: '2021-01-07',
        content: `<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">开启“十四五”，踏上新征程。2021年是我国现代化建设进程中具有特殊重要性的一年，国资国企改革发展重任在肩。推进高质量发展，国资央企有哪些新举措？紧抓改革时间窗口，国企改革三年行动如何落到实处？实现科技自立自强，中央企业怎样啃下“硬骨头”？</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">近日在北京召开的中央企业负责人会议，研究“十四五”形势任务，部署2021年重点工作。会议释放的信号表明，国资央企深入贯彻新发展理念，把自身工作放在进入新阶段、构建新格局中谋划推动，将更好服务党和国家事业发展大局。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">打造提质增效“升级版”</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">企业强则国家强，企业兴则国家兴。党的十九届五中全会强调，做强做优做大国有资本和国有企业。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">数据显示，2019年全国国资系统监管企业资产总额达201.3万亿元，较2012年增长1.5倍；2020年进入世界500强企业达到80家。但从国际对标的角度看，与国际一流企业相比，中国大企业在营利能力、核心竞争力等方面仍存在差距。对于国资央企而言，当前做强做优更为紧迫，这也是进一步做大的重要基础和前提。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">“‘十四五’时期，国资央企高质量发展要迈上新台阶。”国务院国资委党委书记、主任郝鹏指出，为更好引导推动实现高质量发展，坚持问题导向和目标导向，2021年中央企业主要经营目标在去年框架基础上新增“全员劳动生产率”指标，形成“两利四率”指标体系。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">据悉，“两利”指标为利润总额力争与净利润同步增长，其提出的主要考虑是中央企业效益增长要与国家宏观经济增速相匹配，同时为企业夯实资产质量、补齐发展短板、更好蓄能增势留出空间。“四率”指标为营业收入利润率、资产负债率、研发投入强度、全员劳动生产率，其提出的主要考虑是持续推动经营效率和发展质量的边际改善，夯实防风险基础，促进持续健康发展。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">东北财经大学国民经济工程实验室研究员冯立果认为，追求效益、提高效率、创造价值，是对企业作为市场主体的基本要求。2021年世界经济形势仍然复杂严峻，复苏不稳定不平衡，风险隐患不少。在此情况下，国企更要锚定高质量发展，全力做好自己的事，打造提质增效“升级版”势在必行。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">全力抓紧抓实成为主旋律</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">当前，国企改革进入关键阶段。作为2020年至2022年进一步落实国企改革“1+N”政策体系和顶层设计的具体施工图，国企改革三年行动重在见行动、提实效，各项工作正扎实有序推进。继2020年实现良好开局后，国企改革三年行动进入了“要办事”的关键期，全力抓紧抓实成为2021年度主旋律。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">“2021年是国企改革三年行动的攻坚之年、关键之年,主要任务能否落地、是否见效直接决定着2022年能否全面实现预期目标。”郝鹏强调，新的一年实施国企改革三年行动，要更加聚焦重点任务，更加注重激发活力，更加突出基层创新。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">据了解，2021年，国企改革三年行动的重点任务包括，聚焦完善中国特色现代企业制度、深化混合所有制改革、健全市场化经营机制等制度性基础性改革发力攻坚，聚焦厘清治理主体职责边界、实现董事会应建尽建配齐建强、混合所有制企业转换经营机制、商业类企业的公益类业务分类核算和分类考核等重点难点问题力求突破，聚焦做好退休人员社会化管理和厂办大集体改革等收尾性工作确保圆满完成。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">为确保国企改革三年行动落地见效，2021年国资委将通过在线督办系统加强督导推动，并将三年行动的落实情况纳入业绩考核。中央企业将逐条对照任务要求，抓紧抓实，确保在重要领域、关键环节取得实质性突破和进展。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">集中力量加快科技攻关</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">科技自立自强是国家发展的战略支撑。2020年中央经济工作会议强调，要发挥企业在科技创新中的主体作用。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">回顾2020年，天问一号逐梦而行、嫦娥五号奔月取壤、奋斗者号万米探海、北斗三号建成开通等一系列“央企创造”举世瞩目，进一步彰显了中国力量。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">近年来，中央企业在加大科技研发投入、提高科技成果质量、重点攻关大国重器等方面取得显著成效。2018年，中央企业研究与试验发展经费支出同比增长13.4%。2019年，中央企业研发经费投入达到8190亿元，同比增长17.5%。2020年，国资央企顶住压力，推动资金、人才、政策向重点企业、重点项目倾斜。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">构建新发展格局，实现科技自立自强，须发挥社会主义市场经济条件下的新型举国体制优势。国有企业要当好技术创新的主力军、排头兵，把解决“卡脖子”技术、关键核心技术攻关工作放在更加重要的位置。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">“中央企业作为国家战略科技力量，要强化创新主体意识，优化研发支出结构，推动科技创新不断取得突破性、标志性重大成果。”郝鹏强调。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">按照部署，2021年，中央企业要着力抓好关键核心技术攻关，着力提升技术创新能力。围绕国家战略和高质量发展需求，分层次、分领域部署一批产业关键技术、前沿引领技术和应用基础技术，针对产业薄弱环节加大攻关力度。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">推进科技创新，有赖于人才给力、机制发力。2021年，中央企业将引进更多国际一流人才，着力培养科技领军人才和高水平创新团队。落实好出台的科技创新各项支持政策，赋予科研人员更大自主权。</p>
`},
    {
        id: '4',
        title: '人力资源社会保障部进一步加强高技能人才与专业技术人才职业发展贯通',
        date: '2021-01-07',
        content: `<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">近日，人力资源社会保障部印发《关于进一步加强高技能人才与专业技术人才职业发展贯通的实施意见》（以下简称《意见》），进一步打通高技能人才与专业技术人才职业发展通道，加强创新型、应用型、技能型人才培养。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">《意见》指出，要破除束缚人才发展的体制机制障碍，大力弘扬劳模精神、劳动精神、工匠精神，探索建立理论与实践相结合、技术与技能相促进的人才评价使用激励机制，激发高技能人才创新活力。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">《意见》在工程技术领域基础上，进一步扩大两类人才贯通领域。将贯通领域扩大为工程、农业、工艺美术、文物博物、实验技术、艺术、体育、技工院校教师等职称系列。支持高技能人才取得经济、会计、统计、审计、翻译、出版、通信、计算机技术与软件等专业技术人员职业资格。在技术技能融合程度较高的领域实现应通尽通、能通尽通。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">《意见》强调，进一步破除“四唯”倾向，淡化高技能人才职称评审的学历要求，具备高级工以上职业资格或职业技能等级的技能人才，均可参加职称评审，不将学历、论文、外语、计算机等作为高技能人才参加职称评审的限制性条件。高技能人才职称评审更加强调技能贡献，注重评价科技成果转化应用、执行操作规程、解决生产难题、参与技术改造革新、工艺改进、传技带徒等方面的能力和贡献。对长期坚守在生产服务一线岗位工作的高技能领军人才，采取特殊评价办法，建立绿色通道。支持有条件的地区和单位对高技能人才单独分组、单独评审。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">《意见》鼓励专业技术人才参加职业技能评价，加强评价制度与用人制度衔接，探索建立企业内部技能岗位等级与管理、技术岗位序列相互比照，专业技术岗位、经营管理岗位、技能岗位互相衔接机制。高级工、技师、高级技师在学习进修、岗位聘任、职务职级晋升、评优评奖、科研项目申报等方面，比照相应层级专业技术人员享受同等待遇。</p>
<p style="color: #666666;margin-bottom: 20px;text-indent: 2em;">《意见》要求，各地、各有关单位要采取切实管用的措施，加大工作力度，确保各项政策措施取得实效，加强政策宣传解读，提高用人单位积极性，引导两类人才积极参与。</p>
`},
]
export default {
    newsList,
}
