<template>
    <div>
        <div class="public-class">
            <div class="banner">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/public-banner.png" alt="">
            </div>
            <div class="content">
                <div class="title">
                    <i></i>
                    <span>最新公开课</span>
                </div>
                <ul class="new-class">
                    <li @click="geClassDetail('1')"><img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/new-1.png" alt=""></li>
                    <li @click="geClassDetail('2')"><img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/new-2.png" alt=""></li>
                    <li @click="geClassDetail('3')"><img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/new-3.png" alt=""></li>
                </ul>
            </div>
            <div class="content">
                <div class="title">
                    <i></i>
                    <span>往期公开课</span>
                </div>
                <ul class="old-class">
                    <li v-for="ele in list" :key="ele.id" @click="geClassDetail(ele.id)">
                        <img :src="ele.img" alt="">
                        <div class="section">
                            <p class="ques">{{ele.title}}</p>
                            <p class="teacher">主讲：{{ele.teacher}}</p>
                            <p class="date"><i></i><span>{{ele.date}}</span></p>
                        </div>
                    </li>
                </ul>
            </div>

        </div>
        <footers/>
    </div>
</template>

<script>
    import {list} from '../asset/js/publicClassList'
    import footers from '../block/front/footers'

    export default {
        name: "publicClass",
        data() {
            return {
                list: []
            }
        },
        created() {
            this.list = list;
        },
        methods: {
            geClassDetail(id){
                this.$router.push({
                    path:'/classDetail',
                    query:{
                        id: id,
                    }
                })
            }
        },
        components: {
            footers,
        }
    }
</script>

<style lang="stylus" scoped>
    @import '../asset/css/publicClass'
</style>
