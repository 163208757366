<template>
    <el-collapse v-model="activeNames">
         <el-collapse-item :name="index" v-for="(el,index) in itemList" :key="el.id" v-show="el.childrenList">
            <template slot="title" v-if="!el.type">
               <i :class="activeNames.includes(index)?'el-icon-caret-bottom':'el-icon-caret-right'"></i>
                <!-- <img src="../../asset/编组 15.png" alt="" v-if="activeNames.includes(index)">
                <img src="../../asset/编组 14.png" alt="" v-else>  -->
                <span class="sourse-title">{{el.name}}</span>
            </template>
            <ul v-if="el.childrenList">
                <li v-for="(ele,index) in el.childrenList" :key="ele.id" >
                <p v-if="ele.type" class="sourse" @click="toDetail(index,ele)">
                    <i class="el-icon-check defindcheck" v-if="(ele.studyInfo && ele.studyInfo.learnRate===0)||ele.studyInfo===null"></i>
                    <i class="el-icon-check ischeck" v-if="ele.studyInfo && ele.studyInfo.learnRate"></i>
                    <!-- <img src="../../asset/对勾未读.png" alt="" v-if="(ele.studyInfo && ele.studyInfo.learnRate===0)||ele.studyInfo===null">
                    <img src="../../asset/对勾已读.png" alt="" v-if="ele.studyInfo && ele.studyInfo.learnRate"> -->
                    <img src="../../asset/icon-sp.png" alt="" v-if="ele.type===1">
                    <img src="../../asset/编组 2.png" alt="" v-if="ele.type===2">
                    <span :class="isSelect===index?'selected sourse-name':'sourse-name'" :title="ele.name">{{ele.name}} <span v-if="ele.dateExpireStatus == 2 || courseExpireStatus == 2" class="guoqi">已过期不可查看</span></span>
                    <span v-if="ele.studyInfo && ele.studyInfo.learnRate" class="learnRate">{{ele.studyInfo && ele.studyInfo.learnRate}}%</span>
                </p>
                </li>
            </ul>
            <courseItem v-if="el.type===0" :itemList="el.childrenList"></courseItem>
        </el-collapse-item>
    </el-collapse>
</template>
<script>
import courseItem from './courseItem.vue';
export default {
    name: 'courseItem',
    components: {
        courseItem
    },
    props: {
        itemList: {
            type: Array,
            default: () => []
        },
        courseName: {
            type: String,
            default: ''
        },
        courseExpireStatus:{
            type:Number
        }
    },
     data () {
        return {
            activeNames: [0],
            isSelect:''
        }
     },
     methods:{
         toDetail(e,item){
            this.isSelect = e;
            if(item.dateExpireStatus == 2 || this.courseExpireStatus == 2){
                this.$message.info('已过期不可查看')
            }else {
                this.$router.push({
                    path:'/video',
                    query:{
                        trainId:this.$route.query.id,
                        courseId:item.courseId,
                        detailId:item.detailId,
                        studyProcess:item.studyInfo?item.studyInfo.learnRate:'',
                        courseName:this.courseName
                    }
                })
            }
            
         }
     }
}
</script>
<style lang="stylus" scoped>
 @import "../../asset//css/courseItem.styl"

</style>