<template>
    <!-- 报告详情 -->
    <div class="reportDetails">
        <div class="backButton">
            <span @click="goBack">
                    <i class="back el-icon-arrow-left"></i>
                返回</span>
        </div>
        <div class="reportDetails-con">
            <div class="title">
                <div class="name">
                    <span class="line"></span>
                    <h4>{{reportList.assessReportName}}</h4>
                </div>
                <div class="time">
                    <span>{{reportList.time | dataFormat}}</span>
                </div>
            </div>
            <div v-if="single">
                <div class="row">
                    <div class="colLeft">
                        <div class="portrait">
                            <img :src="headImage" alt="">
                            <span>{{user.name}}</span>
                        </div>
                        <div class="liList">
                            <el-scrollbar style="height:100%">
                                <li v-if="resultName">
                                    <span class="label">类型</span>
                                    <span class="con">
                                        <p>{{resultName}}</p>
                                    </span>
                                </li>
                                <template v-for="(item, index) in attributeList">
                                    <li :key="index" v-if="item.attributeType==1 || item.attributeType==3">
                                        <span class="label">{{item.attributeName}}</span>
                                        <span class="con">
                                            <p>{{item.attributeDescription}}</p>
                                        </span>
                                    </li>
                                </template>
                            </el-scrollbar>
                        </div>
                    </div>
                    <div class="colRight">
                        <div id="main" style="width: 712px;height:288px;"></div>
                    </div>
                </div>
                <template v-for="(item, index) in attributeList">
                    <div class="features" :key="index"
                        v-if="item.attributeType==2 || item.attributeType==4">
                        <div class="titles">
                            <span class="main">{{item.attributeName}}</span>
                            <!-- <span class="vice">BEHAVIOR CHARACTERISTICS</span> -->
                        </div>
                        <div class="content single" v-html="item.attributeDescription">{{item.attributeDescription}}</div>
                    </div>
                </template>
            </div>
            <div v-else>
                <div class="basic">
                    <div class="portrait">
                        <img :src="headImage" alt="">
                        <span>{{user.name}}</span>
                    </div>
                    <div class="chart">
                        <div id="main1" style="width: 1152px;height:280px;"></div>
                    </div>
                </div>
                <div class="features" v-for="(item, index) in dimensionDtoList" :key="index">
                    <div class="titles">
                        <span class="main">{{item.dimensionName}}</span>
                        <!-- <span class="vice">BEHAVIOR CHARACTERISTICS</span> -->
                    </div>
                    <div class="content noPadtop">
                        <template v-for="(item1, index1) in item.attributeList">
                            <li class="oneWay" :key="index1"
                                v-if="[1, 3].includes(+item1.attributeType)">
                                <span class="label">{{item1.attributeName}}</span>
                                <span class="con">
                                    <p>{{item1.attributeDescription}}</p>
                                </span>
                            </li>
                            <li :key="index1" v-else>
                                <span class="label labelMany">{{item1.attributeName}}</span>
                                <span class="conMany">
                                    <p v-html="item1.attributeDescription">{{item1.attributeDescription}}</p>
                                </span>
                            </li>
                        </template>
                    </div>
                </div>
            </div>
            <div class="statement">
                <div class="titles">
                    <span class="main">免责声明</span>
                </div>
                <div class="content">
                    <div>以下说明与本测评报告正文一起构成本测评报告不可分割的统一整体。</div>
                    <div>
                        <p>• 本测评报告基于测评者的作答情况而获得，结果代表的是测评者的自我觉知。</p>
                        <p>• 本测评报告正文内容可能引发的与任何第三人之纠纷或冲突，本测评机构不承担任何法律责任。本测评机构亦不在此类纠纷或冲突中充当证人、调停人或其他形式之参与人</p>
                        <p>• 本测评报告不得用于非法之目的，本测评机构不承担任何由此而发生或可能发生之法律责任。</p>
                    </div> 
                    <div>当本测评报告打印、持有、出具、展示或以其它任何形式使用时，即表明本测评报告之持有人或接触人已审读，理解并同意以上各条款之规定。</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'reportDetails',
    components: {},
    data() {
        return {
            id: '',
            reportList: {},
            user: {},
            headImage: '',
            single: true,
            attributeList: {},
            dimensionDtoList: [],
            nameArr: [],
            numArr: [],
            resultName: ''
        };
    },
    filters: {
        dataFormat(msg) {
            if (msg) {
                const m = msg.split(' ')[0].split('-');
                const t = msg.split(' ')[1].slice(0, -3);
                const s = m.join('.') + ' ' + t;
                return s;
            }
        },
    },
    mounted() {
        this.id = this.$route.query.id;
        if (this.$route.query.type=='result') {
            this.findByResultIdId();
        } else {
            this.findByIdReport();
        }
        this.getPersonalInfo();
    },
    methods: {
        // 我的资料
        getPersonalInfo(){
            this.$api.usercenter.findUser().then(res => {
                if(res.code == 0){
                    this.user = res.data;
                    if (this.user.headImage) {
                        this.headImage = this.user.headImage;
                    } else {
                        this.headImage = require("@assets/userL.png");
                    }
                }
            })
        },
        findByResultIdId() {
            this.$api.assess.findByResultIdId(this.id).then((res) => {
                if(res.data){
                    this.getReportData(res.data);
                }
            });
        },
        findByIdReport() {
            this.$api.assess.findByIdReport(this.id).then((res) => {
                if(res.data){
                    this.getReportData(res.data);
                }
            });
        },
        getReportData(data) {
            this.reportList = data;
            if (this.reportList.relationType==2) {
                this.single = false;
                this.dimensionDtoList = this.reportList.dimensionDtoList;
            } else {
                this.single = true;
                this.attributeList = this.reportList.dimensionDtoList[0].attributeList;
                this.resultName = this.reportList.dimensionDtoList[0].resultName;
            }
            this.nameArr = [];
            this.numArr = [];
            this.reportList.indicatorList.forEach(el=>{
                this.nameArr.push(el.indicatorName);
                this.numArr.push(el.score);
            });
            this.drawChart(this.reportList.relationType);
        },
        drawChart(num) {
            const that = this;
            let morecolorList = [];
            let blue = ["#82C1FF", "#7C9CFF"];
            let red = ["#FFA971", "#FF9B80"];
            // 基于准备好的dom，初始化echarts实例
            var myChart;
            if (num==1) {
                myChart = that.$echarts.init(document.getElementById("main"));
            } else {
                that.$nextTick(()=>{
                    myChart = that.$echarts.init(document.getElementById("main1"));
                })
            } 
            that.$nextTick(()=>{
                // 使用刚指定的配置项和数据显示图表。
                myChart.setOption(
                    // 指定图表的配置项和数据
                    {
                        grid: {
                            // show: true,
                            left: 60,
                            right: 60,
                            top: 50,
                            bottom: 30
                        },
                        xAxis: {
                            type: "category",
                            data: that.nameArr,
                            nameTextStyle: {
                                color: 'rgba(102, 102, 102, 1)',
                                fontSize: 18,
                                lineHeight: 18,
                            },
                            nameGap: 7,
                            silent: true,
                            axisTick: {
                                color: "rgba(243, 243, 243, 1)",
                                show: false
                            },
                            axisLine: {
                                show: false
                            },
                        },
                        yAxis: {
                            type: "value",
                            show: true,
                            nameTextStyle: {
                                color: 'rgba(102, 102, 102, 1)',
                                fontSize: 18,
                                lineHeight: 18,
                            },
                            silent: true,
                            axisTick: {
                                color: "rgba(243, 243, 243, 1)",
                                show: false
                            },
                            axisLabel: {
                                show: false,
                                lineStyle: {
                                    color: "rgba(243, 243, 243, 1)"
                                }
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "rgba(228, 229, 230, 1)"
                                }
                            },
                        },
                        series: [
                            {
                                type: "bar",
                                data: that.numArr,
                                label: {
                                    show: true,
                                    position: "top",
                                    color: "#666",
                                    fontSize: 16
                                },
                                itemStyle: {
                                    borderRadius: [100, 100, 0, 0],
                                    color: function(params) {
                                        if (params.dataIndex % 2 === 0) {
                                            morecolorList.push(blue);
                                        } else {
                                            morecolorList.push(red);
                                        }
                                        let colorItem =
                                            morecolorList[params.dataIndex];

                                        return new that.$echarts.graphic.LinearGradient(0, 0, 0, 1,
                                            [
                                                {
                                                    offset: 0,
                                                    color: colorItem[0]
                                                },
                                                {
                                                    offset: 1,
                                                    color: colorItem[1]
                                                }
                                            ],
                                            false
                                        );
                                    }
                                },
                                barWidth: 20
                            }
                        ]
                    }
                );
            })
        },
        // 返回
        goBack() {
            sessionStorage.setItem('userCenterCurrentTab','我的测评')
            this.$router.go(-1)
        },
    }
};
</script>

<style scoped lang="stylus">
@import '../asset/css/reportDetails.styl';
</style>
