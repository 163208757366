<template>
    <div class="management">
        <div class="top">
            <div class="content">
                <div class="left">
                    <p class="title">家政管理系统<span>(扫描二维码下载）</span></p>
                    <div class="code">
                        <div class="ios">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/ios.png" alt="">
                            <p class="text">iOS二维码</p>
                        </div>
                        <div class="android">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/android.png" alt="">
                            <p class="text">安卓二维码</p>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/phone.png" class="phone" alt="">
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="footer-top">
                <div class="left">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/footer-logo.png" class="logo" alt="">
                    <span class="text">山东家政助力家政行业提质扩容</span>
                </div>
                <div class="right">
                    <span>邮编250000</span>
                    <span>地址：山东省济南市历阳大街6号</span>
                </div>
            </div>
            <div class="bottom">
                版权所有： 山东省商务厅京ICP备10026535号-15 技术支持：伟东云教育集团
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'zhaohuManagement'
}
</script>
<style lang="stylus" scoped>
.management
    width 100%
    height 100vh
    background url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/management-banner.png')
    background-size cover
    background-position center
    background-repeat no-repeat
    position relative
    .top
        width 100%
        // height 80vh
        padding-top 167px
        padding-bottom 30px
        box-sizing border-box
        .content
            display flex
            justify-content space-between
            width 1040px
            margin 0 auto 
            .left
                margin-top 197px
                .title
                    font-size: 32px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 45px;
                    margin-bottom 49px
                    span
                        font-size 24px
                        color #999
                .code
                    display flex
                    width 425px
                    justify-content space-between
                    text-align center
                    .text
                        font-size: 16px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        line-height: 22px;
                        margin-top 10px
            .right
                width 302px
                .phone
                    width 100%
    .footer
        width 100%
        height 200px
        background: #202735;
        .footer-top
            width 1200px
            margin 0 auto
            display flex
            box-sizing border-box
            justify-content space-between
            align-items center
            color #fff
            .left
                margin 27px 0
                .logo
                    vertical-align middle
                    width 98px
                    height 94px
                .text
                    vertical-align middle
                    display inline-block
                    margin-left 25px
                    font-size: 22px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 30px;
            .right
                span
                    margin-left 20px
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 20px;
        .bottom
            text-align center
            padding-top 16px
            color rgba(255,255,255,.4)
            border-top: 1px solid #424242;
@media screen and (max-width: 1440px) 
    .management
        .top
        width 100%
        height 75vh
        padding-top 7%
        box-sizing border-box
        .content
            display flex
            justify-content space-between
            width 1040px
            margin 0 auto 
            .left
                margin-top 197px
                .title
                    font-size: 32px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 45px;
                    margin-bottom 49px
                    span
                        font-size 24px
                        color #999
                .code
                    display flex
                    width 425px
                    justify-content space-between
                    text-align center
                    .text
                        font-size: 16px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        line-height: 22px;
                        margin-top 10px
            .right
                width 302px
                .phone
                    width 100%
    .footer
        width 100%
        height 200px
        background: #202735;
        .footer-top
            width 1200px
            margin 0 auto
            display flex
            box-sizing border-box
            justify-content space-between
            align-items center
            color #fff
            .left
                margin 27px 0
                .logo
                    vertical-align middle
                .text
                    vertical-align middle
                    display inline-block
                    margin-left 25px
                    font-size: 22px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 30px;
            .right
                span
                    margin-left 20px
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 20px;
        .bottom
            text-align center
            padding-top 16px
            color rgba(255,255,255,.4)
            border-top: 1px solid #424242;


</style>