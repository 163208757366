<template>
    <div class="homePage">
        <homeBanner :id="trainObj.id" />
        <div class="quality-course">
            <div class="quality-course-title">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/title-1.png" alt="">
            </div>
            <div class="bg-box">
            </div>
            <div class="quality-course-content _clear">
                
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/label-icon.png" class="label-icon" alt="">
                <div class="quality-course-left fl">
                    <img :src="trainObj.picUrl" alt="">
                </div>
                <div class="quality-course-right fl">
                    <div class="quality-course-right-top">
                        <p class="train-name">{{trainObj.name}}</p>
                        <div class="price">
                            <p class="text">限时优惠</p>
                            <p class="preferential-price">¥{{trainObj.salePrice}}</p>
                            <p class="original-price">¥{{trainObj.basePrice}}</p>
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/apply-btn.png" @click="gongxinDetails" class="apply-btn" alt="">
                        </div>
                        <div class="button-box">
                            <p class="button" @click="gongxinDetails">
                                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/mulu-icon.png" alt="">
                                <span>目录大纲</span>
                            </p>
                            <p class="button" @click="gongxinDetails">
                                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/shiting-icon.png" alt="">
                                <span>课程试听</span>
                            </p>
                          
                            <p class="button" @click="showOnLine1">
                                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/youhui-icon.png" alt="">
                                <span>领取优惠</span>
                            </p>
                        </div>
                    </div>
                    <div class="quality-course-right-bottom">
                    </div>
                </div>
            </div>
        </div>
        <div class="home_team">
            <div class="home_team-banner">
                <div class="item item1">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/老师1.png" alt="">
                    <div class="info">
                        <h2 class="name">
                            王群
                        </h2>
                        <p class="jianjie">副教授、研究生导师</p>
                        <p class="jieshao">毕业于北京广播学院（中国传媒大学），获得艺术硕士学位，曾获得北京广播学院“十佳教师”称号。</p>
                    </div>
                </div>
                <div class="item item1">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/老师2.png" alt="">
                    <div class="info">
                        <h2 class="name">
                            杨雅哲（中国香港）
                        </h2>
                        <p class="jianjie">时尚造型师、时装设计师等</p>
                        <p class="jieshao">师从意大利著名成衣手工坊创始人，与国内高端杂志、媒体/电视剧、电台、一线品牌保持长期合作。</p>
                    </div>
                </div>
                <div class="item item1">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/老师3.png" alt="">
                    <div class="info">
                        <h2 class="name">
                            徐明华
                        </h2>
                        <p class="jianjie">电视购物频道资深专家</p>
                        <p class="jieshao">毕业于中国台湾世界新闻学院广播电视专业，国内广播电视专业、电视购物行业资深专家。</p>
                    </div>
                </div>
                <div class="item item1">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/老师4.png" alt="">
                    <div class="info">
                        <h2 class="name">
                            李嘉
                        </h2>
                        <p class="jianjie">创始人、著名购物节目主持人</p>
                        <p class="jieshao">毕业于北京广播学院，中国广播声音购物创始人，中国第一个销售额破10亿购物节目主持人。</p>
                    </div>
                </div>
                
            </div>
        </div>
        
        <div class="lecture">
            <div class="lecture-title">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/title-3.png" alt="">
            </div>
            <div class="lecture-content">
                 <el-carousel :interval="4000" type="card" ref="carousel" @change="carouselChange" :autoplay="false" height="360px">
                    <el-carousel-item class="live-bg" id="item1" ref="item1">
                        <div class="info" v-if="liveObj.length" @click="goliveDetail(liveObj[0])">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/play-icon.png" class="icon-play" alt="">
                            <div class="content _clear">
                                <p class="live-title fl">{{liveObj[0].title}}</p>
                                <p class="live-time fr">{{liveObj[0].beginTime}}</p>
                            </div>
                            <p class="jieshao">
                                <span>行业解读</span>
                                <span>试听公开课</span>
                                <span>专家讲座</span>
                            </p>
                        </div>
                        <div class="course-item info" @click="gongxinDetails" v-else>
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/play-icon.png" class="icon-play" alt="">
                            <p class="text">点击了解课程介绍</p>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item class="live-bg" id="item2"  ref="item2">
                        <div class="info" v-if="liveObj.length && liveObj.length >= 2" @click="goliveDetail(liveObj[1])">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/play-icon.png" class="icon-play" alt="">
                            <div class="content _clear">
                                <p class="live-title fl">{{liveObj[1].title}}</p>
                                <p class="live-time fr">{{liveObj[1].beginTime}}</p>
                            </div>
                            <p class="jieshao">
                                <span>行业解读</span>
                                <span>试听公开课</span>
                                <span>专家讲座</span>
                            </p>
                        </div>
                        <div class="course-item info" @click="gongxinDetails" v-else>
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/play-icon.png" class="icon-play" alt="">
                            <p class="text">了解更多课程详情</p>
                        </div>
                    </el-carousel-item>
                    
                    <el-carousel-item class="live-bg" id="item3"  ref="item3">
                        <div class="info" v-if="liveObj.length && liveObj.length == 3" @click="goliveDetail(liveObj[2])">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/play-icon.png" class="icon-play" alt="">
                            <div class="content _clear">
                                <p class="live-title fl">{{liveObj[2].title}}</p>
                                <p class="live-time fr">{{liveObj[2].beginTime}}</p>
                            </div>
                            <p class="jieshao">
                                <span>行业解读</span>
                                <span>试听公开课</span>
                                <span>专家讲座</span>
                            </p>
                        </div>
                        <div class="course-item info" @click="gongxinDetails" v-else>
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/play-icon.png" class="icon-play" alt="">
                            <p class="text">查看更多试听课</p>
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <div class="double-arrow-box" @click="goLive">更多专题讲座 <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/double-arrow.png" class="double-arrow" alt=""></div>
                
            </div>
        </div>
        <div class="bottoma">
        </div>
        <div class="part6"></div>
        <footers/>
        <div class="fixed-link">
            <div class="fixed-link-top" @click="backTop">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/arrow-top.png" alt="">
                <el-button type="text" id="to-top-btn">回到顶部</el-button>
            </div>
            <div class="fixed-link-bottom">
                <div class="item" @click="showOnLine1" id="itemSelf1">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/link.png" alt="">
                    <p>在线咨询</p>
                </div>
                <div class="item" @click="showOnLine2">
                   <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/er.png" alt="" id="itemSelf2">
                   <p>移动学习</p>
                </div>
            </div>
        </div>
       <div class="online-link" v-if="showOnTip1||showOnTip2" id="onlineTip" :style="showOnTip2?'bottom:70px':'120px'">
           <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/wechat.png" alt="">
           <div>
               <!-- <p>咨询电话:400-8555-686</p> -->
               <p>移动学习 随时随地</p>
           </div>
       </div>
    </div>
</template>

<script>
import homeBanner from '../block/front/homeBanner.vue'
import footers from '../block/front/footers.vue'
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
export default {
  name: 'wanghong_homepage',
  show: true,
  components: {
      homeBanner,
      footers
  },
    data() {
        return {
            visHeight:0,
            // token:'',
            showOnTip1:false,
            showOnTip2:false,
            dialogVisible:false,
            trainObj:{},
            liveObj:[],
            liveHas:false,
            swiper:null,
            carouseId: 0,
            left:11
        };
    },
    filters: {
      dataFormat(msg) {
        if (msg) {
            const m = (msg||'').split(' ')[0].split('-');
            const s = m[0]+'年'+m[1]+'月'+m[2]+'日';
            return s;
        } 
      }
    },
    mounted() {
         document.addEventListener('click',(e) => {
            //获取弹窗对象
            const userCon = document.getElementById('onlineTip');
            const itemSelf1 = document.getElementById('itemSelf1');
            const itemSelf2 = document.getElementById('itemSelf2');
            //判断弹窗对象中是否包含点击对象
            if(userCon && !userCon.contains(e.target) && !itemSelf1.contains(e.target) && !itemSelf2.contains(e.target)) {
                //如果包含则跳转回之前的页面
                this.showOnTip1 = false;
                this.showOnTip2 = false;
            }
        });

    },
    created() {
        this.findSaleWanghongGoods();
        // this.getLive();
        /**
         * 在线客服
         */
        const script=document.createElement("script");
        const script1=document.createElement("script");
        script.type = "text/javascript";
        script1.type = "text/javascript";
        script.src = '//089410.kefu.easemob.com/webim/easemob.js';
        try {
            script1.appendChild(document.createTextNode("window.easemobim = window.easemobim || {};easemobim.config = {hide:true,autoConnect: true};"));
        } catch (ex) {
            script1.text = "window.easemobim = window.easemobim || {};easemobim.config = {hide:true,autoConnect: true};";
        }
        document.body.appendChild(script);
        document.body.appendChild(script1);

        this.$nextTick(function() {
             this.initSwiper();
        });
        // this.token = localStorage.getItem('token');
    },
    beforeDestroy() {
        
    },
    methods: {
        // qimoChatClick(){
        //     window.qimoChatClick();
        // },
        // 跳转直播详情
        goliveDetail(item){
            if(localStorage.getItem('token')){
                this.$api.live.audienceEnterRoom({id: item.id}).then(async (res) => {
                        if (res.success) {
                            this.$router.push({
                                path: '/studentSee',
                                query:{
                                    
                                    roomId: item.id,
                                    type: 1,
                                    title: encodeURIComponent(item.title),
                                }
                            })
                        }
                });
            }else {
                this.$router.push({
                    path: '/login',
                    query: {
                        name: '/gongxin_homepage'
                    }
                })
            }
        },
        carouselChange:function(now){
			/* console.log(now+"，"+old); */
            this.carouseId = now;
		},
        goEvaluation(){
            if(localStorage.getItem('token')){
                this.$router.push('/personalEvaluation')
            } else {
                this.$router.push({
                    path: '/login',
                    query: {
                        name: '/personalEvaluation'
                    }
                })
            }
        },
        details() {
            this.$router.push({
                path:'/wanghongDetails',
                query:{
                    id:this.trainObj.id
                }
            })
        },
        backTop(){
          var timer = setInterval(function(){
            let osTop = document.documentElement.scrollTop || document.body.scrollTop;
            let ispeed = Math.floor(-osTop / 5); 
            document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
            this.isTop = true;
            if(osTop === 0){
              clearInterval(timer);
            }
          },30)
        },
        goLive(){
            if(localStorage.getItem('token')){
                // let routeData = this.$router.resolve({ path: '/liveList' });
                // window.open(routeData.href, '_self');
                this.$router.push('/liveList')
            } else {
                this.$router.push({
                    path: '/login',
                    query: {
                        name: '/liveList'
                    }
                })
            }
        },
        showOnLine1(){
            /* eslint-disable */
            easemobim.bind({configId: "4a291e6d-0c30-46dd-9c65-815655ea59b2"})
            /* eslint-disable no-new */
            // this.showOnTip1  = !this.showOnTip1;
            // this.showOnTip2 = false;
        },
        showOnLine2(){
            this.showOnTip2  = !this.showOnTip2;
            this.showOnTip1 = false;
        },
        gongxinDetails(){
           this.$router.push({
                path:'/wanghongDetails',
                query:{
                    id:this.trainObj.id
                }
            })
        },
        findSaleWanghongGoods() {
            this.$api.home.findSaleWanghongGoods().then(res=>{
                if(res.data){
                    this.trainObj = res.data;
                    sessionStorage.setItem('goodsId', this.trainObj.id)
                    if(this.trainObj.name.length>16){
                        this.trainObj.name = this.trainObj.name.substring(0,16)+'...'
                    }
                }
            })
        },
        // getLive(){
        //     this.$api.home.getLive(1,3).then(res=>{
        //         if(res.data && res.data.list.length>0){
        //             this.liveObj = res.data.list;
        //             this.liveHas = true;
        //         }else{
        //             this.liveHas = false;
        //         }
        //     })
        // },
        initSwiper() {
            this.swiper = new Swiper(".swiper-container", {
                slidesPerView: 4,
                spaceBetween: 0,
                autoplay:true,
                loop:true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                // pagination: {
                //     el: '.swiper-pagination',
                //     clickable: true,
                // },
            });
        },
    }
};
</script>
<style lang="stylus" scoped>
@import '../asset/css/wanghongHomepage.styl'

</style>
