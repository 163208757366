<template>
    <div class="personnel">
        <div class="banner">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/personnel/xycxbannerBig.png" alt="">
        </div>
        <div class="container">
            <div class="tab">
                <p class="tabName" @click="tabClick(item)" :class="{active:tabIndex == item.id}" v-for="item in tabList" :key="item.id">
                    {{item.name}}
                </p>
            </div>
            <div class="content">
                
                <div class="content-middle"> 
                    <div v-if="+tabIndex == 1" class="input">
                        <el-input v-model="companyValue"  class="company-input" placeholder="请输入公司的名称"></el-input>
                        <el-button type="primary" class="search" @click="search(1)">查询</el-button>
                    </div>
                    <div v-if="+tabIndex == 2" class="input">
                        <el-input v-model="cardValue"  class="card-input" placeholder="请输入家政员的身份证号"></el-input>
                        <el-input v-model="nameValue"  class="phone-input" placeholder="请输入家政员的姓名"></el-input>
                        <el-button type="primary" class="search" @click="search(2)">查询</el-button>
                    </div>
                    <div>
                        <div v-if="!personnelShow && !companyShow" class="boxlist">
                            <ul>
                                <li v-for="item in boxlist" :key="item.id">
                                    <img :src="item.imgUrl" alt="">
                                    <p>{{item.name}}</p>
                                </li>
                            </ul>
                        </div>
                        <div v-if="+tabIndex === 2 && !personnelShow" class="appContent">
                            <h2 class="appContent-title">东东助手APP轻松查信用
                                <span class="title-lint"></span>
                            </h2>
                            <div class="appContent-bottom">
                                <div class="appContent-bottom-left">
                                    <div class="leftItem" v-for="item in appContentList" :key="item.id">
                                        <img :src="item.imgurl" alt="">{{item.tip}}
                                    </div>
                                </div>
                                <div class="appContent-bottom-right">
                                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/personnel/appcontent.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div v-if="+tabIndex == 1">
                    <companies ref="companies" :seachflag="companyShow" :companyValue="companyValue" />
                </div>
                <div v-if="+tabIndex === 2 && personnelShow">
                    <workers ref="workers" :name="nameValue" :idcard="cardValue" />
                </div>
            </div>
        </div>

        <footers></footers>
    </div>
</template>
<script>
import footers from './sdjz2Footer.vue'
import companies from '../block/front/companies.vue'
import workers from '../block/front/workers.vue'
export default {
    name:'personnel',
    components:{
        footers,
        companies,
        workers
    },
    data(){
        return{
            appContentList: [
                {
                    id: 0, 
                    imgurl: require('../asset/images/one.png'),
                    tip: '打开阿福到家APP，选择一家有意向的家政公司。'
                },
                {
                    id: 1, 
                    imgurl: require('../asset/images/two.png'),
                    tip: '在家政公司的人员列表，选择一位优秀的家政服务人员。'
                },
                {
                    id: 2, 
                    imgurl: require('../asset/images/three.png'),
                    tip: '在家政员简历页面，点击“信用查询”按钮，即可查看详细的家政员的信用报告。'
                },
            ],
            boxlist: [
                {
                    id: 0,
                    imgUrl: require('../asset/images/sjqw.png'),
                    name: '数据权威'
                },
                {
                    id: 1,
                    imgUrl: require('../asset/images/jzpp.png'),
                    name: '精准匹配'
                },
                {
                    id: 2,
                    imgUrl: require('../asset/images/gxbj.png'),
                    name: '高效便捷'
                },{
                    id: 3,
                    imgUrl: require('../asset/images/dzwd.png'),
                    name: '多种维度'
                }
            ],
            payShow:false,
            personnelShow: false,
            companyValue:'',
            companyShow:false,
            emptyShow:false,
            cardValue:'',
            nameValue:'',
            tabIndex:1,
            tabList:[
                {
                    id:1,
                    name:'查家政公司'
                },
                {
                    id:2,
                    name:'查家政服务人员'
                }
            ],
        }
    },
    mounted(){
        this.tabIndex = this.$route.query.type || 2
    },
    methods:{
        tabClick(item){
            this.tabIndex = item.id
            this.companyValue = ''
            this.cardValue = ''
            this.nameValue = ''
            this.personnelShow = false
            this.companyShow = false
        },
        search(type){
            
            if(type == 1){
                if(this.companyValue){
                    this.companyShow = true;
                    this.$refs.companies.findList(1);
                }else {
                    this.companyShow = false
                    this.$refs.companies.homeDisplayList();
                }
            }else {
                this.personnelShow = false
                if(this.cardValue != '' || this.nameValue != ''){
                    this.personnelShow = true
                    this.$nextTick(()=>{
                        this.$refs.workers.findByNameAndIdCard();
                    })
                    
                }else {
                    this.personnelShow = false
                }
            }
            
        }
    }
}
</script>
<style lang="stylus" scoped>
@import "../asset/css/personnel.styl"
</style>
<style lang="stylus">

.dialogVisible4 .el-dialog__body {
        padding: 0px 20px;
}
</style>