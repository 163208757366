<template>
    <div class="screen">
        <img v-if="type == 1" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/Train-regulation-screen.png" alt="">
        <img v-else-if="type == 2" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/government-screen.png" alt="">
        <img v-else src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/housekeeping-screen.png" alt="">
    </div>
</template>
<script>
export default {
    name:'screen',
    data(){
        return{
            type:1
        }
    },
    mounted(){
         this.type = this.$route.query.type
    }
}
</script>
<style lang="stylus" scoped>
.screen{
    width 100%   
    img{
        width 100%
    }
}
</style>