<template>
    <div class="HouseKeepingTrain publicStylus">
        <div class="container">
            <div class="top">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhaohu/admissions-banner.png" class="banner" alt="">
                <div class="content">
                    <div class="info">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/personnel-icon.png" class="icon" alt="">
                        <p>健康照护招生报名</p>
                    </div>
                </div>
                
            </div>
            <div class="bottom">
                <div class="item" v-for="item in list" :key="item.id">
                    <h2 class="title zh">{{item.zh}}</h2>
                    <p class="cn">{{item.cn}}</p>
                    <p class="line"></p>
                    <p class="content">{{item.content}}</p>
                </div>
            </div>
        </div>
        <footers></footers>
    </div>
</template>
<script>
import footers from './sdjz2Footer.vue'
export default {
    name:'admissions',
    components:{
        footers
    },
    data(){
        return{
            list:[
                {
                    id:1,
                    zh:'增强职业意识',
                    cn:'Enhance Career Awareness',
                    content:'帮助服务人员增强职业意识，强化服务内功、关注细节、把控品质，得到客户的认可。'
                },
                {
                    id:2,
                    zh:'提升职业能力',
                    cn:'Enhance Career Competence',
                    content:'帮助服务人员提升执行和解决问题能力，拥有创新意识和创新思维，提高自身的家政职业能力。'
                },
                {
                    id:3,
                    zh:'提高职业修养',
                    cn:'Improve professional training',
                    content:'帮助服务人员了解现代家政服务行业，了解职业范畴，形成饱满的工作热情、积极的心态和高度责任感。'
                }
            ]
        }
    },
    methods:{
        goPage(){
            window.open('https://sdjzpx.wdeduc.com/sdjz_homepage','newW')
        }
    }
}
</script>
<style lang="stylus" scoped>
@import "../asset/css/publicStylus.styl"
</style>