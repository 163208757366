<template>
    <div class="courseDetails">
        <div class="backButton">
            <span @click="goBack">
                <i class="back el-icon-arrow-left"></i>
                返回
            </span>
        </div>
        <div class="task-container">
            <div class="task-top">
                <span class="task-name" v-if="currentCourse.courseStatus != 8">{{currentCourse.name}}</span>
                <span class="termination" v-else><span>******</span><span>很抱歉，此内容已被强制终止</span></span>
                <span class="task-progress">已完成 {{currentCourse.userLearnInfo && currentCourse.userLearnInfo.learnRate || 0}}%</span>
                <span class="time"></span>
            </div>
            <section>
                <aside>
                    <div class="cover">
                        <img :src="currentCourse.pic" alt="">
                    </div>
                    <div class="evaluation-box">
                        <div class="evaluation _clear">
                            <p v-for="itemcount1 in count" :key="itemcount1">
                                <img src="@assets/course/pfwjx2.png" alt />
                            </p>
                            <p v-for="itemcount2 in 5-count" :key="itemcount2+count">
                                <img src="@assets/course/pfwjx3.png" alt />
                            </p>
                            <p>{{count1.toFixed(0)}}分</p>
                        </div>
                        <div class="course-score" @click="playboxscoreclick">
                            {{myScore>0?'已评分':'未评分'}}
                        </div>
                        <span class="course-info" :class="{active : currentCourse.courseStatus === 8 }"  @click="getcourseInfo(currentCourse.courseStatus)">课程简介</span>
                    </div>
                </aside>
                <article>
                    <div class="termination-icon" v-if="currentCourse.courseStatus === 8">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/course-nodata.png" alt="">
                        <p class="text">课程暂时无法学习呦～</p>
                    </div>
                    <div v-else>
                        <courseItem :itemList="catalogList" :courseExpireStatus='currentCourse.courseExpireStatus' :courseName="currentCourse.name" v-if="currentCourse.id"></courseItem>
                        <p class="course-all">-已加载全部内容-</p>
                    </div>
                </article>
            </section>
            
        </div>
        <playboxscore v-if="playboxscore" :myScore="myScore" :issubmit="myScore>0?false:true"></playboxscore>
      <dialogInfo :info="info" :currentCourse="currentCourse" :nameType='"课程简介："' :type="2" @infoShow="infoShow"></dialogInfo>
    </div>
</template>
<script>
import dialogInfo from './../../train/page/dialogInfo.vue'
export default {
    name:'courseDetails',
    components:{
        dialogInfo
    },
    data(){
        return{
            count:5,
            count1:0,
            myScore:0,//我的评分
            courseId: null,//课程id
            taskId:null,//任务id
            playboxscore:false,
            currentCourse: {},
            info: false,
            catalogList: [],//课程章节
            TaskRule:{},
        }
    },
    created() {
        this.findCourseInfo();
    },
    mounted(){
        this.findCourseItemByCourseId()
        
    },
    methods:{
        // 点击评分
        async evaluateCourse(count) {
            const data = {
                courseId: this.$route.query.id,
                score: count
            };
            await this.$api.course.evaluateCourse({ data }).then(() => {
                this.playboxscore = false;
                this.getEvaluationInfo();
            });
        },
        // 关闭评分
        clickclose() {
            this.playboxscore = false;
            this.$forceUpdate();
        },
        // 课程简介
        getcourseInfo(status){
            if(status === 8){
                this.$message.error('很抱歉，此内容已被强制终止')
            }else {
                this.info = true;
            }
        },
        
        // 课程章节
        async findCourseItemByCourseId () {
            const { data } = await this.$api.training.findCourseItemByCourseId({ 
                params:{
                    courseId:this.$route.query.id,
                    taskId:this.$route.query.taskId,
                    taskType:this.$route.query.taskType || null
                }
             });
            this.catalogList = data;
        },
        // 弹窗
        infoShow(val){
            this.info = val
        },
        // 返回
        goBack(){
            this.$router.push({
                path:'/learning/taskDetail',
                query:{
                    id:this.$route.query.taskId,
                    backMoudle: this.$route.query.backMoudle,
                    type: this.$route.query.taskType,

                }
            })
        },
        // 点击评价
        playboxscoreclick(){
            if(this.currentCourse.userLearnInfo && this.currentCourse.userLearnInfo.learnRate>=30){
                this.playboxscore=true;
            }else{
                this.$message.error('课程学习进度未达到30%，不能进行评价')
            }
        },
        // 课程详情
        async findCourseInfo () {
            let params = {
                courseId: this.$route.query.id,
                taskId: this.$route.query.taskId,
                taskType:this.$route.query.taskType || null
            }
            const { data } = await this.$api.training.findCourseInfo({ params });
            this.currentCourse = data;
            sessionStorage.setItem('courseUserLearnInfo', JSON.stringify(this.currentCourse.userLearnInfo))
            console.log('1111111111111111', this.currentCourse)
            this.getEvaluationInfo();
        },
        async getEvaluationInfo() {
            let params = {
                courseId: this.$route.query.id
            };
            await this.$api.course.getEvaluationInfo({ params }).then(res => {
                this.count1=res.data.aveScore?res.data.aveScore:5;
                this.count=res.data.aveScore?Math.ceil(res.data.aveScore):5;
                this.myScore=res.data.myScore?Math.ceil(res.data.myScore):0;
                this.$forceUpdate();
            });
        },
    }
}
</script>
<style lang="stylus" scoped>
@import '../asset/css/taskDetail.styl'
</style>