import pageJson from './config/page.json'
import staticPageJson from './config/staticPage.json'
import templateConf from './config/template.json'
// import vueTemplate from './config/vueTemplate'
const routerArr = []

let pageObj = {}
const elObj = require.context('./page', false, /\.vue$/)
elObj.keys().forEach(key => {
  const el = elObj(key).default
  el.component = `./page${key.substr(1)}`
  pageObj[el.name] = el
})

for (const key in staticPageJson) {
  if (pageObj[staticPageJson[key].template_name]) {
    const url = pageObj[staticPageJson[key].template_name].component
    const component = () => import(`${url}`)
    routerArr.push({
      ...staticPageJson[key],
      props: () => ({
        pageData: staticPageJson[key],
        templateConf: templateConf[staticPageJson[key].block_layout],
      }),
      component
    })
  }
}

const template = {}
const templateObj = require.context('./template', true, /\.vue$/)
templateObj.keys().forEach(key => {
  const el = templateObj(key).default
  el.component = `./template${key.substr(1)}`
  template[el.name] = el
})
for (const key in pageJson) {
  const url = template[pageJson[key].template_name].component
  const component = () => import(`${url}`)
  routerArr.push({
    ...pageJson[key],
    props: () => ({
      pageData: pageJson[key],
      templateConf: templateConf[pageJson[key].block_layout],
    }),
    component
  })
}

export default routerArr
