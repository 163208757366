<template>
 <div class='sdjiazheng-list'>
     <div class="jingpin2 jingpin">
        <div class="title">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/jinpin-title.png" alt="">
        </div>
        <div class="card-box">
            <div class="card2">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/card-img_1.png" />
            </div>
            <div class="card2">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/card-img_2.png" />
            </div>
            <div class="card2">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/card-img_3.png" />
            </div>
            <div class="card2">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/card-img_4.png" />
            </div>
            <div class="card2">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/card-img_5.png" />
            </div>
            <div class="card2">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/card-img_6.png" />
            </div>
            <!-- <div class="card2">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/card-img_7.png" />
            </div>
            <div class="card2">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/card-img_8.png" />
            </div> -->
        </div>
    </div>
    <div class="train">
        <div class="container">
            <div class="title">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/title-logo.png" alt="">
            </div>
            <div class="box">
                <div class="left">
                    <div class="tip">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/jiazheng/title-01.png" alt="">
                        <div class="content">
                            <p>课程紧扣山东地区家政行业实际，以科学的理念为基</p>
                            <p>础，针对家政服务从业人员职业素养知识进行全方位</p>
                            <p>培训。</p>
                        </div>
                    </div>
                    <div class="tip">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/jiazheng/title-02.png" alt="">
                        <div class="content">
                            <p>专业讲师与图文配合演示，画面生动； </p>
                            <p>案例讲解/情景互动等教学方式授课，内容丰富； </p>
                            <p>教师讲解通俗易懂，专业实用； </p>
                            <p>高清视频支持多终端学习，方便易学。</p>
                        </div>
                    </div>
                </div>
                <div class="right">
                <div class="video">
                    <video
                            ref="vueMiniPlayer"
                            height="364"
                            width="646"
                            id="myvideo"
                            src="https://platform-vod.wdcloudnet.com/customerTrans/928e23e5005956f70e333d6b586f6f88/404bd1fe-179f924c91e-0005-cd1a-d1c-c3eb7.mp4"
                            :controls="controls"
                            poster="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/jiazheng/video-bg.png"
                    />
                    <div class="play" :class="{ hide: isPlay }" @click="playClick()"></div>
                </div>
                </div>
            </div>
            <div class="blue-bg"></div>
            <div class="study" @click="handleStudy">
                立即学习 >
            </div>

        </div>
    </div>
    <div class="jingpin">
        <div class="title">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/course-title.png" alt="">
        </div>
        <div class="card-box">
        <div class="card-img">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/card-img-1.png" />
        </div>
        <div class="card">
            <h5>
            增强职业意识
            </h5>
            <p>
            帮助家政服务人员增强职业意识，强化服务内功、关注细节、把控品质，得到客户的认可
            </p>
        </div>
        <div class="card-img">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/card-img-2.png" />
        </div>
        <div class="card">
            <h5>
            提升职业能力
            </h5>
            <p>
            帮助家政服务人员提升执行和解决问题能力，拥有创新意识和创新思维，提高自身的家政职业能力
            </p>
        </div>
        <div class="card-img">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/card-img-3.png" />
        </div>
        <div class="card">
            <h5>
            提高职业修养
            </h5>
            <p>
            帮助家政服务人员了解现代家政服务行业，了解职业范畴，形成饱满的工作热情、积极的心态和高度责任感
            </p>
        </div>
        <div class="card-img">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/card-img-4.png" />
        </div>
        <div class="card">
            <h5>
            规范职业礼仪
            </h5>
            <p>
            帮助家政服务人员提升内在气质、外在礼节，得到客户的喜爱和尊重
            </p>
        </div>
        <div class="card-img">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/card-img-4.png" />
        </div>
        <div class="card">
            <h5>
            融入齐鲁文化与生活
            </h5>
            <p>
            帮助家政服务人员了解齐鲁文化和生活，更好的做好家政工作
            </p>
        </div>
        </div>
    </div>
    
    <div class="special">
        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/jiazheng/special.png" alt="">
    </div>
    <div class="step">
        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/jiazheng/step.png" alt="">
    </div>

 </div>
</template>

<script>
export default {
    name:'familyList',
    props: {
    },
    data () {
        return {
            domKey:"",
            isPlay: false,
            controls: false,
        };
    },
    methods: {
        playClick(){
            this.isPlay = !this.isPlay;
            this.$refs.vueMiniPlayer.play();
            this.controls = true;
        },
        handleStudy(){
            if (localStorage.getItem('token')) {
                // 跳转学习中心
                this.$api.home.getJiazhengExam().then((res) =>  {
                    if(res.success){
                        if(res.data.studyTrainId){
                            // sessionStorage.setItem('dy-mode', '1');
                            sessionStorage.setItem('jzTrainInfo', JSON.stringify(res.data));
                            this.$router.push(`/course/catalog?id=${res.data.studyTrainId}&dymode=1`);
                        }else {
                            this.$message.warning('账号未开通课程');
                        }

                    }
                })
            }else{
                this.$router.push({
                    path: '/login',
                    query: {
                        name: 'jiazheng_homepage'
                    }
                })
            }
        }
    },
}

</script>
<style lang='stylus' scoped>
    @import "../../asset/css/family.styl"
</style>
