import { render, staticRenderFns } from "./courseDetailsAside.vue?vue&type=template&id=aa0c58d2&scoped=true&"
import script from "./courseDetailsAside.vue?vue&type=script&lang=js&"
export * from "./courseDetailsAside.vue?vue&type=script&lang=js&"
import style0 from "./courseDetailsAside.vue?vue&type=style&index=0&id=aa0c58d2&lang=stylus&scoped=true&"
import style1 from "./courseDetailsAside.vue?vue&type=style&index=1&id=aa0c58d2&lang=stylus&scoped=true&"
import style2 from "./courseDetailsAside.vue?vue&type=style&index=2&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa0c58d2",
  null
  
)

export default component.exports