<template>
 <div class='sdjzmh_homepage'>
     <div class="button-box">
        <div class="box">
            <p class="item" @click="gotraining">培训系统</p>
            <p class="item" @click="$router.push('/credit')">信用系统</p>
            <el-dropdown @command="goPage">
                <span class="el-dropdown-link">
                    管理系统
                </span>
                <el-dropdown-menu slot="dropdown" >
                    <el-dropdown-item command="pc">PC端</el-dropdown-item>
                    <el-dropdown-item command="h5">移动端</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <!-- <p class="item" @click="$router.push('/management')">管理系统</p> -->
        </div>
    </div>
    <el-carousel :autoplay="true">
      <el-carousel-item v-for="(item, index) in imgList" :key="index" arrow="never" >
        <img :src="item.imgUrl" class="bannerImg" alt="">
      </el-carousel-item>
      <img :src="imgHeight" class="toolImg" alt="">
    </el-carousel>
 </div>
</template>

<script>
export default {
    name:'zhaohumh_homepage',
    data () {
        return {
          imgList: [
            {
              imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/banner1.jpg'
            },{
              imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/banner2.jpg'
            },{
              imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/banner3.jpg'
            }
          ],
          imgHeight: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/banner1.jpg'
        };
    },

    methods: {
        gotraining(){
            const arr = ['testing1','testing2','testing3','fat','uat'];
            const hostname = window.location.hostname.split('.')[2]
            if(arr.includes(hostname)){
                window.open(`https://sdjz.saas-pc.${hostname}.wdeduc.com/sdjz_homepage`)
            }
        },
        goPage(command){
            console.log(command,'----')
            if(command === 'pc'){
                window.open('http://qiye.jiafuyun.com')
            }else {
                this.$router.push('/management')
            }
        }
    },  

    mounted(){
        
    },
}

</script>
<style lang='stylus' scoped>
/deep/.main
    padding-top 0!important;
/deep/.el-dropdown-menu__item{
    width 284px
    box-sizing border-box
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 25px;
    text-shadow: 0px 2px 7px rgba(0, 0, 0, 0.06);
    text-align center
    height 70px
    line-height 70px
    &:hover{
        color #D3411D
        background-color transparent    
    }
}
    // 兼容谷歌
.sdjzmh_homepage {
    position relative
    width 100%
    height 100vh
    overflow hidden
    .toolImg{
        width: 100%;
        opacity: 0;
    }
    .bannerImg{
        width 100%
        height 100vh
        position: relative;
        top: 50%;
        object-fit cover
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .button-box{
        position absolute
        bottom 30%
        z-index 999
        width 100%
        text-align center
        .box{
            width 1200px
            margin 0 auto
            text-align center
            display flex
            justify-content space-between
            .item{
                width: 283px;
                height: 97px;
                background: rgba(211, 65, 29, .8);
                border-radius: 8px;
                font-size: 24px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height 97px 
                cursor pointer
            }   
        }

    }
    /deep/.el-dropdown{
        width: 283px;
        height: 97px;
        background: rgba(211, 65, 29, .8);
        border-radius: 8px;
        line-height 97px 
        .el-dropdown-link{
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
        }
        
    }
  /deep/.el-carousel__indicators .el-carousel__indicators--horizontal {
    bottom: 80px!important;
  }
  /deep/.el-carousel--horizontal{
      width 100%
      height 100vh
  }
  /deep/.el-carousel__container{
    width 100%
    height 100vh
    overflow hidden
    img {
      width: 100%!important;
      vertical-align middle
    }
  }
  /deep/.el-carousel__item{
      height 100vh
  }
  /deep/.el-carousel__indicators--horizontal {
    bottom: 23px;
    // display: none;
    .el-carousel__indicator--horizontal {
      padding: 12px 10px;
      .el-carousel__button {
        width: 9px;
        height: 9px;
        border-radius: 50%;
      }
    }
  }
  
}
</style>