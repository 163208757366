<template>
    <div class='examsheetBox'>
        <div class="examsheetBox-title">
            答题卡
        </div>
        <div class="examsheetBox-content">
            <div v-for="(item,index) in list" :key="index" class="examsheetBox-content-firstlayer"> 
                <div class="examsheetBox-content-title">
                    {{item.type==1?'单选题':item.type==2?'多选题':'判断题'}}
                </div>
                <ul class="_clear">
                    <li v-for="(childitem,childindex) in item.childlist" :key="childindex" :class="childitem.name=='2'?'active':''">
                        {{childindex+1}}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'examsheet',
    data () {
        return {
            list:[
                {
                    type:1,
                    childlist:[
                        {
                            name:'2',
                        },
                        {
                            name:'1',
                        }
                    ]
                },
                {
                    type:2,
                    childlist:[
                        {
                            name:'1',
                        },
                        {
                            name:'1',
                        }
                    ]
                },
                {
                    type:3,
                    childlist:[
                        {
                            name:'1',
                        },
                        {
                            name:'1',
                        }
                    ]
                },
               
            ]
        };
    },

    methods: {},

    mounted(){},
}

</script>
<style lang='stylus' scoped>
    @import "../../asset/examresults.styl"
</style>