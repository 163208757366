<template>
    <div class="gx-online-page">
        <div class="online-banner-wrap">
            <!-- <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/online-bg.png" /> -->
            <div class="banner"></div>
            <div class="flex lr-flex info custom-block">
                <div class="item">
                    <p class="title custom-title">真实实验环境</p>
                    <p class="custom-text">线上真实实训平台</p>
                    <p class="custom-text">独享完整实验环境</p>
                </div>
                <div class="item">
                    <p class="title custom-title">详细实验指导</p>
                    <p class="custom-text">实战案例指导手册</p>
                    <p class="custom-text">手把手教你做项目</p>
                </div>
                <div class="item">
                    <p class="title custom-title">丰富实验项目</p>
                    <p class="custom-text">精心打造丰富的实验</p>
                    <p class="custom-text">项目，涵盖AI全领域</p>
                </div>
                <div class="item">
                    <p class="title custom-title">快速上手</p>
                    <p class="custom-text">动手实操训练，快速掌</p>
                    <p class="custom-text">握技能，轻松入职名企</p>
                </div>
            </div>
        </div>
        <div class="blank-img-wrap custom-block">
            <!-- <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/online-blank-bg.png" class="blank-bg-img"/> -->
            <div class="ctx-wrap">
                <!-- <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/online-blank.png" class="blank-img"/>
                <p class="btm-txt">
                    正在建设中，敬请期待……
                </p> -->
                <!-- <div class="type">
                    <label>难易程度：</label>
                    <div :class="['tab', tabId == item.id ? 'active' : '']" @click="tabChange(item.id)" v-for="item in typeList" :key="item.id">
                        <h4>{{item.type}}</h4>
                        <p class="botLine"></p>
                    </div>
                </div> -->

                <div class="title">
                    <span class="name">在线实训</span>
                    <span class="link" v-if="theme !== ''">想要获取更多实训，请购买课程<router-link to="/gongxinDetails">查看课程>></router-link></span>
                </div>
                <div class="card" v-if="cardList.length>0">
                    <el-row>
                     <el-col :span="6" v-for="item in cardList" :key="item.id">
                        <div class="card-item"  @click="gotoDtail(item.courseId,item.labId)">
                                <img :src="item.drillCover" alt="item.drillName">
                                <div class="card-con">
                                    <p class="title custom-title">{{item.drillName}}</p>
                                    <span class="name custom-text"></span>
                                    <span class="mold"></span>
                                    <div class="time"></div>
                                    <div class="cost custom-text">免费</div>
                                </div>
                                <!-- <div class="card-hover">
                                    <div class="title2">{{item.title2}}</div>
                                    <div :class="['btn', item.start==1?'btn-true':'btn-false']" @click="startNow(item.id)">立即开始</div>
                                </div> -->
                        </div>
                     </el-col>
                    </el-row>
 
                </div>
                <div v-if ='noData' class="no-data">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/no-training.png"/>
                    <p>暂无实训内容</p>
                </div>
            </div>
            
        </div>
        
       
        <footers v-if="theme === 'gongxin'"/>
        <weidongFooter v-else/>
    </div>
</template>
<script>
import footers from '../block/front/footers.vue'
import weidongFooter from '../../weidong/page/weidongFooter'
export default {
    name: 'onlineTraining',
    components: {
        footers,
        weidongFooter
    },
    computed:{
        theme(){
            return this.$store.state.theme
        }
    },
    data() {
        return{
            noData:false,
            cardList:[],
            typeList:[
                {
                    id: 1,
                    type: '全部'
                },{
                    id: 2,
                    type: '简单'
                }
            ],
            tabId: 1
        }
    },
    created(){
        this.getDrillListToC()
    },
    methods: {
        gotoDtail(courseId,labId){
            // this.$router.push({
            //     path:'/training_detail',
            //     query:{
            //         courseId,
            //         labId
            //     }
            // })
            let routeData = this.$router.resolve({
                path:'/training_detail',
                query:{courseId,labId}
            })
            window.open(routeData.href, '_blank')


        },
        getDrillListToC(){
             this.$api.training.getDrillListToC({}).then(res => {
                 console.log('res-----2222', res)
                 this.cardList = res.data
                 if(this.cardList.length < 1){
                     this.noData = true
                 }
            })
        },
        startNow(id) {
            if (localStorage.getItem('token')) {
                if (id == 1) {
                    window.open("https://www.lanqiao.cn/courses/3675/learning/?id=94761", "_blank");
                } else if (id ==2) {
                    window.open("https://www.lanqiao.cn/courses/3675/learning/?id=94694", "_blank");
                }
            } else {
                this.$router.push({
                    path: '/login',
                    query: {
                        name: 'online_training',
                    }
                })
            }
        },
        tabChange(id) {
            this.tabId = id
        }
    }
}
</script>
<style lang="stylus" scoped>
@import "../asset/css/onlinetrain.styl"

</style>