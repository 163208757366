<template>
  <div class="videopage">
    <div class="topbox">
      <span @click="back" class="totalback">
        <i class="back el-icon-arrow-left"></i>
        <!-- <img class="back" src="../asset/images/back.png"> -->
        <span class="backtext">返回</span>
      </span>
      <div class="smallLine"></div>
      <span class="tranincoursename">{{getCourseName()}}</span>
    </div>
    <div class="total videoBox">
    <div class="menu" :style="{ width: isCollapse == true ? '330px' : '0px' }">
      <el-tree
            :data="CourseTreelist"
            :props="defaultProps"
            accordion
            highlight-current
            :default-expanded-keys="expandedKeys"
            node-key="id"
            ref="tree"
            class="wdc-select-tree"
            @node-click="handleNodeClick"
            @node-expand="nodeExpandClick"
            :style="{ marginLeft: isCollapse == true ? '0px' : '-500px' }"
        >
            <span class="custom-tree-node" slot-scope="{ node, data }" >
              <img v-if="data.type == 0 && data.parentId == 0 && node.expanded" class="longline"  src="../asset/images/longline.png" alt="">
                <span :title="node.label" class="dy-label" :class="{dyActive:resourcesId == data.detailId}" :style="{marginLeft: data.parentId == 0 && data.childrenList && data.childrenList.length >0 ? '0px' : '20px' }">
                    <img v-if="data.type != 0" class="look"  :src="getLookImg(data)" alt="">
                    <img v-if="data.type === 1" class="icon-label"  src="../asset/images/video.png" alt="">
                    <img v-if="data.type === 2" class="icon-label"  src="@assets/course/编组 2.png" alt="">
                    <img v-if="data.type === 10" class="icon-label"  src="@assets/course/icon-lx@2x.png" alt="">
                    <img v-if="data.type === 11" class="icon-label"  src="@assets/course/icon-zb@2x.png" alt="">
                    <img v-if="data.type === 12" class="icon-label"  src="@assets/course/icon-sx@2x.png" alt="">
                    {{ node.label }}
                    
                </span>
                <img v-if="data.lock" src="@assets/learning/lock-icon2.png" class="lock-icon" alt="">
            </span>
        </el-tree>
    </div>
    <div class="bigline"></div>
    <div class="video" id="videobox">
      <div class="top">
        <div class="title">
          <img
            class="choose"
            :label="true"
            @click="choose"
            :src="getArrowImg()"
          />
          <span class="coursename">{{coursenametext}}</span>
        </div>
        <button class="next" @click="findNext()">下一项</button>
      </div>
      <div
        class="container"
        id="player-con"
        style="height: calc(100vh - 153px)"
        v-if="this.type == 1 || this.type == 2"
      >
        <!-- 音频波形图 -->
        <img
          v-if="this.type == 2"
          class="music"
          src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/music.png"
        />
      </div>

      <div v-if="this.type == 3" id="ifram">
        <!-- pdf -->
        <!-- <iframe 
          v-if="pdfurl.includes('.pdf')"
          :src="
            'static/pdfjs/web/viewer.html?file=' + pdfurl + '&page=' + pdfPage
          "
          style="width: 100%; height: calc(100vh - 153px)"
        /> -->
         <div id="Vidpreview" ref="Vidpreview" style="width: 100%;height: calc(100vh - 163px)"></div>

      </div>

      <div v-if="this.type == 4" class="imgbox" id="imgContent">
        <!-- <img class="img" :src="videoInfo.url" /> -->
       <img :src="videoInfo.url" class="img" />

      </div>
    </div>
    <el-dialog
    class="videoDialog"
      :visible.sync="dialogTableVisible"
      :close-on-click-modal="false"
      @close="closedialog"
    >
      <img src="../asset/images/prohibit.png" />
      <div class="tip">提示</div>
      <div class="text">学习时禁止拖放进度！</div>
      <button class="btn" @click="btnclosedialog(1)">知道了</button>
    </el-dialog>
    <el-dialog
      class="alertDialog"
      :visible.sync="continueDialogVisible"
      :close-on-click-modal="false"
      @close="closedialog"
    >
      <img src="../asset/images/prohibit.png" />
      <div class="text">{{this.TrainBasicInfo.alertWindowTip}}</div>
      <button class="btn" @click="btnclosedialog(2)">我在，继续学习</button>
    </el-dialog>
    </div>
    <facedialog v-if="verification" :address="verificationAddress" :failAddress="verificationFailAddress" :pauseAddress="verificatioPpauseAddress" :type=1></facedialog>
  </div>
</template>

<script>
import facedialog from '@/layout/facedialog.vue'
import $ from 'jquery'
export default {
  name: 'video',
  components: {
    facedialog
  },
  data () {
    return {
        CourseTreelist:[],
        resourcesId:'',
        expandedKeys:[],
        resourcesList:[],//所有资源
        resourcesInfo:null,//当前播放的资源
        lastData:{},
        coursenametext:'',
        defaultProps: {
            children: "childrenList",
            label: "name"
        },
        activeIndex:'',
        type: -1,
        detailId: -1, // 路由跳转时传入的id
        isCollapse: true,
        WDuserInfo: {},
        isfaceauth: false,
        isfacedialog: console.log('不能'),
        activeNames: ['1'],
        changetype: '1',
        description: {
            pic: ''
        },
        canPlay:false,
        descriptionImg: '',
        catelogList: [],
        allresouce: [],
        courseUrl: [],
        params: {
            courseId: 85,
            taskId: 1
        },
        TrainBasicInfo: {
          isAlertWindow:0,
        },
        contentType: [
            { name: '简介', id: '0' },
            { name: '目录', id: '1' }
        ],
        is: true,
        videoInfo: {
            video: null,
            url: [],
            source:{},
            deadline: 0,
            contTime: 0,
            clarity: 0,
            index: 0,
            playPlayer: false,
            isDeadline: true,
            detect: true,
            IosInif: true
        },
        currentlyPlay: {},
        hasvideo: false,
        hasresourceURl: false,
        currentvideo: null,
        courseInfo: {
            id: 1,
            name: '课程',
            intro: ''
        },
        currentCourseId: null,
        IntervalName: null,
        timeoutDialogAlert: null,
        player: null,
        detailparam: {
            id: null,
            taskId: null
        },
        currentCourse: {},
        // 总章
        first: 0,
        // 大章
        second: 0,
        // 小章
        three: 0,
        // 节
        four: 0,
        pdfurl: '',
        pdfPage: '',
        saveLearningParams: {
            recordId: '',
            detailId: '',
            pollingTime: '3',
            curSecond: ''
        },
        name: '',
        pdfIntervalName: null,
        openImg: require('../asset/images/open.png'),
        videoImg: require('../asset/images/video.png'),
        dialogTableVisible: false,
        continueDialogVisible: false,
        currentposition:null,
        allPlayEndIdList:[],
        alertTime: null,
        verification: false,
        verificationAddress: {},
        verificationFailAddress: {},
        verificatioPpauseAddress: {},
        validationResults: false,
        result: null,
        fileUrl:'',
        app:null,
        demo:null
    }
  },
  async mounted () {
    this.detailId = this.$route.query.detailId || ''
    if(this.$route.query.taskType == 2) {
        this.findfaceNeed();// 培训任务规则
    }else {
        this.findTrainBasicInfoById() // 训练营 返回规则接口
    }
    await this.findCourseItemByCourseId()
    //监听当前页改变事件。
    if(this.app){
        this.app.Sub.CurrentPageChange = this.eventHandle
    }
    // this.$nextTick(async () => {
    //     await this.getaliPlay()
    // })
    // this.$nextTick(async () => {
    //    await this.getaliPlay()
    // })
    // await this.pdfTiming()
  },
  watch: {
    // 是否已进行人脸验证
    'result'(val){
      if (val==true) {
        this.validationResults = val;
        this.player.play();
      }
    },
  },
  methods: {
    // 不加载人脸验证
    // notLoad(val) {
    //   this.verification = val;
    // },
    // 获取实训详情
    getdrillDetail(id){
        this.$api.learning.getdrillDetail({id}).then(res => {
            if(res.data){
                window.open(window.location.origin + `/training_detail?courseId=${res.data.courseId}&labId=${res.data.labId}`)
            }
        })
    },
    getCourseName(){
      return this.$route.query.courseName
    },
    getArrowImg(){
     if(this.isCollapse){
       return "/static/images/right.png"
     }{
       return "/static/images/left.png"
     } 
    },
    back(){
    //   this.$router.go(-1)
    
        if(this.$route.query.taskType == 2){ // 课程详情
            this.$router.push({
                path:this.$route.query.backPathName,
                query:{
                    id:this.$route.query.courseId,
                    taskId:this.$route.query.trainId,
                    taskType:this.$route.query.taskType || null,
                    backMoudle:this.$route.query.backMoudle
                }
            })
        }else {// 训练营详情
            this.$router.push({
                path:this.$route.query.backPathName,
                query:{
                    taskId:this.$route.query.trainId,
                    backMoudle:this.$route.query.backMoudle,
                    taskType:this.$route.query.taskType || null,
                }
            })
        }
        
    },
    getLookImg(data){
    //   console.log("树的data",data)
      if(data.studyInfo && data.studyInfo.studyProcess !=0){
        return "/static/images/look.png"
      }else{
        return "/static/images/notlook.png"
      }
    },
    // 点击下一项
    async findNext(){
      // this.resourcesList.forEach(async (item,index) => {
      //       if(item.detailId === this.resourcesInfo.detailId){
      //         var lastData =  this.resourcesList[index]
      //        var pro= (Math.floor(this.videoInfo.video.currentTime)/this.player.getDuration())*100
      //        console.log("pro",pro)
      //         if(pro > lastData.lockCourseRate){
      //           await this.postCourseLearningLogSaveLog()
      //         }
      //       }
      //   })
      //   await this.findCourseItemByCourseId()//获取最新目录
        let info = null
        this.resourcesList.forEach((item,index) => {
            if(item.detailId === this.resourcesInfo.detailId){
               info =  this.resourcesList[index+1]
            }
        })
        // console.log(info,'info')
        if(info){
            this.expandedKeys = [info.id]
            this.handleNodeClick(info)
        }
        if(info == undefined){
          this.$message({
            showClose: true,
            message: '已经是最后一个内容了',
            type: 'warning'
          });
        }
        // this.detailId = info.detailId
    },
    //   单个点击获取资源
    async handleNodeClick(data) {
        // console.log('data内容',data,node,zxc)
        // this.updateChildren(node)
        // console.log("updateChildren")
        // this.$forceUpdate()
        // this.resourcesList.forEach(async (item,index) => {
        //     if(item.detailId === this.resourcesInfo.detailId){
        //       var lastData =  this.resourcesList[index]
        //       if(this.videoInfo){
        //         var pro= (Math.floor(this.videoInfo.video.currentTime)/this.player.getDuration())*100
        //         //  console.log("pro",pro)
        //         if(pro > lastData.lockCourseRate){
        //             await this.postCourseLearningLogSaveLog()
        //         }
        //       }
              
        //     }
        // })
        if(data.type != 0 && data.lock){
            this.postCourseLearningLogSaveLog();// 切换之前保存进度
            await this.findCourseItemByCourseId()//获取最新目录
            // 是最新的目录里面更新后的数据
            this.resourcesList.forEach((item) => {
                if (item.detailId == data.detailId) {
                    data = item;
                }
            })
        }
        

        
        
        if(this.$route.query.taskType == 1 && (data.type === 11 || data.type === 12)){
            this.$message.warning('无内容，无法学习')
            return false;
        }
        if(data.lock){
          console.log("data.type",(data.type))
          this.$message.warning(`该内容未开启，开启条件为前面的内容学习进度达到 ${data.lockCourseRate}%`)
          console.log(data.lock,data.lockCourseRate,"%")
          return;

        }
        if(data.type !== 0) {
            this.resourcesId = data.detailId
            this.resourcesInfo = data
            this.videoInfo.fastForward = false
        }
        this.resourcesPlay(data)
        this.updateProgress(this.CourseTreelist)
        this.$forceUpdate()
    },
    nodeExpandClick(data,node){
      this.updateChildren(node)
    },
    updateChildren(list){
      if(list.childNodes.length > 0){
            list.childNodes.forEach((item)=>{
              item.expanded=true
              this.updateChildren(item)
      })
      }
    },
    // 资源播放
    async resourcesPlay(data){
      console.log("资源播放data",data)
        
        if(data.type == 0){
          return
        }else if(data.type){
          this.type = data.type;
        }
        if(this.TrainBasicInfo.isTrainTimePassed == 1){
          this.$message({
            showClose: true,
            message: '尚未开始哦',
            type: 'warning'
          });
          return
        }
        if(data.lock){
          return
        }
        if(data.type !== 0) {
            this.coursenametext = data.name
        }
        // console.log(data,'data.type---')
        
        //0 目录；1，视频；2，音频；3，文档；4，图片；6，scorm；9，试卷；10，练习；11，直播；12，实锤
        if (data.type == 1 || data.type == 2) {
            // 视频
            this.getVideoPlayURLById(data)
            this.clearPdfTimeing()
            if (data.type == 2) {
              this.courseClearTimeing()
            }
        } else if (data.type == 3) {
            // pdf
            this.pdfTiming()
            this.getDocumentAccessToken(data)
            this.courseClearTimeing()
            this.removePlay()
        } else if (data.type == 4) {
            // 图片
            this.getImageOrDocUrl(data)
            this.removePlay()
            this.pdfTiming()
            this.courseClearTimeing()
        } else if( data.type == 10) {
            this.saveLearningLog(10)
            this.$router.push({
                path:'/practice',
                query:{
                    practiceId:data.detailId,
                    trainId:this.$route.query.trainId,
                    courseId:this.$route.query.courseId,
                    detailId:this.$route.query.detailId,
                    studyProcess:this.$route.query.studyProcess,
                    courseName:this.$route.query.courseName,
                    taskType:this.$route.query.taskType || null,
                    pathName:this.$route.path,
                    backPathName:this.$route.query.backPathName
                }
            })
        }else if(data.type === 11){
            this.saveLearningLog(11)
            window.open(window.location.origin + `/studentSee?type=1&roomId=${data.detailId}`)
        }else if(data.type === 12){
            this.saveLearningLog(12)
            this.getdrillDetail(data.detailId)
        }
    },
    closedialog () {
      this.player.play()
    },
    btnclosedialog (num) {
      if (num==1) {
        this.dialogTableVisible = false
      } else if (num==2) {
        this.continueDialogVisible = false
      }
      this.player.play()
    },
    choose () {
      this.isCollapse = !this.isCollapse
    },
    //获取全部资源文件
    async getAllfile (courseData) {
      if (courseData && courseData.length) {
        courseData.forEach(item => {
            if(item.detailId == this.detailId){
                console.log("只会进来一次吧")
                this.expandedKeys = [item.id]
                this.resourcesId = item.detailId;
                this.coursenametext = item.name
                this.detailId=""//只有第一次拿进来要看到的视频
                //当前播放的视频一定要有进度
                item.studyInfo={
                  ...item.studyInfo,
                  "studyProcess":1
                }
                this.resourcesInfo = item
                // this.progressSetting(item)
                if(this.TrainBasicInfo.isTrainTimePassed == 1){
                  this.$message({
                    showClose: true,
                    message: '尚未开始哦',
                    type: 'warning'
                  });
                  return
                }
                this.resourcesPlay(item)
            }
          if (item.type) {
            this.resourcesList.push(item)
          } else if (item.childrenList && item.childrenList.length > 0) {
            return this.getAllfile(item.childrenList)
          }
        })
      }
    },
    //获取指定data
    updateProgress(list){
      list.forEach((item)=>{
        if(item.detailId == this.resourcesId){
          item.studyInfo = {
            ...item.studyInfo,
            "studyProcess": 1
          }
        }else if(item.type == 0){
          this.updateProgress(item.childrenList)
        }
      })
    },
    // 获取课程目录
    async findCourseItemByCourseId () {
        let params = {
            courseId: this.$route.query.courseId || '',
            taskId:this.$route.query.trainId,
            taskType:this.$route.query.taskType || null
        }
        const { data } = await this.$api.course.findCourseItemByCourseId({
            params
        })
        this.catelogList = data
        this.CourseTreelist = data;
        // 所有资源
        await this.getAllfile(data);

        
    },
    //判断视频是否防拖拽接口
    findTrainBasicInfoById () {
      const params = {
        id: this.$route.query.trainId || 1
      }
      return this.$api.training
        .findTrainBasicInfoById({ params })
        .then(async res => {
          this.TrainBasicInfo = res.data
          this.alertTime = this.TrainBasicInfo.alertTime * 60 * 1000
        })
    },
    //查询培训任务规则
    findfaceNeed() {
        return this.$api.examination.findfaceNeed(this.$route.query.trainId).then(res=>{
            this.TrainBasicInfo = res.data
            this.alertTime = this.TrainBasicInfo.alertTime * 60 * 1000
        })
    },
    // 获取视频播放地址
    async getVideoPlayURLById (data) {
        const params = {
            resId: data.detailId
        }
        this.is = true
        return this.$api.resource.getPlayAuthToken({ params }).then(res => {
          this.videoInfo.source = res.data
          this.getaliPlay()
          
        })
    },
    // 获取文档
    async getDocumentAccessToken(data){
        
        const params = {
            resId:data.detailId
        }
        return this.$api.resource.getDocumentAccessToken({params}).then(res => {
            if(res.data){
                this.getSourseDetail(data)
                this.getTotal(res.data,data)
            }
            
        })
    },
    getSourseDetail(data){
        const params = {
            id:data.detailId
        }
        this.$api.course.getSourseDetail({params}).then(res => {
            if(res.data){
                this.fileUrl = res.data.fileUrl
            }
        })
    },
    async getTotal(data,item){
        localStorage.removeItem('page')
        localStorage.removeItem('totalpage')
        // 下边是安全才有的
        // eslint-disable-next-line no-undef
        this.demo = aliyun.config({
            mount: document.querySelector('#Vidpreview'),
            url: data.previewURL //设置文档预览URL地址。
        })
        // //设置AccessToken。
        this.demo.setToken({token: data.accessToken});
        await this.demo.ready()
        // console.log(this.fileUrl.includes('.ppt'))
        //文档类型为文字类型。
        // const wordApp = demo.WordApplication()
        // //文档类型为表格类型。
        // const excelApp = demo.ExcelApplication()
        //文档类型为演示类型。
        // const pptApp = demo.PPTApplication()
        //文档类型为PDF类型。
        // const pdfApp = demo.PDFApplication()
        //自动识别文档类型。
        this.app = this.demo.Application
        const {Enum} = this.app
        if(this.fileUrl.includes('.ppt') || this.fileUrl.includes('.pptx')){
            let totalPagesz = await this.demo.PPTApplication().ActivePresentation.Slides.Count
            localStorage.setItem('page',totalPagesz)
            localStorage.setItem('totalpage',totalPagesz)
            /***
             * 
             * 当前页数必须是切换生效，滚动页面不生效
             */
            // let totalPages = await demo.PPTApplication().ActivePresentation.SlideShowWindow.View.Slide.SlideIndex 
            /*
            * 跳转指定页
            * @param: number
            */
            // await demo.PPTApplication().ActivePresentation.SlideShowWindow.View.GotoSlide(3)
        }else if(this.fileUrl.includes('.pdf')){
            let totalPages = await this.demo.PDFApplication().ActivePDF.PagesCount
            localStorage.setItem('page',totalPages)
            localStorage.setItem('totalpage',totalPages)
            /*
            * 获取当前呀
            * @return: number
            */
            // let totalPages = await demo.PDFApplication().ActivePDF.CurrentPage
            /*
            *  跳转到相应页
            * @param : { PageNum: number }
            */
            //  let PageNum = 10
            // await demo.PDFApplication().ActivePDF.JumpToPage({PageNum})
        }else if(this.fileUrl.includes('.doc') || this.fileUrl.includes('.docx')){
            let totalPages = await this.app.ActiveDocument.Range.Information(Enum.WdInformation.wdNumberOfPagesInDocument)
                
            if (totalPages.End) {
                console.log("加载完了！一共", totalPages, "页")
                localStorage.setItem('page',totalPages.PagesCount)
                localStorage.setItem('totalpage',totalPages.PagesCount)
            }else {
                localStorage.setItem('page',item.duration == totalPages.PagesCount ? totalPages.PagesCount : item.duration)
                localStorage.setItem('totalpage',item.duration == totalPages.PagesCount ? totalPages.PagesCount : item.duration)
            }
            /*
            * 获取当前呀
            * @param: WdInformation: {
            *      wdActiveEndPageNumber: 3
            *  }
            * @return: number
            */
            // let currentPage = await app.ActiveDocument.Selection.Information(Enum.WdInformation.wdActiveEndPageNumber)
            /*
            * 跳转指定
            * @param: { What?: WdGoToItem, Which?: WdGoToDirection.wdGoToAbsolute, Count?: number, Name?: string}
            * WdGoToItem: {
            *      wdGoToPage: 1,
            *  }
            *  WdGoToDirection: {
            *      wdGoToAbsolute: 1
            *  }
            */
            // await app.ActiveDocument.Selection.GoTo(Enum.WdGoToItem.wdGoToPage, Enum.WdGoToDirection.wdGoToAbsolute, 10)
            // //或者使用如下代码。
            // await app.ActiveDocument.Selection.GoTo({
            //     What: Enum.WdGoToItem.wdGoToPage,
            //     Which: Enum.WdGoToDirection.wdGoToAbsolute,
            //     Count: 10
            // })
        }else if(this.fileUrl.includes('.xls') || this.fileUrl.includes('.xlsx')){
            const Names = []
            //For(start, end, step, handle)
            await this.app.For(1, this.app.Sheets.Count, 1, async (Index) => {
                Names.push(await this.app.Sheets.Item(Index).Name)
            })
            localStorage.setItem('page',Names.length == item.duration ? Names.length:item.duration)
            localStorage.setItem('totalpage',Names.length == item.duration ? Names.length:item.duration)
            // 获取当前sheet名称
            // await demo.ready()
            // const app = demo.ExcelApplication()
            // const name = await app.ActiveSheet.Name
            // console.log('ActiveSheet:', name)
            // 切换到指定sheet
            // await demo.ready()
            // const app = demo.ExcelApplication()
            // const sheetIndex = 1 //sheets序号，从1开始。
            // app.Sheets.Item(sheetIndex).Activate() //切换sheet。
        }
        // console.log(app,'app----')
    },
    // 获取图片文档
    async getImageOrDocUrl (data) {
      const params = {
          id:data.detailId
      }
        return this.$api.resource.getImageOrDocUrl({params}).then(res => {
            if (res.data) {
                 this.videoInfo.url = res.data
            } 
            // this.videoInfo.url = res.data
            // if (data.type == 3) {
            //     this.pdfurl = res.data
            //     this.pdfPage =
            //     (data &&
            //         data.studyInfo &&
            //         data.studyInfo.playLength) ||
            //     1
            // }
        })
    },
    async progressSetting (dataInfo) {
      if (dataInfo && dataInfo.studyInfo) {
        const deadline = (this.player.getDuration() || dataInfo.duration || 0) * ((dataInfo.studyInfo.learnRate || 0) * .01)
        this.videoInfo.deadline = deadline >= (this.player.getDuration() || dataInfo.duration || 0) ? this.player.getDuration() : deadline
        if (dataInfo.studyInfo.playLength) {
          this.videoInfo.video.currentTime = dataInfo.studyInfo.playLength
        } else {
          this.videoInfo.video.currentTime = (this.videoInfo.deadline >= (this.player.getDuration() || dataInfo.duration || 0) ? 0 : this.videoInfo.deadline || 0)
        }
        // if(dataInfo.studyInfo.playLength > this.videoInfo.deadline + 1){
        //   console.log("dataInfo.studyInfo.playLength",dataInfo.studyInfo.playLength)
        //   console.log("this.videoInfo.deadline",this.videoInfo.deadline)
        //   this.canPlay=true
        //   return
        // }
      }
    },
    removePlay () {
      var $ = require('jquery')
      if ($('#J_prismPlayer').length > 0) {
        this.player.dispose()
        $('#J_prismPlayer').remove()
      }
    },
    //观看课程信息保存
    async postCourseLearningLogSaveLog () {
      // if (!this.videoInfo.video) {
      //   return;
      // }
      const data = {
        courseId: this.$route.query.courseId || '',
        taskId: this.$route.query.trainId || '',
        taskType:this.$route.query.taskType || null,//1：任务；2：训练营
        courseItemId: this.resourcesInfo.id,
        cur: this.videoInfo.video.currentTime,
        max:
          this.player && this.player.getDuration()
            ? this.player.getDuration()
            : this.resourcesInfo &&
              this.resourcesInfo.studyInfo &&
              this.resourcesInfo.studyInfo.learnRate
            ? this.resourcesInfo.studyInfo.learnRate
            : 1,
        pollingTime: 5,
        
      }
      return await this.$api.course
        .postCourseLearningLogSaveLog({ data })
        .then(res => {
          console.log(res)
        })
    },
    //记录用户学习时长
    async postUserLearningLog () {
      // if (!this.videoInfo.video) {
      //   return;
      // }
      const data = {
        learningLength: 5
      }
      await this.$api.course.postUserLearningLog({ data })
    },
    // 清除课程定时器
    async courseClearTimeing () {
      if (this.courseIntervalName) {
        clearInterval(this.courseIntervalName)
        this.courseIntervalName = null
      }
    },
    // 课程定时器
    async courseTiming () {
      if (this.courseIntervalName) {
        clearInterval(this.courseIntervalName)
      }
      this.courseIntervalName = setInterval(async () => {
        if (this.$route.name === 'video') {
          this.postCourseLearningLogSaveLog()
          this.postUserLearningLog()
        }
      }, 5000)
      return this.courseIntervalName
    },
    async getaliPlay () {
      var $ = require('jquery')
      $('#player-con').height('calc(100vh - 153px)')
      if ($('#J_prismPlayer').length > 0) {
        this.player.dispose()
        $('#J_prismPlayer').remove()
      }
      const boarddiv = '<div id="J_prismPlayer"></div>'
      $('#player-con').append(boarddiv)
      $('#J_prismPlayer').height('100%')
      // eslint-disable-next-line no-undef
    //   var source = ''
    console.log(this.coursenametext.indexOf('m3u8'))
      let format = ''
      let mediaType = ''
    //   if (this.type == 1) {
    //     // format = 'm3u8';
    //     // source = `{"LD": "${this.videoInfo.url[0]}","SD": "${this.videoInfo.url[1]}"}`
    //   } else 
      if (this.type == 2) {
        format = 'mp3'
        // source = this.videoInfo.url[0]
        mediaType = 'audio'
      }
      this.videoInfo.deadline = 0
      let m3u8Plugin = {
          id: 'J_prismPlayer',
          vid : this.videoInfo.source.videoMeta.videoId,
          playauth : this.videoInfo.source.playAuth,
          cover: this.videoInfo.source.videoMeta.coverURL,
          width: '100%',
          height: '500px',
          seek: 10000,
          autoplay: true,
          preload: false,
          isLive: false,
          useH5Prism: true,
      }
      let plugin = {
          id: 'J_prismPlayer',
          vid : this.videoInfo.source.videoMeta.videoId,
          playauth : this.videoInfo.source.playAuth,
          cover: this.videoInfo.source.videoMeta.coverURL,
          width: '100%',
          height: '500px',
          seek: 10000,
          autoplay: true,
          preload: false,
          isLive: false,
          useH5Prism: true,
          format: format || 'm3u8',
          mediaType: mediaType || 'video',
      }
    /* eslint-disable */
      this.player = new Aliplayer(this.coursenametext.indexOf('m3u8') !== -1 ? m3u8Plugin : plugin)
      /* eslint-disable */
      this.player.on('play', async () => {
        if (
          (+this.$route.query.studyProcess || 0) < 100 &&
          this.$route.name === 'video'
        ) {
          if (this.timer) {
            clearInterval(this.timer);
          }
          if(this.TrainBasicInfo.isAlertWindow === 1){
            console.log(999999999999999);
            console.log(this.alertTime);
              this.timer = setInterval(() => {
                console.log(77777777777);
                  this.player.pause()
                  this.continueDialogVisible=true;
                  clearInterval(this.timer);
              }, this.alertTime)
          }
        }
        // 判断是否需要人脸验证
        // if (this.TrainBasicInfo.isFaceRecognize && !this.validationResults) {
        //   this.player.pause();
        //   this.verification = true;
        //   this.verificationAddress = {};
        //   this.verificationFailAddress = {};
        //   this.verificatioPpauseAddress = {
        //     path:'/course/catalog',
        //     query:{
        //       id:this.$route.query.trainId
        //     }
        //   }
        // }
        this.videoInfo.fastForward = false
        if(this.resourcesInfo.studyInfo && this.resourcesInfo.studyInfo.learnRate < 100){
          this.progressSetting(this.resourcesInfo)
        }
        await this.courseClearTimeing()
        await this.courseTiming()
      })
      this.player.on('pause', async () => {
        this.is = false
        await this.courseClearTimeing()
      })
      this.player.on('ended', async () => {
        this.allPlayEndIdList.push(this.resourcesInfo.detailId)
        this.is = true
        this.courseClearTimeing()
        this.videoInfo.deadline = 0
      })
     
      this.videoInfo.video = document.querySelector('video')
      if (!this.videoInfo.video) {
        this.videoInfo.video = document.querySelector('audio')
      }
      if (!this.videoInfo.video) {
        return;
      }
      this.videoInfo.video.addEventListener('timeupdate', () => {
        // 视频进度发生变化
        // this.videoInfo.video.showHideControls()
        this.videoInfo.fastForward=true
        if (
          !this.videoInfo.video.seeking &&
          this.videoInfo.video.currentTime > this.videoInfo.deadline
        ) {
          this.videoInfo.deadline = this.videoInfo.video.currentTime + 3
          if (!this.resourcesInfo.studyInfo) {
            this.resourcesInfo.studyInfo = {}
          }
          let learnRate = Math.ceil(
            ((this.videoInfo.deadline || 0) /
              (this.player.getDuration() || 0)) *
              100
          )
          learnRate = learnRate > 100 ? 100 : learnRate
          this.resourcesInfo.studyInfo.learnRate =
            learnRate >= this.resourcesInfo.studyInfo.learnRate
              ? learnRate || 0
              : this.resourcesInfo.studyInfo.learnRate || 0
          this.resourcesInfo.studyInfo.playLength =
            this.videoInfo.deadline >= this.resourcesInfo.studyInfo.playLength
              ? this.videoInfo.deadline - 3
              : this.resourcesInfo.studyInfo.playLength
        }
      })

      this.videoInfo.video.addEventListener('seeking', () => {
        if( this.allPlayEndIdList.indexOf(this.resourcesInfo.detailId) != -1){
            return
        }
        // if(this.studyInfo && this.studyInfo.learnRate >= 100){
        //   return
        // }
        if (
          this.resourcesInfo.studyInfo && this.resourcesInfo.studyInfo.learnRate != 100 &&
          this.$route.name === 'video'
        ) {
          if (
            this.player.getCurrentTime() > (this.videoInfo.deadline || 0) &&
            +this.TrainBasicInfo.isDraw == 1
          ) {
            this.videoInfo.video.currentTime = this.videoInfo.deadline - 3.3
            if(this.videoInfo.fastForward && this.resourcesInfo.studyInfo && this.resourcesInfo.studyInfo.learnRate != 100){
              this.player.pause()
              this.dialogTableVisible = true
            }
          }
        }
      })
      // console.log("this.getStatus",this.player.getStatus())
      // this.videoInfo.video.addEventListener('init',()=>{
      //   this.currentposition=this.resourcesInfo.studyInfo.playLength
      //   console.log("ready")
      //   console.log("this.currentposition",this.currentposition)
      //   this.player.seek(this.currentposition)
      // })
    },
    pdfTiming () {
      if (this.pdfIntervalName) {
        clearInterval(this.pdfIntervalName)
      }
      this.pdfIntervalName = setInterval(() => {
        this.saveLearningLog()
        this.postUserLearningLog()
      }, 3000)
    },
    clearPdfTimeing () {
      if (this.pdfIntervalName) {
        clearInterval(this.pdfIntervalName)
        this.pdfIntervalName = null
        // console.log("清楚定时器");
      }
    },
    saveLearningLog (type) {
      const data = {
        courseId: this.$route.query.courseId || '',
        taskId: this.$route.query.trainId || '',
        taskType:this.$route.query.taskType || null,
        courseItemId: this.resourcesInfo.id || '',
        cur: localStorage.getItem('page') || 1,
        max: localStorage.getItem('totalpage') || 1,
        pollingTime: type ? 1 : 5
      }
      this.$api.course.postCourseLearningLogSaveLog({ data }).then(() => {
        // console.log(res)
      })
      return true
    },
  },
  destroyed () {
    this.courseClearTimeing()
    this.clearPdfTimeing()
    this.player && this.player.pause()
    if($('#Vidpreview')){
        $('#Vidpreview').remove()
    }
  }
}
</script>
<style lang="less">
@import "../asset/video.less";
</style>
