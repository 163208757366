
const urlName = '/task/v1'
export default API => ({
    // 培训任务列表
    taskList({ data, params }) {
        return API({
            url: `${urlName}/client/taskList`,
            params,
            data
        });
    },
    // 培训任务详情
    taskDetail({ data, params }) {
        return API({
            url: `${urlName}/client/taskDetail`,
            params,
            data
        });
    },
    // 考试列表
    findUserExams({ data, params }) {
        return API({
            url: `/exam/v1/userExam/findUserExams`,
            params,
            data
        });
    },
    // 实训详情
    getdrillDetail(data) {
        return API({
            url: `/drill/v1/drill/drillDetail`,
            data,
            method:'post',
        })
    }, 
    
})