<template>
    <div class="sdjt-header-box">
        <div class="top">
            <div class="logo-left">
                <div class="logo">交通运输</div>
                <div class="line"></div>
                <div class="logo-mini">数字化人才发展平台</div>
            </div>
            <div class="btn-right" v-if="!token">
                <div class="stu-btn" @click="toLogin(1)">学员登录</div>
                <div class="adm-btn" @click="toLogin(2)">管理员登录</div>
            </div>
            <template v-else>
                <account :replacePage="replacePage"/>
            </template>
        </div>
        <div class="nav">
            <ul class="nav-contaniner">
                <li v-for="(nav,index) in navList" :key="index"  @click="selectNav(nav)">
                    <p :class="{active:nav == isSelect}">{{nav.name}}</p>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import URL from '../../../../config/url';
import account from './account.vue'
export default {
    name:'sdjtNav',
    components:{
        account
    },
    data(){
        return{
            navList:[],
            isSelect:'',
            token:'',
            replacePage:'/login'
        }
    },
    mounted(){
        this.getNavs()
        this.token = localStorage.getItem('token');
        const frontendConfig = localStorage.getItem('frontendConfig') ? JSON.parse(localStorage.getItem('frontendConfig')) : ''
        let path = frontendConfig ? frontendConfig.value : '';
        this.replacePage = path;
    },
    methods:{
        //登录
        toLogin(type){
            if(type==1){
                this.$router.push({
                    path: '/login',
                })
            }else{
                if(window.location.href.indexOf('.testing3.')!=-1||window.location.href.indexOf('localhost:')!=-1){
                    window.open('https://hrss-main.testing3.wdeduc.com/entrance/login')
                }else if(window.location.href.indexOf('.fat.')!=-1){
                    window.open('https://hrss-main.fat.wdeduc.com/entrance/login')
                }else if(window.location.href.indexOf('.uat.')!=-1){
                    window.open('https://hrss-main.uat.wdeduc.com/entrance/login')
                }else{
                    window.open('https://hrss-main.wdeduc.com/entrance/login')
                }
            }
        },
        // 获取导航头数据
        getNavs() {
            if (!this.navList.length) {
                this.$api.home.getMenuNew(URL.confUrl).then((res) => {
                    if (res.data.menuList) {
                        this.navList = res.data.menuList;
                        this.getActiveNav();
                    }
                })
            } else {
                this.getActiveNav();
            }
        },
        // 选中问题
        getActiveNav() {
            this.navList.forEach((d)  => {
                if (d.url === this.$route.path || this.$route.path.indexOf(d.url) !== -1) {
                    this.isSelect = d;
                }
            })
        },
        // 点击跳转
        selectNav(item) {
            console.log(item,'item')
            if (item.url.indexOf('http') === -1) {
                this.isSelect = item;
                this.$router.push({
                    path: item.url,
                });
            } else {
                window.open(item.url, 'newW');
            }
        },
    }
}
</script>
<style lang="stylus" scoped>
.sdjt-header-box{
    width 100%
    // position: fixed;
    // z-index: 666;
    background :#FFFFFF;
    .top{
        width 1200px
        height 90px
        margin 0 auto;
        display flex;
        justify-content:space-between;
        .logo-left{
            display :flex;
            justify-content:space-between;
            width:315px;
            .logo{
                font-size: 30px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #153194;
                line-height: 42px;
                margin-top 24px;
            }
            .line{
                width: 1px;
                height: 32px;
                background: #153194;
                margin-top 30px;
            }
            .logo-mini{
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #153194;
                line-height: 25px;
                margin-top 33px;
            }
        }
        .btn-right{
            display :flex;
            justify-content:space-between;
            width:264px;
            margin-top 25px;
            div{
                width: 120px;
                height: 40px;
                background: #FFFFFF;
                border-radius: 21px;
                border: 1px solid #153194;
                text-align:center;
                line-height : 38px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #153194;
                cursor pointer
            }
        }
    }
    .nav{
        width 100%
        height 60px
        background: linear-gradient(180deg, #1435A2 0%, rgba(60, 60, 231, 0.41) 100%);
        .nav-contaniner{
            width 1200px
            margin 0 auto
            display flex
            li{
                width 120px
                text-align center
                cursor pointer
                &:hover{
                    background #10287B
                }
                p{
                    font-size: 18px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 60px;
                    width 100%
                    height 100%
                    &.active{
                        background #10287B
                    }

                }
            }
        }
    }
}
</style>
