<template>
    <div class="home">
        <div class="header"></div>
        <div class="cont1 cont">
            <div class="cont_title">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/weidong/title-1.png" alt="">
            </div>
            <div class="cont_main">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/3-1.png" class="bulb" alt="">
                 <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/3-2.gif" class="img-2" alt="">
                <h1>测测你的职场匹配度</h1>
                <p>性格因素和职场选择之间，到底存在什么关联？</p>
                <div class="button" @click="goEvaluation">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/hand-icon.png" class="hand" alt="">
                    <span class="text">点击测评</span>
                </div>
            </div>
        </div>
        <div class="cont2 cont">
            <div class="cont_title">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/weidong/title-2.png" alt="">
            </div>
            <div class="cont_main">
                <div class="top">
                    <div class="cont_main_left item">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/weidong/sym7.png" alt="">
                        <div class="item_mask">
                            <span>人工智能</span>
                        </div>
                    </div>
                    <div class="cont_main_middle">
                        <div class="inner_top">
                            <div class="item">
                                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/weidong/sym1.png" alt="">
                                <div class="item_mask">
                                    <span>应急</span>
                                </div>
                            </div>
                            <div class="item">
                                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/weidong/sym2.png" alt="">
                                <div class="item_mask">
                                    <span>民政</span>
                                </div>
                            </div>
                        </div>
                        <div class="inner_bottom item">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/weidong/sym4.png" alt="">
                            <div class="item_mask">
                                <span>退役军人</span>
                            </div>
                        </div>
                    </div>
                    <div class="cont_main_right item">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/weidong/sym3.png" alt="">
                        <div class="item_mask">
                            <span>家政</span>
                        </div>
                    </div>
                </div>
                <div class="bottom">
                    <div class="cont_main_left item">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/weidong/sym5.png" alt="">
                        <div class="item_mask">
                            <span>交通</span>
                        </div>
                    </div>
                    <div class="cont_main_right item">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/weidong/sym6.png" alt="">
                        <div class="item_mask">
                            <span>通识</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottomBg">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/weidong/cont2-bg.png" alt="">
            </div>
        </div>
        <div class="cont3 cont">
            <div class="cont_title">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/weidong/title-3.png" alt="">
            </div>
            <div class="cont_main">
                <el-carousel :interval="4000" type="card" ref="carousel" :autoplay="false" indicator-position="none" arrow="never" height="360px">
                    <el-carousel-item class="live-bg" id="item1" ref="item1">
                        <div class="info">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/play-icon.png" class="icon-play" alt="">
                            <p class="jieshao">
                                <span>行业解读</span>
                                <span>试听公开课</span>
                                <span>专家讲座</span>
                            </p>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item class="live-bg" id="item2"  ref="item2">
                        <div class="course-item info">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/play-icon.png" class="icon-play" alt="">
                            <p class="text">了解更多课程详情</p>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item class="live-bg" id="item3"  ref="item3">
                        <div class="course-item info">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/play-icon.png" class="icon-play" alt="">
                            <p class="text">查看更多直播课</p>
                        </div>
                    </el-carousel-item>

                    <div class="disableClickMask"></div>
                </el-carousel>
                <div class="double-arrow-box" @click="goLive">更多专题讲座 <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/double-arrow.png" class="double-arrow" alt=""></div>
                
            </div>
        </div>
        <div class="cont4 cont">
            <div class="cont_title">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/weidong/title-4.png" alt="">
            </div>
            <div class="cont_main">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/weidong/choice-1.png" />
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/weidong/choice-2.png" />
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/weidong/choice-3.png" />
            </div>
        </div>
        <weidongFooter/>
    </div>
</template>

<script>
import weidongFooter from './weidongFooter'
// import Swiper from "swiper";
// import "swiper/dist/css/swiper.min.css";
export default {
  name: 'weidong_homepage',
    data() {
        return {
            liveObj:[],
        };
    },
    components:{
        weidongFooter
    },
    mounted() {
    
    },
    created() {
        
    },
    methods: {
        goEvaluation(){
            if(localStorage.getItem('token')){
                this.$router.push('/assessList')
            } else {
                this.$router.push({
                    path: '/login',
                    query: {
                        name: '/assessList'
                    }
                })
            }
        },
        // 跳转直播列表
        goLive(){
            if(localStorage.getItem('token')){
                this.$router.push('/liveList')
            } else {
                this.$router.push({
                    path: '/login',
                    query: {
                        name: '/liveList'
                    }
                })
            }
        }
    }
};
</script>
<style lang="stylus" scoped>
@import '../asset/css/weidongHomepage.styl'
</style>
