<template>
    <div class='formPage'>
        <div class="portrait1">
            <img v-if="user.headImage!==''" :src=user.headImage alt="">
            <img v-if="user.headImage==''" src="http://hrss-tes.oss-cn-beijing.aliyuncs.com/undefinedimage/2021/05/1621063663500.png" alt="">
            <span>{{user.name}}</span>
        </div>
        <div class="hello">
            <question :themeColor="themeColor" :buttomText="buttomText"  :imgUrl="imgUrl" :type="type"  :typeId="typeId" ></question>
        </div>
    </div>
</template>

<script>
export default {
    name:'formPage',
    data () {
        return {
            themeColor:'#1972FF',
            buttomText:'提交',
            type:this.$route.query.type,
            typeId:this.$route.query.typeId,
            imgUrl: 'http://hrss-tes.oss-cn-beijing.aliyuncs.com/undefinedimage/2021/05/1621056048082.png',
            headImage:'',
            user: {headImage:''},
        };
    },
    components: {
    },
    filters: {
    },
    watch:{
    },
    methods: {
        test(data){
            console.log('提交后的回调操作,data为提交的信息',data)
        },
        // 我的资料
        getPersonalInfo(){
            this.$api.usercenter.findUser().then(res => {
                if(res.code == 0){
                    this.user = res.data;
                    console.log(res)
                    console.log('12312312312312313')
                }
            })
        },
    },
    destroyed() {
    },
    mounted() {
    },
    created(){
        if(!(this.$route.query.overview=='1')){
            this.getPersonalInfo();
        }
    },
}

</script>
<style scoped lang='less'>
.formPage{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .portrait1 {
        height: 40px;
        line-height: 40px;
        margin: 24px;
        width: 1200px;
        img {
            width: 40px;
            height: 40px;
            margin-right: 16px;
            border-radius: 50%;
            vertical-align: revert;
        }
        span {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #303133;
            vertical-align: text-bottom;
        }
    }
}
</style>
