<template>
 <div class='zzhomeBanner'>
    <el-carousel trigger="click">
      <el-carousel-item v-for="(item, index) in imgList" :key="index">
        <img :src="item.imgUrl" alt="">
      </el-carousel-item>
      <img :src="imgHeight" class="toolImg" alt="">
    </el-carousel>
    <div class="collegeNav">
      <ul class="list">
        <li v-for="(item, index) in collegeList" :key="index" @click="collegeDetails">
          <div class="label">
            <span class="label-name">{{item.name}}</span>
            <span class="label-keyword">{{item.keyword}}</span>
          </div>
          <i class="el-icon-caret-right icon"></i>
        </li>
      </ul>
    </div>
 </div>
</template>

<script>
export default {
    name:'homeBanner',
    props: {
    },
    data () {
        return {
          imgList: [
            {
              imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/newBanner.png'
            },{
              imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/dushu.jpg'
            }
          ],
          imgHeight: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/newBanner.png',
          collegeList: [
            {
              name: '企业管理学院：',
              keyword: '助力企业 / 专业学院'
            },
            {
              name: '南湖学院：',
              keyword: '党建智库 / 党建解决方案师'
            },
            {
              name: '传媒学院：',
              keyword: '广播 / 电视 / 文化展业'
            },
            {
              name: '人力资源学院：',
              keyword: '央企品牌 / 实践教学'
            },
            {
              name: '护理学院：',
              keyword: '护理教育 / 专业课程体系'
            },
            {
              name: '金融学院：',
              keyword: '专业技能培养'
            },
            {
              name: 'IT学院：',
              keyword: 'IT技能 / 互联网人才培养'
            },
          ]
        };
    },

    methods: {
      collegeDetails() {
        this.$router.push({
          path:'/projectDetails'
        })
      }
      
    },

    mounted(){
    },
}

</script>
<style lang='stylus' scoped>
@import "../../asset/css/homeBanner.styl"
    // 兼容谷歌
.zzhomeBanner {
  /deep/.el-carousel__indicator.is-active button {
    width: 32px!important;
  }
  /deep/.el-carousel__indicators .el-carousel__indicators--horizontal {
    bottom: 80px!important;
  }
  /deep/ .el-carousel__arrow{
    display none!important;
  }
  /deep/.el-carousel__container{
    height: auto!important;
    img {
      width: 100%!important;
    }
  }
  /deep/.el-carousel__indicators--horizontal {
    bottom: 23px;
    // display: none;
    .el-carousel__indicator--horizontal {
      padding: 12px 4px;
      .el-carousel__button {
        width: 8px;
        height: 8px;
        border-radius: 4px;
      }
    }
  }
  // 兼容火狐
  @-moz-document url-prefix() {
    .el-carousel__container{
      height: auto!important;
      img {
        width: 100%!important;
      }
    }
    .el-carousel__indicators--horizontal {
      bottom: 23px;
      // display: none;
      .el-carousel__indicator--horizontal {
        padding: 12px 4px;
        .el-carousel__button {
          width: 8px;
          height: 8px;
          border-radius: 4px;
        }
      }
    }
  }
  // 兼容IE
  .el-carousel__container{
    height: auto!important;
    img {
      width: 100%!important;
    }
  }
  .el-carousel__indicators--horizontal {
    bottom: 23px;
    // display: none;
    .el-carousel__indicator--horizontal {
      padding: 12px 4px;
      .el-carousel__button {
        width: 8px;
        height: 8px;
        border-radius: 4px;
      }
    }
  }
}
</style>