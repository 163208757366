<template>
    <!--eslint-disable-->
    <div class="detail-main">
        <div class="return" @click="getReturn">
            < 返回上一页
        </div>
        <div class="class-detail">
           <div class="title">
               {{data.title}}
           </div>
            <div class="video-contanier">
                <video-player  class="video-player-box"
                               ref="videoPlayer"
                               :options="playerOptions"
                               :playsinline="true"
                               customEventName="customstatechangedeventname"
                               @play="onPlayerPlay($event)"
                               @pause="onPlayerPause($event)"
                               @timeupdate="onPlayerTimeupdate($event)"
                              >
                </video-player>
                <!--<div class="play-btn" v-show="isShow">-->
                    <!--<img v-show="isImg" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/play.png" alt="" @click="getPlay">-->
                    <!--<div v-show="isBuy" class="word">-->
                        <!--<div>试看结束，购买后可继续学习哦</div>-->
                        <!--<div>立即购买</div>-->

                    <!--</div>-->
                <!--</div>-->
            </div>
            <div class="person">
                <div class="title">
                    <i></i>
                    <span>名师简介</span>
                </div>
                <div class="introduce">
                    <div class="fl"><img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/person.png" alt=""></div>
                    <div class="fl remark">
                        <p class="p1">张鹏 老师</p>
                        <!--<p class="p2">擅长的技术领域包括Python、web开发、数据分析、机器学习等</p>-->
                        <p class="p3">国家财政部宏观经济首席专家，国家财政部政策实验室宏观经济部主任、经济学博士， 中共北京市委十八大宣讲团成员，中共北京市委讲师团特聘专家，中共北京市直机关工委特聘专家，中共中央党校干部学习网特约专家，多个省、地级党委政府的重点联系专家。中国社科院《宏观经济运行与财政政策研究报告》（2008、2009、2010、2011、2012、2013）核心执笔人。</p>
                    </div>
                </div>
                <div class="title">
                    <i></i>
                    <span>课程简介</span>
                </div>
                <div class="long-img">
                    <img :src='data.img' alt="">
                </div>
            </div>
        </div>

        <footers/>
    </div>
</template>

<script>
    import footers from '../block/front/footers'

    export default {
        name: "classDetail",
        data() {
            return {
                playerOptions: {
                    // videojs options
                    muted: true,
                    language: 'en',
                    playbackRates: [0.7, 1.0, 1.5, 2.0],
                    controlBar: {
                        timeDivider: true,
                        durationDisplay: true,
                        remainingTimeDisplay: true,
                        fullscreenToggle: true,  //全屏按钮
                    },
                    sources: [{
                        type: "video/mp4",
                        src: ""
                    }],
                    poster: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/videobg.png',// 封面
                    // poster: '',
                    width: '1160px', //播放器宽度
                },
                isShow:true,
                isImg:true,
                isBuy:false,
                list:[
                    {
                        id:'1',
                        title:'GDP与构成分析',
                        url:'https://download-resource.wdcloud.cc/customerTrans/185fbaacf585258754313f047523bcda/5724671-17738d2f4b1-0005-180f-1e6-11ff3.mp4',
                        img:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/teacher-1.png'
                    },
                    {
                        id:'2',
                        title:'GDP的增长质量',
                        url:'https://download-resource.wdcloud.cc/customerTrans/185fbaacf585258754313f047523bcda/5927ebc9-1774d026dab-0005-180f-1e6-11ff3.mp4',
                        img:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/teacher-2.png'
                    },
                    {
                        id:'3',
                        title:'最终消费',
                        url:'https://download-resource.wdcloud.cc/customerTrans/185fbaacf585258754313f047523bcda/44942b08-1774d095526-0005-180f-1e6-11ff3.mp4',
                        img:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/teacher-3.png'
                    },
                    {
                        id:'4',
                        title:'宏观调控',
                        url:'https://download-resource.wdcloud.cc/customerTrans/185fbaacf585258754313f047523bcda/3dfc0306-1774cfcec93-0005-180f-1e6-11ff3.mp4',
                        img:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/teacher-4.png'
                    },
                    {
                        id:'5',
                        title:'物价与就业',
                        url:'https://download-resource.wdcloud.cc/customerTrans/185fbaacf585258754313f047523bcda/fd1b909-1774cf67425-0005-180f-1e6-11ff3.mp4',
                        img:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/teacher-5.png'
                    }
                ],
                data:{},
            }
        },
        mounted() {
            console.log(this.$route.query.id,'99999999');
            let tepId = this.$route.query.id;
            this.list.forEach(ele=>{
                if(ele.id === tepId){
                    this.data = ele;
                }
            })
            console.log(this.data);
            this.playerOptions.sources[0].src = this.data.url;

        },
        methods: {
            getReturn(){
                // 公开课 和 非公开课不一样
                // this.$router.push({
                //     name:'publicClass'
                // })
                this.$router.go(-1)
            },
            getPlay(){
                this.$refs.videoPlayer.player.play();
                this.isShow = false;
                this.isImg = false;
                this.isBuy = true;
            },
            getPaused() {
                const myPlayer = this.$refs.videoPlayer.player;
                myPlayer.pause();
            },
            // listen event
            onPlayerPlay() {
                // alert('播放')
                // console.log('player play!', player)
            },
            onPlayerPause() {
                // if(this.currentTime > 10){
                //     // alert('大于10秒了')
                //     // 停止播放
                //     // this.getPaused()
                //     this.isShow = true;
                //     this.isBuy = true;
                //     this.isImg = false;
                //     // 立即购买显示
                // }else {
                //     this.isShow = true;
                //     this.isImg = true;
                //     this.isBuy = false;
                //     // 播放图标显示
                // }
            },
            onPlayerTimeupdate(player){
                console.log(player);
                // this.currentTime = player.cache_.currentTime;
                // if(this.currentTime > 10){
                //     // 停止播放
                //     this.getPaused()
                //     // 立即购买显示
                //     this.isShow = true;
                //     this.isBuy = true;
                //     this.isImg = false;
                // }
            }
        },
        components: {
            footers,
        }
    }
</script>

<style lang="stylus" scoped>
    @import '../asset/css/classDetail.styl'
    .video-player-box
        height 652px
        /deep/ .video-js
            .vjs-big-play-button
                position absolute
                top 50%
                left 50%
                transform translate(-50%,-50%)
                width 102px
                height 102px
                background url("https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/play.png") no-repeat
                border none
                .vjs-icon-placeholder
                    &:before
                        content ''
                        color #fff
            .vjs-play-control
                color #fff
            .vjs-icon-volume-high
                &:before
                    color #ffffff
            .vjs-mute-control
                .vjs-icon-placeholder
                    &:before
                        color #fff
            .vjs-time-control
                color #fff
            .vjs-playback-rate
                .vjs-playback-rate-value
                    color #fff
            .vjs-fullscreen-control
                .vjs-icon-placeholder
                    &:before
                        color #fff
</style>
