<template>
    <div class="personnel">
        <div class="banner">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/query-banner.png" alt="">
        </div>
        <div class="container">
            <div class="tab">
                <p class="tabName" @click="tabClick(item)" :class="{active:tabIndex == item.id}" v-for="item in tabList" :key="item.id">
                    {{item.name}}
                </p>
            </div>
            <div class="content" v-show="tabIndex == 1">
                <div class="input">
                    <el-input v-model="companyValue"  class="company-input" placeholder="请输入公司的名称"></el-input>
                    <el-button type="primary" class="search" @click="search(1)">查询</el-button>
                </div>
                <div class="list">
                    <img v-if="!companyShow" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/query-icon.png" class="query-icon" alt="">
                    <img @click="dialogVisible = true" v-else src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/company-list.jpg" class="cover" alt="">
                </div>
            </div>
            <div class="content" v-show="tabIndex == 2">
                <div class="input">
                    <el-input v-model="cardValue"  class="card-input" placeholder="请输入家政员的身份证号"></el-input>
                    <el-input v-model="phoneValue"  class="phone-input" placeholder="请输入家政员的姓名"></el-input>
                    <el-button type="primary" class="search" @click="search(2)">查询</el-button>
                </div>
                <div class="info">
                    
                    <img v-if="!personnelShow"  src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/query-icon.png" class="query-icon" alt="">
                    <div class="info-container" v-else>
                        <div class="empty" v-show="emptyShow">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/empty-icon.png" class="icon" alt="">
                            <p>该身份证号未查询到具体的家政人员，请确认后再查询！</p>
                        </div>
                        <div class="sample">
                            <p class="title" v-show="emptyShow">
                                服务人员信用样例
                            </p>
                            <div class="sample-box">
                                <div class="title-box">
                                    <img src="../asset/images/jiazheng-head-icon.png" class="head-icon" alt="">
                                    <span class="name">XX阿姨</span>
                                    <span class="number">信用分值：456（良好）</span>
                                    <span class="level">
                                        <span>信用等级： </span>
                                        <img src="../asset/images/stars-icon1.png" class="icon" alt="">
                                        <img src="../asset/images/stars-icon1.png" class="icon" alt="">
                                        <img src="../asset/images/stars-icon1.png" class="icon" alt="">
                                        <img src="../asset/images/stars-icon1.png" class="icon" alt="">
                                        <img src="../asset/images/stars-icon2.png" class="icon" alt="">
                                    </span>
                                </div>
                                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/personnel-cover1.png" class="personnel-cover1" alt="">
                                <div class="info-box">
                                    <p class="title">基本信息：</p>
                                    <p class="text">基本信息是指能验证你身份的数据，包括个人基本信息及学历信息等。该维度是信用体系的基础维度，完善的基本信息有助于更准确的信用评估。</p>
                                    <p class="title">业务信息：</p>
                                    <p class="text">业务信息是指能验证你工作状态的数据，包括语言水平、工作履历等。该维度是信用体系的重要组成部分，完善的业务信息有助于提升信用水平。</p>
                                    <p class="title">培训及技能证书：</p>
                                    <p class="text">培训及技能证书维度是指在伟东平台及其他平台培训的相关数据，包括证书及其他资质认证。该维度对业务能力评估较为重要。</p>
                                    <p class="title">健康信息：</p>
                                    <p class="text">健康信息是家政领域的重要评估维度，完善的健康信息有利于提升信用水平。</p>
                                    <p class="title">测评信息：</p>
                                    <p class="text">测评信息是指能验证你职业素质能力方面的数据，包括心理健康测评、职业素质测评。该维度是家政领域的重要评估维度，完善的测评信息有利于提升信用水平。</p>
                                </div>
                            </div>
                            <div class="button-box" v-show="!emptyShow" @click="dialogVisible2 = true">查看信用报告</div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            title=""
            :visible.sync="dialogVisible"
            width="810"
            :before-close="handleClose">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/gongsi-tanchaung.png" style="width:680px" alt="">
            
        </el-dialog>
        <el-dialog
            title=""
            :visible.sync="dialogVisible2"
            width="810"
            :before-close="handleClose2">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/renyuan-tanchuang.png" style="width:680px" alt="">
            
        </el-dialog>
        <div class="footers">
            版权所有：伟东国信    技术支持：伟东云教育
        </div>
        <!-- <footers></footers> -->
    </div>
</template>
<script>
// import footers from './sdjz2Footer.vue'
export default {
    name:'zhaohupersonnel',
    // components:{footers},
    data(){
        return{
            dialogVisible2:false,
            dialogVisible:false,
            companyValue:'',
            companyShow:false,
            personnelShow:false,
            emptyShow:false,
            cardValue:'',
            phoneValue:'',
            tabIndex:1,
            tabList:[
                {
                    id:1,
                    name:'搜公司'
                },
                {
                    id:2,
                    name:'搜家政人员'
                }
            ]
        }
    },
    mounted(){
        this.tabIndex = this.$route.query.type || 1
    },
    methods:{
        handleClose(){
            this.dialogVisible = false
        },
        handleClose2(){
            this.dialogVisible2 = false
        },
        tabClick(item){
            this.tabIndex = item.id
            this.companyValue = ''
            this.cardValue = ''
            this.phoneValue = ''
        },
        search(type){
            if(type == 1){
                if(this.companyValue){
                    this.companyShow = true
                }else {
                    this.companyShow = false
                }
            }else {
                if(this.cardValue == '' || this.phoneValue == ''){
                    this.emptyShow = true;
                }else {
                    this.emptyShow = false
                }
                if(this.cardValue != '' || this.phoneValue != ''){
                    this.personnelShow = true
                }else {
                    this.personnelShow = false
                }
            }
        }
    }
}
</script>
<style lang="stylus" scoped>
@import "../asset/css/personnel.styl"
</style>