<template>
    <el-dialog
      :visible.sync="showInfo"
      width="800"
      @close="$emit('infoShow',false)"
      class="pub_dialog"
      center>
      <div class="dialog-detail">
        <img :src="currentCourse.pic || currentCourse.iconUrl || currentCourse.coverUrl" alt="">
        <div>
          <h4>{{currentCourse.label || currentCourse.name}}</h4>
          <p class="dialog-date" v-if="currentCourse.trainStartTime && type !== 3">{{currentCourse.trainStartTime | dataFormat}}至{{currentCourse.trainEndTime| dataFormat}}</p>
          <div class="dialog-date" v-else>
              <p v-if="currentCourse.cycleType ===1">{{currentCourse.startTime.split(' ')[0].replace(/-/g,'.')}} 至 {{currentCourse.endTime.split(' ')[0].replace(/-/g,'.')}}</p>
              <p v-if="currentCourse.cycleType ===2">有效期至：{{currentCourse.endTime.split(' ')[0].replace(/-/g,'.')}}</p>
               <p v-if="currentCourse.cycleType ===3">长期有效</p>
          </div>
        </div>
      </div>
      <div class="dialog-info" v-if="!type">{{nameType}}{{description||currentCourse.description}}</div>
      <div v-else class="dialog-info" >
          {{nameType}}<div v-html="description || currentCourse.description"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="$emit('infoShow',false)">知道了</el-button>
      </span>
    </el-dialog>
</template>
<script>
export default {
    name: 'dialogInfo',
    props: {
        info: {
            type: Boolean,
            default: false
        },
        currentCourse: {
            type: Object,
            default: () => {}
        },
        description: {
            type: String,
            default: ''
        },
        type:{
            type:Number
        },
        nameType:{
            type:String,
            default:'课程简介：'
        }
    },
     data () {
        return {
            showInfo:false
        }
     },
     filters: {
      dataFormat(msg) {
        const date = new Date(msg)
        const m = date.getMonth()+1;
        return date.getFullYear()+'.'+m+'.'+date.getDate();
      },
    },
     watch:{
        info(val){
            this.showInfo = val
        }
     },
     methods:{
         toDetail(e){
            this.isSelect = e;
         }
     }
}
</script>
<style lang="stylus">
.el-dialog
  border-radius: 10px;
.el-dialog__footer
  padding: 0px 20px 30px;
.el-dialog__headerbtn .el-dialog__close
  font-size: 20px;
</style>
<style lang="stylus" scoped>  
 @import "../asset/css/dialogInfo.styl"

</style>