<template>
    <div class="sdjz_homepage">
        <!-- <homeBanner /> -->
        <!-- <family /> -->
        <homepageContent1 />
        <!-- <homepageContent2 /> -->
        <footers />
   
    </div>
</template>

<script>
// import homeBanner from '../block/front/homeBanner.vue'
// import family from '../block/front/family.vue'
import footers from '../block/front/footers.vue'
import homepageContent1 from '../block/front/homepageContent1.vue'
// import homepageContent2 from '../block/front/homepageContent2.vue'
export default {
  name: 'sdjz_homepage',
  show: true,
  components: {
    //   homeBanner,
    //   family,
      footers,
      homepageContent1,
    //   homepageContent2,
  },
    data() {
        return {
            
        };
    },
    mounted() {

    },
    created() {
    },
    beforeDestroy() {

    },
    methods: {
        
    }
};
</script>
<style lang="stylus" scoped>
.sdjz_homepage {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    .shenqing{
        position fixed
        top 600px
        right 100px
        cursor pointer
    }
}
</style>
