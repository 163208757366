<template>
    <div class="custom-nav yingji-page-header clearfix">
        <div class="page-header-top">
            <div class="toplogo fl">
                <img
                    class="logo-left"
                    src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/yingji/logo.png"
                    alt=""
                >
                <span class="line" />
                <img
                    class="logo-right"
                    src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/yingji/fbt.png"
                    alt=""
                >
            </div>
            <div class="topright fr">
                <p class="topright-btn">
                    扫码查看手机版
                    <span class="erweima">
                        <img
                            src="https://hrss-tes.oss-cn-beijing.aliyuncs.com/hrss/image/2021/04/1618986664234.jpg"
                            alt=""
                        >
                    </span>
                </p>
            </div>
        </div>
        <div class="page-header-nav ">
            <div class="nav-name clearfix">
                <ul class="fl">
                    <li
                        v-for="(item, index) in navName"
                        :key="index"
                    >
                        <span
                            :class="isSelect === item ? 'active' : ''"
                            @click="selectNav(item)"
                        >
                            {{item.name}}
                        </span>
                    </li>
                </ul>
                <div class="fr">
                    <div v-if="!token" class="un-login" @click="jumpLogin()">
                        登录
                    </div>
                    <template v-else>
                        <account :replacePage="'yingji_homepage'"/>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import URL from '../../../../config/url';
import account from './account.vue'
export default {
    name: 'yingjiNav',
    components: {
        account,
    },
    data() {
        return {
            navName: [],
            isSelect: null,
            token: '',
        }
    },
    watch: {
        $route() {
            this.getNavs();
        }
    },
    created() {
        this.getNavs();
        this.token = localStorage.getItem('token');
    },
    methods: {
        getNavs() {
            if (!this.navName.length) {
                this.$api.home.getMenuNew(URL.confUrl).then((res) => {
                    if (res.data.menuList) {
                        this.navName = res.data.menuList;
                        this.getActiveNav();
                    }
                })
            } else {
                this.getActiveNav();
            }
        },
        getActiveNav() {
            this.navName.forEach((d)  => {
                if (d.url === this.$route.path || this.$route.path.indexOf(d.url) !== -1) {
                    this.isSelect = d;
                }
            })
        },
        selectNav(item) {
            if (item.url.indexOf('http') === -1) {
                this.isSelect = item;
                this.$router.push({
                    path: item.url,
                });
            } else {
                window.open(item.url, 'newW');
            }
        },
        jumpLogin() {
            this.$router.push({
                name: 'login',
            })
        },
    }
}
</script>
<style scoped>
.yingji-page-header {
    cursor: pointer;
    position: fixed;
    width: 100%;
    z-index: 666;
    background: #fff;
}
.page-header-top {
    margin: 0px auto;
    height: 123px;
    line-height: 123px;
    width: 1200px;
}
.page-header-top {
    width: 1200px;
}
.page-header-top .toplogo {
    padding-left: 10px;
}
.page-header-top .toplogo img {
    vertical-align: middle;
}
.page-header-top .toplogo .line {
    width: 1px;
    height: 40px;
    margin: 0px 40px;
    display: inline-block;
    vertical-align: middle;
    border-left: 1px solid #ccc;
}
.page-header-top .topright {
    position: relative;
}
.page-header-top .topright-btn {
    cursor: pointer;
    width: 143px;
    height: 35px;
    margin-top: 50px;
    line-height: 33px;
    text-align: center;
    border-radius: 18px;
    border: 1px solid #d02e3e;
    font-size: 15px;
    font-family: "Source Han Sans CN";
    font-weight: 600;
    font-style: italic;
    color: #d02e3e;
}
.page-header-top .topright-btn:hover {
    background-color: #d02e3e;
    color: #fff;
}
.page-header-top .topright-btn:hover .erweima {
    display: inline-block;
}
.page-header-top .topright .erweima {
    position: absolute;
    right: 0px;
    top: 90px;
    z-index: 10000;
    display: none;
}
.page-header-top .topright .erweima img {
    width: 145px;
}
.page-header-nav {
    width: 100%;
    background-color: #d02e3e;
    height: 66px;
}
.page-header-nav .nav-name {
    margin: 0px auto;
}
.page-header-nav .nav-name {
    width: 1200px;
}
.page-header-nav .nav-name li {
    display: inline-block;
    vertical-align: top;
    line-height: 66px;
    height: 66px;
}
.page-header-nav .nav-name li > span {
    color: #fff;
    font-size: 17px;
    display: inline-block;
    text-align: center;
    padding: 0 30px;
    cursor: pointer;
}
.page-header-nav .nav-name li:hover {
    background-color: #ac1b2c;
}
.page-header-nav .nav-name .active {
    background: #ac1b2c;
}
.page-header-nav .un-login {
    line-height: 34px;
    cursor: pointer;
    padding: 0 20px;
    border: 1px solid #fff;
    border-radius: 17px;
    color: #fff;
    font-size: 14px;
    margin-top: 15px;
}

</style>
