<template>
 <div class='homeBanner'>
    <el-carousel trigger="click">
      <el-carousel-item v-for="(item, index) in imgList" :key="index">
        <img :src="item.imgUrl" alt="">
      </el-carousel-item>
      <img :src="imgHeight" class="toolImg" alt="">
    </el-carousel>
 </div>
</template>

<script>
export default {
    name:'homeBanner',
    props: {
    },
    data () {
        return {
          imgList: [
            {
              imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/sdjz-banner.png',
            },
          ],
          imgHeight: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/sdjz-banner.png',
        };
    },

    methods: {},

    mounted(){
    },
}

</script>
<style lang='stylus' scoped>
    @import "../../asset/css/homeBanner.styl"
</style>
<style lang='stylus'>
    // 兼容谷歌
.homeBanner {
  /deep/.el-carousel__indicators .el-carousel__indicators--horizontal {
    bottom: 80px!important;
  }
  .el-carousel__container{
    height: auto!important;
    img {
      width: 100%!important;
    }
  }
  /deep/.el-carousel__indicators--horizontal {
    bottom: 23px;
    // display: none;
    .el-carousel__indicator--horizontal {
      padding: 12px 10px;
      .el-carousel__button {
        width: 9px;
        height: 9px;
        border-radius: 50%;
      }
    }
  }
  // 兼容火狐
  @-moz-document url-prefix() {
    .el-carousel__container{
      height: auto!important;
      img {
        width: 100%!important;
      }
    }
    .el-carousel__indicators--horizontal {
      bottom: 23px;
      // display: none;
      .el-carousel__indicator--horizontal {
        padding: 12px 10px;
        .el-carousel__button {
          width: 9px;
          height: 9px;
          border-radius: 50%;
        }
      }
    }
  }
  // 兼容IE
  .el-carousel__container{
    height: auto!important;
    img {
      width: 100%!important;
    }
  }
  .el-carousel__indicators--horizontal {
    bottom: 23px;
    // display: none;
    .el-carousel__indicator--horizontal {
      padding: 12px 10px;
      .el-carousel__button {
        width: 9px;
        height: 9px;
        border-radius: 50%;
      }
    }
  }
}
</style>
