<template>
    <div class="live-list">
        <div class="tab">
            <!-- @click="tabClick(tab)" -->
            <p class="text" v-for="tab in tabList" :key="tab.id" :class="{active:tabIndex === tab.id}" >
                {{tab.value}}
            </p>
        </div>
        <div class="content">
            <div class="list" v-for="item in liveList" :key="item.id">
                <div class="left item">
                    <img class="cover" :src="item.propagatePic || 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/scss/live-pic.png'" alt="">
                    <img v-show="item.showNew" class="icon" src="../assets/images/learning/new-icon.png" alt="">
                    
                </div>
                <div class="middle item">
                    <div class="title">
                        <h1>{{item.title}}</h1>
                    </div>
                    <p class="live-time" >直播时间：{{item.beginTime && item.beginTime.replace(/-/g,'.')}} - {{item.planEndTime && item.planEndTime.replace(/-/g,'.')}}</p>
                    <p class="source">来源：{{item.sourceName}}</p>
                </div>
                <div class="right item">
                    <div class="button live-button" @click="goExam(item)" :class="{status : !item.status}">
                        <img class="lock-icon" v-show="item.isLocked" src="./../assets/images/learning/lock-icon3.png" alt="">
                        {{item.status === 1? '开始直播': item.status === 0 ?'未开始' : item.status === 2 && (item.isLiveReplay && item.isRecord ) ? '回放' : '已结束'}}
                    </div>
                </div>
            </div>
            <article v-if="noData" class="no-data">
                <img class="notraining" src="./../assets/images/learning/zb-nodata@2x.png" alt />
                <p>还没有直播内容呦～</p>
            </article>
            <p class="loadingmore" v-if="loading">加载中...</p>
            <p class="loadingmore" v-if="noMore">- 已加载全部内容 -</p>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
export default {
    name:'liveList',
    data(){
        return {
            tabIndex:1,
            tabList:[
                {
                    id:1,
                    value:'全部'
                },
            ],
            liveList:[],
            paging: {
                params: {
                    pageNum: 1,
                    pageSize: 10
                },
                total: 0
            },
            loading:false,
            noData:null
        }
    },
    mounted(){
        this.getData()
        $(window).scroll(() => {
            //判断是否滑动到页面底部
            if (
                Math.round($(window).scrollTop()) ===
                $(document).height() - $(window).height()
            ) {
                // TODO 滑动到底部时可请求下一页的数据并加载，加载可使用append方法
                // console.log('页面底部')
                
                setTimeout(this.reachBottom(), 1000)
            }
        });
    },
    computed: {
        noMore () {
            return this.liveList.length === this.paging.total && this.paging.total !== 0
        },
    },
    methods:{
        // tabClick(item){
        //     this.tabIndex = item.id
        // }
        goExam(item){
            // 上锁
            if(item.isLocked){
                this.$message.warning({
                    message:`该直播未开启，开启条件为前面的课程学习进度达到 ${item.lockCourseRate}%`,
                    offset:70
                })
                if(item.lockExamCondition){
                    this.$message.warning({
                        message:'该直播未开启，开启条件为前面的考试得分及格',
                        offset:120
                    })
                }
            }else {
                // 
                if(item.status === 0){
                    this.$message.warning('直播未开始！')
                }else if(item.status === 1){
                    this.$api.live.audienceEnterRoom({id: item.liveId}).then(async (res) => {
                            if (res.success) {
                                this.$router.push({
                                    path: '/studentSee',
                                    query:{
                                        roomId: item.liveId,
                                        type: 1,
                                    }
                                })
                            }
                    });
                }else {
                    if(item.isLiveReplay && item.isRecord){
                        // 显示回放按钮
                        if(item.isReplay){
                            this.$api.live.saveViewUser(item.liveId).then((res) => {
                                if(res.success){
                                    this.$router.push({
                                        path: '/livePlayback',
                                        query:{
                                            roomId: item.liveId,
                                            sourceId: item.replayResourceId,
                                        }
                                    })
                                }
                            })
                        }else{
                            this.$message.warning('视屏处理中，请次日查看~')
                        }
                    }else{
                        this.$message.warning('直播已结束')
                    }
                }
            }

        },
        reachBottom() {
            if (this.paging.total > this.paging.params.pageNum * this.paging.params.pageSize) {
                this.paging.params.pageNum = this.paging.params.pageNum + 1;
                this.loading = true;
                this.getData("reachBottom");
            } else {
                this.loading = false;
            }
        },
        getData(){
            const params = {
                pageNum: this.paging.params.pageNum,
                pageSize: this.paging.params.pageSize,
            }
            this.$api.live.getDataList({params}).then(({ data: { list, total } }) => {
                if(list && list.length){
                    this.noData = false
                }else {
                    this.noData = true
                }
                if(this.paging.params.pageNum>1){
                    this.liveList = this.liveList.concat(list);
                } else {
                    this.liveList = list;
                }
                this.paging.total = total;
            });
        }
    }
}
</script>
<style lang="stylus" scoped>
@import './../assets/css/learningList.styl'
</style>