<template>
   <div class="yanglao_list">
    <div class="header">
        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/banner.jpg" alt="">
    </div>
    <div class="chuji">
        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/group1.png" alt="">
        <div class="content">
            <div class="left">
                <img class="left_img" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/anniu.png" alt="">
            </div>
            <div class="right">
                <div class="right_content">
                    <div class="right_span">
                        <img class="right_img" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/icon-shijian2x.png" alt="">
                        <div class="right_content_span1">培训课时</div>
                        <div class="right_content_span2">线上34课时 线下16课时（2天）</div>
                    </div>
                    <div class="right_content_span right_span">
                        <img class="right_img" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/kecheng2x.png" alt="">
                        <div class="right_content_span1">培训内容</div>
                        <div class="right_content_span2">根据《养老护理员国家职业技能标准（2019）》相关要求打造线上培训课程，内容涵盖生活照护、基础照护及康复服务。课程通俗易懂、实用性强，操作细节详实，学员易懂易学。</div>
                    </div>
                </div>
                <div class="footer">
                    <div class="button">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/ic-zx.png" alt="">
                        <span>在线咨询</span>
                    </div>
                    <div class="button">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/ic-maxx.png" alt="">
                        <span>马上学习</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shizi">
        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/group2.png" alt="">
        <div class="content">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/ln.png" alt="">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/wwh.png" alt="">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/lxc.png" alt="">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/mlt.png" alt="">
        </div>
    </div>
    <div class="kecheng">
        <img class="kecheng_img" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/bt-kcte.png" alt="">
        <div class="kecheng_content">
            <div class="content">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/kecheng1.png" alt="">
                <div class="content_div">
                    <span>行业名师教学</span>
                </div>
            </div>
            <div class="content">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/kecheng2.png" alt="">
                <div class="content_div">
                    <span>实景技能演示</span>
                </div>
            </div>
            <div class="content">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/kecheng3.png" alt="">
                <div class="content_div">
                    <span>线下实训指导</span>
                </div>
            </div>
        </div>
        <img class="kecheng_footer" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/kecheng4.png" alt="">
    </div>
    <div class="shiyong">
        <img class="shiyong_img" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/group5.png" alt="">
        <div class="content">
            <div class="content2">
                <div class="content_block">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/icon-hyxb.png" alt="">
                    <p>期待从事养老护理工作的行业小白</p>
                </div>
                <div class="content_block">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/icon-jineng.png" alt="">
                    <p>希望提高养老护理技能的行业从业人员</p>
                </div>
                <div class="content_block">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/icon-zinv.png" alt="">
                    <p>想学习养老护理照顾父母的子女</p>
                </div>
            </div>
        </div>
    </div>
    <div class="jiuye">
        <img class="jiuye_img" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/group6.png" alt="">
        <div class="jiuye_p">伟东国伟平台拥有10万+家政企业入驻，家政服务人员获得职业认证后，一周内上岗，伟东国信推荐三家雇主给认证家政服务人员选择。</div>
    </div>
    <div class="jiuye_content">
        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/yanglaoerji/jiuye1.jpg" alt="">
    </div>
   </div>
</template>
<script>
   export default {
       name:'yanglao_familyList',
       props: {
       },
       data () {
       },
       methods: {
       }
   }
   
</script>
<style lang='stylus' scoped>
    @import "../asset/css/yanglaoFamily.styl"
</style>
   