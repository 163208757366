<template>
    <!-- 测评页 -->
    <div class="assess">
        <div class="backButton">
            <span @click="goBack">
                    <i class="back el-icon-arrow-left"></i>
                返回</span>
        </div>
        <div class="assess-con">
            <div class="title">
                <span class="line"></span>
                <h4>{{evaluation.assessName}}</h4>
            </div>
            <div class="left">
                <el-scrollbar style="height:100%">
                    <img :src="evaluation.assessCoverimg" alt="">
                    <p class="synopsis"><span class="label">简介：</span>{{evaluation.assessIntroduce}}</p>
                </el-scrollbar>
            </div>
            <div class="right">
                <div style="height: 100%">
                    <div class="basic">
                        <div class="label">
                            <img src="../asset/images/ic-cp@2x.png" alt="">
                            <span class="name">测评</span>
                            <span class="expire" v-if="evaluation.assessStatus==3">（已过期）</span>
                        </div>
                        <div class="information">
                            <div class="number">总题数：{{evaluation.questionNum}}道</div>
                            <div class="frequency" v-if="evaluation.frequency">{{evaluation.frequencyMonth}}个月可测试{{evaluation.frequencyTimes}}次，剩余{{evaluation.remainderTimes}}次<span v-if="evaluation.remainderDate">，间隔{{evaluation.remainderDate}}天</span></div>
                            <!-- 千万别删！！！ -->
                            <!-- <div class="frequency" v-if="evaluation.frequency">总测评次数：{{evaluation.assessTotal}}次<span v-if="evaluation.intervalDay">，间隔{{evaluation.intervalDay}}天</span></div> -->
                            <div class="frequency" v-else>不限次数</div>
                            <div :class="['btn', prohibit?'prohibit':'']" @click="startEvaluation">{{btnName}}</div>
                        </div>
                    </div>
                    <div class="history-list">
                        <div class="title">
                            <h2><img src="../asset/images/history.png" alt="">测评历史记录</h2>
                        </div>
                        <ul class="list-item">
                            <el-scrollbar style="height:100%">
                                <div v-if="!noList">
                                    <li v-for="(item, index) in resultList" :key="item.id">
                                        <div class="item">
                                            <span class="num">{{index | formartIndex}}</span>
                                            <span class="date">{{item.endTime}}</span>
                                            <span class="time">用时：{{item.duration}}秒</span>
                                        </div>
                                        <div class="btn" @click="viewReport(item.id)">查看报告</div>
                                    </li>
                                </div>
                                <div v-else class="list-none">
                                    <img src="../asset/images/nodata.png" alt="">
                                    <p>暂无测评记录，赶快去测评吧～</p>
                                </div>
                            </el-scrollbar>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'assess',
    components: {},
    data() {
        return {
            resultList: [],
            noList: false,
            assessId: this.$route.query.assessId,
            refId: this.$route.query.refId,
            refType: this.$route.query.refType,
            evaluation: {},
            prohibit: false,
            btnName: '',
            pathName: this.$route.query.pathName,
            backModule: this.$route.query.backMoudle,
            goodsId: this.$route.query.goodsId
        };
    },
    filters: {
      formartIndex(index) {
        if (index+1<10) {
          return '0'+(index+1)
        }
        return index+1
      }
    },
    mounted() {
        this.findByIdAndRef();
        this.historyList();
        this.returnButton();
        if (this.pathName) {
            sessionStorage.setItem( "assessPathName", this.pathName );
            sessionStorage.setItem( "assessBackModule", this.backModule );
            sessionStorage.setItem( "assessGoodsId", this.goodsId );
        }
        this.assessPathName = this.pathName || sessionStorage.getItem( "assessPathName" );
        this.assessBackModule = this.backModule || sessionStorage.getItem( "assessBackModule" );
        this.assessGoodsId = this.goodsId || sessionStorage.getItem( "assessGoodsId" );
    },
    methods: {
        // 浏览器回退按钮
        returnButton(){
            const that = this;
            if (this.$route.name == 'evaluation') {
                if (window.history && window.history.pushState) {
                    window.onpopstate = function() {
                        window.history.pushState('forward', null, '#');  
                        window.history.forward(1);
                        that.goBack();
                    }
                }
                window.history.pushState('forward', null, '#'); //在IE中必须得有这两行  
                window.history.forward(1);
            }
        },
        findByIdAndRef() {
            this.$api.assess.findByIdAndRef(
                {
                    assessId: this.assessId,
                    refId: this.refId,
                    refType: this.refType
                }
            ).then((res) => {
                if(res.data){
                    this.evaluation = res.data;
                    if ((this.evaluation.frequencyStatus==0)||(this.evaluation.frequencyStatus==1)) {
                        this.prohibit = false;
                        this.btnName = '开始测评';
                    } else {
                        this.prohibit = true;
                        if (this.evaluation.frequencyStatus==2) {
                            this.btnName = '测评机会已用完';
                        } else if (this.evaluation.frequencyStatus==3) {
                            this.btnName = '测评已过期';
                        } else {
                            this.btnName = '开始测评';
                        }
                    }
                }
            });
        },
        historyList() {
            this.$api.assess.historyList(
                {
                    assessId: this.assessId,
                    refId: this.refId,
                    refType: this.refType
                }
            ).then((res) => {
                if(res.data){
                    this.resultList = res.data.list;
                    if (this.resultList.length == 0) {
                        this.noList = true;
                    }
                }
            });
        },
        // 返回
        goBack() {
            if(this.assessPathName === '/personalCenter'){
                //返回我的学习
                sessionStorage.setItem('userCenterCurrentTab','我的学习')
            }
            this.$router.push({
                path: this.assessPathName,
                query:{
                    backModule:this.assessBackModule,
                    goodsId: this.assessGoodsId
                }
            })
        },
        // 查看报告
        viewReport(id) {
            this.$router.push({
                path:'/reportDetails',
                query:{
                    id: id,
                    type: 'result'
                }
            })
        },
        // 开始测评
        startEvaluation() {
            if (!this.prohibit) {
                this.$router.push({
                    path:'/evaluation',
                    query: {
                        assessId: this.assessId,
                        refId: this.refId,
                        refType: this.refType
                    }
                })
            }
            // 千万别删！！！
            // else{
            //     if(this.evaluation.frequencyStatus==4){
            //         this.$message.warning('还未到下一次测评时间')
            //     }
            // }
        }
    }
};
</script>

<style scoped lang="stylus">
@import '../asset/css/assess.styl';
</style>
