<template>
<div>
  <div
    class="aside"
    :style="`width: ${style.containerWidth}px;`">
      <MENU
        :menuList="menuList"
        :crowd="nav.crowd"
        :isCollapse="isCollapse" />
  </div>
  <div
    ref="main" class="main"
    :style="`
      width: ${style.mainWidth}px;
      padding-left: ${style.containerWidth}px;
      padding-top: ${style.headerHeight}px;
    `">
      <div
        class="header"
        :style="`height: ${style.headerHeight}px;`"
      ><HEAD :isCollapse="isCollapse" /></div>
      <el-breadcrumb class="ml20 mt20" separator="/">
        <el-breadcrumb-item
          v-for="(item, i) in breadcrumb"
          :key="item.path"
          @click.native="rollback(i)"
          class="shiftUp"
        >{{item.meta.title}}</el-breadcrumb-item>
      </el-breadcrumb>
      <router-view />
  </div>
</div>
</template>

<script>
import MENU from './module/menu'
import HEAD from './module/header'
export default {
  name: 'B',
  components: { MENU, HEAD },
  props: {
    nav: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      isCollapse: false,
      style: {
        containerHeight: 0,
        containerWidth: 200,
        headerHeight: 60,
        mainHeight: 0,
        mainWidth: 0,
      },
      routerNest: [],
      existArr: [],
      menuList: [],
      breadcrumb: [],
      navigate: []
    }
  },
  mounted () {
    this.init()
    this.getHeight()
  },
  methods: {
    async init () {
      this.navigate = JSON.parse(sessionStorage.getItem('navigate'))
      await this.getBreadcrumb(this.$route)
      await this.getMenuList()
      await this.getRouterNest(this.routerNest, this.menuList)
    },
    async getBreadcrumb (route) {
      this.breadcrumb = []
      let pathStr = route.path
      let pathArr = pathStr.split('/').slice(1)
      pathArr.forEach(() => {
        this.navigate.forEach(item => {
          if (item.path === pathStr && item.label) {
            this.breadcrumb.unshift(item)
          }
        })
        pathStr = pathStr.split('/')
        pathStr.pop(0)
        pathStr = pathStr.join('/')
      })
    },
    rollback (i) {
      const storey = (i + 1) - this.breadcrumb.length
      if (storey) {
        this.$router.go(storey)
      }
    },
    setCollapse () {
      this.isCollapse = !this.isCollapse
      if (this.isCollapse) {
        this.style.containerWidth = 64
      } else {
        this.style.containerWidth = 200
      }
      this.getHeight()
    },
    getHeight () {
      const height = window.innerHeight
      this.style.containerHeight = height;
      this.style.mainHeight = this.style.containerHeight - this.style.headerHeight

      const width = window.innerWidth
      const mainWidth = width - this.style.containerWidth - 9
      if (mainWidth <= 960) {
        this.style.mainWidth = 960
        return
      }
      this.style.mainWidth = mainWidth
    },
    async getMenuList () {
      this.routerNest = []
      if (!this.navigate && !this.navigate.length) return
      this.navigate.forEach(item => {
        if (item.name && item.meta.show !== false) {
          this.routerNest.push(item)
        } else if (item.children && item.children.length) {
          item.children.forEach(el => {
            this.routerNest.push(el)
          })
        }
      })
    },
    async nextLevel (obj, nest, i) {
      obj.forEach(item => {
        const depth = item.path.split('/').slice(1)
        nest.forEach(rtr => {
          if (rtr.path === `/${depth[0]}`) {
            if (depth.length === i && i === 2) {
              if (!item.children) {
                item.children = []
              }
              rtr.children.push(item)
              this.existArr.push(item.name)
            }
            if (depth.length === i && i === 3) {
              rtr.children.forEach(children => {
                if (children.path.includes(depth[1])) {
                  if (!item.children) {
                    item.children = []
                  }
                  children.children.push(item)
                  this.existArr.push(item.name)
                }
              })
            }
          }
        })
      })
    },
    async getRouterNest (obj, nest) {
      obj.forEach(item => {
        const depth = item.path.split('/').slice(1)
        if (depth.length === 1) {
          if (!item.children) {
            item.children = []
          }
          nest.push(item)
          this.existArr.push(item.name)
        }
      })
      obj.length && obj.forEach(async (item, index) => {
        if (obj && obj.length && index < 2) {
          await this.nextLevel(obj, nest, index + 2)
          obj.forEach((item, index) => {
            if (this.existArr.includes(item.name)) {
              obj.splice(index, 1)
            }
          })
        }
      })
    }
  },
  created () {
    window.addEventListener('resize', this.getHeight);
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight);
  },
  watch: {
    '$route':'getBreadcrumb'
  }
}
</script>

<style lang="stylus" scoped>
.aside, .header
  position fixed

.aside
  z-index 999
  top 0px
  left 0px
  height 100%
  background-color #011E3D

.header
  z-index 999
  top 0px
  width 100%
  background-color #fff

/deep/ .shiftUp
  cursor pointer
  font-weight 900

/deep/ .el-menu
  border none
  overflow-x auto !important

/deep/ .el-menu-item, /deep/ .el-submenu__title
  i, &>span
    color #5876B3
/deep/ .is-active
  // background-color #3E6CE7 !important
/deep/ .el-menu-item.is-active
  i, &>span
    color #fff !important
</style>
