import { render, staticRenderFns } from "./sdjz_yanglao2.vue?vue&type=template&id=1b50c6c5&scoped=true&"
import script from "./sdjz_yanglao2.vue?vue&type=script&lang=js&"
export * from "./sdjz_yanglao2.vue?vue&type=script&lang=js&"
import style1 from "./sdjz_yanglao2.vue?vue&type=style&index=1&id=1b50c6c5&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b50c6c5",
  null
  
)

export default component.exports