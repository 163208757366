import TRTC from 'trtc-js-sdk';
import { tryTimes } from './trytimes';
const INIT    =  'INIT';
const JOINED  =  'JOINED';
const LIVING  =  'LIVING';

export function client(userId, sdkAppId, userSig) {
      let state = INIT //  ['init', 'joined', 'living', 'leaved']
      const client = TRTC.createClient({
            mode: 'rtc',
            sdkAppId,
            userId,
            userSig
      });

      let studentStream;

      let leaveAndJoin;

      client.on('error', (error) => {
            console.error('client error observed: ',  error);
            leaveAndJoin();
      });

      client.on('stream-added', event => {
            const remoteStream = event.stream;
            const remoteUserId = remoteStream.getUserId();
            console.log('监听到来自', remoteUserId, '的信息');
            if (userId.split('-')[1] === remoteUserId.split('-')[1]) {
                  client.unsubscribe(remoteStream);
            } else {
                  if (remoteUserId === ('student-' + localStorage.getItem('currentStudentCallId'))) {
                        client.subscribe(remoteStream);
                  } else {
                        client.unsubscribe(remoteStream);
                  }
            }
      });
      client.on('network-quality', event => {
        // 监听推流网络状态
        localStorage.setItem('uplinkNetworkQuality',event.uplinkNetworkQuality)
      });
      client.on('stream-removed', (event) => {
            const remoteStream = event.stream;
            remoteStream.stop();
            const remoteUserId = remoteStream.getUserId();
            if (remoteUserId === ('student-' + localStorage.getItem('currentStudentCallId'))) {
                handler.onHangUpFromStudent && handler.onHangUpFromStudent(); // 学生挂断电话 触发 onHangUp
            }
      });

      client.on('stream-subscribed', (event) => {
            const remoteStream = event.stream;
            const remoteUserId = remoteStream.getUserId();
            if (remoteUserId === ('student-' + localStorage.getItem('currentStudentCallId'))) {
                  studentStream = remoteStream;
                  let audioBox = document.getElementById(remoteUserId);
                  if (audioBox == null) {
                        audioBox = document.createElement('div');
                        //audioBox.style.display = 'none';
                        audioBox.setAttribute("id", remoteUserId);
                        document.body.appendChild(audioBox);
                  }
                  remoteStream.play(audioBox)
                  handler.onAnswerFromStudent && handler.onAnswerFromStudent()
            }
      });

      let localStream;

      function startPlay(localStream, el) {
            el.innerHTML = '';
            localStream.play(el, { objectFit: 'contain' }).then(() => {
                  console.log('正在播放中...');
                  if (localStream.getUserId().startsWith('teacher-')) {
                        handler.muteVideo();
                  }

              // const tid =setInterval(() => {
              //           console.log('6秒钟+++++++++')
              //           const frame = localStream.getVideoFrame();
              //           console.log(frame,'sdfsdfafsfsfsdfsdfsdafasd');
              //           if(frame){
              //                 const img = document.getElementById("detImg");
              //                 img.src = frame;
              //
              //           }
              //
              //
              // }, 3000);
              //
              //
              //     const refreshFinishTime = () => {
              //           clearInterval(tid);
              //
              //     }
              //
              //    setTimeout(refreshFinishTime, 30 * 1000)  // 每隔 5 分钟刷一次 结束时间



            }).catch((error) => {
                  const errorCode = error.getCode();
                  if (errorCode === 0x4042) {
                        console.log('0x4042 错误');
                        const resume = () => {
                              localStream.resume();
                              el.removeEventListener('click', resume);
                        }
                        el.addEventListener('click', resume);
                  }
            });
      }

      const handler = {

            hangUp() { // 关闭连麦电话
                  if (studentStream) {
                        const audioTrack = studentStream.getAudioTrack();
                        if (audioTrack) {
                              audioTrack.stop();
                        }
                        studentStream.stop();
                        studentStream.close();
                        studentStream = null;
                  }
            },

            joinRoom(roomId) {
                  roomId = parseInt(roomId);
                  leaveAndJoin = () => {
                        return new Promise((resolve, reject) => {
                              handler.leaveRoom().then(() => {
                                    handler.joinRoom(roomId).then(() => {
                                          resolve();
                                    }).catch((error) => {
                                          reject(error);
                                    });
                              }).catch((error) => {
                                    reject(error);
                              });
                        });
                  };

                  if (state === INIT) {
                        tryTimes((retry) => {
                              client.join({ roomId }).then(() => {
                                    state = JOINED;
                                    this.onJoined && this.onJoined(state);
                              }).catch((error) => {
                                    if (!retry()) {
                                          console.error('进房失败 请刷新一下网页再试一次', error);
                                          alert('进房失败 请刷新一下网页再试一次');
                                          history.back();
                                    }
                              });
                        });
                  }
            },

            async startLiving(el) {
                  if (state !== JOINED) {
                        return;
                  }
                  this.onLiveStarting && this.onLiveStarting();
                  let cameraDevices = await this.cameraDevices();
                  let microphoneDevices = await this.microphoneDevices()
                  let cameraId = cameraDevices[0] && cameraDevices[0].deviceId;
                  let micId = microphoneDevices[0] && microphoneDevices[0].deviceId
                  const config = { userId, audio: true, video: true};
                  if (cameraId) {
                        config.cameraId = cameraId;
                  }
                  if (micId) {
                        config.microphoneId = micId;
                  }
                  localStream = TRTC.createStream(config);
                  localStream.setVideoProfile('720p');
                  localStream.initialize().then(() => {
                        client.publish(localStream).then(() => {
                              console.log('本地流发布成功');
                              startPlay(localStream, el);
                              state = LIVING;
                              
                              this.onLiving && this.onLiving(state);

                              this.onLivingL && this.onLivingL(localStream);

                        }).catch((error) => {
                              console.error('本地流发布失败', error);
                        });
                  }).catch((error) => {
                        switch (error.name) {
                              case 'NotFoundError':
                                    alert('请检查通话所需的摄像头或麦克风等外设是否存在');
                                    break;
                              case 'NotAllowedError':
                                    alert('不授权摄像头/麦克风访问无法进行音视频通话');
                                    break;
                              case 'NotReadableError':
                                    alert('暂时无法访问摄像头/麦克风，请确保当前没有其他应用请求访问摄像头/麦克风，并重试');
                                    break;
                              case 'OverConstrainedError':
                                    alert('确保 cameraId/microphoneId 传值正确且有效');
                                    break;
                              default:
                                    alert('暂时无法访问摄像头/麦克风，请确保当前没有其他应用请求访问摄像头/麦克风，并重试');
                                    break;
                        }
                  });
            },

            stopLiving(callback) {
                  if (state === LIVING) {
                        this.onLiveStoping && this.onLiveStoping(state);
                        client.unpublish(localStream).then(() => {
                              const audioTrack = localStream.getAudioTrack();
                              if (audioTrack) {
                                    audioTrack.stop();
                              }

                              const videoTrack = localStream.getVideoTrack();
                              if (videoTrack) {
                                    localStream.removeTrack(videoTrack).then(() => {
                                          videoTrack.stop();
                                          localStream.stop();
                                          state = JOINED;
                                          this.onUnLiving && this.onUnLiving(state);
                                          callback && callback();
                                    }).catch((error) => {
                                          console.log(error);
                                          callback && callback();
                                    })
                              } else {
                                    callback && callback();
                              }
                        }).catch((error) => {
                              console.log(error)
                              callback && callback();
                        });
                  } else {
                        callback && callback();
                  }
            },
            leaveRoom() {
                  return new Promise((resolve, reject) => {
                        const leave = () => {
                              client.leave().then(() => {
                                    state = INIT;
                                    this.onLeaveRoom && this.onLeaveRoom(state);
                                    resolve();
                              }).catch(error => {
                                    console.error('leaving room failed: ' + error);
                                    reject(error)
                              });
                        }
                        if (state === LIVING) {
                              this.stopLiving(() => {
                                    leave();
                              });
                        } else {
                              leave();
                        }
                  });
            },
            switchVideoDevice(newCameraId) {
                  if (localStream) {
                        localStream.switchDevice('video', newCameraId);
                  }
            },
            switchMicDevice(newMicID) {
                  if (localStream) {
                        localStream.switchDevice('audio', newMicID);
                  }
            },
            cameraDevices() {
                  return TRTC.getCameras();
            },
            microphoneDevices() {
                  return TRTC.getMicrophones();
            },
            muteAudio() {
                  localStream.muteAudio()
            },

            unmuteAudio() {
                  localStream.unmuteAudio()
            },
            muteVideo() {
                  localStream.muteVideo()
            },
            unmuteVideo() {
                  localStream.unmuteVideo()
            },

            canJoinRoom() {
                  return state === INIT;
            },
            canStartLiving() {
                  return state === JOINED;
            },
            canStopLiving() {
                  return state === LIVING;
            },
            canLeaveRoom() {
                  return state === INIT;
            },
      }

      return handler;
}
