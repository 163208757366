<template>
  <!-- 考试详情 -->
  <div class="courseTraining">
          <dialog-commission-equ  :urlH5Str="urlH5Str" :isShowDialog="isShowDialog"  :examID="String(examId)" @equShow="equShow"></dialog-commission-equ>
      <div class="backButton">
      <span @click="goBack">
        <i class="back el-icon-arrow-left"></i>
        返回
      </span>
    </div>
    <div class="train-info">
      <div class="train-name" v-if="examDetailsTitle">
        {{ examDetailsTitle }}
      </div>
      <div>
        <span
          class="train-date"
          v-if="examDetailsTimeSlot"
          >{{ examDetailsTimeSlot }}
        </span>
      </div>
    </div>
    <section>
      <aside>
        <img v-if="examInfo.backImage" :src="examInfo.backImage" alt="" />
        <img v-else src="../asset/images/4.png" alt="" />
      </aside>
      <article class="no-course">
        <h2 class="no-course-title">
          <span class="course-name">{{ examInfo.name }}</span>
          <span class="course-type">{{
            examInfo.type === 1 ? "模拟考试" : "正式考试"
          }}</span>
        </h2>
        <p class="exam-details-ico" v-if="examInfo.result !== -1">
          <img :src="examInfo.result | examresult" />
        </p>
        <div class="exam-card">
          <div class="exam-list">
            <div class="card-list">
              <p class="fraction">{{ examInfo.totalQuestionNum || 0 }}</p>
              <p class="title">总题数(道)</p>
              <img src="../asset/images/zts.png" alt="" />
            </div>
            <div class="card-list zfz">
              <p class="fraction">{{ examInfo.totalScore || 0 }}</p>
              <p class="title">总分值(分)</p>
              <img src="../asset/images/zfz.png" alt="" />
            </div>
            <div class="card-list jgf">
              <p class="fraction">{{ examInfo.passingScore || 0 }}</p>
              <p class="title">及格线(分)</p>
              <img src="../asset/images/jgf.png" alt="" />
            </div>
            <div class="card-list kssc">
              <p class="fraction">{{ examInfo.duration || 0 }}</p>
              <p class="title">考试时长(分钟)</p>
              <img src="../asset/images/kssc.png" alt="" />
            </div>
            <div class="left"></div>
            <div class="right"></div>
          </div>
          <div class="exam-date">
            <span class="title">考试时间：</span>
            <span
              class="time"
              v-if="
                examInfo.isLimitTime &&
                examInfo.examStartDate &&
                examInfo.examEndDate
              "
            >
              {{ examInfo.examStartDate.slice(0, -3) }}至{{
                examInfo.examEndDate.slice(0, -3)
              }}
            </span>
            <span class="time" v-if="!examInfo.isLimitTime">长期有效</span>
            <span class="over" v-show="examInfo.ifEnd === 1">已结束</span>
            <div class="exam-details-footer" v-if="examInfo.ifEnd !== 1">
              <div class="exam-details-exam" :class="examInfo.showDebugButton ? 'exam-details-width' : ''">
                <p v-if="examInfo.ifStart === 0" class="exam-details-btn nostart">
                  <span>考试尚未开始</span>
                </p>
                <p
                  v-if="
                    examInfo.ifStart === 1 &&
                    examInfo.totalExamNum == 0 &&
                    examInfo.isLocked == true
                  "
                  class="exam-details-btn nobgc"
                  @click="examtip"
                >
                  <span
                    ><img src="../asset/images/lock.png" alt="" />进入考场</span
                  >
                </p>
                <p
                  v-if="examInfo.ifStart === 1 && examInfo.totalExamNum == 0"
                  class="exam-details-btn"
                  @click="realName"
                >
                  <span>进入考场</span>
                </p>
                <p
                  v-if="
                    examInfo.ifStart === 1 &&
                    examInfo.leftExamNum === 0 &&
                    examInfo.totalExamNum !== 0
                  "
                  class="exam-details-btn nostart"
                >
                  <span>已无考试剩余次数</span>
                </p>
                <p
                  v-if="
                    examInfo.ifStart === 1 &&
                    examInfo.leftExamNum > 0 &&
                    examInfo.totalExamNum !== 0 &&
                    examInfo.isLocked == true
                  "
                  class="exam-details-btn nobgc"
                  @click="examtip"
                >
                  <span
                    ><img src="../asset/images/lock.png" alt="" /> 进入考场
                  </span>
                </p>
                <p
                  v-if="
                    examInfo.ifStart === 1 &&
                    examInfo.leftExamNum > 0 &&
                    examInfo.totalExamNum !== 0
                  "
                  class="exam-details-btn"
                  @click="realName"
                >
                  <span> 进入考场 </span>
                </p>
                <p v-if="examInfo.totalExamNum > 0" class="examChance">
                  考试剩余机会：{{ examInfo.leftExamNum }}次/ 共{{
                    examInfo.totalExamNum
                  }}次
                </p>
              </div>
              <div class="exam-details-debug" v-if="examInfo.showDebugButton">
                <p @click="commissionEqu" class="exam-details-debugbtn">
                  <span>调试设备</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="history-list">
          <div class="title">
            <h2>
              <img src="../asset/images/history.png" alt="" />历史考试记录
            </h2>
            <el-tooltip placement="left" effect="dark" popper-class="atooltip">
              <div slot="content" class="con">
                异常退出的考试，考试记录会延迟显示，请耐心等待。
              </div>
              <i class="el-icon-info tip-icon"></i>
            </el-tooltip>
          </div>
          <ul class="list-item">
            <el-scrollbar style="height: 100%">
              <div v-if="!noList">
                <li v-for="(item, index) in historyList" :key="item.id">
                  <div class="item">
                    <span class="num">{{ index | formartIndex }}</span>
                    <span class="date">{{ item.examStartTime }}</span>
                    <span class="time">用时：{{ item.useDuration }}</span>
                    <span class="score" v-if="item.scoreAuth">
                      得分：
                      <i v-if="item.paperType == 2 && !item.isReadOver"><span class="modify">阅卷中</span></i>
                      <i v-else><span class="score-num">{{item.score}}</span>分</i>
                      <span class="invalid" v-if="(item.paperType == 1 && item.invalidType) || (item.paperType == 2 && item.isReadOver && item.invalidType)">（异常）</span>
                    </span>
                    <span class="score" v-if="!item.scoreAuth && item.paperType == 2 && !item.isReadOver">
                      得分：
                      <i><span class="modify">阅卷中</span></i>
                    </span>
                  </div>
                  <div class="btn" @click="viewDetails(item)">查看详情</div>
                </li>
              </div>
              <div v-else class="list-none">
                <img src="../asset/images/nodata.png" alt="" />
                <p>暂无考试记录，赶快去考试吧～</p>
              </div>
            </el-scrollbar>
          </ul>
        </div>
      </article>
    </section>
    <facedialog
      v-if="verification"
      :address="verificationAddress"
      :failAddress="verificationFailAddress"
      :type="2"
      :pauseFrame="true"
    >
    </facedialog>
    <realName v-if="realNameDialog"></realName>
    <el-dialog
      class="warning-dialog"
      :visible.sync="warningdialogVisible"
      width="400px"
    >
      <img src="../asset/images/tipimg.png" class="icon-img" alt="">
      <p class="titles">提示</p>
      <p class="text">{{dialogCon}}</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 100%" @click="warningdialogVisible = false"
          >知道了</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import facedialog from "@/layout/facedialog.vue";
import realName from "@/layout/realName.vue";
import dialogCommissionEqu from '../../train/page/dialogCommissionEqu'
import cercertificateIsShowStatus from "@/utils/certificateMixin";
import DialogCommissionEqu from '../../train/page/dialogCommissionEqu';
import md5 from "js-md5";
export default {
  name: "examDetails",
  components: {
      dialogCommissionEqu,
      facedialog,
      realName,
      DialogCommissionEqu

  },
  data() {
    return {
      examInfo: {},
      historyList: [],
      noList: false,
      title: this.$route.query.title,
      timeSlot: this.$route.query.timeSlot,
      bussinessId: this.$route.query.bussinessId,
      examId: this.$route.query.examId,
      type: this.$route.query.type,
      tipcon: "",
      realNameDialog: false,
      verification: false,
      verificationAddress: {},
      verificationFailAddress: {},
      pathName: this.$route.query.pathName,
      backModule: this.$route.query.backMoudle,
      closeRealName: null,
      isFaceRecogBeforeExam: '',
      isFaceRecogDuringExam: '',
      isFaceRecogCommitExam: '',
      warningdialogVisible: false,
      dialogCon: '',// 弹框提示文案
      urlH5Str:'',
      isShowDialog:false
    };
  },
  mixins: [cercertificateIsShowStatus],
  computed: {},
  filters: {
    examresult(val) {
      if (val === 0) {
        return require("../asset/images/bujige@2x.png");
      } else if (val === 1) {
        return require("../asset/images/jige@2x.png");
      } else if (val === 2) {
        return require("../asset/images/weizuoda@2x.png");
      }
    },
    dataFormat(msg) {
      if (msg) {
        const date = new Date(msg);
        const m = date.getMonth() + 1;
        return date.getFullYear() + "." + m + "." + date.getDate();
      }
    },
    formartIndex(index) {
      if (index + 1 < 10) {
        return "0" + (index + 1);
      }
      return index + 1;
    },
  },
  watch: {
    'closeRealName'(val){
      if (val==false) {
        this.realNameDialog = false;
      }
    },
  },
  created() {
    this.findExamListByIds();
    this.findUserPaperListById();
    sessionStorage.setItem( "examDetailsTitle", this.title );
    sessionStorage.setItem( "examDetailsTimeSlot", this.timeSlot );
    this.examDetailsTitle = this.title;
    this.examDetailsTimeSlot = this.timeSlot;
    if (this.pathName) {
      sessionStorage.setItem( "examPathName", this.pathName );
      sessionStorage.setItem( "examBackModule", this.backModule );
    }
    this.examPathName = this.pathName || sessionStorage.getItem( "examPathName" );
    this.examBackModule = this.backModule || sessionStorage.getItem( "examBackModule" );
  },
  methods: {
      // 调试设备
      async commissionEqu () {
          console.log('设备调试++++++++99999')
          let CheckLiveParam = {
              channel: 1,
              businessId: this.$route.query.bussinessId,
              examId:this.$route.query.examId,
              businessType: this.$route.query.type
          }
          console.log(this.$route.query.examId,'this.$route.query.examId')
          if(localStorage.getItem('LiveParam')){
              localStorage.removeItem('LiveParam')
          }
          console.log(this.$route.query.bussinessId,'this.$route.query.bussinessIdthis.$route.query.bussinessId')
          localStorage.setItem('CheckLiveParam',JSON.stringify(CheckLiveParam))
          let localDomain = JSON.parse(window.localStorage.getItem('domain')).filter(item=>item.frontend_template_id=='4')
          let paramURL = 'https://' + localDomain[0].domain + '/device_check?'
          // let paramURL = 'https://hrss-stu.testing2.wdeduc.com' + '/device_check?'

          let qureyS =
              this.$route.query.examId+ '&='
              + this.$route.query.type + '&='
              + this.$route.query.bussinessId+ '&='
              + localStorage.getItem('organ')+ '&='
              + localStorage.getItem('actor')+ '&='
              + localStorage.getItem('token')+ '&='
              + localStorage.getItem('branch')
          let params =
              {
                  'token': qureyS
              }
          console.log('query1231231231',qureyS)
          console.log('this.businessId',this.businessId)

          let res = await this.$api.exam.saveTKData(params)
          console.log(res.data,'-0==-=-=-=')
          this.urlH5Str = paramURL + 'tokens=' +res.data
          console.log('弹出设备调试++++++++')
          this.isShowDialog = true
      },
      equShow(val){
          this.isShowDialog = val
      },
    // 返回
    goBack() {
      if (this.examPathName == '/learning') {
        this.$router.push({
          path: this.examPathName,
          query: {
            backModule:this.examBackModule
          },
        });
      } else if (this.examPathName == '/learning/taskDetail') {
        this.$router.push({
          path: this.examPathName,
          query: {
            id: this.bussinessId,
            type: this.$route.query.type
          },
        });
      } else if (this.examPathName == '/examList') {
        this.$router.push({
          path: this.examPathName,
          query: {},
        });
      }else if(this.examPathName === '/personalCenter'){
          sessionStorage.setItem('userCenterCurrentTab','我的学习')
          this.$router.push({
            path: this.examPathName,
        });
      }
    },
    // 获取考试详情
    findExamListByIds() {
      this.$api.examination
        .findExamListByIds(this.bussinessId, this.$route.query.examId, this.type)
        .then((res) => {
          if (res.data) {
            this.examInfo = res.data;
            // isOpenPCCamera 是否开启电脑摄像头(1:是;0:否)
            // isOpenPhoneCamera 是否开启手机摄像头(1:是;0:否)
            // showDebugButton 是否显示调试设备按钮(true:是;false:否)
            // beLate 是否迟到(true:是;false:否)
            if (this.examInfo.isOpenPCCamera) {
              this.isFaceRecogBeforeExam = 0;
              this.isFaceRecogDuringExam = 0;
              this.isFaceRecogCommitExam = 0;
            } else {
              this.isFaceRecogBeforeExam = this.examInfo.isFaceRecogBeforeExam;
              this.isFaceRecogDuringExam = this.examInfo.isFaceRecogDuringExam;
              this.isFaceRecogCommitExam = this.examInfo.isFaceRecogCommitExam;
            }
            sessionStorage.setItem('isFaceRecogBeforeExam',JSON.stringify(this.isFaceRecogBeforeExam));
            sessionStorage.setItem('isFaceRecogDuringExam',JSON.stringify(this.isFaceRecogDuringExam));
            sessionStorage.setItem('isFaceRecogCommitExam',JSON.stringify(this.isFaceRecogCommitExam));
          }
        });
    },
    // 获取考试历史记录
    findUserPaperListById() {
      this.$api.examination
        .findUserPaperListById(this.bussinessId, this.examId, this.type)
        .then((res) => {
          if (res.data) {
            res.data.list.forEach((item) => {
              item.useDuration = this.timeFormatter(item.useDuration);
            });
            this.historyList = res.data.list;
            if (this.historyList.length > 0) {
              this.noList = false;
            } else {
              this.noList = true;
            }
          }
        });
    },
    // 进入考场
    exampath() {
      if(this.examInfo.isLimitTime && this.$dateFormat.dateFormat() < this.examInfo.examStartDate){
        this.$message.warning('该考试时间未到')
      }else {
        if (this.isFaceRecogBeforeExam == 1) {
          this.verification = true;
          this.verificationAddress = {
            path: "/course/resdExam",
            query: {
              examId: this.examInfo.examId,
              businessId: this.bussinessId,
              dymode: this.$route.query.dymode,
              taskType: this.type, //taskType为1，则为从培训任务跳过来的
              // 入口
              source: 'examDetails'
            },
          };
          this.verificationFailAddress = {};
        } else {
          this.$router.push({
            path: "/course/resdExam",
            query: {
              examId: this.examInfo.examId,
              businessId: this.bussinessId,
              dymode: this.$route.query.dymode,
              taskType: this.type,
              // 入口
              source: 'examDetails'
            },
          });
        }
      }
    },
    // 判断是否已实名
    realName() {
      if (this.examInfo.beLate) {
        this.dialogCon = '您已超过最迟开考时间！';
        this.warningdialogVisible = true;
      } else if (this.examInfo.isOpenPCCamera) {
        this.judgefaceauth('device');
      } else {
        if (this.isFaceRecogBeforeExam == 0 && this.isFaceRecogCommitExam == 1 ||
        this.isFaceRecogBeforeExam == 0 && this.isFaceRecogDuringExam == 1 ) {
          this.judgefaceauth('goExam');
        } else {
          this.exampath();
        }
      }
    },
    // 是否实名
    judgefaceauth(type) {
      const goPath = type;
      this.$api.face.judgefaceauth({data:{}}).then((res)=>{
        var authToken = localStorage.getItem("token");
        var authTokenParam = authToken + "true";
        var authMd5Param = md5(authTokenParam).toLowerCase();
        var authResData = res.data && res.data.toLowerCase();
        if(authMd5Param !== authResData){
          this.realNameDialog = true;
          this.closeRealName = true;
        } else {
          // 类型为去考试--进入考场
          if (goPath == 'goExam') {
            this.exampath();
          } else {
              // 判断是否调整好了设备
              let params = {
                  businessId: this.$route.query.bussinessId,
                  examId:this.examInfo.examId,
                  businessType: this.$route.query.type
              }
              this.$api.exam.queryLinkStatusAndPhoneLive(params).then(res=>{
                  if(res.data.isOpenPhoneCamera==1){ // 开启了h5
                      if (res.data.pcLiveStatus&&res.data.phoneLiveStatus) {
                          this.exampath();
                      } else {
                          this.dialogCon = '检测到您设备未调整完善，为保证考试顺畅，请调整好设备后再进入考场';
                          this.warningdialogVisible = true;
                      }
                  }else if(res.data.isOpenPhoneCamera==0){ // 没有开启h5
                      if (res.data.pcLiveStatus) {
                          this.exampath();
                      } else {
                          this.dialogCon = '检测到您设备未调整完善，为保证考试顺畅，请调整好设备后再进入考场';
                          this.warningdialogVisible = true;
                      }
                  }


              })
          }
        }
      })
    },
    examtip() {
      if (this.examInfo.lockCourseRate && this.examInfo.lockExamCondition) {
        this.tipcon = `该考试未开启，开启条件为前面的课程学习进度达到 ${this.examInfo.lockCourseRate}%，考试及格`;
      } else if (this.examInfo.lockCourseRate) {
        this.tipcon = `该考试未开启，开启条件为前面的课程学习进度达到 ${this.examInfo.lockCourseRate}%`;
      } else if (this.examInfo.lockExamCondition) {
        this.tipcon = `该考试未开启，开启条件为前面的考试及格`;
      }
      this.$message({
        showClose: true,
        message: this.tipcon,
        type: "warning",
      });
    },
    // 查看详情
    viewDetails(item) {
      if (item.paperType == 2 && !item.isReadOver) {
        this.$message({
          message: '阅卷中，请耐心等待',
          type: 'warning'
        });
      } else if (this.examInfo.examId) {
        this.$router.push({
          path: "/course/handPaper",
          query: {
            userPaperId: item.id,
            businessId: this.bussinessId,
            examId: this.examInfo.examId,
            // 用来判断是否显示重考按钮
            type: "historyList",
            dymode: this.$route.query.dymode,
            //taskType为1，则为从培训任务跳过来的
            taskType: this.type,
            // 入口
            source: 'examDetails'
          },
        });
      }
    },
    timeFormatter(time) {
      const hours = Math.floor(time / 60 / 60);
      const minutes = Math.floor((time / 60) % 60);
      const seconds = Math.floor(time % 60);
      var hours1 = hours < 10 ? "0" + hours : hours;
      var minutes1 = minutes < 10 ? "0" + minutes : minutes;
      var seconds1 = seconds < 10 ? "0" + seconds : seconds;
      return hours1 + "时" + minutes1 + "分" + seconds1 + "秒";
    },
  },
};
</script>
<style lang="stylus" scoped>
.training {
  /deep/.el-collapse {
    border: none;
  }

  /deep/.el-collapse-item__arrow {
    display: none;
  }

  /deep/.el-collapse-item__header {
    background: rgba(49, 111, 255, 0.07);
    border: 1px solid #D7DCEE;
    padding: 0 12px;
    height: 58px;
    line-height: 58px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1B2257;

    img {
      margin-right: 4px;
    }
  }

  /deep/.el-collapse-item {
    margin-bottom: 16px;
  }

  /deep/.el-collapse-item__wrap {
    border: 1px solid #D7DCEE;
    border-top: none;
  }

  /deep/.el-collapse-item__content {
    padding: 12px 16px;
  }

  /deep/.el-collapse-item__header.is-active {
    border-bottom-color: #d7dcee;
  }
}
</style>
<style lang="stylus" scoped>
@import '../asset/css/examDetails.styl';
</style>
<style lang="stylus">
.el-tooltip__popper.is-dark {
  .con {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
  }
}

.el-tooltip__popper .popper__arrow {
  top: 12px !important;
}

// 兼容火狐
@-moz-document url-prefix() {
  .training section {
    .no-course .history-list {
      overflow: hidden;

      .list-item {
        margin-right: -17px;
        margin-bottom: -17px;
      }
    }
  }
}
</style>
