<!-- 
 * @description: 小程序页面
 * @fileName: wechatapplet.vue 
 * @author:ninghf 
 * @date: 2021-06-30 18:09:37
!-->
<template>
    <div class="shandong-applet">
        <div class="shandong-applet-content">
            <div class="applet-content-left">
                <div class="content-top">
                    <p class="title1">营销小程序</p>
                    <p class="title2">打造自己的线上门店，让雇主主动留单</p>
                    <ul>
                        <li>多种营销玩法，提升成交</li>
                        <li>线上渠道开店，海量曝光</li>
                        <li>一键轻松配置小程序</li>
                    </ul>
                </div>
                <div class="content-bottom">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/sdjt/sdjt-er.png" alt="">
                    <p>扫一扫，进入家政商城</p>
                </div>
            </div>
            <div class="applet-content-right">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/sdjt/applet-1.png" alt="">
            </div>
        </div>
        <footers></footers>
    </div>
</template>
<script>
import footers from '../block/front/footers.vue'
export default {
    name:'wechatapplet',
    data(){
        return{

        }
    },
    components:{footers}
}
</script>
<style lang="stylus" scoped>
    .shandong-applet
        background:#FFFCFC;
    .shandong-applet-content
        margin:0 auto;
        max-width 1200px
        display flex
        justify-content space-between
        padding-top:60px;
        padding-bottom 12px
    .applet-content-left
        color:#333;
        padding-top:96px;
        .content-top
            margin-bottom 65px;
            .title1
                font-size:32px;
                line-height :45px;
                margin-bottom 14px;
                font-weight:500;
            .title2 
                font-size:24px;
                line-height 33px;
                margin-bottom 32px;
            ul li
                color:#666;
                font-size:20px;
                line-height 28px;
                margin-bottom 12px;
                padding-left:20px;
                position relative
                &::after
                    width:8px;
                    height 8px;
                    border-radius:50%;
                    background:#C23613;
                    position absolute;
                    left:0;
                    top:50%;
                    transform :translateY(-50%);
                    content :'';
        .content-bottom
            img 
                width:152px
                display block;
                margin-bottom 11px
            p
                font-size:14px;
                line-height 20px
                color:#666;
                text-align center;
                width:152px;
    .applet-content-right
        img 
            max-width:767px;
</style>


