<template>
    <div class="wanghongDetails">
        <!--顶部底图-->
        <div class="banner-wrap" >
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/detail-banner.png" />
        </div>
        <!--课程包信息-->
        <div class="trainng-card-wrap" :class="{'fixed': isFixed}">
            <div class="trainng-card flex lr-flex block-main">
                <div>
                    <div class="flex price-box">
                        <p class="flex">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/red-y.png" class="red"/>
                            <span class="now-price">{{trainObj.salePrice}}</span>
                        </p>
                        <p  class="flex pre-price-box">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/grey-y.png" class="grey"/>
                            <span class="pre-price">{{trainObj.basePrice}}</span>
                        </p>
                        <p class="name" v-if="isFixed">{{trainObj.name}}</p>
                    </div>
                    <p v-if="!isFixed">
                        <span class="name">
                            {{trainObj.name}}
                        </span>
                        <span class="time" v-if="trainObj.validityType === 1">
                            有效期：长期有效
                        </span>
                        <span class="time" v-else-if="trainObj.validityType === 2">
                            有效期：{{dataFormat(trainObj.startTime)}}-{{dataFormat(trainObj.endTime)}}
                        </span>
                        <span class="time" v-if="trainObj.validityType === 4">
                            有效期：报名后{{trainObj.days}} 天内有效
                        </span>

                    </p>
                </div>
                <div class="btn-study" @click="jumpStudy">
                    {{purchased?'立即报名':'立即学习'}}
                </div>
            </div>
        </div>
        <!--课程特色-->
        <div class="feature-block">
            <div class="top-course">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/detail-top-title.png" />
            </div>
            <div class="block-main flex lr-flex">
                <div class="item">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/features1.png" />
                    <p>重实战落地的学习模式</p>
                    <div class="desc">从理论学习，到案例实操，还原真实直播场景</div>
                </div>
                <div class="item">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/features2.png" />
                    <p>奇驴科技网红扶持</p>
                    <div class="desc">面向优秀学员提供账号养号、平台推荐、流量扶持等。</div>
                </div>
                <div class="item">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/features3.png" />
                    <p>实训课程内容</p>
                    <div class="desc">真实行业案例分析，教师讲解通俗易懂</div>
                </div>
                <div class="item">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/features4.png" />
                    <p>权威机构证书</p>
                    <div class="desc">由国家广播电视总局广播影视人才交流中心颁发证书</div>
                </div>
            </div>
        </div>
        <!--课程大纲-->
        <div class="course-block">
            <div class="block-title">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/courses-title.png" />
            </div>
            <div class="block-main">
                <el-collapse accordion v-model="activeCollapse" >
                    <el-collapse-item
                        :name="item.id" 
                        class="my-collapse-item"  
                        v-for="item in planList" 
                        :key="item.id"
                        :class="{'item-one': item.id === 1, 'item-last': item.id === 4}"
                    >
                        <template slot="title">
                            <span class="stage">
                                {{item.stageNum}}：
                            </span>
                            <span class="name">
                                {{item.stageName}}
                            </span>
                        </template>
                        <div class="info">
                            {{item.describe}}
                        </div>
                        <div class="task-box flex">
                            <div class="zhangjie">
                                <div class="min-card" :class="{'active': chapterList[item.id-1] === index }" v-for="(subitem, index) in item.chapterList" :key="index" @click="clickItem(item.id, index)">
                                    {{subitem.chapter }}
                                </div>
                            </div>
                            <div class="right-box">
                                <div class="txt">
                                    <!-- {{item.tasktitle}} -->
                                    {{item.chapterList[chapterList[item.id-1]].tasktitle}}
                                </div>
                                <p v-for="(ctx, idx) in item.chapterList[chapterList[item.id-1]].taskcontent" :key="idx">
                                    {{ctx}}
                                </p>
                            </div>
                        </div>
                    </el-collapse-item>
                    
                </el-collapse>
            </div>
        </div>
        <!--试听-->
        <div class="listen-block">
            <div class="block-title">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/listens-title.png" />
            </div>
            <div class="block-main">
                <div class="tabs">
                    <p @click="changeCorousel(0)" :class="{active: activeListenIndex===0}">视频一：主播造型设计和直播内容如何协调搭配</p>
                    <p @click="changeCorousel(1)"  :class="{active: activeListenIndex===1}">视频二：美妆、护肤品类销售逻辑与技巧</p>
                </div>
                <el-carousel class="list" arrow="always" height="530px" ref="carousel" :autoplay="false" indicator-position="none" @change="changeCarousel">
                    <el-carousel-item>
                        <div class="carousel-ctx flex lr-flex">
                            <div class="video-wrap">
                                <video 
                                    id="video1" 
                                    src="https://platform-vod.wdcloudnet.com/customerTrans/928e23e5005956f70e333d6b586f6f88/2467bb8d-1777b020959-0005-cd1a-d1c-c3eb7.mp4" 
                                    controls="controls" 
                                    poster="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/poster2.png"
                                    controlslist="nodownload"
                                />
                            </div>
                            <div class="tip-box">
                                <div class="txt">
                                    知识点介绍：
                                </div>
                                <p>
                                    发型分析及设计
                                </p>
                                 <p>
                                    妆容/服装与发型的搭配
                                </p>
                                <p>
                                   个人形象定位及风格定位
                                </p>
                                <p>
                                    配饰的搭配
                                </p>
                                <p>
                                   套装的搭配
                                </p>
                                <p>
                                   运动风的搭配
                                </p>
                                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/listen-info.png" class="bg"/>
                            </div>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <div class="carousel-ctx flex lr-flex">
                            <div class="video-wrap">          
                                <video 
                                    id="video2" 
                                    src="https://platform-vod.wdcloudnet.com/customerTrans/928e23e5005956f70e333d6b586f6f88/3d0a8d85-1777b020938-0005-cd1a-d1c-c3eb7.mp4" 
                                    controls="controls" 
                                    poster="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/poster1.png"
                                    controlslist="nodownload"
                                />
                            </div>
                            <div class="tip-box tip-box2">
                                <div class="txt">
                                    知识点介绍：
                                </div>
                                <p>
                                    用场景化方式吸引受众购买
                                </p>
                                 <p>
                                    从品牌方向推荐产品
                                </p>
                                 <p>
                                    直播中催促下单的技巧
                                </p>
                                 <p>
                                    如何分析受众的购买心理
                                </p>
                                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/listen-info2.png" class="bg"/>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <footers/>
    </div>
</template>

<script>
import planList from './planList.js'
import footers from '../block/front/footers.vue'
export default {
  name: 'wanghongDetails',
  show: true,
  props: {
  },
  components: {
      footers
  },
    data() {
        return {
            isFixed: false,
            activeListenIndex: 0,
            planList: planList,
            chapterList: [0,0,0,0,0,0,0,0,0],
            trainObj:{},
            video1: null,
            video2: null,
            activeCollapse: 1,
            purchased: null,
            goodsId: ''
        };
    },
    mounted() {
        let that = this;
        window.addEventListener("scroll", function () {
            if(document.documentElement.scrollTop > 220){
                that.isFixed = true
            } else {
                that.isFixed = false;
            }
        })
        this.getGoodsById();
        this.video1 = document.getElementById('video1');
        this.video2 = document.getElementById('video2');
    },
    created() {
        
    },
    beforeDestroy() {
        
    },
    methods: {
        dataFormat(msg) {
            if (msg) {
                const m = (msg||'').split(' ')[0].split('-');
                const s = m[0]+'年'+m[1]+'月'+m[2]+'日';
                return s;
            }
        },
        getGoodsById() {
            this.goodsId = this.$route.query.id || sessionStorage.getItem('goodsId');
            this.$api.home.findSaleWanghongGoods(this.goodsId).then(res=>{
                if (res.data) {
                    this.trainObj = res.data
                    if(this.trainObj.name.length>11){
                        this.trainObj.name = this.trainObj.name.substring(0,11)+'...'
                    }
                    if (localStorage.getItem('token')) {
                        this.checkUserCanByGoods(this.trainObj.id);
                    } else {
                        this.purchased = true;
                    }
                }
            })
        },
        checkUserCanByGoods(id) {
            this.$api.home.checkUserCanByGoods(id).then(res=>{
                if (res.data) {
                    this.purchased = res.data;
                }
            })
        },
        changeCarousel(a) {
            if (a === 1) {
                this.activeListenIndex = 1;
                this.video1.pause()
            } else {
                this.activeListenIndex = 0;
                this.video2.pause()
            }
        },
        jumpStudy() {
            if (localStorage.getItem('token')) {
                if (this.purchased) {
                    this.$router.push(`/orderPage?id=${this.trainObj.id}`)
                    sessionStorage.removeItem("dialogState")
                    sessionStorage.removeItem("typeState");
                    sessionStorage.removeItem("qrcodeState");
                } else {
                    // sessionStorage.setItem('dy-mode', '1');
                    this.$router.push(`/course/catalog?id=${this.trainObj.referId}&dymode=1`)
                }
            } else {
                this.$router.push({
                    path: '/login',
                    query: {
                        name: 'wanghongDetails',
                        type: 'order',
                        id: this.trainObj.id,
                        referId: this.trainObj.referId
                    }
                })
            }
        },
        clickItem(id, index) {
            this.$set(this.chapterList, id-1, index);
        },
        changeCorousel(val) {
            this.activeListenIndex = val;
            this.$refs.carousel.setActiveItem(val);
            if (val == 1) {
                this.video1.pause();
            } else {
                this.video2.pause();
            }
        },
    }
};
</script>
<style lang="stylus" scoped>
@import '../asset/css/gongxinDetails';
.wanghongDetails {
    .listen-block {
        /deep/.el-carousel__container {
            width: 1100px;
            margin: 0 50px;
        }
    }
    /deep/.el-carousel__container {
        .el-carousel__arrow {
            content: none;
            background-color: rgba(31,45,61,0);
            width: 21px;
            height: 41px;
            background-repeat: no-repeat;
            background-position: center 0px;
            background-size: cover;
            i {
                display: none;
            }
        }
        .el-carousel__arrow--left {
            background-image: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/arror-l.png')
            left: -50px;
        }
        .el-carousel__arrow--right {
            background-image: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/wanghong/arror-r.png')
            right: -50px;
        }
        /deep/ .el-carousel__indicators--horizontal {
            display: none;
        }
    }
}
</style>
