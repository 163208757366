<template>
    <div class="exam-detail-page m-container">
        <div class="exam-detail clearfix">
            <div class="backButton">
                <span @click="goBack">
                     <i class="back el-icon-arrow-left"></i>
                    返回</span>
            </div>
            <div class="exam-left">
                <div class="exam-top">
                    <div class="exam-top-left">
                        <div class="exam-top-title">
                            <h1>
                              {{ examDetail.examName }}
                            </h1>
                            <span>{{examDetail.type==1?'模拟考试':'正式考试'}}</span>
                        </div>
                        <div class="bottom">
                            <span>答题人：{{examDetail.userName }}</span>
                            <span>考试时间：{{ examDetail.enterTime }}</span>
                            <span>用时：{{ examDetail.useDuration }}</span>
                        </div>
                    </div>
                    <div class="exam-top-right" v-if="examDetail.scoreAuth == true && !readOver">
                        <div class="time">{{ examDetail.score }}<span>分</span></div>
                        <div class="titles">
                            <span>总分：{{ examDetail.totalScore }}</span>
                            <!-- <span>及格分：{{ examDetail.passingScore }}</span> -->
                        </div>
                    </div>
                    <img v-if="examDetail.scoreAuth == true && !readOver" :src="unanswered?img3:examDetail.isPass?img1:img2" alt="" class="exam-top-img">
                </div>
                <div class="scroll">
                    <!-- 实操考试阅卷中 -->
                    <div v-if="readOver">
                        <div class="submitReadOver">
                            <img src="../asset/images/successExam.png" alt="">
                            <p>您已提交，阅卷中，请耐心等待 ~</p>
                        </div>
                        <div class="abnormal" v-if="examDetail.isValid == 0">
                            <div class="titles">
                                <img src="../asset/images/ycjl.png" alt="" />
                                异常记录
                            </div>
                            <p>{{invalid}}</p>
                        </div>
                    </div>
                    <div v-else>
                        <!-- 成绩有效 -->
                        <div v-if="examDetail.isValid == 1">
                            <!-- 允许查看得分 -->
                            <div class="abnormal-list scroll-list" v-if="examDetail.scoreAuth == true">
                                <div class="number answer">
                                    <img src="../asset/images/dd.png" alt="" class="icon">
                                    <h1 class="num">{{ examDetail.rightCount }}</h1>
                                    <p class="titles">答对(道)</p>
                                </div>
                                <div class="number incorrectly">
                                    <img src="../asset/images/dc.png" alt="" class="icon">
                                    <h1 class="num">{{ examDetail.errorCount }}</h1>
                                    <p class="titles">答错(道)</p>
                                </div>
                                <div class="number noAanswer">
                                    <img src="../asset/images/wd.png" alt="" class="icon">
                                    <h1 class="num">{{ examDetail.emptyCount }}</h1>
                                    <p class="titles">未答(道)</p>
                                </div>
                                <div class="number score">
                                    <img src="../asset/images/zts.png" alt="" class="icon">
                                    <h1 class="num">{{ examDetail.totalCount }}</h1>
                                    <p class="titles">总题数(道)</p>
                                </div>
                            </div>
                            <!-- 不允许查看得分 -->
                            <div class="submitSuccess" v-if="examDetail.scoreAuth == false">
                                <img src="../asset/images/successExam.png" alt="">
                                <p>您的考试已提交，感谢参与作答 ~</p>
                            </div>
                        </div>
                        <!-- 成绩无效 -->
                        <div class="scroll-abnormal" v-if="examDetail.isValid == 0">
                            <h3>检测到您有异常行为</h3>
                            <div class="abnormal-con" :class="examDetail.scoreAuth == true ? 'martop48' : ''">
                                <img src="../asset/images/abnormal.png" alt="">
                                <span>异常记录</span>
                                <p>{{invalid}}</p>
                            </div>
                            <!-- 允许查看得分 -->
                            <div class="abnormal-list" v-if="examDetail.scoreAuth == true">
                                <div class="number answer">
                                    <img src="../asset/images/dd.png" alt="" class="icon">
                                    <h1 class="num">{{ examDetail.rightCount }}</h1>
                                    <p class="titles">答对(道)</p>
                                </div>
                                <div class="number incorrectly">
                                    <img src="../asset/images/dc.png" alt="" class="icon">
                                    <h1 class="num">{{ examDetail.errorCount }}</h1>
                                    <p class="titles">答错(道)</p>
                                </div>
                                <div class="number noAanswer">
                                    <img src="../asset/images/wd.png" alt="" class="icon">
                                    <h1 class="num">{{ examDetail.emptyCount }}</h1>
                                    <p class="titles">未答(道)</p>
                                </div>
                                <div class="number score">
                                    <img src="../asset/images/zts.png" alt="" class="icon">
                                    <h1 class="num">{{ examDetail.totalCount }}</h1>
                                    <p class="titles">总题数(道)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="scroll-btn">
                        <div class="submit-btn" v-if="$route.query.type!=='historyList'">
                            <div v-if="haveFrequency">
                                <el-button v-if="examDetail.result == 1" type="primary" plain @click="submitExam">重考</el-button>
                                <el-button v-else type="primary" @click="submitExam">重考</el-button>
                            </div>
                            <el-button v-else-if="noFrequency" type="primary" class="noneNum">已无考试剩余次数</el-button>
                            <div v-else-if="again">
                                <el-button v-if="examDetail.result == 1" type="primary" plain @click="submitExam">重考</el-button>
                                <el-button v-else type="primary" @click="submitExam">重考</el-button>
                            </div>
                            <el-button v-else-if="overdue" type="primary" @click="submitExam">不在考试周期内</el-button>
                            <p v-if="haveFrequency" class="num">考试剩余机会：{{frequency}}次/共{{examDetail.examCount}}次</p>
                            <p v-else-if="noFrequency" class="num">考试剩余机会：0次/共{{examDetail.examCount}}次</p>
                        </div>
                        <div class="submit-btn-see" v-if="examDetail.questionAuth">
                            <span class="button" @click="testPaper">查看试卷详情</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            id="tip-dialog"
            :visible.sync="coursedialogVisible"
            width="400px"
            :before-close="handleClose">
            <img src="../asset/images/ic-ts@2x.8d378b1f.png" class="icon-img" alt="">
            <p class="title">{{text1}}</p>
            <p class="text">{{text2}}</p>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleClose">{{submitText}}</el-button>
            </span>
        </el-dialog>
        <facedialog v-if="verification" :address="verificationAddress" :failAddress="verificationFailAddress" :type=2 :pauseFrame="true"></facedialog>
    </div>
</template>

<script>
// import components from '../config/vueBlock';
import facedialog from '@/layout/facedialog.vue'
export default {
  name: 'handPaper',
  show: true,
  components: {
    facedialog
  },
//   components,
    data() {
        return {
            text1:'',
            text2:'',
            submitText:'',
            examDetail: {},
            examTime: '',
            coursedialogVisible: false,
            tipsContent: '',
            flag: false,
            startTime: '',
            endTime: '',
            frequency: null,
            userPaperId: this.$route.query.userPaperId,
            businessId: this.$route.query.businessId,
            examId: this.$route.query.examId,
            //taskType为1，则为从培训任务跳过来的
            taskType: this.$route.query.taskType || null, 
            // 考前人脸识别
            isFaceRecogBeforeExam: JSON.parse(sessionStorage.getItem('isFaceRecogBeforeExam')),
            // 考中人脸识别
            isFaceRecogDuringExam: JSON.parse(sessionStorage.getItem('isFaceRecogDuringExam')),
            // 考后人脸识别
            isFaceRecogCommitExam: JSON.parse(sessionStorage.getItem('isFaceRecogCommitExam')),
            // 入口
            source: this.$route.query.source,
            userName:'',
            haveFrequency: false,
            noFrequency: false,
            again: false,
            overdue:false,            
            img1: require('../asset/images/ysjg.png'),
            img2: require('../asset/images/ysbjg.png'),
            img3: require('../asset/images/yswzd.png'),
            invalid: '',
            verification: false,
            verificationAddress: {},
            verificationFailAddress: {},
            returnPath: {},
            unanswered: false,
            readOver: false
        };
    },
    mounted() {
        if (this.source == 'examDetails') {
            this.returnPath = {
                path:'/examDetails',
                query:{
                    bussinessId: this.businessId, 
                    examId: this.examId, 
                    type: this.taskType,
                    dymode: this.$route.query.dymode,
                    title: sessionStorage.getItem("examDetailsTitle"),
                    timeSlot: sessionStorage.getItem("examDetailsTimeSlot")
                }
            }
        } else if (this.source == 'coursecatalog') {
            this.returnPath = {
                path:'/course/catalog',
                query:{
                    id:this.businessId,
                    taskType: this.taskType,
                    dymode: this.$route.query.dymode
                }
            }
        }
    },
    created() {
        this.findUserPaperSurface();
        this.returnButton();
    },
    destroyed(){
        window.removeEventListener('popstate', this.popstate, false);
    },
    methods: {
        // 浏览器回退按钮到训练营
        returnButton(){
            if (this.$route.name == 'handPaper') {
                let that = this;
                if (window.history && window.history.pushState) {
                    history.pushState(null, null, document.URL);
                    window.addEventListener('popstate', that.popstate, false)
                }
            }
        },
        popstate () {
            history.pushState(null, null, document.URL)
        },
        goBack() {
            if (this.$route.name == 'handPaper') {
                if (!(this.source == 'examDetails' && !this.examId)) {
                    this.$router.push(this.returnPath)
                }
            }
        },
        handleClose () {
            this.coursedialogVisible = false;
        },
        // 试卷信息
        findUserPaperSurface() {
            this.$api.examination.findUserPaperSurface(this.userPaperId).then((res) => {
                if(res.data && res.data != 'null'){
                    this.examDetail = res.data;
                    if (this.examDetail.invalidType == 1) {
                        this.invalid = '人脸识别失败，强制交卷!'
                    } else if (this.examDetail.invalidType == 2) {
                        this.invalid = '防切屏超过次数限制，强制交卷!'
                    } else if (this.examDetail.invalidType == 3) {
                        this.invalid = '多人出现超过次数限制，强制交卷!'
                    } else if (this.examDetail.invalidType == 4) {
                        this.invalid = '不是本人超过次数限制，强制交卷!'
                    } else if (this.examDetail.invalidType == 5) {
                        this.invalid = '检测不到人超过次数限制，强制交卷!'
                    } else if (this.examDetail.invalidType == 7) {
                        this.invalid = '管理员强制交卷!'
                    } else if (this.examDetail.invalidType == 9) {
                        this.invalid = '系统交卷!'
                    }
                    if (this.examDetail.emptyCount == this.examDetail.totalCount) {
                        this.unanswered = true;
                    } else {
                        this.unanswered = false;
                    }
                    if (this.examDetail.paperType == 2 && !this.examDetail.isReadOver) {
                        this.readOver = true;
                    }
                    // this.formateSeconds(this.examDetail.useDuration);
                    this.examDetail.useDuration = this.timeFormatter(this.examDetail.useDuration);
                    this.startTime = (new Date(this.examDetail.trainExamStartTime)).getTime();
                    this.endTime = (new Date(this.examDetail.trainExamEndTime)).getTime();
                    var nowTime = (new Date()).getTime();
                    this.frequency = this.examDetail.examCount - this.examDetail.yetExamCount;
                    if (this.examDetail.isLimitTime) {
                        if ( nowTime > this.startTime && nowTime < this.endTime ) {
                            if (this.examDetail.examCount == 0) {
                                // this.tipsContent = '重考';
                                this.again = true;
                            } else if (this.frequency > 0) { 
                                this.haveFrequency = true;
                                // this.tipsContent = '重考(考试剩余机会：' + this.frequency + '次/共'+ this.examDetail.examCount +'次)';
                            } else {
                                this.noFrequency = true;
                                // this.tipsContent = '考试机会已用完';
                                // this.flag = true;
                            }
                        } else {
                            // this.tipsContent = '不在考试周期内';
                            this.overdue = true;
                        }
                    } else {
                        if (this.examDetail.examCount == 0) {
                            // this.tipsContent = '重考';
                            this.again = true;
                        } else if (this.frequency > 0) {
                            this.haveFrequency = true;
                            // this.tipsContent = '重考(考试剩余机会：' + this.frequency + '次/共'+ this.examDetail.examCount +'次)';
                        } else {
                            this.noFrequency = true;
                            // this.tipsContent = '考试机会已用完';
                            // this.flag = true;
                        }
                    }
                }
            });
        },
        timeFormatter(time) {
            const hours = Math.floor(time / 60 / 60);
            const minutes = Math.floor((time / 60) % 60);
            const seconds = Math.floor(time % 60);
            var hours1 = hours < 10 ? "0" + hours : hours;
            var minutes1 = minutes < 10 ? "0" + minutes : minutes;
            var seconds1 = seconds < 10 ? "0" + seconds : seconds;
            return hours1 + "时" + minutes1 + "分" + seconds1 + "秒";
        },
        formateSeconds(endTime){
            let secondTime = parseInt(endTime)//将传入的秒的值转化为Number
            let min = 0// 初始化分
            let h =0// 初始化小时
            let result=''
            if(secondTime>60){//如果秒数大于60，将秒数转换成整数
                min=parseInt(secondTime/60)//获取分钟，除以60取整数，得到整数分钟
                secondTime=parseInt(secondTime%60)//获取秒数，秒数取佘，得到整数秒数
                if(min>60){//如果分钟大于60，将分钟转换成小时
                h=parseInt(min/60)//获取小时，获取分钟除以60，得到整数小时
                min=parseInt(min%60) //获取小时后取佘的分，获取分钟除以60取佘的分
                }
            }
            var xs = `${h.toString().padStart(2,'0')}` == '00' ? '' : `${h.toString().padStart(2,'0')}小时`;
            var fz = `${min.toString().padStart(2,'0')}` == '00' ? '' : `${min.toString().padStart(2,'0')}分`;
            var mz = `${secondTime.toString().padStart(2,'0')}` == '00' ? '' : `${secondTime.toString().padStart(2,'0')}秒`;
            result = xs + fz + mz;
            this.examTime = result;
        },
        // 再考一次
        submitExam() {
            this.$store.state.testPaper = false
            var nowTime = (new Date()).getTime();
            if (this.examDetail.isLimitTime) {
                const lateTime = this.examDetail.allowLateDuration*60*1000;
                if ( nowTime > this.startTime && nowTime < this.endTime ) {
                    if (this.examDetail.allowLateDuration > 0 && nowTime > this.startTime+lateTime) {
                        this.coursedialogVisible = true;
                        this.text1 = "提示";
                        this.text2 = "您已超过最迟开考时间！";
                        this.submitText = "确定";
                    } else if (this.isFaceRecogBeforeExam==1) {
                        this.verification = true;
                        this.verificationAddress = {
                            path:'/course/resdExam',
                            query:{
                                userPaperId:this.userPaperId,
                                businessId:this.businessId,
                                examId: this.examId,
                                dymode: this.$route.query.dymode,
                                taskType: this.taskType,
                                source: this.source
                            }
                        };
                        this.verificationFailAddress = this.returnPath;
                    } else {
                        this.$router.push({
                            path:'/course/resdExam',
                            query:{
                                userPaperId:this.userPaperId,
                                businessId:this.businessId,
                                examId: this.examId,
                                dymode: this.$route.query.dymode,
                                taskType:this.taskType,
                                source: this.source
                            }
                        }) 
                    }
                } else {
                    this.coursedialogVisible = true;
                    this.text1 = "提示";
                    this.text2 = "已不在考试周期内";
                    this.submitText = "确定";
                } 
            } else {
                if (this.isFaceRecogBeforeExam==1) {
                    this.verification = true;
                    this.verificationAddress = {
                        path:'/course/resdExam',
                        query:{
                            userPaperId:this.userPaperId,
                            businessId:this.businessId,
                            examId: this.examId,
                            dymode: this.$route.query.dymode,
                            taskType: this.taskType,
                            source: this.source
                        }
                    };
                    this.verificationFailAddress = this.returnPath;
                } else {
                    this.$router.push({
                        path:'/course/resdExam',
                        query:{
                            userPaperId:this.userPaperId,
                            businessId:this.businessId,
                            examId: this.examId,
                            dymode: this.$route.query.dymode,
                            taskType: this.taskType,
                            source: this.source
                        }
                    }) 
                }
            }
        },
        // 查看试卷详情
        testPaper() {
            if (this.readOver) {
                this.$message({
                    message: '阅卷中，请耐心等待',
                    type: 'warning'
                });
            } else {
                this.$store.state.testPaper = false
                this.$router.push({
                    path:'/course/testPaperDetails',
                    query:{
                        userPaperId:this.userPaperId,
                        businessId:this.businessId,
                        examId: this.examId,
                        dymode: this.$route.query.dymode
                    }
                })
            }
        }
    }
};
</script>
<style lang="stylus">
.el-button--primary {
    background: #316FFF;
    border-color: #316FFF;
    span {
        color: #fff;
        font-size: 16px;
    }
}
.is-plain {
    span {
        color: #409EFF!important;
    }
    &:hover {
        span {
            color: #FFF!important;
        }
    }
}
#tip-dialog {
    text-align: center;
    .el-dialog {
        border-radius: 10px;
        .el-dialog__header {
            display: none;
        }
        .el-dialog__body {
            padding-top: 40px;
            padding-bottom: 30px;
            .icon-img {
                width: 50px;
                height: 50px;
                display: block;
                margin: 0 auto;
                margin-bottom: 19px;
            }
            .title {
                font-size: 24px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #1B2257;
                line-height: 34px;
                margin-bottom: 16px;
            }
            .text {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
            }
        }
        .el-dialog__footer {
            padding: 0 65px 30px;
            text-align: center;
            .dialog-footer {
                .el-button {
                    width: 240px;
                    height: 40px;
                    text-align: center;
                }
            }
        }
    }
}
</style>
<style lang="stylus" scoped>
@import "../asset/handPaper.styl"
</style>
