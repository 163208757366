<template>
  <div class="divBox backf">
    <el-row v-for="(item, index) in pageData.layout" :key="item.aid">
      <template v-for="(blockName, i) in item.block_name">
        <el-col
          v-if="templateConf && templateConf[index] && templateConf[index].items && templateConf[index].items[i]"
          :span="+templateConf[index].items[i].column_num || 24"
          :key="i">
            <template v-if="Array.isArray(blockName)">
              <el-row v-if="templateConf[index].items[i].children && templateConf[index].items[i].children.length">
                <template
                  v-for="(el, j) in blockName"
                  :span="+templateConf[index].items[i].children[j].column_num || 24">
                    <el-col
                      :key="`${el}${j}`">
                      <template v-if="Array.isArray(el)">
                        <el-row>
                          <el-col v-for="(a, l) in el" :key="`${el}${l}`">
                            <component
                              v-if="a"
                              :is="a"
                            ></component>
                          </el-col>
                        </el-row>
                      </template>
                      <template v-else>
                        <component
                          v-if="el"
                          :is="el"
                        ></component>
                      </template>
                    </el-col>
                </template>
              </el-row>
            </template>
            <template v-else>
              <component
                v-if="blockName"
                :is="blockName"
              ></component>
            </template>
        </el-col>
      </template>
    </el-row>
  </div>
</template>

<script>
import components from './../config/vueBlock';

export default {
  name: 'courseGoodsIdx',
  components,
  props: {
    pageData: {
      type: Object,
      default: () => ({
        layout: []
      })
    },
    templateConf: {
      type: Array,
      default: () => ([])
    }
  },

  data () {
    return {}
  },
  mounted () {
  },
  methods: {

  },
};
</script>

<style lang="stylus" scoped>

</style>
