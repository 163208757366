<template>
    <div class="examList">
        <examList></examList>
    </div>
</template>
<script>
export default {
    name:'examList2',
    data(){
        return {

        }
    }
}
</script>
<style lang="stylus" scoped>
.examList
    width 1200px
    margin 0 auto
    /deep/.exam-list .tab .text
        margin-top 24px
</style>