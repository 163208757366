var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"evaluation"},[_c('div',{staticClass:"backButton"},[_c('span',{on:{"click":_vm.goBack}},[_c('i',{staticClass:"back el-icon-arrow-left"}),_vm._v(" 返回")])]),_c('div',{staticClass:"evaluation-con"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"line"}),_c('h4',{attrs:{"title":_vm.examDetail.assessName}},[_vm._v(_vm._s(_vm.examDetail.assessName))])]),_c('div',{staticClass:"testPaper"},_vm._l((_vm.questionsList),function(item,index){return _c('div',{key:index,staticClass:"item",attrs:{"id":'s' + item.id}},[_c('div',{staticClass:"stem"},[_c('h4',[_vm._v(_vm._s(index + 1)+".【 "+_vm._s(item.type == 1 ? '单选题' : item.type == 2 ? '多选题' : item.type == 3 ? '判断题' : item.type == 4 ? '高阶题' : '')+" 】"),_c('span',{domProps:{"innerHTML":_vm._s(item.title)}},[_vm._v(_vm._s(item.title))])]),_vm._l((item.optionList),function(item1,index1){return _c('li',{key:index1},[_vm._v(_vm._s(item1.optionNumber)),_c('span',{domProps:{"innerHTML":_vm._s(item1.optionContent)}},[_vm._v(_vm._s(item1.optionContent))])])})],2),_c('div',{staticClass:"option"},_vm._l((item.optionList),function(item1,index1){return _c('li',{key:index1,staticClass:"answer",on:{"click":function($event){return _vm.handleClickOption(
                                        item,
                                        item.optionList,
                                        item.type,
                                        item1,
                                        index,
                                        index1
                                    )}}},[(item.type!=2)?_c('span',{staticClass:"option-btn",class:{
                                        'active': item1.isAnswers
                                    }}):_vm._e(),(item.type==2)?_c('span',{staticClass:"el-checkbox__input",class:{
                                        'is-checked': item1.isAnswers
                                    }},[_c('span',{staticClass:"el-checkbox__inner"})]):_vm._e(),_c('span',{staticClass:"option-text"},[_vm._v(_vm._s(item1.optionNumber))])])}),0)])}),0)]),_c('div',{staticClass:"right"},[_vm._m(0),_c('div',{class:['answer-num', _vm.winH==768?'min-answer-num':'']},_vm._l((_vm.questionsList),function(item,index){return _c('span',{key:index,class:['pointer', item.result ? 'ydt' : ''],on:{"click":function($event){$event.preventDefault();return _vm.anchor('s' + item.id)}}},[_vm._v(" "+_vm._s(index + 1)+" ")])}),0),_c('div',{staticClass:"answer-btn"},[_c('div',{staticClass:"btn",on:{"click":_vm.submitExam}},[_vm._v("提交")])])])])]),_c('el-dialog',{staticClass:"tip-dialog",attrs:{"visible":_vm.coursedialogVisible,"width":"400px","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.coursedialogVisible=$event}}},[_c('img',{staticClass:"icon-img",attrs:{"src":require("../asset/images/ic-ts@2x.8d378b1f.png"),"alt":""}}),_c('p',{staticClass:"titles"},[_vm._v("提示")]),(_vm.complete)?_c('p',{staticClass:"text"},[_vm._v("是否要进行交卷")]):_c('p',{staticClass:"text"},[_vm._v("为保证测评准确性，请全部作答后提交")]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[(_vm.complete)?_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v(_vm._s(_vm.cancelText))]):_vm._e(),_c('el-button',{style:(_vm.complete?'width:120px':'width:100%'),attrs:{"type":"primary"},on:{"click":function($event){return _vm.rightBtn()}}},[_vm._v(_vm._s(_vm.submitText))])],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scantron"},[_c('div',{staticClass:"titles"},[_vm._v("答题卡")]),_c('div',{staticClass:"type"},[_c('span',{staticClass:"finish"},[_c('span'),_vm._v("已答")]),_c('span',{staticClass:"none"},[_c('span'),_vm._v("未答")])])])}]

export { render, staticRenderFns }