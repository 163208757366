<template>
  <div class="exam-top">
    <div class="exam-top-left">
      <div class="exam-top-title">
        <h1>
          {{ examDetail.examName }}
        </h1>
        <span>{{ examDetail.type == 1 ? "模拟考试" : "正式考试" }}</span>
      </div>
      <div class="bottom">
        <span>答题人：{{ examDetail.userName }}</span>
        <span>考试时间：{{ examDetail.enterTime }}</span>
        <span>用时：{{ examDetail.useDuration }}</span>
      </div>
    </div>
    <div class="exam-top-right">
      <div class="time">{{ examDetail.score }}<span>分</span></div>
      <div class="titles">
        <span>总分数：{{ examDetail.totalScore }}</span>
        <span>及格分：{{ examDetail.passingScore }}</span>
      </div>
    </div>
    <img :src="examDetail.isPass ? img1 : img2" alt="" class="exam-top-img" />
  </div>
</template>

<script>
import components from "../../config/vueBlock";
export default {
  name: "handPaper",
  show: true,
  components,
  data() {
    return {
      text1: "",
      text2: "",
      submitText: "",
      examDetail: {},
      examTime: "",
      coursedialogVisible: false,
      tipsContent: "",
      flag: false,
      startTime: "",
      endTime: "",
      frequency: null,
      userPaperId: this.$route.query.userPaperId,
      businessId: this.$route.query.businessId,
      examId: this.$route.query.examId,
      userId: "",
      userName: "",
      haveFrequency: false,
      noFrequency: false,
      img1: require("../../asset/images/ysjg.png"),
      img2: require("../../asset/images/ysbjg.png"),
    };
  },
  mounted() {},
  created() {
    // this.userId = sessionStorage.getItem('userId');
    this.findUserPaperSurface();
    // this.findById();
    this.returnButton();
  },
  destroyed() {
    window.removeEventListener("popstate", this.popstate());
  },
  methods: {
    // 浏览器回退按钮到训练营
    returnButton() {
      if (this.$route.name == "handPaper") {
        if (window.history && window.history.pushState) {
          console.log("window.history.pushState", window.history.pushState);
          window.addEventListener("popstate", this.popstate());
        }
        // window.history.pushState('forward', null, '#'); //在IE中必须得有这两行
        // window.history.forward(1);
      }
    },
    popstate() {
      if (this.$route.path === "/course/testPaper") {
        this.$router.replace({
          path: "/course/handPaper",
          query: {
            userPaperId: this.$route.query.userPaperId,
            businessId: this.$route.query.businessId,
            examId: this.$route.query.examId,
            type: this.$route.query.type,
          },
        });
      }
    },

    // 返回训练营
    async goBack() {
      await new Promise((res, rej) => {
        try {
          window.removeEventListener("popstate", this.popstate());
          res(true);
        } catch (error) {
          rej(error);
        }
      });
      await new Promise((res, rej) => {
        try {
          this.$router.push({
            path: "/course/catalog",
            query: {
              id: this.businessId,
            },
          });
          res(true);
        } catch (error) {
          rej(error);
        }
      });
      // if (this.$route.query.type == 'test') {

      // } else {
      // this.$router.go(-1)
      // }
    },
    handleClose() {
      this.coursedialogVisible = false;
    },
    // findById(){
    //      this.$api.examination.findById(this.userId).then((res)=>{
    //          this.userName = res.data.name;

    //      })
    // },
    // 试卷信息
    findUserPaperSurface() {
      this.$api.examination
        .findUserPaperSurface(this.userPaperId)
        .then((res) => {
          if (res.data && res.data != "null") {
            this.examDetail = res.data;
            // this.formateSeconds(this.examDetail.useDuration);
            this.examDetail.useDuration = this.timeFormatter(
              this.examDetail.useDuration
            );
            this.startTime = new Date(
              this.examDetail.trainExamStartTime
            ).getTime();
            this.endTime = new Date(this.examDetail.trainExamEndTime).getTime();
            var nowTime = new Date().getTime();
            if (nowTime > this.startTime && nowTime < this.endTime) {
              this.frequency =
                this.examDetail.examCount - this.examDetail.yetExamCount;
              if (this.examDetail.examCount == 0) {
                this.tipsContent = "重考";
              } else if (this.frequency > 0) {
                this.haveFrequency = true;
                // this.tipsContent = '重考(考试剩余机会：' + this.frequency + '次/共'+ this.examDetail.examCount +'次)';
              } else {
                this.noFrequency = true;
                // this.tipsContent = '考试机会已用完';
                // this.flag = true;
              }
            } else {
              this.tipsContent = "不在考试周期内";
            }
          }
        });
    },
    timeFormatter(time) {
      const hours = Math.floor(time / 60 / 60);
      const minutes = Math.floor((time / 60) % 60);
      const seconds = Math.floor(time % 60);
      var hours1 = hours < 10 ? "0" + hours : hours;
      var minutes1 = minutes < 10 ? "0" + minutes : minutes;
      var seconds1 = seconds < 10 ? "0" + seconds : seconds;
      return hours1 + "时" + minutes1 + "分" + seconds1 + "秒";
    },
    formateSeconds(endTime) {
      let secondTime = parseInt(endTime); //将传入的秒的值转化为Number
      let min = 0; // 初始化分
      let h = 0; // 初始化小时
      let result = "";
      if (secondTime > 60) {
        //如果秒数大于60，将秒数转换成整数
        min = parseInt(secondTime / 60); //获取分钟，除以60取整数，得到整数分钟
        secondTime = parseInt(secondTime % 60); //获取秒数，秒数取佘，得到整数秒数
        if (min > 60) {
          //如果分钟大于60，将分钟转换成小时
          h = parseInt(min / 60); //获取小时，获取分钟除以60，得到整数小时
          min = parseInt(min % 60); //获取小时后取佘的分，获取分钟除以60取佘的分
        }
      }
      var xs =
        `${h.toString().padStart(2, "0")}` == "00"
          ? ""
          : `${h.toString().padStart(2, "0")}小时`;
      var fz =
        `${min.toString().padStart(2, "0")}` == "00"
          ? ""
          : `${min.toString().padStart(2, "0")}分`;
      var mz =
        `${secondTime.toString().padStart(2, "0")}` == "00"
          ? ""
          : `${secondTime.toString().padStart(2, "0")}秒`;
      result = xs + fz + mz;
      this.examTime = result;
    },
    // 再考一次
    submitExam() {
      this.$store.state.testPaper = false;
      var nowTime = new Date().getTime();
      if (nowTime > this.startTime && nowTime < this.endTime) {
        this.$router.push({
          path: "/course/resdExam",
          query: {
            userPaperId: this.userPaperId,
            businessId: this.businessId,
            examId: this.examId,
          },
        });
      } else {
        this.coursedialogVisible = true;
        this.text1 = "提示";
        this.text2 = "已不在考试周期内";
        this.submitText = "确定";
      }
    },
    // 查看试卷详情
    testPaper() {
      this.$store.state.testPaper = false;
      this.$router.push({
        path: "/course/testPaperDetails",
        query: {
          userPaperId: this.userPaperId,
          businessId: this.businessId,
          examId: this.examId,
        },
      });
    },
  },
};
</script>
<style lang="stylus">
.el-button--primary {
  background: #316FFF;
  border-color: #316FFF;

  span {
    color: #fff;
    font-size: 16px;
  }
}

#tip-dialog {
  text-align: center;

  .el-dialog {
    border-radius: 10px;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding-top: 40px;
      padding-bottom: 30px;

      .icon-img {
        width: 50px;
        height: 50px;
        display: block;
        margin: 0 auto;
        margin-bottom: 19px;
      }

      .title {
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1B2257;
        line-height: 34px;
        margin-bottom: 16px;
      }

      .text {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }

    .el-dialog__footer {
      padding: 0 65px 30px;
      text-align: center;

      .dialog-footer {
        .el-button {
          width: 240px;
          height: 40px;
          text-align: center;
        }
      }
    }
  }
}
</style>
<style lang="stylus" scoped>
@import '../../asset/handPaper.styl';
</style>
