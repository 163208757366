<template>
  <div>
    <div class="gzjt gzjt-nav">
      <div class="menuContents">
        <h1 class="imglogoG fl">道路运输安全宣教平台</h1>
        <div class="gzjt-nav-bar fl">
          <p class="item-nav fl" 
            v-for="item in navList" 
            :key="item.id"
            :class="{'active': setActive(item)}"
            @click="clickNav(item)"
          >
            {{item.title}}
          </p>
        </div>
        <div v-if="!userInfo.name" class="un-login fr" @click="jumpLogin('gzjt_homepage')">
            学员登录
        </div>
        <el-dropdown class="fr user-dropdown" v-else @visible-change="toggleUser" trigger="click" >
          <div class="flex user-account">
            <p class="txt">
              {{ userInfo.name || '--' }}
            </p>
            <p>
              <i :class="[{'el-icon-arrow-down': !showUser},{'el-icon-arrow-up': showUser}]"></i>
            </p>
            
          </div>
          <el-dropdown-menu slot="dropdown" class="user-dropdown-menu user-dropdown-menu-gzjt" >
            <el-dropdown-item>
              <ul>
                <li>{{ userInfo.name || '--' }}</li>
                <li>{{ geTel() }}</li>
              </ul>
            </el-dropdown-item>
            <el-dropdown-item divided>
              <ul
                @click="showBranchList"
                v-if="branchList && branchList.length > 1"
              >
                <li>切换机构</li>
                <li>当前：{{ branchName || '--' }}</li>
              </ul>
              <ul v-else>
                <li>所在机构</li>
                <li>{{ branchName || '--' }}</li>
              </ul>
            </el-dropdown-item>
            <el-dropdown-item divided>
              <ul>
                <li @click="signOut">
                  退出登录
                </li>
              </ul>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <img
          class="fr user-img"
          v-if="userInfo.headImage"
          :src="userInfo.headImage"
          alt=""
        />
        <img class="fr user-img" v-if="userInfo.name" src="@assets/userL.png" alt="" />
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="branchListShow"
      :modal="false"
      width="400px"
      center
    >
      <div class="gzjt-dialog">
        <p class="branchTitle">切换机构</p>
        <ul class="ct">
          <li
            v-for="item in branchList"
            :key="item.id"
            @click="primaryBranch(item.id)"
            :class="{ primary: primary === item.id, mt10: true }"
          >
            <span v-show="branchId === item.id" class="current">当前</span
            >{{ item.name }}
          </li>
        </ul>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setBranch">选好了</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
export default {
  name: 'gzjtNav',
  data () {
    return {
      newVal: null,
      branchList: [],
      branchListShow: false,
      primary: '',
      userInfo: {},
      branchName: '',
      branchId: '',
      activeNav: this.$route.path,
      examId: 0,
      trainId: 0,
      showUser: false,
      mode: '0', //  0:其他 1' 学习中心 '2': 模拟考试
      navList: [
        {
          path: '/gzjt_homepage',
          needLogin: false,
          title: '首页',
          activePaths: ['/gzjt_homepage', '/gzjtDetails']
        },
        {
          path: '/gzjt_Regulations',
          needLogin: false,
          title: '政策法规',
          activePaths: ['/gzjt_Regulations']
        },
        {
          path: '/course/catalog',
          needLogin: true,
          title: '学习中心',
          activePaths: ['/course', '/course/catalog', '/course/resdExam', '/course/testPaper', '/course/handPaper', '/course/testPaperDetails']
        },
        
        {
          path: '/course/catalog',
          needLogin: true,
          title: '考试中心',
          activePaths: ['/course', '/course/catalog', '/course/resdExam', '/course/testPaper', '/course/handPaper', '/course/testPaperDetails']
        },
      ]
    }
  },
  created () {
    if (localStorage.getItem('token')) {
        window.addEventListener('setItem', this.watchStorage)
        this.getFindUserBranchList()
        this.getAccountUserInfo()
    }
  },
  watch: {
    $route() {
      setTimeout(() => { //路由跳转
        this.activeNav = this.$route.path;
        this.mode = this.$route.query.dymode  || '0';
      }, 100)
    }
  },
  destroyed(){
    window.removeEventListener('setItem', this.watchStorage);
  },
  async mounted () {
    setTimeout(() => { //刷新
      this.activeNav = this.$route.path
      const item = this.navList.find(el=>el.path===this.activeNav)
      if (item.title !== '学习中心'&&item.title !== '考试中心') {
        // sessionStorage.setItem('dy-mode', '0');
        this.activeNav = item.path
        this.mode = '0';
      } 
    }, 100)
    // if (sessionStorage.getItem('dy-mode')) {
    //   this.mode = sessionStorage.getItem('dy-mode');
    // }
    this.mode = this.$route.query.dymode  || '0'
  },
  methods: {
    toggleUser(show) {
      this.showUser = show;
    },
    setActive(item) {
      // 学习中心，考试中心的内部跳转需要处理。
      let flag = false;
      this.mode = this.$route.query.dymode || '0';
      // this.mode = sessionStorage.getItem('dy-mode') || '0';
      if (this.mode === '1') {
        flag = item.title === '学习中心' ? item.activePaths.includes(this.activeNav): false;
      } else if (this.mode === '2') {
        flag = item.title === '考试中心' ? item.activePaths.includes(this.activeNav): false;
      } else {
        flag = item.activePaths.includes(this.activeNav);
      }
      
      return flag;
    },
    clickNav(item) { 
      if (this.$route.path === '/course/testPaper') {
        return;
      }
      let name = item.path;
      if (item.title === '考试中心') {

        name = `${name}?id=${this.examId}&dymode=2`
        // sessionStorage.setItem('dy-mode', '2');
        this.mode = '2';

      } else if (item.title === '学习中心') {

        name = `${name}?id=${this.trainId}&dymode=1`
        // sessionStorage.setItem('dy-mode', '1');
        this.mode = '1';

      } else if (item.path) {
        // sessionStorage.setItem('dy-mode', '0');
        this.mode = '0';
      }
      if (item.needLogin && !localStorage.getItem('token')) {
        this.jumpLogin(name);
      } else if (name) {
        this.$router.push(name)
      }
      
    },
    jumpLogin() {
      this.$router.push({
            name: 'login',
            // query: {
            //     name
            // }
        })
    },
    async watchStorage(){
      this.newVal=sessionStorage.getItem('signOutStorage')
      if(this.newVal=='signOut') {
        const params = { token: localStorage.getItem('token') }
        if(Object.keys(this.userInfo).length>0){
          await this.$api.usercenter.postAccounLogout({ params })
        }
        this.userInfo = {};
        this.$router.push({ name: 'gzjt_homepage' })
        sessionStorage.clear()
        localStorage.clear()
      }
    },
    async signOut () {
      sessionStorage.setItem('expect', 'yes')
      this.newVal=sessionStorage.getItem('signOutStorage')
      if(this.newVal=='login' && this.$route.path==='/course/testPaper') {
        Vue.prototype.resetSetItem('signOutStorage', 'bullet')
      } else {
        const params = { token: localStorage.getItem('token') }
        await this.$api.usercenter.postAccounLogout({ params })
        this.userInfo = {};
         window.location.replace('gzjt_homepage');
        sessionStorage.clear()
        localStorage.clear()
      }
    },
    getAccountUserInfo () {
      this.$api.usercenter.getAccountUserInfo({}).then(res => {
        this.userInfo = res.data
        localStorage.setItem('userInfo',JSON.stringify(res.data))
      })
    },
    geTel () {
      const Phone = this.userInfo.phone;
      const reg = /^(\d{3})\d{4}(\d{4})$/
      if (Phone) {
        return Phone.replace(reg, '$1****$2')
      } else {
        return '--'
      }
    },
    getGzjtTrain(){
        this.$api.home.getGzjtTrain().then(({data}) =>  {
            this.examId = data.examTrainId;
            this.trainId = data.studyTrainId;
        })
    },
    
    async getFindUserBranchList () {
         if (!localStorage.getItem('branchList')) {
         await this.$api.usercenter.getFindUserBranchList({}).then(res => {
          if (res.data && res.data.length) {
            const branchList = res.data.filter(item => Object.prototype.toString.call(item) === '[object Object]').map(item => item).map(item => {
              return {
                name: item.name,
                id: item.branch,
                actor: item.actor,
                organ: item.organ,
                userId: item.userId,
                code: item.code
              }
            })
            if (branchList.length) {
              localStorage.setItem('branchList', JSON.stringify(branchList))
              localStorage.setItem('organ', branchList[0].organ)
              localStorage.setItem('actor', branchList[0].actor)
              localStorage.setItem('branch', branchList[0].id)
              localStorage.setItem('userId', branchList[0].userId)
              localStorage.setItem('code', branchList[0].code)
              this.$sensors.login(branchList[0].code)
              this.branchList = branchList;
              this.branchName = branchList[0].name;
              this.branchId = branchList[0].id;
            }
          }
        });
       }  else {
          this.branchList = JSON.parse(localStorage.getItem('branchList'))
          if (!this.branchList.length) return
          if (localStorage.getItem('branch')) {
            this.branchList.forEach(item => {
              if (item.id == localStorage.getItem('branch')) {
                this.branchId = item.id;
                this.branchName = item.name
              }
            })
          } else {
            this.branchId = this.branchList[0].id;
            this.branchName = this.branchList[0].name
          }
       }
       this.getGzjtTrain()
    },
    showBranchList () {
      this.branchListShow = !this.branchListShow
      this.primary = ''
    },
    primaryBranch (branchId) {
      this.primary = branchId
    },
    async setBranch () {
      if (!this.primary) {
        this.showBranchList()
        return
      }
      this.branchList.forEach(async item => {
        if (item.id == this.primary) {
          this.branchId = item.id
          this.branchName = item.name;
          localStorage.setItem('organ', item.organ)
          localStorage.setItem('actor', item.actor)
          localStorage.setItem('branch', item.id)
          localStorage.setItem('userId', item.userId)
          localStorage.setItem('code', item.code)
          setTimeout(async () => {
            
            location.replace('/gzjt_homepage');
            sessionStorage.setItem('dy-mode', '0')
          }, 500)
        }
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
@import './../../../../assets/css/menu'
</style>
<style lang="stylus">
.gzjt-nav {
    background: #fff !important;
    font-family: PingFangSC-Regular, PingFang SC;
    height: 91px;
    line-height 91px
    position: fixed;
    width: 100%;
    z-index: 100;
    .menuContents {
      margin: 0 auto;
      width: 1200px;
      background: #fff;
    }
    .imglogoG {
      width: 240px;
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #316FFF;
    }
    .gzjt-nav-bar {
      margin-left: 171px;
      .item-nav {
        display: inline-block;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4A4A4A;
        cursor: pointer;
        margin-right 84px
        height: 63px;
        padding-bottom 10px
      }
      .item-nav.active {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #316FFF;
        border-bottom: 4px solid #086FFE;
      }
    }
    .un-login {
        color: #fff;
        width: 108px;
        height: 36px;
        background: #316FFF;
        border-radius: 20px;
        text-align: center;
        line-height: 36px;
        margin-top: 28px;
        margin-right: 20px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        cursor: pointer;
    }
    .move.el-dropdown {
        color:  #666;
        margin-left: 24px;
        span {
            font-size: 18px;
        }
    }
    .user-dropdown {
      :focus{
            outline: none !important;
      }
      .user-account {
        color: #333;
        display: flex;
        line-height: 76px;
        .txt {
          font-size: 18px;
        }
        i {
          margin-left: 10px;
          line-height: 80px;
        }
      }
      
    }
    .user-img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-top: 20px;
      margin-right: 8px;
    }

    
}
.user-dropdown-menu-gzjt {
  text-align: left;
  border-radius: 6px;
  margin-top: 22px !important;
  
  .el-dropdown-menu__item li{
    max-width: 168px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 22px;
    font-size: 14px;
  }
  .el-dropdown-menu__item li:first-child {
    color: #1B2257;
  }
  .el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
    background: #fff !important;
    
    li {
      color: #316FFF;
    }
    
  }
  // .logout {
  //   color: #1B2257;
  //   line-height: 54px !important;
  //   &:hover {
  //     color: #316FFF;
  //   }
  // }
  
}

.gzjt-dialog{
  .branchTitle{
    text-align center
    font-family PingFangSC-Medium, PingFang SC;
    font-size: 24px;
    font-weight: 900;
    color: #1B2257;
    line-height: 34px;
    margin-bottom 20px
  }
  li{
    width 352px
    height 40px
    line-height 40px
    border-radius 4px
    color: #666666;
    border 1px solid #D7DCEE
    cursor pointer
  }
  .current{
    width: 32px;
    height: 16px;
    margin-right 8px
    color #316FFF
    background: rgba(49, 111, 255, 0.24);
    border-radius: 2px;
    border: 1px solid #316FFF;
    font-size 12px
    font-family PingFangSC-Medium, PingFang SC;
    padding: 1px 3px;
  }
  .primary{
    border: 1px solid #316FFF;
    color #1B2257
  }
  .qrcode{
    width: 154px;
    height: 154px;
    margin: 28px 0 16px -77px;
    position relative;
    left 50%
    .xia{
      position absolute
      right -6px
      bottom -6px
    }
    .shang{
      position absolute
      left -6px
      top -6px
    }
  }
  .qrcodetitle{
    font-size: 16px;
    color: #666666;
    line-height: 22px;
  }
}

</style>
