<template>
    <div class="header-box">
        <div class="nav">
            <div class="container">
                <div class="left">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/static-pc/header-logo.png" alt="">
                </div>
                <div class="menu">
                    <ul>
                        <li class="item" v-for="nav in NAVList" :key="nav.id" :class="{active : nav.path === activeNav}" @click="tabClick(nav)">
                            <p class="title">{{nav.title}}</p>
                            <p class="line" v-show="nav.path === '/'+activeNav.split('/')[1]"></p>
                        </li>
                    </ul>
                </div>
                <div class="right" v-if="!token">
                  <el-button class="button" @click="jumpLogin('weidong_homepage','login')" round>注册/登录</el-button>
                </div>
                <template v-else>
                  <account class="account" :replacePage="'weidong_homepage'"/>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import account from './account.vue'
export default {
    data(){
        return{
            token:'',
            NAVList:[
                {
                    id:1,
                    title:'首页',
                    path:'/weidong_homepage'
                },
                {
                    id:2,
                    title:'资源商城',
                    path:'/courseGoods'
                },
                {
                    id:3,
                    title:'学习中心',
                    path:'/learning'
                },
                {
                    id:4,
                    title:'实训中心',
                    path:'/online_training'
                },
                {
                    id:5,
                    title:'考试中心',
                    path:'/examList'
                },
                {
                    id:6,
                    title:'直播专区',
                    path:'/liveList'
                },
                {
                    id:7,
                    title:'就业广场',
                    path:'/jobIdx'
                }
            ],
            activeNav:this.$route.path,
        }
    },
    components:{
      account
    },
    watch: {
        $route() {
          setTimeout(() => { //路由跳转
              this.activeNav = this.$route.path
          }, 100)
        }
    },
    async mounted(){
        this.token = localStorage.getItem('token')
    },
    methods:{
        // 导航切换
        tabClick(nav){
            this.activeNav = nav.path
            if (this.$route.path === '/course/testPaper') {
                return;
            } else if(nav.path === this.$route.path) {
                return
            }
            this.$router.push(nav.path)
        },
        jumpLogin(name,type) {
          this.$router.push({
            name: type,
            query: {
                name
            }
          })
        }
    }
}
</script>
<style lang="stylus" scoped>
@import './css/weidongNav.styl'
</style>