<template>
    <div class="aic_homepage">
        <homeBanner />
        <div class="content">
            <p class="title">
                <img src="./../asset/images/left.png" class="icon" alt="">
                <span>考前辅导</span>
                <img src="./../asset/images/right.png" class="icon" alt="">
            </p>
            <p class="text">
                人工智能应用工程师考试支持平台，是依托工业和信息化技术技能人才网上学习平台，为参加培训及考试的学员提供人工智能应用工程师系列培训的考前辅导服务平台。该平台由伟东云教育集团提供技术支持服务，为学员提供以下主要服务：
            </p>
            <div class="img">
                <img src="./../asset/images/live.png" class="live-icon" alt="">
                <img src="./../asset/images/exam.png" class="exam-icon" alt="">
            </div>
        </div>
        <footers />
    </div>
</template>
<script>
import homeBanner from '../block/front/homeBanner.vue'
import footers from '../block/front/footers.vue'
export default {
    name:'aic_homepage',
    components:{
        homeBanner,
        footers
    },
    data(){
        return {

        }
    }
}
</script>
<style lang="stylus" scoped>
.aic_homepage{
    .content{
        width 100%
        height auto 
        background #F7FAFF
        padding-top 80px    
        .title{
            text-align center
            margin-bottom 34px
            span {
                font-size: 30px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #3F3F3F;
                line-height: 42px;
                padding 0 17px
                display inline-block
                vertical-align middle
            }
            .icon{
                width 38px
                vertical-align middle
            }
        }
        .text{
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #3F3F3F;
            line-height: 36px;
            width 993px
            margin 0 auto 34px
        }
        .img{
            margin-bottom 93px
            text-align center
            img{
                width 418px
            }
            .live-icon{
                margin-right 50px    
            }
        }
    }
}
</style>