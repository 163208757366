<template>
  <div class="container">
      <el-dialog
    :visible.sync="showInfo"
    @close="closeDialog"
    @open="openDialog"
    class="pub_dialog"
    center
  >
    <div slot="title" class="dialogHeader">
      <p class="header-title">
        <span> 调试设备 </span>
      </p>
    </div>
    <el-tabs v-model="activeName" :stretch="false">
      <el-tab-pane  label="电脑摄像头检测" name="first">
        <div class="detect-box">
          <DeviceCheckDialog type="restart" @closePc="closePc" ref="detectCameraPc"/>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="isOpenPhoneCamera==1" label="手机端摄像头检测" name="second">
        <div class="mobile-detect">
          <div class="Qr-Code">
            <p class="grid-title">
              <span> 手机端副摄像头二维码 </span>
            </p>
            <div class="qrcode"  ref="qrCodeUrl"></div>
            <div class="step-title">
              <p>检测步骤：</p>
              <p>
                请使用手机端浏览器或微信扫描上方二维码，如右侧示意图，可以查看手机实时画面，则检测通过。
              </p>
            </div>
          </div>
          <div class="example-img">
            <p class="grid-title">
              <span>示例</span>
            </p>
            <img src="../asset/example-img.png" />
          </div>
          <div class="camera-screen">
            <p class="grid-title">
              <span> 手机端摄像头实时画面 </span>
            </p>
            <div v-if="!phoneLiveStatus" class="video-screen">
              <!-- 插入视频流 -->
            </div>

              <div v-if="phoneLiveStatus" id="id_test_video" ref="videos" class="video-screen">
                  <!-- 插入视频流 -->
              </div>
            <div class="detect-pass">
              <p>手机摄像头实时画面检测</p>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
  </div>
</template>
<!--<script src="https://imgcache.qq.com/open/qcloud/video/vcplayer/TcPlayer-2.3.3.js" charset="utf-8"></script>-->
<!--<script src="https://cloudcache.tencent-cloud.com/open/qcloud/video/vcplayer/TcPlayer-2.3.3.js" charset="utf-8"></script>-->
<script>
import QRCode from "qrcodejs2";
const Q = require('q');
import $ from "jquery";
let loadedAMapJS = false // 是否加载完js
export default {
  name: "dialogCommissionEqu",
  components: {
    DeviceCheckDialog: () =>
      import("@/modules/exam/page/dialogs/check/DeviceCheckDialog.vue"),
  },
  props: {
    isShowDialog: {
      type: Boolean,
      default: false,
    },
    urlH5Str: {
          type: String,
          default: '',
      },
      examID: {
          type: String,
          default: '',
      },
  },
  data() {
    return {
      showInfo: false,
      activeName: "first",
      closeShowDialog: false,
      tid:null,
      phoneLiveUrl:"",
      isOpenPhoneCamera:'',//是否开启手机摄像头(1:是;0:否)
      phoneLiveStatus:'',
      player:''
    };
  },
  created() {
    // 判断是否加载过
    if (!loadedAMapJS) {
      this.asyncLoadJs('https://imgcache.qq.com/open/qcloud/video/vcplayer/TcPlayer-2.3.3.js').then(() => {
        loadedAMapJS = true
        console.log('动态加载css+++++++++++++')
      })
    }

  },

  mounted(){

    },
  watch: {
    isShowDialog(val) {
        if(!val){
            clearInterval(this.tid);
        }else {
            let params = {
                id:this.examID,
            }
            this.$api.exam.examDetailData(params).then(res=>{
                this.isOpenPhoneCamera = res.data.isOpenPhoneCamera;
                if(this.isOpenPhoneCamera==1){
                    this.$nextTick((res)=>{
                        console.log(res)
                        this.creatQrCode();
                    })
                    this.tid = setInterval(() => {
                          this.checkH5Play();
                    }, 500);
                }
            })
        }
      this.showInfo = val;
    }
  },
  methods: {
    asyncLoadJs (url) {
      return Q.Promise((resolve, reject) => {
        let hasLoaded = $('script[src="'+url+'"]').length > 0
        if (hasLoaded) {
          resolve()
          return
        }

        let script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        document.body.appendChild(script)
        script.onload = () => {
          resolve()
        }
        script.onerror = () => {
          reject()
        }
      })
    },
    // 检测h5播放链接
      checkH5Play(){
        let LiveParam = JSON.parse(localStorage.getItem('CheckLiveParam'));
        console.log(LiveParam,'轮询参数====')
        let params = {
          businessId: LiveParam.businessId,
          examId:LiveParam.examId,
          businessType: LiveParam.businessType || LiveParam.taskType
        }
        this.$api.exam.queryLinkStatusAndPhoneLive(params).then(res=>{
          // clearInterval(this.tid);
          // console.log(res)
          // this.initP('https://player.wdeduc.com/live/9999.flv')
          // return
          if(res.data.phoneLiveUrl&&res.data.phoneLiveStatus){
              clearInterval(this.tid);
              let self = this;
              setTimeout(function() {
                  self.phoneLiveStatus=res.data.phoneLiveStatus;
                  if(self.phoneLiveStatus){
                      self.phoneLiveUrl= res.data.phoneLiveUrl;
                      self.$nextTick(function (){
                          self.initP(self.phoneLiveUrl)
                      })
                  }

              }, 100)



          }
        })
      },
      initP(video) {
          if(this.player){
              this.player.destroy();
          }
         this.player = new window.TcPlayer("id_test_video", {
              flv:video,
              autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
              poster: {style: "cover"},
              width :  '262',//视频的显示宽度，请尽量使用视频分辨率宽度
              height: '300',//视频的显示高度，请尽量使用视频分辨率高度
              controls: 'none',
              systemFullscreen: true,
              live: true,
              // controls:'system', // default 显示默认控件，none 不显示控件，system 移动端显示系统控件 备注：如果需要在移动端使用系统全屏，就需要设置为system。默认全屏方案是使用 Fullscreen API + 伪全屏的方式例子
              pausePosterEnabled: true,
              volume: 0.5,
              h5_flv:true,
              wording: {
                  1:'',
                  2:'手机摄像头实时画面检测',
                  4:'',
                  13:'',
                  2032: '',
                  2048: '',
                  12:'请进行手机调试'
              }
          });
          // this.autoPlay(1)

          // this.player.listener
          this.player.mute(true)
      },

      autoPlay(num) {
          if (num > 5) {
              return
          }
          if (this.player) {
              setTimeout(() => {
                  this.autoPlay(num + 1)
                  this.player.play()
                  console.log('======___player')
              }, 100)
          }
      },
      // pc 端设备检测结束
      closePc(){
          if(this.isOpenPhoneCamera==1){
              this.activeName='second';
          }else {
              this.showInfo = false;
          }
      },
    openDialog() {
      this.$nextTick(() => {
          // 修改电脑端组件样式
          if( this.$refs.detectCameraPc.$el){
              this.$refs.detectCameraPc.$el.children[0].style.background = "none";
              this.$refs.detectCameraPc.$el.style.position = "relative";
              if(this.$refs.detectCameraPc.$refs.deviceTestingCloseBtn){
                  this.$refs.detectCameraPc.$refs.deviceTestingCloseBtn.style.display =
                      "none";
              }
          }

      });
      this.closeShowDialog = true;
    },

    //   生成二维码
    creatQrCode() {
      // let localDomain = JSON.parse(window.localStorage.getItem('domain')).filter(item=>item.frontend_template_id=='4')
      let paramURL = this.urlH5Str;
      if(this.$refs.qrCodeUrl.title)return
      new QRCode(this.$refs.qrCodeUrl, {
        text: paramURL, // 需要转换为二维码的内容
        width: 160,
        height: 160,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    closeDialog() {
      clearInterval(this.tid);
      this.$emit("equShow", false);
    }
  },
};
</script>
<style lang="stylus" scoped>
.container /deep/ .el-dialog {
  border-radius: 10px;
  height: 641px;
  width: 1000px;
}
//.container #tab-first{
//  padding-right: 48px;
//  text-align: right;
//}
//.container #tab-second{
//  padding-left: 48px;
//  text-align: left;
//}
//.container .el-tabs__nav-scroll{
//  width: 310px;
//  margin: 0 auto;
//}
//.container .el-tabs__active-bar{
//  width: 98px!important;
//}
//.container .el-tabs__item{
//  color: none;
//}

.container /deep/ .el-tabs__item.is-active {
    color: #316FFF;
}

.container /deep/ .el-tabs__item.is-active .is-top .is-focus  {
    color: #316FFF;
}

.container /deep/ .el-tabs__active-bar{
    background-color: #316FFF;
}

.container /deep/ .el-dialog__header{
    padding:0 0 10px 0;
}

.container /deep/ el-dialog--center .el-dialog__body{
    padding:0 25px 30px;

}

.container /deep/ .el-tabs__nav-scroll {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container /deep/.el-dialog__headerbtn{
    top:10px;
}
.el-tabs__header {
  margin-bottom: 39px;
}

.el-dialog__header {
  padding: 0;
}

.el-dialog__headerbtn .el-dialog__close {
  font-size: 20px;
}

.el-dialog__headerbtn {
  top: 11px;
}
</style>
<style lang="stylus" scoped>
@import '../asset/css/dialogCommissionEqu.less';
@import url('https://imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.css');
</style>
