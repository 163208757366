<template>
    <div class="training-task-list">
        <div class="tab">
            <p class="text" v-for="tab in tabList" :key="tab.id" :class="{active:tabIndex === tab.id}" @click="tabClick(tab)">
                {{tab.value}}
            </p>
        </div>
        <div class="content">
            <div class="list" v-for="item in taskList" :key="item.id">
                <div class="left item">
                    <img class="cover" :src="item.coverUrl" alt="">
                    <img v-show="item.isNewTask" class="icon" src="../assets/images/learning/new-icon.png" alt="">
                </div>
                <div class="middle item">
                    <div class="title">
                        <!-- 0 未分类，1 必修课，2 选修课，3 公开课 -->
                        <p 
                            class="modifier" 
                            :style="
                                item.taskType === 1 ? 'background:#FF7051'
                                :item.taskType === 2?'background:#2EC8A5'
                                :'background:#6080FB'" 
                            v-show="item.taskType"
                        >
                            {{item.taskType === 1 ? '必修课' :item.taskType === 2?'选修课':'公开课' }}
                        </p>
                        <h1>{{item.name}}</h1>
                    </div>
                    <p class="label">
                        <span class="text" v-for="type in item.typeList" :key="type">{{type}}</span>
                    </p>
                    <p class="bottom-container">
                        <span class="shichang" v-show="item.courseNum">学习时长: {{formateTime(item.learnTotalTime)}} 小时</span>
                        <span class="studyProcess" v-show="item.courseNum" :style="validity === '' || validity === 'true' ? 'border-right: 1px solid #ccc;' :'border-right:0'">已完成: {{item.studyProcess}}%</span>
                        <!-- <span class="time" v-if="item.cycleType === 1">有效期至：{{item.startTime.split(' ')[0].replace(/-/g,'.')}} - {{item.endTime.split(' ')[0].replace(/-/g,'.')}}</span> -->
                        <span class="time" v-if="(item.cycleType === 2 || item.cycleType === 1) && (validity === '' || validity === 'true')">有效期至：{{item.endTime && item.endTime.split(' ')[0].replace(/-/g,'.')}}</span>
                        <span class="time" v-if="item.cycleType === 3 && (validity === '' || validity === 'true')">长期有效</span>
                    </p>
                </div>
                <div class="right item">
                    <div class="button" @click="goTaskDetail(item)">{{$dateFormat.dateFormat() > item.endTime ? '查看' :item.isNewTask ? '开始学习':'继续学习'}}</div>
                </div>
            </div>
            <article v-show="noData" class="no-data">
                <img class="notraining" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/nodata.png" alt />
                <p>您还没有学习内容</p>
            </article>
            <p class="loadingmore" v-if="loading">加载中...</p>
            <p class="loadingmore" v-if="noMore">- 已加载全部内容 -</p>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
export default {
    name:'trainingTask',
    props:{
        validity:{
            type:String,
            default:''
        }
    },
    data(){
        return {
            tabIndex:-1,
            tabList:[
                {
                    id:-1,
                    value:'全部',
                },
                {
                    id:1,
                    value:'未开始',
                },
                {
                    id:2,
                    value:'进行中',
                },
                {
                    id:3,
                    value:'已结束',
                }
            ],
            paging: {
                params: {
                    pageNum: 1,
                    pageSize: 10
                },
                total: 0
            },
            taskList:[],
            taskStatus:null,
            loading:false,
            noData:null,
        }
    },
    computed: {
        noMore () {
            return this.taskList.length === this.paging.total && this.paging.total !== 0
        },
    },
    mounted(){
        this.getData()
        $(window).scroll(() => {
            //判断是否滑动到页面底部
            if (
                Math.round($(window).scrollTop()) ===
                $(document).height() - $(window).height()
            ) {
                // TODO 滑动到底部时可请求下一页的数据并加载，加载可使用append方法
                // console.log('页面底部')
                
                setTimeout(this.reachBottom(), 1000)
            }
        });
    },
    methods:{
        formateTime(val) {
            if(val){
                if((val / 3600).toFixed(2)>0){
                    return (val / 3600).toFixed(2);
                }else{
                    return (val / 3600).toFixed(0);
                }
                
            }else{
                return val;
            }
        },
        tabClick(item){
            this.tabIndex = item.id
            this.paging.params = {
                pageNum: 1,
                pageSize: 10
            }
            this.taskStatus = item.id === -1 ? null : item.id;
            this.getData();
        },
        
        goTaskDetail(item){
            if(this.$dateFormat.dateFormat() < item.startTime){
                this.$message.warning('任务未开始')
            }else if(this.$dateFormat.dateFormat() > item.endTime ){
                this.$message.warning('已到期，无法学习')
            }else {
                this.$router.push({
                    path:'/learning/taskDetail',
                    query:{
                        backMoudle:'trainingTask',
                        id:item.id,
                        validity:this.validity,
                        backPath:this.$route.path
                    }
                })
            }
        },
        reachBottom() {
            if (this.paging.total > this.paging.params.pageNum * this.paging.params.pageSize) {
                this.paging.params.pageNum = this.paging.params.pageNum + 1;
                this.loading = true;
                this.getData("reachBottom");
            } else {
                this.loading = false;
            }
        },
        getData() {
            this.$api.learning
                .taskList({
                    params: {
                        pageNum: this.paging.params.pageNum,
                        pageSize: this.paging.params.pageSize,
                        taskStatus: this.taskStatus
                    }
                })
                .then(({ data: { list, total } }) => {
                    if(list && list.length){
                        this.noData = false
                    }else {
                        this.noData = true;
                    }
                    if(this.paging.params.pageNum>1){
                        this.taskList = this.taskList.concat(list);
                    } else {
                        this.taskList = list || [];
                    }
                    let typeList = [];
                    this.taskList.length && this.taskList.forEach(item => {
                        typeList = [];
                        item.contentTypeSet.length && item.contentTypeSet.forEach(item2=>{
                            if(item2 === 2){
                                typeList.push('课程')
                            }else if(item2 === 3){
                                typeList.push('考试')
                            }else if(item2 === 4){
                                typeList.push('直播')
                            }else if(item2 === 5){
                                typeList.push('实训')
                            }else if(item2 === 6){
                                typeList.push('表单')
                            }else if(item2 === 7){
                                typeList.push('练习')
                            }
                        })
                        item.typeList = typeList;
                    })
                    this.paging.total = total;
                });
                
        },
    }
}
</script>
<style lang="stylus" scoped>
@import './../assets/css/learningList.styl'
</style>