import Vue from 'vue';
import './libs/element';
import App from './App.vue';
import router from './router';
import store from './store';
// import i18n from './locale';
import config from './config';
import api from './api';
import Templates from './libs/template'
import Tools from '@stroll/tools'
// import Theme from './libs/theme'
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";
import './styl/app.styl';
// import './assets/css/style.styl';
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
config.productionTip = process.env.NODE_ENV === 'development';
import 'promise-polyfill/src/polyfill';
import dateFormat from './libs/dateFormat';// 当前时间转换
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;
import sensors from './utils/sensors'
Vue.prototype.$sensors = sensors
import question from '@wd/question';
import tempRender from '@wd/news-temp';
import '@wd/question/lib/question.css';
Vue.use(question)
Vue.use(tempRender)
Vue.prototype.resetSetItem = function (key, newVal) {
    if (key == 'signOutStorage') {
        // 创建一个StorageEvent事件
        var newStorageSignOut = document.createEvent('StorageEvent');
        const storage = {
            setItem: function (k, val) {
                sessionStorage.setItem(k, val);
                // 初始化创建的事件
                newStorageSignOut.initStorageEvent('setItem', false, false, k, null, val, null, null);
                // 派发对象
                window.dispatchEvent(newStorageSignOut)
            }
        }
        return storage.setItem(key, newVal);
    }
},
    /**
     * @description 全局注册应用配置
     */
    Vue.use(Templates)
Vue.use(VueVideoPlayer, /* {
  options: global default options,
  events: global videojs events
} */)
Vue.prototype.$config = config;
Vue.prototype.$tools = Tools
Vue.prototype.$api = api;
Vue.prototype.$dateFormat = dateFormat;

//新加配置代码
Vue.prototype.$store = store;
let domain = window.location.host
const TcPlayer = window.TcPlayer
Vue.prototype.TcPlayer = TcPlayer
//close


const BrowserLogger = require('alife-logger');
// BrowserLogger.singleton(conf) conf传入config配置。
// eslint-disable-next-line no-unused-vars
const bl = BrowserLogger.singleton({
    pid: 'g2t7afypx3@a71a3d04b8450ea',
    // 设定日志上传地址：
    // 部署新加坡地域可设为`https://arms-retcode-sg.aliyuncs.com/r.png?`。
    // 部署美西地域可设为`http://arms-us-west-1.console.aliyun.com/r.png?`。
    imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
    enableLinkTrace:true,
    // enableApiCors: true,
    // 其他config配置。
});


const hostname = window.location.hostname
if (!hostname.includes('dev') && !hostname.includes('localhost')) {
    Sentry.init({
        dsn: "https://fc24a93e5abc44c1a269d3d550ff133a@sentry.corp.wdeduc.com/7",
        integrations: [
            new VueIntegration({
                Vue,
                tracing: true,
            }),
            new Integrations.BrowserTracing(),
        ],
        environment: process.env.VUE_APP_NODE_ENV,
        release: process.env.VUE_APP_RELEASE,
        logErrors: true
    });
}
function bindDynamicPage(pageList) {
    store.commit('storeSetPageList', pageList);
    pageList.forEach(p => {
        p.uri && router.addRoute({
            path: p.uri,
            name: p.pageName,
            meta: {
                noLogin: true,
            },
            component: () => import(/* webpackChunkName: 'error' */ './modules/dynamic/page/render.vue'),
        })
        p.manual_url && router.addRoute({
            path: p.manual_url,
            name: p.pageName,
            meta: {
                noLogin: true,
            },
            component: () => import(/* webpackChunkName: 'error' */ './modules/dynamic/page/render.vue'),
        })
    });
}
// generated buy layout
// function getLayoutPage() {
//     return api.configuration.getPageConfig({ domain }).then((data) => {
//         if (data.data && data.data.clickList) {
//             bindDynamicPage(data.data.clickList);
//         }
//     })
// }
function getLayoutJsonPage(dragList) {
    // 根据后端pages定义路由
    dragList.map((page) => {
        if (page.uri){
          const route = {
            path: page.uri.indexOf(':') != -1 ? page.uri.split(':')[0] : page.uri,
            name: page.pageName,
            query: page.uri.indexOf(':') != -1 ? page.uri.split(':')[1] : '',
            component: () => import(/* webpackChunkName: 'error' */ './view/common/common_page.vue'),
            meta: {
                keepAlive: true,
                moduleId: page.pageName,
                name: page.label,
                noLogin: true
            }
          };
          router.addRoute(route);
        }
        if (page.manual_url){
            const route = {
                path: page.manual_url,
                name: page.pageName,
                query: page.uri.indexOf(':') != -1 ? page.uri.split(':')[1] : '',
                component: () => import(/* webpackChunkName: 'error' */ './view/common/common_page.vue'),
                meta: {
                    keepAlive: true,
                    moduleId: page.pageName,
                    name: page.label,
                    noLogin: true
                },
            };
            router.addRoute(route);
        }

    });
    store.commit('setPageConfigs', dragList);
    // localStorage.setItem('setPageConfigs', JSON.stringify(dragList)) //本地存储数据

}
function getLayoutJson() {
    return api.configuration.getConfiguration({ domain }).then((data) => {
        if (data.code === 0) {
            bindDynamicPage(data.data.clickList);
            getLayoutJsonPage(data.data.dragList)

            let temp = {
              pageHeader: data.data.pageHeader,
              pageHeaderSetting: data.data.pageHeaderSetting,
              pageEndSetting: data.data.pageEndSetting,
              pageEnd: data.data.pageEnd
            }
            localStorage.setItem('setTempConfigs', JSON.stringify(temp)) //本地存储数据
        } else {
            console.log(data.message)
        }
    })
}

Promise.all([getLayoutJson()]).then(() => {
    let errorPage = [
        {
            path: '/401',
            name: 'error_401',
            component: () => import(/* webpackChunkName: 'error' */ './view/error/401.vue'),
        },
        {
            path: '/500',
            name: 'error_500',
            component: () => import(/* webpackChunkName: 'error' */ './view/error/500.vue'),
        },
        {
            path: '*',
            name: 'error_404',
            component: () => import(/* webpackChunkName: 'error' */ './view/error/404.vue')
        },
    ]
    router.addRoutes(errorPage)
    new Vue({
        router,
        store,
        // i18n,
        render: h => h(App),
    }).$mount('#wdc-app');
});
