<template>
    <div class="Playback">
        <div class="title">
            <i class="el-icon-arrow-left icon" @click="handleBack()"></i>
            <p class="name">{{title}}</p>
            <p class="button">直播回放</p>
        </div>
        <div class="bottom-contrain">
            <div class="bottom-l">
                <videoliveplayer ref="videoliveplayer"></videoliveplayer>
                 <!-- <span class="fangluping">{{studentCode + '正在观看视频'}}</span> -->
            </div>
            <div class="bottom-r">
                <div class="teacher">
                    <img :src="propagatePic ? propagatePic : require('../../../assets/images/phone.png')" alt="" style="width:280px;height:210px;">
                </div>
                <div class="send-barrage" >
                    <div class="title">
                        <p>讨论</p>
                    </div>
                    <div class="middle" v-infinite-scroll="load">
                        <div class="item" :class="userId === item.id ? 'oneself':''" v-for="item in discussionList" :key="item.id">
                        <div class="left head-portrait">
                            <img :src="item.userHeadPortrait ? item.userHeadPortrait : require('../../../assets/images/userL.png')" alt="">
                        </div>
                        <div class="right">
                            <div class="top">
                                <span class="label student" v-if="item.role === 1">学生</span>
                                <span class="label teacher" v-else>老师</span>
                                <span class="name">{{item.userNickname}}</span>
                            </div>
                            <div class="content">
                                <p v-for="(msg,index2) in item.renderDom" :key="index2" >
                                    <span v-if="msg.name === 'text'" class="text">{{msg.text}}</span>
                                    <img :src="msg.src" class="emoji" v-else alt="" style="width:24px;">
                                </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <!-- <div class="emojiMap" ref="emojiMap" v-show="emojiMapShow">
                        <div class="emoji" v-for="(value,key,index) in emojiMap" :key="index">
                        <img :src="value" alt="">
                        </div>
                    </div> -->
                    <div class="bottom">
                        <div class="icon-box">
                        <img src="../asset/emoji-icon.png" class="emoji-icon" alt="">
                        
                        <img src="../asset/hand-icon.png" class="hand-icon"  alt="">
                        <!-- <span v-show="Countdown" class="time">{{Countdown}} s</span> -->
                        </div>
                        <div class="input-box">
                        <el-input placeholder="请输入..." resize="none" type="textarea" maxlength="70" show-word-limit></el-input>
                        <el-button type="primary" size="small"  round>发送</el-button>
                        </div>  
                        
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import videoliveplayer from "./video"
import { renderDom } from '../libs/emojiRender'
export default {
    name:'livePlayback',
    components:{
        videoliveplayer
    },
    data(){
        return{
            userId:3,
            title:'',
            propagatePic:'',
            discussionList:[],
            groupId:'',
            sorceId:'',//资源id
            params:{
                pageNum:1,
                pageSize:20
            },
            count:0,
            studentCode:'',
        }
    },
    created(){
        if(this.$route.query.roomId){
            this.sorceId = this.$route.query.sourceId
        }
    },
    mounted(){
        this.findById()
        // this.getBarrageData();
    },
    methods:{
        handleBack(){
            this.$router.go(-1)
            // this.$router.push({
            //     path: '/learning'
            // })
        },
        findById(){
            this.$api.live.findById(
                {
                id: this.$route.query.roomId // 课程id
                }
            ).then((res) => {
                if(res.success){
                    this.groupId = res.data.groupId
                    // this.groupId = '@TGS#aBNTDIGHQ'
                    this.propagatePic = res.data.propagatePic
                    this.title = res.data.title
                    this.getBarrageData()
                    this.saveViewUser()
                    this.getStudentVisitCode()
                }
            })
        },
        // 获取弹幕列表
        getBarrageData(){
            const data = {
                groupId:this.groupId,
                msgState:2,
            }
            const params = this.params
            this.$api.live.getBarrageList({data,params}).then((res) => {
                if(res.success){
                    res.data.list.forEach(item => {
                        this.discussionList.push(item)
                        this.discussionList.length && this.discussionList.forEach(item =>{
                            item.renderDom = renderDom(item)
                        })
                    })
                }
            })
        },
        // 无限滚动
        load(){
            this.count += 1;
            this.params.pageNum = this.count;
            this.getBarrageData();
        },
        // 直播回放
        saveViewUser(){
            this.$api.live.getPlayAuthToken(this.sorceId).then((res) => {
                if(res.success){
                    this.$refs.videoliveplayer.initPlayer(res.data)
                }
            })
        },
        // 防录屏
        getStudentVisitCode(){
            this.$api.live.getStudentVisitCodes().then((res) => {
                if(res.success && res.data){
                    this.studentCode = res.data

                }
            })
        }
    }
}
</script>
<style lang="stylus" scoped>
@import "../asset/css/playback.styl"
</style>