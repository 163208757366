<template>
    <div class="college-main">
        <div class="college-detail">
            <div class='projectList'>
                <!-- <h2 class="projectList-title">学院专区</h2>
                <div class="projectList-bgc"></div>
                <p class="projectList-con">以用户为核心的卓越企业培训体验</p> -->
                <div class="projectList-list">
                    <div class="list" v-for="(item, index) in listCard" :key="index" @click="goLogin(item.id)">
                        <div class="list-img">
                            <img :src="item.imgUrl" alt="">
                        </div>
                        <div class="title">
                            <h2 class="list-title">{{item.title}}</h2>
                            <p class="list-con">{{item.content}}</p>
                        </div>
                    </div>
                </div>
                <paging class="paging" :paging="paging" :cardPage="cardPage" v-if="listCard.length" />
            </div>
        </div>
    </div>

</template>

<script>
import paging from "../../../../layout/paging";
export default {
    name:'collegeDetail',
    components: {
        paging,
    },
    props: {
    },
    data () {
        return {
            listCard: [
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/college_img1.png',
                    title: '企业管理学院',
                    content: '包含8门课程',
                    id: 0
                },
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/college_img2.png',
                    title: '南湖学院',
                    content: '包含10门课程',
                    id: 1
                },
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/college_img3.png',
                    title: '传媒学院',
                    content: '包含0门课程',
                    id: -3
                },
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/college_img4.png',
                    title: '技工学院',
                    content: '包含0门课程',
                    id: -4
                },
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/college_img5.png',
                    title: '创业学院',
                    content: '包含0门课程',
                    id: -5
                },
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/college_img6.png',
                    title: '人力资源学院',
                    content: '包含0门课程',
                    id: -6
                },
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/college_img7.png',
                    title: '护理学院',
                    content: '包含10门课程',
                    id: 2
                },
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/college_img8.png',
                    title: '安保职业学院',
                    content: '包含0门课程',
                    id: -8
                },
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/college_img9.png',
                    title: '金融学院',
                    content: '包含0门课程',
                    id: -9
                },
                {
                    imgUrl: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/zhongzhi/college_img10.png',
                    title: 'IT学院',
                    content: '包含10门课程',
                    id: 3
                },
            ],
            cardPage: "zhongzhi_college",
            paging: {
                params: {
                    pageNum: 1,
                    pageSize: 12
                },
                total: 0
            },
        };
    },
    mounted() {
        this.paging.total = this.listCard.length
    },
    methods: {
        goLogin(id){
            if (id >= 0) {
                this.$router.push({
                    path:'/collegeDetails',
                    query: {
                        id: id
                    }
                })
            } else {
                this.$message({
                    showClose: true,
                    message: '学院正在建设中，敬请期待',
                    type: 'warning'
                });
            }
        },
    },
}

</script>
<style lang='stylus' scoped>
    @import "../../asset/css/college.styl"
</style>
