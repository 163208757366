<template>
    <div class='companies'>
        <ul v-if="companiesList.length>0">
            <li v-for="(item,index) in companiesList" :key="index">
                <img src="../../asset/images/companies.png" alt="">
                <div class="companies-main">
                    <h4 class="companies-main-title">{{item.companyName}}</h4>
                    <p>营业执照号：{{item.companyCreditCode}}</p>
                    <p>联系方式: {{item.companyLegalPerson}}&nbsp;&nbsp;{{item.companyPhone}}</p>
                    <p>公司地址：{{item.companyStoreAddress}}{{item.companyHouseNumber}}</p>
                </div>
                <div class="companies-btn" @click="detailBtn(item.companyId)">诚信详情</div>
            </li>
            <div class="companies-page" v-if="seachflag">
                <el-pagination
                background
                :current-page="params.pageNum"
                :page-size="params.pageSize"
                layout="total, prev, pager, next, jumper"
                @current-change="changePage"
                @size-change="changePage"
                :total="total">
                </el-pagination>
            </div>
        </ul>
        <div class="noData" v-if="seachflag==true && companiesList.length===0">
            <img src="../../asset/images/noData.png" alt="">
            <p>未查询到具体的家政公司，请确认后再查询！</p>
        </div>
        <companyCredit v-if="dialogShow.dialogVisible3" :dialogShow="dialogShow" :companyId="companyId"></companyCredit>
    </div>
</template>

<script>
import companyCredit from './companyCredit.vue'
export default {
    name:'companies',
    components:{
        companyCredit
    },
    props:{
        seachflag:{
            type:Boolean,
            default:false
        },
        companyValue:{
            type:String,
            default:''
        },
    },
    data () {
        return {
            total:0,
            dialogShow:{
                dialogVisible3:false,
            },
            companiesList:[],
            companyId:null,
            params:{
                pageNum:1,
                pageSize:10,
            }
        };
    },
    methods: {
        changePage(index){
            this.params.pageNum=index;
            this.findList();
        },
        detailBtn(id){
            this.companyId=id;
            this.dialogShow.dialogVisible3=true;
        },
        homeDisplayList(){
            this.$api.sdjz.homeDisplayList()
            .then(res => {
                if(res.success==true){
                    this.companiesList=res.data.list;
                }
            });
        },
        findList(index){
            if(index===1){
                this.params.pageNum=1;
            }
            let data={
                companyName:this.companyValue,
            }
            this.$api.sdjz.findList({data,params:this.params})
            .then((res) => {
                if(res.success==true){
                    this.companiesList=res.data.list;
                    this.total=res.data.total;
                }
            });
        }
    },

    mounted(){
        this.homeDisplayList();
    },
}

</script>
<style lang='stylus' scoped>
    @import "../../asset/css/companies.styl"
</style>