<template>
    <div class="sdjt-approval-page">
        <div class="block-1 block">
            <h4>申领操作流程</h4>
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/approval/approval-c-1.png" />
        </div>
        <div class="block-2 block">
            <h4>上门证功能</h4>
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/approval/approval-c-2.png" />
        </div>
        <div class="block-3 block">
            <h4>上门证展示</h4>
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/approval/approval-c-3.png" />
            <div class="flex">
                <p>物理证</p>
                <p>电子证</p>
            </div>
        </div>
        <footers/>
    </div>
</template>
<script>
import footers from './sdjz2Footer.vue'
export default {
    name: 'doorToDoorApproval',
    components: {
        footers,
    }
}
</script>
<style scoped lang="stylus">
.sdjt-approval-page
    .block
        width 100%
        padding-top 84px
        box-sizing border-box
        img
            display block
            margin 0 auto
        h4
            color #D3411D
            font-size 32px
            line-height 45px
            text-align center
    .block-1
        height 548px
        background url(https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/approval/approval-bg-1.png)
        background-size cover
        img
            margin-top 64px
            width 1166px
    .block-2
        height 658px
        background url(https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/approval/approval-bg-2.png)
        background-size cover
        img
            margin-top 36px
            width 1261px
    .block-3
        height 888px
        background url(https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/approval/approval-bg-3.png)
        background-size cover
        margin-top -4px
        margin-bottom 54px
        img
            margin-top 17px
            width 1019px
        .flex
            display flex
            margin 0 auto 
            width 600px
            justify-content space-between
            p 
                color #313131
                font-size 32px
                line-height 45px
</style>