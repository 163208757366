<template>
    <div class="homecontent1-ll">
        <div class="zhuangye banner">
            <!-- <img class="left" src="../../asset/images/left.png" alt=""> -->
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/page-block/sdjzpx.png" alt="">
            <!-- <img class="right" src="../../asset/images/right.png" alt=""> -->
        </div>
        <div class="main-content-wraper">
            <div class="main-content">
                <div class="shangmen">
                    <!-- 上门服务证 step1-->
                    <!-- <a name="step1">
                        <img class="shangmentop" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/page-block/shangmen.png" alt="">
                    </a> -->
                    <div id="step2">
                         <h3  class="link_title">居家上门服务证</h3>
                        <p class="bottom"></p> 
                        <img class="shangmentop"  id="step1" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/page-block/shangmen.png" alt="">
                    </div>

                    <!-- 职业认证课 step2-->
                    <div id="step2">
                    <h3  class="link_title">职业认证课</h3>
                    <p class="bottom"></p> 
                    <ul class="zhiyeUL">
                        <li @click="jump">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/page-block/zhiye1.png" alt="">
                            <div class="step2-text">
                                <p class="step2-title1">养老护理</p>
                                <p class="step2-title2">课程重点在老年人生活照护、技术护理、康复照护、心理照护四大模块，分为初、中、高级、技师（专家）、高级技师（金牌专家）。</p>
                            </div>
                        </li>
                        <li>
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/page-block/zhiye2.png" alt="">
                            <div class="step2-text">
                                <p class="step2-title1">家务服务</p>
                                <p class="step2-title2">课程重点包括家庭营养餐、清洁洗涤、居家美化、基础护理相关内容，分为初、中、高级、技师（专家）、高级技师（金牌专家）。</p>
                            </div>
                        </li>
                        <li>
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/page-block/zhiye3.png" alt="">
                            <div class="step2-text">
                                <p class="step2-title1">母婴护理</p>
                                <p class="step2-title2">课程重点包括孕产妇、新生儿照护、母乳喂养、产后康复等内容，分为初、中、高级课程。</p>
                            </div>
                        </li>
                        <li>
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/page-block/zhiye4.png" alt="">
                            <div class="step2-text">
                                <p class="step2-title1">育婴</p>
                                <p class="step2-title2">课程是育婴员全国职业资格考试精讲课程，包括婴幼儿生活照料、保健护理及行为、语言认知培养，分为初、中、高级。</p>
                            </div>
                        </li>
                        <li>
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/page-block/zhiye5.png" alt="">
                            <div class="step2-text">
                                <p class="step2-title1">家庭照护</p>
                                <p class="step2-title2">课程重点内容包括老年人、病患医学护理和生活照料复合技能，分为初、中、高级。</p>
                            </div>
                        </li>
                        <li>
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/page-block/zhiye6.png" alt="">
                            <div class="step2-text">
                                <p class="step2-title1">整理收纳</p>
                                <p class="step2-title2">课程注重实用性、课程内容包括功能空间收纳、亲子收纳、生活用品收纳等，分为初、中、高级。</p>
                            </div>
                        </li>
                    </ul>
                    </div>    
                      
                </div>
                <div class="zhuangye">
                    <!-- 职业证书 -->
                    <div id="step3"  class="link_title" >
                        <Certificate/>
                    </div>
                </div>
                
            </div>
            <!-- 推荐课程 申请入驻 -->
            <div class="right-recoment" :style="{'opacity':anchorOpacity}">
                <div class="item" @click="clickShowDialog(1)"></div>
                <div class="item"  @click="clickShowDialog(2)"></div>
            </div>
        </div>
        <div class="main-content-wraper2">
             <!-- 专向能力课 step4-->
            <!-- <div class="apy-zh"> -->
                <div id="step4"  class="link_title">
                    <!-- <h3>专项能力课</h3> -->
                    <img   src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/page-block/zhuanxiang.png" alt="">
                </div>
            <!-- </div> -->
             <!-- 学什么 step5-->
             <div  id="step5" class="link_title">
                <img  src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/page-block/xueshenme.png" alt="">
             </div>
            <!-- 如何开始学 step6-->
            <img id="step6" class="link_title" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/page-block/ruhe.png" alt="">
            <div class="sdjt-hp-page-block">
                <!-- 如何晋级 step7-->
                <img id="step7" class="link_title" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/page-block/page-block-6.png" />
                <!-- 优质师资 step8-->
                <img id="step8" class="link_title" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/page-block/page-block-7.png" />
                <!--  更多技能 step9-->
                <img id="step9" class="link_title" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/page-block/page-block-8.png" />
                <!--  更好未来 step10-->
                <img id="step10" class="link_title" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/page-block/page-block-9.png" />
                <!-- 平台特色 step11-->
                <img id="step11" class="link_title" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/page-block/page-block-10.png" />
                <!-- 学习流程 -->
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/page-block/page-block-11.png" />
            </div>
        </div>
        <ul class="anchor-list" :style="{'opacity':anchorOpacity}">
            <li v-for="(item, index) in anchorList" @click="linkTo(item.id,(index))" :class="activeNav !== index ? 'anchor-item': 'link-active '"  :key="index">
                <span :class="index>0 ?'border': 'number'" >{{index+1}}</span>
                <!-- <a :href="item.id">
                    <span class="title">{{item.name}}</span>
                </a> -->
                <span class="title">{{item.name}}</span>
            </li>
        </ul>
        <FormDialog ref="dialogShow"/>

    </div>
</template>

<script>
import FormDialog from './formDialog.vue'
import Certificate from './certificate.vue'
export default {
    data() {
        return {
            currentIndex: 0,
            activeNav: null,
            dialogShow: {
                dialogVisible: false
            },
            anchorOpacity: 0,
            initOffetHeight: 0,
            rightRecoment:0,
        };
    },
    components: {
        FormDialog,
        Certificate
    },
    methods: {
        jump() {
            this.$router.push('sdjz_yanglao2')
        },
        linkTo(val, index) {
            this.activeNav = index
            
            // 获取含样式类 class="link_title" 的所有元素，得到一个数组（列表）
            let titleList = document.querySelectorAll('.link_title')
            // offsetTop为元素顶部与整个文档顶部间的距离
            let offsetTop = titleList[index].offsetTop;
            // offset为平滑滚动结束后，元素顶部与显示屏顶部间的距离,默认为0，页面滚动到标题触顶
            // let offset=0
            // offsetTop -= offset
            // scrollTop为显示屏顶部与整个文档顶部间的距离
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

            // 步长（时长500ms，每10ms一跳，共50跳）
            let step = Math.abs(offsetTop-scrollTop) / 50;
            if (offsetTop > scrollTop) {
                // offsetTop > scrollTop 时元素在显示屏下方时，滚动条需下滑
                // scrollTop需增加（offsetTop-scrollTop）
                smoothDown();
            } else {
                // offsetTop < scrollTop 时元素在显示屏上方时，滚动条需上滑
                // scrollTop需减少（scrollTop - offsetTop）
                smoothUp();
            }
            // 向上平滑滚动
            function smoothUp () {
                if (scrollTop > offsetTop) {
                    scrollTop -= step;
                    document.body.scrollTop = scrollTop;
                    document.documentElement.scrollTop = scrollTop;
                    setTimeout(smoothUp, 10);
                } else {
                    document.body.scrollTop = offsetTop;
                    document.documentElement.scrollTop = offsetTop;
                }
            }
            // 向下平滑滚动
            function smoothDown () {
                if (scrollTop < offsetTop) {
                    scrollTop += step;
                    document.body.scrollTop = scrollTop;
                    document.documentElement.scrollTop = scrollTop;
                    setTimeout(smoothDown, 10);
                } else {
                    document.body.scrollTop = offsetTop;
                    document.documentElement.scrollTop = offsetTop;
                }
            }
        },

        //页面滚动时触发
        scroll() {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            let titleList = document.querySelectorAll('.link_title')
            let offsetTopList = [scrollTop]

            titleList.forEach(item => {
                offsetTopList.push(item.offsetTop)
            })
            offsetTopList.sort((a,b)=>{
                    return a - b;
                }
            )
            if(scrollTop>=offsetTopList[0]){
                this.activeNav = offsetTopList.indexOf(scrollTop)
            }
            if(scrollTop>=this.initOffetHeight) {
                this.anchorOpacity = 1
                this.rightRecoment = 1
            } else {
                this.anchorOpacity = 0
                this.rightRecoment = 0
            }
        },
        clickShowDialog(type) {
            if(type === 1){
                
                // this.$router.push('/courseGoods')
                this.$router.push({
                    path:'/courseGoodsDetail',
                    query:{
                        goodsId:'655'
                    }
                })
                // window.open('https://sdjzpx.wdeduc.com/courseGoodsDetail?goodsId=655','_self')
            }else {
                // this.dialogShow.dialogVisible = true
                this.$refs.dialogShow.isShow();
            }
        }

    },
    created() {
        // this.anchorList = [{id:'#step1', name: '上门服务证'}, {id: '#step2', name: '职业认证课'}, 
        // {id: '#step3', name: '职业证书'}, {id: '#step4', name: '专项能力课'}, 
        // {id: '#step5', name: '学什么'}, {id: '#step6', name: '如何开始学'},
        // {id: '#step7', name: '如何晋级'}, {id: '#step8', name: '更优秀的师资'}, 
        // {id: '#step9', name: '更多的技能'}, {id: '#step10', name: '更美好的未来'}, {id: '#step11', name: '平台特色'}]
         this.anchorList = [{id:'step1', name: '上门服务证'}, {id: 'step2', name: '职业认证课'}, 
        {id: 'step3', name: '职业证书'}, {id: 'step4', name: '专项能力课'}, 
        {id: 'step5', name: '学什么'}, {id: 'step6', name: '如何开始学'},
        {id: 'step7', name: '如何晋级'}, {id: 'step8', name: '更优秀的师资'}, 
        {id: 'step9', name: '更多的技能'}, {id: 'step10', name: '更美好的未来'}, {id: 'step11', name: '平台特色'}]
    },
    mounted() {
        this.$nextTick(
            () => {
                // 监听页面滚动事件
                window.addEventListener("scroll", this.scroll)
                this.initOffetHeight = document.querySelectorAll('.link_title')[0].offsetTop - 80
                
            }
           
        )

    }
};
</script>

<style scoped lang="stylus">
@import '../../asset/css/homepageContent1.styl'
</style>
<style scoped lang="stylus">
.sdjt-hp-page-block
    width 900px
    img
        width 100%
        display block
.main-content-wraper
    display flex 
    box-sizing border-box 
    width 100%
    justify-content center 
    // padding-left 270px
    background url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/page-block/sdjz-bg-home.png') no-repeat center top ;
    background-size:cover
    .main-content 
        width 900px
    .right-recoment
        width 270px
        box-sizing border-box
        position fixed
        right -50px
        top 120px
        .item
            width 160px
            height 220px
            border 1px solid pink
            background-size 100% 100%
            background-repeat no-repeat
            &:nth-child(1)
                background-image url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/page-block/tuijian.png')
                margin 0 0 24px 0
            &:nth-child(2)
                background-image url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/shandongjiazheng/page-block/ruzhu.png')
                
.main-content-wraper2
    img 
        width 100%
        object-fit cover
        text-align center
    .sdjt-hp-page-block
        width 100%
    
.anchor-list
    position fixed
    left 47px
    top 80px
    color #183B56
    font-size 16px
    .anchor-item
        position relative
        display flex
        align-items center
        height 64px
        font-weight: 600
        .number 
            width 30px
            height 30px
            background #FFFFFF
            box-shadow 0px 1px 4px 0px rgba(21, 37, 71, 0.15)
            border 1px solid rgba(45, 103, 255, 0.5)
            text-align center 
            line-height 30px
            margin-right 17px
            border-radius 50%
            color #2D67FF
        .border
            width 30px
            height 30px
            background #FFFFFF
            box-shadow 0px 1px 4px 0px rgba(21, 37, 71, 0.15)
            border 1px solid rgba(45, 103, 255, 0.5)
            text-align center 
            line-height 30px
            margin-right 17px
            border-radius 50%
            color #2D67FF
            &::after
                position absolute
                left 15px
                top -9px
                width 1px;
                height 20px
                // border 1px solid #B9C7EE;
                background-color #B9C7EE
                content ''
                z-index -1
    .link-active 
        position relative
        display flex
        align-items center
        height 64px
        color #2D67FF
        font-weight: 600
        .number 
            width 30px
            height 30px
            box-shadow 0px 1px 4px 0px rgba(21, 37, 71, 0.15)
            border 1px solid rgba(45, 103, 255, 0.5)
            text-align center 
            line-height 30px
            margin-right 17px
            border-radius 50%
            // color #2D67FF
            color white
            background-color #2D67FF
        .border
            width 30px
            height 30px
            box-shadow 0px 1px 4px 0px rgba(21, 37, 71, 0.15)
            border 1px solid rgba(45, 103, 255, 0.5)
            text-align center 
            line-height 30px
            margin-right 17px
            border-radius 50%
            // color #2D67FF
            color white
            background-color #2D67FF

            &::after
                position absolute
                left 15px
                top -9px
                width 1px;
                height 20px
                // border 1px solid #B9C7EE;
                background-color #B9C7EE
                content ''
                z-index -1



</style>
