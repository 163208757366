<template>
    <div class="gongxintDetails">
        <!--顶部底图-->
        <div class="banner-wrap" >
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/course-banner2.jpg" />
        </div>
        <!--课程包信息-->
        <div class="trainng-card-wrap" :class="{'fixed': isFixed}">
            <div class="trainng-card flex lr-flex block-main">
                <div>
                    <div class="flex price-box">
                        <div class="free" v-if="trainObj.isFree">
                            免费
                        </div>
                        <div v-else class="flex">
                            <p class="flex" v-show="trainObj.salePrice">
                                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/red-y.png" class="red"/>
                                <span class="now-price">{{trainObj.salePrice}}</span>
                            </p>
                            <p  class="flex pre-price-box" v-show="trainObj.basePrice">
                                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/grey-y.png" class="grey"/>
                                <span class="pre-price">{{trainObj.basePrice}}</span>
                            </p>
                        </div>
                        
                        <p v-if="isFixed" style="display:flex;">
                            <span class="name" :title="trainObj.name">{{trainObj.name}}</span>
                            <span class="time" v-if="trainObj.validityType === 1">
                                有效期：长期有效
                            </span>
                            <span class="time" v-else-if="trainObj.validityType === 2">
                                有效期：{{dataFormat(trainObj.startTime)}}-{{dataFormat(trainObj.endTime)}}
                            </span>
                            <span class="time" v-if="trainObj.validityType === 4">
                                有效期：报名后{{trainObj.days}} 天内有效
                            </span>
                        </p>
                    </div>
                    <p v-if="!isFixed" style="display:flex;">
                        <span class="name" :title="trainObj.name">
                            {{trainObj.name}}
                        </span>
                        <span class="time" v-if="trainObj.validityType === 1">
                            有效期：长期有效
                        </span>
                        <span class="time" v-else-if="trainObj.validityType === 2">
                            有效期：{{dataFormat(trainObj.startTime)}}-{{dataFormat(trainObj.endTime)}}
                        </span>
                        <span class="time" v-if="trainObj.validityType === 4">
                            有效期：报名后{{trainObj.days}} 天内有效
                        </span>
                    </p>
                </div>
                <div class="btn-study"  >
                    <!-- {{purchased?'报名已结束':'立即学习'}} -->
                    <p @click="jumpStudy" class="button" :class="{'disable': btnType == '报名已结束'}">{{btnType}}</p>
                    <p @click="dialog.show = true" class="xieyi">《培训服务协议》</p>

                </div>
            </div>
        </div>
        <!-- 项目介绍 -->
        <div class="project-introduction">
            <div class="container">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/details-title-1.png" class="title" alt="">
                <div class="content">
                    <div class="left">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/project-img.png" alt="">
                    </div>
                    <div class="right">
                        <h2 class="title">《人工智能应用管理师职业能力等级评价项目》</h2>
                        <p class="text">是由工业和信息化部教育与考试中心（CEIAEC）面向人工智能行业从业者推出的职业能力等级评定项目。该项目面向当前人工智能应用管理人才极度紧缺的现状，弥补了权威性人工智能人才能力培养体系和评价体系标准的空白，作为国内人工智能领域首个职业能力等级评价标准。为人工智能人才培养，人才评估和企业招聘提供了重要的依据。目前，《人工智能应用管理师职业能力等级评价证书》已经得到越来越多用人单位的认可，助力求职者实现更高职业梦想。</p>
                        <p class="label">
                            <span>实现职业梦想</span>
                            <span>用人单位认可</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!--课程定位-->
        <div class="img-block1">
            <!-- <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/课程定位@2x.png" class="right" /> -->
        </div>
        <!--学员对象-->
        <div class="img-block2">
            <!-- <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/学员对象@2x.png" class="right" /> -->
        </div>
        <!--课程内容-->
        <div class="img-block3">
            <!-- <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/课程定位备份 2@2x.png" class="right" /> -->
        </div>
        <!--课程大纲-->
        <div class="course-block">
            <!-- <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/stage1.png"  class="img1 img"/>
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/stage2.png"  class="img2 img"/>
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/stage3.png"  class="img3 img"/>
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/stage4.png"  class="img4 img"/> -->
            <div class="block-title">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/course-title.png" />
                <!-- <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/details-title-img.png" />
                课程大纲与学习安排
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/details-title-img.png" /> -->
            </div>
            <div class="block-main" style="min-height:auto;">
                <el-collapse accordion v-model="activeCollapse" >
                    <el-collapse-item
                        :name="item.id" 
                        class="my-collapse-item"  
                        v-for="item in planList" 
                        :key="item.id"
                        :class="{'item-one': item.id === 1, 'item-last': item.id === 5}"
                    >
                        <template slot="title">
                            <span class="stage">
                                {{item.stageNum}}：
                            </span>
                            <span class="name">
                                {{item.stageName}}
                            </span>
                        </template>
                        <div class="info">
                            {{item.describe}}
                        </div>
                        <div class="des" v-if="item.id==5">
                            说明：线上学习有效期1年，学员自由安排时间，学习期间教务汇总学习情况灵活安排直播答疑与考前辅导（考前辅导一般安排在评价考试前一周）。
                        </div>
                    </el-collapse-item>
                    
                </el-collapse>
            </div>
        </div>
        <!--学习资料-->
        <div class="img-block4">
            <!-- <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/学习资料@2x.png" class="right" /> -->
        </div>
        <!--学习收获-->
        <div class="img-block5">
            <!-- <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/学习收获@2x.png" class="right" /> -->
        </div>
        <agreementTwo :dialog="dialog" />
        <footers/>
    </div>
</template>

<script>
// import planList from './planList.js'
import footers from '../block/front/footers.vue'
import agreementTwo from './agreementTwo.vue'
export default {
  name: 'gongxinDetailsTwo',
  show: true,
  props: {
  },
  components: {
      footers,
      agreementTwo
  },
    data() {
        return {
            planList:[
                {
                    id: 1,
                    stageNum: '阶段一',
                    stageName: '《Python编程基础》',
                    describe: 'Python是人工智能领域最常用的编程语言之一，本课程以案例方式帮助学员快速掌握Python核心编程知识，熟悉语言特点和编程规范，掌握基础编程能力和技巧，为后续课程的学习打下基础。',
                },
                {
                    id: 2,
                    stageNum: '阶段二',
                    stageName: '《数据采集与处理》',
                    describe: '数据是人工智能的基础资源，数据的质量直接影响着AI算法的性能，本课程将学习重要的数据采集和处理知识，学员将掌握如何通过ETL工具、网络爬虫等方法采集和获取数据，以及如果通过清洗、转换等方法处理数据。',
                },
                {
                    id: 3,
                    stageNum: '阶段三',
                    stageName: '《数据标注工程》',
                    describe: '数据标注员工/数据标注工程师是人工智能产业发展催生出来的新兴岗位，人才缺口较大。本课程包括数据标注工具的使用、数据标注场景、数据标注案例实战、数据标注平台使用、数据标准管理等内容，帮助学员快速掌握数据标注工作相关的主要知识和技能。',
                },
                {
                    id: 4,
                    stageNum: '阶段四',
                    stageName: '《系统部署与运维》',
                    describe: '本课程主要面向人工智能信息系统相关的运营维护、技术支持等工作需求，包括linux运维管理、web服务器运维、大数据运维、docker运维、自动化运维管理等内容。',
                },
                {
                    id: 5,
                    stageNum: '阶段五',
                    stageName: '《AI开放能力平台应用》',
                    describe: '运用主流的AI开放能力平台实现图像、语音、自然语言处理等人工智能应用是初级人工智能应用管理师的重要能力要求，本课程通过多个案例介绍主流AI开放能力平台（百度、腾讯AI开放平台等）的算法功能，以及API接口调用方法，基于开放能力实现AI应用。',
                },
            ],
            isFixed: false,
            activeListenIndex: 0,
            activeCaseTab: 1,
            // planList: planList,
            chapterList: [0,0,0,0,0,0,0,0,0],
            trainObj:{},
            dialog:{
                show:false,
            },
            caseList: [
                {txt: '案例一', id: 1},
                {txt: '案例二', id: 2},
                {txt: '案例三', id: 3},
                {txt: '案例四', id: 4},
                {txt: '案例五', id: 5},
                {txt: '案例六', id: 6},
                {txt: '案例七', id: 7},
            ],
            video1: null,
            video2: null,
            activeCollapse: 1,
            // purchased: null,
            goodsId: '',
            btnType: ''
        };
    },
    computed: {
        curTabImg() {
            return `https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/case-${this.activeCaseTab}.png`;
        },
    },
    mounted() {
        let that = this;
        window.addEventListener("scroll", function () {
            if(document.documentElement.scrollTop > 220){
                that.isFixed = true
            } else {
                that.isFixed = false;
            }
        })
        this.getGoodsById();
        this.video1 = document.getElementById('video1');
        this.video2 = document.getElementById('video2');
    },
    created() {
        
    },
    beforeDestroy() {
        
    },
    methods: {
        // 时间换算
        dataFormat(msg) {
            if (msg) {
                const m = (msg||'').split(' ')[0].split('-');
                const s = m[0]+'年'+m[1]+'月'+m[2]+'日';
                return s;
            }
        },
        getGoodsById() {
            this.goodsId = this.$route.query.id || sessionStorage.getItem('goodsId');
            this.$api.home.getGoodsById(this.goodsId).then(res=>{
                if (res.data) {
                    this.trainObj = res.data
                    if(this.trainObj.name.length>11){
                        this.trainObj.name = this.trainObj.name.substring(0,11)+'...'
                    }
                    if (localStorage.getItem('token')) {
                        this.checkUserCanByGoods(this.trainObj.id);
                    } else {
                        // this.purchased = true;
                        if (this.trainObj.status==1) {
                            this.btnType='立即报名'
                        } else if (this.trainObj.status==0) {
                            this.btnType='报名已结束'
                        }
                    }
                }
            })
        },
        checkUserCanByGoods(id) {
            this.$api.home.checkUserCanByGoods(id).then(res=>{
                if (res.data) {
                    // this.purchased = res.data;
                    if (this.trainObj.status==1) {
                        this.btnType='立即报名'
                    } else if (this.trainObj.status==0) {
                        this.btnType='报名已结束'
                    }
                } else {
                    this.btnType='立即学习'
                }
            })
        },
        changeCarousel(a) {
            if (a === 1) {
                this.activeListenIndex = 1;
                this.video1.pause()
            } else {
                this.activeListenIndex = 0;
                this.video2.pause()
            }

        },
        jumpStudy() {
            if (this.btnType == '立即学习') {
                if(this.trainObj.goodsType === 1){
                    // this.$router.push(`/course/catalog?id=${this.trainObj.referId}&taskType=${this.trainObj.goodsType}`)
                    this.$router.push({
                                    path:'/course/catalog',
                                    query:{
                                        taskId:this.trainObj.referId,
                                        taskType:this.trainObj.goodsType,
                                        backPath:this.$route.path
                                    }
                                })
                }else if(this.trainObj.goodsType === 2){
                    // this.$router.push(`/learning/taskDetail?id=${this.trainObj.referId}&taskType=${this.trainObj.goodsType}`)
                    this.$router.push({
                                    path:'/learning/taskDetail',
                                    query:{
                                        id:this.trainObj.referId,//任务id
                                        taskType:this.trainObj.goodsType,
                                        backPath:this.$route.path
                                    }
                                })
                }else if(this.trainObj.goodsType === 3){
                    this.$router.push({
                        path:'/assess',
                        query:{
                            assessId:this.trainObj.referId,//测评id
                            refId:this.trainObj.id,
                            refType:2,
                            pathName:this.$route.path
                        }
                    })
                }
            }else {
                if(this.trainObj.isFree){
                    let data = {
                        goodsId:this.trainObj.id,
                        orderSource:1
                    }
                    this.$api.home.placeOrder({data}).then(res => {
                        if(res.code === 0){
                            this.$message({
                                message: '报名成功',
                                type: 'success'
                            })
                            if(this.trainObj.goodsType==1){
                                this.$router.push({
                                    path:'/course/catalog',
                                    query:{
                                        taskId:this.trainObj.referId,
                                        taskType:this.trainObj.goodsType,
                                        // goodsId:this.$route.query.goodsId,
                                        backPath:this.$route.path
                                    }
                                })
                            }else if(this.trainObj.goodsType==2){
                                this.$router.push({
                                    path:'/learning/taskDetail',
                                    query:{
                                        id:this.trainObj.referId,//任务id
                                        // goodsId:this.$route.query.goodsId,
                                        backPath:this.$route.path,
                                        type:this.trainObj.goodsType,
                                    }
                                })
                            }else if(this.trainObj.goodsType === 3){
                                this.$router.push({
                                    path:'/assess',
                                    query:{
                                        assessId:this.trainObj.referId,//测评id
                                        refId:this.trainObj.id,
                                        refType:2,
                                        pathName:this.$route.path
                                    }
                                })
                            }
                        }
                    })
                }else {
                    if (this.btnType == '立即报名') {
                        if (localStorage.getItem('token')) {
                            this.$router.push(`/orderPage?id=${this.trainObj.id}&dymode=1`)
                            sessionStorage.removeItem("dialogState")
                            sessionStorage.removeItem("typeState");
                            sessionStorage.removeItem("qrcodeState");
                        } else {
                            this.$router.push({
                                path: '/login',
                                query: {
                                    name: 'gongxinDetailsTwo',
                                    type: 'order',
                                    id: this.trainObj.id,
                                    referId: this.trainObj.referId
                                }
                            })
                        }
                    }
                }
                
            }
        },
        clickItem(id, index) {
            this.$set(this.chapterList, id-1, index);
        },
        changeCorousel(val) {
            this.activeListenIndex = val;
            this.$refs.carousel.setActiveItem(val);
            if (val == 1) {
                this.video1.pause();
            } else {
                this.video2.pause();
            }
        },
    }
};
</script>
<style lang="stylus">
</style>
<style lang="stylus" scoped>
@import '../asset/css/gongxinDetails';
.gongxintDetails {
    min-width:1200px;
    .block-main{
        .info{
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 25px;
            margin:20px 34px;
        }
        .des{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
            margin:10px 34px 30px;
        }
    }
    .project-introduction{
        background :rgb(243,247,255)
    }
    .img-block1{
        background:url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/课程定位@2x.png') center center no-repeat;
        background-size :1920px 770px;
        height:770px;
        background-color: #fff;
    }
    .img-block2{
        background:url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/学员对象@2x.png') center center no-repeat;
        background-size :1920px 689px;
        height:689px;
        background-color: rgb(63,118,247);
    }
    .img-block3{
        background:url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/课程定位备份 2@2x.png') center center no-repeat;
        background-size :1920px 679px;
        height:679px;
        background-color: rgb(244,248,255);
    }
    .img-block4{
        background:url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/学习资料@2x.png') center center no-repeat;
        background-size :1920px 251px;
        height:251px;
        background-color: rgb(86,136,251);
    }
    .img-block5{
        background:url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/学习收获1@2x.png') center center no-repeat;
        background-size :1920px 730px;
        height:730px;
        background-color: rgb(242,245,250);
    }
    .course-block{
        background :rgb(243,247,255)
        padding-bottom:97px;
        .block-title{
            padding:49px 0 38px;
        }
    }
    .listen-block {
        /deep/.el-carousel__container {
            width: 1100px;
            margin: 0 50px;
        }
    }
    /deep/.el-collapse-item__content{
        padding-bottom:0;
    }
    /deep/.el-carousel__container {
        .el-carousel__arrow {
                content: none;
                background-color: rgba(31,45,61,0);
                width: 21px;
                height: 41px;
                background-repeat: no-repeat;
                background-position: center 0px;
                background-size: cover;
                i {
                    display: none;
                }
            }
            .el-carousel__arrow--left {
                background-image: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/arrorl.png');
                left: -50px;
            }
            .el-carousel__arrow--right {
                background-image: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/gongxin/arrorr.png');
                right: -50px;
            }
        }
        /deep/.el-carousel__indicators--horizontal {
            display: none;
        }
}
</style>
