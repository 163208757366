<template>
  <div class="loginBox">
    <div class="login">
      <p class="login-left" />
      <div class="login-box">
        <ul class="tabs">
          <li
            :key="item.key"
            v-for="(item, index) in tabsList"
            @click="setActiveName(item.key)"
            :style="index === 0 ? 'margin-right:40px' : ''"
            :class="{ highlight: activeName === item.key, hastwo:tabsList.length !== 1, [theme]: theme }"
          >{{ item.val }}</li>
        </ul>
        <el-form class="form" ref="form" :rules="rules" :model="formData">
          <el-form-item prop="account" ref="account">
            <el-input
              :placeholder="'请输入'+placeholderTxt"
              v-model="formData.account"
              @keyup.enter.native="onSubmit"
              key="account"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item prop="password" v-if="activeName === 'password'" ref="password">
            <el-input :type="passw" placeholder="请输入登录密码" v-model="formData.password" key="password" clearable></el-input>
          </el-form-item>
          <el-form-item v-else prop="authCode" ref="authCode">
            <el-input placeholder="请输入验证码" v-model="formData.authCode" @keyup.enter.native="onSubmit">
              <el-button
                slot="append"
                @click="postAccountGetAuthCode"
                :style="sendCodeBtnStyle"
                round
              >{{ 0 >= Countdown ? '发送验证码' : Countdown+'s' }}</el-button>
            </el-input>
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button class="loginbtn" :class="{[theme]:theme}" @click="onSubmit">登录</el-button>
          <div v-show="activeName === 'password'" class="forgetpsd">
            <router-link class="fr" :to="{ path: '/forget' }" style="color:#5F6368">忘记密码？</router-link>
          </div>
        </div>
        <div class="read" :class="{[theme]:theme}">
            <el-checkbox v-model="hasreaded">我已阅读并同意</el-checkbox>
            <span class="agreement" @click="goPage('service-agreement')">《用户协议》</span>
            <span style="color:#666">及</span>
            <span class="agreement" @click="goPage('privacy')">《法律声明及隐私政策》</span>
        </div>
        <div class="footerTip" v-if="canRegister">
          <span class="tip">还没账号？ </span>
          <a href="javascript:;" class="view" :class="{[theme]:theme}" @click="toRegister"> 立即注册 ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import $ from "jquery";
import md5 from "js-md5";
import config from '@/config/url'
import { isPhone, isEmail, isIdentitycard } from '@/utils/validator'
export default {
  name: "login",
  data() {
    const accountValidator = (rule, value, callback) => {
      if ( isPhone.test(value) || isEmail.test(value) || isIdentitycard.test(value) ) {
        callback()
      } else {
        callback(new Error('请输入'+this.placeholderTxt))
      }
    }
    return {
      tabsList: [
        { key: "password", val: "密码登录" },
        { key: "Verification", val: "验证码登录" }
      ],
      activeName: "password",
      passw: "password",
      CountdownInterval: null,
      Countdown: 0,
      smallImg: "",
      rules: {
        account: [
          {
            validator: accountValidator.bind(this),
            trigger: "blur"
          }
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" }
        ],
        authCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 6, max: 6, message: "请输入六位验证码", trigger: "blur" }
        ]
      },
      formData: {
        account: null,
        password: null,
        authCode: null
      },
      bigImg: "",
      loginMethodList:['1','2','3','4','5'],//1,3验证码，1手机号3邮箱，           2，4，5密码 ，2手机号，4邮箱， 5身份证
      logintxt:'手机号码/邮箱/身份证号码',
      canRegister:false,
      hasreaded:false,
    };
  },
  created() {
    sessionStorage.clear();
    localStorage.clear();
    let url= config.confUrl
    this.configuration(url);
  },
  computed:{
    theme(){
      return this.$store.state.theme
    },
    placeholderTxt(){
      if(this.tabsList.length == 1 && this.activeName === 'Verification' && this.logintxt.includes('身份证')){
        let index = this.logintxt.lastIndexOf('/')
        return this.logintxt.slice(0,index)
      }else if(this.tabsList.length == 2 && this.activeName === 'password'){
        let strToArr = this.logintxt.split('/')
        if(!this.loginMethodList.includes('2') && this.logintxt.includes('手机号码')){
          let index = strToArr.indexOf('手机号码')
          strToArr.splice(index,1)
        }
        if(!this.loginMethodList.includes('4') && this.logintxt.includes('邮箱')){
          let index = strToArr.indexOf('邮箱')
          strToArr.splice(index,1)
        }
        return strToArr.join('/')
      }else if(this.tabsList.length == 2 && this.activeName === 'Verification'){
        let strToArr = this.logintxt.split('/')
        let ind = strToArr.indexOf('身份证号码')
        if(ind != -1){
          strToArr.splice(ind,1)
        }
        if(!this.loginMethodList.includes('1') && this.logintxt.includes('手机号码')){
          let index = strToArr.indexOf('手机号码')
          strToArr.splice(index,1)
        }
        if(!this.loginMethodList.includes('3') && this.logintxt.includes('邮箱')){
          let index = strToArr.indexOf('邮箱')
          strToArr.splice(index,1)
        }
        return strToArr.join('/')
      }else{
        return this.logintxt
      }
    },
    sendCodeBtnStyle(){
      let background = '#316fff'
      if(this.theme === 'yingji'){
        background = '#D63535'
      }else if(this.theme === 'wanghong'){
        background = 'linear-gradient(147deg, #7D7EFF 0%, #FF48A7 100%)'
      }else if(this.theme === 'shanghaijiaoda'){
        background = '#C51022'
      }
      return {
          background:0 >= this.Countdown ? background:'#BFC8D9',
          border:'none'
      }
    }
  },
  mounted() {
    console.log(this.theme)
  },
  methods: {
      //   隐私协议跳转
    goPage(url){
        window.open(`${window.location.origin}/${url}`)
    },
    handleLoginTxt(){
      let result = '手机号码/邮箱/身份证号码'
      if(!this.loginMethodList.includes('1') && !this.loginMethodList.includes('3')){
        //密码
        result = this.getMethodTxt()
        this.tabsList = [{ key: "password", val: "密码登录" }]
      }else if(!this.loginMethodList.includes('2') && !this.loginMethodList.includes('4') && !this.loginMethodList.includes('5')){
        //验证码
        result = this.getMethodTxt()
        this.tabsList = [{ key: "Verification", val: "验证码登录" }]
        this.activeName = 'Verification'
      }else{
        //验证码+密码
        result = this.getMethodTxt()
        this.tabsList = [
          { key: "password", val: "密码登录" },
          { key: "Verification", val: "验证码登录" }
        ]
      }
      this.logintxt = result
    },
    getMethodTxt(){
      let hasphone,hasemail,hasidentifycard = ''
      if(this.loginMethodList.includes('1') || this.loginMethodList.includes('2')) hasphone = '手机号码'
      if(this.loginMethodList.includes('3') || this.loginMethodList.includes('4')) hasemail = '邮箱'
      if(this.loginMethodList.includes('5')) hasidentifycard = '身份证号码'
      let arr = [hasphone,hasemail,hasidentifycard].filter(item => {return !!item})
      return arr.join('/')
    },
    setActiveName(val) {
      if(val === this.activeName) return
      this.activeName = val;
      this.$refs.account.clearValidate();
      if (this.activeName === "password") {
        this.$refs.authCode.clearValidate();
      } else {
        this.$refs.password.clearValidate();
      }
    },
    configuration(url) {
      this.$api.configure.configuration(url).then(res => {
        if (res.data) {
          let frontendConfig = res.data.frontendConfig.find(item => {
              return item.key === "homepage";
          });
          localStorage.setItem('domain', JSON.stringify(res.data.domain)?JSON.stringify(res.data.domain):'');
          localStorage.setItem("hasGetConfig", "1");
          localStorage.setItem("theme", res.data.theme || "");
          this.$store.commit('setTheme',res.data.theme || "")
          localStorage.setItem("frontendConfig",frontendConfig ? JSON.stringify(frontendConfig) : "");
          localStorage.setItem("configurationArr",JSON.stringify(res.data.frontendConfig));
          // 修改页签信息
          if (res.data.pageLogo) {
            var link = document.querySelector("link[rel*='icon']");
            link.href = res.data.pageLogo;
            localStorage.setItem('pageLogo', res.data.pageLogo)
          }
          if (res.data.pageTitle) {
            document.title = res.data.pageTitle;
            localStorage.setItem('pageTitle', res.data.pageTitle)
          }
          this.initConfig();
        }
      });
    },
    initConfig() {
      /*
        手机号+验证码 is_allow_telephone_verification_code_login 1
        手机号+密码   is_allow_telephone_password_login 2
        邮箱+验证码   is_allow_email_verification_code_login 3
        邮箱+密码     is_allow_email_password_login 4
        身份证+密码   is_allow_id_number_password_login 5
        注册方式:手机号+验证码  is_allow_telephone_register
        注册方式:邮箱+验证码    is_allow_email_register
      */
      let configurationArr = JSON.parse( localStorage.getItem('configurationArr') )
      if (configurationArr.length>0) {
        configurationArr.forEach(item => {
          switch (item.key) {
            case 'custom_login_background_img':
              this.bigImg = item.value;
              break;
            case 'custom_login_img':
              this.smallImg = item.value;
              break;
            case 'is_open_register':
              this.canRegister = item.value === 'true'? true:false
              break;
            case 'is_allow_telephone_verification_code_login':
              if(item.value === 'false'){
                let index = this.loginMethodList.indexOf('1')
                this.loginMethodList.splice(index,1)
              }
              break;
            case 'is_allow_telephone_password_login':
              if(item.value === 'false'){
                let index = this.loginMethodList.indexOf('2')
                this.loginMethodList.splice(index,1)
              }
              break;
            case 'is_allow_email_verification_code_login':
              if(item.value === 'false'){
                let index = this.loginMethodList.indexOf('3')
                this.loginMethodList.splice(index,1)
              }
              break;
            case 'is_allow_email_password_login':
              if(item.value === 'false'){
                let index = this.loginMethodList.indexOf('4')
                this.loginMethodList.splice(index,1)
              }
              break;
            case 'is_allow_id_number_password_login':
              if(item.value === 'false'){
                let index = this.loginMethodList.indexOf('5')
                this.loginMethodList.splice(index,1)
              }
              break;
            default:
              break;
          }
        })

        const imgsrc1 = "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/beijin.png";
        const imgsrc2 = "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/login/rectangle.png";
        this.$nextTick(() => {
          // 防止背景图闪现两次
          $(".loginBox").css("background-image",`url(${this.bigImg ? this.bigImg : imgsrc1})`);
          $('.login-left').css('background-image', `url(${this.smallImg ? this.smallImg : imgsrc2})`)
        });
        this.handleLoginTxt()
      }
    },
    async onSubmit() {

      this.$refs.form.validate(valid => {
        if (valid) {
            if(!this.hasreaded){
                this.$message.warning('请勾选用户协议')
                return false;
            }
          this.toLogin();
        } else {
          return false;
        }
      });
    },
    async postAccountGetAuthCode() {
      const data = { account: this.formData.account,type:1 };
      this.formData.authCode = null;
      if (isPhone.test(this.formData.account) || isEmail.test(this.formData.account)) {
        if (this.Countdown > 0) return;
        this.Countdown = 60;
        this.setCountdown();
        this.$sensors.track('UM_Click_Verification_Code_Button')
        this.$api.usercenter.getAuthCodeCommon({ data })
      }
    },
    async toLogin() {
      let method = this.activeName === 'password'? 'postAccountLogin':'postAccountLoginByAuthCode'
      const data = {...this.formData};
      data.password && (data.password = md5(data.password).toLowerCase())
      await this.$api.usercenter[method]({ data })
        .then(async res => {
          if (res.data) {
            localStorage.setItem("token", res.data);

            try {
              await this.getFindUserBranchList()
            } catch (error) {
              if(error) return
            }
            
            this.getAccountUserInfo()//账号
            this.getUserInfo()//不同机构下

            //军民、民政登录成功逻辑
            if(this.theme === 'junmin' || this.theme === 'minzheng'){
              //判断资料是否完善
              await this.judgePersonalInfoOk()
              if(localStorage.getItem('checkJunminIsSaveInfo') === 'false' || localStorage.getItem('checkMinzhengIsSaveInfo') === 'false') return//军民资料是否完善
            }

            const orderPage = [ "gongxinDetails", "collegeDetails", "wanghongDetails","gongxinDetailsTwo"]; //订单页

            if ( orderPage.includes(this.$route.query.name) && this.$route.query.type == "order" ) {
              this.checkUserCanByGoods(this.$route.query.id);
            } else if ( this.$route.query.name && this.$route.query.name !== "error_404" ) {
              this.$router.push(this.$route.query.name);
            } else {
              // 这里的条件语句后期还要修改
              if (localStorage.getItem('frontendConfig') &&  this.theme && this.theme !== 'zhongzhi') {
                if(this.theme == 'junmin'){
                    console.log("触发了")
                    this.$router.push('/personalCenter')
                    sessionStorage.setItem('userCenterCurrentTab','我的学习')
                    return
                }
                let val = JSON.parse(localStorage.getItem('frontendConfig')).value;
                this.$router.push(val || '/')
              } else {
                this.$router.push('/learning')
              }
            }
          } else {
            return res.data;
          }
        })
        .catch(() => {
          return false;
        });
    },
    getAccountUserInfo () {
      this.$api.usercenter.getAccountUserInfo({}).then(res => {
        this.$store.commit('saveUserInfo',res.data)
        localStorage.setItem('userInfo',JSON.stringify(res.data))
      })
    },
    getUserInfo(){
      console.log('getUserInfogetUserInfogetUserInfo')
      
      this.$api.usercenter.findUser().then(res => {
        if(res.code == 0){
          this.$store.commit('saveSubUserInfo',res.data)
          localStorage.setItem('subUserInfo',JSON.stringify(res.data))
        }
      })
    },
    getFindUserBranchList() {
      localStorage.removeItem("organ")
      localStorage.removeItem("actor")
      localStorage.removeItem('branch')
      return this.$api.usercenter.getFindUserBranchList({}).then(res => {
        if (res.data && res.data.length) {
          const branchList = res.data
            .filter(
              item =>
                Object.prototype.toString.call(item) === "[object Object]"
            )
            .map(item => {
              return {
                name: item.name,
                id: item.branch,
                actor: item.actor,
                organ: item.organ,
                userId: item.userId,
                code: item.code
              };
            });
          if (branchList.length) {
            localStorage.setItem("branchList", JSON.stringify(branchList));
            localStorage.setItem("code", branchList[0].code);
            localStorage.setItem("organ", branchList[0].organ);
            localStorage.setItem("actor", branchList[0].actor);
            localStorage.setItem("branch", branchList[0].id);
            localStorage.setItem("userId", branchList[0].userId);
            this.$sensors.login(branchList[0].code)
          }
        }
      });
    },
    //判断军民、民政用户资料是否完善
    judgePersonalInfoOk(){
      if(this.theme === 'junmin'){
        return this.$api.usercenter.checkSaveDetail().then(res => {
          localStorage.setItem('checkJunminIsSaveInfo',res.data)//军民资料是否完善
          if(!res.data){
            sessionStorage.setItem('userCenterCurrentTab','我的资料')
            this.$router.push('/personalCenter')
            this.$message({
              type:'error',
              message:'请先完善个人资料',
              duration: 2500
            })
          }
        })
      }else if(this.theme === 'minzheng'){
        return this.$api.usercenter.checkMinzhengSaveDetail().then(res => {
          localStorage.setItem('checkMinzhengIsSaveInfo',res.data)//军民资料是否完善
          if(!res.data){
            sessionStorage.setItem('userCenterCurrentTab','我的资料')
            this.$router.push('/personalCenter')
            this.$message({
              type:'error',
              message:'请先完善个人资料',
              duration: 2500
            })
          }
        })
      }
    },
    // 判断用户是都已购买课程
    checkUserCanByGoods(id) {
      this.$api.home.checkUserCanByGoods(id).then(res => {
        if (res.data) {
          // 未购买，跳转到订单页面
          this.$router.replace(`/orderPage?id=${id}&dymode=0`);
        } else {
          // 已购买，跳转到课程学习页
          this.$router.replace('/learning');
        }
      });
    },
    setCountdown() {
      this.CountdownInterval = setInterval(() => {
        this.Countdown--;
        if (this.Countdown <= 0) {
          clearInterval(this.CountdownInterval);
        }
      }, 1000);
    },
    toRegister(){
      if(this.theme === 'minzheng'){
        this.$router.push('/register-mz')
      }else{
        this.$router.push('/register')
      }
    }
  }
};
/* eslint-disable no-new */
</script>

<style lang="stylus" scoped>
@import './../asset/css/login';

.tabs {
  background: #fff;
  font-size: 24px;
  color: #999;
  font-family: PingFangSC-Medium, PingFang SC;
  padding-left: 0;
  text-align: left;

  li {
    position: relative;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    height: 40px;
    margin-bottom: 0;

    &.highlight.yingji {
      border-bottom:none;
      color:#D63535;
    }
    &.highlight.wanghong {
      border-bottom:none;
      color:#AD6ADE;
    }
    &.highlight.shanghaijiaoda {
      border-bottom:none;
      color:#C51022;
    }
    &.highlight {
      border-bottom:none;
      color:#316FFF;
    }

    &.hastwo:first-child::after {
      position: absolute;
      right: -20px;
      top: 14px;
      content: '';
      display: inline-block;
      width: 1px;
      height: 15px;
      background: #E6E6E6;
    }
  }
}

.login {
  .login-btn {
    width: 190px;
    height: 36px;
    padding: 0;
    border-radius: 22px;
    background: #316fff;
    border: none;
  }

  /deep/ .el-button {
    color: #fff;
  }

  /deep/.el-button--primary >span {
    font-size: 14px;
  }

  /deep/ .el-form-item.is-error .el-input__inner, /deep/ .el-form-item .el-input__inner:focus {
    border-color: #D8D8D8;
    caret-color: #316fff;
  }

  /deep/ .el-button--info.is-disabled, /deep/ .el-button--info.is-disabled:active, /deep/ .el-button--info.is-disabled:focus, /deep/ .el-button--info.is-disabled:hover {
    background-color: #DEDDDE;
    border: #DEDDDE;
  }

  .forget {
    display: inline-block;
    margin-left: 20px;

    .fr {
      color: #5F6368;
    }
  }
}

.el-form-item {
  margin-top: 30px;
  margin-right: 0;
  // .sendCodeBtn.yingji:hover{
  //   background:#D63535 !important;
  // }
  // .sendCodeBtn.wanghong:hover{
  //   background:linear-gradient(147deg, #7D7EFF 0%, #FF48A7 100%) !important;
  // }
}
.read{
    margin-top 10px
    .agreement{
        color #0F3EAA
        cursor pointer
    }
}
.btn {
  display: flex;
  align-items: center;
  margin-top: 60px;

  .loginbtn {
    width: 190px;
    height: 36px;
    margin-right: 20px;
    padding: 0;
    border-radius: 22px;
    background: #316fff;
    border:none;
  }
  .loginbtn.yingji{
    background: #D63535;
  }
  .loginbtn.yingji:hover{
    background: #D63535 !important;
  }
  .loginbtn.shanghaijiaoda{
    background: #C51022;
  }
  .loginbtn.shanghaijiaoda:hover{
    background: #C51022 !important;
  }
  .loginbtn.wanghong{
    background: linear-gradient(147deg, #7D7EFF 0%, #FF48A7 100%);
  }
  .loginbtn.wanghong:hover{
    background: linear-gradient(147deg, #7D7EFF 0%, #FF48A7 100%) !important;
  }

  /deep/ .el-button--primary span {
    font-size: 14px;
  }
}

.footerTip {
  display: flex;
  margin-top: 20px;

  .tip {
    font-size: 12px;
    color: #5F6368;
  }

  .view {
    font-size: 12px;
    color: #1972ff;
  }
  .view.yingji{
    color: #D63535;
  }
  .view.wanghong{
    color: #AD6ADE;
  }
  .view.shanghaijiaoda{
    color: #C51022;
  }
}

.mt20.ct {
  font-size: 16px;
  color: #999999;
}



</style>
