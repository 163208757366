<template>
    <el-dialog
      :visible.sync="showInfo"
      width="800"
      @close="$emit('infoShow',false)"
      class="pub_dialog"
      center>
      <div class="dialog-detail">
        <img :src="currentCourse.pic || currentCourse.iconUrl" alt="">
        <div>
          <h4>{{currentCourse.label || currentCourse.name}}</h4>
          <p class="dialog-date" v-if="currentCourse.trainStartTime">{{currentCourse.trainStartTime | dataFormat}}至{{currentCourse.trainEndTime| dataFormat}}</p>
        </div>
      </div>
      <p class="dialog-info">{{currentCourse.trainStartTime?'训练营简介：':'课程简介：'}}{{description||currentCourse.description}}</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="$emit('infoShow',false)">知道了</el-button>
      </span>
    </el-dialog>
</template>
<script>
export default {
    name: 'dialogInfo',
    props: {
        info: {
            type: Boolean,
            default: false
        },
        currentCourse: {
            type: Object,
            default: () => {}
        },
        description: {
            type: String,
            default: ''
        },
    },
     data () {
        return {
            showInfo:false
        }
     },
     filters: {
      dataFormat(msg) {
        const date = new Date(msg)
        const m = date.getMonth()+1;
        return date.getFullYear()+'.'+m+'.'+date.getDate();
      },
    },
     watch:{
        info(val){
            this.showInfo = val
        }
     },
     methods:{
         toDetail(e){
            this.isSelect = e;
         }
     }
}
</script>
<style lang="stylus">
.el-dialog
  border-radius: 10px;
.el-dialog__footer
  padding: 0px 20px 20px;
.el-dialog__headerbtn .el-dialog__close
  font-size: 18px;
</style>
<style lang="stylus" scoped>  
 @import "../asset/css/dialogInfo.styl"

</style>