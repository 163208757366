<template>
  <div>
    <!-- 人脸对比模板 -->
    <div class="face-compare" v-show="false">
      <!-- 身份证图片 -->
      <img id="orgImg" crossorigin='anonymous' :src="orgImgElUrl" />
      <!-- 屏幕抓取图片 -->
      <img id="detImg" crossorigin='anonymous' src="" />
    </div>
    <!-- 考试直播窗口 -->
    <div class="exam-live-view">
      <!-- 异常记录 -->
      <div class="exception-record">
        <div class="exception-record-title" >
          电脑摄像头:
          <el-popover
            placement="bottom"
            width="234"
            trigger="hover">
            <div
              v-if="exceptionRecord"
              style="padding: 0px 4px 4px 4px;box-sizing:border-box;"
            >
              <div
                style="margin-top:16px"
                class="warning-text"
                v-show="exceptionRecord.multiUserNum !=0"
              >
                <span style="margin-right:10px">⚠️警告</span>
                <span>检测多人出现: {{exceptionRecord.multiUserNum}}次,超过{{exceptionRecord.multiUserLimit}}次{{recordTips}}</span>
              </div>
              <div
                style="margin-top:16px"
                class="warning-text"
                v-show="exceptionRecord.notFaceNum !=0"
              >
                <span style="margin-right:10px">⚠️警告</span>
                <span>未检测到人脸: {{exceptionRecord.notFaceNum}}次,超过{{exceptionRecord.notFaceLimit}}次{{recordTips}}</span>
              </div>
              <div
                style="margin-top:16px"
                class="warning-text"
                v-show="exceptionRecord.notOwnNum !=0"
              >
                <span style="margin-right:10px">⚠️警告</span>
                <span>检测到不是本人: {{exceptionRecord.notOwnNum}}次,超过{{exceptionRecord.notOwnLimit}}次{{recordTips}}</span>
              </div>
              <div
                style="margin-top:16px"
                class="warning-text"
                v-show="exceptionRecord.switchScreenNum !=0"
              >
                <span style="margin-right:10px">⚠️警告</span>
                <span>检测到切屏: {{exceptionRecord.switchScreenNum}}次,超过{{exceptionRecord.switchScreenNumLimit}}次{{recordTips}}</span>
              </div>
            </div>
            <div
              style="text-align: center"
              v-show="exceptionRecord.multiUserNum==0 && exceptionRecord.notFaceNum==0 && exceptionRecord.notOwnNum==0 && exceptionRecord.switchScreenNum == 0">
                无异常记录
            </div>
            <span
              slot="reference"
            >
              异常记录
            </span>
          </el-popover>
        </div>
        <div
          class="detection-btn"
          @click="handleshowCheck"
          v-show="showDetectionBtn"
          :style="{'z-index': showDetectionBtn ? '11': ''}"
        >
          检测设备
        </div>
      </div>
      <!--   摄像头播放   -->
      <div ref="video" class="realy-player-box" v-loading="loading"></div>
    </div>
    <DeviceCheckDialog
      v-if="showCheckDialog"
      @close="showCheckDialog=false"
      ref="checkout"
    ></DeviceCheckDialog>
  </div>
</template>
<script>
import * as faceapi from "face-api.js";
import DeviceCheckDialog from './dialogs/check/DeviceCheckDialog';
// import { client as screenSender } from '../libs/screen';
import { client as cameraSender } from '../libs/camera';
async function processCamera(vm,data) {
  const client = cameraSender( data.userId , data.skuAppId, data.userSig, vm);
    // let chatsTimer;

    client.onLivingL = (localStream) => {
      // vm.livingState = 'starting';
      vm.tid =setInterval(() => {
        console.log('10秒钟+++++++++')
        const frame = localStream.getVideoFrame();
        if(frame){
          if (vm.monitorNum < 12) {
            ++vm.monitorNum
          }
          const img = document.getElementById("detImg");
          img.src = frame;
          vm.nomalImg = frame;
          vm.fnInit()
        }
      }, 10000);
      // const refreshFinishTime = () => {
      //   clearInterval(vm.tid);
      // }
      // setTimeout(refreshFinishTime, 5*60 * 1000)
    };
    client.onLiveStarting = () => {
      vm.livingState = 'living';
      vm && vm.$once('hook:beforeDestroy', () => {
      });
    };
    client.onLiveStoping = () => {
      vm.livingState = 'finish';
      // clearInterval(chatsTimer);
      clearInterval(vm.tid);
    }
    vm && vm.$once('hook:beforeDestroy', async () => {
      // clearInterval(chatsTimer);
      clearInterval(vm.tid);
      localStorage.removeItem('LiveParam');
      try {
        await client.leaveRoom();
        console.log('camera leave room');
      } catch(error) {
        console.error(error);
      }
    })
    return client;
}
// async function processScreen(vm,data) {
//     // let params = {
//     //   channel: 1,
//     //   businessId: vm.params.businessId,
//     //   examId: vm.params.examId,
//     //   businessType: vm.params.taskType
//     // }
//     // const res = await vm.$api.exam.queryLinkLiveData(params)
//     const client = screenSender(data.userId , data.skuAppId, data.userSig);
//     client.onLiving = () => {
//         vm.shareScreening = true;
//     };
//     client.onLiveStoping = () => {
//         vm.shareScreening = false;
//     }
//
//     vm && vm.$once('hook:beforeDestroy', async () => {
//         try {
//             await client.stopSharing();
//             console.log('stop sharing')
//         } catch (error) {
//             console.error(error);
//         }
//         try {
//             await client.leaveRoom();
//             console.log('screen leave room')
//         } catch (error) {
//             console.error(error);
//         }
//     })
//
//     return client;
// }
export default {
    name: "teacherLive",
    components:{
      DeviceCheckDialog
    },
    props: {
      // 控制是否显示直播 是否检测异常
      controlSwitch: {
        type: Object,
        default: () => {}
      },
      whetherCapture: {
        type: Boolean,
        default: false
      },
      showDetectionBtn: {
        type: Boolean,
        default: false
      }
    },
    data(){
      return{
        showCheckDialog: false,
        distance: 0, // 对比误差值
        desc: [], // 样本矩阵
        orgImgEl: null,
        detImgEl: null,
        nets: "ssdMobilenetv1", // 模型
        params: {}, // 开直播参数
        monitorNum: -1,
        loading: true,
        monitorType: '', // 6:管理警告(pc需抓拍);7:管理员强制交卷(pc需抓拍);
        orgImgElUrl: '', //人脸图
        nomalImg: '', // 当前帧
        exceptionRecord: '', // 异常记录
        recordTips: '将强制交卷，请勿遮挡脸部，确保您的正脸在摄像像头拍摄范围内。',
        tid: null, // 人脸对比轮询
        livingState: '', // 监听直播推流成功
      }
    },
    watch: {
      'monitorType'(val) {
        if (val == 6 || val == 7 || val == 2) {
          this.fnInit();
        }
      },
      // 'livingState'(val) {
      //   if (val=='starting' && this.monitorNum < 1) {
      //     this.$emit('connected');
      //   }
      // }
    },
    created() {
      this.params = Object.assign(this.params,this.$route.query);
      localStorage.setItem('LiveParam',JSON.stringify(this.params))
      // 获取身份证人脸图片
      this.getFace();
    },
    async mounted() {
        let params = {
          channel: 1,
          businessId: this.params.businessId,
          examId: this.params.examId,
          businessType: this.params.taskType,
          userPaperId: this.$route.query.userPaperId
        }

        const res = await this.$api.exam.queryLinkLiveData(params)
        this._cameraClient = await processCamera(this,res.data);
        // this._screenClient = await processScreen(this,res.data);

        this._cameraClient.joinRoom(res.data.roomId);
        // this._screenClient.joinRoom(res.data.roomId);

        setTimeout(() => {
          this.clickSecondConfirmPop('start');
        }, 5000)
    },
    methods:{
        async cutOriginImageFace(){
          await faceapi.loadFaceRecognitionModel("/models");

          await faceapi.nets[this.nets].loadFromUri("/models");
          // 截原始图片的人脸
          this.orgImgEl = document.getElementById("orgImg");

          const orgImgElDetections = await faceapi.detectAllFaces(this.orgImgEl);

          const originFaceImages = await faceapi.extractFaces(this.orgImgEl, orgImgElDetections);

          let originImage = this.convertCanvasToImage(originFaceImages[0]);

          this.orgImgEl.src = originImage.src || this.orgImgElUrl;
        },
        // canvas 转image
        convertCanvasToImage(canvas) {
          //新Image对象，可以理解为DOM
          var image = new Image();
          // canvas.toDataURL 返回的是一串Base64编码的URL，当然,浏览器自己肯定支持
          // 指定格式 PNG
         if (canvas) {
          image.src = canvas.toDataURL("image/png");
         }
          return image;
        },
        // 初始化模型加载
        async fnInit() {
            await faceapi.loadFaceRecognitionModel("/models");
            await faceapi.nets[this.nets].loadFromUri("/models");
            // 节点属性化
            this.orgImgEl = document.getElementById("orgImg");
            this.detImgEl = document.getElementById("detImg");
            if (!this.detImgEl.src) return

            const detections = await faceapi.detectAllFaces(this.detImgEl);
            const faceImages = await faceapi.extractFaces(this.detImgEl, detections);

            let endImage = this.convertCanvasToImage(faceImages[0]);
            const img = document.getElementById("detImg");
            img.src = endImage;
            this.detImgEl.src = endImage.src;
            this.loading = false // 直播加载loading结束
              // 如果弹框一直存在 不抓取异常
              if (this.whetherCapture) return;
              console.log(this.monitorNum,'首次截取人脸============================')
              // 首次截取人脸
              if (this.monitorNum == 0) {
                this.saveCapture(this.nomalImg, this.monitorNum)
                return
              } else if (this.monitorType == 2 || this.monitorType == 6 || this.monitorType == 7) {
                // 异常截取人脸
                console.log('异常截取人脸',this.monitorType)
                let monitorType = this.monitorType;
                this.saveCapture(this.nomalImg, monitorType)
              }
              console.log(faceImages.length,'检测有'+faceImages.length+'个人脸===========')
            if (!faceImages.length && this.controlSwitch.isMonitorNotFace == 1) {
              console.log('未检测到人脸------',this.nomalImg)
              this.saveCapture(this.nomalImg, 5)
            } else if (faceImages.length > 1 && this.controlSwitch.isMonitorMultiUser == 1) {
              console.log('检测到多人----')
              this.saveCapture(this.nomalImg, 3)
            } else {
              console.log('检测到一人进行比对---')
              //转图片矩阵数据
              this.desc = [
                await faceapi.computeFaceDescriptor(this.orgImgEl),
                await faceapi.computeFaceDescriptor(this.detImgEl),
              ];
              let distance = faceapi
                .euclideanDistance(this.desc[0], this.desc[1])
                .toFixed(2);
              // 不是本人抓取图片
              if (distance > 0.5 && this.controlSwitch.isMonitorNotOwn == 1) {
                this.saveCapture(this.nomalImg, 4);
              }
              this.distance = distance;
            }
        },
        clickSecondConfirmPop(flag) {
          if (flag == 'start') {
              this.startLiving();
          } else {
              this.stopLiving();
          }
        },
        startLiving() {
          this._cameraClient.startLiving(this.$refs.video);
          this.$nextTick(()=>{
            this.cutOriginImageFace();
            this.fnInit();
          })
        },
        async stopLiving() {
            this.livingTips_html = ''
            this.studentList = [];

            this._cameraClient.stopLiving();
            this.livingState = 'finish';
        },
        handleshowCheck() {
          this.showCheckDialog = true;
          this.$emit('showShade')
        },
        // 异常上报
        async saveCapture(url, type) {
          let monitorTime = this.getNowTime();
          let data = {
            businessId: this.params.userPaperId,
            businessType: 2,
            businessTypeEnum: null,
            monitorTime: monitorTime,
            monitorType: type,
            monitorTypeEnum: null,
            photoChannel: 1,
            photoChannelEnum: null,
            userPhotoBase64: url
          }
          let res = await this.$api.exam.saveCapture(data)
          console.log(res,'发送抓拍')
        },
        // 获取身份证人脸图
        async getFace() {
          let res = await this.$api.exam.getFace();
          // if (res.success) {
            this.orgImgElUrl = 'data:image/png;base64,' + res.data;
            console.log('data:image/png;base64,' + res.data,'检测到身份证照片====')
          // }
        },
        // 获取当前抓拍时间
        getNowTime() {
          let yy = new Date().getFullYear();
          let mm = new Date().getMonth()+1;
          let dd = new Date().getDate();
          let hh = new Date().getHours();
          let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
          let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
          let time = yy+'-'+mm+'-'+dd+' '+hh+':'+mf+':'+ss;
          return time;
        }
    },
    destroyed() {
      localStorage.removeItem('LiveParam');
      clearInterval(this.tid);
      this.stopLiving()
    }
}
</script>
<style lang="stylus" scoped>
  .face-compare {
    img {
       width: 256px;
    }
    #detImg {
      min-height: 2px;
    }
  }
  .exam-live-view {
    margin-top: 39px;
    .exception-record {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      .exception-record-title {
        color: #1B2257;
        font-size: 14px;
        span {
          color: #FF5454;
          display: inline-back;
          cursor:pointer;
        }
      }
      .detection-btn {
        z-index: 9;
        font-size: 14px;
        color: #fff;
        padding: 6px 9px;
        bbox-sizing: border-box;
        background: #316FFF;
        border-radius: 4px;
        cursor:pointer;
      }
    }
    .realy-player-box {
      width: 264px;
      height: 264px;
      border-radius: 4px;
    }
  }
</style>
